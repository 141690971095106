import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import imageNotification from "../../images/NotificacionesColfactura.png";
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    typographyNotification: {
        textAlign: 'center',
    },
    span: {
        fontSize: 30,
        fontFamily: 'Muli',
        color: '#FFFFFF',
        letterSpacing: 0
    }
}));

const NotificationNotCreated = ({ ...props }) => {
    const classes = useStyles();
    const {
        text,
    } = props;

    return (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={12}>
                <img src={imageNotification} />
            </Grid>
            <Grid item xs={12} className={classes.typographyNotification}>
                <span className={classes.span}>{text}</span>
            </Grid>
        </Grid>
    )
};

NotificationNotCreated.propTypes = {
    text: PropTypes.string.isRequired
};

export default NotificationNotCreated
