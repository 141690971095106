import {
  SAVE_DISCOUNT,
  UPDATE_DISCOUNT,
  DELETE_DISCOUNT,
  GET_DISCOUNTLIST,
  GET_DISCOUNT,
  GET_REASON_DISCOUNT,
  GET_FILTER_DISCOUNTS,
  GET_FILTER_DISCOUNT,
  LOADING_SAVE_DISCOUNT,
  CHANGE_EDITING_DISCOUNT_STATUS,
  LOADING_GET_DISCOUNTS,
} from "../actions/types";

const initialState = {
  loadingGetDiscountList: false,
  loadingSave: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DISCOUNT:
      return {
        ...state,
        currentDiscount: action.payload,
      };

    case GET_DISCOUNTLIST:
      return {
        ...state,
        getDiscountsEnterprise: action.payload,
      };

    case SAVE_DISCOUNT:
      return {
        ...state,
        createDiscount: action.payload,
      };

    case UPDATE_DISCOUNT:
      return {
        ...state,
        updateDiscount: action.payload,
      };

    case DELETE_DISCOUNT:
      return {
        ...state,
        deleteDiscount: action.payload,
      };

    case GET_REASON_DISCOUNT:
      return {
        ...state,
        reasonDiscount: action.payload,
        getReasonDiscount: action.payload.result.map(item => {
          return {
            ...item,
            value: item.id,
            text: item.descripcion
          }
        })
      };

    case GET_FILTER_DISCOUNTS:
      return {
        ...state,
        getFilterDiscounts: action.payload,
      };

    case GET_FILTER_DISCOUNT:
      return {
        ...state,
        currentDiscount: action.payload,
        editingDiscountData: !!action.payload ? true : false,
      };

    case CHANGE_EDITING_DISCOUNT_STATUS:
      return {
        ...state,
        editingDiscountData: action.payload,
      };
    case LOADING_SAVE_DISCOUNT:
      return {
        ...state,
        loadingSave: action.payload,
      }
    case LOADING_GET_DISCOUNTS:
      return {
        ...state,
        loadingGetDiscountList: action.payload
      }
    default:
      return state;
  }
}
