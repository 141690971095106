import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import PersonIcon from '@material-ui/icons/Person';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import SwitchForm from '../../components/switch/switch.component.js';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.thirdBackgroundColor,
        color: theme.palette.primaryColor,
        fontWeight: 300,

    },
    avatar: {
        fontSize: 21,
        paddingTop: 4
    },
    header: {
        alignItems: 'normal',
        overflow: 'hidden',
        padding: '12px 14px 0px 11px',
    },
    headerContent: {
        overflow: 'hidden',
    },
    title: {
        color: theme.palette.primaryColor,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 'bold',
        fontSize: 18
    },
    content: {
        paddingLeft: 52,
        maxHeight: 200,
    },
    icon: {
        color: theme.palette.secundaryColor
    },
}));

function UserCard(props) {
    const { data, type, onClick, onChange } = props;
    const branchOfficesList = props.branchOfficeReducer.branchOfficesList;
    let branchName = null
    if (!!branchOfficesList && !!data.sucursalid) {
        const branch = branchOfficesList.find(branch => branch.id === data.sucursalid)
        branchName = branch.nombre
    }
    const classes = useStyles();


    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.header}
                avatar={
                    !!data.rolid ?
                        <PersonIcon className={classes.icon} /> :
                        <BusinessCenterIcon className={classes.icon} />
                }
                action={
                    (!!data.rolid ? data.rolid !== 1 : true) &&
                    <IconButton aria-label="settings" onClick={onClick} >
                        <CreateIcon className={classes.icon} />
                    </IconButton>
                }
                classes={{
                    avatar: classes.avatar,
                    title: classes.title,
                    content: classes.headerContent
                }}
                title={data.nombres + ' ' + data.apellidos}
            />
            <CardContent className={classes.content}>
                <Typography variant="body2" component="div">
                    <Fragment>
                        {type === "Biller" ?
                            <Fragment>
                                {data.email}<br />
                                {data.telefono}<br />
                                {data.rolid === 3 ? 'Colaborador' : data.rolid === 1 ? 'Representante legal' : 'Administrador'}<br />
                                {!!branchName &&
                                    <Fragment>
                                        {branchName}<br />
                                    </Fragment>
                                }
                            </Fragment>
                            : <Fragment>
                                {!!data.email &&
                                    <Fragment>
                                        {data.email}<br />
                                    </Fragment>
                                }
                                {!!data.telefono &&
                                    <Fragment>
                                        {data.telefono}<br />
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        {(!!data.rolid ? data.rolid !== 1 : true) &&
                            <SwitchForm
                                id="chkBillerRol"
                                name="chkBillerRol"
                            titleOn={
                                !!data.rolid ? data.idstate === 0 ? "Desactivar facturador" : "Activar facturador" :
                                    data.idstate === 0 ? "Desactivar vendedor" : "Activar vendedor"
                            }
                                withoutMargin={true}
                                checked={data.idstate === 0 ? true: false}
                                onChange={onChange} 
                                loading={data?.loading}
                                />
                        }
                    </Fragment>
                </Typography>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = state => ({
    branchOfficeReducer: state.branchOfficeReducer,
})

export default connect(mapStateToProps, {})(UserCard);
