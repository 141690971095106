import {
  SAVE_BASIC_DATA,
  GET_TYPE_DOCUMENT,
  GET_REGIMEN,
  GET_COMPANY,
  STATUS_STEP_BASIC_DATA,
  LOADING_SAVE_BASIC_DATA,
  LOADING_GET_BASIC_DATA
} from "../actions/types";

const initialState = {
  stepBasicDataComplete: false,
  loadingSaveBasicData: false,
  loadingGetBasicData: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_BASIC_DATA:
      return {
        ...state,
        requestSaveBasicData: action.payload,
      };
    case GET_TYPE_DOCUMENT:
      return {
        ...state,
        requestTypeDocument: action.payload,
      };
    case GET_REGIMEN:
      return {
        ...state,
        requestRegimen: action.payload,
      };
    case GET_COMPANY:
      return {
        ...state,
        requestCompany: action.payload
      }
    case STATUS_STEP_BASIC_DATA:
      return {
        ...state,
        stepBasicDataComplete: action.payload,
      };
    case LOADING_SAVE_BASIC_DATA:
      return {
        ...state,
        loadingSaveBasicData: action.payload
      }
    case LOADING_GET_BASIC_DATA:
      return {
        ...state,
        loadingGetBasicData: action.payload
      }
    default:
      return state;
  }
}
