import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { URL_SECURITY } from '../config/config'

import MasterPage from '../components/masterPage/masterPage.component.js'
import { validateTokenExpirationDate } from '../actions/authActions';

const PrivateRoute = ({ component: Component, ...rest }) => {
    validateTokenExpirationDate();
    return (
        <Route {...rest} render={props => (
            <div>
                {
                    localStorage.getItem('isAuthenticated')
                        ? <MasterPage component={Component} {...props} />
                        : window.location.href = URL_SECURITY
                }
            </div>
        )} />
    );
};

PrivateRoute.propTypes = {
    generalReducer: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    generalReducer: state.generalReducer
})

export default connect(mapStateToProps, {})(PrivateRoute);
