import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Grid } from "@material-ui/core";

import CustomTooltip from "../tooltip/tooltip.component"

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        textTransform: 'capitalize',
        color: theme.palette.primaryColor,
        fontWeight: 600,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.fourthBackgroundColor,

        '& .Mui-selected': {
            backgroundColor: theme.palette.secundaryColor,
            borderRadius: 5,
            border: 'none'
        },
        '& .Mui-selected:hover': {
            backgroundColor: theme.palette.secundaryColor,
            borderRadius: 5,
            border: 'none'
        }
    },
    group: {
        width: '100%',
        border: 'none'
    },
    styleText: {
        color: theme.palette.primaryColor,
        textTransform: 'capitalize'
    },
    styleToogle: {
        height: 10,
        width: '100%',
        border: 'none',
        fontWeight: 600
    },
    root: {
        color: theme.palette.primaryColor,
        '& .MuiFormLabel-root.Mui-focused ': {
            color: theme.palette.primaryColor
        },
        paddingLeft: 10,
        paddingRight: 10,
    },
    title: {
        color: "gray",
        fontFamily: theme.typography.fontFamily,
        fontSize: 11,
        boder: 'none',
        lineHeight: '15px',
        padding: '0 0 0 30px'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}));


export default function ToggleButtons(props) {
    const classes = useStyles();
    const { label, onChange, value, titleOne, titleTwo, txtTooltip } = props;

    return (
        <div className={classes.root}>
            <span className={classes.title}> {label}</span>
            <Grid container alignItems="center" spacing={1} >
                {txtTooltip ? (
                    <Fragment>
                        <Grid item xs={11} className={classes.container}>
                            <ToggleButtonGroup
                                value={value}
                                exclusive
                                onChange={onChange}
                                aria-label="text alignment"
                                size={'large'}
                                className={classes.group}
                            >
                                <ToggleButton value="1" aria-label="left aligned" className={classes.styleToogle}  >
                                    <span className={classes.styleText} >{titleOne}</span>
                                </ToggleButton>
                                <ToggleButton value="2" aria-label="right aligned" className={classes.styleToogle}  >
                                    <span className={classes.styleText}>{titleTwo}</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={1} className={classes.wrapper}>
                            <CustomTooltip message={txtTooltip} />
                        </Grid>
                    </Fragment>
                ) : <Grid item xs={12} className={classes.container}>
                        <ToggleButtonGroup
                            value={value}
                            exclusive
                            onChange={onChange}
                            aria-label="text alignment"
                            size={'large'}
                            className={classes.group}
                        >
                            <ToggleButton value="1" aria-label="left aligned" className={classes.styleToogle}  >
                                <span className={classes.styleText} >{titleOne}</span>
                            </ToggleButton>
                            <ToggleButton value="2" aria-label="right aligned" className={classes.styleToogle}  >
                                <span className={classes.styleText}>{titleTwo}</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                }
            </Grid>
        </div>
    );
}
