import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";

import { SuccessAlert, WarningAlert } from '../../helpers/alert.helpers'
import { regexAlphaNumeric } from '../../helpers/customRegex.hepers'
import InputForm from "../../components/input/input.component";
import CustomAutoComplete from '../../components/autocomplete/autocomplete.component'
import ButtonPrimary from '../../components/button/buttonPrimary.component';

import { getAllCitiesAction } from '../../actions/configAction'
import { saveAndressAction, updateAddressAction, getAndressListAction } from '../../actions/addressAction'
import generateId from "../../utils/generateId";


class NewAddress extends Component {


    constructor(props) {
        super(props);
        this.state = {
            form: {
                txtAddressName: '',
                txtAddressDescription: '',
                ddlAddressState: null,
                ddlAddressCity: null,
                citiesAddress: []
            },

            getStatesResponse: [],
            allCitiesCountry:[],
            saveAddressResponse: {},
            updateAddressListResponse: {},
            loadAddress: null,
            editAddressStatus: false
        }

        this.validator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentDidMount() { 
        this.props.getAllCitiesAction();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.configReducer.getStatesResponse && nextProps.configReducer.getStatesResponse !== state.getStatesResponse)
            update.getStatesResponse = nextProps.configReducer.getStatesResponse;

        if (!!nextProps.configReducer.allCitiesCountry && nextProps.configReducer.allCitiesCountry !== state.allCitiesCountry)
            update.allCitiesCountry = nextProps.configReducer.allCitiesCountry;


        if (!!nextProps.addressReducer.saveAddressResponse && nextProps.addressReducer.saveAddressResponse !== state.saveAddressResponse)
            update.saveAddressResponse = nextProps.addressReducer.saveAddressResponse;

        if (nextProps.addressReducer.loadAddress !== state.loadAddress) {
            update.loadAddress = nextProps.addressReducer.loadAddress;
            update.editAddressStatus = nextProps.addressReducer.editAddressStatus;
        }

        if (!!nextProps.addressReducer.updateAddressResponse && nextProps.addressReducer.updateAddressResponse !== state.updateAddressResponse) {
            update.updateAddressResponse = nextProps.addressReducer.updateAddressResponse;
        }


        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.saveAddressResponse !== this.state.saveAddressResponse) {
            if (this.state.saveAddressResponse.responseCode === 'A10') {
                this.cleanForm();
                SuccessAlert('Registro exitoso', 'Se ha registrado una dirección adicional');
            }
            else if (this.state.saveAddressResponse.responseCode === 'R11') {
                WarningAlert('Dirección existente', 'Ya se ha agregado una dirección con este nombre');
            }
        }

        if (prevState.loadAddress !== this.state.loadAddress) {
            if (!!this.state.loadAddress)
                this.loadInfo(this.state.loadAddress);
        }

        if (prevState.updateAddressResponse !== this.state.updateAddressResponse) {
            if (this.state.updateAddressResponse.responseCode === 'A10') {
                this.cleanForm();
                SuccessAlert('Actualización exitosa', 'Se ha actualizado la dirección');
                this.props.getAndressListAction();
            }
        }
    }


    syncChanges(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    syncAutoCompleteChanges(event, values, id) {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: values
            }
        }))
    }

    validateAlphaNumeric(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexAlphaNumeric;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    changeAddressState(event, values) {
        event.preventDefault();
        let newCities = [];
        if (!!values)
            newCities = this.state.allCitiesCountry.filter(c => c.departamentoid === parseInt(values.value));

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                ddlAddressCity: null,
                citiesAddress: !!newCities ? newCities : null
            }
        }))
    }

    onSaveAddress = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            let direccion = {
                id: this.state.editAddressStatus ? this.state.loadAddress.id : generateId(),
                descripcion: this.state.form.txtAddressName,
                direccion: this.state.form.txtAddressDescription,
                departamentoid: this.state.form.ddlAddressState.value,
                departamentodescripcion: this.state.form.ddlAddressState.text,
                ciudadid: parseInt(this.state.form.ddlAddressCity.value),
                ciudaddescripcion: this.state.form.ddlAddressCity.text
            }

            if (this.state.editAddressStatus)
                this.props.updateAddressAction(direccion);
            else
                this.props.saveAndressAction(direccion);


        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    cleanForm = () => {
        this.setState({
            form: {
                txtAddressName: '',
                txtAddressDescription: '',
                ddlAddressState: null,
                ddlAddressCity: null,
                citiesAddress: []
            },
            saveAddressResponse: {},
        });
        this.validator.hideMessages();
    }

    loadInfo(direcccion) {
        let cities = this.state.allCitiesCountry.filter(c => c.departamentoid === parseInt(direcccion.departamentoid, 10));
        let city = cities.length ? cities.filter(c => c.value === direcccion.ciudadid.toString()) : null;
        let state = this.state.getStatesResponse.filter(s => s.value === direcccion.departamentoid.toString())

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtAddressName: direcccion.descripcion,
                txtAddressDescription: direcccion.direccion,
                ddlAddressState: state.length ? state[0] : null,
                ddlAddressCity: !!city ? city[0] : null,
                citiesAddress: !!cities ? cities : []
            }
        }))
    }


    render() {
        return (
            <Fragment>
                <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                        <InputForm
                            id={"txtAddressName"}
                            label={"Nombre Dirección *"}
                            maxLength={100}
                            value={this.state.form.txtAddressName}
                            onChange={(e) => {
                                this.syncChanges(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|min:3|max:100"}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <InputForm
                            id={"txtAddressDescription"}
                            label={"Dirección *"}
                            maxLength={100}
                            value={this.state.form.txtAddressDescription}
                            onChange={(e) => {
                                this.syncChanges(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required"}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <CustomAutoComplete
                            id="ddlAddressState"
                            label="Departamento *"
                            value={this.state.form.ddlAddressState}
                            options={this.state.getStatesResponse}
                            textNoOption="No se encontraron resultados."
                            onChange={(event, values) => {
                                this.syncAutoCompleteChanges(event, values, 'ddlAddressState');
                                this.changeAddressState(event, values);
                            }}
                            validator={this.validator}
                            validateOptions={'required'}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <CustomAutoComplete
                            id="ddlAddressCity"
                            label="Municipio *"
                            value={this.state.form.ddlAddressCity}
                            options={this.state.form.citiesAddress}
                            textNoOption="No se encontraron resultados."
                            onChange={(event, values) => {
                                this.syncAutoCompleteChanges(event, values, 'ddlAddressCity');
                            }}
                            validator={this.validator}
                            validateOptions={'required'}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <ButtonPrimary
                            text={this.state.editAddressStatus ? 'Actualizar' : 'Agregar'}
                            type="button"
                            loading={false}
                            onClick={(e) => this.onSaveAddress(e)}
                        />
                    </Grid>
                </Grid>
            </Fragment>

        );
    }


}

NewAddress.propTypes = {
    getAllCitiesAction: PropTypes.func.isRequired,
    saveAndressAction: PropTypes.func.isRequired,
    updateAddressAction: PropTypes.func.isRequired,
    getAndressListAction: PropTypes.func.isRequired,

    configReducer: PropTypes.object.isRequired,
    addressReducer: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    configReducer: state.configReducer,
    addressReducer: state.addressReducer
})

export default connect(mapStateToProps, {
    getAllCitiesAction,
    saveAndressAction,
    updateAddressAction,
    getAndressListAction

})(NewAddress);