import {
    SAVE_BILLER,
    GET_BILLERS,
    SAVE_SELLER,
    GET_SELLERS,
    UPDATE_BILLER,
    LOAD_BILLER,
    UPDATE_SELLER,
    LOAD_SELLER,
    UPDATE_STATUS_BILLER,
    UPDATE_STATUS_SELLER,
    GET_ERRORS,
    LOADING_SAVE_BILLER,
    LOADING_SAVE_SELLER,
    LOADING_GET_BILLERS,
    LOADING_GET_SELLERS
}
    from './types'
import { API_ENDPOINT_USER, DEFAULTRESPONSE, defaultHeaders } from "../config/config";
import axios from 'axios'
import { handleResponse, enterpriseIdHeader } from './authActions'
import { filterByOffice } from './basicDataActions';

var billers = [];
var filteredBillers = [];
var sellers = [];
var filteredSellers = [];

const urlUser = `${API_ENDPOINT_USER}/user/api`;


/** BEGIN USER BILLERS */
/**
 * Consulta listado de usuarios con rol facturador y aplica filtro
 * @param {*} filter Palabra para filtro
 * @param {*} refresh Estado, define nueva consulta a API
 */
export const getBillersAction = (filter = '', refresh = true) => async (dispatch) => {

    if (!refresh) {
        filteredBillers = filterListUsers(billers, filter);
        dispatch({
            type: GET_BILLERS,
            payload: filteredBillers
        })

    } else {
        try {
            changGetBillersLoading(dispatch, true);
            let config = defaultHeaders();
            Object.assign(config.headers, {
                'empresaId': `${enterpriseIdHeader()}`,
            });

            var response = await axios.get(`${urlUser}${'/user/GetUser'}`, config);

            if (response.data?.result !== null && response.data?.result !== undefined) {
                billers = response.data.result.map(item => {
                    return {
                        ...item,
                        nombreCompleto: `${item.nombres} ${item.apellidos}`
                    }
                });
            }


            filteredBillers = filterListUsers(billers, filter);
            dispatch({
                type: GET_BILLERS,
                payload: filteredBillers
            })


        } catch (err) {
            handleResponse(err.response)
            console.log("Error al obtener los usuarios de la empresa", err.response)
            const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
            dispatch({
                type: GET_ERRORS,
                payload: response,
                payload1: 'No se ha podido obtener los usuarios de la empresa'
            });
        } finally {
            changGetBillersLoading(dispatch, false);
        }
    }
}


/**
 * Actualiza estado loading conulta listado facturadores
 * @param {*} dispatch 
 * @param {boolean} status Nuevo estado loaging
 */
function changGetBillersLoading(dispatch, status) {
    dispatch({
        type: LOADING_GET_BILLERS,
        payload: status
    })
}


/**
 * Registra información de usuario facturador en BD
 * @param {object} body Información usuario
 */
export const saveBillerAction = (data) => async (dispatch) => {

    try {
        changeSaveBillerLoading(dispatch, true);
        data = {
            ...data,
            empresaid: `${enterpriseIdHeader()}`,
        }

        var response = await axios.post(`${urlUser}${'/user'}`, data, defaultHeaders());
        dispatch({
            type: SAVE_BILLER,
            payload: response.data
        })

    } catch (err) {
        handleResponse(err.response)
        console.log("Error al crear facturador", err)
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido crear facturador'
        });

    } finally {
        changeSaveBillerLoading(dispatch, false);
    }
}

/**
 * Actualiza información de usurio facturador
 * @param {*} data nueva información usurio
 */
export const updateBillerAction = (data) => async (dispatch) => {

    try {
        changeSaveBillerLoading(dispatch, true);
        data = {
            ...data,
            empresaid: `${enterpriseIdHeader()}`,
        }

        var response = await axios.put(`${urlUser}${'/user'}`, data, defaultHeaders());
        dispatch({
            type: UPDATE_BILLER,
            payload: response.data
        });

        clearLoadBiller(dispatch);

    } catch (err) {
        console.log("Error al actualizar el usuario", err.response)
        handleResponse(err.response)
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar el usuario'
        });

    } finally {
        changeSaveBillerLoading(dispatch, false);
    }
}


/**
 * Actualiza estado loading guardado o actualización usuario facturador
 * @param {*} dispatch 
 * @param {boolean} status Nuevo estado loaging
 */
function changeSaveBillerLoading(dispatch, status) {
    dispatch({
        type: LOADING_SAVE_BILLER,
        payload: status
    })
}


export const loadBillerAction = (data) => (dispatch) => {
    dispatch({
        type: LOAD_BILLER,
        payload: data
    })
}


function clearLoadBiller(dispatch) {
    dispatch({
        type: LOAD_BILLER,
        payload: null
    })
}


/**
 * Actualiza estado de usuario facturador
 * @param {*} id Identificador usuario
 * @param {*} status Identificador nuevo estado
 */
export const updateBillerStatusAction = (id, status) => async (dispatch) => {

    try {
        changeUpdateStateBillerLoading(dispatch, true, id);
        let config = defaultHeaders();
        Object.assign(config.headers, {
            'userId': id,
            'stateId': status,
        });

        var result = await axios.put(`${urlUser}${'/user/UpdateState'}`, null, config);
        let response = {
            ...result.data,
            id: id,
            status: status
        }

        dispatch({
            type: UPDATE_STATUS_BILLER,
            payload: response
        });

    } catch (err) {
        handleResponse(err.response)
        console.log("Error al actualizar el usuario", err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar usuario facturador.'
        });
    } finally {
        changeUpdateStateBillerLoading(dispatch, false, id);
    }
}


/**
 * Cambia estado loading para actualización de estado dentro del listado de facturadores
 * @param {object} dispatch 
 * @param {boolean} status  Estado loading
 */
function changeUpdateStateBillerLoading(dispatch, status, id) {

    let newData = filteredBillers.find(n => n.id === id);

    if (!!newData) {
        const index = filteredBillers.findIndex(e => e.id === id);
        let newArray = [...filteredBillers];
        newArray[index] = {
            ...newData,
            loading: status
        };
        filteredBillers = newArray;
    }

    dispatch({
        type: GET_BILLERS,
        payload: filteredBillers
    })
}


/**
 * Actualiza listado de facturadores despues de cambiar el 
 * estado de uno de estos activar e inactivar 
 * @param {int} id Identificador facturador
 * @param {int} status Nuevo estado del facturador
 */
export const refrehAfterUpdateBillerStatusAction = (id, status, filter) => async (dispatch) => {

    let newData = billers.find(n => n.id === id);

    if (!!newData) {
        const index = billers.findIndex(e => e.id === id);
        let newArray = [...billers];
        newArray[index] = { ...newData, idstate: status };
        billers = newArray;
    }

    filteredBillers = filterListUsers(billers, filter);
    dispatch({
        type: GET_BILLERS,
        payload: filteredBillers
    })
}


/** END USER BILLERS */



/** BEGIN USER SELLER */


/**
 * Consulta listado de vendedores y aplica filtro
 * @param {*} filter Filtro
 * @param {*} refresh Estado, Conuslta información a webAPI
 */
export const getSellersAction = (filter = '', refresh = true) => async (dispatch) => {


    if (!refresh) {
        filteredBillers = filterListUsers(sellers, filter);
        dispatch({
            type: GET_SELLERS,
            payload: filteredBillers
        })

    } else {
        try {
            changGetSellersLoading(dispatch, true);
            let config = defaultHeaders();
            Object.assign(config.headers, {
                'empresaId': `${enterpriseIdHeader()}`,
            });

            var response = await axios.get(`${urlUser}/seller/GetSeller`, config);

            if (response.data?.result !== null && response.data?.result !== undefined) {
                sellers = response.data.result.map(item => {
                    return {
                        ...item,
                        nombreCompleto: `${item.nombres} ${item.apellidos}`
                    }
                });
            }

            filteredSellers = filterListUsers(sellers, filter);
            dispatch({
                type: GET_SELLERS,
                payload: filteredSellers
            })

        } catch (err) {
            handleResponse(err.response);
            console.log("Error al obtener los vendedores de la empresa", err.response);
            const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
            dispatch({
                type: GET_ERRORS,
                payload: response,
                payload1: 'No se ha podido obtener listado de  vendedores de la empresa.'
            });
        } finally {
            changGetSellersLoading(dispatch, false);
        }
    }
}

/**
 * Actualiza estado loading consulta listado vendedores
 * @param {*} dispatch 
 * @param {boolean} status Nuevo estado loaging
 */
function changGetSellersLoading(dispatch, status) {
    dispatch({
        type: LOADING_GET_SELLERS,
        payload: status
    })
}

/**
 * Actualiza estado de usuario vendedor
 * @param {*} id  Identificador vendedor
 * @param {*} status Identificador nuevo estado vendedor
 */
export const updateSellerStatusAction = (id, status) => async (dispatch) => {

    try {
        changeUpdateStateSellerLoading(dispatch, true, id);
        let config = defaultHeaders();
        Object.assign(config.headers, {
            'sellerId': id,
            'stateId': status
        });

        var result = await axios.put(`${urlUser}${'/seller/UpdateState'}`, null, config);

        let response = {
            ...result.data,
            id: id,
            status: status
        }

        dispatch({
            type: UPDATE_STATUS_SELLER,
            payload: response
        });

    } catch (err) {
        handleResponse(err.response)
        console.log("Error al actualizar el vendedor", err.response)
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar el vendedor'
        });
    } finally {
        changeUpdateStateSellerLoading(dispatch, false, id);
    }
}


/**
 * Cambia estado loading para actualización de estado dentro del listado de facturadores
 * @param {object} dispatch 
 * @param {boolean} status  Estado loading
 */
function changeUpdateStateSellerLoading(dispatch, status, id) {

    let newData = filteredSellers.find(n => n.id === id);

    if (!!newData) {
        const index = filteredSellers.findIndex(e => e.id === id);
        let newArray = [...filteredSellers];
        newArray[index] = {
            ...newData,
            loading: status
        };
        filteredSellers = newArray;
    }

    dispatch({
        type: GET_SELLERS,
        payload: filteredSellers
    })
}

/**
 * Actualiza listado de vendedores despues de cambiar el 
 * estado de uno de estos activar e inactivar 
 * @param {int} id Identificador vendedor
 * @param {int} status Nuevo estado del facturador
 */
export const refrehAfterUpdateSellerStatusAction = (id, status, filter) => async (dispatch) => {

    let newData = sellers.find(n => n.id === id);

    if (!!newData) {
        const index = sellers.findIndex(e => e.id === id);
        let newArray = [...sellers];
        newArray[index] = { ...newData, idstate: status };
        sellers = newArray;
    }

    filteredSellers = filterListUsers(sellers, filter);
    dispatch({
        type: GET_SELLERS,
        payload: filteredSellers
    })

}

/**
 * Registra información de usuario vendedor en BD
 * @param {object} data información de usuario
 */
export const saveSellerAction = (data) => async (dispatch) => {

    try {
        changeSaveSellerLoading(dispatch, true);
        data = {
            ...data,
            empresaid: `${enterpriseIdHeader()}`,
        }
        var response = await axios.post(`${urlUser}${'/seller'}`, data, defaultHeaders());

        dispatch({
            type: SAVE_SELLER,
            payload: response.data
        })
    } catch (err) {
        handleResponse(err.response)
        console.log("Error al guardar  vendedor", err.response)
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido guardar el vendedor'
        });
    } finally {
        changeSaveSellerLoading(dispatch, false);
    }

}

/**
 * Actualiza información de usuario vendedor
 * @param {object} body Nueva información usuario vendedor
 */
export const updateSellerAction = (data) => async (dispatch) => {

    try {
        changeSaveSellerLoading(dispatch, true);

        var response = await axios.put(`${urlUser}${'/seller'}`, data, defaultHeaders());
        dispatch({
            type: UPDATE_SELLER,
            payload: response.data
        });

    } catch (err) {
        handleResponse(err.response)
        console.log("Error al actualizar el vendedor", err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar el vendedor'
        });
    } finally {
        changeSaveSellerLoading(dispatch, false);
    }
}


/**
 * Actualiza estado loading guardado o actualización usuario facturador
 * @param {*} dispatch 
 * @param {boolean} status Nuevo estado loaging
 */
function changeSaveSellerLoading(dispatch, status) {
    dispatch({
        type: LOADING_SAVE_SELLER,
        payload: status
    })
}

/**
 * CArga información de vendedor en Redux para edición
 * @param {*} data 
 */
export const loadSellerAction = (data) => (dispatch) => {
    dispatch({
        type: LOAD_SELLER,
        payload: data
    })
}


/** END USER SELLER */




/**
 * Filtra listado de ususarios por filtro
 * @param {*} list Listado usurios
 * @param {*} filter Filtro
 */
function filterListUsers(list, filter) {

    if (filter === '' || filter === null || filter === undefined)
        return list;

    const data = list.filter(x => {
        filter = filter.toLowerCase().trim();
        const nombreCompleto = x.nombreCompleto.toLowerCase().indexOf(filter);
        const nombres = x.nombres.toLowerCase().indexOf(filter);
        const apellidos = x.apellidos.toLowerCase().indexOf(filter);
        const correo = !!x.email ? x.email.toLowerCase().indexOf(filter) : -1;
        const telefono = !!x.telefono ? x.telefono.toLowerCase().indexOf(filter) : -1;
        const rol = !!x.rolid ? x.rolid === 1 || x.rolid === 2 ? 'Administrador'.toLowerCase().indexOf(filter) : 'Colaborador'.toLowerCase().indexOf(filter) : -1;
        const office = !!x.sucursalid ? filterByOffice(x.sucursalid, filter) : -1;

        if (nombreCompleto > -1)
            return true;
        else if (nombres > -1)
            return true;
        else if (apellidos > -1)
            return true;
        else if (correo > -1)
            return true;
        else if (telefono > -1)
            return true;
        else if (rol > -1)
            return true;
        else if (office > -1)
            return true;

    });

    return data;
}


export const fillListBillers = (listBillers) => {
    billers = listBillers
}

export const fillListSellers = (listSellers) => {
    sellers = listSellers
}



