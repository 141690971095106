import React, { Fragment, Component } from 'react';
import InvoiceGrid from '../invoice/invoice-grid.view';
import InvoiceCreate from '../invoice/invoice-create.view';
import InvoiceEdit from '../invoice/invoiceEdit.view';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import "../../App.css";
import '../../styles/invoice.css';
import CustomTabs from "../../components/tabs/tabs.component";
import { cleanErrorAction } from '../../actions/generalAction'



class Invoice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: {},
      showCreate: {display : 'none'},
      showGrid: {display : 'block'}, 
      showEdit: {display: 'none'} ,
      objHead: null,
      editInvoiceStatus: false,
    }    
  }   
  
  empresaId(){
    return JSON.parse(localStorage.getItem('dataCompany')).companyId;
  }  
  userId(){
    //return 'f0f45843-405b-4e9d-8703-3714e5f151db';
    return '';
  }
       
  componentDidMount() {
    this.props.cleanErrorAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.generalReducer.error & nextProps.generalReducer.error !== state.error) {
      update.error = nextProps.generalReducer.error;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this.props.cleanErrorAction();
  }

  handleBack = () => {
    this.setState({ 
      ...this.state, 
      showGrid: { display: 'block' }, 
      showCreate: { display: 'none' }, 
      showEdit: { display: 'none'} ,
      editInvoiceStatus: false,
    });
  };


  handleCreate = () => {
    this.setState({ 
      ...this.state, 
      showGrid: { display: 'none' }, 
      showCreate: { display: 'block' }, 
      showEdit: { display: 'none'},
      editInvoiceStatus: false,
    
    });
  };


  handleEdit = (objHead) => {
    this.setState({ 
      ...this.state, 
      showGrid: { display: 'none' }, 
      showCreate: { display: 'none' }, 
      showEdit: { display: 'block'},
      objHead: objHead,
      editInvoiceStatus: true,
    });    
  };


  tabs = [
    {
      title: 'Emitidas',
      component: <InvoiceGrid HandleCreate={this.handleCreate} HandleEdit={this.handleEdit} Change={Math.random()} Empresaid={this.empresaId()} Userid={this.userId()} Editada={false} />
    },
    {
      title: 'Recibidas',
      component: ''
    },
    {
      title: 'Editadas',
      component: <InvoiceGrid HandleCreate={this.handleCreate} HandleEdit={this.handleEdit} Change={Math.random()} Empresaid={this.empresaId()} Userid={this.userId()} Editada={true} />
    }
  ];

  render() {
    return (
      <Fragment>
        <div className="container-form" style={this.state.showGrid} >
          <CustomTabs tabs={this.tabs} />
        </div>
        <div style={this.state.showCreate}>
          <InvoiceCreate HandleBack={this.handleBack} Empresaid={this.empresaId()} Userid={this.userId()}></InvoiceCreate>
        </div>
        <div style={this.state.showEdit}>
          <InvoiceEdit Empresaid={this.empresaId()} Userid={this.userId()} objInvoice={this.state.objHead} editInvoiceStatus={this.state.editInvoiceStatus} ></InvoiceEdit>
        </div>
      </Fragment>
    );
  }


}

Invoice.propTypes = {
  cleanErrorAction: PropTypes.func.isRequired,
  generalReducer: PropTypes.object.isRequired,


}

const mapStateToProps = state => ({
  generalReducer: state.generalReducer
})

export default connect(mapStateToProps, {
  cleanErrorAction
})(Invoice);