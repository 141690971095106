export const regexEmail = /[^-_.@A-Za-z0-9]+/g;
export const regexPhone = /[^-+()0-9\s]+/g;
export const regexOnlyNumbers = /[^0-9]+/g;
export const regexAlphaNumeric = /[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]+/g;
export const regexDecimal = /[^0-9\\.]+/g;
export const regexPercentage = /[((100(\.0{1,2})?)|(\d{1,2}(\.\d{1,2})?))]+/g;
export const regexTwoDecimal = /[^0-9]+([^.0-9]{1,3})?$/g;
export const regexSpace = /[^[A-Za-z][A-Za-z0-9]]+/g
export const regexNames = /[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ._&-:,;$#\s]+/g;
export const regexLettersAndNumbers = /[^a-zA-Z0-9]+/g;
