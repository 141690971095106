/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Checkbox from "@material-ui/core/Checkbox";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Tooltip } from '@material-ui/core';

import "../GrandList/GridList.component.css";
import { URL_IMAGES } from '../../../../config/config.js';

const useStyles = makeStyles((theme) => ({

  root: {
    width: "100%",
    '& .MuiGridListTile-tile': {
      borderRadius: 10
    },
  },

  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  '& .MuiCheckbox-root': {
    color: 'white',
    padding: 5
  },
  styleCheck: {
    color: theme.palette.primaryColor
  },

}));

const useStylesItem = makeStyles((theme) => ({
  root: {
    height: '100%',
    border: '0.3px solid #ffffff17',
    borderRadius:'10px',
    '& .MuiGridListTileBar-title': {
      fontWeight: 600
    },
    '& .MuiGridListTileBar-subtitle': {
      fontWeight: 600
    }
  },
  rootSelected: {
    height: '100%',
    borderRadius:'10px',
    '& .MuiGridListTileBar-title': {
      fontWeight: 600
    },
    '& .MuiGridListTileBar-subtitle': {
      fontWeight: 600
    },
    '& .MuiGridListTile-tile':{
      border: '1.5px solid #16B1F3',
      boxShadow: '0px 0px 6px 1px #16b1f38c',
    }

  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  '& .MuiCheckbox-root': {
    color: 'white',
    padding: 5
  },
  styleCheck: {
    color: theme.palette.primaryColor
  },
  categoryStyle: {
    borderRadius: 10,
    width: '80%',
    height: 8,
    marginTop: 5,
    marginBottom: 5
  }


}));

const CheckboxCustom = withStyles({
  root: {
    "& .MuiCheckbox-colorPrimary": {
      backgroundColor: "green",
      color: "red",

    },
    '& .MuiCheckbox-root': {
      color: 'white'
    },
    "& .MuiTab-textColorSecondary": {
      backgroundColor: "green",
      color: "red",
    },
    "&.Mui-checked": {
      color: "#16B1F3",
    },
  },
})(Checkbox);


const ItemList = ({ ...props }) => {
  const classes = useStylesItem();
  const [checked, setChecked] = React.useState(false);
  const [localdata, setLocalData] = React.useState({});
  const { onSelectArticle, onChange, data } = props;

  useEffect(() => {

    if (!!data && data !== localdata) {
      setLocalData(data);
      setChecked(data.checked);
    }

  }, [data, localdata, props.data])



  const handleCheck = (e) => {
    setChecked(e.target.checked);
  }


  const subNameArticle = (name) => {
    return name ? name.substring(0, 2).toUpperCase() : "";
  };


  return (

    <GridListTile
      className={checked === true ? classes.rootSelected : classes.root}
      spacing={5}>
      <Tooltip title="Ver detalle...">
        <div className="containerGrid"
          onClick={(e) => onSelectArticle(e, data)}
          style={{
            background: `${data.imagen === null || data.imagen === undefined || data.imagen === '' ?
              data.color :
              null}`,
            cursor: 'pointer',
          }}>
          <img src={data.imagen === null || data.imagen === undefined || data.imagen === '' ? '' : URL_IMAGES + data.imagen}
            alt={''}
            style={{ borderRadius: 10 }}
            onClick={(e) => onSelectArticle(e, data)}
          />


          {
            data.imagen === null || data.imagen === undefined || data.imagen.length === 0 ?
              <span className='span'>{subNameArticle(data.nombrearticulo)}</span> : ''
          }
        </div>
      </Tooltip>
      <GridListTileBar
        title={data.nombrearticulo}
        subtitle={
          <Fragment>
            <div
              className={classes.categoryStyle}
              style={{
                backgroundColor: !!data?.color ? data.color : '',
              }} >
            </div>
            <div>
              {`$ ${data.valor.toLocaleString('en')}`}
            </div>
          </Fragment>
        }
        actionIcon={
          <CheckboxCustom
            className={classes.styleCheck}
            onChange={
              (e) => {
                onChange(e, data);
                handleCheck(e)
              }}
            checked={checked}
          />
        }
        actionPosition="right"
      >
      </GridListTileBar>
    </GridListTile>
  )

}




const GrandList = ({ ...props }) => {
  const classes = useStyles();
  const { options, onSelectArticle, onChange } = props;


  //Identifica tamño de la pantalla
  const getGridListCols = () => {
    if (isWidthUp('xl', props.width))
      return 5;

    if (isWidthUp('lg', props.width))
      return 4;

    if (isWidthUp('md', props.width))
      return 3;

    return 2;
  }


  return (
    <GridList
      className={classes.root}
      cellHeight={260}
      spacing={10}
      cols={getGridListCols()}>

      {options.map((data, index) =>
        <div key={index}>
          <ItemList
            key={index}
            data={data}
            onSelectArticle={(e) => onSelectArticle(e, data)}
            onChange={onChange}
          />
        </div>

      )}

    </GridList>

  );
};
export default withWidth()(GrandList);

