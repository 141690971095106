import { GET_ERRORS, SET_INDEX_SELECTED } from './types';

/**
  * get list of access Menu from Database
  *
  * @method GET
  * @param {*}
  */

export const getMenuAction = () => async (dispatch) => {};


/**
 * @method
 * @description Limpia error generado por consumo de apis
 */
export const cleanErrorAction = () => dispatch => {


  dispatch({
    type: GET_ERRORS,
    payload: {}
  })

}

export const setIndexSelectedAction = (index) => dispatch => {
  dispatch({
    type: SET_INDEX_SELECTED,
    payload: index
  })

}

