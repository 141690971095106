/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { URL_IMAGES } from '../../../config/config.js';

import Tooltip from '@material-ui/core/Tooltip';
import { Grid, IconButton } from "@material-ui/core/";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonPrimary from '../../../components/button/buttonPrimary.component'
import CustomProgress from '../../../components/Progress/progress.component'

import { updateFavoriteStatusAction } from '../../../actions/articleActions'

import isEmpty from '../../../utils/isEmpty'
import { getRoleOfLoggedUser } from "../../../actions/authActions";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-colorSecondary': {
            color: theme.palette.primaryColor
        },
        width: '100%',
        overflowY: 'auto'
    },
    nameStyle: {
        color: theme.palette.fourthColor,
        fontSize: '22px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        lineHeight: '26px'
    },
    totalStyle: {
        color: theme.palette.secundaryColor,
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '26px'
    },
    comunStyle: {
        color: '#9e9e9e',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '26px'
    },
    whiteStyle: {
        color: theme.palette.primaryColor,
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '26px'
    },
    greenStyle: {
        color: theme.palette.secundaryColor,
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '26px'
    },
    tag: {
        width: 100,
        borderRadius: 10,
        color: theme.palette.primaryColor,
        fontWeight: 600,
        fontSize: 12,
        marginRight: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 1,
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center'

    },

    cover: {
        color: theme.palette.primaryColor,
        height: '210px',
        borderRadius: 10,
        width: '95%',
        border: '0.3px solid #ffffff17',
        position: 'relative'
    },
    image: {
        height: '210px',
        borderRadius: 10,
        width: '95%'
    },
    titleImage: {
        position: 'absolute',
        textAlign: "center",
        color: theme.palette.primaryColor,
        top: '40%',
        left: '40%',
        fontSize: '4vh',
        fontWeight: 600
    },
    divider: {
        width: '100%',
        borderBottom: '1px solid gray',
        display: 'inline-grid'
    },
    styleContainer: {
        backgroundColor: theme.palette.fourthBackgroundColor,
        fontSize: '18px',
        padding: '5px 15px',
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        color: theme.palette.primaryColor
    },

    styleActions: {
        backgroundColor: theme.palette.fourthBackgroundColor,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    icon: {
        color: theme.palette.primaryColor
    },
    list: {
        listStyleType: 'none',
        paddingInlineStart: 0
    },
    iconChecked: {
        color: theme.palette.primaryColor
    },
    progress: {
        textAlign: 'center',
        width: '100%'
    },
    button: {
    },
    label: {
        flexDirection: 'column',
        fontSize: 10,
        color: theme.palette.primaryColor
    },
    formCheck: {
        margin: 0,
        '& .MuiIconButton-root': {
            padding: 0
        },

    },
    '& .MuiFormControlLabel-root': {
        margin: 0
    }
}));



const DetailArticle = ({ ...props }) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const [localdata, setLocalData] = React.useState({});
    const { data, onSelectItem, onLoadItem, onDeleteItem, loadingFavorite } = props;


    useEffect(() => {

        if (!!data && data !== localdata) {
            setLocalData(data);
            setChecked(data.favorito === true ? true : false);
        }

    }, [data, localdata, props.data])

    const subNameArticle = (name) => {
        return name ? name.substring(0, 2).toUpperCase() : "";
    };

    const handleChecked = (event) => {
        const value = event.target.checked;
        setChecked(value);
        props.updateFavoriteStatusAction(data.id, value);
    }


    return (
        <div className={classes.root}>
            <Grid container style={{ padding: '0px 20px' }}>
                <Grid items lg={5} xs={5}>
                    {isEmpty(data.imagen) ?
                        <div
                            style={{
                                background: data.color
                            }}
                            className={classes.cover}>
                            <span className={classes.titleImage} >{subNameArticle(data.nombrearticulo)}</span>
                        </div>
                        :
                        <img
                            height={'100%'}
                            width={'100%'}
                            className={classes.image}
                            src={URL_IMAGES + data.imagen}
                            alt={''}
                        />
                    }
                </Grid>
                <Grid items lg={7} xs={7}>
                    <span className={classes.nameStyle} >{data.nombrearticulo.toLocaleString('en')}</span>
                    <br />
                    <br />

                    <span className={classes.totalStyle}>{`$ ${data.total.toLocaleString('en')}`}</span>
                    <br />

                    <span className={classes.comunStyle}>
                        {'Precio editable: '}
                        <span className={classes.whiteStyle}>{data.precioeditable === true ? 'SI' : 'NO'}</span>
                    </span> <br />

                    <span className={classes.comunStyle}>
                        {'IVA: '}
                        <span className={classes.greenStyle} >{data.valoriva.toLocaleString('en')}%</span>
                    </span>  &nbsp;&nbsp;

                    <span className={classes.comunStyle}>
                        {'INC: '}
                        <span className={classes.greenStyle} >{data.valorinc.toLocaleString('en')}%</span>
                    </span>
                    <br />
                    <span className={classes.divider} ></span>
                    <br />
                    <div style={{ display: 'flex' }}>
                        {data.adcategoriaarticulo.map((category, index) => (
                            <div
                                key={index}
                                className={classes.tag}
                                style={{ backgroundColor: category?.categoria?.color }}>
                                {category?.categoria?.nombre}
                            </div>
                        ))}
                    </div>

                </Grid>

                <Grid item xs={12} lg={12} className={classes.styleContainer}>
                    {data.descripcion}
                </Grid>

                <Grid item xs={12} lg={12} className={classes.styleContainer}>
                    <ul className={classes.list}>
                        <li>
                            <span className={classes.comunStyle} >{'- Valor Neto: '}</span>
                            <span className={classes.greenStyle}>  {`$ ${data.neto.toLocaleString('en')}`}</span>
                        </li>
                        <li>
                            <span className={classes.comunStyle} >{'- Costo: '}</span>
                            <span className={classes.greenStyle}> {!!data.costo ? `$ ${data.costo.toLocaleString('en')}` : '$ 0.00'}</span>
                        </li>
                        <li>
                            <span className={classes.comunStyle} >{'- Unidad de medidad: '}</span>
                            <span className={classes.whiteStyle}> {'UND'}</span>
                        </li>
                        <li>
                            <span className={classes.comunStyle} >{'- Referencia: '}</span>
                            <span className={classes.whiteStyle}> {data.codigo}</span>
                        </li>
                        <li>
                            <span className={classes.comunStyle} >{'- Codigo de barras: '}</span>
                            <span className={classes.whiteStyle}> {data.codigobarras}</span>
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <br></br>

            <Grid container className={classes.styleActions}>
                {loadingFavorite === true
                    ? <div className={classes.progress}>
                        <CustomProgress />
                    </div>
                    : <Fragment>
                        <Grid item lg={6} xs={6} style={{ textAlign: 'center' }}>
                            <ButtonPrimary
                                text={"Añadir a la factura"}
                                loading={false}
                                type='button'
                                onClick={(e) => onSelectItem(e, data)}
                            />
                        </Grid>
                        {
                            (getRoleOfLoggedUser())
                                ? <Fragment>
                                    <Grid item lg={2} xs={2} style={{ textAlign: 'center' }}>
                                        <Tooltip title="No tiene permisos sobre esta acción">
                                            <IconButton
                                                classes={{ root: classes.button, label: classes.label }}
                                                variant="raised"
                                                disableRipple={true} >
                                                <DeleteIcon className={classes.icon} />
                                                {'Eliminar'}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item lg={2} xs={2} style={{ textAlign: 'center' }}>
                                        <Tooltip title="No tiene permisos sobre esta acción">
                                            <FormControlLabel
                                                style={{ margin: 0 }}
                                                control={
                                                    <Checkbox
                                                        name="checkedF"
                                                        checked={checked}
                                                        className={classes.formCheck}
                                                        icon={
                                                            <IconButton
                                                                classes={{ root: classes.button, label: classes.label }}
                                                                variant="raised"
                                                                disableRipple={true}>
                                                                <StarBorderIcon className={classes.icon} />
                                                                {'Destacar'}
                                                            </IconButton>}
                                                        checkedIcon={
                                                            <IconButton
                                                                classes={{ root: classes.button, label: classes.label }}
                                                                variant="raised"
                                                                disableRipple={true}>
                                                                <StarIcon className={classes.iconChecked} />
                                                                {'Destacar'}
                                                            </IconButton>
                                                        }
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item lg={2} md={2} style={{ textAlign: 'center' }}>
                                        <Tooltip title="No tiene permisos sobre esta acción">
                                            <IconButton
                                                classes={{ root: classes.button, label: classes.label }}
                                                variant="raised"
                                                disableRipple={true} >
                                                <EditIcon className={classes.icon} />
                                                {'Editar'}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Fragment>
                                : <Fragment>
                                    <Grid item lg={2} xs={2} style={{ textAlign: 'center' }}>
                                        <IconButton
                                            classes={{ root: classes.button, label: classes.label }}
                                            variant="raised"
                                            disableRipple={true}
                                            onClick={(e) => onDeleteItem(e, data)} >
                                            <DeleteIcon className={classes.icon} />
                                            {'Eliminar'}
                                        </IconButton>
                                    </Grid>
                                    <Grid item lg={2} xs={2} style={{ textAlign: 'center' }}>
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <Checkbox
                                                    name="checkedF"
                                                    checked={checked}
                                                    onChange={handleChecked}
                                                    className={classes.formCheck}
                                                    icon={
                                                        <IconButton
                                                            classes={{ root: classes.button, label: classes.label }}
                                                            variant="raised"
                                                            disableRipple={true}>
                                                            <StarBorderIcon className={classes.icon} />
                                                            {'Destacar'}
                                                        </IconButton>}
                                                    checkedIcon={
                                                        <IconButton
                                                            classes={{ root: classes.button, label: classes.label }}
                                                            variant="raised"
                                                            disableRipple={true}>
                                                            <StarIcon className={classes.iconChecked} />
                                                            {'Destacar'}
                                                        </IconButton>
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={2} style={{ textAlign: 'center' }}>
                                        <IconButton
                                            classes={{ root: classes.button, label: classes.label }}
                                            variant="raised"
                                            disableRipple={true}
                                            onClick={(e) => onLoadItem(e, data)} >
                                            <EditIcon className={classes.icon} />
                                            {'Editar'}
                                        </IconButton>
                                    </Grid>
                                </Fragment>
                        }
                    </Fragment>
                }

            </Grid>

        </div>
    )
}

DetailArticle.propTypes = {
    updateFavoriteStatusAction: PropTypes.func.isRequired,

    articleReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    articleReducer: state.articleReducer

});

export default connect(mapStateToProps, {
    updateFavoriteStatusAction
})(DetailArticle);

