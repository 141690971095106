import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const SuccessAlert = (title, message) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        icon: 'success',
        title: <p>{title}</p>,
        text: message,
        background:'#000000'    
    })
}

export const WarningAlert = (title, message) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        icon: 'warning',
        title: <p>{title}</p>,
        text: message,
        background:'#000000'    
    })
}

export const ErrorAlert = (title, message) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        icon: 'error',
        title: <p>{title}</p>,
        text: message,
        width: 600,
    })
}

export const ConfirmAlert = (title, message, callback) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        title: <p>{title}</p>,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        background:'#000000'        
    }).then(function (confirmed) {
        callback(confirmed && confirmed.value === true)
    })
}


export const LoadMassive = (title, message) => {
    var el = document.createElement('span'),
        t = document.createTextNode("Custom HTML Message!!");
    el.style.cssText = 'color:#F6BB42';
    el.appendChild(t);
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html:
            '<font size="5" color="#00BFFF">Cargue masivo de artículos</font>' +
            '<p align="left"><font color="white"> Bienvenido al cargue masivo, aquí podras cargar toda la información  de varios artículos al mismo tiempo, solo debes segir estas instrucciones:</font></p>, ' +
            '<p align="left"><font color="white" ><font color="#00BFFF" >1.</font> Descarga la plantilla de cargue, deberas completarla con la información solicitada de cada artículo que desea subir;por favor, no la modifiques ni cambies el nombre del archivo.</font></p>' +

            '<p align="left"><font  color="white"><font color="#00BFFF" >2.</font> Sube la plantilla con la información diligenciada mediante el botón "Cargar Plantilla Masiva", nosotros haremos el resto...tu,tranquilo.</font></p>' +

            '<p align="left"><font color="white"><font color="#00BFFF" >3.</font> Listo!!!, La plataforma te mostrará el resultado del cargue, por favor verifica que todo quede en orden. </font></p>'
        ,
        showCancelButton: true,
        confirmButtonColor: '#00BFFF',
        cancelButtonColor: '#00BFFF',
        confirmButtonText: 'Descargar Plantilla',
        cancelButtonText: 'Cargar Plantilla Masiva',
        padding: '3em',
        background: '#2E2E2E',
        showCloseButton: true,
        position: 'center-right',
        backdrop: `

        rgba(0,0,0,0.4)
        left top
        no-repeat`,
        width: '80%'

    }).then((result) => {
        if (result.value) {
            return true;
        }
    })
}

export const ShowTemplateAlert = (title, src) => {
    var el = document.createElement('span'),
        t = document.createTextNode("Custom HTML Message!!");
    el.style.cssText = 'color:#F6BB42';
    el.appendChild(t);
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        html:
            '<font size="5" color="#00BFFF">' + title + '</font>' +
            '<p><img src="' + src + '" width="83%" height="83%"/></p>'
        ,
        confirmButtonColor: '#00BFFF',
        confirmButtonText: 'Cerrar',
        padding: '0em',
        background: '#2E2E2E',
        showCloseButton: true,
        position: 'center',
        backdrop: `

        rgba(0,0,0,0.4)
        left top
        no-repeat`,
        width: '30%'

    }).then((result) => {
        if (result.value) {
            return true;
        }
    })
}


