import React from 'react';
import { useHistory } from 'react-router-dom';

const Document = (props) => {
  const history = useHistory();
  const redirect  = props.history.location.state.redirect;
 // console.log('story-->' + redirect );
  //history.push('/invoice');
  
  if (redirect !== undefined){
    if(redirect === 1){
      history.push('/invoice');
    }
    if(redirect === 2){
      history.push('/quotation');
    }
  }
  else{
    history.push('/invoice');
  }
  

  return(
    <div>
        Inicio
    </div>
  );
  
}

export default Document;