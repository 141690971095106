import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { getRoleOfLoggedUser } from '../../../actions/authActions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.thirdBackgroundColor,
        color: theme.palette.primaryColor,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: 55
        }
    },
    avatar: {
    },
    header: {
        color: theme.palette.primaryColor,
        paddingBottom: 0,
        paddingTop: 0
    },
    title: {
        color: theme.palette.primaryColor,
    },
    icon: {
        color: theme.palette.secundaryColor
    },
    iconAction: {
        color: theme.palette.primaryColor
    },
    content: {
        fontSize: 14,
        color: theme.palette.primaryColor
    },
    text: {
        color: theme.palette.primaryColor
    },
    valueText: {
        color: '#EC005F',
        fontWeight: 500
    }
}));

export default function ManageChargeCard(props) {
    const classes = useStyles();
    const { data, onLoad, onDelete } = props;

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={
                    <AddToPhotosIcon fontSize="small" className={classes.icon} />
                }
                action={
                    <Fragment>
                        {
                            (getRoleOfLoggedUser())
                                ? <Tooltip title="No tiene permisos sobre esta acción">
                                    <IconButton aria-label="Editar">
                                        <CreateIcon className={classes.iconAction} />
                                    </IconButton>
                                </Tooltip>
                                : <Tooltip title="Editar">
                                    <IconButton aria-label="Editar" onClick={onLoad}>
                                        <CreateIcon className={classes.iconAction} />
                                    </IconButton>
                                </Tooltip>
                        }
                        <Tooltip title="Eliminar">
                            <IconButton aria-label="Eliminar" onClick={onDelete} >
                                <DeleteIcon className={classes.iconAction} />
                            </IconButton>
                        </Tooltip>
                    </Fragment>
                }
                classes={{
                    title: classes.title,
                }}
                title={data.descripcion}

            />
            <CardContent className={classes.content}>
                {data.tipocargo === 1 ?
                    <span className={classes.valueText}>$ {data.valorasumar.toLocaleString('en')}</span>
                    :
                    <span>
                        <span>{data.valor.toLocaleString('en')}%</span> &nbsp;
                        <span className={classes.valueText}>($ {data.valorasumar.toLocaleString('en')})</span>
                    </span>
                }
            </CardContent>
        </Card>
    );
}
