import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { format } from "date-fns";
import SimpleReactValidator from "simple-react-validator";

import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EventIcon from '@material-ui/icons/Event';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import InputForm from "../../../components/input/input.component";
import DateForm from "../../../components/datepicker/datepicker.component";
import { getInvoices, updateDocument, getDocument, sendInvoice, getTotalDocs } from "../../../actions/invoiceActions";
import {addChargeToInvoiceAction, openModalChargeAction, addDiscountToInvoiceAction, setStatusShowCartAction, calculeTotalNote} from "../../../actions/valuesInvoiceAction";
import { SuccessAlert, WarningAlert } from '../../../helpers/alert.helpers';
import QuotationDetails from '../../invoice/quotation/quotationDetails.view';
import CustomProgress from '../../../components/Progress/progress.component';
import SimpleModal from '../../../components/modal/simpleModal.component';
import PopoverModal from '../../../components/modal/popoverModal.component';
import StyledCheckbox from '../../../components/checkForm/styledCheckbox.component';
import ButtonSecundary from '../../../components/button/buttonSecundary.component';
import { regexEmail } from '../../../helpers/customRegex.hepers';
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginBottom: 5,
    height: 90
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 14,
  },
  showLayer :{
    display: 'block'
  },
  hideLayer:{
      display: 'none'
  },
  letter: {
      color: '#FFFFFF'
  },
  letterSearch: {
    color: '#848484'
},
  alignRight:{
    textAlign: "right"
  },
  alignLeft:{
    textAlign: "left"
  },
  alignCenter:{
    textAlign: "center",
  },
  link:{
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center"
  },
  widthMax:{
    width: "100%"
  },
  paddingIcons: {
    paddingTop: "20%"
  },
  width200:{
    width: "200px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position:"absolute",
    padding: "1%",
  },
  width340:{
    width: "340px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position:"absolute",
    padding: "1%",
  },
  width400:{
    width: "400px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position:"absolute",
    padding: "1%",
  }
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const QuotationGrid = (props) => {
  const classes = useStyles();
  const { HandleCreate, invoices, document, totaldocs, Empresaid, HandleEdit, HandleBill,Type} = props;
  const windowsHeight = parseInt((window.innerHeight) * 0.58, 10);
  const heightDiv = windowsHeight + 'px';
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = React.useState(null);
  const [openModalMail, setOpenModalMail] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [selectedId, setSelectedId] = useState({
    Id: 0,
    Cambio: 0
  });
 const basepages = 30; //definido en el documento
  const [filters, setFilters] = useState({
    Fechini: "",
    Fechfin: "",
    Vencidas: false,
    Destacadas: false,
    Empresaid: Empresaid,
    Numero: "",
    Cambio: 0,
    Id: 0,
    Ini: 1,
    Fin: basepages,
    Diantipodocumentoelectronicoid: 4,
    Tipoaceptaciondocumentoid:Type
  });
  const [datesTmp, setDatesTmp] = useState({
    Fechini: format(new Date(), 'MM-dd-yyyy'),
    Fechfin: format(new Date(), 'MM-dd-yyyy'),
  });
  const [emails, setEmails] = useState([]);
  const [activateMail, setActivateMail] = useState(
    {
      Activo: false,
      Cambio: 0
    }
  )
  const [numberLocalInvoice, setNumberLocalInvoice] = useState("");
  const [updatesDocument, setUpdatesDocument] = useState({
    Operacion: "",
    Favorito: false,
    Pagado: false,
    Id: 0
  });
  const [loading, setLoading] = useState({
    Loading:false,
    SelectId: 0
  });
  const validator = new SimpleReactValidator(reactValidatorOptions);
  const filterImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC";
  

  const [numberInvoice, setNumberInvoice] = useState("");

  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);
  };

  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  const handleOpenModalDates = (event) => {
    setModalDatesAnchorEl(event.currentTarget);
  };

  const handleCloseModalMail = () => {
    setOpenModalMail(false);
  };

  const handleOpenModalMail = () => {
    setOpenModalMail(true);
  };

  const handleCloseModalFile = () => {
    setOpenModalFile(false);
  };

  const handlecheckFilters = (event) => {

    let countInactive = 0;
    if (!filters.Vencidas)
      countInactive++;
    if (!filters.Destacadas)
      countInactive++;
    
    if (countInactive>=3 && event.target.checked === false) //si se desactivan todos los check
    {
      cleanFilter();
    }
    else{     

      if (event.target.name === "chkVencidas")
        setFilters((prevState) => ({ ...prevState, Vencidas: event.target.checked, Id: 0, Ini:0, Fin:0 }));

      if (event.target.name === "chkDestacadas")
        setFilters((prevState) => ({ ...prevState, Destacadas: event.target.checked, Id: 0, Ini:0, Fin:0 }));
    }


    
  }
  const cleanFilter = () => {
    setFilters((prevState) => ({
      ...prevState,
      Vencidas: false,
      Destacadas: false,
      Id: 0,
      Ini: 1,
      Fin: basepages
    }));
    handleCloseModalChecks();
  }

  const handleDate = (id, date) => {

    //console.log('date'+ date);
    if (id === "txtfechini") {
      setDatesTmp((prevState) => ({ ...prevState, Fechini: new Date(date).toISOString() }));
    }
    if (id === "txtfechfin") {
      setDatesTmp((prevState) => ({ ...prevState, Fechfin: new Date(date).toISOString() }));
    }
    //console.log(datesTmp);
  }

  const filterDate = () => {

    if (datesTmp.Fechini === '' || datesTmp.Fechifin === '') {
      WarningAlert('Debe seleccionar un rango de fechas valido');
    }
    else {
      if (new Date(datesTmp.Fechini) > new Date(datesTmp.Fechfin)) {
        WarningAlert('La fecha inicial no puede ser mayor a la final');
      }
      else {
        setFilters((prevState) => ({
          ...prevState,
          Fechini: datesTmp.Fechini,
          Fechfin: datesTmp.Fechfin,
          Id: 0,
          Ini: 0,
          Fin: 0 
        }));
        handleCloseModalDates();
      }
    }
  }

  const cleanFilterDate = () => {

    setDatesTmp((prevState) => ({ ...prevState, Fechini: format(new Date(), 'MM-dd-yyyy'), Fechfin: format(new Date(), 'MM-dd-yyyy') }));
    setFilters((prevState) => ({
      ...prevState,
      Fechini: "",
      Fechfin: "",
      Id: 0,
      Ini: 1,
      Fin: basepages
    }));
    handleCloseModalDates();
  }

  const handleNumber = (e) => {
    let number = e.target.value;    
    setNumberLocalInvoice(number); 
    if (number.length>= 4){     
      setNumberInvoice(number);       
    }
    else{
      if (numberInvoice !== ''){
        setNumberInvoice('');         
      }      
    }
  }

  const handleMail = (e) => {
    e.preventDefault();
    validator.hideMessages();
    let arrMail = [];   
    let regex = regexEmail;
    if (e.target.value !== "") {
      arrMail[0] = e.target.value;
      arrMail[0] = arrMail[0].replace(regex, '');      
    }    
    //alert(arrMail[0]);
    setEmails(arrMail);
  }

  const SendMails = (e) => {

  e.preventDefault();
    if (validator.allValid()) {
      if (emails.length === 0) {
        handleCloseModalMail();
        WarningAlert('Debe digitar un correo valido');
        //handleOpenModalMail();
      }
      else {
        setActivateMail({
          Activo: true,
          Cambio: Math.random()
        });
      }
    }
    else{
      WarningAlert('correo no valido');
      validator.showMessages();
      //forceUpdate();
    }   
  }

  const cleanMail = () => {
    handleCloseModalMail();
    setEmails([]);
    setActivateMail({
      Activo: false,
      Cambio: 0
    });
  }

  const openMailModal = (mail) => {
    let arrMail = [];
    arrMail[0] = mail;
    setEmails(arrMail);
    setOpenModalMail(true);
  }

  const openFileModal = () => { 
    setOpenModalFile(true);
  }

  const filterNumber = () => {
    if (numberInvoice !== "") {
      setFilters((prevState) => ({
        ...prevState,
        Numero: numberInvoice,
        Id: 0,
        Ini: 0,
        Fin: 0
      }));
    }
    else {
      setFilters((prevState) => ({
        ...prevState,
        Numero: "",
        Id: 0,
        Ini: 1,
        Fin: basepages
      }));
    }
  }

  const handleFavourite = (action, id) => {

    //console.log("action" + action + " id " + id);
    setUpdatesDocument((prevState) => ({
      ...prevState,
      Id: id,
      Operacion: "FAVORITO",
      Vencidas: false,
      Favorito: action
    }));
  }

  const updateGridAuto = () => {
    //console.log('llego aqui-->update grid' + updatesDocument.Id);
    handleIdChange(updatesDocument.Id);    

  } 

  const handleIdChange = (id) => {
    setLoading({
      Loading: true,
      SelectId: id
    });
    setFilters((prevState) => ({
      ...prevState,
      Id: id,
      Cambio: Math.random()
    }));
  }  

  useEffect(() => {
    props.getTotalDocs(Empresaid,4);
    props.getInvoices(filters, handleDetailsDocument);
    
  }, [filters]);

  useEffect(() => {
    if (updatesDocument.Id !== 0) {
      props.updateDocument(updatesDocument, updateGridAuto);

    }
  }, [updatesDocument]);

  useEffect(() => {
    if (emails.length > 0) {
      SuccessAlert("Envio de cotización realizado");
      props.sendInvoice({ "Correos": emails });
      cleanMail();
    }
  }, [activateMail])  

  useEffect(() => {
    filterNumber();
  }, [numberInvoice])

  //Enevto click nueva o editar Factura
  const openCreate = () => {
    HandleCreate();
  }

  const openEditComponent = (objHead) => {
    HandleEdit(objHead)
  }

  const openBillComponent = (objHead) => {
    HandleBill(objHead)
  }

  const openEdit = (invoice, type) => {
    

    
    let contacts=[];
    let articles=[];
    let charge=[];
    let discount=[];
    let categories=[];
    let countArticles = 0;
    let dirMain=[];
    let dirOthers=[];

    //console.log('objeto a enviar fuete'+JSON.stringify(invoice.adclientedirecciones))
    

    if (invoice.adclientedirecciones.length>0){
        invoice.adclientedirecciones.forEach(item => {

            if (item.principal){
              dirMain.push({
                "descripcion":item.descripcion,
                "clienteid":invoice.clienteid,
                "ciudadid":item.ciudadid,
                "direccion":item.direccion,
                "principal":true,
                "id":item.id,
                "idstate":0,
                "createdby":0,
                "createdon":"1900-01-01",
                "modifiedby":0,
                "modifiedon":"1900-01-01",
                "idbusinessunit":0,
                "idowner":0,
                "ciudaddescripcion":item.nombmun,
                "departamentoid":item.depid,
                "departamentodescripcion":item.nombdep
              });
            }
            else{
              dirOthers.push({
                "descripcion":item.descripcion,
                "clienteid":invoice.clienteid,
                "ciudadid":item.ciudadid,
                "direccion":item.direccion,
                "principal":item.principal,
                "id":item.id,
                "idstate":0,
                "createdby":0,
                "createdon":"1900-01-01",
                "modifiedby":0,
                "modifiedon":"1900-01-01",
                "idbusinessunit":0,
                "idowner":0,
                "ciudaddescripcion":item.nombmun,
                "departamentoid":item.depid,
                "departamentodescripcion":item.nombdep
              }); 
            }
      });
    }
 

    if (invoice.adclientecontactos!== null){
        if (invoice.adclientecontactos.length>0){
          invoice.adclientecontactos.forEach(item => {
            contacts.push(
            {
              id: item.id, 
              nombre: item.nombre, 
              telefono: item.telefono, 
              celular: item.celular, 
              email: item.email, 
              clienteid: item.clienteid, 
              idstate:0, 
              createdby:0, 
              modifiedby:0,
              idbusinessunit:0, 
              idowner:0
            });
          });
      }
    }
    if (invoice.opdetalledocumento !== null){
      if(invoice.opdetalledocumento.length>0){
           invoice.opdetalledocumento.forEach(item => {
          countArticles++;      
          categories=[];
          
          if (item.adcategoriaarticulo !== null){
            if (item.adcategoriaarticulo.length>0){
                item.adcategoriaarticulo.forEach(cat => {
                    categories.push({
                      categoriaid: cat.id,
                      articuloid: item.id,
                      articulo: null,
                      categoria:{
                        nombre: cat.nombre,
                        color: cat.color,
                        imagen: null, 
                        activo:false,
                        favorita:false,
                        id:cat.id,
                        idstate:0,
                        createdby:1,
                        createdon:cat.createdon,
                        modifiedby:1,
                        modifiedon:cat.modifiedon,
                        idbusinessunit:0,
                        idowner:0,
                        articulos:null
                      }
                    })
                });
              }  

          }
            

          articles.push({
            identificador:item.id,
            codigo:item.codigo, 
            descripcion: item.descripcion,
            imagen: item.imagen,
            color:item.colorimagen,
            cantidad: item.cantidad,
            valorunitario:item.valorunitario,
            valorNeto:item.valorunitario, //
            valoreditable:false, //
            porcentajedescuento:item.porcentajedescuento,
            valordescuento:item.totaldescuento,
            tipodescuento:item.tipodescuento, 
            dianunidadmedidaid:item.dianunidadmedidaid,
            articuloid:item.articuloid,
            subtotal:item.subtotalini,
            totaldescuento:item.totaldescuento,
            totaliva:item.totaliva,
            totalinc:item.totalinc,
            total:item.total,
            documentoid:item.id,
            categorias:categories, 
            porcentajeiva:item.valoriva, //
            porcentajeinc:item.valorinc, //
            valorbruto:item.total,
            valordescuentocomercial: item.valordescuentocomercial //
           });
        });
        
      }
    }    

    //if (invoice)
    
    let objHead = {
          documentId : invoice.id,
          numeroFactura : invoice.numero,
          visibleCredit: invoice.dianformapagoid === 2 ? true : false,
          visibleCounted : invoice.dianformapagoid === 2 ? false : true, 
          dateInvoice: invoice.fecha,
          datePay: invoice.fechavigencia,
          paymethodid: invoice.dianmediopagoid === null ? 0 : invoice.dianmediopagoid,
          includeVendor: invoice.incluirvendedor, 
          clientInfo: {
              documento: invoice.clientedocumento,  
              documentodv: invoice.clientedv,
              razonsocial: invoice.clienterazon,
              telefono: invoice.clientetelefono,
              correoelectronico: invoice.clientecorreo,
              diantipoentidadid: 0,
              diantipopersonaid: invoice.diantipopersonaid,
              empresaid: invoice.empresaid,
              favorito: false,
              diantiporegimenid: invoice.diantiporegimenid,
              adclientedireccion: dirOthers,
              adcontactos: contacts,
              id: invoice.clienteid,
              idstate: 0,
              createdby: 0,
              createdon: '1900-01-01',
              modifiedby: 0,
              modifiedon: '1900-01-01',
              idbusinessunit: 0,
              idowner: 0,
              direccionid: dirMain[0].id,
              direccion: dirMain[0].direccion,
              ciudadid: dirMain[0].ciudadid,
              ciudaddescripcion: dirMain[0].ciudaddescripcion,
              departamentoid: dirMain[0].departamentoid,
              departamentodescripcion:dirMain[0].departamentodescripcion,
              text: '',
              diantipodocumentoidentidadid: invoice.diantipodocumentoidentidadid,
          },  
          vendorid: invoice.vendedorid === null ? '' :invoice.vendedorid,
          branchofficeid: invoice.sucursalid,
          resolutionid:  invoice.numeracionid === 0 ? null : invoice.numeracionid,
          addressid: invoice.direccionclienteid,
          contactid: invoice.contactoclienteid,
          observations: invoice.textoobservacion === null ? '' : invoice.textoobservacion, 
          favorite : invoice.favorito,   
          valueoptions: 0,
          Resolution:{
            id: invoice.numeracionid, 
            resolucion: invoice.resolucion,  
            fechainicio: invoice.fechainicio, 
            fechafinal: invoice.fechafinal, 
            prefijo: invoice.prefijo, 
            consecinicial: invoice.consecinicial, 
            consecfinal: invoice.consecfinal, 
            consecactual: invoice.consecactual, 
            diantipomodalidadid: 0, 
            sucursalempresaid: invoice.sucursalid, 
            nombre: invoice.nombrenumeracion, 
            descripcion: ''
          },
          Vendor:{
            id: invoice.vendedorid === null ? '' :invoice.vendedorid, 
            nombres: invoice.nombresvendedor === null ? null : invoice.nombresvendedor, 
            documento: '', 
            telefono: '', 
            email: ''
          },    
          observationid:0,
          rangeDate: 0,
          othersPayMethod: false,  
          dirprincipal: dirMain
        }
    //
    
    //localStorage.removeItem('itemsInCart');
    //localStorage.setItem('itemsInCart', JSON.stringify(articles))
    if (invoice.opdocumentocargos !== null){
      if(invoice.opdocumentocargos.length>0){

        invoice.opdocumentocargos.forEach(item => {
          charge.push({
            id:item.cargoid,
            identificador:item.identificador,
            descripcion:item.descripcion,
            tipocargo:item.tipocargo,
            valor:item.valor,
            esglobal:item.descripcion === "Bolsas" ? true : item.esglobal,
            cantidad:item.cantidad,
            valorcalculado:item.valorcalculado,
            valorasumar:item.valorasumar
           });
           //g("charge"+ JSON.stringify(charge));
           //props.addChargeToInvoiceAction(charge);
        });
        
      }
    }

    if (invoice.opdocumentodescuentos !== null){
      if(invoice.opdocumentodescuentos.length>0){

        invoice.opdocumentodescuentos.forEach(item => {
          discount.push({
            motivoid: item.motivoid,
            motivoDescripcion: item.motivo,
            id: item.descuentoid,
            identificador:item.identificador,
            descripcion:item.descripcion, 
            modelodescuento:item.modelodescuento,              
            tipodescuento:item.tipodescuento,
            valor:item.valor,
            valoradescontar: item.valoradescontar   
           });
           //props.addDiscountToInvoiceAction(discount);
        });
        
      }
    }
    //console.log('numeracion'+JSON.stringify(objHead.resolutionid));   
   // props.setStatusShowCartAction(true); 
   props.calculeTotalNote(articles, charge, discount);    
    if (type === 1){
      openEditComponent(objHead);
    }
    else{
      openBillComponent(objHead);  
    }
    
  }

  const pad = (input, length, padding) => {
    var str = input + "";
    return (length <= str.length) ? str : pad(padding + str, length, padding);
  }
  const cardsFacturas = () => {
    if (invoices !== undefined) {
      const cards = invoices.map((facturas) =>
        <div className={selectedId.Id === facturas.id ? 'cardDetailInvoiceSelected' : 'cardDetailInvoice'}>
          <Grid container >
            <Grid item lg={9} md={9} sm={12} className="nameCostumer" onClick={() => handleIdChange(facturas.id)}>
            {facturas.razonsocial }
            </Grid>           
            <Grid item lg={3} md={3} sm={12} className={classes.alignRight}>
              <div className={ loading.Loading && loading.SelectId === facturas.id? 'panelVisibleInvoice' :'panelInvisibleInvoice'}>
              <Fragment><CustomProgress /></Fragment>
              </div>
              <div className={ loading.Loading && loading.SelectId === facturas.id ? 'panelInvisibleInvoice' :'panelVisibleInvoice'}>
              <div className={facturas.favorito === true ? classes.showLayer: classes.hideLayer}>
                <StarIcon onClick={() => handleFavourite(false, facturas.id)} ></StarIcon>
              </div>
              <div className={facturas.favorito === false ? classes.showLayer: classes.hideLayer}>
                <StarBorderIcon onClick={() => handleFavourite(true, facturas.id)} ></StarBorderIcon>
              </div>
              </div>              
            </Grid>
          </Grid>
          <Grid container onClick={() => handleIdChange(facturas.id)}>
            <Grid item lg={6} md={6} sm={12} className="dataInvoiceGrid">
              {loading.Loading && loading.SelectId === facturas.id? '' : new Date(facturas.fecha).getFullYear()+'-'+pad((new Date(facturas.fecha).getMonth() + 1), 2, "0")+'-'+pad(new Date(facturas.fecha).getDate(), 2, "0")+' '+pad(new Date(facturas.fecha).getHours(), 2, "0")+':'+pad(new Date(facturas.fecha).getMinutes(), 2, "0")}              
            </Grid>
            <Grid item lg={6} md={6} sm={12} className={selectedId.Id === facturas.id ? 'totalInvoiceGridSelected' : facturas.estadocot === 'Vencida' ? 'totalInvoiceGridExpired' : 'totalInvoiceGridPaidout'}>
              {loading.Loading && loading.SelectId === facturas.id? '' :'$'+new Intl.NumberFormat('es-CO').format(facturas.valortotal)}
            </Grid>
          </Grid>
          <Grid container onClick={() => handleIdChange(facturas.id)}>         
          <Grid item lg={6} md={6} sm={12} className="dataNumberInvoice">
               {loading.Loading && loading.SelectId === facturas.id? '' :'Cotización: '+facturas.numero}
            </Grid>            
            <Grid item lg={6} md={6} sm={12} className={selectedId.Id === facturas.id ? 'stateInvoiceSelected' :facturas.estadocot === 'Vencida' ? 'stateInvoiceExpired' : 'stateInvoicePaidout'}>
              {loading.Loading && loading.SelectId === facturas.id? '' :facturas.estadocot}
            </Grid>                
          </Grid>
        </div>
      );
      return cards;
    }
  }

  const loadMoreInvoices = (newIni, newFin) =>{
    setFilters((prevState) => ({ ...prevState, Ini: newIni, Fin: newFin }));
    //handleLoadMore();
  }

  const handleDetailsDocument = (id) => {   
    setLoading({
      Loading: false,
      SelectId: 0
    });

    setSelectedId((prevState) => ({
      ...prevState,
      Id: filters.Id === 0 ? id : filters.Id,
      Cambio: Math.random()
    }));    
    
  } 

  const modalMails = () => {
    return(
      <form onSubmit={(e) => SendMails(e)} style={{width:"100%"}}>
        <div>
          <Grid container>   
            <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
              Enviaremos la cotización al siguiente correo electrónico, puedes modificarlo si lo requieres
            </Grid>  
            <Grid item lg={12} md={12} sm={12} xs={12}>                
              <InputForm
                  id={"txtEmail"}
                  label={"Correo electrónico *"}
                  maxLength={50}
                  value={emails[0]}
                  onChange={(e) => handleMail(e)}                  
                  validator={validator}
                  className={classes.letter} 
                  validateOptions={"required|email|min:10|max:50"}
              />
            </Grid>
          </Grid>
          <br></br>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} className={classes.alignCenter} >          
              <ButtonSecundary
                        text={'Enviar'}
                        loading={false}
                        type={'submit'}
                        />
            </Grid>
          </Grid>
        </div>
      </form>      
    );
  }

  const modalFilters = () =>{
    return(
      <div>
      <Grid container justify="flex-start" alignItems="center">
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox className={classes.letter} 
            checked={filters.Vencidas}
            onChange={handlecheckFilters}
            name="chkVencidas"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Vencidas
        </Grid>
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox className={classes.letter} onClick={() => handlecheckFilters}
            checked={filters.Destacadas}
            onChange={handlecheckFilters}
            name="chkDestacadas"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Destacadas
        </Grid>
        <Grid item lg={12} md={12} sm={12} className={classes.link}>
          <Link href="#" onClick={cleanFilter} variant="inherit" underline="none" className={classes.link}>
            Limpiar Filtro
          </Link>
        </Grid>
      </Grid>
    </div>
    );
  }
  
  const modalDates = () =>{
    return(
      <div>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={6} className={classes.alignCenter}>        
           Fecha Inicio:
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <DateForm
            id={"txtfechini"}
            value={datesTmp.Fechini}
            onChange={handleDate}
            disableFuture={true}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} className={classes.alignCenter}>   
          Fecha Fin:
        </Grid>
        <Grid item lg={6} md={6} sm={6} >
          <DateForm
            id={"txtfechfin"}
            value={datesTmp.Fechfin}
            onChange={handleDate}
            disableFuture={true}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={6} md={6} sm={6} className={classes.alignCenter}>
        <ButtonSecundary
                        text={'Limpiar'}
                        loading={false}
                        onClick={cleanFilterDate}/>
        </Grid>
        <Grid item lg={6} md={6} sm={6} className={classes.alignCenter} >        
          <ButtonSecundary
                        text={'Consultar'}
                        loading={false}
                        onClick={filterDate}/>
        </Grid>
      </Grid>
    </div>
    );
  }

  return (
    <div className={classes.letter}>      
      <div className="createInvoice" onClick={openCreate}>      
        <Grid container>        
          <Grid item lg={6} md={6} sm={12} className={classes.alignRight}>
            Crear nueva cotización
         </Grid>
          <Grid item lg={6} md={6} sm={12} className={classes.alignLeft}>
          &nbsp;&nbsp;&nbsp;<AddCircleRoundedIcon></AddCircleRoundedIcon>
          </Grid>
        </Grid>
      </div>
      <div className="spacingInvoice"></div>
      <Grid container spacing={1}>
        <Grid item lg={5} md={6} sm={12}>
          <div className="spacingInvoice">
            <Grid container>
              <Grid item lg={10} md={10} sm={12}>
                <FormControl className={classes.widthMax}  >
                  <InputLabel>Buscar</InputLabel>
                  <Input
                    id="standard-adornment-invoice"
                    type='text'
                    onChange={handleNumber}
                    defaultValue={numberLocalInvoice}
                    className="letterTextSearch"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"                          
                        >
                          <SearchIcon className={classes.letterSearch}></SearchIcon>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={2} md={2} sm={12}>
                <Grid container spacing={2} className={classes.paddingIcons}>
                  <Grid item>
                  <div className="spacingInvoiceMini"></div>
                    <EventIcon className="buttonInvoiceBehavior" onClick={handleOpenModalDates}></EventIcon>
                  </Grid>
                  <Grid item>
                  <div className="spacingInvoiceMini"></div>
                    <div className="buttonInvoiceBehavior" onClick={handleOpenModalChecks}><img alt={'Filtrar'} src={filterImage}></img></div> 
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div style={{ overflow: 'auto', height: heightDiv }}>       
            {cardsFacturas()}            
                  <div className={filters.Fin >= parseInt(totaldocs) || filters.Ini===0 || invoices.length<basepages ? 'panelInvisibleInvoice': 'panelVisibleInvoice panelCentered' }>
                    <button className="buttonFilter" onClick={() => loadMoreInvoices((filters.Ini), (filters.Fin+5))}>Cargar más</button></div>
                    <div className={invoices.length===0 ? 'panelVisibleInvoice' : 'panelInvisibleInvoice'}>No se encontraron cotizaciones</div>
          </div>         
        </Grid>
        <Grid item lg={7} md={6} sm={12}>
          <QuotationDetails 
          ObjId={selectedId} 
          heightDiv={heightDiv} 
          vista="Grid" 
          functionDetails={handleIdChange} 
          OpenMailModal={openMailModal} 
          OpenFileModal={openFileModal}
          OpenEdit={openEdit}
          ></QuotationDetails>
        </Grid>
      </Grid>
      <PopoverModal
                    handleClose={handleCloseModalChecks}
                    open={Boolean(modalChecksAnchorEl)}
                    component={modalFilters}
                    width={191} 
                    popoverAnchorEl={modalChecksAnchorEl}
                    showCloseButton={false}
                />                 
      <PopoverModal
                    handleClose={handleCloseModalDates}
                    open={Boolean(modalDatesAnchorEl)}
                    component={modalDates}
                    title={'Filtro por fechas'}
                    width={292}
                    popoverAnchorEl={modalDatesAnchorEl}
                    showCloseButton={false}
                />   
      <SimpleModal
                    onClose={handleCloseModalMail}
                    open={openModalMail}
                    component={modalMails}
                    title={'Enviar Cotización'}
                    width={365}
                    showCloseButton={false}
                />     
    </div>
  );
}


const mapStateToProps = (state) => ({
  invoices: state.invoiceReducer.invoices,
  sucessupdate: state.invoiceReducer.sucess,
  document: state.invoiceReducer.document,
  sendinvoice: state.invoiceReducer.sendmail,
  totaldocs: state.invoiceReducer.totaldocs
});

const mapDispatchToProps = {
  getInvoices,
  updateDocument,
  getDocument,
  sendInvoice,
  getTotalDocs,
  setStatusShowCartAction,
  addChargeToInvoiceAction,
  openModalChargeAction,
  addDiscountToInvoiceAction,
  calculeTotalNote
};
export default connect(mapStateToProps, mapDispatchToProps)(QuotationGrid);