import {
    GET_NUMERATIONS,
    GET_NUMERATION,
    UPDATE_NUMERATION,
    SAVE_NUMERATION,
    GET_MODALITY_TYPES,
    SYNC_GET_NUMERATION,
    UPDATE_NUMERATION_STATE,
    LOAD_NUMERATION,
    GET_FILTER_NUMERATIONS,
    STATUS_SYNC_NUMERATION,
    LOADING_UPDATE_NUMERATION,
    LOADING_SAVE_NUMERATION,
    LOADING_GET_NUMERATIONS
} from "../actions/types"

import isEmpty from '../utils/isEmpty'

const initialState = {
    loadingSyncNumerations: false,
    loadingUpdateState: false,
    loadingGetNumerations: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_NUMERATION:
            return {
                ...state,
                saveNumerationResponse: action.payload,
            }
        case GET_NUMERATIONS:
            return {
                ...state,
                numerations: action.payload,
                filteredNumerations: action.payload1,        
            }
        case GET_NUMERATION:
            return {
                ...state,
                numeration: action.payload
            }
        case UPDATE_NUMERATION:
            return {
                ...state,
                updateNumerationResponse: action.payload,
            }
        case UPDATE_NUMERATION_STATE:
            return {
                ...state,
                updateNumerationStateResponse: action.payload,
            }
        case GET_MODALITY_TYPES:
            return {
                ...state,
                modalities: action.payload,
            }
        case LOAD_NUMERATION:
            return {
                ...state,
                loadedNumeration: action.payload,
                editNumerationStatus: !isEmpty(action.payload),
            }
        case GET_FILTER_NUMERATIONS:
            return {
                ...state,
                getFilterNumerations: action.payload,
            }
        case SYNC_GET_NUMERATION:
            return {
                ...state,
                syncNumerationResponse: action.payload,
            }
        case STATUS_SYNC_NUMERATION:
            return {
                ...state,
                loadingSyncNumerations: action.payload
            }
        case LOADING_UPDATE_NUMERATION:
            return {
                ...state,
                loadingUpdateState: action.payload
            }
        case LOADING_SAVE_NUMERATION:
            return {
                ...state,
                loadingSaveNumeration: action.payload
            }
        case LOADING_GET_NUMERATIONS:
            return{
                ...state,
                loadingGetNumerations: action.payload
            }
        default:
            return state
    }
}