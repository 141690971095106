import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { withStyles } from '@material-ui/styles'

import { Create, ExpandMore } from "@material-ui/icons"
import Card from '@material-ui/core/Card'
import { connect } from 'react-redux'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'


import SwitchForm from '../switch/switch.component.js';
import { updteNumerationStateAction } from '../../actions/numerationActions'
import { formatDate } from "../../utils/general.js"
import {WarningAlert } from "../../helpers/alert.helpers.js"


const useStyles = theme => ({
    root: {
        backgroundColor: theme.palette.thirdBackgroundColor,
        color: theme.palette.primaryColor,
        fontWeight: 300
    },
    header: {
        alignItems: 'normal',
        paddingBottom: 0,
    },
    headerContent: {
        textOverflow: 'ellipsis',
    },
    title: {
        color: theme.palette.primaryColor,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    typography: {
        fontSize: 16,
        lineHeight: '24px'
    },
    resolution: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '26px'
    },
    numerationName: {
        fontSize: 18,
        fontStyle: "oblique",
        lineHeight: '26px'
    },
    iconModality: {
        width: 'auto'
    },
    icon: {
        color: theme.palette.secundaryColor
    },
    content: {
        paddingTop: 0,
        paddingLeft: 58,
    },
    content2: {
        paddingTop: 0,
        paddingLeft: 62,
    },
    content3: {
        paddingTop: 0,
        paddingLeft: 54,
    },
    actions: {
        justifyContent: "flex-end",
        padding: 'unset'
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
})

export class CustomCardNumeration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalities: [],
            sucursales: [],
            active: false,
            expanded: false,
            data: {},
            loading: false
        }
    }


    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.numerationReducer.modalities) {
            if (nextProps.numerationReducer.modalities !== state.modalities) {
                update.modalities = nextProps.numerationReducer.modalities
            }
        }

        if (!!nextProps.data && nextProps.data !== state.data) {
            update.data = nextProps.data;
            update.loading = nextProps.data?.loading === true ? true : false;
        }
        
        if (!!nextProps.branchOfficeReducer.branchOfficesList && nextProps.branchOfficeReducer.branchOfficesList !== state.sucursales) {
            update.sucursales = nextProps.branchOfficeReducer.branchOfficesList
        }

        return Object.keys(update).length ? update : null
    }

    /**
     * Evento, escucha cambio en checkbox de estado, actualiza estado de numeración
     * @param {*} e Evento
     * @param {*} value Nuevo valor
     */
    syncCheckChange = (e, value) => {
        let currentDate = new Date();
        let formatVigencia = new Date(this.state.data.fechafinal);
        if (formatVigencia > currentDate && parseInt(this.state.data.consecactual) < parseInt(this.state.data.consecfinal)) {
            const checked = e.target.checked
            let valorcheck = checked === true ? 0: 1;
            let data = {
                idnumeracion: this.state.data.id,
                idstate: valorcheck
            }
            this.props.updteNumerationStateAction(data)
        } else {
            WarningAlert('Upss...!', 'No se puede activar una numeración no vigente.')
        }
    };

    /**
     * Muesta información adicional de numeración
     */
    handleExpandClick = () => {
        this.setState({
            expanded: this.state.expanded === true ? false : true
        })

    };

    render() {
        const classes = this.props.classes;
        let icon = ""
        switch (this.state.data.diantipomodalidadid) {
            case 1:
                break
            case 2:
                icon = <img className={classes.iconModality} src={require('./../../images/computerIcon.png')} alt={"computerIcon"} />
                break
            case 3:
                icon = <img className={classes.iconModality} src={require('./../../images/electronicInvoiceIcon.png')} alt={"electronicInvoiceIcon"} />
                break
            case 4:
                icon = <img className={classes.iconModality} src={require('./../../images/posIcon.png')} alt={"posIcon"} />
                break
            case 5:
                icon = <img className={classes.iconModality} src={require('./../../images/contingencyIcon.png')} alt={"contingencyIcon"} />
                break
            default:
                break
        }

        return (

            <Card className={classes.root}>
                <CardHeader
                    className={classes.header}
                    avatar={
                        <i id="icon-numeration" className="material-icons large">
                            {icon}
                        </i>
                    }
                    action={
                        <IconButton aria-label="settings" disabled={this.props.loading}  onClick={(e) => this.props.onClick(e, this.state.data)} >
                            <Create className={classes.icon}/>
                        </IconButton>
                    }
                    classes={{
                        title: classes.title,
                        content: classes.headerContent
                    }}
                    title={
                        <Fragment>
                            <Typography className={classes.typography} variant="body1">
                                <Fragment>
                                    <span className={classes.resolution}>{this.state.data.resolucion}<br /></span>
                                    {!!this.state.data.nombre && (
                                        <Fragment>
                                            <span className={classes.numerationName}>{this.state.data.nombre} <br /></span>
                                        </Fragment>
                                    )}
                                    Modalidad: {this.state.modalities.length > 0 ?
                                        this.state.modalities.find(element => element.id === this.state.data.diantipomodalidadid).descripcion
                                        : ''}<br />
                                    Vigencia: {formatDate(this.state.data.fechafinal, 'dd/MM/yyyy')}<br />
                                </Fragment>
                            </Typography>
                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                <Typography variant="body1" className={classes.typography}>
                                    <Fragment>
                                        Prefijo: {!!this.state.data.prefijo ? this.state.data.prefijo : 'Sin prefijo'}<br />
                                        Rango inicial: {this.state.data.consecinicial}<br />
                                        Rango final: {this.state.data.consecfinal}<br />
                                        Consecutivo actual: {this.state.data.consecactual}<br />
                                        Fecha de formalización: {formatDate(this.state.data.fechainicio, 'dd MMM yyyy')}<br />
                                        Sucursal: {this.state.sucursales.length > 0 && this.state.data.sucursalempresaid !== null ?
                                            this.state.sucursales.find(element => element.id === this.state.data.sucursalempresaid).nombre
                                            : 'Sin sucursal asignada'}<br />
                                    </Fragment>
                                </Typography>
                            </Collapse>
                        </Fragment>
                    }
                />
                <CardContent className={this.state.data.diantipomodalidadid === 2 ? classes.content2 : this.state.data.diantipomodalidadid === 3 ? classes.content3 : classes.content}>
                    <SwitchForm
                        name="idstate"
                        titleOn={this.state.data.idstate === 0 ? "Desactivar numeración" : "Activar numeración"}
                        checked={this.state.data.idstate === 0 ? true : false}
                        withoutMargin={true}
                        onChange={(e) => this.syncCheckChange(e)}
                        value={this.state.data.idstate === 0 ? true : false}
                        loading={this.state.loading}/>
                </CardContent>

                <CardActions className={classes.actions}>
                    <IconButton
                        onClick={() => this.handleExpandClick()}
                        aria-expanded={this.state.expanded} >
                            <Typography variant="caption" className={classes.icon}>{this.state.expanded ? 'Ver menos' : 'Ver más'}</Typography>
                            <ExpandMore className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.expanded
                            }, classes.icon)} />
                    </IconButton>
                </CardActions>
            </Card>
        )
    }
}

CustomCardNumeration.propTypes = {
    updteNumerationStateAction: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    branchOfficeReducer: state.branchOfficeReducer,
    numerationReducer: state.numerationReducer,
})

export default connect(mapStateToProps, {
    updteNumerationStateAction,
})(withStyles(useStyles)(CustomCardNumeration))


