import { API_ENDPOINT_ARTICLE, DEFAULTRESPONSE, API_IBUHOO_IMAGEMANAGER } from "../config/config";
import {
  GET_UNIT_MEASURE,
  SAVE_ARTICLE,
  LOAD_ARTICLE,
  UPDATE_ARTICLE,
  SUCCES_DELETE_ARTICLE,
  SEND_INDEX_TAB,
  GET_ERRORS,
  LOADING_GET_ARTICLES,
  GET_ARTICLE_LIST,
  GET_ARTICLE_CHECKED,
  LOADING_UPDATE_ARTICLE,
  LOADING_SAVE_ARTICLE,
  UPDATE_FAVORITE_ARTICLE,
  GET_TOTAL_DETAIL,
  GET_DOCUMENT_DETAIL
} from "./types";
import axios from "axios";
import isEmpty from "../utils/isEmpty";
import { QUANTITY_DATA, defaultHeaders } from '../config/config.js';

import { handleResponse, enterpriseIdHeader } from './authActions';
import { addArticlesToCart } from '../actions/valuesInvoiceAction'


var allArticles = [];
var totalArticles = 0;
var checkedArticles = [];


/**
 * Obtiene listado unidades de medida
 */
export const getUnitMeasureAction = () => async (dispatch) => {

  try {
    var result = await axios.get(`${API_ENDPOINT_ARTICLE}${"/item/api/unidadmedida"}`);

    let response = {
      statusCode: '200',
      StatusMessage: "Registro exitoso",
      result: result.data.map(item => {
        return {
          ...item,
          value: item.id,
          text: item.descripcion
        }
      }),
    };

    dispatch({
      type: GET_UNIT_MEASURE,
      payload: response,
    });

  } catch (err) {
    handleResponse(err.response);
    console.log("Error consultando listado unidades de medida,", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido obtener listado unidades de medida'
    });
  }

};


/**
 * Realiza consumo de web api para registro de articulo y categorias asociadas
 * @param {object} data
 */
export const createArticleAction = (data) => async (dispatch) => {
  changeSaveLoading(dispatch, true);

  var resultSaveImage = "", urlimagen = "";
  try {
    //Se llama al microservicio de imagenes
    if (!!data.imagen) {
      if (data.imagen !== data.imgArticleSrcOld) {

        resultSaveImage = await axios.post(`${API_IBUHOO_IMAGEMANAGER}${"/imagemanager/api/image"}`,
          {
            "ApplicationName": "ColFactura",
            "Extension": ".png",
            "ImageBase64": data.imagen.replace("data:image/png;base64,", "")
          }
          , defaultHeaders());

        urlimagen = resultSaveImage.data.result.relativePath;
        data = {
          ...data,
          imagen: urlimagen,
        }
      }
    }
  }
  catch (err) {
    //Puede fallar el servicio, pero se debe crear el articulo
    data = {
      ...data,
      imagen: "",
    }
  }
  finally {
    //Se crea el articulo
    try {
      data = {
        ...data,
        idowner: 1,
        createdby: 1,
        modifiedby: 1,
        empresaid: `${enterpriseIdHeader()}`,
      }

      var result = await axios.post(`${API_ENDPOINT_ARTICLE}${"/item/api/articulo/CreateArticle"}`, data, defaultHeaders())

      dispatch({
        type: SAVE_ARTICLE,
        payload: result.data,
      });

    } catch (err) {
      console.log("Error guardando articulo,", !!err.response ? err.response : err);
      handleResponse(err.response);
      const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
        type: GET_ERRORS,
        payload: response,
        payload1: 'No se ha podido registrar articulo'
      });
    }
    finally {
      changeSaveLoading(dispatch, false);
    }
  }
}


/**
 * Registra información articulo y lo agrega al carrito si el registro es exitoso
 * @param {object} data Infromación articulo
 */
export const createAndAddArticleAction = (data) => async (dispatch) => {
  changeSaveLoading(dispatch, true);

  var resultSaveImage = "", urlimagen = "";

  try {
    //Se llama al microservicio de imagenes
    if (!!data.imagen) {
      if (data.imagen !== data.imgArticleSrcOld) {

        resultSaveImage = await axios.post(`${API_IBUHOO_IMAGEMANAGER}${"/imagemanager/api/image"}`,
          {
            "ApplicationName": "ColFactura",
            "Extension": ".png",
            "ImageBase64": data.imagen.replace("data:image/png;base64,", "")
          }
          , defaultHeaders());

        urlimagen = resultSaveImage.data.result.relativePath;
        data = {
          ...data,
          imagen: urlimagen,
        }
      }
    }
  }
  catch (err) {
    //Puede fallar el servicio, pero se debe crear el articulo
    data = {
      ...data,
      imagen: "",
    }
  }
  finally {
    //Se crea el articulo
    try {
      data = {
        ...data,
        idowner: 1,
        createdby: 1,
        modifiedby: 1,
        empresaid: `${enterpriseIdHeader()}`,
      }

      var result = await axios.post(`${API_ENDPOINT_ARTICLE}${"/item/api/articulo/CreateArticle"}`, data, defaultHeaders());

      dispatch({
        type: SAVE_ARTICLE,
        payload: result.data,
      });

      if (result.data.statusCode === '201') {
        let configItem = defaultHeaders();
        Object.assign(configItem.headers, {
          'pId': result.data.result
        });

        var resultItem = await axios.get(`${API_ENDPOINT_ARTICLE}/item/api/articulo/GetArticleById/`, configItem);
        let items = [];
        items.push(resultItem.data.result);
        let totals = addArticlesToCart(items);


          //Actualiza items en Redux
          dispatch({
            type: GET_DOCUMENT_DETAIL,
            payload: totals.detalleDocumento,
        });


        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });
      }
    } catch (err) {
      console.log("Error guardando articulo,", !!err.response ? err.response : err);
      handleResponse(err.response);
      const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
        type: GET_ERRORS,
        payload: response,
        payload1: 'No se ha podido registrar articulo'
      });
    }
    finally {
      changeSaveLoading(dispatch, false);
    }
  }
}


/**
 * Realiza consumo de web api para actualizacion de articulo y categorias asociadas
 * @param {object} data
 */
export const updateArticleAction = (data) => async (dispatch) => {
  changeSaveLoading(dispatch, true);
  //Se llama al microservicio de imagenes
  var resultSaveImage = "", urlimagen = "";
  try {

    if (!!data.imagen) {
      if (data.imagen !== data.imgArticleSrcOld) {
        //Al actualizar si se envia el RelativePath para que la imagen anterior sea eliminada
        resultSaveImage = await axios.post(`${API_IBUHOO_IMAGEMANAGER}${"/imagemanager/api/image"}`,
          {
            "ApplicationName": "ColFactura",
            "Extension": ".png",
            "RelativePath": data.imgArticleSrcOld,
            "ImageBase64": data.imagen.replace("data:image/png;base64,", "")
          }
          , defaultHeaders());

        urlimagen = resultSaveImage.data.result.relativePath;
        data = {
          ...data,
          imagen: urlimagen,
        }
      }
    }
  }
  catch (err) {
    //Puede fallar el servicio, pero se debe actualizar el articulo
    data = {
      ...data,
      imagen: data.imgArticleSrcOld,
    }
  }
  finally {
    //Se actualiza el articulo
    try {
      data = {
        ...data,
        idowner: 1,
        createdby: 1,
        modifiedby: 1,
        empresaid: `${enterpriseIdHeader()}`,
      }

      var result = await axios.post(`${API_ENDPOINT_ARTICLE}${"/item/api/articulo/UpdateArticle"}`, data, defaultHeaders())

      dispatch({
        type: UPDATE_ARTICLE,
        payload: result.data,
      });

    } catch (err) {
      console.log("Error actualizando articulo,", !!err.response ? err.response : err);
      handleResponse(err.response);
      const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
        type: GET_ERRORS,
        payload: response,
        payload1: 'No se ha podido actualizar articulo'
      });
    }
    finally {
      changeSaveLoading(dispatch, false);
    }
  }
}


/**
 * Actualiza estado loading guardado articulo
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_ARTICLE,
    payload: status
  })
}



/**
 * Actualiza estado favorito de un articulo
 * @param {int} id Identificador articulo 
 * @param {boolean} status  Estado de articulo (favorito)
 */
export const updateFavoriteStatusAction = (id, status) => async (dispatch) => {

  try {

    changeUpdateLoading(dispatch, true);
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers,
      {
        'articleId': id,
        'articleState': status
      });


    var result = await axios.put(`${API_ENDPOINT_ARTICLE}${"/item/api/articulo/UpdateFavorite/"}`, {}, myConfig);

    let response = {
      statusCode: '200',
      statusMessage: "Actualización  exitosa",
      result: result.data,
      id: id
    };

    dispatch({
      type: UPDATE_FAVORITE_ARTICLE,
      payload: response,
    });

  } catch (err) {
    console.log("Error actualizando estado favorito,", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido actualizar estado favorito'
    });

  } finally {
    changeUpdateLoading(dispatch, false);
  }
};



/**
 * Eliminado logico Articulo, Cambio de estado
 * @param {int} id Identificador articulo
 */
export const deleteArticle = (id) => async (dispatch) => {

  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers,
      {
        'articleId': id,
      });

    var result = await axios.put(`${API_ENDPOINT_ARTICLE}${"/item/api/articulo/UpdateState/"}`, null, myConfig);

    let response = {
      statusCode: '200',
      statusMessage: "Eliminado  exitoso",
      result: result.data,
      id: id
    };

    dispatch({
      type: SUCCES_DELETE_ARTICLE,
      payload: response,
    });


  } catch (err) {

    console.log("Error eliminando articulo", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido eliminar articulo'
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }

};

/**
 * Carga información de articulo en Redux, para edicion en componente
 * @param {object} data  Información de articulo
 */
export const loadArticleAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_ARTICLE,
    payload: data,
  });
}

/**
 * Limpia información de articulo en Redux, Despues de Edición
 */
export const clearLoadArticleAction = () => (dispatch) => {
  dispatch({
    type: LOAD_ARTICLE,
    payload: null

  });
}

export const sendTabArticle = (index) => (dispatch) => {
  dispatch({
    type: SEND_INDEX_TAB,
    payload: index
  })
}


/**
 * Realiza consulta de articulos al montarse el componente,
 * Evitando  replicas en listado de articulos
 * @param {object} data 
 */
export const getInitialArticleListAction = (data) => async dispatch => {

  try {

    changeGetListLoading(dispatch, true);

    var body = {
      companyId: `${enterpriseIdHeader()}`,
      filter: data.filter,
      quantity: QUANTITY_DATA,
      skip: data.skip,
      categories: !!data.categories ? data.categories : [],
      type: data.type,
      typeOrder: data.typeOrder
    }

    const url = `${API_ENDPOINT_ARTICLE}${'/item/api/articulo/GetArticlesPaginationSearch/'}`
    var res = await axios.post(url, body, defaultHeaders());

    allArticles = res.data.result;
    totalArticles = res.data.count;

    dispatch({
      type: GET_ARTICLE_LIST,
      payload: allArticles,
      payload1: totalArticles
    });

  } catch (err) {
    console.log("Error consultando articulos,", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido obtener lisitado articulos'
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }

}

/**
 * Obtiene listado de articulos disponibles  por empresa,
 * obteniendo  lotes de 'N'  articulos de acuerdo a configuración de paginación y 
 * parametros de busqueda definidos por usuario
 * @param {object} data Parametros de busqueda
 */
export const getArticleListAction = (data, refresh = true) => async dispatch => {

  try {

    changeGetListLoading(dispatch, true);

    if (refresh === false) {

      allArticles = checkSelectedItems(allArticles);

      dispatch({
        type: GET_ARTICLE_LIST,
        payload: allArticles,
        payload1: totalArticles
      });

    } else {

      var body = {
        companyId: `${enterpriseIdHeader()}`,
        filter: data.filter,
        quantity: QUANTITY_DATA,
        skip: data.skip,
        categories: !!data.categories ? data.categories : [],
        type: data.type,
        typeOrder: data.typeOrder
      }


      const url = `${API_ENDPOINT_ARTICLE}${'/item/api/articulo/GetArticlesPaginationSearch/'}`
      var res = await axios.post(url, body, defaultHeaders());


      allArticles = allArticles.concat(res.data.result);
      allArticles = checkSelectedItems(allArticles);
      totalArticles = res.data.count;

      dispatch({
        type: GET_ARTICLE_LIST,
        payload: allArticles,
        payload1: totalArticles
      });

    }

  } catch (err) {
    console.log("Error consultando listado de articulos disponibles,", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido obtener listado de articulos disponibles'
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }
}


/**
 * Obtiene listado de articulos disponibles  por empresa y 
 * parametros de busqueda definidos por usuario
 * @param {object} data Parametros de busqueda
 */
export const filterArticleListAction = (data) => async dispatch => {

  try {

    changeGetListLoading(dispatch, true);

    //Limpia listado en memoria.
    allArticles = [];


    var body = {
      companyId: `${enterpriseIdHeader()}`,
      filter: data.filter,
      quantity: QUANTITY_DATA,
      skip: data.skip,
      categories: !!data.categories ? data.categories : [],
      type: data.type,
      typeOrder: data.typeOrder
    }

    const url = `${API_ENDPOINT_ARTICLE}${'/item/api/articulo/GetArticlesPaginationSearch/'}`;
    var res = await axios.post(url, body, defaultHeaders());

    allArticles = res.data.result;
    allArticles = checkSelectedItems(allArticles);
    totalArticles = res.data.count;

    dispatch({
      type: GET_ARTICLE_LIST,
      payload: allArticles,
      payload1: totalArticles
    });



  } catch (err) {
    console.log("Error filtrando listado de articulos disponibles,", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido filtrando listado de articulos disponibles'
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }
}

/**
 * Consulta articulo despues de actualizar y refresca listado en redux
 * @param {int} id 
 */
export const refreshAfterUpdatingAction = (id, type) => async (dispatch) => {

  try {

    dispatch({
      type: GET_ARTICLE_LIST,
      payload: [],
      payload1: 0
    });

    changeGetListLoading(dispatch, true);

    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers,
      {
        'pId': id
      });


    const url = `${API_ENDPOINT_ARTICLE}${'/item/api/articulo/GetArticleById/'}`;
    var res = await axios.get(url, myConfig);


    const newData = res.data.result;
    if (!!newData) {
      // Verifica si el articulo esta marcado como favorito
      // y el origen de la peticion (seccion todos---> 0 o seccion favoritos--> 1)
      if (type === 1 && newData.favorito === false) {
        allArticles = allArticles.filter(c => c.id !== newData.id);

      } else {
        const index = allArticles.findIndex(e => e.id === id);
        if (index < 0) {
          allArticles.push(newData);
        } else {
          let newArray = [...allArticles];
          newArray[index] = newData;
          allArticles = newArray;
        }
      }
    }

    dispatch({
      type: GET_ARTICLE_LIST,
      payload: allArticles,
      payload1: allArticles.count
    });

  } catch (err) {
    handleResponse(err.response);
    console.log("Error consultando articulo actualizado,", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar articulo actualizado'
    });
  }
  finally {
    changeGetListLoading(dispatch, false);
  }

}

/**
 * Consulta articulo despues de guardar y refresca listado en redux
 * @param {int} id 
 */
export const refreshAfterSaveAction = (id, type) => async (dispatch) => {

  try {

    dispatch({
      type: GET_ARTICLE_LIST,
      payload: [],
      payload1: 0
    });

    changeGetListLoading(dispatch, true);

    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers,
      {
        'pId': id
      });



    const url = `${API_ENDPOINT_ARTICLE}${'/item/api/articulo/GetArticleById/'}`;
    var res = await axios.get(url, myConfig);

    const newData = res.data.result;

    //Verifica si el articulo esta marcado como favorito
    // y el origen de la peticion (seccion todos---> 0 o seccion favoritos--> 1)
    if (type === 1 && newData.favorito === false) {
      //No se agrega a lista si no esta marcado como favorito

    } else {
      if (!isEmpty(newData))
        allArticles.splice(0, 0, newData);
    }


    dispatch({
      type: GET_ARTICLE_LIST,
      payload: allArticles,
      payload1: allArticles.count
    });

  } catch (err) {
    console.log("Error consultando articulo creado,", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar articulo creado'
    });
  }
  finally {
    changeGetListLoading(dispatch, false);
  }

}

/**
 * Quita de la lista articulo eliminado y actualiz en Redux
 * @param {int} id 
 */
export const refreshAfterDeleteAction = (id) => async (dispatch) => {

  try {

    dispatch({
      type: GET_ARTICLE_LIST,
      payload: [],
      payload1: 0
    });

    changeGetListLoading(dispatch, true);

    allArticles = allArticles.filter(a => a.id !== id);

    dispatch({
      type: GET_ARTICLE_LIST,
      payload: allArticles,
      payload1: allArticles.count
    });


  } catch (err) {
    console.log("Error consultando nuevo listado de articulos,", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar nuevo listado articulos'
    });
  }
  finally {
    changeGetListLoading(dispatch, false);
  }

}


/**
 * Limpia listado de articulos cargados en memoria
 * @action
 */
export const clearArticleListAction = () => async dispatch => {

  allArticles = [];
  dispatch({
    type: GET_ARTICLE_LIST,
    payload: allArticles
  });
}


/**
 * Ordena listado articulos de acuerdo a la selección del usuario
 * @param {int} type  Tipo de ordenamiento
 */
export const orderArticleListAction = (type) => async dispatch => {

  changeGetListLoading(dispatch, true);
  allArticles = orderArticlesList(type, allArticles);

  dispatch({
    type: GET_ARTICLE_LIST,
    payload: allArticles
  });

  changeGetListLoading(dispatch, false);

}

/**
 * Carga en memoria  articulos seleccionados en catalogo
 * @param {object} item 
 * @param {boolean} checked 
 */
export const setArticleCheckedAction = (item, checked) => async dispatch => {

  if (checked === true) {
    const exist = checkedArticles.filter(c => c.id === item.id);

    if (exist[0] === null || exist[0] === undefined) {
      checkedArticles.push(item);
    }

  } else {
    checkedArticles = checkedArticles.filter(c => c.id !== item.id);
  }

  dispatch({
    type: GET_ARTICLE_CHECKED,
    payload: checkedArticles
  });

}

/**
 * Obtine listado de articulos seleccionados en el catalogo
 */
export const getArticleCheckedAction = () => async dispatch => {

  dispatch({
    type: GET_ARTICLE_CHECKED,
    payload: checkedArticles
  });

}

/**
 * Limpia listado de articulos seleccionados en catalogo
 */
export const cleanArticleCheckedAction = () => async dispatch => {

  checkedArticles = [];
  dispatch({
    type: GET_ARTICLE_CHECKED,
    payload: checkedArticles
  });

}


/**
 * Actualiza estado loading consulta listado articulos
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: LOADING_GET_ARTICLES,
    payload: status
  })
}


/**
 * Actualiza estado loading consulta listado articulos
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeUpdateLoading(dispatch, status) {
  dispatch({
    type: LOADING_UPDATE_ARTICLE,
    payload: status
  })
}


// { value: 1, text: "Menor precio" },
// { value: 2, text: "Mayor precio" },
// { value: 3, text: "A-Z" },
// { value: 4, text: "Z-A" },
// { value: 5, text: "Ver todo" }

/**
 * Ordena listado de articulos de acuerdo al tipo seleccionado por el usuario
 * @param {int} type Tipo de ordenamiento
 * @param {*} list Lista articulos
 */
function orderArticlesList(type, list) {
  let temp = [];
  if (type === 3) {

    temp = list.sort((x, y) => {
      let a = x.nombrearticulo.toUpperCase();
      let b = y.nombrearticulo.toUpperCase();
      return a === b ? 0 : a > b ? 1 : -1;
    })

  } else if (type === 4) {

    temp = list.sort((x, y) => {
      let a = x.nombrearticulo.toUpperCase();
      let b = y.nombrearticulo.toUpperCase();
      return a === b ? 0 : a < b ? 1 : -1;
    })

  } else if (type === 1) {

    temp = list.sort((x, y) => {
      return x.valor - y.valor;
    })

  } else if (type === 2) {

    temp = list.sort((x, y) => {
      return y.valor - x.valor;
    })
  } else {

    temp = list.sort((x, y) => {
      let a = new Date(x.modifiedon);
      let b = new Date(y.modifiedon);
      return b - a;
    })
  }

  return temp;

}
/**
 * Verifica si existen elementos seleccionados y 
 * agrega propiedad check segun corresponda
 * @param {Array} listArticles 
 */
function checkSelectedItems(listArticles) {

  for (let i = 0; i < listArticles.length; i++) {

    const exist = checkedArticles.find(c => c.id === listArticles[i].id);
    if (!!exist) {

      listArticles[i] = {
        ...listArticles[i],
        checked: true
      }

    } else {

      listArticles[i] = {
        ...listArticles[i],
        checked: false
      }
    }
  }

  return listArticles;
}



