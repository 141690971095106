/* eslint-disable jsx-a11y/alt-text */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";

import {Grid} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";

import InputForm from "../../../components/input/input.component";
import SwitchForm from "../../../components/switch/switch.component.js";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import CustomAutoComplete from "../../../components/autocomplete/autocomplete.component";
import MultiSelectForm from "../../../components/multiselect/multiselect.component";
import ButtonPrimary from '../../../components/button/buttonPrimary.component'
import ButtonSecundary from '../../../components/button/buttonSecundary.component'

import {reactValidatorOptions} from "../../../helpers/simpleReactValidator";
import {SuccessAlert, WarningAlert} from "../../../helpers/alert.helpers";
import {regexOnlyNumbers, regexSpace} from "../../../helpers/customRegex.hepers";
import isEmpty from '../../../utils/isEmpty'
import {
  clearLoadArticleAction,
  createAndAddArticleAction,
  createArticleAction,
  getUnitMeasureAction,
  updateArticleAction
} from "../../../actions/articleActions";
import {cleanMenuComponentAction, getMenuComponentAction} from '../../../actions/configAction'
import imageDefault from "../../../../src/images/image.png";
import panoramaIcon from "../../../../src/images/panoramaIcon.png"
import '../article/article.css'
import SimpleModal from '../../../components/modal/simpleModal.component'
import {imageSize, URL_IMAGES} from "../../../config/config";
import {bytesToSize} from "../../../utils/general";
import CustomCropper from "../../../components/customCropper/customCropper.component";

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    outline: 0
  },
  paperModalImage: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    width: 400,

    outline: 0
  },
  Iconclose: {
    color: theme.palette.thirdColor,
    fontSize: 15,
    padding: 0

  },
  buttonClose: {
    textAlign: 'end'
  }
});

export class CreateArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txtArticleName: '',
        txtArticleDescription: '',
        txtArticlePrice: 0,
        chkArticlePrice: false,
        txtArticleIva: 0,
        chkArticleIva: false,
        txtArticleInc: 0,
        chkArticleInc: false,
        chkArticleTax: false,
        txtArticleNetWorth: 0,
        txtArticleTotal: 0,
        txtArticleCost: "",
        ddlArticleUnitMeasure: null,
        ddlArticleCategory: [],
        txtArticleReference: "",
        chkArticleFavorite: false,
        txtArticleBarcode: "",
        imgArticleSrc: "",
        categories: [],
      },
      //Pendientes or revisar
      getCategories: [],
      valuesCategories: null,
      valueCategory: [],

      unitMeasure: [],
      loadingSaveArticle: false,
      saveArticleResponse: {},
      updateArticleResponse: {},
      loadObjectArticle: {},
      editArticleStatus: false,
      stateModal: false,
      openModalIVA: false,
      openModalINC: false,
      image: null,
      menuComponent:[],
      configurableMenu:{},
      imgArticleSrcOld: ""
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {
    this.props.getMenuComponentAction(5);
    this.props.getUnitMeasureAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.articleReducer.requestUnitMeasure && nextProps.articleReducer.requestUnitMeasure.responseCode !== state.unitMeasure)
      update.unitMeasure = nextProps.articleReducer.requestUnitMeasure.result;

    if (!!nextProps.categoryReducer.getCategories && nextProps.categoryReducer.getCategories !== state.getCategories)
        update.getCategories = nextProps.categoryReducer.getCategories;

    if (!!nextProps.articleReducer.saveArticleResponse !== nextProps.articleReducer.saveArticleResponse !== state.saveArticleResponse)
      update.saveArticleResponse = nextProps.articleReducer.saveArticleResponse;


    if (!!nextProps.articleReducer.loadArticleObject && nextProps.articleReducer.loadArticleObject !== state.loadArticleObject)
      update.loadArticleObject = nextProps.articleReducer.loadArticleObject;

    if(!isEmpty(nextProps.articleReducer.editArticleStatus)  && nextProps.articleReducer.editArticleStatus !== state.editArticleStatus)
    update.editArticleStatus = nextProps.articleReducer.editArticleStatus;

    if (!!nextProps.articleReducer.updateArticleResponse !== nextProps.articleReducer.updateArticleResponse !== state.updateArticleResponse)
      update.updateArticleResponse = nextProps.articleReducer.updateArticleResponse;


    if (!!nextProps.configReducer.menuComponent && nextProps.configReducer.menuComponent !== state.menuComponent){

      update.menuComponent = nextProps.configReducer.menuComponent;
      let menu = nextProps.configReducer.menuComponent;
      let componetIVA = menu.find(e => e.id === 67)?.adcomponenteinformacion;
      let componetINC = menu.find(e => e.id === 68)?.adcomponenteinformacion;
      let componetPrice = menu.find(e => e.id === 66)?.adcomponenteinformacion;
      let componetTax = menu.find(e => e.id === 69)?.adcomponenteinformacion;
      let componentSaveButton = menu.find(e => e.id === 44)?.adcomponenteinformacion;
      let componentMessageSave= menu.find(element => element.id === 70)?.adcomponenteinformacion;
      let componentMessageUpdate= menu.find(element => element.id === 71)?.adcomponenteinformacion;


      update.configurableMenu = {
        ...state.configurableMenu,
        titleIVA: !!componetIVA?.find(e => e.id === 56)?.text ? componetIVA.find(e => e.id === 56)?.text : 'Aplica IVA',
        messageIVA: !!componetIVA?.find(e => e.id === 67)?.text ? componetIVA.find(e => e.id === 67)?.text : '',
        bottonIVA: !!componetIVA?.find(e => e.id === 68)?.text ? componetIVA.find(e => e.id === 68)?.text : '',
        hrefIVA: !!componetIVA?.find(e => e.id === 68)?.url ? componetIVA.find(e => e.id === 68)?.url : '',

        titleINC: !!componetINC?.find(e => e.id === 58)?.text ? componetINC.find(e => e.id === 58)?.text : 'Aplica INC',
        messageINC: !!componetINC?.find(e => e.id === 69)?.text ? componetINC.find(e => e.id === 69)?.text : '',
        bottonINC: !!componetINC?.find(e => e.id === 70)?.text ? componetINC.find(e => e.id === 70)?.text : '',
        hrefINC: !!componetINC?.find(e => e.id === 70)?.url ? componetINC.find(e => e.id === 70)?.url : '',

        titlePrice: !!componetPrice?.find(e => e.id === 57)?.text ? componetPrice.find(e => e.id === 57)?.text : 'Precio editable',
        messagePrice: !!componetPrice?.find(e => e.id === 64)?.text ? componetPrice.find(e => e.id === 64)?.text : '',
        bottonPrice: !!componetPrice?.find(e => e.id === 65)?.text ? componetPrice.find(e => e.id === 65)?.text : '',
        hrefPrice: !!componetPrice?.find(e => e.id === 65)?.url ? componetPrice.find(e => e.id === 65)?.url : '',

        titleTax: !!componetTax?.find(e => e.id === 59)?.text ? componetTax.find(e => e.id === 59)?.text : 'Precio editable',
        messageTax: !!componetTax?.find(e => e.id === 71)?.text ? componetTax.find(e => e.id === 71)?.text : '',
        bottonTax: !!componetTax?.find(e => e.id === 72)?.text ? componetTax.find(e => e.id === 72)?.text : '',
        hrefTax: !!componetTax?.find(e => e.id === 72)?.url ? componetTax.find(e => e.id === 72)?.url : '',

        saveButton: !!componentSaveButton?.find(e => e.id === 54)?.text ? componentSaveButton.find(e => e.id === 54)?.text : 'Listo',
        messageSave: !!componentMessageSave?.find(e => e.id === 60)?.text ? componentMessageSave.find(e => e.id === 60)?.text : 'Registro exitoso.',
        messageUpdate: !!componentMessageUpdate?.find(e => e.id === 61)?.text ? componentMessageUpdate.find(e => e.id === 61)?.text : 'Actualización exitoso.',
      }
    }



    if (!isEmpty(nextProps.articleReducer.loadingSaveArticle) && nextProps.articleReducer.loadingSaveArticle !== state.loadingSaveArticle)
      update.loadingSaveArticle = nextProps.articleReducer.loadingSaveArticle;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.saveArticleResponse !== this.state.saveArticleResponse) {


      const statusCode = this.state.saveArticleResponse.statusCode;

      if (statusCode === "201") {
        SuccessAlert('Genial...', this.state.configurableMenu.messageSave);
        this.cleanForm();
      } else if (statusCode === "210") {
        WarningAlert('Registro incompleto.', "Ha ocurrido un error, es posible que la información de articulo no se haya registrado por completo.");
        this.cleanForm();
      } else if (statusCode === "211") {
        WarningAlert('Opss...', "Ya se existe un articulo con la referencia digitada.");
      }
    }

    if (prevState.loadArticleObject !== this.state.loadArticleObject) {
      if (this.state.editArticleStatus === true)
        this.loadInfoForm(this.state.loadArticleObject);
      else
        this.cleanForm();
    }

    if (prevState.updateArticleResponse !== this.state.updateArticleResponse) {

      const statusCode = this.state.updateArticleResponse.statusCode;

      if (statusCode === "201") {
        SuccessAlert('Genial...', this.state.configurableMenu.messageUpdate);
        this.cleanForm();
      } else if (statusCode === "210") {
        WarningAlert('Registro incompleto.', "Ha ocurrido un error, es posible que la información de articulo no se haya actualizado por completo.");
        this.cleanForm();
      } else if (statusCode === "212") {
        WarningAlert('Opss...', "No se ha encontrado información del articulo.");
        this.cleanForm();
      }
    }


  }

  componentWillUnmount(){
    //Limpiar menu configurable al desmontar el componente FR
    this.props.cleanMenuComponentAction();
    this.props.clearLoadArticleAction();
  }


  /**
   * Sincroniza nuevo valor de  input con state
   * @param {object} e Evento
   */
  async syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    await this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }));
  }



  /**
 * Sincroniza Autocomplete con state
 * @param {object} event evento
 * @param {object} values nevo valor state
 * @param {string} id Identificador autocomplete
 */
  syncAutoCompleteChanges(event, values, id) {
    event.preventDefault();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: values
      }
    }))
  }

  /**
   * Sincroniza checkBox con state
   * @param {object} e evento
   */
  async syncCheckChange(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const checked = e.target.checked;
    await this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: checked
      }
    }))
  }

  /**
   * Sincroniza componente multiple select con state
   * @param {*} event
   */
  syncMultipleChange = (event) => {
    const options = event.target;
    const value = [];

    for (let index = 0; index < options.value.length; index++) {
      value.push(options.value[index]);
    }

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        ddlArticleCategory: value,
      }
    }));
  };

  /**
   * Regex solo numeros
   * @param {*} e
   */
  validateOnlyNumber(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexOnlyNumbers, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }

  /**
   * Regex Alfanumerico con espacios
   * @param {*} e
   */
  validateSpace(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexSpace, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }

  /**
   * Evento click, Valida valor IVA digitado en
   * modal confirmación IVA
   * @param {*} e  Evento
   * @param {*} name Identificador validador Input IVA
   */
  confirmIVA = (e, name) => {
    e.preventDefault();
    if (this.validator.fieldValid(name)) {
      this.calculateValues();
      this.closeModalIVA();
    } else {
      this.validator.showMessageFor(name);
      this.forceUpdate();
    }
  };


  /**
   * Evento click, Valida valor inc digitado en
   * modal confirmación inc
   * @param {*} e  Evento
   * @param {*} name Identificador validador Input inc
   */
  confirmINC = (e, name) => {
    e.preventDefault();
    if (this.validator.fieldValid(name)) {
      this.setState({
        openModalINC: false
      });
    } else {
      this.validator.showMessageFor(name);
      this.forceUpdate();
    }
  };


  /**
   * Calcula valores de articulo dependendo de la configuración de usuario,
   * aplica o no impuesto
   */
  calculateValues() {
    const iva = isEmpty(this.state.form.txtArticleIva) ? 0 : parseFloat(this.state.form.txtArticleIva);
    const inc = isEmpty(this.state.form.txtArticleInc) ? 0 : parseFloat(this.state.form.txtArticleInc);
    const price = isEmpty(this.state.form.txtArticlePrice) ? 0 : parseFloat(this.state.form.txtArticlePrice);
    let ivavalue = price * (iva / 100);
    let incvalue = price * (inc / 100);

    if (this.state.form.chkArticleTax === true) {
      let totalWithoutTax = price - ivavalue - incvalue;

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleNetWorth: Math.round(totalWithoutTax),
          txtArticleTotal: Math.round(this.state.form.txtArticlePrice),
        },
      }));
    }

    if (this.state.form.chkArticleTax === false) {
      let total = price + ivavalue + incvalue;


      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleNetWorth: Math.round(this.state.form.txtArticlePrice),
          txtArticleTotal: Math.round(total),
        },
      }));
    }
  }


  /**
   * Abre modal para registro de IVA,
   * cuando aplica (checkeo apiaca IVA)
   * @param {object} e
   */
  handleOpenModalIVA(e) {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked === true) {
      this.setState({
        openModalIVA: true
      })
    }
  }

  /**
   * Evento check, cambio aplica iva
   * @param {*} e
   */
  async changeCheckIVA(e) {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked === true) {
      this.setState({
        openModalIVA: true
      });

    } else {

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleIva: 0,
        }
      }));

      this.calculateValues();
    }
  }


  /**
     * Evento check, cambio aplica iva
     * @param {*} e
     */
  async changeCheckINC(e) {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked === true) {
      this.setState({
        openModalINC: true
      });

    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleInc: 0,
        }
      }));

      this.calculateValues();
    }
  }

  /**
   * Cierra modal para registro de IVA,
   * actualiza estado para visualizacion
   */
  closeModalIVA() {
    this.setState({
      openModalIVA: false
    })
  }

  /**
   * Abre modal para registro de IVA,
   * cuando aplica (checkeo apiaca IVA)
   * @param {object} e
   */
  handleOpenModalINC(e) {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked === true) {
      this.setState({
        openModalINC: true
      })
    }
  }

  /**
   * Cierra modal para registro de IVA,
   * actualiza estado para visualizacion
   */
  closeModalINC() {
    this.setState({
      openModalINC: false
    })
  }

  /**
   * Abre modal Imagen
   */
  handleOpen = () => {
    this.setState({
      stateModal: true
    });
  };

  /**
   * Cierra modal Imagen
   */
  handleClose = () => {
    this.setState({
      stateModal: false,
    });
  };


  /**
   * Guarda o actualiza información de un articulo
   * @param {Object} e Evento
   */
  saveArticle = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      let data = {};
      let categories = this.state.form.ddlArticleCategory.map((index, unit) => {
        return { categoriaid: index };
      });

      data = {
        id: this.state.editArticleStatus === true ? this.state.loadArticleObject.id : 0,
        imagen: this.state.form.imgArticleSrc,
        nombrearticulo: this.state.form.txtArticleName,
        valor: parseFloat(this.state.form.txtArticlePrice),
        precioeditable: this.state.form.chkArticlePrice,
        aplicaiva: this.state.form.chkArticleIva,
        valoriva: parseFloat(this.state.form.txtArticleIva),
        aplicainc: this.state.form.chkArticleInc,
        valorinc: parseFloat(this.state.form.txtArticleInc),
        incluyeimpuesto: this.state.form.chkArticleTax,
        neto: parseFloat(this.state.form.txtArticleNetWorth),
        total: parseFloat(this.state.form.txtArticleTotal),
        costo: parseFloat(this.state.form.txtArticleCost),
        dianunidadmedidaid: parseInt(this.state.form.ddlArticleUnitMeasure.value),
        codigo: this.state.editArticleStatus === true ? this.state.loadArticleObject.codigo : this.state.form.txtArticleReference,
        descripcion: this.state.form.txtArticleDescription,
        favorito: this.state.form.chkArticleFavorite,
        codigobarras: this.state.form.txtArticleBarcode,
        adcategoriaarticulo: categories,
        color: this.state.editArticleStatus === true ? this.state.loadArticleObject.color : "",
        tipodescuento: null,
        descuento: 0,
        activo: true,
        imgArticleSrcOld:this.state.imgArticleSrcOld
      };

      if (this.state.editArticleStatus === true) {
        this.props.updateArticleAction(data)
      } else {

        this.props.origin === 'cart' ?
          this.props.createAndAddArticleAction(data) :
          this.props.createArticleAction(data);
      }

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  /**
   * Limpia formulario, despues de guardao o actualización
   */
  cleanForm() {

    this.setState({
      form: {
        txtArticleName: '',
        txtArticleDescription: '',
        txtArticlePrice: 0,
        chkArticlePrice: false,
        txtArticleIva: 0,
        chkArticleIva: false,
        txtArticleInc: 0,
        chkArticleInc: false,
        chkArticleTax: false,
        txtArticleNetWorth: 0,
        txtArticleTotal: 0,
        txtArticleCost: "",
        ddlArticleUnitMeasure: null,
        ddlArticleCategory: [],
        txtArticleReference: "",
        chkArticleFavorite: false,
        txtArticleBarcode: "",
        imgArticleSrc: "",
        categories: [],
      },
      valuesCategories: null,
      openModalIVA: false,
      openModalINC: false,

    });
    this.validator.hideMessages();
    this.props.clearLoadArticleAction();
  }


  /**
 * Carga información de articulo en formulario para edición
 * @param {object} object Información articulo para edición
 */
  loadInfoForm(item) {

    let categories = []
    item.adcategoriaarticulo.map((index, item) => {
      return categories.push(index.categoriaid)
    });

    const unitMeasure = this.state.unitMeasure.find(c => c.id === item.dianunidadmedidaid);

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        imgArticleSrc: item.imagen && URL_IMAGES + item.imagen,
        txtArticleName: item.nombrearticulo,
        txtArticleDescription: item.descripcion,
        txtArticlePrice: item.valor,
        chkArticlePrice: item.precioeditable,
        txtArticleIva: item.valoriva,
        chkArticleIva: item.aplicaiva,
        txtArticleInc: item.valorinc,
        chkArticleInc: item.aplicainc,
        chkArticleTax: item.incluyeimpuesto,
        txtArticleNetWorth: item.neto,
        txtArticleTotal: item.total,
        txtArticleCost: item.costo,
        ddlArticleUnitMeasure: !!unitMeasure ? unitMeasure : null,
        ddlArticleCategory: categories,
        txtArticleReference: item.codigo,
        chkArticleFavorite: item.favorito,
        txtArticleBarcode: item.codigobarras
      },
      imgArticleSrcOld:item.imagen
    }));
  }



  onChange(e) {
    e.preventDefault();
    try {

      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;

      } else if (e.target) {
        files = e.target.files;
        var objetFile = files[0];
        if (!/\.(jpg|png)$/i.test(objetFile.name)) {
          WarningAlert("Formato no permitido. Formatos permitidos .jpg o .png");
          this.handleClose();
        } else if (objetFile.size >= imageSize) {
          WarningAlert(`El tamaño de la imagen no puede se superior a ${bytesToSize(imageSize)}`);
          this.handleClose();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({image: reader.result});
          };
          reader.readAsDataURL(files[0]);
          this.handleOpen();
        }
      }
      e.target.value = null
    } catch (error) {
      console.log("Error cargando imagen", error);
    }
  };

  onClickSave = async () => {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
      /*
      // Esta seccion crea un url Blob para la imagen

      let imageURL;
      await fetch(canvas)
        .then(res => res.blob())
        .then(blob => {
          imageURL = window.URL.createObjectURL(blob)
        });
      */
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          imgArticleSrc: canvas,
        }
      }));
    }
    this.handleClose();
  }

  setEditorRef = (editor) => this.editor = editor

  validateFieldsOnBlur(e, name) {
    e.preventDefault();
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name)
    }
  }

  render() {
    const { classes } = this.props;
    let categoryOption = [];
    if (!!this.state.getCategories) {
      categoryOption = this.state.getCategories.map((item) => {
        return {
          ...item,
          value: item.id,
          text: item.nombre,
          color: item.color,
        };
      });
    }

    return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item lg={4} xs={12}>
            <input
              accept="image/*"
              style={{ display: "none", zIndex: -1 }}
              id="icon-button-file"
              type="file"
              onChange={(e) => this.onChange(e)}
            />
            <div className="container">
              <div className={'container-img'} style={
                {
                  backgroundImage: `url( ${isEmpty(this.state.form.imgArticleSrc) ? imageDefault : this.state.form.imgArticleSrc} )`,
                  backgroundPosition: 'center center',
                }
              }>
              </div>
              <label htmlFor="icon-button-file">
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  className="btn">
                  <img width='30' src={panoramaIcon} alt='panorama'/>
                </IconButton>
              </label>
            </div>

          </Grid>

          <Grid item lg={8} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticleName"
                  value={this.state.form.txtArticleName}
                  label={"Nombre artículo *"}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) =>
                    this.syncChanges(e)
                  }
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticleDescription"
                  maxLength="200"
                  value={this.state.form.txtArticleDescription}
                  label={"Descripción *"}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) =>
                    this.syncChanges(e)
                  } />
              </Grid>

              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticlePrice"
                  value={this.state.form.txtArticlePrice}
                  label={"Precio"}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) =>
                    this.syncChanges(e)
                  }
                  onBlur={(e) => {
                    this.calculateValues();
                  }}
                  format={true}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <SwitchForm
                  name={"chkArticlePrice"}
                  value={this.state.form.chkArticlePrice}
                  checked={this.state.form.chkArticlePrice}
                  titleOn={"¿El precio es editable?"}
                  onChange={(e) => this.syncCheckChange(e)}
                  txtTooltip={
                    <TooltipMessage
                    title={this.state.configurableMenu.titlePrice}
                    message={this.state.configurableMenu.messagePrice}
                    botton={this.state.configurableMenu.bottonPrice}
                    href={this.state.configurableMenu.hrefPrice}
                  />
                  }
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticleNetWorth"
                  label={"Valor sin impuesto"}
                  value={this.state.form.txtArticleNetWorth}
                  disabled
                  onChange={(e) =>
                    this.syncChanges(e)
                  }
                  format={true}
                />

              </Grid>

              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticleTotal"
                  label={"Valor total con impuestos"}
                  value={this.state.form.txtArticleTotal}
                  onChange={(e) =>
                    this.syncChanges(e)
                  }
                  format={true}
                  disabled
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <SwitchForm
                  name={"chkArticleIva"}
                  value={this.state.form.chkArticleIva}
                  checked={this.state.form.chkArticleIva}
                  titleOn="Este producto aplica IVA :"
                  text={isEmpty(this.state.form.txtArticleIva) ? 0 : this.state.form.txtArticleIva}
                  maxLength={5}
                  onChange={(e) => {
                    this.syncCheckChange(e);
                    this.changeCheckIVA(e);
                  }}
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.configurableMenu.titleIVA}
                      message={this.state.configurableMenu.messageIVA}
                      botton={this.state.configurableMenu.bottonIVA}
                      href={this.state.configurableMenu.hrefIVA}
                    />
                  }
                />
              </Grid>
              <Grid lg={6} xs={12}>
                <SwitchForm
                  name={"chkArticleInc"}
                  titleOn={"Este producto aplica INC: "}
                  value={this.state.form.chkArticleInc}
                  checked={this.state.form.chkArticleInc}
                  maxLength={5}
                  onChange={(e) => {
                    this.syncCheckChange(e);
                    this.changeCheckINC(e);
                  }}
                  text={isEmpty(this.state.form.txtArticleInc) ? 0 : this.state.form.txtArticleInc}
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.configurableMenu.titleINC}
                      message={this.state.configurableMenu.messageINC}
                      botton={this.state.configurableMenu.bottonINC}
                      href={this.state.configurableMenu.hrefINC}
                    />
                  }
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid lg={4} xs={12}>
            <SwitchForm
              name={'chkArticleTax'}
              value={this.state.form.chkArticleTax}
              checked={this.state.form.chkArticleTax}
              titleOn="¿El precio incluye impuesto?"
              onChange={
                async (e) => {
                  await this.syncCheckChange(e);
                  this.calculateValues();
                }
              }
              txtTooltip={
                <TooltipMessage
                title={this.state.configurableMenu.titleTax}
                message={this.state.configurableMenu.messageTax}
                botton={this.state.configurableMenu.bottonTax}
                href={this.state.configurableMenu.hrefTax}
              />
              }
            />
          </Grid>

          <Grid lg={4} xs={12}>
            <InputForm
              name="txtArticleCost"
              label={"Costo"}
              value={this.state.form.txtArticleCost}
              validator={this.validator}
              validateOptions={"isNumber"}
              onChange={(e) =>
                this.syncChanges(e)
              }
              format={true}
            />
          </Grid>
          < Grid lg={4} xs={12}>
            <CustomAutoComplete
              name="ddlArticleUnitMeasure"
              label={"Unidad de medida * "}
              value={this.state.form.ddlArticleUnitMeasure}
              options={this.state.unitMeasure}
              textNoOption="No se encontraron resultados."
              onChange={(event, value) =>
                this.syncAutoCompleteChanges(event, value, "ddlArticleUnitMeasure")
              }
              validator={this.validator}
              validateOptions={"required"}
            />
          </ Grid>
          <Grid lg={4} xs={12}>
            <MultiSelectForm
              label={"Categorías"}
              name="categorias"
              value={this.state.form.ddlArticleCategory}
              options={categoryOption}
              onChange={this.syncMultipleChange}
              renderValue={(selected) =>
                this.state.form.ddlArticleCategory
                  .map((value) => {
                    let name = categoryOption.filter(
                      (category) => category.id === value
                    );
                    return name[0]?.nombre;

                  })
                  .join(",")
              }
              model={"Articulo"}
              placeholder={"Seleccione..."}
            />
          </Grid>

          <Grid lg={4} xs={12}>
            <InputForm
              name="txtArticleReference"
              value={this.state.form.txtArticleReference}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateSpace(e)
              }}
              label={"Referencia *"}
              nameValidator={'referencia'}
              onBlur={(e) =>
                this.validateFieldsOnBlur(e, 'referencia')
              }
              disabled={this.state.editArticleStatus=== true ? true: false}
              validator={this.validator}
              validateOptions={'required|blankSpace'}
            />
          </Grid>
          <Grid lg={4} xs={12}>
            <SwitchForm
              name={"chkArticleFavorite"}
              value={this.state.form.chkArticleFavorite}
              checked={this.state.form.chkArticleFavorite}
              titleOn="Marcar como destacado"
              onChange={(e) => this.syncCheckChange(e)}
            />
          </Grid>
          <Grid lg={4} xs={12}>
            <InputForm
              name="txtArticleBarcode"
              value={this.state.form.txtArticleBarcode}
              label={"Código de barras"}
              onChange={(e) =>
                this.syncChanges(e)
              }
            />
          </Grid>

        </Grid>


        <Grid container>
          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4} >
            <ButtonPrimary
              text={this.state.configurableMenu.saveButton}
              loading={this.state.loadingSaveArticle}
              type={'button'}
              onClick={(e) => this.saveArticle(e)}
            />
          </Grid>
        </Grid>


        {/* Modal Imagen */}
        <SimpleModal
          onClose={this.handleClose}
          open={this.state.stateModal}
          title='Actualizar imagen del artículo'
          component={() =>
            <CustomCropper
              onClickSave={this.onClickSave}
              handleClose={this.handleClose}
              image={this.state.image}
              setEditorRef={this.setEditorRef}
            />}
        />

        {/* Modal IVA */}
        <Modal
          className={classes.modal}
          open={this.state.openModalIVA}
          disableAutoFocus={true}>
          <div className={classes.paper}
            style={{
              width: 400,
              borderRadius: 5,
              padding: '30px 10px 20px 10px'
            }}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <span
                  style={{
                    color: "#A9F5BC",
                    textAlign: "center",
                    padding: '0 10px',
                  }}>
                  Valor % impuesto IVA
                 </span>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <InputForm
                  name="txtArticleIva"
                  value={this.state.form.txtArticleIva}
                  label={"% de impuesto"}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validateOnlyNumber(e)
                  }}
                  nameValidator={'ejemplo'}
                  validator={this.validator}
                  validateOptions={'percentageValue|percentageTwoDecimal'}
                  tooltip={true}
                  porcentage={true}
                />

                <Grid item xs={12} lg={12}>
                  <ButtonSecundary
                    text={'Confirmar'}
                    onClick={(e) => this.confirmIVA(e, 'ejemplo')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Modal>

        {/* Modal INC */}
        <Modal
          className={classes.modal}
          open={this.state.openModalINC}>
          <div
            className={classes.paper}
            style={{
              width: 400,
              borderRadius: 5,
              padding: '30px 10px 20px 10px'
            }}>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12}>
                <span
                  style={{
                    color: "#A9F5BC",
                    textAlign: "center",
                  }}>
                  Valor % impuesto INC
                </span>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <InputForm
                  name="txtArticleInc"
                  value={this.state.form.txtArticleInc}
                  label={"% de impuesto"}
                  nameValidator={'txtArticleInc'}
                  validator={this.validator}
                  validateOptions={"percentageValue|percentageTwoDecimal"}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validateOnlyNumber(e)
                  }}
                  onBlur={(e) => {
                    this.calculateValues();
                  }}
                  tooltip={true}
                  porcentage={true}
                />

                <Grid item lg={12} xs={12}>
                  <ButtonSecundary
                    text={'Confirmar'}
                    onClick={(e) => this.confirmINC(e, 'txtArticleInc')}
                  />
                </Grid>

              </Grid>
            </Grid>
          </div>
        </Modal>

      </Fragment>
    );
  }
}

CreateArticle.propTypes = {
  getUnitMeasureAction: PropTypes.func.isRequired,
  createArticleAction: PropTypes.func.isRequired,
  updateArticleAction: PropTypes.func.isRequired,
  clearLoadArticleAction: PropTypes.func.isRequired,
  getMenuComponentAction: PropTypes.func.isRequired,
  cleanMenuComponentAction: PropTypes.func.isRequired,
  createAndAddArticleAction: PropTypes.func.isRequired,

  configReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  articleReducer: state.articleReducer,
  categoryReducer: state.categoryReducer,
  configReducer: state.configReducer
});

export default connect(mapStateToProps, {
  getUnitMeasureAction,
  clearLoadArticleAction,
  createArticleAction,
  updateArticleAction,
  getMenuComponentAction,
  cleanMenuComponentAction,
  createAndAddArticleAction

})(withStyles(useStyles)(CreateArticle));
