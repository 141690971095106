import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
        backgroundColor: '#16B1F3',
        color: theme.palette.primaryColor,
        fontWeight: 300
    },
    avatar: {
        backgroundColor: red[500],
    },
    title: {
        color: '#FFFFFF',
        fontSize: 45,
        fontFamily:'Muli',
        fontWeight:800,
        marginLeft:34
    },
    subTitle: {
        color: '#FFFFFF',
        fontSize: 16,
        fontFamily:'Muli',
        fontWeight:600,
        marginLeft:34

    },
    content: {
        color: 'white'
    },
    buttonInfo: {
        backgroundColor: '#F5D13D',
        color: 'white',
        fontSize: 16,
        fontWeight: 600,
        padding: 1,
        border: 'none',
        borderRadius: 5,
        marginLeft:30,
        fontFamily:'Muli',
        width:129,
        height:29

    },
    actions:{
        textAlign: 'center',
    }
}));

export default function InfoCard(props) {
    const { title, subtitle } = props;
    const classes = useStyles();
    
    return (
        <Card className={classes.root}>
            <CardHeader />
            <span className={classes.title}>{title}</span>   
                <Typography variant="body2" className={classes.subTitle} component="p">
                    {subtitle}
                </Typography>
            <CardActions disableSpacing className={classes.actions}>
                <button type="button" className={classes.buttonInfo} >Comprar</button>
            </CardActions>
        </Card>
    );
}
