import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import {red} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(255,255,255,0.11)',
    color: theme.palette.primaryColor,
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    color: '#FFFFFF',
    fontSize: 45,
    fontFamily: 'Muli',
    fontWeight: 800,
    marginLeft: 34
  },
  subTitle: {
    paddingTop: '10px',
    maxWidth: '200px',
    minHeight: '55px',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: '-0.5px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    margin: 'auto'
  },
  subTitleCalendar: {
    paddingTop: '10px',
    maxWidth: '200px',
    minHeight: '55px',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: '-0.5px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    margin: 'auto'
  },
  buttonInfo: {
    backgroundColor: '#F5D13D',
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    padding: 1,
    border: 'none',
    borderRadius: 5,
    marginLeft: 30,
    fontFamily: 'Muli',
    width: 129,
    height: 29
  },
  actions: {
    textAlign: 'center'
  },
  divImage: {
    textAlign: 'center',
  },
  contentCard: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 10,
    }
  }
}));

export default function InfoCard(props) {
  const {subtitle, imageCard, margin} = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.contentCard}>
        <div className={classes.divImage}>
          <img src={imageCard} alt='card'/>
        </div>
        <div className={margin ? classes.subTitleCalendar : classes.subTitle}
             dangerouslySetInnerHTML={{__html: subtitle}}/>
      </CardContent>
    </Card>
  );
}
