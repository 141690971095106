import { SAVE_ADDRESS, GET_ADDRESSLIST, DELETE_ADDRESS, LOAD_ADDRESS, UPDATE_ADDRESS } from './types'


let addressList = [];

export const saveAndressAction = (address) => (dispatch) => {

    let response = {};
    if (!existAddress(address.id)) {
        response = {
            responseCode: "A10",
            message: "Se ha agregado un dirección adicional",
        }
        addressList.push(address);
    } else {
        response = {
            responseCode: "R11",
            message: "El usuario ya encuentra registrado",
        }
    }

    dispatch({
        type: SAVE_ADDRESS,
        payload: response
    })
}

export const loadAndressListAction = (list) => (dispatch) => {
    addressList = list;
    dispatch({
        type: GET_ADDRESSLIST,
        payload: addressList
    })
}


export const getAndressListAction = () => (dispatch) => {
    dispatch({
        type: GET_ADDRESSLIST,
        payload: addressList
    })
}

export const deleteAddressAction = (id) => (dispatch) => {

    addressList = addressList.filter(a => a.id !== id);
    let response = {
        responseCode: "A10",
        message: "La dirección se ha eliminado",
    }

    dispatch({
        type: DELETE_ADDRESS,
        payload: response
    })

    dispatch({
        type: GET_ADDRESSLIST,
        payload: addressList
    })
}

export const loadAddressAction = (address) => (dispatch) => {
    dispatch({
        type: LOAD_ADDRESS,
        payload: address
    })
}

export const updateAddressAction = (address) => (dispatch) => {
    try {
        let newAddress = addressList.filter(b => b.id !== address.id);
        addressList = newAddress;
        addressList.push(address);

        let response = {
            responseCode: "A10",
            message: "Se ha actualizado un dirección adicional",
        };

        dispatch({
            type: UPDATE_ADDRESS,
            payload: response
        });

        clearLoadAddress(dispatch);

    } catch (err) {
        console.log("ERROR", err);
        alert("Ha ocurrido un error", "No se ha podido actualizar dirección");
    }
}



export const clearAndressListAction = () => (dispatch) => {
    addressList = [];
    dispatch({
        type: GET_ADDRESSLIST,
        payload: addressList
    })
}


//Funciones
function existAddress(id) {
    let item = addressList.filter(a => a.id === id);
    return item.length ? true : false;
}

function clearLoadAddress(dispatch) {
    dispatch({
        type: LOAD_ADDRESS,
        payload: null
    })
}