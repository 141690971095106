import { API_ENDPOINT_ARTICLE, DEFAULTRESPONSE } from "../config/config";
import {
  SUCCES_CATEGORY,
  SUCCES_DELETE_CATEGORY,
  SUCCES_UPDATE_CATEGORY,
  LOAD_CATEGORY,
  GET_CATEGORY,
  GET_ERRORS,
  GET_CATEGORY_ARTICLE,
  CLEAN_DATA_CATEGORY,
  CLEAN_STATUS

} from "./types";
import axios from "axios";



export const postCategory = (data) => async (dispatch) => {
  await axios
    .post(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria"}`, data)
    .then((result) => {
      const response = {
        httpCode: result.request.status,
        responseCode: result.request.statusText,
        message: "Registro exitoso",
      }
      dispatch({
        type: SUCCES_CATEGORY,
        payload: response,
      })
    })
}

export const putCategory = (data) => async (dispatch) => {
  
  await axios
    .put(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria"}`, data)
    .then((result) => {
      const response = {
        httpCode: result.request.status,
        responseCode: result.request.statusText,
        message: "Registro exitoso",
      };
      dispatch({
        type: SUCCES_UPDATE_CATEGORY,
        payload: response,
      })
    })
}

export const deleteCategory = (data) => async (dispatch) => {
  await axios
    .put(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria/UpdateState"}`, null, {
      params: {
        categoryId: data,
      },
    })
    .then((result) => {
      const response = {
        httpCode: result.request.status,
        responseCode: result.request.statusText,
        message: "Registro exitoso",
      };
      dispatch({
        type: SUCCES_DELETE_CATEGORY,
        payload: response,
      })
    })
}

/**
 * Actions que recibe la data, para realizar la edición.
 * @param {Data que se envia al formulario, para realizar edicion} request 
 */
export const loadCategoryAction = (request) => (dispatch) => {
  dispatch({
    type: LOAD_CATEGORY,
    payload: request,
  })
}

export const getCategoryAction = () => async (dispatch) => {

  try {
    var result = await axios.get(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria/GetCategory"}`)

    // const response = {
    //   statusCode: '200',
    //   statusMessage: "Transaccion exitosa",
    //   result: result.data,
    // };

    dispatch({
      type: GET_CATEGORY_ARTICLE,
      payload: result.data,
    })

  } catch (err) {
    console.log("Error obteniendo listado categorias", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido obtener listado de categorias'
    });
  }

}

export const getCategoryPagination = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      page: data.page,
      quantity: data.quantity,
      name : data.name === null ? '' : data.name
    },
  };
  await axios
    .get(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria/Pagination"}`, config)
    .then((result) => {
      dispatch({
        type: GET_CATEGORY,
        payload: result.data.result,
        payload1 : result.data.count
      })
    })
};

export const dataCleanCloseForm = (data) => async(dispatch) => {
  let sendClean = {
    data : data.data,
    clean: data.clean === undefined ? [] : []
  }
  try {
    dispatch({
      type: CLEAN_DATA_CATEGORY,
      payload : sendClean,
      payload1: false
    });
  } catch (error) {
    console.log("Error-->", error);
  }
}

export const cleanStatusEdit = (request) => (dispatch) => {
      dispatch({
        type: CLEAN_STATUS,
        payload: request.data
      });
    }

  

