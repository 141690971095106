import isEmpty from '../utils/isEmpty'

const calculateDV = (value) => {
    if (value === null || value === undefined || isEmpty(value))
        return 0;

    value = value.replace(/\s/g, ""); // Espacios
    value = value.replace(/,/g, ""); // Comas
    value = value.replace(/\./g, ""); // Puntos
    value = value.replace(/-/g, ""); // Guiones

    if (isNaN(value)) {
        console.log("El nit/cédula '" + value + "' no es válido(a).");
        return 0;
    };

    var vpri, x, y, z;

    // Procedimiento
    vpri = new Array(16);
    z = value.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
        y = (value.substr(i, 1));
        x += (y * vpri[z - i]);
    }

    y = x % 11;
    return (y > 1) ? 11 - y : y;
}

export default calculateDV;