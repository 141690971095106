import React, { Component } from "react"
import PropTypes from "prop-types"

import { withStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import RoomIcon from '@material-ui/icons/Room';
import { Grid } from "@material-ui/core"
import PersonIcon from '@material-ui/icons/Person';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EditIcon from '@material-ui/icons/Edit';
import CustomTooltip from '../../components/tooltip/tooltip.component'

const useStyles = theme => ({
    show: {
        marginLeft: 'auto',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    root: {
        backgroundColor: '#5E5E5E',
        color: '#FFFFFF',
        fontWeight: 300,
        fontFamily: 'Muli',
        fontSize: '16px'
        
    },
    rootsel: {
        backgroundColor: '#3B3B3B',
        color: '#FFFFFF',
        fontWeight: 300,
        fontFamily: 'Muli',
        cursor:'pointer',
        height: '88px',
        fontSize: '16px'
        
    },
    rootchoose: {
        backgroundColor: '#16B1F3',
        color: '#FFFFFF',
        fontWeight: 300,
        fontFamily: 'Muli',
        cursor:'pointer',
        height: '85px',
        fontSize: '16px'      
    },
    title: {
        color: '#FFFFFF',
        fontWeight: 500,
        fontSize: 16,
        fontFamily: 'Muli'
    },
    icon: {
        color: '#2CC63E'
    }
})
export class CustomCardAditionalData extends Component {

    state = {
        data: this.props.data,
        editAction: this.props.editAditional,
        deleteAction: this.props.deleteAditional,
        onChange: this.props.onChange,
        principal: this.props.principal,
        active: false,
        type: this.props.type,
        functionSel: this.props.functionSel
    };

    handleClick = () => {
        !this.state.active ? this.setState({ active: true }) : this.setState({ active: false })
    }

     pad = (input, length, padding) => { 
        var str = input + "";
        return (length <= str.length) ? str : this.pad(padding+str, length, padding);
      }

    render() {
        const classes = this.props.classes;
        if (this.state.type === "directions"){
            return (
                <Card className={this.props.selectedId === this.state.data.id ? classes.rootchoose : classes.rootsel} onClick={() => this.state.functionSel(this.state.data.id)}>                 
                    <CardContent>
                        <Grid container>
                            <Grid item lg={1} md={1} sm={12} > 
                            <RoomIcon style={{ color: this.props.selectedId === this.state.data.id ? "#FFFFFF" : "#2CC63E" }}></RoomIcon>
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} >
                            <b>{this.state.data.descripcion}</b><br></br>
                            {this.state.data.direccion} {this.state.data.ciudaddescripcion}({this.state.data.departamentodescripcion})
                            </Grid>
                            <Grid item lg={1} md={1} sm={12}>                   
                            </Grid>
                            <Grid item lg={1} md={1} sm={12}>                    
                            </Grid>
                        </Grid>
                    </CardContent>       
                </Card>  
                     
            );    
        }

        if (this.state.type === "contacts"){
            return (
                <Card className={this.props.selectedId === this.state.data.id ? classes.rootchoose : classes.rootsel} onClick={() => this.state.functionSel(this.state.data.id)}>                 
                    <CardContent>
                        <Grid container>
                            <Grid item lg={1} md={1} sm={12} > 
                            <PersonIcon style={{ color: this.props.selectedId === this.state.data.id ? "#FFFFFF" : "#2CC63E" }}></PersonIcon>
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} >
                            <b>{this.state.data.nombre}</b><br></br>
                            {this.state.data.telefono} / {this.state.data.email}
                            </Grid>
                            <Grid item lg={1} md={1} sm={12}>                  
                            </Grid>
                            <Grid item lg={1} md={1} sm={12}>                   
                            </Grid>
                        </Grid>
                    </CardContent>       
                </Card>  
                     
            );            
        }

        if (this.state.type === "charges"){
            return (
                     <Card className={classes.root} style={{ color : '#FFFFFF'}}>                 
                    <CardContent >
                        <Grid container>
                            <Grid item lg={2} md={2} sm={12}> 
                            <LibraryAddIcon style={{ color: "#2CC63E" }}></LibraryAddIcon>
                            </Grid>
                            <Grid item lg={10} md={10} sm={12}>
                            <b>&nbsp;{this.state.data.descripcion}</b><br></br>
                            &nbsp;{this.state.data.tipocargo=== 1? '$' : '%'}{new Intl.NumberFormat('es-CO').format(this.state.data.valor)} {this.state.data.cantidad>1 ? '('+this.state.data.cantidad+')' :''}<br></br>
                            </Grid>
                        </Grid>
                    </CardContent>       
                </Card>  
            );
        }

        if (this.state.type === "discounts"){
            return (
                <Card className={classes.root} style={{ color : '#FFFFFF'}}>                 
                    <CardContent>
                        <Grid container>
                            <Grid item lg={1} md={1} sm={12}> 
                            <div style={{display: this.state.data.modelodescuento === 1 ? 'none': 'block' }}>
                            <LocalOfferIcon style={{ color: "#2CC63E" }}></LocalOfferIcon>
                            </div>
                            <div style={{display: this.state.data.modelodescuento === 1 ? 'block': 'none' }}>
                            <AccountBalanceIcon style={{ color: "#2CC63E" }}></AccountBalanceIcon>
                            </div>  
                            </Grid>
                            <Grid item lg={10} md={10} sm={12}>
                            <b>{this.state.data.descripcion}</b><br></br> 
                            {this.state.data.tipodescuento===1 ? '$' : '%'}{new Intl.NumberFormat('es-CO').format(this.state.data.valor)}<br></br>
                            {this.state.data.motivo}<br></br>
                            </Grid>      
                            <Grid item lg={1} md={1} sm={12}>
                                <div >
                            <IconButton aria-label="settings">
                            <CustomTooltip
                        message={this.props.tooltipMessage}
                        />
                            </IconButton>
                            </div>                 
                            </Grid>
                        </Grid>
                    </CardContent>       
                </Card>  
                     
            );
        }

        if (this.state.type === "notes"){
            return (
                <Card className={classes.root} style={{ color : '#FFFFFF'}}>                 
                    <CardContent>
                        <Grid container>
                            <Grid item lg={1} md={1} sm={12}> 
                            <div>
                            <EditIcon style={{ color: "#2CC63E" }}></EditIcon>
                            </div>          
                            </Grid>
                            <Grid item lg={9} md={9} sm={12}>
                            <b>Nota {this.state.data.diantipodocumentoelectronicoid === 2 ? 'Debito': 'Crédito'}</b><br></br> 
                            Número Nota: {this.state.data.numero}<br></br>
                            Concepto: {this.state.data.notaconcepto}<br></br>
                            Fecha: {this.pad(new Date(this.state.data.fecha).getDate(), 2, "0")}/{this.pad((new Date(this.state.data.fecha).getMonth()+1),2,"0")}/{new Date(this.state.data.fecha).getFullYear()} {this.pad(new Date(this.state.data.fecha).getHours(), 2, "0")}:{this.pad(new Date(this.state.data.fecha).getMinutes(), 2, "0")}<br></br>
                            </Grid>      
                            <Grid item lg={1} md={1} sm={12}>
                                <div >                           
                            </div>                 
                            </Grid>
                        </Grid>
                    </CardContent>       
                </Card>  
                     
            );
        }
        
    }
}

CustomCardAditionalData.propTypes = {
    data: PropTypes.object
}

export default (withStyles(useStyles)(CustomCardAditionalData))