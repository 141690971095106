import React, { Fragment , useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { getRoleOfLoggedUser } from '../../../actions/authActions';
import CustomTooltip from '../../../components/tooltip/tooltip.component'
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import { getComponentName,getComponentUrl } from '../../../utils/general.js';
import { ClearGetComponentsAction,getMenuComponentAction} from '../../../actions/configAction'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.thirdBackgroundColor,
        color: theme.palette.primaryColor,
        '& .MuiCardContent-root':{
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft:55
        }
    },
    avatar: {
    },
    header: {
        paddingBottom: 0,
        paddingTop: 0
    },
    content: {

        fontSize: 14
    },
    title: {
        color: theme.palette.primaryColor,
    },

    icon: {
        color: theme.palette.secundaryColor
    },
    iconAction: {
        color: theme.palette.primaryColor
    },
    valueText: {
        color: '#EC005F',
        fontWeight: 500
    },
    help:{
    marginLeft:-25 ,
    marginTop:-36
    }
}));

function ManageDiscountCard(props) {
    const classes = useStyles();
    const { data, onLoad, onDelete,getMenuComponentAction,ClearGetComponentsAction } = props;

    let { configReducer } = props;
    let components = configReducer.menuComponent
    useEffect(() => {
        getMenuComponentAction(3);
        // returned function will be called on component unmount 
        return () => {
          ClearGetComponentsAction()
        }
      },[])

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={data.modelodescuento === 1
                    ? <LocalOfferIcon fontSize="small" className={classes.icon} />
                    : <AccountBalanceIcon fontSize="small" className={classes.icon} />
                }
                action={
                    <Fragment>
                        
                        {
                            (getRoleOfLoggedUser())
                                ? <Tooltip title="No tiene permisos sobre esta acción">
                                    <IconButton aria-label="Editar">
                                        <CreateIcon className={classes.iconAction} />
                                    </IconButton>
                                </Tooltip>
                                : <Tooltip title="Editar">
                                    <IconButton aria-label="Editar" onClick={onLoad}>
                                        <CreateIcon className={classes.iconAction} />
                                    </IconButton>
                                </Tooltip>
                        }

                        <Tooltip title="Eliminar">
                            <IconButton aria-label="Eliminar" onClick={onDelete} >
                                <DeleteIcon className={classes.iconAction} />
                            </IconButton>
                        </Tooltip>
                        <div className={classes.help} >
                        <CustomTooltip
                        
                        message={ data.modelodescuento === 1 ?
                            <TooltipMessage
                               title={ getComponentName(components,93,157,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,93,158,'Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura.')}
                               botton={ getComponentName(components,93,159,'Más información')}
                               href={getComponentUrl(components, 93, 159, null)}
                             /> :  
                             <TooltipMessage
                               title={ getComponentName(components,94,160,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,94,161,'Es un descuento que afecta el valor total a pagar pero no el valor total de la factura.')}
                               botton={ getComponentName(components,94,162,'Más información')}
                               href={getComponentUrl(components, 94, 162, null)}
                             />
                        }
                        />
                        </div>
                        
                    </Fragment>
                }
                classes={{
                    title: classes.title,
                }}
                title={data.modelodescuento === 1 ? 'Descuento Comercial' : 'Descuento Financiero'}
            />
            <CardContent className={classes.content}>
                {data.tipodescuento === 1 ?
                    <span className={classes.valueText}>$ {!!data?.valoradescontar ? data.valoradescontar.toLocaleString('en') : '0'}</span>
                    :
                    <span>
                        <span>{data.valor.toLocaleString('en')}%</span> &nbsp;
                        <span className={classes.valueText}>($ {!!data?.valoradescontar ? data.valoradescontar.toLocaleString('en') : '0'})</span>
                    </span>
                }
                <br />
                <span>{`Motivo: ${data.motivoDescripcion}`}</span>
                <br />
                <span>   {data.descripcion}</span>

            </CardContent>
        </Card>
    );
}

ManageDiscountCard.propTypes = {
    configReducer: PropTypes.object.isRequired

}

const mapStateToProps = state => ({
    configReducer: state.configReducer
})

export default connect(mapStateToProps, {
    getMenuComponentAction,
    ClearGetComponentsAction
 })(ManageDiscountCard);
