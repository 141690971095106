import { API_ENDPOINT_ENTERPRISE, DEFAULTRESPONSE } from "../config/config";
import axios from 'axios'
import { SAVE_TEMPLATE, GET_TEMPLATE, STATUS_STEP_TEMPLATE, GET_ERRORS } from "./types"
import SmallYellowTemplate from "../images/yellow-template-small.png"
import BigYellowTemplate from "../images/yellow-template-big.png"
import SmallRedTemplate from "../images/red-template-small.png"
import BigRedTemplate from "../images/red-template-big.png"
// import SmallBlueTemplate from "../images/blue-template-small.png"
// import BigBlueTemplate from "../images/blue-template-big.png"
import SmallGreenTemplate from "../images/green-template-small.png"
import BigGreenTemplate from "../images/green-template-big.png"
// import SmallPinkTemplate from "../images/pink-template-small.png"
// import BigPinkTemplate from "../images/pink-template-big.png"
import SmallDefaultTemplate from "../images/default-template-small.jpg"
import BigDefaultTemplate from "../images/default-template-big.jpg"

import { handleResponse, authHeader, enterpriseIdHeader } from './authActions'
const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;

const templates = [
    { id: '9e192a73-c7d7-4de0-a85d-2d68001ced66', nombre: "Plantilla 1", srcSmall: SmallYellowTemplate, srcBig: BigYellowTemplate },
    { id: '4648d8c1-8574-468d-9dea-61651a831a1b', nombre: "Plantilla 2", srcSmall: SmallRedTemplate, srcBig: BigRedTemplate },
    { id: '6dab59c2-dc40-45b3-b2ab-43c92e71f900', nombre: "Plantilla 3", srcSmall: SmallGreenTemplate, srcBig: BigGreenTemplate },
    { id: '37938662-0f4f-41d5-af99-9f6b27e91d0a', nombre: "Plantilla 4", srcSmall: SmallDefaultTemplate, srcBig: BigDefaultTemplate },
]

export const getTemplate = () => async (dispatch) => {
    const response = {
        httpCode: "200",
        responseCode: "A10",
        message: "Registro exitoso",
        content: templates
    }

    dispatch({
        type: GET_TEMPLATE,
        payload: response
    })

}

export const postTemplate = (data) => async (dispatch) => {
    const config = {
        headers: {
          'Authorization': `${authHeader()}`,
          'empresaId': `${enterpriseIdHeader()}`,
        }
      }
    await axios.put(`${urlEnterprise}`, data, config).then((response) => {
        dispatch({
            type: SAVE_TEMPLATE,
            payload: response.data
        })
    }).catch(err => {
        handleResponse(err.response)
        console.log("Error actualizando logo y plantilla.", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar Logo y plantilla.'
        });
    })

}

export const changeStepStatusAction = (status) => (dispatch) => {
    dispatch({
        type: STATUS_STEP_TEMPLATE,
        payload: status
    })
}

