import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { SuccessAlert } from '../../../helpers/alert.helpers'
import { regexDecimal, regexNames } from '../../../helpers/customRegex.hepers'
import InputForm from "../../../components/input/input.component";
import RadioButtonGroup from '../../../components/radioButton/radioButton.component'
import ButtonSecundary from '../../../components/button/buttonSecundary.component'

import { postCharge, getCharges } from '../../../actions/chargeAction'
import {
    updateChargeToInvoiceAction,
    getChargesInvoiceAction,
    clearLoadedChargeInvoiceAction,
    openModalChargeAction,
    getTotalAction
} from '../../../actions/valuesInvoiceAction'
import getTypeValue from '../../../utils/typeValue'



class NewChargeInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                txtChargeValue: '',
                txtChargeName: '',
                rbgTypeCharge: ''
            },
            saveChargeResponse: {},
            loadingSave: false,
            loadChargeDocument: null,
            editChargeDocument: false,
            updateChargeDocumentResponse: {}
        }
        this.validator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentDidMount() { }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.chargeReducer.createCharge && nextProps.chargeReducer.createCharge !== state.saveChargeResponse)
            update.saveChargeResponse = nextProps.chargeReducer.createCharge;

        if (nextProps.chargeReducer.loadingSave !== state.loadingSave)
            update.loadingSave = nextProps.chargeReducer.loadingSave;

        if (!!nextProps.valuesInvoiceReducer.loadChargeDocument && nextProps.valuesInvoiceReducer.loadChargeDocument !== state.loadChargeDocument) {
            update.loadChargeDocument = nextProps.valuesInvoiceReducer.loadChargeDocument;
            const item = nextProps.valuesInvoiceReducer.loadChargeDocument;;
            update.form = {
                txtChargeValue: item.valor,
                txtChargeName: item.descripcion,
                rbgTypeCharge: item.tipocargo.toString()
            };
        }

        if (nextProps.valuesInvoiceReducer.editChargeDocument !== state.editChargeDocument)
            update.editChargeDocument = nextProps.valuesInvoiceReducer.editChargeDocument;

        if (!!nextProps.valuesInvoiceReducer.updateChargeDocumentResponse && nextProps.valuesInvoiceReducer.updateChargeDocumentResponse !== state.updateChargeDocumentResponse) {
            update.updateChargeDocumentResponse = nextProps.valuesInvoiceReducer.updateChargeDocumentResponse;
        }


        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.saveChargeResponse !== this.state.saveChargeResponse) {
            if (this.state.saveChargeResponse.statusCode === '201') {
                SuccessAlert('Genial...!', 'Se ha registrado exitosamente el nuevo cargo.');
                this.props.getCharges('', true);
                this.cleanForm();
            }
        }

        if (prevState.updateChargeDocumentResponse !== this.state.updateChargeDocumentResponse) {
            if (this.state.updateChargeDocumentResponse.statusCode === '200') {
                this.cleanForm();
                this.props.openModalChargeAction(false);
            }
        }
    }

    /**
     * @event
     * @description Sincroniza informacion Input con State
     * @param {*} e 
     */
    syncChanges(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    /**
   * @event
   * @description Sincroniza informacion Radio Button con State
   * @param {*} e 
   */
    syncRadioChanges(e, id) {
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    //Valida valor deciamal
    validateDecimal(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        value = value.replace(regexDecimal, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    /**
     * Elimina caracteres no validos, solo caracteres validos para nombres
     * @param {*} e  Evento
     */
    validateNames(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexNames;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }


    /**
     * @event
     * @description Captura evento de guardado
     */
    onSaveCharge = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            let charge = {
                descripcion: this.state.form.txtChargeName,
                tipocargo: parseInt(this.state.form.rbgTypeCharge),
                valor: parseFloat(this.state.form.txtChargeValue),
                esglobal: false,
            }

            this.props.postCharge(charge);

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    /**
     * @event 
     * @description Edita valor de un cargo agregado al documento (Factura, Nota)
     * @param {*} e
     */
    onUpdateChargeDocument(e) {
        e.preventDefault();
        if (this.validator.allValid()) {

            let chargeLoaded = this.state.loadChargeDocument;
            let chargeInvoice = {
                ...chargeLoaded,
                valor: parseFloat(this.state.form.txtChargeValue)
            }

            this.props.updateChargeToInvoiceAction(chargeInvoice);

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    /**
     * @function
     * @description Limpia formulario
     */
    cleanForm() {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtChargeValue: '',
                txtChargeName: '',
                rbgTypeCharge: ''
            },
        }))
        this.props.clearLoadedChargeInvoiceAction();
        this.validator.hideMessages();
    }

    
    render() {
        return (
            <Fragment>
                <Grid container spacing={1}>
                    <Grid item lg={12} xs={12}>
                        <RadioButtonGroup
                            name={'rbgTypeCharge'}
                            value={this.state.form.rbgTypeCharge}
                            onChange={(e) => this.syncRadioChanges(e, 'rbgTypeCharge')}
                            options={getTypeValue()}
                            label={"Tipo Cargo:"}
                            validator={this.validator}
                            validateOptions={"required"}
                            disabled={this.state.editChargeDocument}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <InputForm
                            name={'txtChargeValue'}
                            label={this.state.form.rbgTypeCharge === '2' ? "% Cargo *" : "Valor Cargo *"}
                            maxLength={100}
                            value={this.state.form.txtChargeValue}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateDecimal(e);
                            }}
                            nameValidator={'Valor Cargo'}
                            validator={this.validator}
                            validateOptions={this.state.form.rbgTypeCharge === '1' ? "required" : "required|porcentage"}
                            format={this.state.form.rbgTypeCharge === '1' ? true : false}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <InputForm
                            name={'txtChargeName'}
                            label={"Nombre Cargo *"}
                            maxLength={100}
                            value={this.state.form.txtChargeName}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateNames(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required"}
                            disabled={this.state.editChargeDocument}
                        />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <ButtonSecundary
                            text={'Confirmar'}
                            loading={this.state.loadingSave}
                            onClick={this.state.editChargeDocument === true ?
                                (e) => this.onUpdateChargeDocument(e) :
                                (e) => this.onSaveCharge(e)} />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

NewChargeInvoice.propTypes = {
    postCharge: PropTypes.func.isRequired,
    getCharges: PropTypes.func.isRequired,
    updateChargeToInvoiceAction: PropTypes.func.isRequired,
    getChargesInvoiceAction: PropTypes.func.isRequired,
    clearLoadedChargeInvoiceAction: PropTypes.func.isRequired,
    openModalChargeAction: PropTypes.func.isRequired,
    getTotalAction: PropTypes.func.isRequired,

    chargeReducer: PropTypes.object.isRequired,
    valuesInvoiceReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    chargeReducer: state.chargeReducer,
    valuesInvoiceReducer: state.valuesInvoiceReducer,
})

export default connect(mapStateToProps, {
    postCharge,
    getCharges,
    updateChargeToInvoiceAction,
    getChargesInvoiceAction,
    clearLoadedChargeInvoiceAction,
    openModalChargeAction,
    getTotalAction

})(NewChargeInvoice);