import React, { Component, Fragment } from "react"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { WarningAlert } from '../../helpers/alert.helpers'

import CollapsePanel from '../../components/collapse/collapse.component'
import NewContact from '../contact/newContact.component'
import ContactCard from '../contact/contactCard.componet'

import { getContactsAction, deleteContactAction, loadContactAction } from '../../actions/contactAction'


class Address extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openPanel: '',
            getContactResponse: [],
            editContactStatus: false

        }
    }

    componentDidMount() {
        this.props.getContactsAction();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.contactReducer.getContactResponse && nextProps.contactReducer.getContactResponse !== state.getContactResponse)
            update.getContactResponse = nextProps.contactReducer.getContactResponse;

        if (nextProps.contactReducer.editContactStatus !== state.editContactStatus) {
            update.editContactStatus = nextProps.contactReducer.editContactStatus;
        }

        return Object.keys(update).length ? update : null;
    }

    componentWillUnmount() {

    }


    onDelete(e, id) {
        e.preventDefault();
        this.props.deleteContactAction(id);
    }

    onLoad(e, id) {
        e.preventDefault();
        let item = this.state.getContactResponse.filter(item => item.id === id);

        if (item == undefined || item == null || item.length === 0) {
            WarningAlert('Opss...', 'No se ha podido cargar contacto');
            return;
        }

        this.props.loadContactAction(item[0]);

        this.setState({
            openPanel: 'panel',
        })
    }

    onClickCollapse = () => {
        if (this.state.openPanel === 'panel') {
            this.setState({
                openPanel: ''
            })
        } else if (this.state.openPanel === '') {
            this.setState({
                openPanel: 'panel',
            })
        }
    }



    render() {
        return (
            <Fragment>
                <div className="comunContainer">
                    <CollapsePanel
                        component={NewContact}
                        title={!this.state.editContactStatus ? "Agregar contacto adicional" : "Editar contacto adicional"}
                        titleOpen={!this.state.editContactStatus ? "Agregar contacto adicional" : "Editar contacto adicional"}
                        onClick={this.onClickCollapse}
                        open={this.state.openPanel}
                        type={'secundary'} />
                </div>

                <div className="comunContainer">
                    <Grid container spacing={1}>
                        {this.state.getContactResponse.map((data, index) =>
                            <Grid item key={index} md={4} >
                                <ContactCard
                                    data={data}
                                    onDelete={(e) => this.onDelete(e, data.id)}
                                    onLoad={(e) => this.onLoad(e, data.id)} />
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Fragment>


        );
    }
}

Address.propTypes = {
    getContactsAction: PropTypes.func.isRequired,
    deleteContactAction: PropTypes.func.isRequired,
    loadContactAction: PropTypes.func.isRequired,

    contactReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    contactReducer: state.contactReducer,

})

export default connect(mapStateToProps, {
    getContactsAction,
    deleteContactAction,
    loadContactAction
})(Address);
