import {
  GET_BILL_CLIENT,
  GET_COLLABORATOR_RANKING,
  GET_BILLING,
  GET_BRANCH_RAKING,
  GET_INCOME,
  GET_BEST_CUSTOMER,
  GET_RAKING_ITEMS,
  GET_ISSUED_INVOICE
} from "../actions/types";

const initialState = {
  loadingGetGraphic: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BILL_CLIENT:
      return {
        ...state,
        getBillClient: action.payload,
      };
    case GET_COLLABORATOR_RANKING:
      return {
        ...state,
        getRakingColaborator: action.payload,
        statusCodeColaborator: action.payload1,
      };
    case GET_BILLING:
      return {
        ...state,
        getBilling: action.payload,
        totalSold: action.payload1,
        statusCodeBilling: action.payload2,
      };

    case GET_INCOME:
      return {
        ...state,
        getIncome: action.payload,
        statusCodeIncome: action.payload1,
      };
    case GET_BEST_CUSTOMER:
      return {
        ...state,
        getCustomer: action.payload,
        statusCodeCustomer: action.payload1,
      };
    case GET_BRANCH_RAKING:
      return {
        ...state,
        getRakingBranch: action.payload,
        statusCodeBranch: action.payload1,
      };
    case GET_RAKING_ITEMS:
      return {
        ...state,
        getRakingItem: action.payload,
        statusCodeItem: action.payload1,
      };
    case GET_ISSUED_INVOICE:
      return {
        ...state,
        quantityInvoice: action.payload,
      };
    default:
      return state;
  }
}
