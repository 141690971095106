import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import {
  SuccessAlert,
} from "../../../helpers/alert.helpers";

import Biller from "./biller.view.js";
import Seller from "./seller.view.js";

import UserCard from "../../../components/card/userCard.component";
import InputIcon from "../../../components/input/inputIcon.component";
import CollapsePanel from "../../../components/collapse/collapse.component";
import NotificationNotCreated from "../../../components/notification/notificationNotCreated.component"
import CustomProgress from '../../../components/Progress/progress.component'

import {
  loadBillerAction,
  loadSellerAction,
  updateBillerStatusAction,
  getBillersAction,
  refrehAfterUpdateBillerStatusAction,
  refrehAfterUpdateSellerStatusAction,
  updateSellerStatusAction,
  getSellersAction,
} from "../../../actions/userAction";
import { getComponentName } from "../../../utils/general";
import isEmpty from "../../../utils/isEmpty";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredBillersList:[],
      filteredSellersList: [],
      txtFilterBiller: '',
      txtFilterSeller: '',
      filterBillers: [],
      filterSellers: [],
      resultBiller: false,
      updateBillerStatusResponse: {},
      expandPanelBiller: "",
      loadSellerObject: {},
      expandPanelSeller: "",
      updateSellerStatusResponse: {},
      editBillerStatus: false,
      editSellerStatus: false,
      saveSellerResponse: {},
      updateSellerResponse: {},
      saveBillerResponse: {},
      updateBillerResponse: {},
      checkedState: false,
      companyId: 0,
      loadingGetBillers: false,
      loadingGetSellers: false,
    };
  }

  componentDidMount() {
    this.props.getBillersAction('', false);
    this.props.getSellersAction('', false);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.userReducer.filteredBillersList && nextProps.userReducer.filteredBillersList !== state.filteredBillersList)  
      update.filteredBillersList = nextProps.userReducer.filteredBillersList;
    
    if (!!nextProps.userReducer.filteredSellersList && nextProps.userReducer.filteredSellersList !== state.filteredSellersList)
      update.filteredSellersList = nextProps.userReducer.filteredSellersList;

    if (!!nextProps.userReducer.updateBillerStatusResponse && nextProps.userReducer.updateBillerStatusResponse !== state.updateBillerStatusResponse)
      update.updateBillerStatusResponse = nextProps.userReducer.updateBillerStatusResponse;

    if (!!nextProps.userReducer.updateSellerStatusResponse && nextProps.userReducer.updateSellerStatusResponse !== state.updateSellerStatusResponse)
      update.updateSellerStatusResponse = nextProps.userReducer.updateSellerStatusResponse;


    if (nextProps.userReducer.editBillerStatus !== state.editBillerStatus)
      update.editBillerStatus = nextProps.userReducer.editBillerStatus;

    if (nextProps.userReducer.editSellerStatus !== state.editSellerStatus)
      update.editSellerStatus = nextProps.userReducer.editSellerStatus;

    if (!!nextProps.userReducer.saveSellerResponse &&
      nextProps.userReducer.saveSellerResponse !== state.saveSellerResponse)
      update.saveSellerResponse = nextProps.userReducer.saveSellerResponse

    if (!!nextProps.userReducer.updateSellerResponse &&
      nextProps.userReducer.updateSellerResponse !== state.updateSellerResponse)
      update.updateSellerResponse = nextProps.userReducer.updateSellerResponse;

    if (!!nextProps.userReducer.saveBillerResponse &&
      nextProps.userReducer.saveBillerResponse !== state.saveBillerResponse)
      update.saveBillerResponse = nextProps.userReducer.saveBillerResponse;

    if (!!nextProps.userReducer.updateBillerResponse &&
      nextProps.userReducer.updateBillerResponse !== state.updateBillerResponse) {
      update.updateBillerResponse = nextProps.userReducer.updateBillerResponse;
    }
   
    if (!isEmpty(nextProps.userReducer.loadingGetBillers) && nextProps.userReducer.loadingGetBillers !== state.loadingGetBillers)
      update.loadingGetBillers = nextProps.userReducer.loadingGetBillers;
 
    if (!isEmpty(nextProps.userReducer.loadingGetSellers) && nextProps.userReducer.loadingGetSellers !== state.loadingGetSellers)
      update.loadingGetSellers = nextProps.userReducer.loadingGetSellers;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.updateBillerStatusResponse !== this.state.updateBillerStatusResponse) {
      if (this.state.updateBillerStatusResponse.statusCode === '201') {
        SuccessAlert(getComponentName(this.state.components, 34, 110, 'Buen trabajo!!!'), getComponentName(this.state.components, 34, 111, 'Actualizaste el usuario $nombreUsuario de manera exitosa, sigamos facturando.').replace('$nombreUsuario', this.state.updateBillerStatusResponse.result.nombres + ' ' + this.state.updateBillerStatusResponse.result.apellidos));

        let id = this.state.updateBillerStatusResponse.id;
        let status = this.state.updateBillerStatusResponse.status;
        this.props.refrehAfterUpdateBillerStatusAction(id, status, this.state.txtFilterBiller);
      }
    }

    if (prevState.updateSellerStatusResponse !== this.state.updateSellerStatusResponse) {
      if (this.state.updateSellerStatusResponse.statusCode === '201') {
        SuccessAlert(getComponentName(this.state.components, 34, 110, 'Buen trabajo!!!'), getComponentName(this.state.components, 34, 111, 'Actualizaste el usuario $nombreUsuario de manera exitosa, sigamos facturando.').replace('$nombreUsuario', this.state.updateSellerStatusResponse.result.nombres + ' ' + this.state.updateSellerStatusResponse.result.apellidos));

        let id = this.state.updateSellerStatusResponse.id;
        let status = this.state.updateSellerStatusResponse.status;
        this.props.refrehAfterUpdateSellerStatusAction(id, status, this.state.txtFilterSeller);
      }
    }

    if (prevState.saveBillerResponse !== this.state.saveBillerResponse) {
      if (this.state.saveBillerResponse.statusCode === '201' || this.state.saveBillerResponse.statusCode === '400') {
        this.setState({
          expandPanelBiller: ''
        })
      }
    }

    if (prevState.updateBillerResponse !== this.state.updateBillerResponse) {
      if (this.state.updateBillerResponse.statusCode === '200' || this.state.updateBillerResponse.statusCode === '201') {
        this.setState({
          expandPanelBiller: ''
        })
      }
    }

    if (prevState.saveSellerResponse !== this.state.saveSellerResponse) {
      if (this.state.saveSellerResponse.statusCode === '201' || this.state.saveSellerResponse.statusCode === '200') {
        this.setState({
          expandPanelSeller: ''
        })
      }
    }

    if (prevState.updateSellerResponse !== this.state.updateSellerResponse) {
      if (this.state.updateSellerResponse.statusCode === "200" || this.state.updateSellerResponse.statusCode === "201") {
        this.setState({
          expandPanelSeller: ''
        })
      }
    }
  }

  componentWillUnmount() {}

  /**
 * Evento, Cambio de estado facturador desde listado
 * @param {*} e Evento 
 * @param {int} id  Identificador Facturador
 */
  onChangeStatusBiller = (e, id) => {
    const checked = e.target.checked;
    this.props.updateBillerStatusAction(id, checked ? 0 : 1);
  };


  /**
  * Evento, Cambio de estado vendedor desde listado
  * @param {*} e Evento 
  * @param {*} id  Identificador vendedor
  */
  onChangeStatusSeller = (e, id) => {
    e.preventDefault();
    const checked = e.target.checked;
    this.props.updateSellerStatusAction(id, checked ? 0 : 1);
  };


  /**
   * Evento,  escucha cambios en filtro facturadores,
   * invoca función para filtrado
   * @param {*} e Evento
   */
  onChangeFilterBiller(e) {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      txtFilterBiller: value
    })
    this.props.getBillersAction(value, false);
  }


  /**
  * Evento,  escucha cambios en filtro vendedores,
  * invoca función para filtrado
  * @param {*} e Evento
  */
  onChangeFilterSeller(e) {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      txtFilterSeller: value
    })
    this.props.getSellersAction(value, false);
  }


  /**
   * Evento, Exapande o collapsa 'Collapse' formulario facturador
   */
  onClickCollapseBillerChange = () => {
    this.setState({
      expandPanelBiller: this.state.expandPanelBiller === 'panel' ? '' : 'panel'
    })
  }


  /**
   * Evento, Exapande o collapsa 'Collapse' formulario facturador
   */
  onClickCollapseSellerChange = () => {
    this.setState({
      expandPanelSeller: this.state.expandPanelSeller === 'panel' ? '' : 'panel'
    })
  }

  /**
   * Evento, Carga información de facturador en formulario
   * para edición 
   * @param {*} e Evento
   * @param {object} data Información facturador
   */
  onLoadBiller = (e, data) => {
    e.preventDefault();
    this.setState({
      expandPanelBiller: "panel",
    });
    this.props.loadBillerAction(data);
  };

  onLoadSeller = (e, data) => {
    e.preventDefault();

    this.setState({
      expandPanelSeller: "panel",
    });

    this.props.loadSellerAction(data);
  };


  render() {
    return (
      <Fragment>
        <div className="comunContainer">
          <div className="title-yellow">Facturadores</div>
        </div>

        <div className="comunContainer">

          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <CollapsePanel
                component={Biller}
                title={
                  !this.state.editBillerStatus
                    ? getComponentName(this.state.components, 29, 97, 'Crear nuevo facturador')
                    : "Editar Facturador"
                }
                titleOpen={
                  !this.state.editBillerStatus
                    ? "Nuevo Facturador"
                    : "Editar Facturador"
                }
                titleEdit={"Editar Facturador"}
                open={this.state.expandPanelBiller}
                onClick={() => this.onClickCollapseBillerChange()}
              />
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <InputIcon
              id={"txtFilterBiller"}
              label={"Buscar facturador"}
              value={this.state.txtFilterBiller}
              onChange={(e) => {
                this.onChangeFilterBiller(e);
              }}
            />
          </Grid>
        </Grid>
        
        <Grid container spacing={3} >
          {this.state.loadingGetBillers === true ?
            <Grid item lg={12} xs={12} >
              <Grid container alignItems="center" justify="center" >
                <CustomProgress />
              </Grid>
            </Grid>
            :
            <Fragment>
              {this.state.filteredBillersList.length > 0 ?
                <Fragment>
                  {this.state.filteredBillersList.map((data, index) =>
                    <Grid item key={index} lg={4} xs={12}>
                      <UserCard
                        type={"Biller"}
                        data={data}
                        onClick={(e) => this.onLoadBiller(e, data)}
                        onChange={(e) => this.onChangeStatusBiller(e, data.id)} />
                    </Grid>
                  )}
                </Fragment> :
                <Grid item lg={12} xs={12}>
                  <NotificationNotCreated text={"No se han encontrado facturadores..."} />
                </Grid>
              }
            </Fragment>
          }
        </Grid>

        <div className="comunContainer">
          <div className="title-yellow">Vendedores</div>
        </div>

        <div className="comunContainer">
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <CollapsePanel
                component={Seller}
                title={
                  !this.state.editSellerStatus
                    ? getComponentName(this.state.components, 31, 102, 'Crear nuevo vendedor')
                    : "Editar Vendedor"
                }
                titleOpen={
                  !this.state.editSellerStatus
                    ? "Nuevo Vendedor"
                    : "Editar Vendedor"
                }
                titleEdit={"Editar Vendedor"}
                open={this.state.expandPanelSeller}
                onClick={() => this.onClickCollapseSellerChange()}
              />
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <InputIcon
              name={"txtFilterSeller"}
              label={"Buscar vendedor"}
              value={this.state.txtFilterSeller}
              onChange={(e) => {
                this.onChangeFilterSeller(e)
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} >
          {this.state.loadingGetSellers === true ?
            <Grid item lg={12} xs={12}>
              <Grid container alignItems="center" justify="center" >
                <CustomProgress />
              </Grid>
            </Grid>
            :
            <Fragment>
              {this.state.filteredSellersList.length > 0 ?
                <Fragment>
                  {this.state.filteredSellersList.map((data, index) =>
                    <Grid item key={index} lg={4} xs={12}>
                      <UserCard
                        type={"Seller"}
                        data={data}
                        onClick={(e) => this.onLoadSeller(e, data)}
                        onChange={(e) =>
                          this.onChangeStatusSeller(e, data.id)
                        } />
                    </Grid>
                  )}
                </Fragment> :
                <Grid item lg={12} xs={12}>
                  <NotificationNotCreated text={"No se han encontrado vendedores..."} />
                </Grid>
              }
            </Fragment>
          }
        </Grid>

      </Fragment>
    );
  }
}

User.propTypes = {
  loadBillerAction: PropTypes.func.isRequired,
  loadSellerAction: PropTypes.func.isRequired,
  updateBillerStatusAction: PropTypes.func.isRequired,
  getBillersAction: PropTypes.func.isRequired,
  updateSellerStatusAction: PropTypes.func.isRequired,
  userReducer: PropTypes.object.isRequired,
  getSellersAction: PropTypes.func.isRequired,
  refrehAfterUpdateBillerStatusAction: PropTypes.func.isRequired,
  refrehAfterUpdateSellerStatusAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  authReducer: state.authReducer
});

export default connect(mapStateToProps, {
  loadBillerAction,
  loadSellerAction,
  updateBillerStatusAction,
  getBillersAction,
  updateSellerStatusAction,
  getSellersAction,
  refrehAfterUpdateBillerStatusAction,
  refrehAfterUpdateSellerStatusAction
})(User);
