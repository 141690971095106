import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { regexAlphaNumeric } from '../../helpers/regex.helper';

import InputForm from "../../components/input/input.component";
import SwitchForm from '../../components/switch/switch.component.js';
import SelectForm from '../../components/select/select.component';
import CollapsePanel from '../../components/collapse/collapse.component';
import CollapsePanelStyles from '../../components/collapse/collapsestyles.component';
import FormClient from '../clients/newClient.component';
import ListWithFilter from '../../components/list/listWithFilter.component'

import { format } from "date-fns";
import { getPaymentMethodsAction } from '../../actions/configAction';
import { getAditionalData } from '../../actions/invoiceActions';
import CustomCardAditionalData from "../invoice/aditional-data-card.component";
import ClearIcon from '@material-ui/icons/Clear';
import StarIcon from '@material-ui/icons/Star';
import DateForm from "../../components/datepicker/datepicker.component";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import { Create, Delete } from "@material-ui/icons";


import {
    loadClientAction,
    clearLoadClientAction,
    getClientsAction

} from '../../actions/clientAction'

import isEmpty from '../../utils/isEmpty'



class InvoiceForm extends Component {

    newDatePay() {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
    }

    constructor(props) {
        super(props);
        this.state = {
            expandPanelNumeration: '',
            cleanData: false,
            edit: false,
            expandPanel: {
                expandPanelInfo: 'panel',
                cleanData: false,
                edit: false
            },
            height: props.height,
            cancelcreate: props.CancelCreate,
            nextstep: props.NextStep,
            getPaymentMethodsResponse: [],
            getVendorsResponse: [],
            getBranchOffices: [],
            getNumerationsResponse: [],
            getObservationsReponse: [],
            form: {
                visibleCredit: this.props.objHead === null ? true : this.props.objHead.visibleCredit,
                visibleCounted: this.props.objHead === null ? false : this.props.objHead.visibleCounted,
                dateInvoice: this.props.objHead === null ? format(new Date(), 'yyyy/MM/dd') : this.props.objHead.dateInvoice,
                datePay: this.props.objHead === null ? format(this.newDatePay(), 'yyyy/MM/dd') : this.props.objHead.datePay,
                paymethodid: this.props.objHead === null ? 0 : this.props.objHead.paymethodid,
                includeVendor: this.props.objHead === null ? true : this.props.objHead.includeVendor,
                clientInfo: {
                    documento: this.props.objHead === null ? '' : this.props.objHead.clientInfo.documento,
                    documentodv: this.props.objHead === null ? '' : this.props.objHead.clientInfo.documentodv,
                    razonsocial: this.props.objHead === null ? '' : this.props.objHead.clientInfo.razonsocial,
                    telefono: this.props.objHead === null ? '' : this.props.objHead.clientInfo.telefono,
                    correoelectronico: this.props.objHead === null ? '' : this.props.objHead.clientInfo.correoelectronico,
                    diantipoentidadid: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.diantipoentidadid,
                    diantipopersonaid: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.diantipopersonaid,
                    empresaid: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.empresaid,
                    favorito: this.props.objHead === null ? false : this.props.objHead.clientInfo.favorito,
                    diantiporegimenid: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.diantiporegimenid,
                    adclientedireccion: this.props.objHead === null ? [] : this.props.objHead.clientInfo.adclientedireccion,
                    adcontactos: this.props.objHead === null ? [] : this.props.objHead.clientInfo.adcontactos,
                    id: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.id,
                    idstate: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.idstate,
                    createdby: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.createdby,
                    createdon: this.props.objHead === null ? '' : this.props.objHead.clientInfo.createdon,
                    modifiedby: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.modifiedby,
                    modifiedon: this.props.objHead === null ? '' : this.props.objHead.clientInfo.modifiedon,
                    idbusinessunit: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.idbusinessunit,
                    idowner: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.idowner,
                    direccionid: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.direccionid,
                    direccion: this.props.objHead === null ? '' : this.props.objHead.clientInfo.direccion,
                    ciudadid: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.ciudadid,
                    ciudaddescripcion: this.props.objHead === null ? '' : this.props.objHead.clientInfo.departamentoid,
                    departamentoid: this.props.objHead === null ? 0 : this.props.objHead.clientInfo.departamentoid,
                    departamentodescripcion: this.props.objHead === null ? '' : this.props.objHead.clientInfo.departamentodescripcion,
                    text: this.props.objHead === null ? '' : this.props.objHead.clientInfo.text,
                    diantipodocumentoidentidadid: this.props.objHead === null ? '' : this.props.objHead.clientInfo.diantipodocumentoidentidadid,
                },
                vendorid: this.props.objHead === null ? '' : this.props.objHead.vendorid,
                branchofficeid: this.props.objHead === null ? '' : this.props.objHead.branchofficeid,
                resolutionid: this.props.objHead === null ? '' : this.props.objHead.resolutionid,
                addressid: this.props.objHead === null ? 0 : this.props.objHead.addressid,
                contactid: this.props.objHead === null ? 0 : this.props.objHead.contactid,
                observations: this.props.objHead === null ? '' : this.props.objHead.observations,
                favorite: this.props.objHead === null ? false : this.props.objHead.favorite,
                valueoptions: this.props.objHead === null ? 0 : this.props.objHead.valueoptions,
                Resolution: this.props.objHead === null ? {} : this.props.objHead.Resolution,
                Vendor: this.props.objHead === null ? {} : this.props.objHead.Vendor,
                observationid: this.props.objHead === null ? '' : this.props.objHead.observationid,
                rangeDate: this.props.objHead === null ? 0 : this.props.objHead.rangeDate,
                othersPayMethod: this.props.objHead === null ? false : this.props.objHead.othersPayMethod,
                dirprincipal: this.props.objHead === null ? [] : this.props.objHead.dirprincipal
            },
            editClientStatus: false,
            openPanel: '',
            showIncludeVendor: this.props.objHead === null ? false : this.props.objHead.vendorid === '' || this.props.objHead.vendorid === 0 ? false : true,
            activeInfo: this.props.objHead === null ? false : true,
            validatorDirection: '',
            validatorClient: '',
            getClientListResponse: [],
            loadingListClients: false,
            saveClientResponse: {},
            updateClientResponse: {}

        }
        this.validator = new SimpleReactValidator(reactValidatorOptions);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        let windowsHeight = parseInt((window.innerHeight) * 0.7, 10);
        this.setState({ height: windowsHeight + 'px' });
        this.props.getPaymentMethodsAction();
        this.props.getAditionalData(this.props.Empresaid, this.props.Userid);
        this.props.getClientsAction();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.configReducer.getPaymentMethodsResponse && nextProps.configReducer.getPaymentMethodsResponse !== state.getPaymentMethodsResponse)
            update.getPaymentMethodsResponse = nextProps.configReducer.getPaymentMethodsResponse;

        if (!!nextProps.invoiceReducer.aditionaldataenterprise.advendedor && nextProps.invoiceReducer.aditionaldataenterprise.advendedor !== state.getVendorsResponse)
            update.getVendorsResponse = nextProps.invoiceReducer.aditionaldataenterprise.advendedor;

        if (!!nextProps.invoiceReducer.aditionaldataenterprise.adsucursalesempresa && nextProps.invoiceReducer.aditionaldataenterprise.adsucursalesempresa !== state.getBranchOffices)
            update.getBranchOffices = nextProps.invoiceReducer.aditionaldataenterprise.adsucursalesempresa;

        if (!!nextProps.invoiceReducer.aditionaldataenterprise.adnumeracion && nextProps.invoiceReducer.aditionaldataenterprise.adnumeracion !== state.getNumerationsResponse)
            update.getNumerationsResponse = nextProps.invoiceReducer.aditionaldataenterprise.adnumeracion;


        /*if (update.getBranchOffices !== undefined){
            if (update.getBranchOffices.length === 1){               

                update.branchofficeid = update.getBranchOffices[0].id;

                console.log('derived'+ JSON.stringify(update.branchofficeid));
                /*update.form(prevState) => ({
                    form: {
                        ...prevState.form,
                        branchofficeid: update.getBranchOffices[0].id
                    }, 
                }));
            }
        }*/

        if (!!nextProps.invoiceReducer.aditionaldataenterprise.adobservaciones && nextProps.invoiceReducer.aditionaldataenterprise.adobservaciones !== state.getObservationsReponse)
            update.getObservationsReponse = nextProps.invoiceReducer.aditionaldataenterprise.adobservaciones;

        if (nextProps.clientReducer.editClientStatus !== state.editClientStatus)
            update.editClientStatus = nextProps.clientReducer.editClientStatus;

        if (!!nextProps.clientReducer.getClientListResponse && nextProps.clientReducer.getClientListResponse !== state.getClientListResponse)
            update.getClientListResponse = nextProps.clientReducer.getClientListResponse;

        if (!isEmpty(nextProps.clientReducer.loadingListClients) && nextProps.clientReducer.loadingListClients !== state.loadingListClients)
            update.loadingListClients = nextProps.clientReducer.loadingListClients;

        if (!!nextProps.clientReducer.saveClientResponse && nextProps.clientReducer.saveClientResponse !== state.saveClientResponse) {
            update.saveClientResponse = nextProps.clientReducer.saveClientResponse;
        }

        if (!!nextProps.clientReducer.updateClientResponse && nextProps.clientReducer.updateClientResponse !== state.updateClientResponse) {
            update.updateClientResponse = nextProps.clientReducer.updateClientResponse;
        }

        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {


        if (prevState.getBranchOffices !== undefined) {
            if (prevState.getBranchOffices.length === 1) {
                //console.log('derived'+ JSON.stringify(prevState.getBranchOffices[0].id));

                prevState.form = {
                    ...prevState.form,
                    branchofficeid: prevState.getBranchOffices[0].id
                }

            }
        }

        if (prevState.getObservationsReponse !== undefined) {
            if (prevState.getObservationsReponse.length === 1) {
                //console.log('array'+JSON.stringify(prevState.getObservationsReponse[0].texto));
                prevState.form = {
                    ...prevState.form,
                    observations: prevState.getObservationsReponse[0].texto
                }
            }
        }
        //Actualiza lista de clientes despues de Guardar desde el componente NewClient
        if (prevState.saveClientResponse !== this.state.saveClientResponse) {
            if (this.state.saveClientResponse.statusCode === '201' || this.state.saveClientResponse.statusCode === '200') {
                this.props.getClientsAction();
                this.setState({
                    openPanel:''
                })
            }
        }

        //Actualiza lista de clientes despues de Actualizar desde el componente NewClient
        if (prevState.updateClientResponse !== this.state.updateClientResponse) {
            if (this.state.updateClientResponse.statusCode === '201' || this.state.updateClientResponse.statusCode === '200') {
                this.props.getClientsAction();
                this.setState({
                    openPanel:''
                })
            }
        }

        //Carga detalle cliente despues de nuevo cargue del listado clientes
        if (prevState.getClientListResponse !== this.state.getClientListResponse) {
            const newdata = this.state.getClientListResponse[0];
            if (!!newdata) {
                if (this.props.objHead === null){
                    this.setDetailClient(newdata);
                }
                
            }
        }

    }

    componentWillUnmount() {
        //alert('componente cagado');
    }

    cleanData() {

    }

    cleanComponent() {
        this.props.clearLoadClientAction();
    }

    syncChanges = (value, id) => {
        let i = 0;
        //console.log('--Value'+ id + ' ' + value);
        if (id === "observacionid") {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    observations: this.state.form.observations + (this.state.form.observations === "" ? "" : "\n") + value + ": ",
                    observationid: value
                }
            }));
        }
        else {
            let obj = null;
            switch (id) {
                case "vendorid":
                    for (i === 0; i < this.state.getVendorsResponse.length; i++) {
                        if (value === this.state.getVendorsResponse[i].id) {
                            obj = this.state.getVendorsResponse[i];
                        }
                    }
                    if (value === 0) {
                        this.setState((prevState) => ({
                            form: {
                                ...prevState.form,
                                [id]: value,
                                Vendor: '',
                                includeVendor: false
                            },
                            showIncludeVendor: false
                        }));
                    }
                    else {
                        this.setState((prevState) => ({
                            form: {
                                ...prevState.form,
                                [id]: value,
                                Vendor: obj,
                                includeVendor: true
                            },
                            showIncludeVendor: true
                        }));
                    }
                    break;
                case "resolutionid":
                    for (i === 0; i < this.state.getNumerationsResponse.length; i++) {
                        if (value === this.state.getNumerationsResponse[i].id) {
                            obj = this.state.getNumerationsResponse[i];
                        }
                    }
                    //console.log('seleccion-->'+JSON.stringify(obj))

                    this.setState((prevState) => ({
                        form: {
                            ...prevState.form,
                            [id]: value,
                            Resolution: obj,
                            
                        },
                        validatorClient: ''
                    }));
                    break;
                case "paymethodid":
                    if (value === "999") {
                        //alert("Se escogio para ver más");
                        this.setState((prevState) => ({
                            form: {
                                ...prevState.form,
                                [id]: 0,
                                othersPayMethod: true
                            },
                        }));
                        //this.setState((prevState) => ({othersPayMethod: true}));
                    }
                    else {
                        this.setState((prevState) => ({
                            form: {
                                ...prevState.form,
                                [id]: value
                            }
                        }));
                    }
                    break;
                case "observations":
                    if (value === "") {
                        this.setState((prevState) => ({
                            form: {
                                ...prevState.form,
                                [id]: value,
                                observationid: ''
                            }
                        }));
                    }
                    else {
                        this.setState((prevState) => ({
                            form: {
                                ...prevState.form,
                                [id]: value
                            }
                        }));
                    }

                    break;
                default:
                    this.setState((prevState) => ({
                        form: {
                            ...prevState.form,
                            [id]: value
                        }
                    }));
                    break;
            }

        }

    };

    validateAlphaNumeric = (e) => {
        if (!regexAlphaNumeric(e))
            e.preventDefault();
    }

    dateToString(dateTime) {
        const date = new Date(dateTime)
        return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
    }

    switchHandleChange = (event) => {

        //console.log('evento' + event.target.checked);
        let eventCheck = event.target.checked;

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                includeVendor: eventCheck
            }
        }));
    };

    saveInvoiceData = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            let fechafinal = this.state.form.Resolution.fechafinal;
            fechafinal = new Date(fechafinal).getFullYear()+'/'+this.pad((new Date(fechafinal).getMonth()+1),2,"0")+'/'+ this.pad(new Date(fechafinal).getDate(), 2, "0")
            
            if (fechafinal<this.state.form.dateInvoice){
                this.setState((prevState) => ({
                    validatorClient: 'La resolución selecciona esta vencida'
                }));
            }
            else if (this.state.form.clientInfo.id === 0) {
                //WarningAlert('Debe seleccionar un cliente');
                this.setState((prevState) => ({
                    validatorClient: 'Debe seleccionar un cliente'
                }));
            }
            else if (this.state.form.addressid === 0) {
                //WarningAlert('Debe seleccionar una dirección en Información Adicional');
                this.setState((prevState) => ({
                    validatorDirection: 'Debe seleccionar una dirección',
                    validatorClient: ''
                }));
            }
            else {
                //SuccessAlert("Información de la factura creada, seleccione los articulos a continuación");   
                this.setState((prevState) => ({
                    validatorDirection: '',
                    validatorClient: ''
                }));
                this.props.ApproveStepInfo(this.state.form);
            }
        }
        else {
            //WarningAlert('Hay campos por completar');
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handlePayForm = (form) => {
        if (form === 1) {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    visibleCredit: true, visibleCounted: false, paymethodid: 0, othersPayMethod: false, datePay: format(this.newDatePay(), 'yyyy/MM/dd')
                }
            }));
        }
        else {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    visibleCredit: false, visibleCounted: true, datePay: ''
                }
            }));
        }
    }
    handleDateForm = (id, date) => {

        if (id === "txtfechfac") {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    dateInvoice: new Date(date).toISOString()
                }
            }));
        }

        if (id === "txtfechpag") {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    datePay: new Date(date).toISOString(), valueoptions: 0, rangeDate: 0
                }
            }));
        }
    }

    handlePlusDayPay = (event) => {
        let dateNow = new Date();
        let days = parseInt(event);
        dateNow.setDate(dateNow.getDate() + days);
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                datePay: format(dateNow, 'yyyy/MM/dd'),
                rangeDate: days
            }
        }));
    }

    applyFavorite = (action) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                favorite: action
            }
        }));
    }

    handleAddress = (id) => {

        if (id === this.state.form.addressid) {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    addressid: 0
                }
            }));
        }
        else {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    addressid: id
                },
                validatorDirection: '',
            }));
        }
    }

    handleContact = (id) => {
        if (id === this.state.form.contactid) {

            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    contactid: 0
                }
            }));
        }
        else {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    contactid: id
                }
            }));
        }

    }

    onClickCollapse = () => {
        if (this.state.openPanel === 'panel') {
            this.setState({
                openPanel: '',
                activeInfo: this.state.form.clientInfo.id === 0 ? false : true
            })

        } else if (this.state.openPanel === '') {
            this.setState({
                openPanel: 'panel',
                activeInfo: false
            })
        }
    }


    /**
     * Evento, Escucha evento seleción de cliente
     * @param {object} e Evento
     * @param {object} item  Información cliente
     */
    handleClientSelection(e, item) {
        e.preventDefault();
        this.setDetailClient(item);
    }

    /**
     * Carga información de cliente seleccionado
     * @param {object} item Información cliente
     */
    setDetailClient(item) {
        let arr = [{
            descripcion: "Principal",
            clienteid: item.id,
            ciudadid: item.ciudadid,
            direccion: item.direccion,
            principal: true,
            id: item.direccionid,
            idstate: 0,
            createdby: 0,
            createdon: item.createdon,
            modifiedby: 0,
            modifiedon: item.modifiedon,
            idbusinessunit: 0,
            idowner: 0,
            ciudaddescripcion: item.ciudaddescripcion,
            departamentoid: item.departamentoid,
            departamentodescripcion: item.departamentodescripcion
        }];

        this.setState((prevState) => ({
            form: {
                ...prevState.form,  
                clientInfo: item,
                addressid: item.adclientedireccion.length === 0 ? arr[0].id : 0,
                contactid: item.adcontactos.length === 1 ? item.adcontactos[0].id : 0,
                dirprincipal: arr
            },
            activeInfo: true,
            validatorClient: ''
        }));
    }


    editActionClient = (item) => {
        this.props.loadClientAction(item);
        this.setState({
            openPanel: 'panel',
            activeInfo: false,
            validatorDirection: '',
            validatorClient: ''
        });

        if (item === null) {
            this.cleanCostumer();
        }
    }

    cleanCostumer = () => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                clientInfo: {
                    documento: '',
                    documentodv: '',
                    razonsocial: '',
                    telefono: '',
                    correoelectronico: '',
                    diantipoentidadid: 0,
                    diantipopersonaid: 0,
                    empresaid: 0,
                    favorito: false,
                    diantiporegimenid: 0,
                    adclientedireccion: [],
                    adcontactos: [],
                    id: 0,
                    idstate: 0,
                    createdby: 0,
                    createdon: '',
                    modifiedby: 0,
                    modifiedon: '',
                    idbusinessunit: 0,
                    idowner: 0,
                    direccionid: 0,
                    direccion: '',
                    ciudadid: 0,
                    ciudaddescripcion: '',
                    departamentoid: 0,
                    departamentodescripcion: '',
                    text: '',
                    diantipodocumentoidentidadid: 0
                }
            }
        }));
    }

    cleanForm = () => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                visibleCredit: true,
                visibleCounted: false,
                dateInvoice: format(new Date(), 'yyyy/MM/dd'),
                datePay: format(this.newDatePay(), 'yyyy/MM/dd'),
                paymethodid: 0,
                includeVendor: true,
                clientInfo: {
                    documento: '',
                    documentodv: '',
                    razonsocial: '',
                    telefono: '',
                    correoelectronico: '',
                    diantipoentidadid: 0,
                    diantipopersonaid: 0,
                    empresaid: 0,
                    favorito: false,
                    diantiporegimenid: 0,
                    adclientedireccion: [],
                    adcontactos: [],
                    id: 0,
                    idstate: 0,
                    createdby: 0,
                    createdon: '',
                    modifiedby: 0,
                    modifiedon: '',
                    idbusinessunit: 0,
                    idowner: 0,
                    direccionid: 0,
                    direccion: '',
                    ciudadid: 0,
                    ciudaddescripcion: '',
                    departamentoid: 0,
                    departamentodescripcion: '',
                    text: ''

                },
                vendorid: '',
                branchofficeid: '',
                resolutionid: '',
                addressid: 0,
                contactid: 0,
                observations: '',
                favorite: false,
                valueoptions: 0,
                Resolution: {},
                Vendor: {},
                dirprincipal: [],
                observationid: 0,
                rangeDate: 0,
                othersPayMethod: false
            },
            editClientStatus: false,
            openPanel: '',
            activeInfo: false,
            showIncludeVendor: false,
        }
        ));
        this.state.cancelcreate();
    }

    cancelEdition = () => {
        this.cleanForm();

    }

    handleEditAddress = () => {
        this.editActionClient(this.state.form.clientInfo);
    }

    handleDeleteAddress = () => {
        this.editActionClient(this.state.form.clientInfo);
    }

    handleEditContact = () => {
        this.editActionClient(this.state.form.clientInfo);
    }

    handleDeleteContact = () => {
        this.editActionClient(this.state.form.clientInfo);
    }

     pad = (input, length, padding) => { 
        var str = input + "";
        return (length <= str.length) ? str : this.pad(padding+str, length, padding);
      }

    getInfoAditional = (directions, contacts, principal) => {
        return (
            <div>
                <div className="spacingInvoice"></div>
                <div className="spacingInvoice"></div>
                <div className="aditionalInfoTitle" >
                    <Grid container>
                        <Grid item lg={7} md={7} sm={12} style={{ textAlign: "right" }}>
                            Direcciones adicionales
                            </Grid>
                        <Grid item lg={5} md={5} sm={12} style={{ textAlign: "left" }}>

                        </Grid>
                    </Grid>
                </div>
                <div className="spacingInvoice"></div>
                <div className="spacingInvoice"></div>
                <Grid container spacing={1}>
                    {directions.map((data, index) =>
                        <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                            <CustomCardAditionalData data={data} change={Math.random()} type="directions" functionSel={this.handleAddress} selectedId={this.state.form.addressid} editAditional={this.handleEditAddress} deleteAditional={this.handleDeleteAddress} />
                        </Grid>
                    )}
                    {principal.map((data, index) =>
                        <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                            <CustomCardAditionalData data={data} change={Math.random()} type="directions" functionSel={this.handleAddress} selectedId={this.state.form.addressid} editAditional={this.handleEditAddress} deleteAditional={this.handleDeleteAddress} />
                        </Grid>
                    )}
                </Grid>
                <div className="spacingInvoice"></div>
                <div className="aditionalInfoTitle" >
                    <Grid container>
                        <Grid item lg={7} md={7} sm={12} style={{ textAlign: "right" }}>
                            Contactos adicionales
                            </Grid>
                        <Grid item lg={5} md={5} sm={12} style={{ textAlign: "left" }}>

                        </Grid>
                    </Grid>
                </div>
                <div className="spacingInvoice"></div>
                <div className="spacingInvoice"></div>
                <Grid container spacing={1}>
                    {contacts.map((data, index) =>
                        <Grid item key={index} lg={6} md={6} sm={12} xs={12} >
                            <CustomCardAditionalData data={data} type="contacts" functionSel={this.handleContact} selectedId={this.state.form.contactid} editAditional={this.handleEditContact} deleteAditional={this.handleDeleteContact} />
                        </Grid>
                    )}
                </Grid>
            </div>
        )
    }

    render() {
        return (
            <Box >
            <div style={{ color: '#FFFFFF' }}>
                <div className="spacingInvoice"></div>
                <Grid container>
                    <Grid item lg={4} md={4} sm={12}>
                        <div className="panelOverflowInvoiceCostumer" style={{ height: this.state.height }} >
                            <ListWithFilter
                                list={this.state.getClientListResponse}
                                onClick={(e, item) => {
                                    this.handleClientSelection(e, item)
                                }}
                                loading={this.state.loadingListClients}
                                top={30}
                            />
                        </div>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12}>
                        <div className="panelOverflowInvoice"  style={{ height: this.state.height }} >
                            <div >
                                <CollapsePanel
                                    component={FormClient}
                                    title={!this.state.editClientStatus ? "Crear nuevo cliente" : "Editar cliente"}
                                    titleOpen={!this.state.editClientStatus ? "Nuevo cliente" : "Editar cliente"}
                                    open={this.state.openPanel}
                                    onClick={this.onClickCollapse}
                                    type="createclient"
                                />
                            </div>
                            <div className="spacingInvoiceMini"></div>
                            <div className='cardCostumerInvoice' style={{ display: this.state.activeInfo ? 'block' : 'none', width: '99%' }} ref={(div) => this.divCliente = div}>
                                <Grid container>
                                    <Grid item lg={5} md={5} sm={12}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12} className="totalInvoiceDetail">{this.state.form.clientInfo.razonsocial}</Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12} className="fieldsReadBig">{this.state.form.clientInfo.documento}</Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item lg={6} md={6} sm={12} className="fieldsReadBig">{this.state.form.clientInfo.diantipopersonaid === 1 ? 'Persona Juridica' : 'Persona Natural'}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={1} md={1} sm={12}><div className="verticalLineInvoice"></div></Grid>
                                    <Grid item lg={6} md={6} sm={12}>
                                        <Grid container>
                                            <Grid item lg={1} md={1} sm={12}><EmailIcon className="iconColorInvoice"></EmailIcon></Grid>
                                            <Grid item lg={8} md={8} sm={12} className="titleArticleInvoiceItalic">&nbsp;&nbsp;{this.state.form.clientInfo.correoelectronico}</Grid>
                                            <Grid item lg={3} md={3} sm={12}>
                                                <Create style={{ color: "#F0F0F0", cursor: 'pointer' }}
                                                    onClick={(e) => this.editActionClient(this.state.form.clientInfo)} /> &nbsp;
                                    <Delete style={{ color: "#F0F0F0", cursor: 'pointer' }}
                                                    onClick={(e) => this.editActionClient(null)} />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item lg={1} md={1} sm={12}><PhoneIcon className="iconColorInvoice"></PhoneIcon></Grid>
                                            <Grid item lg={11} md={11} sm={12} className="titleArticleInvoiceItalic">&nbsp;&nbsp;{this.state.form.clientInfo.telefono}</Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item lg={1} md={1} sm={12}><RoomIcon className="iconColorInvoice"></RoomIcon></Grid>
                                            <Grid item lg={11} md={11} sm={12} className="titleArticleInvoiceItalic">&nbsp;&nbsp;{this.state.form.clientInfo.direccion}</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="spacingInvoice"></div>
                            <div spacing></div>
                            <Fragment>
                                <form id="invoiceDataForm"
                                    onSubmit={(e) => this.saveInvoiceData(e)}
                                    noValidate
                                    autoComplete="off">

                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12}>
                                            <DateForm
                                                id={"txtfechfac"}
                                                value={this.state.form.dateInvoice}
                                                onChange={this.handleDateForm}
                                                disablePast={true}
                                                label="Fecha Factura"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6}>
                                            <div className="miniLetterInvoice" style={{ marginLeft: '3%' }}>Forma de Pago</div>
                                            <div className="buttonGroupInovice" style={{ width: '94%', textAlign: 'center', marginLeft: '3%' }}>
                                                <button type="button" className={this.state.form.visibleCredit === true ? 'buttonGreenInvoice' : 'buttonGrayInvoice'} onClick={() => this.handlePayForm(1)}>Crédito</button>
                                                <button type="button" className={this.state.form.visibleCounted === true ? 'buttonGreenInvoice' : 'buttonGrayInvoice'} onClick={() => this.handlePayForm(2)}>Contado</button>
                                            </div>
                                        </Grid>
                                        <Grid item lg={6} md={6}>
                                            <div style={{ display: this.state.form.visibleCounted === true ? 'block' : 'none' }}>
                                                <div className={this.state.form.othersPayMethod ? 'panelInvisibleInvoice' : 'panelVisibleInvoice'}>
                                                    <SelectForm
                                                        id="dianmediopagoid"
                                                        label={"Medio de Pago"}
                                                        name="dianmediopagoid"
                                                        validator={this.validator}
                                                        validateOptions={"required"}
                                                        style={{ color: '#FFFFFF' }}
                                                        value={this.state.form.paymethodid}
                                                        options={this.state.getPaymentMethodsResponse.filter(paymet => paymet.defecto === true).map((item) => ({
                                                            value: item.value,
                                                            text: item.text,
                                                        }))}
                                                        onChange={(ev) =>
                                                            this.syncChanges(ev.target.value, "paymethodid")
                                                        }
                                                        placeholder={"Seleccione..."}
                                                    />
                                                </div>
                                                <div className={this.state.form.othersPayMethod ? 'panelVisibleInvoice' : 'panelInvisibleInvoice'}>
                                                    <SelectForm
                                                        id="dianmediopagoidfull"
                                                        label={"Medio de Pago"}
                                                        name="dianmediopagoid"
                                                        validator={this.validator}
                                                        validateOptions={"required"}
                                                        style={{ color: '#FFFFFF' }}
                                                        value={this.state.form.paymethodid}
                                                        options={this.state.getPaymentMethodsResponse.filter(paymet => paymet.value !== '999').map((item) => ({
                                                            value: item.value,
                                                            text: item.text,
                                                        }))}
                                                        onChange={(ev) =>
                                                            this.syncChanges(ev.target.value, "paymethodid")
                                                        }
                                                        placeholder={"Seleccione..."}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ display: this.state.form.visibleCredit === true ? 'block' : 'none' }}>
                                                <Grid container>
                                                    <Grid item lg={6} md={6}>
                                                        <DateForm
                                                            id={"txtfechpag"}
                                                            value={this.state.form.datePay}
                                                            onChange={this.handleDateForm}
                                                            disablePast={true}
                                                            label="Fecha de Pago"
                                                            minDate={this.newDatePay()}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <div className="spacingInvoice"></div>
                                                            <div className="miniLetterInvoice" style={{ textAlign: 'left' }}>Días</div>
                                                            <div className="buttonGroupInovice" style={{ textAlign: 'center', width: '97%' }}>
                                                                <Grid container>
                                                                    <Grid item lg={4} md={4}>
                                                                        <button type="button" style={{ width: '100%' }} className={this.state.form.rangeDate === 30 ? 'buttonGreenInvoice' : 'buttonGrayInvoice'} onClick={() => this.handlePlusDayPay(30)}>30</button>
                                                                    </Grid>
                                                                    <Grid item lg={4} md={4}>
                                                                        <button type="button" style={{ width: '100%' }} className={this.state.form.rangeDate === 60 ? 'buttonGreenInvoice' : 'buttonGrayInvoice'} onClick={() => this.handlePlusDayPay(60)}>60</button>
                                                                    </Grid>
                                                                    <Grid item lg={4} md={4}>
                                                                        <button type="button" style={{ width: '100%' }} className={this.state.form.rangeDate === 90 ? 'buttonGreenInvoice' : 'buttonGrayInvoice'} onClick={() => this.handlePlusDayPay(90)}>90</button>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item lg={2} md={2}>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6}>
                                            <SelectForm
                                                id="vendedorid"
                                                label={"Vendedor"}
                                                name="vendedorid"
                                                color="#FFFFFF"
                                                value={this.state.form.vendorid}
                                                nameSelected="Seleccione Vendedor"
                                                idSelected="0"
                                                options={this.state.getVendorsResponse.map((item) => ({
                                                    value: item.id,
                                                    text: item.nombres,
                                                }))}
                                                onChange={(ev) =>
                                                    this.syncChanges(ev.target.value, "vendorid")
                                                }
                                                placeholder={"Seleccione..."}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6}>
                                            <div className={this.state.showIncludeVendor === true ? 'panelVisibleInvoice' : 'panelInvisibleInvoice'}>
                                                <SwitchForm
                                                    id="chkVendedor"
                                                    name="chkVendedor"
                                                    value={this.state.form.includeVendor}
                                                    titleOn="Incluir datos del vendedor en la factura"
                                                    checked={this.state.form.includeVendor}
                                                    onChange={this.switchHandleChange}
                                                    validator={this.validator}
                                                    validateOptions={"required"}
                                                ></SwitchForm>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6}>
                                            <SelectForm
                                                id="sucursalid"
                                                label={"Sucursal *"}
                                                name="sucursalid"
                                                value={this.state.form.branchofficeid}
                                                validator={this.validator}
                                                validateOptions={'required'}
                                                options={this.state.getBranchOffices.map((item) => ({
                                                    value: item.id,
                                                    text: item.nombre + ' ' + item.direccion,
                                                }))}
                                                onChange={(ev) =>
                                                    this.syncChanges(ev.target.value, "branchofficeid")
                                                }
                                                placeholder={"Seleccione..."}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6}>
                                            <SelectForm
                                                id="resolucionid"
                                                label={"Resolución Factura*"}
                                                name="resolucionid"
                                                value={this.state.form.resolutionid}
                                                validator={this.validator}
                                                validateOptions={"required"}
                                                options={this.state.getNumerationsResponse.filter(num => num.sucursalempresaid === this.state.form.branchofficeid).map((item) => ({
                                                    value: item.id,
                                                    text: item.descripcion + ' - Prefijo: ' + item.prefijo + ' - ' + item.nombre + ' - ' + this.pad(new Date(item.fechafinal).getDate(), 2, "0")+'/'+this.pad((new Date(item.fechafinal).getMonth()+1),2,"0")+'/'+new Date(item.fechafinal).getFullYear(),
                                                }))}
                                                onChange={(ev) =>
                                                    this.syncChanges(ev.target.value, "resolutionid")
                                                }
                                                placeholder={"Seleccione..."}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={2}></Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item lg={12} md={12}>
                                            <InputForm
                                                id="observaciones"
                                                maxLength="1000"
                                                label={"Observaciones *"}                                                
                                                multiline={true}
                                                value={this.state.form.observations}
                                                onChange={(ev) =>
                                                    this.syncChanges(ev.target.value, "observations")
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item lg={12} md={12}>
                                            <CollapsePanelStyles
                                                component={() => this.getInfoAditional(this.state.form.clientInfo.adclientedireccion, this.state.form.clientInfo.adcontactos, this.state.form.dirprincipal)}
                                                title={"Información Adicional"}
                                                titleOpen={"Información Adicional"}
                                                titleEdit={"Información Adicional"}
                                                edit={this.state.expandPanel.edit}
                                                open={this.state.expandPanel.expandPanelInfo}
                                                cleanData={this.state.expandPanel.cleanData}
                                                backgroundColorSummary={{ backgroundColor: "#2CC63E", width: '99%', color: '#000000' }}
                                                backgroundColorDetail={{ width: '99%', backgroundColor: "#272727" }}
                                                type="showinfo"
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className="spacingInvoice"></div>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <div className="font-validator-red validatorOthers">{this.state.validatorClient}{this.state.validatorDirection}</div>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>

                                            <button className="buttonInvoiceConfirm" type="submit">Continuar</button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Fragment>
                            <div className="spacingInvoice"></div>
                        </div>
                    </Grid>
                </Grid>
                <div className="bottomButtonsCreateInvoice">
                    <Grid container >
                        <Grid itemlg={6} md={6} sm={12}>
                            <div style={{ display: this.state.form.favorite ? 'block' : 'none' }}>
                                <StarIcon className="buttonInvoiceBehavior" onClick={() => this.applyFavorite(false)}></StarIcon><br></br>
                            </div>
                            <div style={{ display: this.state.form.favorite === false ? 'block' : 'none' }}>
                                <StarBorderIcon className="buttonInvoiceBehavior" onClick={() => this.applyFavorite(true)}></StarBorderIcon><br></br>
                            </div>
                            <div className="textButtonsInvoice">Destacar</div>
                        </Grid>
                        <Grid itemlg={6} md={6} sm={12}>
                            <ClearIcon className="buttonInvoiceBehavior" onClick={() => this.props.CancelCreate(1)}></ClearIcon><br></br>
                            <div className="textButtonsInvoice">Cancelar</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            </Box>
        );
    }

}

InvoiceForm.propTypes = {
    getPaymentMethodsAction: PropTypes.func.isRequired,
    getAditionalData: PropTypes.func.isRequired,
    loadClientAction: PropTypes.func.isRequired,
    clearLoadClientAction: PropTypes.func.isRequired,
    getClientsAction: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    configReducer: state.configReducer,
    invoiceReducer: state.invoiceReducer,
    clientReducer: state.clientReducer,

})

export default connect(mapStateToProps, {
    getPaymentMethodsAction,
    getAditionalData,
    loadClientAction,
    clearLoadClientAction,
    getClientsAction
})(InvoiceForm);


