import React, { Component, Fragment } from "react"
import { connect } from 'react-redux'
import { Grid } from "@material-ui/core"

import FrecuentQuestionCard from '../../components/card/frequentQuestionsCard.component'
import InputIcon from "../../components/input/inputIcon.component";
import {getFrequentQuestionsFilter} from '../../actions/configAction';

export class FrequentQuestions extends Component {

  constructor() {
    super();
    this.state={
      frequentQuestion:[],
      txtFilter: ""
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (!!nextProps.configReducer.frequentQuestion && nextProps.configReducer.frequentQuestion !== state.frequentQuestion)
    update.frequentQuestion = nextProps.configReducer.frequentQuestion;

    if (!!nextProps.configReducer.getFilterQuestion && nextProps.configReducer.getFilterQuestion !== state.frequentQuestion)
    update.frequentQuestion = nextProps.configReducer.getFilterQuestion;

    return Object.keys(update).length ? update : null;
  }

  syncChanges(value, property) {
    this.setState((prevState) => ({
      ...prevState.txtFilter,
      [property]: value,
    }));
  }

  onChangeFilter(e) {
    e.preventDefault();
    const value = e.target.value;
    this.props.getFrequentQuestionsFilter(value, false);
  }

  render() {
    return (
      <Fragment>
        <Grid container spacing={3}>
      <Grid item lg={12} xs={12} md={12}>
        <InputIcon
          id={"txtFilter"}
          value={this.state.txtFilter}
          onChange={(e) => {
            this.syncChanges(e.target.value, "txtFilter")
            this.onChangeFilter(e)
          }}
        />
      </Grid>
      
    </Grid>
    <br/>
    <Grid container spacing={1}>

     {!!this.state.frequentQuestion && this.state.frequentQuestion.length > 0 ? this.state.frequentQuestion === undefined ? '' : this.state.frequentQuestion.map((item,index) =>(
           <Grid item md={4} lg={6} xs={12} >
           <FrecuentQuestionCard
           question = {item.question}
           description={item.question.length >56? item.answer.substring(0,125) :item.answer.substring(0,125)}
           description2={item.answer}
           />
           </Grid>
      )
      ) :'No se encontraron registros' }
    </Grid>
      </Fragment>
    )
  }
}

FrequentQuestions.propTypes = {}

const mapStateToProps = state => ({
  configReducer: state.configReducer
})

export default connect(mapStateToProps, {getFrequentQuestionsFilter})(FrequentQuestions)

