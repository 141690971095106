import React, { Component } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './routes/privateRoutes';
import PublicRoute from './routes/publicRoutes';
import { createBrowserHistory } from 'history'
import { getMenusAction, getStatesAction, getCities, getDocumentTypesAction, getRegimeTypesAction,getFrequentQuestions,getReportProblem, getSecurityPolicy, getreason,getIssue} from './actions/configAction'
import MyAccount from './views/myAccount/myAccount.view'
import Home from './views/dashboard/home.view'
import ClientsView from "./views/clients/clients.view";
import CatalogCategory from './views/catalogCategory/catalogCategory.view';
import Invoice from './views/invoice/invoice.view';
import Charges from './views/charges/charges.view';
import Discounts from './views/discount/discount.view';
import Document from './views/invoice/document.view';
import Quotation from './views/invoice/quotation/quotation.view';
import Index from './views/home/index';
import Help from './views/helps/help.view'
import { CHATBOT_WOLKVOX } from "./config/config";


let hist = createBrowserHistory()

class Startup extends Component {

    componentDidMount() {
        this.props.getMenusAction()
        this.props.getStatesAction()
        this.props.getRegimeTypesAction()
        this.props.getDocumentTypesAction()
        this.props.getCities()
        this.props.getFrequentQuestions()
        this.props.getSecurityPolicy()
        this.props.getIssue()

        //Se inicializa el Chatbot de ayuda
        const script = document.createElement("script");
        script.id = "prodId";
        script.type = "text/javascript";
        script.src = CHATBOT_WOLKVOX;
        script.async = "";

        document.body.appendChild(script);
    }

    render() {
        return (
            <BrowserRouter history={hist}>
                <Switch>
                    <PrivateRoute exact path="/dashboard" component={Home} history={hist} />
                    <PrivateRoute exact path="/myAccount" component={MyAccount} history={hist} />
                    <PrivateRoute exact path="/clients" component={ClientsView} history={hist} />
                    <PrivateRoute exact path="/catalogCategory" component={CatalogCategory} history={hist} />
                    <PrivateRoute exact path="/invoice" component={Invoice} history={hist} />
                    <PrivateRoute exact path="/charges" component={Charges} history={hist} />
                    <PrivateRoute exact path="/discounts" component={Discounts} history={hist} />
                    <PrivateRoute exact path="/document" component={Document} history={hist} />
                    <PrivateRoute exact path="/quotation" component={Quotation} history={hist} />
                    <PrivateRoute exact path="/helps" component={Help} history={hist} />
                    <PublicRoute exact path="/home/:queryString" component={Index} history={hist} />
                    <Route render={() => <Redirect to="/dashboard" />} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default connect(null,
    {
        getMenusAction,
        getCities,
        getDocumentTypesAction,
        getRegimeTypesAction,
        getStatesAction,
        getFrequentQuestions,
        getReportProblem,
        getSecurityPolicy,
        getreason,
        getIssue
    })(Startup);
