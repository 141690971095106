import React from "react";
import {
    GET_DOCUMENT_DETAIL,
    GET_TOTAL_DETAIL,
    GET_CHARGES_DOCUMENT,
    LOAD_CHARGE_DOCUMENT,
    UPDATE_CHARGE_DOCUMENT,
    OPEN_MODAL_CHARGE,
    GET_DISCOUNTS_DOCUMENT,
    LOAD_DISCOUNT_DOCUMENT,
    OPEN_MODAL_DISCOUNT,
    UPDATE_DISCOUNT_DOCUMENT,
    SHOW_CART_INVOICE,
    DOCUMENT_VALID
} from "./types"
import { DEFAULTRESPONSE } from '../config/config'
import { ErrorAlert } from '../helpers/alert.helpers'
import generateId from '../utils/generateId'


import { calculateValuesItem, calculateTotal } from '../utils/calculateInvoiceValues'


var chargesDocument = [];
var discountsDocument = [];


/**
 * Escucha cambios en  valores de cada item cargado en carro de compras,
 * actualiza listado detalle documento y recalcula totales de factura
 * @param {object} item 
 */
export const updateCartAction = (item) => async (dispatch) => {

    try {

        let newdetail = getCartToLocalStorage();
        newdetail = newdetail.filter(d => d.identificador !== item.identificador);
        newdetail.push(item);
        refreshLocalStorage(newdetail);

        let totals = calculateTotal(newdetail, chargesDocument, discountsDocument);
 
        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;


        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error actualizando carrito,", err);
        ErrorAlert("opss!!", "No se ha podido actualizar carrito");
    }
}




/**
 * Actualiza cambios en la descripción de un articulo
 * y actualiza listado en memoria
 * @param {object} item //Información item
 */
export const updateDescriptionItemCartAction = (item) => async (dispatch) => {

    try {

        let newdetail = getCartToLocalStorage();
        newdetail = newdetail.filter(d => d.identificador !== item.identificador);
        newdetail.push(item);
        refreshLocalStorage(newdetail);

    } catch (err) {
        console.error("Error actualizando descripción de item carrito,", err);
        ErrorAlert("opss!!", "No se ha podido actualizar descripción de item");
    }
}


/**
 * @action
 * @description Obtiene objecto con detalle del total de a factura
 */
export const getTotalAction = () => async (dispatch) => {

    try {
        const myDetail = getCartToLocalStorage();
        let totals = calculateTotal(myDetail, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

        //Actualiza items en Redux
        dispatch({
            type: GET_DOCUMENT_DETAIL,
            payload: totals.detalleDocumento,
        });
      
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error calculando totales de factura,", err)
        ErrorAlert("opss!!", "No se ha podido calcular el total de la factura");
    }
}


/**
 * @action
 * @description Actualiza estado para mostrar o ocultar catalogo
 * @param {boolean} status 
 */
export const setStatusShowCartAction = (status) => (dispatch) => {
    dispatch({
        type: SHOW_CART_INVOICE,
        payload: status,
    });
}


/**
 * @event 
 * @description Calcula valores iniciales, agrega listado de item seleccionados
 * a local storage, los carga en Redux
 * @param {Array} listItems 
 */
export const addArticlesToCartAction = (listItems) => async (dispatch) => {

    try {

        let totals = addArticlesToCart(listItems);
      
        //Actualiza items en Redux
        dispatch({
            type: GET_DOCUMENT_DETAIL,
            payload: totals.detalleDocumento,
        });

        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Errro agregando articulos a factura", err)
        localStorage.clear('itemsInCart');
    }
}


/**
 * Agrega listado de articulos a localstorage
 * @param {Array<object>} listItems Listado articulos
 */
export const addArticlesToCart = (listItems) => {

    let newDetailDocument = [];
    if (!!listItems && listItems.length > 0) {

        for (let i = 0; i < listItems.length; i++) {
            const item = listItems[i];
            let detailItem = calculeInitialValuesArticle(item);
            newDetailDocument.push(detailItem);
        }
    }

    //Agrega Listado a varible en memoria
    let myDetailDocument = getCartToLocalStorage();
    myDetailDocument = myDetailDocument.concat(newDetailDocument);

    //ordena listado
    myDetailDocument = myDetailDocument.sort((x, y) => {
        let a = new Date(x.dateAdded);
        let b = new Date(y.dateAdded);
        return a - b;
    })

    refreshLocalStorage(myDetailDocument);

    let totals = calculateTotal(myDetailDocument, chargesDocument, discountsDocument);

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;

    return totals;
}


/**
* @function
* @description Genera objeto y calcula valores inciales para articulo cargado en carrito
* @param {object} item
*/
function calculeInitialValuesArticle(item) {

    try {

        let itemDetail = {
            identificador: generateId(),
            codigo: item.codigo,
            descripcion: item.nombrearticulo,
            nombrearticulo: item.nombrearticulo,
            imagen: item.imagen,
            color: item.color,
            cantidad: 1,
            valorunitario: item.valor,
            valorNeto: item.neto,
            valoreditable: item.precioeditable,
            porcentajedescuento: 0,
            valordescuento: 0,
            tipodescuento: 2,
            dianunidadmedidaid: item.dianunidadmedidaid,
            articuloid: item.id,
            subtotal: 0,
            totaldescuento: 0,
            totaliva: 0,
            totalinc: 0,
            total: 0,
            documentoid: 0,
            categorias: item?.adcategoriaarticulo === null ? [] : item?.adcategoriaarticulo,
            porcentajeiva: item?.valoriva,
            porcentajeinc: item?.valorinc,

            valordescuentocomercial: 0,
            prorrateo: 0,
            totaldescuentoreal: 0,
            subtotalreal: 0,
            totalivareal: 0,
            totalincreal: 0,
            totalreal: 0,
            dateAdded: item.dateAdded

        }
        calculateValuesItem(itemDetail);
        return itemDetail;

    } catch (err) {
        console.error("Error calculando valores de articulo", err);
        throw err;
    }
}


/**
 * @function
 * @description Actualiza carrito en local storage
 * @param {Array<object>} listItems 
 */
function refreshLocalStorage(listItems) {

    //Actualiza local stotage
    localStorage.removeItem('itemsInCart');
    localStorage.setItem('itemsInCart', JSON.stringify(listItems))
}

/**
 * @function
 * @description Obtiene listado cargado en local storage
 */
function getCartToLocalStorage() {

    const temp = localStorage.getItem('itemsInCart');
    const list = temp === null ? [] : JSON.parse(localStorage.getItem('itemsInCart'));
    return list;
}



/**
 * @action
 * @description Elimina item de carro
 * @param {object} item 
 */
export const deleteItemToCartAction = (item) => async (dispatch) => {

    try {

        //Elimina de detalle documento
        let detalleTemp = getCartToLocalStorage();
        detalleTemp = detalleTemp.filter(d => d.identificador !== item.identificador);;
        refreshLocalStorage(detalleTemp);

        //Actualiza totales de factura
        let totals = calculateTotal(detalleTemp, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

    
        //Actualiza items en Redux
        dispatch({
            type: GET_DOCUMENT_DETAIL,
            payload: totals.detalleDocumento,
        });

        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error eliminando articulo de factura,", err)
        ErrorAlert("opss!!", "No se ha podido eliminar articulo de  factura");
    }
}




//BEGIN  Seccion Cargos
/**
 * @action
 * @description Agrega cargo a lista en memoria
 * @param {object} charge 
 */
export const addChargeToInvoiceAction = (charge) => async (dispatch) => {

    try {

        chargesDocument.push(charge);

        //Actualiza totales de factura
        let detalleTem = getCartToLocalStorage();
        let totals = calculateTotal(detalleTem, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });





    } catch (err) {
        console.error("Error agregando cargo a documento,", err)
        ErrorAlert("Opss!!", "Ha ocurrido un error agregando cargo a documento");
    }
}


/**
 * @action
 * @description Actualiza cargo de documento (Factura, Nota)
 * @param {object} charge 
 */
export const updateChargeToInvoiceAction = (charge) => async (dispatch) => {

    try {

        let chargesTemp = chargesDocument.filter(c => c.identificador !== charge.identificador);
        chargesTemp.push(charge);
        chargesDocument = chargesTemp;

        dispatch({
            type: UPDATE_CHARGE_DOCUMENT,
            payload: DEFAULTRESPONSE.SuccessResponse,
        })

        //Actualiza totales de factura
        let detalleTem = getCartToLocalStorage();
        let totals = calculateTotal(detalleTem, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error actualizando cargo de documento,", err)
        ErrorAlert("Opss!!", "Ha ocurrido un error actualizando cargo a documento");
    }
}


/**
 * @action
 * @description Actualiza cargo global  de documento (Factura, Nota)
 * @param {object} charge 
 */
export const updateGlobalChargeToInvoiceAction = (charge) => async (dispatch) => {

    try {

        let chargesTemp = chargesDocument.filter(c => c.identificador !== charge.identificador);
        chargesTemp.push(charge);
        chargesDocument = chargesTemp;

        dispatch({
            type: UPDATE_CHARGE_DOCUMENT,
            payload: DEFAULTRESPONSE.SuccessResponse,
        })

        //Actualiza totales de factura
        let detalleTem = getCartToLocalStorage();
        let totals = calculateTotal(detalleTem, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;


        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error actualizando cargo global de documento,", err)
        ErrorAlert("Opss!!", "Ha ocurrido un error actualizando cargo global a documento");
    }
}

/**
 * @action
 * @description Elimina cargo de documento
 * @param {object} charge 
 */
export const deleteChargeToInvoiceAction = (charge) => async (dispatch) => {

    try {

        let chargesTemp = chargesDocument.filter(c => c.identificador !== charge.identificador);
        chargesDocument = chargesTemp;

        //Actualiza totales de factura
        let detalleTem = getCartToLocalStorage();
        let totals = calculateTotal(detalleTem, chargesDocument, discountsDocument);
        
        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;


        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error eliminando cargo de documento,", err)
        ErrorAlert("Opss!!", "Ha ocurrido un error eliminando cargo de documento");
    }
}

/**
 * @action
 * @description Obtiene listado de cargos agregados a  un documento
 */
export const getChargesInvoiceAction = () => async (dispatch) => {

    dispatch({
        type: GET_CHARGES_DOCUMENT,
        payload: chargesDocument,
    });
}

/**
 * @action
 * @description Carga Objeto cargo en redux para edición
 * @param {object} charge 
 */
export const loadChargeInvoiceAction = (charge) => async (dispatch) => {

    dispatch({
        type: LOAD_CHARGE_DOCUMENT,
        payload: charge,
    });
}

/**
 * @action
 * @description Limpia objeto cargo de redux
 */
export const clearLoadedChargeInvoiceAction = () => async (dispatch) => {


    dispatch({
        type: UPDATE_CHARGE_DOCUMENT,
        payload: null,
    })

    dispatch({
        type: LOAD_CHARGE_DOCUMENT,
        payload: null,
    });
}

/**
 * @action
 * @description Limpia objeto cargo de redux, se inicializa
 */
export const clearLoadedChargeInvoiceActionAll = () => async (dispatch) => {


    dispatch({
        type: UPDATE_CHARGE_DOCUMENT,
        payload: null,
    })

    dispatch({
        type: LOAD_CHARGE_DOCUMENT,
        payload: null,
    });

    dispatch({
        type: GET_CHARGES_DOCUMENT,
        payload: null,
    });

    chargesDocument = [];
}

/**
 * @action
 * @description Aigna estado de modal para gestión cargo (abrir, cerrar)
 */
export const openModalChargeAction = (status) => async (dispatch) => {

    dispatch({
        type: OPEN_MODAL_CHARGE,
        payload: status,
    })
}

//END Seccion Cargos



//BEGIN Seccion Descuentos

/**
 * @action
 * @description Agrega descuento a lista en memoria,
 * actualiza listado en Redux
 * @param {object} discount 
 */
export const addDiscountToInvoiceAction = (discount) => async (dispatch) => {

    try {

        discountsDocument.push(discount);

        //Actualiza totales de factura
        let detalleTem = getCartToLocalStorage();
        let totals = calculateTotal(detalleTem, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error agregando descuento a documento,", err)
        ErrorAlert("Opss!!", "Ha ocurrido un error agregando descuento a documento");
    }
}


/**
 * @action
 * @description Actualiza descuento de documento (Factura, Nota)
 * @param {object} discount 
 */
export const updateDiscountToInvoiceAction = (discount) => async (dispatch) => {

    try {

        let discountsTemp = discountsDocument.filter(c => c.identificador !== discount.identificador);
        discountsTemp.push(discount);
        discountsDocument = discountsTemp;

        dispatch({
            type: UPDATE_DISCOUNT_DOCUMENT,
            payload: DEFAULTRESPONSE.SuccessResponse,
        })


        //Actualiza totales de factura
        let detalleTem = getCartToLocalStorage();
        let totals = calculateTotal(detalleTem, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error actualizando descuento de documento,", err)
        ErrorAlert("Opss!!", "Ha ocurrido un error descuento cargo a documento");
    }
}

/**
 * @action
 * @description Elimina descuento de documento
 * @param {object} discount 
 */
export const deleteDiscountToInvoiceAction = (discount) => async (dispatch) => {

    try {

        let discuntsTemp = discountsDocument.filter(c => c.identificador !== discount.identificador);
        discountsDocument = discuntsTemp;
    
        //Actualiza totales de factura
        let detalleTem = getCartToLocalStorage();
        let totals = calculateTotal(detalleTem, chargesDocument, discountsDocument);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

        //Actualiza totales en Redux
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });


    } catch (err) {
        console.error("Error eliminando descuento de documento,", err)
        ErrorAlert("Opss!!", "Ha ocurrido un error eliminando descuento de documento");
    }
}

/**
 * @action
 * @description Obtiene listado de cargos agregados a  un documento
 */
export const getDiscountsInvoiceAction = () => async (dispatch) => {

    dispatch({
        type: GET_DISCOUNTS_DOCUMENT,
        payload: discountsDocument,
    });

}

/**
 * @action
 * @description Carga objeto descuento en redux para edición
 * @param {object} discount 
 */
export const loadDiscountInvoiceAction = (discount) => async (dispatch) => {

    dispatch({
        type: LOAD_DISCOUNT_DOCUMENT,
        payload: discount,
    });
}

/**
 * @action
 * @description Limpia objeto cargo de redux
 */
export const clearLoadedDiscountInvoiceAction = () => async (dispatch) => {

    dispatch({
        type: UPDATE_DISCOUNT_DOCUMENT,
        payload: null,
    })

    dispatch({
        type: LOAD_DISCOUNT_DOCUMENT,
        payload: null,
    });
}

/**
 * @action
 * @description Limpia objeto cargo de redux, iniciliazar
 */
export const clearLoadedDiscountInvoiceActionAll = () => async (dispatch) => {

    dispatch({
        type: UPDATE_DISCOUNT_DOCUMENT,
        payload: null,
    })

    dispatch({
        type: LOAD_DISCOUNT_DOCUMENT,
        payload: null,
    });

    dispatch({
        type: GET_DISCOUNTS_DOCUMENT,
        payload: null,
    });

    discountsDocument = [];
}

/**
 * @action
 * @description Asigna estado de modal para gestión cargo (abrir, cerrar)
 */
export const openModalDiscountAction = (status) => async (dispatch) => {

    dispatch({
        type: OPEN_MODAL_DISCOUNT,
        payload: status,
    })
}

//End Seccion Descuentos

/**
 * Carga estado  de carrito en redux (valido invalido) dependiedo de si existen o no
 * valores negativos en totales
 * @param {boolean} status Estado de carrto
 */
export const validateTotalDocumentAction = (status) => async (dispatch) => {
    dispatch({
        type: DOCUMENT_VALID,
        payload: status,
    })
}

/**
 * Carga estado  de carrito en redux (valido invalido) dependiedo de si existen o no
 * valores negativos en totales
 * @param {boolean} status Estado de carrto
 */
export const calculeTotalNote = (detailDocument, charges, discounts) => async (dispatch) => {
    
    try {        
        refreshLocalStorage(detailDocument);
        discountsDocument = [];
        chargesDocument = [];

        let totals = calculateTotal(detailDocument, charges, discounts);

        //Actualiza lista descuentos y cargos en memoria
        discountsDocument = totals.otrosdescuentos;
        chargesDocument = totals.cargos;

        //Actualiza items en Redux
        dispatch({
            type: GET_DOCUMENT_DETAIL,
            payload: totals.detalleDocumento,
        });
      
        dispatch({
            type: GET_TOTAL_DETAIL,
            payload: totals,
        });

    } catch (err) {
        console.error("Error calculando totales al editar la factura,", err)
        ErrorAlert("opss!!", "No se ha podido calcular el total de la factura");
    }
}





