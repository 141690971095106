
import isEmpty from '../utils/isEmpty'

/**
 * @function DEVUELVE DESCRIPCIÓN DE TIPO DOCUMENTO IDENTIFICACIÓN
 * @param {*} value Identificador tipo documento identidad
 */
const documentDescription = (value) => {
    if (value === null || value === undefined || isEmpty(value))
        return 'NA';

    switch (value) {
        case 1:
            return 'RC.'
        case 2:
            return 'TI.'
        case 3:
            return 'CC.'
        case 4:
            return 'TE.'
        case 5:
            return 'CE.'
        case 6:
            return 'NIT.'
        case 7:
            return 'PA.'
        case 8:
            return 'DE.'
        default:
            return ''
    }
}

export default documentDescription;