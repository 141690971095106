import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '5px',
        color: theme.palette.primaryColor,
        border: 'none'
    },
    title: {
        fontSize: 16,
        color: '#393939',
        fontWeight: 'bold',
    },
    body: {
        width: '100%'
    },
    icon: {

    }
}));


const ExpansionPanel = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        borderRadius: 5,
        backgroundColor: 'transparent',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },

    },
    expanded: {

    },


})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#2CC63E',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: 5,
        '& > .MuiExpansionPanelSummary-content': {
            display: 'block',
            textAlign: 'center'
        },
        '& > .MuiExpansionPanelSummary-expandIcon': {
            padding: '5px 12px'
        }
    },
    content: {},
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.thirdBackgroundColor
    },
}))(MuiExpansionPanelDetails);



const SecondCollapse = ({ component: Component, ...props }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('');
    const { open, title } = props;


    useEffect(() => {
        if (open !== '')
            setExpanded('panel');

    }, [open]);


    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <ExpansionPanel className={classes.root} square expanded={expanded === 'panel'} onChange={handleChange('panel')}>
            <ExpansionPanelSummary
                aria-controls="paneld-content"
                id="paneld-header"
                expandIcon={<ExpandMoreIcon fontSize="large" />}>
                <Typography  className={classes.title}>
                    {title}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography  component={'div'} className={classes.body}>
                    <Component />
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}

SecondCollapse.propTypes = {

};

export default SecondCollapse;
