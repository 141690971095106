import axios from 'axios';
import {
    GET_STATES,
    GET_CITIES,
    GET_CITIES_STATE,
    GET_ALL_CITIES,
    GET_DOCUMENT_TYPES,
    GET_REGIME_TYPES,
    GET_ERRORS,
    GET_MENU,
    GET_COMPONENTS,
    GET_PAYMENT_METHODS,
    GET_CITIES_COUNTRY,
    GET_MENU_COMPONENT,
    GET_FREQUENT_QUESTIONS,
    GET_REPORT_PROBLEM,
    GET_SECURITY_POLICY,
    SEND_MAIL,
    GET_QUESTION_FILTER,
    GET_REASON,
    SEND_MAIL_CONTACT
} from './types'
import { API_ENDPOINT_CONFIGURATION, DEFAULTRESPONSE } from '../config/config';
import { capitalizeUpperLetters } from '../utils/general';
import {fullNameUser, emailUser} from './authActions';

const urlConfiguration = `${API_ENDPOINT_CONFIGURATION}/config/api`;

var states = [];
var cities = [];
var documentTypes = [];
var regimeTypes = [];
var menus = [];
var paymentMethods = [];
var allCitiesCountry = [];
var menuComponent=[];
var allFrequentQuestions = [];
var dataIssue = [];
var dataReason = []

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
};

/**
 * Obtiene listado de departamentos por identificador de pais,
 * valida si ya se ha consultado información de api, evitando rescosultas inecesarias
 */
export const getStatesAction = () => async dispatch => {

    if (states.length > 0) {

        dispatch({
            type: GET_STATES,
            payload: states
        });

    } else {

        try {

            const url = `${urlConfiguration}${'/DaneDepartamento/GetDepartamentoByPaisId'}`;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'paisid': 1
                }
            };
            var res = await axios.get(url, config);

            states = res.data.map((item) => {
                return {
                    value: item.id.toString(),
                    text: item.descripcion.toUpperCase()
                }
            });

            dispatch({
                type: GET_STATES,
                payload: states
            });


        } catch (err) {
            const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
            dispatch({
                type: GET_ERRORS,
                payload: response,
                payload1: 'Error consultando listado de departamentos'
            });
        }
    }
}

export const getCitiesAction = (departamentoid) => async dispatch => {


    if (cities.length > 0) {
        dispatch({
            type: GET_CITIES,
            payload: cities.filter(c => c.departamentoid === parseInt(departamentoid, 10))
        });

    } else {
        const url = `${urlConfiguration}${'/DaneCiudad'}`;
        await axios.get(url, config)
            .then(res => {
                cities = res.data.map((item) => {
                    return {
                        ...item,
                        value: item.id.toString(),
                        text: capitalizeUpperLetters(item.descripcion),
                        departamentoid: item.departamentoid
                    }
                })
                dispatch({
                    type: GET_CITIES,
                    payload: cities.filter(c => c.departamentoid === parseInt(departamentoid, 10))
                });
            })
            .catch(err => {
                const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
                dispatch({
                    type: GET_ERRORS,
                    payload: response,
                    payload1: 'Error consultando listado de ciudades', // ? Temporal
                });
            });
    }
}

/**
 * Obtiene listado de ciudades de un pais
 */
export const getAllCitiesAction = () => async dispatch => {

    if (allCitiesCountry.length > 0) {
        dispatch({
            type: GET_CITIES_COUNTRY,
            payload: allCitiesCountry
        });

    } else {

        try {
            const url = `${urlConfiguration}${'/DaneCiudad'}`;
            var res = await axios.get(url, config);

            allCitiesCountry = res.data.map((item) => {
                return {
                    ...item,
                    value: item.id.toString(),
                    text: item.descripcion,
                    departamentoid: item.departamentoid
                }
            })

            dispatch({
                type: GET_CITIES_COUNTRY,
                payload: allCitiesCountry
            });
        } catch (err) {
            const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
            dispatch({
                type: GET_ERRORS,
                payload: response,
                payload1: 'Error consultando listado de ciudades', 
            });

        }
    }
}

export const getCities = (departamentoid) => async dispatch => {
    if (cities.length > 0) {
        const allCities = cities.filter(c => c.departamentoid === Number.parseInt(departamentoid))
        dispatch({
            type: GET_CITIES_STATE,
            payload: allCities.map(city => {
                return {
                    ...city,
                    value: city.id.toString(),
                    text: capitalizeUpperLetters(city.descripcion),
                    departamentoid: city.departamentoid
                }
            })
        });

    } else {
        const url = `${urlConfiguration}${'/DaneCiudad'}`;
        await axios.get(url)
            .then(res => {
                cities = res.data
                dispatch({
                    type: GET_ALL_CITIES,
                    payload: res.data
                });
            })
            .catch(err => {
                const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
                dispatch({
                    type: GET_ERRORS,
                    payload: response,
                    payload1: 'Error consultando listado de ciudades', // ? Temporal
                });
            });
    }
}

export const getDocumentTypesAction = () => async dispatch => {

    if (documentTypes.length > 0) {
        dispatch({
            type: GET_DOCUMENT_TYPES,
            payload: documentTypes
        });

    } else {
        const url = `${urlConfiguration}${'/DianTipDocIdentidad'}`;
        await axios.get(url, config)
            .then(res => {
                documentTypes = res.data.map(item => {
                    return {
                        value: item.id.toString(),
                        text: item.descripcion,
                    }
                });
                documentTypes = documentTypes.filter(d => d.value !== '9');

                dispatch({
                    type: GET_DOCUMENT_TYPES,
                    payload: documentTypes
                });
            })
            .catch(err => {
                const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
                dispatch({
                    type: GET_ERRORS,
                    payload: response,
                    payload1: 'Error consultando listado tipos de identifdicación', // ? Temporal
                });
            });
    }

}

export const getRegimeTypesAction = () => async dispatch => {
    if (documentTypes.length > 0) {
        dispatch({
            type: GET_REGIME_TYPES,
            payload: regimeTypes
        });

    } else {
        const url = `${urlConfiguration}${'/DianTipoRegimen'}`;
        await axios.get(url, config)
            .then(res => {
                // console.log("Tipod de REgimen--->", res.data)
                regimeTypes = res.data.map(item => {
                    return {
                        value: item.id.toString(),
                        text: item.descripcion,
                    }
                });

                dispatch({
                    type: GET_REGIME_TYPES,
                    payload: regimeTypes
                });

            })
            .catch(err => {
                const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
                dispatch({
                    type: GET_ERRORS,
                    payload: response,
                    payload1: 'Error consultando listado tipos de regimen', // ? Temporal
                });

            });
    }

}

export const getMenusAction = () => (dispatch) => {
    
    axios.get(`${urlConfiguration}${'/menu'}`).then((response) => {
       
        menus = response.data
        dispatch({
            type: GET_MENU,
            payload: response.data
        })
    }).catch(err => {
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'Error consultando listado menus configurables', // ? Temporal
        });
    })
}


// function changeLoading(dispatch, status) {
//     dispatch({
//         type: LOADING,
//         payload: status
//     })
// }

export const getComponentsAction = (id) => (dispatch) => {
    const components = menus.find(element => element.id === id)
    if (!!components) {
        dispatch({
            type: GET_COMPONENTS,
            payload: components.adcomponente,
        })
    }
}

/**
 * Consulta información de menus configurables y filtra por componente
 * @param {*} id Identificador de componente
 */
export const getMenuComponentAction = (id) => async (dispatch) => {
    try {
        if (menuComponent.length > 0) {

            let component = menuComponent.find(e => e.id === id)?.adcomponente;
            dispatch({
                type: GET_MENU_COMPONENT,
                payload: component
            })

        } else {
            var response = await axios.get(`${urlConfiguration}${'/menu'}`);
            menuComponent = response.data
            let component = menuComponent.find(e => e.id === id)?.adcomponente;

            dispatch({
                type: GET_MENU_COMPONENT,
                payload: component
            })
        }

    } catch (err) {
        console.log('Error consultando listado menus configurables, por componente');
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'Error consultando listado menus configurables, por componente',
        });
    }
}

export const cleanMenuComponentAction = () => async (dispatch) => {
    dispatch({
        type: GET_MENU_COMPONENT,
        payload: [],
    })
}



export const ClearGetComponentsAction = () => (dispatch) => {
    dispatch({
        type: GET_COMPONENTS,
        payload: [],
    })
}

export const getPaymentMethodsAction = () => async dispatch => {
    if (paymentMethods.length > 0) {
        dispatch({
            type: GET_PAYMENT_METHODS,
            payload: paymentMethods
        });

    } else {
        const url = `${urlConfiguration}${'/DianMedioPago'}`;
        await axios.get(url)
            .then(res => {
                paymentMethods = res.data.map(item => {
                    return {
                        value: item.id.toString(),
                        text: item.descripcion,
                        defecto: item.defecto
                    }
                });
                paymentMethods.push({
                    value: 0,
                    text: "Seleccione",
                    defecto: true
                });
                dispatch({
                    type: GET_PAYMENT_METHODS,
                    payload: paymentMethods
                });
            })
            .catch(err => {
                const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
                dispatch({
                    type: GET_ERRORS,
                    payload: response,
                    payload1: 'Error consultando listado medios de pago', // ? Temporal
                });
            });
    }
}

export const filterByPlace = (ciudadid, filter) => {
    let city = cities.find(city => city.id === ciudadid)
    let state = !!city ? states.find(state => Number.parseInt(state.value) === city.departamentoid) : null
    let result = -1
    if (!!city && result < 0)
      result = city.descripcion.toLowerCase().indexOf(filter)
    if (!!state && result < 0)
      result = state.text.toLowerCase().indexOf(filter)
    return result
  }

  export const getFrequentQuestions= () => (dispatch) => {
    axios.get(`${urlConfiguration}${'/menu/faq'}`).then((response) => {
        allFrequentQuestions = response.data.result
        dispatch({
            type: GET_FREQUENT_QUESTIONS,
             payload: response.data.result
        })
    }).catch(err => {
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'Error consultando listado de preguntas frecuentes', // ? Temporal
        });
    })
}


export const getReportProblem = () => (dispatch) => {
    let data =  []
      dataIssue.map(item=>{
          let items = {
              value : item.id,
              text : item.descripcion
          }
          data.push(items)
        }
      )
        dispatch({
            type: GET_REPORT_PROBLEM,
             payload :data,
             payload1:dataIssue,
             payload2: fullNameUser(),
             payload3: emailUser()
        })
}

export const getreason = () => async (dispatch) => {
           
    let reasonData =[]        
        dataReason.map(item=>{
        let items = {
            value : item.id,
            text : item.descripcion
        }
        reasonData.push(items)
      }
    )
        dispatch({
            type: GET_REASON,
             payload :reasonData,
             payload1: dataReason,
             payload2: emailUser()
        })
}

export const getSecurityPolicy= () => (dispatch) => {
    axios.get(`${urlConfiguration}${'/menu/securityPoli'}`).then((response) => {
        let policy= ""

        response.data.result.map(item =>{
            policy= item.description
        })
        dispatch({
            type: GET_SECURITY_POLICY,
             payload: policy
        })
    }).catch(err => {
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'Error consultando listado de preguntas frecuentes', // ? Temporal
        });
    })
}

export const sendMailReport= (body,value) => (dispatch) => {
    axios.post(`${urlConfiguration}${'/menu/send'}`,body).then((response) => {
        dispatch({
            type: SEND_MAIL,
            payload: response.data
        })
    }).catch(err => {
        console.log("Error consultando las facturas disponibles", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido cargar la cantidad de facturas disponibles'
        });
    })
}

export const sendMailContact= (body) => (dispatch) => {
    axios.post(`${urlConfiguration}${'/menu/send'}`,body).then((response) => {
        dispatch({
            type: SEND_MAIL_CONTACT,
            payload: response.data
        })
    }).catch(err => {
        console.log("Error enviando el correo , para contactarnos", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido enviar el correo , par la sección de contactenos'
        });
    })
}

export const getFrequentQuestionsFilter = (filter = '', refresh = true) => (dispatch) => {
    if (allFrequentQuestions.length > 0 && !refresh) {
      dispatch({
        type: GET_QUESTION_FILTER,
        payload: filerList(allFrequentQuestions, filter),
    });
    } 
}
  

function filerList(list, filter) {
    try {
  
      if (!list) return list;
  
      const data = list.filter(x => {
        const question = x.question.toLowerCase();
        const answer = x.answer.toLowerCase();
        const query = `${question} ${answer}`;
        const modValue = filter.toLowerCase();
        return query.indexOf(modValue) > -1;
      });
      return data;
    } catch (err) {
      console.log("Error filtrando el listado de preguntas frecuentes:", err);
    }
  }


  export const getIssue= () => (dispatch) => {
    axios.get(`${urlConfiguration}${'/menu/issue'}`).then((response) => {
        response.data.result.map(item =>{
            if(item.tipo === 1){
               dataIssue.push(item)
            }else{
                dataReason.push(item)
            }
        })
    }).catch(err => {
        console.log("Error consultando los asuntos ", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido cargar los asuntos.'
        });
    })
}