import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";

import { Grid } from "@material-ui/core";

import SwitchForm from "../../components/switch/switch.component.js";
import SelectForm from "../../components/select/select.component";
import InputForm from "../../components/input/input.component";
import TooltipMessage from "../../components/tooltip/tootltip-message.component";
import ResponseModal from '../../components/modal/responseModal.component';
import ButtonPrimary from '../../components/button/buttonPrimary.component'
import ToggleButtons from '../../components/toggle/toggle.component';
import CustomProgress from '../../components/Progress/progress.component'


import { updateBasicDataAction, getCompanyAction } from "../../actions/basicDataActions";

import { regexEmail, regexAlphaNumeric, regexPhone, regexOnlyNumbers, regexLettersAndNumbers } from "../../helpers/customRegex.hepers";
import { SuccessAlert } from "../../helpers/alert.helpers";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { getComponentName, getComponentUrl } from "../../utils/general.js";
import isEmpty from '../../utils/isEmpty'

export class BasicData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      form: {
        diantipodocumentoidentidadid: '',
        documento: '',
        documentodv: '',
        diantipopersonaid: '1',
        diantiporegimenid: '',
        razonsocial: '',
        correoelectronico: '',
        telefono: '',
        observacion: '',
        aplicafe: false,
        testid: '',
        correoelectronicofacturas: '',
      },

      responseModal: {
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: 0
      },

      saveBasicDataResponse: {},

      requestCompany: {},

      options: [],
      optionsRegimen: [],
      loadingSaveBasicData: false,
      loadingGetBasicData: false

    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.configReducer.components && nextProps.configReducer.components !== state.components)
      update.components = nextProps.configReducer.components;

    if (!!nextProps.configReducer.getDocumentTypesResponse && nextProps.configReducer.getDocumentTypesResponse !== state.options)
      update.options = nextProps.configReducer.getDocumentTypesResponse;

    if (!!nextProps.configReducer.getRegimeTypesResponse && nextProps.configReducer.getRegimeTypesResponse !== state.optionsRegimen)
      update.optionsRegimen = nextProps.configReducer.getRegimeTypesResponse;

    if (!!nextProps.basicDataReducer.requestSaveBasicData && nextProps.basicDataReducer.requestSaveBasicData !== state.saveBasicDataResponse)
      update.saveBasicDataResponse = nextProps.basicDataReducer.requestSaveBasicData;

    if (!!nextProps.basicDataReducer.requestCompany && nextProps.basicDataReducer.requestCompany !== state.requestCompany) {
      if (Object.keys(nextProps.basicDataReducer.requestCompany).length) {
        update.requestCompany = nextProps.basicDataReducer.requestCompany;
        let data = nextProps.basicDataReducer.requestCompany;
        update.form = {
          diantipodocumentoidentidadid: data.diantipodocumentoidentidadid.toString(),
          documento: data.documento,
          documentodv: data.documentodv,
          correoelectronico: data.correoelectronico,
          telefono: data.telefono,
          diantiporegimenid: data.diantiporegimenid.toString(),
          diantipopersonaid: data.diantipopersonaid === 1 ? '1' : '2',
          aplicafe: data.aplicafe,
          observacion: data.observacion,
          testid: data.testid,
          razonsocial: data.razonsocial,
        }
      }
    }
  

    if (!isEmpty(nextProps.basicDataReducer.loadingSaveBasicData) && nextProps.basicDataReducer.loadingSaveBasicData !== state.loadingSaveBasicData)
      update.loadingSaveBasicData = nextProps.basicDataReducer.loadingSaveBasicData;

    if (!isEmpty(nextProps.basicDataReducer.loadingGetBasicData) && nextProps.basicDataReducer.loadingGetBasicData !== state.loadingGetBasicData)
      update.loadingGetBasicData = nextProps.basicDataReducer.loadingGetBasicData;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.saveBasicDataResponse !== this.state.saveBasicDataResponse) {
      if (this.state.saveBasicDataResponse.statusCode === '201') {
        SuccessAlert(
          getComponentName(this.state.components, 14, 20, 'Genial!!!'),
          getComponentName(this.state.components, 14, 21, 'Configuraste tus datos básicos correctamente.'));
        this.props.getCompanyAction();

      } else if (this.state.saveBasicDataResponse.statusCode === '252') {
        this.setState({
          responseModal: {
            type: 'warning',
            title: getComponentName(this.state.components, 88, 141, 'Oops!!!'),
            subtitle: getComponentName(this.state.components, 88, 142, 'Test set id incorrecto'),
            body: <div dangerouslySetInnerHTML={{ __html: getComponentName(this.state.components, 88, 143, 'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y rectifica el identificador del set de pruebas.') }}></div>,
            modalImage: 'warning',
            open: true,
            closeElement: getComponentName(this.state.components, 88, 145, 'Skip'),
            onCloseElement: 0
          },
        })
      } else if (this.state.saveBasicDataResponse.statusCode === '253') {
        this.setState({
          responseModal: {
            type: 'warning',
            title: getComponentName(this.state.components, 89, 146, 'Oops!!!'),
            subtitle: getComponentName(this.state.components, 89, 147, 'Aun no tienes registrado a Colfactura como tu software de facturación electrónica en Dian'),
            body: <div dangerouslySetInnerHTML={{ __html: getComponentName(this.state.components, 89, 148, 'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y realiza el proceso de registro como facturador electrónico seleccionando a Gestión de Seguridad Electrónica S.A (Colfactura) como tu proveedor tecnológico. ¿Tienes dudas de cómo realizar el proceso? <a style="color:#16B1F3;" href="https://www.youtube.com/watch?v=Om59dTlnD6c" target="_blank">Apréndelo aquí</a>.') }}></div>,
            modalImage: 'warning',
            open: true,
            closeElement: getComponentName(this.state.components, 89, 149, 'Skip'),
            onCloseElement: 0
          },
        })
      } else if (this.state.saveBasicDataResponse.statusCode === '250') {
        this.setState({
          responseModal: {
            type: 'success',
            title: getComponentName(this.state.components, 14, 20, 'Genial!!!'),
            subtitle: getComponentName(this.state.components, 14, 21, 'Configuraste tus datos básicos correctamente.'),
            body: <div dangerouslySetInnerHTML={{ __html: getComponentName(this.state.components, 14, 22, 'Solo te faltan 4 items para terminar de configurar "MI CUENTA"') }}></div>,
            modalImage: 'successBasicData',
            open: true,
            closeElement: getComponentName(this.state.components, 14, 23, 'Skip'),
            onCloseElement: 0
          },
        })
      }
    }

  }


  /**
   * Sincroniza nuevo valor de input con state
   * @param {*} e Evento
   */
  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  };


  /**
   * Sincroniza nuevo valor de checkbox con state
   * @param {*} e 
   */
  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked
      }
    }))
  };



  /**
   * Verifica si caracteres invalidos y da formato al campo TestID
   * @param {object} e Evento
   */
  syncChangesTestId(e) {
    e.preventDefault();
    const name = e.target.name;
    let value = e.target.value;

    let guid = '';
    let str = value;
    const regex = /[^a-f0-9\s]+/g;
    str = str.replace(regex, '');
    str = str.trim();

    let one = str.slice(0, 8);
    let two = str.slice(8, 12);
    let three = str.slice(12, 16);
    let four = str.slice(16, 20);
    let five = str.slice(20, 32);

    two = two.length > 0 ? '-' + two : '';
    three = three.length > 0 ? '-' + three : '';
    four = four.length > 0 ? '-' + four : '';
    five = five.length > 0 ? '-' + five : '';
    guid = one + two + three + four + five;


    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: guid
      }
    }))
  }


  /**
   * Sincroniza nuevo valor toogle con state
   * @param {*} event Evento
   * @param {*} newAlignment Nuevo valor 
   */
  onChangeTooggle = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        diantipopersonaid: !!newAlignment ? newAlignment : '1'
      }
    }))

  };

  /**
   * Limpia caracteres invalidos para correo electronico
   * @param {*} e Evento
   */
  validateEmail(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexEmail, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }

  /**
   * Limpia caracteres invalidos para telefono
   * @param {*} e Evento
   */
  validatePhone(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexPhone, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }


  /**
   * Limpia caracteres invalidos para valores alfanumericos
   * @param {*} e Evento
   */
  validateAlphaNumeric(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexAlphaNumeric, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }


  /**
   * Limpia caracteres invalidos para valores numericos
   * @param {*} e Evento
   */
  validateOnlyNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexOnlyNumbers, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }


  /**
   * Limpia caracteres invalidos, solo letras y numeros
   * @param {*} e Evento
   */
  validateLettersAndNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexLettersAndNumbers, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }



  handleResponseModal = () => {
    this.setState({
      responseModal: {
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: ''
      }
    });
  }


  /**
   * Captura y actualiza información de empresa.
   */
  saveBasicData = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      let data = {
        ...this.state.requestCompany,
        diantipodocumentoidentidadid: this.state.form.diantipodocumentoidentidadid,
        documento: this.state.form.documento,
        documentodv: this.state.form.documentodv,
        diantipopersonaid: this.state.form.diantipopersonaid === '1' ? 1 : 2,
        diantiporegimenid: this.state.form.diantiporegimenid,
        razonsocial: this.state.form.razonsocial,
        correoelectronico: this.state.form.correoelectronico,
        telefono: this.state.form.telefono,
        observacion: this.state.form.observacion,
        aplicafe: this.state.form.aplicafe,
        testid: this.state.form.testid,
        numeraciones: [],
        sucursales: [],
      }
      this.props.updateBasicDataAction(data);

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };


  render() {
    return (
      <Fragment>
        <br></br>

        {this.state.loadingGetBasicData === true ?
          <Grid container alignItems="center" justify="center">
            <CustomProgress />
          </Grid> :
          <form id="basicDataForm" onSubmit={(e) => this.saveBasicData(e)} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <SelectForm
                  label={"Tipo documento *"}
                  name="diantipodocumentoidentidadid"
                  value={this.state.form.diantipodocumentoidentidadid}
                  options={this.state.options}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) => this.syncChanges(e)}
                  disabled={true}
                />
              </Grid>

              {/* Numero de documento */}
              <Grid item lg={2} xs={12}>
                <InputForm
                  name="documento"
                  label={"Número de documento *"}
                  maxLength={20}
                  value={this.state.form.documento}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.state.form.diantipodocumentoidentidadid === '7' ? this.validateLettersAndNumbers(e) : this.validateOnlyNumbers(e);
                  }}
                  validator={this.validator}
                  validateOptions={"required|documento"}
                  disabled={true}
                />
              </Grid>

              {/* Numero de verificacion */}
              {this.state.form.diantipodocumentoidentidadid === '6' ? (
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <InputForm
                    name="documentodv"
                    label={"Número de verificación"}
                    maxLength={20}
                    value={this.state.form.documentodv}
                    disabled
                    onChange={(e) => {
                      this.syncChanges(e);
                      this.validateOnlyNumbers(e);
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Grid container spacing={2}>
              {this.state.form.diantipodocumentoidentidadid === '6' ? (
                <Grid item lg={4} xs={12}>
                  <ToggleButtons
                    label={"Tipo de persona"}
                    value={this.state.form.diantipopersonaid}
                    onChange={this.onChangeTooggle}
                    titleOne={'Jurídica '}
                    titleTwo={'Natural'}
                    txtTooltip={
                      <TooltipMessage
                        title={getComponentName(this.state.components, 12, 16, '¿Qué es el Test set Id?')}
                        message={getComponentName(this.state.components, 12, 17, '¿Qué es el Test set Id?')}
                        botton={getComponentName(this.state.components, 12, 18, '¿Qué es el Test set Id?')}
                        href={getComponentUrl(this.state.components, 12, 18, null)}
                      />
                    }
                  />
                </Grid>
              ) : ''}

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputForm
                  name={"razonsocial"}
                  label={this.state.form.diantipopersonaid === '1' ? "Razón Social *" : "Nombre(s) y Apellido(s) *"}
                  maxLength={200}
                  value={this.state.form.razonsocial}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validateAlphaNumeric(e);
                  }}
                  nameValidator={'nombreEmpresa'}
                  validator={this.validator}
                  validateOptions={'required|min:3'}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <SelectForm
                  label={"Régimen"}
                  name="diantiporegimenid"
                  value={this.state.form.diantiporegimenid}
                  options={this.state.optionsRegimen}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) => this.syncChanges(e)}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputForm
                  name="correoelectronico"
                  maxLength={200}
                  label={"Correo electrónico"}
                  value={this.state.form.correoelectronico}
                  validator={this.validator}
                  validateOptions={"required|correo"}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validateEmail(e);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputForm
                  name={"telefono"}
                  label={"Teléfono *"}
                  maxLength={20}
                  value={this.state.form.telefono}
                  validator={this.validator}
                  validateOptions={"required|phone"}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validatePhone(e);
                  }}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <SwitchForm
                  name="aplicafe"
                  titleOn="Voy a facturar electronicamente"
                  checked={this.state.form.aplicafe}
                  disabled={this.state.requestCompany.testidexitoso}
                  onChange={this.syncCheckChange}
                />
              </Grid>
            </Grid>

            {this.state.form.aplicafe === true &&
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <InputForm
                    name="testid"
                    label={"Test set id"}
                    maxLength={36}
                    value={this.state.form.testid}
                    validator={this.validator}
                    validateOptions={"required|uuid"}
                    onChange={(e) => this.syncChangesTestId(e)}
                    placeholder={'00000000-0000-0000-0000-000000000000'}
                    tooltip={
                      <TooltipMessage
                        title={getComponentName(this.state.components, 10, 10, '¿Qué es el Test set Id?')}
                        message={getComponentName(this.state.components, 10, 11, '¿Qué es el Test set Id?')}
                        botton={getComponentName(this.state.components, 10, 12, '¿Qué es el Test set Id?')}
                        href={getComponentUrl(this.state.components, 10, 12, null)}
                      />
                    }
                  />
                </Grid>
              </Grid>
            }

            <Grid container spacing={2}>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <InputForm
                  name="observacion"
                  label="Observaciones"
                  maxLength={300}
                  value={this.state.form.observacion}
                  multiline
                  onChange={(e) => this.syncChanges(e)}
                  tooltip={
                    <TooltipMessage
                      title={getComponentName(this.state.components, 11, 13, '¿Qué es el Test set Id?')}
                      message={getComponentName(this.state.components, 11, 14, '¿Qué es el Test set Id?')}
                      botton={getComponentName(this.state.components, 11, 15, '¿Qué es el Test set Id?')}
                      href={getComponentUrl(this.state.components, 11, 15, null)}
                    />
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={5} justify="center" alignItems="center">
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ButtonPrimary
                  text={getComponentName(this.state.components, 13, 19, 'Listo')}
                  loading={this.state.loadingSaveBasicData}
                  type={'submit'}
                />
              </Grid>
            </Grid>
          </form>
        }



        <ResponseModal
          modalType={this.state.responseModal.modalType}
          title={this.state.responseModal.title}
          subtitle={this.state.responseModal.subtitle}
          body={this.state.responseModal.body}
          modalImage={this.state.responseModal.modalImage}
          open={this.state.responseModal.open}
          closeElement={this.state.responseModal.closeElement}
          onCloseElement={() => this.handleResponseModal()}
        />
      </Fragment >
    );
  }
}

BasicData.propTypes = {
  getCompanyAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  basicDataReducer: state.basicDataReducer,
  generalReducer: state.generalReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  updateBasicDataAction,
  getCompanyAction,
})(BasicData);
