//token prefix eg. 'Bearer ' or 'Token '
export const TOKEN_PREFIX = '';
export const QUANTITY_DATA = '5';
export const SIZE_IMAGE = '1024';

export const API_ENDPOINT = 'http://localhost';
export const API_ENDPOINT_ARTICLE = 'https://democore.colfactura.com/item';
export const API_ENDPOINT_CONFIGURATION = 'https://democore.colfactura.com/conf';
export const API_ENDPOINT_ENTERPRISE = 'https://democore.colfactura.com/enterprise';
export const API_ENDPOINT_CUSTOMERS = 'https://democore.colfactura.com/customer';
export const API_ENDPOINT_DOCUMENT = 'https://democore.colfactura.com/document';
export const API_ENDPOINT_USER = 'https://democore.colfactura.com/user';
export const API_ENDPOINT_DASHBOARD = 'https://democore.colfactura.com/dashboard';
export const URL_SECURITY = "https://demosecurity.colfactura.com/";
export const API_ENDPOINT_DISCHARGE = 'https://af30old92h.execute-api.us-east-2.amazonaws.com/demo'
export const URL_IMAGES  = 'https://qanet.colfactura.com';
export const API_IBUHOO_IMAGEMANAGER = 'https://qanet.colfactura.com';
export const API_ENDPOINT_IBUHOO = 'https://af30old92h.execute-api.us-east-2.amazonaws.com/demo';
export const CHATBOT_WOLKVOX = 'https://chat01.ipdialbox.com/chat/?prodId=Z3NlLjM0Mg==';


export const DEFAULTRESPONSE = {
    noResponseFromApi: {
        statusCode: '500',
        statusMessage: 'No hay respuesta por parte del servidor.',
        result: '',
    },
    SuccessResponse: {
        statusCode: '200',
        statusMessage: 'Transacción exitosa',
        result: '',
    }
};

export function defaultHeaders() {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        }
    }
}

export const ROL_CFCOLAB = 'CFCOLAB';

// Maximum size of images in bytes
export const imageSize = 2097152;

// Size of the image cutter canvas
export const imageCutterSize = {
    width: 450,
    height: 450
};

export const TIME_REFRESH_TOKEN = 20;
