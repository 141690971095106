import {
    SAVE_BILLER,
    GET_BILLERS,
    SAVE_SELLER,
    GET_SELLERS,
    UPDATE_BILLER,
    LOAD_BILLER,
    UPDATE_SELLER,
    LOAD_SELLER,
    UPDATE_STATUS_BILLER,
    UPDATE_STATUS_SELLER,
    LOADING_SAVE_BILLER,
    LOADING_SAVE_SELLER,
    LOADING_GET_BILLERS,
    LOADING_GET_SELLERS
} from '../actions/types'

import isEmpty from '../utils/isEmpty'

const initialState = {
    loadingSaveBiller: false,
    loadingSaveSeller: false,
    loadingGetBillers: false,
    editBillerStatus: false,
    editSellerStatus: false
}


export default function (state = initialState, action) {

    switch (action.type) {
        case SAVE_BILLER:
            return {
                ...state,
                saveBillerResponse: action.payload
            }
        case GET_BILLERS:
            return {
                ...state,
                filteredBillersList: action.payload
            }
        case SAVE_SELLER:
            return {
                ...state,
                saveSellerResponse: action.payload
            }
        case GET_SELLERS:
            return {
                ...state,
                filteredSellersList: action.payload
            }
        case UPDATE_BILLER:
            return {
                ...state,
                updateBillerResponse: action.payload

            }
        case LOAD_BILLER:
            return {
                ...state,
                loadedBillerObject: action.payload,
                editBillerStatus: !isEmpty(action.payload)
            }
        case LOAD_SELLER:
            return {
                ...state,
                loadSellerObject: action.payload,
                editSellerStatus: !isEmpty(action.payload)
            }
        case UPDATE_SELLER:
            return {
                ...state,
                updateSellerResponse: action.payload
            }

        case UPDATE_STATUS_BILLER:
            return {
                ...state,
                updateBillerStatusResponse: action.payload
            }

        case UPDATE_STATUS_SELLER:
            return {
                ...state,
                updateSellerStatusResponse: action.payload
            }
        case LOADING_SAVE_BILLER:
            return {
                ...state,
                loadingSaveBiller: action.payload
            }
        case LOADING_SAVE_SELLER:
            return {
                ...state,
                loadingSaveSeller: action.payload
            }
        case LOADING_GET_BILLERS:
            return {
                ...state,
                loadingGetBillers: action.payload
            }
        case LOADING_GET_SELLERS:
            return{
                ...state,
                loadingGetSellers: action.payload
            }
        default:
            return state
    }
} 