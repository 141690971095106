import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { regexEmail, regexAlphaNumeric, regexPhone } from "../../../helpers/customRegex.hepers";
import InputForm from "../../../components/input/input.component";
import SwitchForm from "../../../components/switch/switch.component.js";
import SelectForm from "../../../components/select/select.component";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import ButtonPrimary from '../../../components/button/buttonPrimary.component'
import ToggleButtons from '../../../components/toggle/toggle.component';

import {
  saveBillerAction,
  updateBillerAction,
  getBillersAction,
  loadBillerAction
} from "../../../actions/userAction";

import { SuccessAlert, WarningAlert } from "../../../helpers/alert.helpers";
import { getComponentName, getComponentUrl } from "../../../utils/general";
import isEmpty from '../../../utils/isEmpty'


class Biller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txtBillerName: "",
        txtBillerLastName: "",
        txtBillerEmail: "",
        txtBillerPhone: "",
        chkBillerRol: true,
        ddlBillerOffice: "",
        chkBillerStatus: true,
        toogleRol: '1'
      },

      saveBillerResponse: {},
      updateBillerResponse: {},
      loadedBillerObject: {},
      editBillerStatus: false,
      branchOfficesList: [],
      components: [],
      loadingSaveBiller: false,
      branchOfficesOptions: []
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() { }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.userReducer.saveBillerResponse && nextProps.userReducer.saveBillerResponse !== state.saveBillerResponse)
      update.saveBillerResponse = nextProps.userReducer.saveBillerResponse;

    if (nextProps.userReducer.loadedBillerObject !== state.loadedBillerObject)
      update.loadedBillerObject = nextProps.userReducer.loadedBillerObject;

    if (!isEmpty(nextProps.userReducer.editBillerStatus) && nextProps.userReducer.editBillerStatus !== state.editBillerStatus)
      update.editBillerStatus = nextProps.userReducer.editBillerStatus;

    if (!!nextProps.userReducer.updateBillerResponse && nextProps.userReducer.updateBillerResponse !== state.updateBillerResponse)
      update.updateBillerResponse = nextProps.userReducer.updateBillerResponse;

    if (!!nextProps.configReducer.components & nextProps.configReducer.components !== state.components)
      update.components = nextProps.configReducer.components

    if (!!nextProps.branchOfficeReducer.branchOfficesList && nextProps.branchOfficeReducer.branchOfficesList !== state.branchOfficesList) {
      update.branchOfficesList = nextProps.branchOfficeReducer.branchOfficesList;
      update.branchOfficesOptions = nextProps.branchOfficeReducer.branchOfficesList.filter(b => b.idstate === 0);
    }
      

    if (!isEmpty(nextProps.userReducer.loadingSaveBiller) && nextProps.userReducer.loadingSaveBiller !== state.loadingSaveBiller)
      update.loadingSaveBiller = nextProps.userReducer.loadingSaveBiller;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.saveBillerResponse !== this.state.saveBillerResponse) {
      if (this.state.saveBillerResponse.statusCode === '201') {
        SuccessAlert(
          getComponentName(this.state.components, 30, 98, 'Huu juu!!!'),
          getComponentName(this.state.components, 30, 99, 'Has vinculado un nuevo usuario a tu empresa')
        );
        this.props.getBillersAction();
      } else if (this.state.saveBillerResponse.statusCode === "301") {
        WarningAlert(
          "Facturador existente",
          "Ya existe un facturador con este email"
        );
      }
    }

    if (prevState.updateBillerResponse !== this.state.updateBillerResponse) {
      if (this.state.updateBillerResponse.statusCode === '201') {
        SuccessAlert(getComponentName(this.state.components, 34, 110, 'Buen trabajo!!!'), getComponentName(this.state.components, 34, 111, 'Actualizaste el usuario $nombreUsuario de manera exitosa, sigamos facturando.').replace('$nombreUsuario', this.state.updateBillerResponse.result.nombres + ' ' + this.state.updateBillerResponse.result.apellidos));
        this.cleanFormBiller();
        this.props.getBillersAction();
      }
    }

    if (prevState.loadedBillerObject !== this.state.loadedBillerObject) {
      if (!!this.state.loadedBillerObject) {
        this.loadInfo(this.state.loadedBillerObject);
      }
    }

  }

  componentWillUnmount() {
    this.props.loadBillerAction(null);
  }

  /**
   * Sincroniza nuevo valor de input o select con state.
   * @param {*} e Evento
   */
  syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }


  /**
   * Sincroniza nuevo valor de checbox con state.
   * @param {*} e Evento
   */
  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked,
      },
    }));
  };


  /**
   * Elimina caracteres invalidos para correo electronico
   * @param {*} e Evento
   */
  validateEmail(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexEmail;
    value = value.replace(regex, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Elimina caracteres inavlidos para formatado telefonico
   * @param {*} e Evento
   */
  validatePhone(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexPhone;
    value = value.replace(regex, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Elimina caracteres invalidos, solamente letras y numeros
   * @param {*} e Evento
   */
  validateAlphaNumeric(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexAlphaNumeric;
    value = value.replace(regex, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Sincroniza valor toogle button con state
   * @param {*} event  Evento
   * @param {*} newAlignment  Nuevo valor
   */
  syncChangeRol = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleRol: !!newAlignment ? newAlignment : '1'
      }
    }))

  };


  /**
   * Captura y gurada o actualiza información de usuario facturador
   * @param {*} e 
   */
  onSaveBiller = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {

      if (this.state.editBillerStatus) {
        const data = {
          ...this.state.loadedBillerObject,
          nombres: this.state.form.txtBillerName,
          apellidos: this.state.form.txtBillerLastName,
          telefono: this.state.form.txtBillerPhone,
          rolid: this.state.form.toogleRol === '1' ? 2 : 3,
          sucursalid: this.state.form.ddlBillerOffice,
          idstate: this.state.form.chkBillerStatus === true ? 0 : 1,
          rol: null
        };

        this.props.updateBillerAction(data);

      } else {
        const data = {
          nombres: this.state.form.txtBillerName,
          apellidos: this.state.form.txtBillerLastName,
          email: this.state.form.txtBillerEmail,
          telefono: this.state.form.txtBillerPhone,
          rolid: this.state.form.toogleRol === '1' ? 2 : 3,
          sucursalid: this.state.form.ddlBillerOffice,
          idstate: this.state.form.chkBillerStatus === true ? 0 : 1,
          createdby: 1,
          modifiedby: 1,
          idowner: 1,
        };
        this.props.saveBillerAction(data);

      }

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  /**
   * Limpia información del formulario
   */
  cleanFormBiller = () => {
    this.setState({
      form: {
        txtBillerName: '',
        txtBillerLastName: '',
        txtBillerEmail: '',
        txtBillerPhone: '',
        toogleRol: '1',
        ddlBillerOffice: '',
        chkBillerStatus: true,
      },
    });
    this.validator.hideMessages();
    this.forceUpdate();
    this.props.loadBillerAction(null);

  };

  /**
   * Carga información de facturador para edición
   * @param {object} data Información facturador
   */
  loadInfo = (data) => {


    this.setState({
      form: {
        txtBillerName: data.nombres,
        txtBillerLastName: data.apellidos,
        txtBillerEmail: data.email,
        txtBillerPhone: data.telefono,
        toogleRol: data.rolid === 2 ? '1' : '2',
        ddlBillerOffice: data.sucursalid,
        chkBillerStatus: data.idstate === 0 ? true : false,
      },
    });
  }

  render() {

    //Listado sucursales
    let branchOfficesOptions = this.state.branchOfficesOptions;
    if (this.state.editBillerStatus === true) {
      let loadedBillerObject = this.state.loadedBillerObject;
      let exist = branchOfficesOptions.find(b => b.id === loadedBillerObject.sucursalid);
      if (exist === undefined) {
        let newOption = this.state.branchOfficesList.find(b => b.id === loadedBillerObject.sucursalid);
        if (newOption !== undefined) {
          branchOfficesOptions.push(newOption);
        }
      }
    }


    return (
      <form onSubmit={(e) => this.onSaveBiller(e)} autoComplete="off">
        <Grid container alignItems="center" spacing={3}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={"txtBillerName"}
              label={"Nombres *"}
              maxLength={200}
              value={this.state.form.txtBillerName}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateAlphaNumeric(e);
              }}
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={"txtBillerLastName"}
              label={"Apellidos *"}
              maxLength={200}
              value={this.state.form.txtBillerLastName}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateAlphaNumeric(e);
              }}
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name="txtBillerEmail"
              label={"Correo electrónico *"}
              disabled={this.state.editBillerStatus}
              maxLength={200}
              value={this.state.form.txtBillerEmail}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateEmail(e);
              }}
              validator={this.validator}
              validateOptions={"required|email"}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name="txtBillerPhone"
              label={"Teléfono *"}
              maxLength={15}
              value={this.state.form.txtBillerPhone}
              onChange={(e) => {
                this.syncChanges(e);
                this.validatePhone(e);
              }}
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ToggleButtons
              label={"Rol"}
              value={this.state.form.toogleRol}
              onChange={this.syncChangeRol}
              titleOne={'Administrador'}
              titleTwo={'Colaborador'}
              txtTooltip={
                <TooltipMessage
                  title={getComponentName(this.state.components, 32, 103, '¿Qué hacen los roles?')}
                  message={getComponentName(this.state.components, 32, 104, 'Administradores: Emitir y editar facturas, cotizaciones, crear clientes, productos y usuarios. \n Colaborador: Emitir y crear facturas, cotizaciones y crear clientes.').toString()}
                  botton={getComponentName(this.state.components, 32, 105, 'Más Información')}
                  href={getComponentUrl(this.state.components, 17, 105, null)}
                />}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SelectForm
              label={"Sucursal "}
              name="ddlBillerOffice"
              value={this.state.form.ddlBillerOffice}
              options={branchOfficesOptions}
              onChange={(e) => {
                this.syncChanges(e);
              }}
              placeholder={"Seleccione..."}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SwitchForm
              name="chkBillerStatus"
              titleOn={this.state.form.chkBillerStatus ? "Desactivar facturador" : "Activar facturador"}
              checked={this.state.form.chkBillerStatus}
              value={this.state.form.chkBillerStatus}
              onChange={this.syncCheckChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="flex-end" alignItems="center">
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ButtonPrimary
              text={getComponentName(this.state.components, 36, 117, 'Listo')}
              loading={this.state.loadingSaveBiller}
              type={'submit'}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

Biller.propTypes = {
  saveBillerAction: PropTypes.func.isRequired,
  updateBillerAction: PropTypes.func.isRequired,
  getBillersAction: PropTypes.func.isRequired,
  loadBillerAction: PropTypes.func.isRequired,

  userReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  branchOfficeReducer: state.branchOfficeReducer,
  userReducer: state.userReducer,
  basicDataReducer: state.basicDataReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  saveBillerAction,
  updateBillerAction,
  getBillersAction,
  loadBillerAction
})(Biller);
