import {
GET_BILL_CLIENT,
GET_COLLABORATOR_RANKING,
GET_BILLING,
GET_BRANCH_RAKING,
GET_INCOME,
GET_BEST_CUSTOMER,
GET_RAKING_ITEMS,
GET_ISSUED_INVOICE,
GET_ERRORS

} from './types'

import axios from 'axios';

import { API_ENDPOINT_DASHBOARD, DEFAULTRESPONSE, API_ENDPOINT_DISCHARGE} from '../config/config';
import { authHeader, enterpriseIdHeader,enterpriseDocumentType, enterpriseDocumentNumber } from './authActions';

const urlConfiguration = `${API_ENDPOINT_DASHBOARD}/dashboard/api`;
const urlConfigurationDischarge = `${API_ENDPOINT_DISCHARGE}/license/api`;

let quantityBilling = 0

/**
 * Method to load available clients and invoices in infoCard Licence
 * @param {*} 
 */
export const getBillClient = () => async(dispatch) => {
    const url = `${urlConfigurationDischarge}${'/license/InvoiceCustomers'}`;
    let data = {
      documentType:`${enterpriseDocumentType()}`,
      documentNumber:`${enterpriseDocumentNumber()}`
    }
    try {
      await axios.post(url,data)
      .then(res => {
        quantityBilling= res.data.result.records.map(item => { return item.billings})[0]
          dispatch({
            type: GET_BILL_CLIENT,
            payload:res.data.result.records
        }) 
      })
    } catch (err) {
        console.log("Error consultando las facturas disponibles", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido cargar la cantidad de facturas disponibles'
    })
    }
}
/**
 * Top 10 best sellers
 */
export const getrakingCollaborators = () => async(dispatch) => {  
    try {
      const url = `${urlConfiguration}${'/dashboard/GetCollaborator'}`;
        const config = {
            headers: {
                'Authorization': `${authHeader()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'empresaId' : `${enterpriseIdHeader()}`,
                'page':0,
                'quantity':10
            }
        };

        await axios.get(url, config)
            .then(res => {
                dispatch({
                  type: GET_COLLABORATOR_RANKING,
                  payload:res.data.result.records,
                  payload1: res.data.statusCode
              }) 
            })
    } catch (err) {
        console.log("Error consultando Raking de colaboradores", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido cargar el raking de colaboradores'
    })
    }
}

/**
 * Method to billing
 * @param {*} 
 */
export const getBilling = () => async(dispatch) => {
    
    try {

      const url = `${urlConfiguration}${'/dashboard/GetBilling'}`;
        const config = {
            headers: {
                'Authorization': `${authHeader()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'empresaId' : `${enterpriseIdHeader()}`,
                'filter': 1,
                'quarter':'',
                'year':''

            }
        };
        await axios.get(url, config)
            .then(res => {
              var getBillings = []
              let totalSold
              const options2 = { style: 'currency', currency: 'USD' };
              const numberFormat2 = new Intl.NumberFormat('en-US', options2);
              for(let i in  res.data.result.records){ 
                getBillings.push(res.data.result.records[i].billingExpired)
                getBillings.push(res.data.result.records[i].billingPaidout)
                getBillings.push(res.data.result.records[i].billingPending)
                totalSold = numberFormat2.format(res.data.result.records[i].totalSold) 
               //totalSold = res.data.result.records[i].totalSold 

                
              }
            
              dispatch({
                type: GET_BILLING,
                payload:getBillings,
                payload1 : totalSold,
                payload2: res.data.statusCode
            })    
            })
    } catch (err) {
        console.log("Error consultado datos de facturacion", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido cargar los datos de facturación'
    })
    }
}

/**
 * Method to billing
 * @param {*} 
 */
export const getIncome = () => async(dispatch) => {
    try {
      const url = `${urlConfiguration}${'/dashboard/GetIncome'}`;
      const config = {
          headers: {
              'Authorization': `${authHeader()}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'empresaId' : `${enterpriseIdHeader()}`

          }
      };
      await axios.get(url, config)
          .then(res => {
            var getIncomes = []
            for(let i in  res.data.result.records){
              getIncomes.push(res.data.result.records[i].january)
              getIncomes.push(res.data.result.records[i].february)
              getIncomes.push(res.data.result.records[i].march)
              getIncomes.push(res.data.result.records[i].april)
              getIncomes.push(res.data.result.records[i].may)
              getIncomes.push(res.data.result.records[i].june)
              getIncomes.push(res.data.result.records[i].july)
              getIncomes.push(res.data.result.records[i].august)
              getIncomes.push(res.data.result.records[i].september)
              getIncomes.push(res.data.result.records[i].october)
              getIncomes.push(res.data.result.records[i].november)
              getIncomes.push(res.data.result.records[i].december)
            }
            dispatch({
              type: GET_INCOME,
              payload:getIncomes,
              payload1: res.data.statusCode
          })    
          })
 
    } catch (err) {
        console.log("Error consultando los ingresos", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido cargar los valores de ingresos'
    })
    }
}

/**
 * Method to billing
 * @param {*} 
 */
export const getCustomer = () => async(dispatch) => {
    try {
      const url = `${urlConfiguration}${'/dashboard/GetClient'}`;
      const config = {
          headers: {
              'Authorization': `${authHeader()}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'empresaId' :`${enterpriseIdHeader()}`
              
          }
      };
      await axios.get(url, config)
          .then(res => { 
    let customer= [];
    const styleTotalsOLD = { style: 'currency', currency: 'USD', maximumSignificantDigits: 3};
    const numberFormat = new Intl.NumberFormat('en-US', styleTotalsOLD);
    customer = res.data.result.records.map((item) => {
          return {
            clientId: item.clientId,
            nombreCliente: item.clientName,
            Cantidad_Factura: item.quantityInvoice,
            Ingresos :item.totalSold
          }
        });
           dispatch({
            type: GET_BEST_CUSTOMER,
            payload:customer,
            payload1: res.data.statusCode
        })    
          })
    } catch (err) {
        console.log("Error consultando raking de clientes", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido cargar el raking de clientes'
    })
    }
}
/**
 * Top 10 best sellers
 */
export const getrakingBranchOffice = () => async(dispatch) => {  
  try {

    const url = `${urlConfiguration}${'/dashboard/GetBranche'}`;
    const config = {
        headers: {
            'Authorization': `${authHeader()}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'empresaId' : `${enterpriseIdHeader()}`,
            'page':0,
            'quantity':10
        }
    };
    await axios.get(url, config)
        .then(res => {
         dispatch({
          type: GET_BRANCH_RAKING,
          payload:res.data.result.records,
          payload1: res.data.statusCode
      })    
        })

  } catch (err) {
      console.log("Error consultando el raking de sucursales", !!err.response ? err.response : err);
      const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
          type: GET_ERRORS,
          payload: response,
          payload1: 'No se ha podido cargar el raking de sucursales'
  })
  }
 
}

export const getrakingArticle= () => async(dispatch) => {  
  try {
    const url = `${urlConfiguration}${'/dashboard/GetArticle'}`;
    const config = {
        headers: {
            'Authorization': `${authHeader()}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'empresaId' : `${enterpriseIdHeader()}`
        }
    };
    await axios.get(url, config)
        .then(res => {
         dispatch({
          type: GET_RAKING_ITEMS,
          payload:res.data.result.records,
          payload1: res.data.statusCode
      })    
        }) 
  } catch (err) {
    //  console.log("Error consultando el raking de articulos", !!err.response ? err.response : err);
    //  const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    //  dispatch({
    //      type: GET_ERRORS,
    //      payload: response,
    //      payload1: 'No se ha podido cargar el raking de articulos'
  //})
  }
}


export const issuedInvoices = () => async(dispatch) => {  
  try {
    const url = `${urlConfiguration}${'/dashboard/GetBillings'}`;
    const config = {
        headers: {
            'Authorization': `${authHeader()}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'businessId' : `${enterpriseIdHeader()}`
        }
    };
    await axios.get(url, config)
        .then(res => {
          let invoiceIssued =  res.data.result.records.map(item =>{ return item.countBilling})[0]
          let result = quantityBilling - invoiceIssued
         dispatch({
          type:GET_ISSUED_INVOICE,
          payload:result < 0 ? 0 : result 
      })    
        }) 
  } catch (err) {
      console.log("Error consultando el raking de articulos", !!err.response ? err.response : err);
      const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
          type: GET_ERRORS,
          payload: response,
          payload1: 'No se ha podido cargar el raking de articulos'
  })
  }
}
