import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, Checkbox, ListItemText, FormHelperText, OutlinedInput } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    select: {
        "& ul": {
            backgroundColor: theme.palette.primaryBackgroundColor,
        },
        "& li span": {
            color: `${theme.palette.primaryColor} !important`,
        },
    },
}));


const CustomForm = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.thirdColor, //azul
        },
        '& label.Mui-disabled': {
            color: 'gray', //gris
        },
        '& label.MuiFormLabel-filled:not(.Mui-focused)': {
            color: 'gray', //gris
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.thirdColor, //azul
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:focus': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInputBase-input': {
            color: theme.palette.primaryColor
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '2px solid',
                borderColor: ' rgba(255,255,255,0.3)', //gris
            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.thirdColor, //azul
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray', //gris
            },
            '& svg': {
                color: theme.palette.fifthBackgroundColor
            }
        }
    },
}))(FormControl);

const MultiSelectForm = ({ ...props }) => {
    const classes = useStyles();
    const {
        id,
        onChange,
        values,
        options,
        label,
        value,
        hidden,
        validator,
        validateOptions,
        name,
        disabled,
        renderValue,
        model,
        optional
    } = props;

    const labelRef = React.useRef(null);
    const labelNode = ReactDOM.findDOMNode(labelRef.current);
    const labelWidth = labelNode != null ? labelNode.offsetWidth : 0;



    const optionsSelectCategory = options ? options.map((option, index) => ({
        key: index,
        value: option.value,
        text: option.text,
        color: option.color
    }))
        : values.map((item, index) => ({
            key: index,
            text: item,
            value: item,
            color: item
        }));

    const optionsSelect = options ? options.map((option, index) => ({
        key: index,
        value: option.value,
        text: option.text
    }))
        : values.map((item, index) => ({
            key: index,
            text: item,
            value: item
        }));

    let errorValidator = validator ? validator.message(label, value, validateOptions) : '';

    if (hidden) return null;

    return (
        <CustomForm variant="outlined" className={classes.root} error={!!errorValidator ? true : false}>
            <InputLabel ref={labelRef} htmlFor={id}>{label}</InputLabel>
            <Select
                multiple
                fullWidth
                variant="outlined"
                input={
                    <OutlinedInput
                        id={id}
                        name={name}
                        labelWidth={labelWidth}
                    />
                }
                value={value}
                onChange={onChange}
                renderValue={renderValue}
                disabled={disabled}
                MenuProps={{ classes: { paper: classes.select } }}
                IconComponent={props => (
                    <ExpandMoreIcon {...props} />
                )}

            >
                {optional &&
                    <MenuItem key={-1} value={null} >
                        <ListItemText primary={'Seleccione...'} />
                    </MenuItem>}
                {model === 'Articulo' ? optionsSelectCategory.map((option, index) => (
                    <MenuItem key={index} value={option.value} >
                        <Checkbox checked={value.includes(option.value)} />
                        <ListItemText primary={option.text} />
                        <StopIcon style={{ color: option.color }} />
                    </MenuItem>
                )) :
                    optionsSelect.map((option, index) => (
                        <MenuItem key={index} value={option.value} >
                            <Checkbox checked={value.includes(option.value)} />
                            <ListItemText primary={option.text} />
                        </MenuItem>
                    ))
                }
            </Select>
            <FormHelperText>{errorValidator}</FormHelperText>
        </CustomForm>
    )
};

MultiSelectForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string
};

export default MultiSelectForm
