import React, {Component, Fragment} from "react";

import {changeStepStatusAction, getTemplate, postTemplate,} from "../../../actions/templateActions";
import {getCompanyAction} from "../../../actions/basicDataActions";
import {SuccessAlert, WarningAlert} from "../../../helpers/alert.helpers";

import {connect} from "react-redux";
import PropTypes from "prop-types";

import {Grid} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TemplateCarousel from "../../../components/carrousel/templateCarrousel"
import imageDefault from "../../../../src/images/image.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./template.view.css";
import {bytesToSize, getComponentName} from "../../../utils/general";
import SimpleModal from "../../../components/modal/simpleModal.component";
import {imageSize} from "../../../config/config";
import CustomCropper from "../../../components/customCropper/customCropper.component";

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.seventhBackgroundColor,
    borderRadius: 5,
    width: 540,
    outline: 0
  },
  title: {
    background: "#f5d13d",
    textAlign: "center",
    fontWeight: "bold",
    borderRadius: 10,
  },
  imagePanel: {
    background: "#424242",
    borderRadius: 5,
    margin: "auto",
    width: "auto",
    textAlign: "center"
  },
  iconButtonFile: {
    borderRadius: "50%",
    fontSize: 50,
    position: 'absolute',
    left: 130,
    top: -40
  },
  crooper: {
    height: 274,
    width: "100%",
  },
  groupButtonModal: {
    paddingRight: 30
  },
  btnModalImage: {
    borderRadius: 5,
    backgroundColor: theme.palette.thirdColor,
    width: '100%',
    height: 39,
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.primaryColor,
    fontSize: 20,
    fontWeight: 600,
    marginTop: 5,
    marginBottom: 15,
    marginLeft: 15
  },
  uploadText: {
    color: "white",
    fontSize: 19,
    fontStyle: "oblique",
    lineHeight: '17px',
    fontWeight: 600
  },
  selectTemplate: {
    background: "#f5d13d",
    textAlign: "center",
    fontWeight: "bold",
    borderRadius: 10,
  },
  exitPanel: {
    textAlign: "center"
  },
  iconButtonCloseModal: {
    textAlign: 'end'
  },
  iconCloseModal: {
    color: theme.palette.thirdColor,
    fontSize: 16,
    padding: 0
  }
});

export class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        logo: "",
        plantillaid: "",
      },

      src: "",
      cropResult: null,

      stateModal: false,

      requestGetTemplate: {},

      requestPostTemplate: {},

      templates: [],
      stepTemplateComplete: false,

      company: {},
      components: [],

    };
  }

  componentDidMount() {
    this.props.getTemplate();
    if (this.state.company.id !== null && this.state.company.id !== undefined) {
      this.setState(({
        form: {
          plantillaid: this.state.company.plantillaid,
          logo: this.state.company.logo,
        },
        cropResult: this.state.company.logo,
        src: this.state.company.logo,
      }))
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (!!nextProps.templateReducer.requestResponseTemplate) {
      if (nextProps.templateReducer.requestResponseTemplate.responseCode === "A10" &&
        nextProps.templateReducer.requestResponseTemplate.content.length > 0) {
        update.templates = nextProps.templateReducer.requestResponseTemplate.content;
      }
    }

    if (!!nextProps.templateReducer.requestPostTemplate && nextProps.templateReducer.requestPostTemplate !== state.requestPostTemplate) {
      update.requestPostTemplate = nextProps.templateReducer.requestPostTemplate;
    }

    if (!!nextProps.templateReducer.stepTemplateComplete) {
      update.stepTemplateComplete = nextProps.templateReducer.stepTemplateComplete;
    }

    if (!!nextProps.templateReducer.stepTemplateComplete !== state.stepTemplateComplete ) {
      update.stepTemplateComplete = nextProps.templateReducer.stepTemplateComplete;
    }

    if (!!nextProps.basicDataReducer.requestCompany) {
      if (nextProps.basicDataReducer.requestCompany !== state.company) {
        update.company = nextProps.basicDataReducer.requestCompany
        update.form = {
          plantillaid: nextProps.basicDataReducer.requestCompany.plantillaid,
          logo: nextProps.basicDataReducer.requestCompany.logo,
        }
        update.cropResult = nextProps.basicDataReducer.requestCompany.logo ? nextProps.basicDataReducer.requestCompany.logo : null
      }
    }

    if (!!nextProps.configReducer.components !== state.components ) {
      update.components = nextProps.configReducer.components;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!prevState.requestPostTemplate && prevState.requestPostTemplate !== this.state.requestPostTemplate) {
      if (this.state.requestPostTemplate.statusCode === '201') {
        this.cleanData();
        SuccessAlert("Increible!!!", "Configuraste tu logo y plantilla");
        this.props.getCompanyAction()
      }
    }
    if (!!this.state.company && !this.state.stepTemplateComplete) {
      if (!!this.state.company.plantillaid) {
        this.props.changeStepStatusAction(true);

      }
    }


    if (prevState.company !== this.state.company &&
      (this.state.company.id !== null && this.state.company.id !== undefined)) {
      this.setState(({
        form: {
          plantillaid: this.state.company.plantillaid,
          logo: this.state.company.logo,
        },
        cropResult: this.state.company.logo,
        src: this.state.company.logo,
      }))
    }
  }

  cleanData() {
    this.setState({ form: { plantillaid: "" }, cropResult: null, src: null });
  }

  onChange = (e) => {
    e.preventDefault();
    try {

      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
        var objetFile = files[0];
        if (!/\.(jpg|png)$/i.test(objetFile.name)) {
          WarningAlert("Formato no permitido. Formatos permitidos .jpg o .png");
          this.handleClose();
        } else if (objetFile.size >= imageSize) {
          WarningAlert(`El tamaño de la imagen no puede se superior a ${bytesToSize(imageSize)}`);
          this.handleClose();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({src: reader.result});
          };
          reader.readAsDataURL(files[0]);
          this.handleOpen();
        }
      }
      e.target.value = null
    } catch (error) {
      console.log("Error cargando imagen", error);
    }
  };

  syncCheckChange = (e, id) => {
    const name = e.target.name;
    const checked = e.target.checked;
    if (checked) {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          [name]: id
        }
      }))
    }
  };

  onClickSave = async () => {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
      this.setState({cropResult: canvas});
    }
    this.handleClose();
  };

  setEditorRef = (editor) => this.editor = editor;

  useDefaultImage = () => {
    this.setState({ cropResult: this.state.src });
  };

  selectTemplate() {
    if (!!this.form) {
      if (!!this.form.plantillaid !== '') {
        const plantilla = this.state.templates.filter((template) => template.id === this.state.form.plantillaid)
        SuccessAlert(plantilla.nombre + " seleccionada");

      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.form.plantillaid !== null || this.state.form.plantillaid !== undefined || this.state.form.plantillaid !== '') {
      let data = {
        ...this.state.company,
        logo: this.state.cropResult,
        plantillaid: this.state.form.plantillaid,
        numeraciones: [],
        sucursales: []
      };
      this.props.postTemplate(data);
    } else {
      alert("Debe seleccionar una plantilla")
    }
  };

  handleOpen = () => {
    this.setState({ stateModal: true });
  };

  handleClose = () => {
    this.setState({ stateModal: false });
  };

  buyTemplate = () => {

  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>

        <form onSubmit={(e) => this.onSubmit(e)} noValidate autoComplete="off">

          <div className="comunContainer">
            <div className="title-yellow">Logo Empresa</div>
          </div>

          <Grid container spacing={3} className={classes.imagePanel} alignItems={"center"}>
            <Grid item md={4} lg={3} sm={6} xs={12}>
              <input
                className="input-image"
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={this.onChange}
              />
              <label htmlFor="icon-button-file">
                <IconButton aria-label="upload picture" component="span">
                  <i className={classes.iconButtonFile}><img src={require('./../../../images/template-input-button.png')} alt={"inputButton"} /></i>
                </IconButton>
                <img className="imagen" src={!!this.state.cropResult ? this.state.cropResult : imageDefault} alt={"imageDefault"} />
              </label>

              <SimpleModal
                onClose={this.handleClose}
                open={this.state.stateModal}
                title='Actualizar imagen del artículo'
                component={() =>
                  <CustomCropper
                    onClickSave={this.onClickSave}
                    handleClose={this.handleClose}
                    image={this.state.src}
                    setEditorRef={this.setEditorRef}
                  />}
              />

            </Grid>
            <Grid item md={8} lg={9} sm={6} xs={12}>
              <Typography variant="h6" gutterBottom className={classes.uploadText} >
              Carga aquí el logo de tu empresa, ten en cuenta que este es el que se mostrará en tus facturas.
            </Typography>
            </Grid>
          </Grid>

          <div className="comunContainer">
            <div className="title-yellow">Selección Plantilla</div>
          </div>

          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12}>
              <TemplateCarousel templates={this.state.templates} syncCheckChange={this.syncCheckChange}
                buyTemplate={this.buyTemplate} checked={this.state.form.plantillaid} />
            </Grid>
          </Grid>

          <Grid container spacing={3} justify="flex-end" alignItems="center">
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <button type="submit" className="btn-primary">
                {getComponentName(this.state.components, 43, 126, 'Listo')}
              </button>
            </Grid>
          </Grid>
        </form >
      </Fragment>

    );
  }
}

Template.propTypes = {
  getTemplate: PropTypes.func.isRequired,
  changeStepStatusAction: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  templateReducer: state.templateReducer,
  basicDataReducer: state.basicDataReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  getTemplate,
  postTemplate,
  changeStepStatusAction,
  getCompanyAction
})(withStyles(useStyles)(Template));
