import { API_ENDPOINT_ENTERPRISE, DEFAULTRESPONSE, defaultHeaders } from "../config/config";
import {
  SAVE_BASIC_DATA,
  GET_ERRORS,
  GET_COMPANY,
  LOADING_SAVE_BASIC_DATA,
  LOADING_GET_BASIC_DATA
} from "./types";
import axios from 'axios'
import { handleResponse, enterpriseIdHeader } from './authActions'

const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;

let branchOffices = []

/**
*  Consula información de empresa asociada al usuario logueado.
*  ajustes
*/
export const getCompanyAction = () => async (dispatch) => {

  try {
    changeGetLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'pEnterpriseId': `${enterpriseIdHeader()}`,
    });

    var response = await axios.get(`${urlEnterprise}/GetEnterprise`, config);
    dispatch({
      type: GET_COMPANY,
      payload: response.data.result
    })

  } catch (err) {
    handleResponse(err.response);
    console.log("Error obteniendo información de la empresa.", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido obtener información de la empresa.'
    });

  } finally {
    changeGetLoading(dispatch, false);
  }
}


/**
 * Actualiza estado loading consulta información de empresa
 * @param {*} dispatch 
 * @param {boolean} status Estado loading
 */
function changeGetLoading(dispatch, status) {
  dispatch({
    type: LOADING_GET_BASIC_DATA,
    payload: status
  })
}

/**
 * Actualiza información basica de empresa
 */
export const updateBasicDataAction = (body) => async (dispatch) => {

  try {
    changeSaveLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'empresaId': `${enterpriseIdHeader()}`,
    });

    var response = await axios.put(`${urlEnterprise}`, body, config);
    dispatch({
      type: SAVE_BASIC_DATA,
      payload: response.data
    })

  } catch (err) {
    handleResponse(err.response)
    console.log("Error actualizando la información de la empresa.", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido actualizar la información de la empresa.'
    });

  } finally {
    changeSaveLoading(dispatch, false);
  }

};


/**
 * Actualiza estado loading Registro a actualización
 * información basica
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_BASIC_DATA,
    payload: status
  })
}


export const filterByOffice = (sucursalid, filter) => {
  let office = branchOffices.find(office => office.id === sucursalid);
  return !!office ? office.nombre.toLowerCase().indexOf(filter) : -1
}


/**
 * Verifica si la empresa del usuario logueado tiene un certificado digital valido
 */
export const checkCertificateCompanyAction = (checkData, loadingCreateInvoice) => async (dispatch) => {
  try {
    debugger;
    loadingCreateInvoice(true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'pEnterpriseId': `${enterpriseIdHeader()}`,
    });

    var response = await axios.get(`${urlEnterprise}/GetCertificate`, config);
    debugger;
    checkData(response.data.result);


  } catch (err) {
    console.log("Error verificando certificado digital de empresa.", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido verificar certificado digital de la empresa.'
    });
  } finally {
    loadingCreateInvoice(false);
  }

}