import axios from 'axios';
import {
    SAVE_CLIENT,
    UPDATE_CLIENT,
    GET_CLIENTS_LIST,
    LOAD_CLIENT,
    GET_ERRORS,
    LOADING_SAVE_CLIENT,
    LOADING_LIST_CLIENT,
    LOAD_CLIENT_DETAIL,
    DELETE_CLIENT,
    STANDOUT_CLIENT,
    LOADING_DETAIL_CLIENT

} from './types'
import { API_ENDPOINT_CUSTOMERS, DEFAULTRESPONSE, defaultHeaders } from '../config/config';
import { handleResponse, enterpriseIdHeader } from './authActions';



let allClients = [];
const urlEndPoint = `${API_ENDPOINT_CUSTOMERS}/customer/api/Cliente`;

/**
 * @method POST
 * @description Guarda cliente
 * @param client Información cliente
 */
export const saveClientAction = (client) => async dispatch => {

    try {
        changeSaveLoading(dispatch, true);

        client = {
            ...client,
            EmpresaId: `${enterpriseIdHeader()}`,
        }

        const body = JSON.stringify(client);
        const url = `${urlEndPoint}${'/CreateCustomer'}`;
        var res = await axios.post(url, body, defaultHeaders());

        dispatch({
            type: SAVE_CLIENT,
            payload: res.data
        })

    } catch (err) {
        console.log("Error guardando cliente,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido registrar cliente'
        });

    } finally {
        changeSaveLoading(dispatch, false);
    }
}


/**
 * @method PUT
 * @description Actualiza cliente
 * @param {object} client  Información nueva
 */
export const updateClientAction = (client) => async dispatch => {

    try {

        changeSaveLoading(dispatch, true);

        client = {
            ...client,
            EmpresaId: `${enterpriseIdHeader()}`,
        }

        const body = JSON.stringify(client);
        const url = `${urlEndPoint}${'/UpdateCustomer'}`;

        var res = await axios.put(url, body, defaultHeaders());

        dispatch({
            type: UPDATE_CLIENT,
            payload: res.data
        });

    } catch (err) {
        console.log("Error actualizando cliente,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar cliente'
        });

    } finally {
        changeSaveLoading(dispatch, false);
    }
}

/**
 * Cambia estado loading action Save o Update
 * @param {*} dispatch 
 * @param {*} status  Estado loading
 */
function changeSaveLoading(dispatch, status) {
    dispatch({
        type: LOADING_SAVE_CLIENT,
        payload: status
    })
}


/**
 * Obtiene todos los clientes diferentes de estado borrado
 * @method GET 
 * @param {*} refresh  Estado de refresh consulta a api
 */
export const getClientsAction = (refresh = true) => async dispatch => {

    if (allClients.length > 0 && refresh === false) {
        dispatch({
            type: GET_CLIENTS_LIST,
            payload: allClients
        });

    } else {
        try {
            changeGetListLoading(dispatch, true);

            let myConfig = defaultHeaders();
            Object.assign(myConfig.headers, {'EnterpriseId': `${enterpriseIdHeader()}`})

            const url = `${urlEndPoint}${'/GetByEnterprise'}`;
            var res = await axios.get(url, myConfig);

            allClients = res.data.result.map((item, index) => {
                return {
                    ...item,
                    text: item.razonsocial
                }
            });

            dispatch({
                type: GET_CLIENTS_LIST,
                payload: allClients
            });

        } catch (err) {
            console.log("Error consultando clientes,", !!err.response ? err.response : err);
            handleResponse(err.response);
            const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
            dispatch({
                type: GET_ERRORS,
                payload: response,
                payload1: 'No se ha podido obtener lisitado clientes'
            });
        } finally {
            changeGetListLoading(dispatch, false);
        }
    }
}

function changeGetListLoading(dispatch, status) {
    dispatch({
        type: LOADING_LIST_CLIENT,
        payload: status
    })
}


/**
 * Actualiza estado de cliente a borrado
 * @method PUT
 * @param {*} id 
 */
export const deleteClientAction = (id) => async dispatch => {

    try {

        changeDetailLoading(dispatch, true);

        const body = JSON.stringify({});
        const url = `${urlEndPoint}${'/UpdateStatus'}`;
        let myConfig = defaultHeaders();
        Object.assign(myConfig.headers,
            {
                'Id': id,
                'Status': 2
            }
        )
 
        var res = await axios.put(url, body, myConfig);

        dispatch({
            type: DELETE_CLIENT,
            payload: res.data
        })

    } catch (err) {
        console.log("Error eliminando cliente,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido eliminar cliente'
        });

    } finally {
        changeDetailLoading(dispatch, false);
    }
}

/**
 * Actualiza estado favorito de un cliente
 * @param {int} id 
 */
export const standOutClientAction = (id) => async dispatch => {

    try {

        changeDetailLoading(dispatch, true);

        const body = JSON.stringify({});
        const url = `${urlEndPoint}${'/UpdateStandOut'}`;
        let myConfig = defaultHeaders();
        Object.assign(myConfig.headers, {
            'Id': id,
        });

        var res = await axios.put(url, body, myConfig);

        dispatch({
            type: STANDOUT_CLIENT,
            payload: res.data
        })

    } catch (err) {
        console.log("Error destacando cliente,", !!err.response ? err.response : err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        handleResponse(err.response);
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido destacar cliente'
        });
    } finally {
        changeDetailLoading(dispatch, false);
    }
}

/**
 * Cambia estado loading detalle cliente
 * @param {object} dispatch  funcion dispatch
 * @param {boolean} status Estado loading
 */
function changeDetailLoading(dispatch, status) {
    dispatch({
        type: LOADING_DETAIL_CLIENT,
        payload: status
    })
}


export const loadClientAction = (client) => async dispatch => {
    dispatch({
        type: LOAD_CLIENT,
        payload: client
    })
}

/**
 * Carga obecto cliente para Panel de detalle
 * @param {*} client 
 */
export const loadClientDetailAction = (client) => async dispatch => {
    dispatch({
        type: LOAD_CLIENT_DETAIL,
        payload: client
    })
}

/**
 * Limpia información de cliente para visualización en detalle
 */
export const clearLoadClientDetailAction = () => async dispatch => {
    dispatch({
        type: LOAD_CLIENT_DETAIL,
        payload: null
    })
}

/**
 * Limpia información de cliente para edición en formulario
 */
export const clearLoadClientAction = () => async dispatch => {
    dispatch({
        type: LOAD_CLIENT,
        payload: null
    })
}








