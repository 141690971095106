import React from "react"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: 0
    },
    iconLock:{
        color:'#B8B8B8',
        fontSize: 18
    }
}));

const CustomInput = withStyles({
    root: {
        '& .MuiInputLabel-formControl': {
            fontSize: 10
        },
        '& .MuiFormLabel-root.Mui-disabled':{
              color: '#FFFF',
        },
        '& label.Mui-focused': {
            color: '#16B1F3', //verde
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#16B1F3', //verde
        },
        '& .MuiInputBase-input': {
            color: '#FFFF',
            fontSize: 15,
            paddingTop: 0,
            paddingBottom: 0
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:focus': {
            borderBottomColor: 'gray', //gris
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#16B1F3', //verde
            },
        },

    },
})(TextField);

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}


const InputSmall = ({ ...props }) => {
    const { tooltip, onChange, value, label, disabled, validator, validateOptions, hidden, onKeyPress, onBlur, maxLength, nameValidator, variant, name, lockIcon, format } = props;
    const classes = useStyles();

    let errorValidator = validator
        ? validator.message(
            !!nameValidator ? nameValidator : label,
            value,
            validateOptions,
        )
        : "";
    if (!!hidden) return null

    return (
        <div className={classes.root} >
            <CustomInput
                className={classes.margin}
                disabled={disabled === true ? true : false}
                label={label}
                variant={!!variant ? variant : "outlined"}
                name={name}
                value={value}
                onChange={onChange}
                error={!!errorValidator ? true : false}
                helperText={errorValidator}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
                size={'small'}
                inputProps={{ maxLength: maxLength }}
                autoComplete='off'
                InputProps={{
                    inputComponent: format === true ? NumberFormatCustom: 'input',
                    endAdornment:
                        lockIcon===true ?
                            <InputAdornment position="end">
                                <LockIcon className={classes.iconLock}> </LockIcon>
                            </InputAdornment> : ''
                }}

            />
        </div>

    );
}


InputSmall.propTypes = {

}

export default InputSmall
