import React, { useEffect, Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secundaryBackgroundColor,
        borderRadius: '5px',
        color: theme.palette.primaryColor,
    },
    title: {
        display: "inline-flex",
        verticalAlign: "top",
        fontSize: 14
    },
    titleOpen: {
        color: '#16B1F3',
        fontSize: 14,
        fontWeight: 600
    },
    body: {
        width: '100%'
    },
    margin: {
        flexDirection: "column",
        justifyContent: "center"
    },
    icon: {
        color: theme.palette.primaryColor
    }
}));
const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },

    },
    expanded: {

    }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
       
        border: '1px solid gray',
        borderRadius: 5,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },

    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        border: '1px solid gray',
        borderRadius:5,
        padding: 0,
        backgroundColor: theme.palette.secundaryBackgroundColor,
    },
}))(MuiExpansionPanelDetails);



const ThirdCollapse = ({ component: Component, ...props }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('');
    const { open, title, onClick } = props;




    useEffect(() => {
        if (open !== '') {
            setExpanded('panel')
        }

        if (open !== 'panel') {
            setExpanded('')
        }

    }, [open, props.open]);



    return (
        <Fragment>
            <ExpansionPanel className={classes.root} quare expanded={expanded === 'panel'}  onChange={onClick}>
                <ExpansionPanelSummary
                    aria-controls="paneld-content"
                    id="paneld-header"
                    expandIcon={<ExpandMoreIcon fontSize="large" className={classes.icon} />}>
                    <Typography disableTypography className={classes.title}>
                        {title}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails  component={'div'}>
                    <Component />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Fragment>

    );
}

ThirdCollapse.propTypes = {
    component: PropTypes.object.isRequired,
};

export default ThirdCollapse;
