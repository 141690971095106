import React, { Component, Fragment } from "react"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'


import { WarningAlert } from '../../helpers/alert.helpers'
import CollapsePanel from '../../components/collapse/collapse.component'
import NewAddress from '../address/newAddress.component'
import AddressCard from '../address/addressCard.component'

import { getAndressListAction, clearAndressListAction, deleteAddressAction, loadAddressAction } from '../../actions/addressAction'


class Address extends Component {

    constructor(props) {
        super(props);
        this.state = {
            getAddressListResponse: [],
            deleteAddressResponse: {},
            openPanel: '',
            editAddressStatus: false
        }
    }

    componentDidMount() {
        this.props.getAndressListAction();
    }


    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.addressReducer.getAddressListResponse && nextProps.addressReducer.getAddressListResponse !== state.getAddressListResponse)
            update.getAddressListResponse = nextProps.addressReducer.getAddressListResponse;

        if (!!nextProps.addressReducer.deleteAddressResponse && nextProps.addressReducer.deleteAddressResponse !== state.deleteAddressResponse)
            update.deleteAddressResponse = nextProps.addressReducer.deleteAddressResponse;

        if (nextProps.addressReducer.editAddressStatus !== state.editAddressStatus)
            update.editAddressStatus = nextProps.addressReducer.editAddressStatus;

        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        this.props.clearAndressListAction();
    }

    onDelete(e, id) {
        e.preventDefault();
        this.props.deleteAddressAction(id);
    }

    //Envia información de dirección para edicion en formulario
    onLoad(e, id) {
        e.preventDefault();
        let item = this.state.getAddressListResponse.filter(item => item.id === id);

        if (item == undefined || item == null || item.length === 0) {
            WarningAlert('Opss...', 'No se ha podido cargar dirección');
            return;
        }
        this.props.loadAddressAction(item[0]);

        this.setState({
            openPanel: 'panel',
        })
    }

    //Captura evento click expansión de panel
    onClickCollapse = () => {
        if (this.state.openPanel === 'panel') {
            this.setState({
                openPanel: ''
            })
        } else if (this.state.openPanel === '') {
            this.setState({
                openPanel: 'panel',
            })
        }
    }


    render() {
        return (
            <Fragment>
                <div className="comunContainer">
                    <CollapsePanel
                        component={NewAddress}
                        title={!this.state.editAddressStatus ? "Agregar dirección adicional" : "Editar dirección adicional"}
                        titleOpen={!this.state.editAddressStatus ? "Agregar dirección adicional" : "Editar dirección adicional"}
                        open={this.state.openPanel}
                        onClick={this.onClickCollapse}
                        type={'secundary'} />
                </div>

                <div className="comunContainer">
                    <Grid container spacing={1}>
                        {this.state.getAddressListResponse.map((data, index) =>
                            <Grid item key={index} md={4} >
                                <AddressCard
                                    data={data}
                                    onDelete={(e) => this.onDelete(e, data.id)}
                                    onLoad={(e) => this.onLoad(e, data.id)} />
                            </Grid>
                        )}
                    </Grid>
                </div>

            </Fragment>
        );


    }


}

Address.propTypes = {
    getAndressListAction: PropTypes.func.isRequired,
    clearAndressListAction: PropTypes.func.isRequired,
    deleteAddressAction: PropTypes.func.isRequired,
    loadAddressAction: PropTypes.func.isRequired,

    addressReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    addressReducer: state.addressReducer
})

export default connect(mapStateToProps, {
    getAndressListAction,
    clearAndressListAction,
    deleteAddressAction,
    loadAddressAction
})(Address);
