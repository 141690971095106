import React,{Component} from "react";

import { withStyles } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";

import {Card,CardContent,Grid,Tooltip} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";

import CustomProgress from '../../../components/Progress/progress.component';


const useStyles = (theme) => ({
 
  txtFilter: {
    margin: "10px 0px 10px 0px",
    color: theme.palette.primaryColor,
  },
  titleCard: {
    color: theme.palette.primaryColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    fontWeight:'bold',
    textAlign: "center",
  },
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
  },
  colorDescription: {
    background: "#272727",
    color: theme.palette.primaryColor,
    fontSize: 12.32,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    width: '100%',
    height:'100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nameArticle: {
    marginLeft: 3,
  },
  priceArticle: {
    fontSize: 10.68,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    marginLeft: 10,
    fontWeight:200
  },
  cardOpacity:{
    opacity:0.3
  },
  divMessage:{
    textAlign:'center',
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  }
});

class RakingArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: false,
      timer: 500,
      animation: "slide",
      indicators: false,
      timeout: 200,
      numItemPerPage: 6,
      pages: 0,
      navButtonsAlwaysVisible: true,
    };
  }
/**
 * Metodo para obtener las dos primeras letras del nombre del articulo
 * @param {Parametro del nombre del articulo que llega de la respuesta} name 
 */
  subNameArticle = (name) => {
    return name ? name.substring(0, 2).toUpperCase() : "";
  };
/**
 * Metodo para obtener el color de la categoria que llega en la data
 * @param {Arreglo que se agrega de acuerdo a la dat que llega} color 
 */
  colorCategory = (color) =>{
    return color.find(c => c.color ).color
  }

  /**
   *
   * @param {*} classes
   * @param {*} data
   */
  CardItem(classes, data, index) {
    return (
      
        <Grid item lg={2} xs={2}>
        <Card className={index===5 ? classes.cardOpacity:''} >
          <CardActionArea>
            <div className="containerImg">
            <img src={data.image} width={'100%'} height={80} style={{background:data.image === null || data.image === undefined || data.image.length === 0 ? data.color:'' }} />
            {data.image === null || data.image === undefined || data.image.length === 0 ? <span className='span'>{this.subNameArticle(data.name)}</span>: ''}  
            </div>
            <Tooltip title={data.name}>
            <div className={classes.colorDescription}>
            <hr color={data.color} size={5} />
              <span className={classes.nameArticle}>{data.name}</span>  
              <br />
              <span className={classes.priceArticle}> ${data.price.toLocaleString('en')}</span>
            </div>
            </Tooltip>
          </CardActionArea>
        </Card>
      </Grid>
      
      
    );
  }

  render() {
    const { name, classes, items , statusCode } = this.props;

    let list = [];
    let listCards = [];
    for (let index = 0; index < items.length; index++) {
      if (
        (index + 1) % this.state.numItemPerPage !== 0 &&
        index + 1 !== items.length
      ) {
        list.push(items[index]);
      } else {
        list.push(items[index]);
        listCards.push(list);
        list = [];
      }
    }
    return (
      <Card className={classes.colorCard}>
      {/**
       * 
       * 
       * 
       * 
       */}  <CardHeader title={name} className={"title"} />
        {items.length === 0 && statusCode === undefined ?  <div className={classes.divMessage}>Sin artículos</div>  : 
            items.length === 0 && statusCode === 0  || items.length === 0 && statusCode === 200? 
            <div className={classes.divMessage}>Sin artículos</div>: ''}
        <CardContent>
       {items.length === 0 && statusCode === undefined ? '' : 
            items.length === 0 && statusCode === 0  || items.length === 0 && statusCode === 200? 
           '':
          !!listCards && listCards.length && (
            <Carousel
              autoPlay={this.state.autoPlay}
              timer={this.state.timer}
              animation={this.state.animation}
              indicators={this.state.indicators}
              timeout={this.state.timeout}
              navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}>
              {listCards.map((cards, index) => {
                return (
                  <Grid container spacing={1} key={index} vstyle={{marginTop:100}}>
                    {cards.map((data, indexCard) =>
                      this.CardItem(classes, data, indexCard)
                    )}
                  </Grid>)
              })}
            </Carousel>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(useStyles)(RakingArticle);
