import { API_ENDPOINT_ENTERPRISE, DEFAULTRESPONSE, defaultHeaders } from "../config/config"
import {
    GET_NUMERATIONS,
    GET_NUMERATION,
    SAVE_NUMERATION,
    UPDATE_NUMERATION,
    GET_MODALITY_TYPES,
    STATUS_STEP_NUMERATION,
    SYNC_GET_NUMERATION,
    STATUS_SYNC_NUMERATION,
    UPDATE_NUMERATION_STATE,
    LOAD_NUMERATION,
    GET_ERRORS,
    LOADING_SAVE_NUMERATION,
    LOADING_GET_NUMERATIONS
} from "./types"

import axios from 'axios'
import { handleResponse, enterpriseIdHeader } from './authActions'


const urlNumeration = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/numeracion`;
const urlModality = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/modalidad`;

let numerations = [];
let filteredNumerations =[];
let modalities = [];


/**
 * Consulta y filtra listado numeraciones 
 * @param {string} filter Parametro para filtro
 * @param {boolean} refresh Estado realizar consulta a Api
 */
export const getNumerationsFilterAction = (filter = '', refresh = true) => async (dispatch) => {

    if (!refresh) {
        filteredNumerations = filterList(numerations, filter);
        dispatch({
            type: GET_NUMERATIONS,
            payload: numerations,
            payload1: filteredNumerations
          
        })
    } else {
        try {
            changeGetLoading(dispatch, true);
            let config = defaultHeaders();
            Object.assign(config.headers, {
                'empresaId': `${enterpriseIdHeader()}`,
            });
            var result = await axios.get(`${urlNumeration}/GetByEmpresaIdModalidadId`, config);

            numerations = result.data.result;
            filteredNumerations = filterList(numerations, filter);
            dispatch({
                type: GET_NUMERATIONS,
                payload: numerations,
                payload1: filteredNumerations
              
            })

        } catch (err) {
            console.log("Error obteniendo listado de numeraciones,", !!err.response ? err.response : err);
            handleResponse(err.response);
            const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
            dispatch({
                type: GET_ERRORS,
                payload: response,
                payload1: 'No se ha podido obtener listado de numeraciones'
            });

        }finally{
            changeGetLoading(dispatch, false);
        }
    }
}

/**
 * Actualiza estado loading consulta listado numeraciones
 * @param {*} dispatch 
 * @param {boolean} status Nuevo estado
 */
function changeGetLoading(dispatch, status) {
    dispatch({
      type: LOADING_GET_NUMERATIONS,
      payload: status
    })
  }
  


/**
 * Filta listado de numeraciones
 * @param {*} list Listado numeraciones
 * @param {*} filter Parametro para filtro
 */
function filterList(list, filter) {

    if (filter === '')
        return list;

    list = list.filter(c =>
        c.resolucion?.toLowerCase()?.match(new RegExp(filter.toLowerCase() + ".*")) ||
        c.prefijo?.toLowerCase()?.match(new RegExp(filter.toLowerCase() + ".*")) ||
        c.nombre?.toLowerCase()?.match(new RegExp(filter.toLowerCase() + ".*")) ||
        c.diantipomodalidaddescripcion?.toLowerCase()?.match(new RegExp(filter.toLowerCase() + ".*"))
    );
    return list;
}


/**
 * Sincroniza  numeraciones con existentes en DIAN
 */
export const getSynchronizationDian = () => async (dispatch) => {

    try {
        changeSyncStatusAction(dispatch, true);
        const body = {
            'empresaId': `${enterpriseIdHeader()}`
        }

        var result = await axios.post(`${urlNumeration}/getSynchronizationDian`, body, defaultHeaders());
        dispatch({
            type: SYNC_GET_NUMERATION,
            payload: result.data
        })

    } catch (err) {
        console.log("Error sincronizando listado de numeraciones,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido sincronizar listado de numeraciones'
        });

    } finally {
        changeSyncStatusAction(dispatch, false);
    }
}

/**
 * Actualiza estado loading sincronización DIAN
 * @param {*} dispatch 
 * @param {boolean} status Nuevo estado
 */
function changeSyncStatusAction(dispatch, status) {
    dispatch({
        type: STATUS_SYNC_NUMERATION,
        payload: status,
    });
};



export const getNumeration = (id) => async (dispatch) => {

    let numeration = []

    for (let index = 0; index < numerations.length; index++) {
        if (numerations[index].id === id) {
            numeration = numerations[index]
        }
    }
    const response = {
        httpCode: "200",
        responseCode: "OK",
        content: numeration
    }
    dispatch({
        type: GET_NUMERATION,
        payload: response
    })


}

/**
 * Consulta listado de modalidades existentes.
 */
export const getModalities = () => async (dispatch) => {

    try {
        var result = await axios.get(`${urlModality}`, defaultHeaders());

        modalities = result.data;
        dispatch({
            type: GET_MODALITY_TYPES,
            payload: modalities
        })

    } catch (err) {
        console.log("Error obteniendo listado de modalidades,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido obtener listado de modalidades.'
        });
    }
}

/**
 * Registra información de  una numeración
 * @param {object} data Información numeración
 */
export const createNumerationAction = (data) => async (dispatch) => {

    try {
        changeSaveLoading(dispatch, true);
        data = {
            ...data,
            empresaId: `${enterpriseIdHeader()}`
        }

        var result = await axios.post(`${urlNumeration}`, data, defaultHeaders());
        dispatch({
            type: SAVE_NUMERATION,
            payload: result.data,
        });

    } catch (err) {
        console.log("Error guardando numeración,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido guardar numeración'
        });
    } finally {
        changeSaveLoading(dispatch, false);
    }
};

/**
 * Actualiza información  una numeracion
 * @param {object} body Nueva información de nuemración
 */
export const updateNumerationAction = (data) => async (dispatch) => {

    try {
        changeSaveLoading(dispatch, true);
        data = {
            ...data,
            empresaId: `${enterpriseIdHeader()}`
        }

        var result = await axios.put(`${urlNumeration}/UpdateNumeration`, data, defaultHeaders());
        dispatch({
            type: UPDATE_NUMERATION,
            payload: result.data,
        });

    } catch (err) {
        console.log("Error actualizando numeracion,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar numeracion'
        });
    } finally {
        changeSaveLoading(dispatch, false);
    }
};

/**
 * Actualiza estado loading guardado o actualización numeración
 * @param {*} dispatch 
 * @param {boolean} status Nuevo estado loaging
 */
function changeSaveLoading(dispatch, status) {
    dispatch({
        type: LOADING_SAVE_NUMERATION,
        payload: status
    })
}


/**
 * Actualiza estado de numeracion
 * @param {object} data Información de numeración
 */
export const updteNumerationStateAction = (data) => async (dispatch) => {

    try {
        changeUpdateStateLoading(dispatch, true, data.idnumeracion);

        let config = defaultHeaders();
        Object.assign(config.headers,{
                'idnumeracion': data.idnumeracion,
                'idstate': data.idstate
            })

        var result = await axios.put(`${urlNumeration}/UpdateState`, null, config);

        //Agrega nuevo estado de numeración
        let response = {
            ...result.data,
            idstate: data.idstate,
            idnumeracion: data.idnumeracion,
        
        }

        dispatch({
            type: UPDATE_NUMERATION_STATE,
            payload: response
        })

    } catch (err) {
        console.log("Error actualizando estado de numeración,", !!err.response ? err.response : err);
        handleResponse(err.response);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: GET_ERRORS,
            payload: response,
            payload1: 'No se ha podido actualizar estado de numeración.'
        });

    } finally {
        changeUpdateStateLoading(dispatch, false, data.idnumeracion);
    }
}

/**
 * Cambia estado loading para cambio de estado dentro del listado de numeraciones
 * @param {object} dispatch 
 * @param {boolean} status  Estado loading
 */
function changeUpdateStateLoading(dispatch, status, id) {

    let newData = filteredNumerations.find(n => n.id === id);

    if (!!newData) {
        const index = filteredNumerations.findIndex(e => e.id === id);
        let newArray = [...filteredNumerations];
        newArray[index] = {
            ...newData,
            loading: status
        };
        filteredNumerations = newArray;
    }

    dispatch({
        type: GET_NUMERATIONS,
        payload: numerations,
        payload1: filteredNumerations
    })
}


/**
 * Actualiza listado de nuemraciónes, despues de cambiar el estado (activar, inactivar)
 * @param {int} id Identificador numeración actualizada
 * @param {int} idstate Identificador nuevo estado numeración
 */
export const refreshAfterChangeStatusAction = (id, idstate, filter) => async (dispatch) => {

    let newData = numerations.find(n => n.id === id);

    if (!!newData) {
        const index = numerations.findIndex(e => e.id === id);
        let newArray = [...numerations];
        newArray[index] = { ...newData, idstate: idstate };
        numerations = newArray;
    }

    filteredNumerations = filterList(numerations, filter);
    dispatch({
        type: GET_NUMERATIONS,
        payload: numerations,
        payload1: filteredNumerations
    })
}

/**
 * CRaga información de numeración en Redux
 * para edición.
 * @param {object} data Información numeración 
 */
export const loadNumerationAction = (data) => async (dispatch) => {

    dispatch({
        type: LOAD_NUMERATION,
        payload: data
    })
};


export const fillListNumerations = (listNumerations) => {
    numerations = listNumerations
}



export const changeStepStatusAction = (status) => (dispatch) => {
    dispatch({
        type: STATUS_STEP_NUMERATION,
        payload: status,
    });
};

