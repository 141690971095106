import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { SuccessAlert, ConfirmAlert } from '../../helpers/alert.helpers'
import CollapsePanel from '../../components/collapse/collapse.component';
import NewClient from '../clients/newClient.component'
import ClientDetail from "./clientDetail.component.js";
import ListWithFilter from '../../components/list/listWithFilter.component'

import { getRegimeTypesAction } from '../../actions/configAction';
import {
    loadClientAction,
    loadClientDetailAction,
    deleteClientAction,
    clearLoadClientAction,
    clearLoadClientDetailAction,
    standOutClientAction,
    getClientsAction
} from '../../actions/clientAction'
import isEmpty from './../../utils/isEmpty';

class ClientManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                txtFilter: '',
            },
            expandCollapse: '',
            loading: false,
            getClientListResponse: [],
            clientsList: [],
            editClientStatus: false,
            openPanel: '',
            loadingListClients: false,
            loadClientDetail: null,
            deleteClientResponse: {},
            standOutClientResponse: {},
            saveClientResponse: {},
            updateClientResponse: {},
            getRegimeTypesResponse: [],
            loadingDetail: false,
            loadingSave: false,
        }
    }

    componentDidMount() {
        this.props.getClientsAction();
        this.props.getRegimeTypesAction();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (nextProps.generalReducer.loading !== state.loading)
            update.loading = nextProps.generalReducer.loading;

        if (!!nextProps.clientReducer.getClientListResponse && nextProps.clientReducer.getClientListResponse !== state.getClientListResponse)
            update.getClientListResponse = nextProps.clientReducer.getClientListResponse;

        if (nextProps.clientReducer.editClientStatus !== state.editClientStatus)
            update.editClientStatus = nextProps.clientReducer.editClientStatus;

        if (nextProps.clientReducer.loadingListClients !== state.loadingListClients)
            update.loadingListClients = nextProps.clientReducer.loadingListClients;

        if (nextProps.clientReducer.loadClientDetail !== state.loadClientDetail)
            update.loadClientDetail = nextProps.clientReducer.loadClientDetail;

        if (!!nextProps.clientReducer.deleteClientResponse && nextProps.clientReducer.deleteClientResponse !== state.deleteClientResponse)
            update.deleteClientResponse = nextProps.clientReducer.deleteClientResponse;


        if (!!nextProps.clientReducer.standOutClientResponse !== nextProps.clientReducer.standOutClientResponse !== state.standOutClientResponse)
            update.standOutClientResponse = nextProps.clientReducer.standOutClientResponse;

        if (!!nextProps.clientReducer.saveClientResponse && nextProps.clientReducer.saveClientResponse !== state.saveClientResponse) {
            update.saveClientResponse = nextProps.clientReducer.saveClientResponse;
            update.openPanel = '';
        }

        if (!!nextProps.clientReducer.updateClientResponse && nextProps.clientReducer.updateClientResponse !== state.updateClientResponse) {
            update.updateClientResponse = nextProps.clientReducer.updateClientResponse;
            update.openPanel = '';
        }

        if (!!nextProps.configReducer.getRegimeTypesResponse && nextProps.configReducer.getRegimeTypesResponse !== state.getRegimeTypesResponse)
            update.getRegimeTypesResponse = nextProps.configReducer.getRegimeTypesResponse;

        if (!isEmpty(nextProps.clientReducer.loadingDetail) && nextProps.clientReducer.loadingDetail !== state.loadingDetail)
            update.loadingDetail = nextProps.clientReducer.loadingDetail;

        if (!isEmpty(nextProps.clientReducer.loadingSave) && nextProps.clientReducer.loadingSave !== state.loadingSave)
            update.loadingSave = nextProps.clientReducer.loadingSave;

        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.deleteClientResponse !== this.state.deleteClientResponse) {
            if (this.state.deleteClientResponse.statusCode === '201' || this.state.deleteClientResponse.statusCode === '200') {
                SuccessAlert("Genial...!", "Se ha eliminado este cliente");
                this.cleanComponent();
            }
        }

        if (prevState.standOutClientResponse !== this.state.standOutClientResponse) {
            if (this.state.standOutClientResponse.statusCode === '201' || this.state.standOutClientResponse.statusCode === '200') {
                this.refreshBeforeFavorite();
            }
        }

        //Actualiza lista de clientes despues de Guardar desde el componente NewClient
        if (prevState.saveClientResponse !== this.state.saveClientResponse) {
            if (this.state.saveClientResponse.statusCode === '201' || this.state.saveClientResponse.statusCode === '200') {
                this.props.getClientsAction();
            }

        }

        //Actualiza lista de clientes despues de Actualizar desde el componente NewClient
        if (prevState.updateClientResponse !== this.state.updateClientResponse) {
            if (this.state.updateClientResponse.statusCode === '201' || this.state.updateClientResponse.statusCode === '200') {
                this.props.getClientsAction();
            }
        }

        //Carga detalle cliente despues de nuevo cargue del listado clientes
        if (prevState.getClientListResponse !== this.state.getClientListResponse) {
            const newList = this.filterTypeClient(this.state.getClientListResponse);
            const newdata = newList[0];
            if (!!newdata) {
                this.props.loadClientDetailAction(newdata);
            }
        }
    }

    componentWillUnmount() {
        this.props.clearLoadClientDetailAction();
    }


    /**
     * Sincroniza input con state de componente
     * @param {*} e 
     */
    syncChanges(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }



    /**
     * Captura evento click expansión de panel nuevo cliente
     */
    onClickCollapse = () => {
        if (this.state.openPanel === 'panel') {
            this.setState({
                openPanel: ''
            })
        } else if (this.state.openPanel === '') {
            this.setState({
                openPanel: 'panel',
            })
        }
    }

    /**
     * Carga en redux detalle de cliente
     * @param {object} e  Evento
     * @param {object} item Información cliente a cargar en detalle
     */
    onClickClient(e, item) {
        e.preventDefault();
        this.props.loadClientDetailAction(item);
    }

    /**
     * Envia Objeto cliente a formulario para edición
     * @param {object} e Evento
     * @param {object} item Información cliente a cargar en formulario 
     */
    onLoadClient = (e, item) => {
        e.preventDefault();
        this.props.loadClientAction(item);

        this.setState({
            openPanel: 'panel',
        })
    }

    /**
     * Evento, Eliminar Cliente
     * @param {object} e Evento
     * @param {object} item  Información cliente a eliminar
     */
    onDeleteClient = (e, item) => {
        e.preventDefault();
        const deleteData = (confirmed) => {
            if (confirmed) {
                this.props.deleteClientAction(item.id);
            }
        }
        ConfirmAlert('Eliminar cliente.', `¿Desea eliminar el cliente ${item.razonsocial}?`, deleteData)
    }

    /**
     * Destacar cliente
     * @param {object} e Evento
     * @param {object} item Información cliente a desctacar
     */
    onStandOutClient = (e, item) => {
        e.preventDefault();
        this.props.standOutClientAction(item.id);
    }

    /**
     * Limpia información cargada en formulario de nuevo cliente
     * y detalle cliente
     */
    cleanComponent() {
        this.props.clearLoadClientAction();
        this.props.clearLoadClientDetailAction();
        this.props.getClientsAction();
    }

    /**
     * Obtiene descripción de tipo regimen
     */
    getRegimeTypeName() {
        const list = this.state.getRegimeTypesResponse;
        const loadClient = this.state.loadClientDetail;
        const regimeName = list.filter(x => {
            const value = x.value;
            return value.indexOf(loadClient.diantiporegimenid) > -1;
        });
        return regimeName[0];
    }

    /**
     * Actualiza vista despues de cabio estado favorito
     * desde el detalle del cliente
     */
    refreshBeforeFavorite() {
        this.props.clearLoadClientAction();
        this.props.getClientsAction();
        let newData = {
            ...this.state.loadClientDetail,
            favorito: !this.state.loadClientDetail.favorito
        }
        this.props.loadClientDetailAction(newData);
    }

    /**
     * Filtra listado clientes por tipo
     * @param {Array} list lista a filtrar
     */
    filterTypeClient(list) {
        const type = this.props.type;

        list = !!list? list : [];
        let newList = [];

        if (type === 0) { // Filtro todos
            newList = list;
        } else if (type === 1) {
            newList = list.filter(c => c.favorito === true); // Favoritos
        } else if (type === 2) {
            newList = list.sort((a, b) => { //Recientes
                return (b.id - a.id)
            });
        } else {
            newList = list;
        }

        return newList;
    }



    render() {

        return (
            <Fragment >
                <div style={{ minWidth: 800 }}>
                        <CollapsePanel
                            component={NewClient}
                            title={!this.state.editClientStatus ? "Crear nuevo cliente" : "Editar cliente"}
                            titleOpen={!this.state.editClientStatus ? "Nuevo cliente" : "Editar cliente"}
                            open={this.state.openPanel}
                            onClick={this.onClickCollapse} />
               
                    <div className="comunContainer">
                        <Grid container spacing={3}>
                            <Grid item lg={6} xs={6}>
                             
                                <ListWithFilter
                                    list={this.filterTypeClient(this.state.getClientListResponse) }
                                    onClick={(e, item) => {
                                        this.onClickClient(e, item)
                                    }}
                                    loading={this.state.loadingListClients}
                                    top={30}
                                />
                            </Grid>
                            <Grid item lg={6} xs={6}>
                                    <ClientDetail
                                        data={this.state.loadClientDetail}
                                        regimeTypeName={this.state.loadClientDetail && this.getRegimeTypeName()}
                                        onUpdate={(e) => {
                                            this.onLoadClient(e, this.state.loadClientDetail);
                                        }}
                                        onDelete={(e) => {
                                            this.onDeleteClient(e, this.state.loadClientDetail);
                                        }}
                                        onStandOut={(e) => {
                                            this.onStandOutClient(e, this.state.loadClientDetail);
                                        }}
                                        loadingDetail={this.state.loadingDetail}
                                        disabled={this.state.loadingSave === true ||  this.state.loadingListClients === true ? true: false}
                                        history={this.history}
                                    />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Fragment>
        )
    }



}

ClientManage.propTypes = {
    loadClientAction: PropTypes.func.isRequired,
    loadClientDetailAction: PropTypes.func.isRequired,
    deleteClientAction: PropTypes.func.isRequired,
    clearLoadClientAction: PropTypes.func.isRequired,
    clearLoadClientDetailAction: PropTypes.func.isRequired,
    standOutClientAction: PropTypes.func.isRequired,
    getClientsAction: PropTypes.func.isRequired,

    generalReducer: PropTypes.object.isRequired,
    clientReducer: PropTypes.object.isRequired,
    getRegimeTypesAction: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    generalReducer: state.generalReducer,
    clientReducer: state.clientReducer,
    configReducer: state.configReducer,
})

export default connect(mapStateToProps, {
    loadClientAction,
    loadClientDetailAction,
    deleteClientAction,
    clearLoadClientAction,
    clearLoadClientDetailAction,
    standOutClientAction,
    getRegimeTypesAction,
    getClientsAction
})(ClientManage);