import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { SuccessAlert } from '../../../helpers/alert.helpers'
import { regexDecimal,regexNames } from '../../../helpers/customRegex.hepers'
import InputForm from "../../../components/input/input.component";
import SelectForm from '../../../components/select/select.component'
import RadioButtonGroup from '../../../components/radioButton/radioButton.component'
import ButtonSecundary from '../../../components/button/buttonSecundary.component'
import ToggleButtons from '../../../components/toggle/toggle.component'
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";

import { getReasonDiscount, postDiscount, getDiscounts } from '../../../actions/discountAction';
import { clearLoadedDiscountInvoiceAction, updateDiscountToInvoiceAction, openModalDiscountAction, getTotalAction } from '../../../actions/valuesInvoiceAction'
import { getMenuComponentAction, cleanMenuComponentAction } from "../../../actions/configAction";
import getTypeValue from '../../../utils/typeValue'


class NewDiscountInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                toogleModelDiscount: '1',
                ddlDicountMotive: '',
                txtDicountValue: '',
                txtDicountDescription: '',
                rbgTypeDicount: ''
            },
            getReasonDiscount: [],
            saveDiscountResponse: {},
            loadingSave: false,
            loadDiscountDocument: {},
            editDiscountDocument: false,
            updateDiscountDocumentResponse: {},
            configurableMenu: {}
        }
        this.validator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentDidMount() {
        this.props.getReasonDiscount();
        this.props.getMenuComponentAction(3);
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.discountReducer.getReasonDiscount && nextProps.discountReducer.getReasonDiscount !== state.getReasonDiscount)
            update.getReasonDiscount = nextProps.discountReducer.getReasonDiscount;

        if (!!nextProps.discountReducer.createDiscount && nextProps.discountReducer.createDiscount !== state.saveDiscountResponse)
            update.saveDiscountResponse = nextProps.discountReducer.createDiscount;

        if (nextProps.discountReducer.loadingSave !== state.loadingSave)
            update.loadingSave = nextProps.discountReducer.loadingSave;

        if (!!nextProps.valuesInvoiceReducer.loadDiscountDocument && nextProps.valuesInvoiceReducer.loadDiscountDocument !== state.loadDiscountDocument) {
            update.loadDiscountDocument = nextProps.valuesInvoiceReducer.loadDiscountDocument;
            const item = nextProps.valuesInvoiceReducer.loadDiscountDocument;
            update.form = {
                toogleModelDiscount: item.modelodescuento.toString(),
                ddlDicountMotive: item.motivoid.toString(),
                txtDicountValue: item.valor,
                txtDicountDescription: item.descripcion,
                rbgTypeDicount: item.tipodescuento.toString()
            };
        }

        if (nextProps.valuesInvoiceReducer.editDiscountDocument !== state.editDiscountDocument)
            update.editDiscountDocument = nextProps.valuesInvoiceReducer.editDiscountDocument;

        if (!!nextProps.valuesInvoiceReducer.updateDiscountDocumentResponse && nextProps.valuesInvoiceReducer.updateDiscountDocumentResponse !== state.updateDiscountDocumentResponse) {
            update.updateDiscountDocumentResponse = nextProps.valuesInvoiceReducer.updateDiscountDocumentResponse;
        }

        
        if (!!nextProps.configReducer.menuComponent && nextProps.configReducer.menuComponent !== state.menuComponent) {
            update.menuComponent = nextProps.configReducer.menuComponent;
            let menu = nextProps.configReducer.menuComponent;
            let discountModelComponent = menu.find(e => e.id === 97)?.adcomponenteinformacion;
            update.configurableMenu = {
                ...update.configurableMenu,
                discountModelTitle: !!discountModelComponent?.find(e => e.id === 169)?.text ? discountModelComponent.find(e => e.id === 169)?.text : '',
                discountModelMessage: !!discountModelComponent?.find(e => e.id === 170)?.text ? discountModelComponent.find(e => e.id === 170)?.text : '',
                discountModelButton: !!discountModelComponent?.find(e => e.id === 171)?.text ? discountModelComponent.find(e => e.id === 171)?.text : '',
                discountModelHref: !!discountModelComponent?.find(e => e.id === 171)?.url ? discountModelComponent.find(e => e.id === 171)?.url : '',
            }
            let discountTypeComponent = menu.find(e => e.id === 98)?.adcomponenteinformacion;
            update.configurableMenu = {
                ...update.configurableMenu,
                discountTypelTitle: !!discountTypeComponent?.find(e => e.id === 172)?.text ? discountTypeComponent.find(e => e.id === 172)?.text : '',
                discountTypeMessage: !!discountTypeComponent?.find(e => e.id === 173)?.text ? discountTypeComponent.find(e => e.id === 173)?.text : '',
                discountTypeButton: !!discountTypeComponent?.find(e => e.id === 174)?.text ? discountTypeComponent.find(e => e.id === 174)?.text : '',
                discountTypeHref: !!discountTypeComponent?.find(e => e.id === 174)?.url ? discountTypeComponent.find(e => e.id === 174)?.url : '',
            }
        }
        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.saveDiscountResponse !== this.state.saveDiscountResponse) {
            if (this.state.saveDiscountResponse.statusCode === '201') {
                SuccessAlert('Genial...!', 'Se ha registrado exitosamente el nuevo cargo.');
                this.props.getDiscounts('', true);
                this.cleanForm();
            }
        }

        if (prevState.updateDiscountDocumentResponse !== this.state.updateDiscountDocumentResponse) {
            if (this.state.updateDiscountDocumentResponse.statusCode === '200') {
                this.props.openModalDiscountAction(false);
                this.cleanForm();
            }
        }
    }

    componentWillUnmount() {
        this.props.cleanMenuComponentAction();
    }


    //Sincroniza state Input
    syncChanges(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    //Sincroniza estado Radio button Group
    syncRadioChanges(e, id) {
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    validateDecimal(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        value = value.replace(regexDecimal, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }


    /**
     * Elimina caracteres no validos, solo caracteres validos para nombres
     * @param {*} e  Evento
     */
    validateNames(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexNames;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    /**
     * @event
     * @description Evento cambio modelo de descuento
     */
    onChangeModelDiscount = (event, newAlignment) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                toogleModelDiscount: !!newAlignment ? newAlignment : '1'
            }
        }))
    };


    /**
     * @event
     * @description Evento click, Guardado nuevo descuento empresa
     */
    onSaveCharge = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {

            let discount = {
                descripcion: this.state.form.txtDicountDescription,
                motivoid: parseInt(this.state.form.ddlDicountMotive),
                modelodescuento: parseInt(this.state.form.toogleModelDiscount),
                tipodescuento: parseInt(this.state.form.rbgTypeDicount),
                valor: parseFloat(this.state.form.txtDicountValue)
            }

            this.props.postDiscount(discount);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    /**
     * @event 
     * @description Edita valor de un descuento agregado al documento (Factura, Nota)
     * @param {*} e
     */
    onUpdateDiscountDocument(e) {
        e.preventDefault();
        if (this.validator.allValid()) {

            let discountLoaded = this.state.loadDiscountDocument;
            const reasons = !!this.state.getReasonDiscount ? this.state.getReasonDiscount : [];
            let motive = reasons.filter(r => r.id === parseInt(this.state.form.ddlDicountMotive));
            let descripcion = !!motive[0]?.descripcion ? motive[0]?.descripcion : '';

            let discountInvoice = {
                ...discountLoaded,
                modelodescuento: parseInt(this.state.form.toogleModelDiscount),
                motivoid: parseInt(this.state.form.ddlDicountMotive),
                motivoDescripcion: descripcion,
                tipodescuento: parseInt(this.state.form.rbgTypeDicount),
                valor: parseFloat(this.state.form.txtDicountValue)
            }

            this.props.updateDiscountToInvoiceAction(discountInvoice);

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    /**
    * @function
    * @description Limpia formulario
    */
    cleanForm() {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                toogleModelDiscount: '1',
                ddlDicountMotive: '',
                txtDicountValue: '',
                txtDicountDescription: '',
                rbgTypeDicount: ''
            },
        }))
        this.props.clearLoadedDiscountInvoiceAction();
        this.validator.hideMessages();
    }

    
    render() {
        return (
            <Fragment>
                <Grid container spacing={1}>
                    <Grid item lg={6} xs={12}>
                        <ToggleButtons
                            label={"Modelo de descuento"}
                            value={this.state.form.toogleModelDiscount}
                            onChange={this.onChangeModelDiscount}
                            titleOne={'Comercial'}
                            titleTwo={'Financiero'}
                            txtTooltip={
                                <TooltipMessage
                                    title={this.state.configurableMenu.discountModelTitle}
                                    message={this.state.configurableMenu.discountModelMessage}
                                    botton={this.state.configurableMenu.discountModelButton}
                                    href={this.state.configurableMenu.discountModelHref}
                                />}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <SelectForm
                            label={"Motivo descuento *"}
                            name="ddlDicountMotive"
                            value={this.state.form.ddlDicountMotive}
                            options={this.state.getReasonDiscount}
                            onChange={(e) => {
                                this.syncChanges(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required"}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <RadioButtonGroup
                            name={'rbgTypeDicount'}
                            value={this.state.form.rbgTypeDicount}
                            onChange={(e) => this.syncRadioChanges(e, 'rbgTypeDicount')}
                            options={getTypeValue()}
                            label={"Tipo Descuento:"}
                            validator={this.validator}
                            validateOptions={"required"}
                            txtTooltip={
                                <TooltipMessage
                                    title={this.state.configurableMenu.discountTypelTitle}
                                    message={this.state.configurableMenu.discountTypeMessage}
                                    botton={this.state.configurableMenu.discountTypeButton}
                                    href={this.state.configurableMenu.discountTypeHref}
                                />}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <InputForm
                            name={"txtDicountValue"}
                            label={this.state.form.rbgTypeDicount === '2' ? "% Descuento *" : "Valor Descuento *"}
                            maxLength={100}
                            value={this.state.form.txtDicountValue}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateDecimal(e);
                            }}
                            nameValidator={"valorDescuento"}
                            validator={this.validator}
                            validateOptions={this.state.form.rbgTypeDicount === '1' ? "required" : "required|porcentage"}
                            format={this.state.form.rbgTypeDicount === '1' ? true : false}

                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <InputForm
                            name={"txtDicountDescription"}
                            label={"Descripción *"}
                            maxLength={100}
                            value={this.state.form.txtDicountDescription}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateNames(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required"}
                            disabled={this.state.editDiscountDocument === true ? true : false}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={3} xs={12}></Grid>
                    <Grid item lg={6} xs={12}>
                        <ButtonSecundary
                            text={'Confirmar'}
                            loading={this.state.loadingSave}
                            onClick={this.state.editDiscountDocument === true ?
                                (e) => this.onUpdateDiscountDocument(e) :
                                (e) => this.onSaveCharge(e)} />
                    </Grid>
                    <Grid item lg={3} xs={12}></Grid>
                </Grid>
            </Fragment>

        );
    }


}

NewDiscountInvoice.propTypes = {
    getReasonDiscount: PropTypes.func.isRequired,
    postDiscount: PropTypes.func.isRequired,
    getDiscounts: PropTypes.func.isRequired,
    clearLoadedDiscountInvoiceAction: PropTypes.func.isRequired,
    updateDiscountToInvoiceAction: PropTypes.func.isRequired,
    openModalDiscountAction: PropTypes.func.isRequired,
    getTotalAction: PropTypes.func.isRequired,
   cleanMenuComponentAction: PropTypes.func.isRequired,

    valuesInvoiceReducer: PropTypes.object.isRequired,
    discountReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    discountReducer: state.discountReducer,
    valuesInvoiceReducer: state.valuesInvoiceReducer,
    configReducer: state.configReducer

})

export default connect(mapStateToProps, {
    getReasonDiscount,
    postDiscount,
    getDiscounts,
    clearLoadedDiscountInvoiceAction,
    updateDiscountToInvoiceAction,
    openModalDiscountAction,
    getTotalAction,
    getMenuComponentAction,
    cleanMenuComponentAction
})(NewDiscountInvoice);