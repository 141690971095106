import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { validateTokenAction } from './../../actions/authActions';

const useStyles = (theme) => ({
  divContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  gridAling: {
    margin: '20px',
    padding: '20px'
  },
});

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loginState: false,
    }
  }

  componentDidMount() {

    const queryString = this.props.match.params.queryString;
    const valitateJwt = this.props.validateTokenAction(queryString, this.props.history);
    valitateJwt.then(data => {
      this.setState({
        loginState: true,
      });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item lg={4} md={4} sm={12}></Grid>
          <Grid item lg={4} md={4} sm={12} className={classes.gridAling}>
            <div className={classes.divContainer}>
              <CircularProgress size={60} thickness={7} />
              {
                this.state.loginState === true
                  ? <Redirect to='/dashboard' />
                  : null
              }

            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12}></Grid>
        </Grid>
      </div>
    )
  }
}

Index.propTypes = {
  prop: PropTypes
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
  validateTokenAction,
})(withStyles(useStyles)(Index));