import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#5E5E5E',
    color: theme.palette.primaryColor,
    boxShadow:'none',
    '& .MuiCardHeader-avatar': {
      marginRight:10
    },
    '& .MuiCardContent-root:last-child':{
      paddingBottom: 0,
    }
  },
  avatar: {

  },
  header: {
    padding: 0
  },
  title: {
    color: theme.palette.primaryColor,
    fontWeight: 'bold',
    fontSize: 14
  },
  subheader: {
    color: theme.palette.primaryColor
  },
  icon: {
    color: theme.palette.secundaryColor
  },
  iconAction: {
    color: theme.palette.primaryColor
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
    marginLeft:15
  }
}));

export default function ContactCard(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header}
        avatar={<PersonIcon fontSize="small" className={classes.icon} />}
        action={''}
        classes={{
          title: classes.title,
        }}
        title={data.nombre}
        subheader={''}
      />
      <CardContent className={classes.cardContent}>
      <Grid container>
              <Grid item lg={12} xs={12}>
              {`${data.celular} - ${data.telefono}`}
              </Grid>
              <Grid item lg={12} xs={12}>
                {data.email}
              </Grid>
            </Grid>
      </CardContent>
    </Card>
  );
}
