import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import PersonIcon from '@material-ui/icons/Person';
import AssessmentIcon from '@material-ui/icons/Assessment';
import StyleIcon from '@material-ui/icons/Style';

import ReceiptIcon from '@material-ui/icons/Receipt';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Avatar from "@material-ui/core/Avatar";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import Dashboard from '@material-ui/icons/Dashboard';
import Grid from '@material-ui/core/Grid';
import { Tooltip } from '@material-ui/core';

import CustomProgress from '../../components/Progress/progress.component';

import { getStatesAction } from '../../actions/configAction';
import { cleanErrorAction, setIndexSelectedAction } from '../../actions/generalAction'

import isEmpty from '../../utils/isEmpty'

import { readGeneralError } from '../../helpers/generalError.helpers'
import SelectedMenuImage from '../../images/selected-menu.png'
import SelectedMyAccountIcon from '../../images/selected-colfactura-icon.png'
import MyAccountIcon from '../../images/colfactura-icon.png'
import { logoutUserAction, getRoleOfLoggedUser } from '../../actions/authActions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        color: theme.palette.primaryColor,
        backgroundColor: theme.palette.primaryBackgroundColor,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        }
    },
    // necessary for content to be below app bar
    toolbar: {
        ...theme.mixins.toolbar,
        flexWrap: 'wrap',
        background: theme.palette,

    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },

    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.primaryBackgroundColor,
    },
    content: {
        flexGrow: 1,
        padding: '0px',
        backgroundColor: theme.palette.primaryBackgroundColor,
    },
    itemText: {
        color: theme.palette.primaryColor,
        '&.Mui-selected': {
            background: `url('${SelectedMenuImage}')`,
            backgroundPosition: 'right'
        },
        "&.MuiListItem-root:hover": {
            background: `url('${SelectedMenuImage}')`,
            backgroundPosition: 'right'
        },
    },
    icon: {
        color: theme.palette.primaryColor
    },
    divider: {
        backgroundColor: "#232323",
        marginLeft: '10px',
        marginRight: '10px',
        height: '2px',
    },
    imageApp: {
        padding: '30px 10px 20px 10px'
    },
    wrapTypography: {
        fontSize: 0,
    },
    navUser: {
        color: theme.palette.primaryColor,
        fontWeight: 'bold',
        fontSize: '14px',
        margin: '2px',
        padding: '2px',
    },
    navUserEmail: {
        color: theme.palette.primaryColor,
        fontSize: '12px',
        margin: '2px',
        padding: '2px',
    },
    navWrapperUser: {
        textAlign: 'right',
    },
    divContainer: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
        textAlign: 'center',
    },
    gridAling: {
        margin: '20px',
        padding: '20px',
    },
    logoutShort: {
        paddingTop: '100px',
    },
    logoutLong: {
        paddingTop: '300px',
    },
    textDisabled: {
        color: theme.palette.eighthColor
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const MasterPage = ({ component: Component, container, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState('');
    const { getStatesAction, cleanErrorAction } = props;
    const { logoutUserAction } = props;
    const company = JSON.parse(localStorage.getItem('dataCompany'));
    const menu = props.configReducer.menu;
    let menuList = []

    const nameMenu = (dbName, name) => {
        return !!dbName ? dbName : name
    }

    if (!!menu && menu.length > 0) {
        menuList = [
            {
                text: nameMenu(menu.find(element => element.id === 1).descripcion, 'Inicio'),
                link: '/home',
                icon: <HomeIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 2).descripcion, 'Mi Cuenta'),
                link: (getRoleOfLoggedUser()) ? '/' : '/myAccount',
                icon: (getRoleOfLoggedUser()) ? <i><img src={MyAccountIcon} alt="" /></i> : <i><img src={MyAccountIcon} alt="" /></i>,
                selectedIcon: <i><img src={SelectedMyAccountIcon} alt="" /></i>,
                disabled: (getRoleOfLoggedUser()) ? true : false,
            },
            {
                text: nameMenu(menu.find(element => element.id === 3).descripcion, 'Facturas'),
                link: '/invoice',
                icon: <ReceiptIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 4).descripcion, 'Cotizaciones'),
                link: '/quotation',
                icon: <DescriptionIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 5).descripcion, 'Artículos'),
                link: '/catalogCategory',
                icon: <StyleIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 6).descripcion, 'Cargos'),
                link: '/charges',
                icon: <AddToPhotosIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 12).descripcion, 'Descuentos'),
                link: '/discounts',
                icon: <LocalOfferIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 7).descripcion, 'Clientes'),
                link: '/clients',
                icon: <PersonIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 8).descripcion, 'Informes'),
                link: '/',
                icon: <AssessmentIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 9).descripcion, 'Ayuda'),
                link: '/helps',
                icon: <HelpIcon />
            },
            {
                text: nameMenu(menu.find(element => element.id === 10).descripcion, 'Ir a la tienda'),
                link: '/',
                icon: <ShoppingBasket />
            },
            {
                text: nameMenu(menu.find(element => element.id === 11).descripcion, 'Mi Plan'),
                link: '/',
                icon: <Dashboard />
            }
        ];
    }

    useEffect(() => {
        getStatesAction();
    }, [getStatesAction]);


    //Leer Error
    useEffect(() => {
        if (!isEmpty(props.generalReducer.errorResponse)) {
            if (!isEmpty(props.generalReducer.errorResponse.error)) {
                readGeneralError(props.generalReducer.errorResponse);
                cleanErrorAction();
            }
        }
    }, [cleanErrorAction, props, props.generalReducer.errorResponse]);


    //Leer opcion seleccionada, agrega estilos 
    // a opcion seleccionada
    useEffect(() => {

        if (!isEmpty(props.generalReducer.indexSelected)) {
            setSelectedIndex(props.generalReducer.indexSelected);
        }
    }, [props.generalReducer.indexSelected]);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    /**
     * @event
     * @description Escucha selección de lista
     * @param {*} event 
     * @param {*} index 
     */
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        props.setIndexSelectedAction(index);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} >
                <img className={classes.imageApp} src={require('./../../images/logo-small.png')} width="200" alt={"Logo"} ></img>
            </div>
            <Divider className={classes.divider} />
            <List>
                {(!!menuList && menuList.length > 0)
                    ? menuList.map((menu, index) => (
                        <div key={index} >
                            {
                                (menu.disabled === true)
                                    ? <LightTooltip
                                        title="No tiene permisos sobre esta acción"
                                        placement="right">
                                        <ListItem
                                            button key={menu.text}
                                            className={classes.itemText}
                                            component={Link}
                                            selected={selectedIndex === index} >
                                            <ListItemIcon className={classes.icon}>
                                                {selectedIndex === 1 && selectedIndex === index ? menu.selectedIcon : menu.icon}
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={
                                                <Typography >
                                                    <span className={classes.textDisabled}>{menu.text}</span>
                                                </Typography>}>
                                            </ListItemText>
                                        </ListItem>
                                    </LightTooltip>

                                    : <ListItem
                                        button key={menu.text}
                                        className={classes.itemText}
                                        component={Link}
                                        to={menu.link}
                                        selected={selectedIndex === index}
                                        onClick={(event) => {
                                            handleListItemClick(event, index);
                                        }} >
                                        <ListItemIcon className={classes.icon}>
                                            {selectedIndex === 1 && selectedIndex === index ? menu.selectedIcon : menu.icon}
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary={
                                            <Typography >
                                                <span>{menu.text}</span>
                                            </Typography>}>
                                        </ListItemText>
                                    </ListItem>
                            }

                            {index % 2 !== 0 ?
                                <Divider className={classes.divider} /> : ''
                            }
                        </div>
                    ))
                    : <Grid container>
                        <Grid item lg={12} md={12} sm={12} className={classes.gridAling}>
                            <div className={classes.divContainer}>
                                <CustomProgress /></div>
                        </Grid>
                    </Grid>
                }
            </List>
            <List className={(menuList.length > 0) ? classes.logoutShort : classes.logoutLong} component="nav" aria-label="secondary mailbox folders">
                <ListItem button>
                    <ListItemText
                        className={classes.itemText}
                        primary={
                            <PowerSettingsNewIcon />
                        }
                        onClick={(event) => {
                            logoutUserAction();
                        }}
                    >
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" color="inherit" noWrap className={classes.toolbarTitle}>
                    </Typography>
                    <Typography variant="h6" noWrap className={classes.wrapTypography}>
                        <Grid container className={classes.navWrapperUser}>
                            <Grid item lg={12} mg={12} xs={12} className={classes.navWrapperUser}>
                                <span className={classes.navUser}>
                                    {!!company?.companyName ? company?.companyName : ''}
                                </span>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.navWrapperUser}>
                                <span className={classes.navUserEmail}>
                                    {!!company?.userEmail ? company?.userEmail : ''}
                                </span>
                            </Grid>
                        </Grid>
                    </Typography>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        edge="end"
                        className="iconAccount"
                    />
                    <Avatar alt={!!company?.userName ? company?.userName.toUpperCase() : ''} src="/static/image/3.jpg" />
                    {/* <IconButton edge="end" aria-label="show 3 new notifications" color="inherit">
                        <Badge badgeContent={3} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    */}
                </Toolbar>
            </AppBar>
            <nav className={`${classes.drawer} 'custom-drawer'`} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />

                <Component {...props} history={props.history} />
            </main>
        </div>
    );
}



MasterPage.propTypes = {
    container: PropTypes.any,

    cleanErrorAction: PropTypes.func.isRequired,
    getStatesAction: PropTypes.func.isRequired,
    setIndexSelectedAction: PropTypes.func.isRequired,

    configReducer: PropTypes.object.isRequired,
    generalReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    configReducer: state.configReducer,
    generalReducer: state.generalReducer,
    basicDataReducer: state.basicDataReducer,
})

export default connect(mapStateToProps, {
    getStatesAction,
    cleanErrorAction,
    setIndexSelectedAction,
    logoutUserAction,
})(MasterPage);
