import {
    SAVE_CONTACT
    , GET_CONTACTSLIST
    , DELETE_CONTACT
    , LOAD_CONTACT
    , UPDATE_CONTACT
} from './types'

let contactList = [];



export const saveContactAction = (contact) => (dispatch) => {
    let response = {};
    if (!existContact(contact.id)) {
        response = {
            responseCode: "A10",
            message: "Se ha agregado un contacto adicional",
        }
        contactList.push(contact);
    } else {
        response = {
            responseCode: "R11",
            message: "El contacto ya encuentra registrado",
        }
    }

    dispatch({
        type: SAVE_CONTACT,
        payload: response
    })
}

export const getContactsAction = () => (dispatch) => {
    dispatch({
        type: GET_CONTACTSLIST,
        payload: contactList
    })
}

export const deleteContactAction = (id) => (dispatch) => {

    contactList = contactList.filter(a => a.id !== id);
    let response = {
        responseCode: "A10",
        message: "El contacto se ha eliminado",
    }

    dispatch({
        type: DELETE_CONTACT,
        payload: response
    });

    clearLoadedContact(dispatch);

    dispatch({
        type: GET_CONTACTSLIST,
        payload: contactList
    });


}

export const loadContactAction = (contact) => (dispatch) => {
    dispatch({
        type: LOAD_CONTACT,
        payload: contact
    })
}

export const updateContactAction = (contact) => (dispatch) => {
    try {
        let newContacts = contactList.filter(b => b.id !== contact.id);
        contactList = newContacts;
        contactList.push(contact);

        let response = {
            responseCode: "A10",
            message: "Se ha actualizado contacto adicional",
        };

        dispatch({
            type: UPDATE_CONTACT,
            payload: response
        });

        clearLoadedContact(dispatch);

        dispatch({
            type: GET_CONTACTSLIST,
            payload: contactList
        })



    } catch (err) {
        console.log("ERROR", err);
    }
}

export const loadContactListAction = (list) => (dispatch) => {
    contactList = list;
    dispatch({
        type: GET_CONTACTSLIST,
        payload: contactList
    })
}

export const clearContactListAction = () => (dispatch) => {
    contactList = [];
    dispatch({
        type: GET_CONTACTSLIST,
        payload: contactList
    })
}

function existContact(id) {
    let item = contactList.filter(a => a.id === id);
    return item.length ? true : false;
}

function clearLoadedContact(dispatch) {
    dispatch({
        type: LOAD_CONTACT,
        payload: null
    })
}