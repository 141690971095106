import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        textAlign: 'center',
    },
    progress: {
        color: theme.palette.thirdColor
    }
}));

export default function CustomProgress(props) {
    const classes = useStyles();
    const { size } = props;

    return (
        <div className={classes.root}>
            <CircularProgress className={classes.progress} style={{ width: !!size ? size : 40, height: !!size ? size : 40 }} />
        </div>
    );
}