import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CustomTabs from "../../components/tabs/tabs.component";
import ClientManage from './clientManage.component';
import { cleanErrorAction } from '../../actions/generalAction'


class Clients extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) { }

  componentWillUnmount() { }

  getTabs = () => {
    return [
      {
        title: 'Todos',
        component: <ClientManage type={0} {...this.props} />
      },
      {
        title: 'Favoritos',
        component: <ClientManage type={1} {...this.props} />
      },
      {
        title: 'Recientes',
        component: <ClientManage type={2} {...this.props} />
      }
    ];
  }

  render() {
    return (
      <div className="container-form" >
        <CustomTabs tabs={this.getTabs()} />
      </div>
    );
  }


}

Clients.propTypes = {
  cleanErrorAction: PropTypes.func.isRequired,
  generalReducer: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  generalReducer: state.generalReducer
})

export default connect(mapStateToProps, {
  cleanErrorAction
})(Clients);