
import React,{ useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Article from '../catalogCategory/article/article.view';
import Category from '../catalogCategory/category/category.view';
import CustomTabs from "../../components/tabs/tabs.component";

import { sendTabArticle } from '../../actions/articleActions'
import { getComponentsAction, ClearGetComponentsAction } from '../../actions/configAction'



const tabs = [
  {
    title: 'Artículos',
    component: <Article type={0} /> //Todos
  },
  {
    title: 'Favoritos',
    component: <Article type={1} />  //Favoritos
  },
  {
    title: 'Categorías',
    component: <Category/>
  }
];


const CatalogCategory = (props) => {
  const { sendTabArticle, getComponentsAction , ClearGetComponentsAction} = props;


  useEffect(() => {
    getComponentsAction(5);
    // returned function will be called on component unmount 
    return () => {
      ClearGetComponentsAction()
    }
  },[])


  const tabIndex = (value) => {
    sendTabArticle(value);
  };

 
  return (
    <div className="container-form">
      <CustomTabs tabs={tabs} tabIndex={tabIndex} using={true} />
    </div>
  );
};


CatalogCategory.propTypes = {
  sendTabArticle: PropTypes.func.isRequired,
  getComponentsAction: PropTypes.func.isRequired,
  ClearGetComponentsAction: PropTypes.func.isRequired,
  generalReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  generalReducer: state.generalReducer
});

export default connect(mapStateToProps, {
  sendTabArticle,
  getComponentsAction,
  ClearGetComponentsAction
})(CatalogCategory);
