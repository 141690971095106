import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import QuotationForm from '../../invoice/quotation/quotationForm.view';
import QuotationRevision from '../../invoice/quotation/quotationRevision.view';
import InvoiceItem from './../../invoice/invoiceItem/invoiceItem.view.js';

import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';

import CustomProgress from '../../../components/Progress/progress.component';
import { updateDocument, createElectronicInvoice, editQoutation, setActiveStepInvoiceAction } from "../../../actions/invoiceActions";
import { getTotalAction, setStatusShowCartAction, clearLoadedChargeInvoiceActionAll, clearLoadedDiscountInvoiceActionAll } from "../../../actions/valuesInvoiceAction";
import { useHistory } from 'react-router-dom';
import { ConfirmAlert, WarningAlert } from '../../../helpers/alert.helpers';
import ResponseModal from '../../../components/modal/responseModal.component';
import FileModalData from '../../../components/modal/fileModalData.component';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secundaryBackgroundColor,
        paddingBottom: '18px',
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingRight: '0px'
    },
    activeButton: {
        borderBottom: '1px solid #50C1EE',
        paddingBottom: '10px'
    }
}));

const QontoConnector = withStyles({
    root: {},
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    line: {
        borderColor: '#4F4F4F',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (<div className={
        clsx(classes.root, {
            [classes.active]: active,
        })
    } > {completed ? < Check className={classes.completed} /> : <div className={classes.circle} />} </div>
    );
}

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#FFFFFF',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#2CC63E',
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: '#4F4F4F',
    },
    completed: {
        color: '#4F4F4F',
        zIndex: 1,
        fontSize: 18,
        backgroundColor: '#2CC63E',
        borderRadius: '50%',
        padding: 5
    },

});

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};



function getSteps() {
    return ['Información de Cotización', 'Artículos', 'Revisar Cotización'];
}

const QuotationEdit = (props) => {
    const classes = useStyles();
    const {setActiveStepInvoiceAction} = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [objInvoice, setObjInvoice] = React.useState({
        invoice: null,
        startedit: false
    });
    const [star, setStar] = React.useState(false);
    const windowsHeight = parseInt((window.innerHeight) * 0.7, 10);
    const heightDiv = windowsHeight + 'px';
    const steps = getSteps();
    const { HandleBack, Empresaid, Userid, totalinvoice, objHead, isValidDocument } = props;
    const [loading, setLoading] = React.useState(false);
    const [formQuoSave, setFormQuoSave] = React.useState(null);
    const [modalResponse, setModalResponse] = React.useState({
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: 0
    });
    const [arrNumerations, setArrNumerations] = React.useState([]);
    const [footShow, setFootShow] = React.useState(false);
    const [openModalFile, setOpenModalFile] = React.useState(false);
    const [formPreview, setFormPreview] = React.useState(null);
    const history = useHistory()


    /**
    * Carga información de paso activo en redux
    */
    useEffect(() => {
        if (activeStep !== null && activeStep !== undefined) {
            console.log("Actualizando paso en COtización", activeStep);
            setActiveStepInvoiceAction(activeStep);
        }
    }, [activeStep])



    const handleStep = (step) => () => {

        let newCompleted;
        let i = 0;
        if (step === 0) {
            newCompleted = new Set();
        }
        else {
            //alert(step);
            newCompleted = new Set();
            for (i === 0; i < step; i++) {
                newCompleted.add(i);
            }
        }

        setCompleted(newCompleted);
        setActiveStep(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }
    const cancelCreate = () => {
        setActiveStep(0);
        setObjInvoice({
            invoice: null,
            startedit: false
        });
        HandleBack();
    }
    const nextStepForm = (step) => {
        setActiveStep(step);
    }

    //datos del paso 2
    const approveStepInfo = (obj, numerations) => {
        if (obj !== null) {
            setStar(obj.favorite);
        }
        let nums = numerations;

        nums.push({
            id: 0,
            resolucion: 0,
            fechainicio: null,
            fechafinal: null,
            prefijo: "",
            consecinicial: "",
            consecfinal: "",
            consecactual: "",
            diantipomodalidadid: 0,
            sucursalempresaid: obj.branchofficeid,
            nombre: "Seleccione resolución",
            descripcion: "Seleccione resolución                 "
        });
        setArrNumerations(nums);
        setObjInvoice({
            invoice: obj,
            startedit: false
        });
    }
    const handleFavourite = (action) => {
        setStar(action);
    }

    const sendQuotation = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;

        let objQuotation = objInvoice.invoice;

        if (objInvoice != null) {

            for (i === 0; i < objQuotation.clientInfo.adclientedireccion.length; i++) {
                if (objQuotation.clientInfo.adclientedireccion[i].id === objQuotation.addressid) {
                    address = {
                        nombmun: objQuotation.clientInfo.adclientedireccion[i].ciudaddescripcion,
                        nombpais: objQuotation.clientInfo.adclientedireccion[i].departamentodescripcion,
                        id: objQuotation.clientInfo.adclientedireccion[i].id,
                        direccion: objQuotation.clientInfo.adclientedireccion[i].direccion,
                        descripcion: objQuotation.clientInfo.adclientedireccion[i].descripcion,
                        Clienteid: objQuotation.clientInfo.adclientedireccion[i].clienteid,
                        Ciudadid: objQuotation.clientInfo.adclientedireccion[i].ciudadid,
                        Idstate: objQuotation.clientInfo.adclientedireccion[i].idstate,
                        Createdby: objQuotation.clientInfo.adclientedireccion[i].createdby,
                        Modifiedby: objQuotation.clientInfo.adclientedireccion[i].modifiedby,
                        Idbusinessunit: objQuotation.clientInfo.adclientedireccion[i].idowner,
                        Idowner: objQuotation.clientInfo.adclientedireccion[i].idowner,
                        Principal: objQuotation.clientInfo.adclientedireccion[i].principal
                    }
                }
            }

            if (address !== {}) {
                address = {
                    nombmun: objQuotation.dirprincipal[0].ciudaddescripcion,
                    nombpais: objQuotation.dirprincipal[0].departamentodescripcion,
                    id: objQuotation.dirprincipal[0].id,
                    direccion: objQuotation.dirprincipal[0].direccion,
                    descripcion: objQuotation.dirprincipal[0].descripcion,
                    Clienteid: objQuotation.dirprincipal[0].clienteid,
                    Ciudadid: objQuotation.dirprincipal[0].ciudadid,
                    Idstate: objQuotation.dirprincipal[0].idstate,
                    Createdby: objQuotation.dirprincipal[0].createdby,
                    Modifiedby: objQuotation.dirprincipal[0].modifiedby,
                    Idbusinessunit: objQuotation.dirprincipal[0].idowner,
                    Idowner: objQuotation.dirprincipal[0].idowner,
                    Principal: objQuotation.dirprincipal[0].principal
                }
            }
            for (j === 0; j < objQuotation.clientInfo.adcontactos.length; j++) {

                if (objQuotation.clientInfo.adcontactos[j].id === objQuotation.contactid) {

                    contact = {
                        id: objQuotation.clientInfo.adcontactos[j].id,
                        nombre: objQuotation.clientInfo.adcontactos[j].nombre,
                        telefono: objQuotation.clientInfo.adcontactos[j].telefono,
                        celular: objQuotation.clientInfo.adcontactos[j].celular,
                        email: objQuotation.clientInfo.adcontactos[j].email,
                        clienteid: objQuotation.clientInfo.adcontactos[j].clienteid,
                        idstate: objQuotation.clientInfo.adcontactos[j].idstate,
                        createdby: objQuotation.clientInfo.adcontactos[j].createdby,
                        modifiedby: objQuotation.clientInfo.adcontactos[j].modifiedby,
                        idbusinessunit: objQuotation.clientInfo.adcontactos[j].idbusinessunit,
                        idowner: objQuotation.clientInfo.adcontactos[j].idowner
                    }
                }
            }

            //console.log('contacto'+ JSON.stringify(contact))
            const setPrincipal = (confirmed) => {

                totalinvoice.detalleDocumento.forEach(element => {
                    sumDiscounts = sumDiscounts + element.valordescuento;
                    products.push({
                        Codigo: element.codigo,
                        Descripcion: element.descripcion,
                        Cantidad: element.cantidad,
                        Valorunitario: element.valorNeto,
                        Porcentajedescuento: element.porcentajedescuento,
                        Valordescuento: element.valordescuento,
                        Id: 0,
                        Dianunidadmedidaid: element.dianunidadmedidaid,
                        Articuloid: element.articuloid,
                        Totaliva: element.totaliva,
                        Totalinc: element.totalinc,
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        Subtotal: element.subtotal,
                        Tipodescuento: element.tipodescuento,
                        Total: element.total,
                        Totaldescuento: element.totaldescuento,
                        Valordescuentocomercial: element.valordescuentocomercial,
                        Prorrateo: element.prorrateo,
                        Totaldescuentoreal: element.totaldescuentoreal,
                        Subtotalreal: element.subtotalreal,
                        Totalivareal: element.totalivareal,
                        Totalincreal: element.totalincreal,
                        Totalreal: element.totalreal
                    })
                });

                totalinvoice.cargos.forEach(charge => {
                    charges.push({
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Cargoid: charge.id,
                        Descripcion: charge.descripcion,
                        Tipocargo: charge.tipocargo,
                        Valor: charge.valor,
                        Esglobal: false //no definido
                    })
                });

                totalinvoice.otrosdescuentos.forEach(discount => {
                    discounts.push({
                        Idstate: 1,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Descuentoid: discount.id,
                        descripcion: discount.descripcion,
                        motivoid: discount.motivoid,
                        modelodescuento: discount.modelodescuento,
                        valor: discount.valor,
                        tipodescuento: discount.tipodescuento
                    })
                });

                if (confirmed) {
                    setLoading(true);
                    setFormQuoSave({
                        Opdocumento: {
                            id: objHead.documentId,
                            fecha: objQuotation.dateInvoice,
                            numeracionid: 0,
                            estadodocumentoid: 0,
                            tipoaceptaciondocumentoid: 1,
                            tipoacusedocumentoid: 3,
                            valorapagar: totalinvoice.totalapagar,
                            dianformapagoid: objQuotation.visibleCredit === true ? 2 : 1,
                            valordescuento: sumDiscounts,
                            valorcargos: 0,
                            subtotal: totalinvoice.valorbruto,
                            valortotal: totalinvoice.totalfactura,
                            valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                            valorcargodocumento: totalinvoice.valorcargos,
                            valoranticipo: 0,
                            valorinc: totalinvoice.valorinc,
                            valoriva: totalinvoice.valoriva,
                            textoobservacion: objQuotation.observations,
                            vendedorid: objQuotation.vendorid,
                            diantipodocumentoelectronicoid: 4,
                            clienteid: objQuotation.clientInfo.id,
                            dianmediopagoid: objQuotation.paymethodid,
                            idstate: 0,
                            createdby: 1,
                            modifiedby: 1,
                            idbusinessunit: 1,
                            idowner: 1,
                            origendocumentoid: 1,
                            Dianconceptonotadebitoid: 0,
                            Dianconceptonotacreditoid: 0,
                            fechavigencia: objQuotation.datePay,
                            favorito: star,
                            empresaid: Empresaid,
                            Incluirvendedor: objQuotation.includeVendor,
                            Valordescuentoreal: totalinvoice.valordescuentoreal,
                            Valorivareal: totalinvoice.valorivareal,
                            Valorincreal: totalinvoice.valorincreal,
                            TotalfacturaReal: totalinvoice.totalfacturaReal,
                            Vendedor: {
                                Id: objQuotation.vendorid.id,
                                Nombres: objQuotation.Vendor.nombres,
                                Idstate: objQuotation.Vendor.idstate,
                                Createdby: objQuotation.Vendor.createdby,
                                Modifiedby: objQuotation.Vendor.modifiedby,
                                Idbusinessunit: objQuotation.Vendor.idbusinessunit,
                                Idowner: objQuotation.Vendor.idowner,
                                Empresaid: objQuotation.Vendor.empresaid,
                                Telefono: objQuotation.Vendor.telefono,
                                Email: objQuotation.Vendor.email
                            },
                            Cliente: {
                                Id: objQuotation.clientInfo.id,
                                Documento: objQuotation.clientInfo.documento,
                                Documentodv: objQuotation.clientInfo.documentodv,
                                Razonsocial: objQuotation.clientInfo.razonsocial,
                                Telefono: objQuotation.clientInfo.telefono,
                                Correoelectronico: objQuotation.clientInfo.correoelectronico,
                                Diantipodocumentoidentidadid: objQuotation.clientInfo.diantipodocumentoidentidadid,
                                Diantipopersonaid: objQuotation.clientInfo.diantipopersonaid,
                                Empresaid: objQuotation.clientInfo.Empresaid,
                                Favorito: objQuotation.clientInfo.Favorito,
                                Idstate: objQuotation.clientInfo.Idstate,
                                createdby: objQuotation.clientInfo.createdby,
                                modifiedby: objQuotation.clientInfo.modifiedby,
                                idbusinessunit: objQuotation.clientInfo.idbusinessunit,
                                idowner: objQuotation.clientInfo.idbusinessunit,
                                Clientedireccion: {
                                    Id: address.id,
                                    Descripcion: address.descripcion,
                                    Clienteid: address.clienteid,
                                    Ciudadid: address.ciudadid,
                                    Idstate: address.idstate,
                                    Createdby: address.createdby,
                                    Modifiedby: address.modifiedby,
                                    Idbusinessunit: address.idowner,
                                    Idowner: address.idowner,
                                    Direccion: address.direccion,
                                    Principal: address.principal
                                },
                                Contactos: {
                                    Id: contact.id,
                                    Nombre: contact.nombre,
                                    Telefono: contact.telefono,
                                    Celular: contact.celular,
                                    Email: contact.email,
                                    Clienteid: contact.clienteid,
                                    Idstate: contact.idstate,
                                    Createdby: contact.createdby,
                                    Modifiedby: contact.modifiedby,
                                    Idbusinessunit: contact.idbusinessunit,
                                    Idowner: contact.idowner
                                }
                            },
                            Numeracion: { //SE ENVIA DTO VACIO PARA ENVIAR LA SUCURSAL
                                Id: 0,
                                Resolucion: '',
                                Fechainicio: '1900-01-01',
                                Fechafinal: '1900-01-01',
                                Prefijo: '',
                                Consecinicial: '',
                                Consecfinal: '',
                                Consecactual: '',
                                Empresaid: 0,
                                Diantipomodalidadid: 0,
                                Sucursalempresaid: objQuotation.branchofficeid,
                                Idstate: 0,
                                Createdby: 0,
                                Modifiedby: 0,
                                Idbusinessunit: 0,
                                Idowner: 0,
                                Nombre: ''
                            }

                        },
                        LstOpdetalledocumento: products,
                        LstCargos: charges,
                        LstDescuentos: discounts
                    });
                }
            }

            if (totalinvoice.detalleDocumento.length === 0) {
                WarningAlert("Debe seleccionar al menos un producto");
            }
            else {

                if (totalinvoice.totalapagar < 0) {
                    WarningAlert("El valor a pagar de la cotización no puede ser negativo");
                }
                else {
                    let validacargos = false;
                    if (totalinvoice.valorcargos > 0) {
                        let valorbolsas = 0;
                        totalinvoice.cargos.forEach(charge => {
                            if (charge.descripcion === 'Bolsas') {
                                valorbolsas = valorbolsas + charge.valorcalculado
                            }

                        });

                        if ((totalinvoice.valorbruto - totalinvoice.valorOtrosDescuentos - totalinvoice.valordescuentoreal) < totalinvoice.valorcargos - valorbolsas) {
                            WarningAlert("El valor de los cargos no puede ser superior a la cotización con descuentos");
                        }
                        else {
                            validacargos = true;
                        }
                    } else {
                        validacargos = true;
                    }

                    if (validacargos) {
                        ConfirmAlert('Oh...', '¿Desea editar la cotización?', setPrincipal);
                    }
                }
            }

        }
    }

    const sendPreview = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;

        let objQuotation = objInvoice.invoice;

        if (objInvoice != null) {

            for (i === 0; i < objQuotation.clientInfo.adclientedireccion.length; i++) {
                if (objQuotation.clientInfo.adclientedireccion[i].id === objInvoice.addressid) {
                    address = {
                        nombmun: objQuotation.clientInfo.adclientedireccion[i].ciudaddescripcion,
                        nombpais: objQuotation.clientInfo.adclientedireccion[i].departamentodescripcion,
                        id: objQuotation.clientInfo.adclientedireccion[i].id,
                        direccion: objQuotation.clientInfo.adclientedireccion[i].direccion,
                        descripcion: objQuotation.clientInfo.adclientedireccion[i].descripcion,
                        Clienteid: objQuotation.clientInfo.adclientedireccion[i].clienteid,
                        Ciudadid: objQuotation.clientInfo.adclientedireccion[i].ciudadid,
                        Idstate: objQuotation.clientInfo.adclientedireccion[i].idstate,
                        Createdby: objQuotation.clientInfo.adclientedireccion[i].createdby,
                        Modifiedby: objQuotation.clientInfo.adclientedireccion[i].modifiedby,
                        Idbusinessunit: objQuotation.clientInfo.adclientedireccion[i].idowner,
                        Idowner: objQuotation.clientInfo.adclientedireccion[i].idowner,
                        Principal: objQuotation.clientInfo.adclientedireccion[i].principal
                    }
                }
            }

            if (address !== {}) {
                address = {
                    nombmun: objQuotation.dirprincipal[0].ciudaddescripcion,
                    nombpais: objQuotation.dirprincipal[0].departamentodescripcion,
                    id: objQuotation.dirprincipal[0].id,
                    direccion: objQuotation.dirprincipal[0].direccion,
                    descripcion: objQuotation.dirprincipal[0].descripcion,
                    Clienteid: objQuotation.dirprincipal[0].clienteid,
                    Ciudadid: objQuotation.dirprincipal[0].ciudadid,
                    Idstate: objQuotation.dirprincipal[0].idstate,
                    Createdby: objQuotation.dirprincipal[0].createdby,
                    Modifiedby: objQuotation.dirprincipal[0].modifiedby,
                    Idbusinessunit: objQuotation.dirprincipal[0].idowner,
                    Idowner: objQuotation.dirprincipal[0].idowner,
                    Principal: objQuotation.dirprincipal[0].principal
                }
            }
            for (j === 0; j < objQuotation.clientInfo.adcontactos.length; j++) {

                if (objQuotation.clientInfo.adcontactos[j].id === objQuotation.contactid) {

                    contact = {
                        id: objQuotation.clientInfo.adcontactos[j].id,
                        nombre: objQuotation.clientInfo.adcontactos[j].nombre,
                        telefono: objQuotation.clientInfo.adcontactos[j].telefono,
                        celular: objQuotation.clientInfo.adcontactos[j].celular,
                        email: objQuotation.clientInfo.adcontactos[j].email,
                        clienteid: objQuotation.clientInfo.adcontactos[j].clienteid,
                        idstate: objQuotation.clientInfo.adcontactos[j].idstate,
                        createdby: objQuotation.clientInfo.adcontactos[j].createdby,
                        modifiedby: objQuotation.clientInfo.adcontactos[j].modifiedby,
                        idbusinessunit: objQuotation.clientInfo.adcontactos[j].idbusinessunit,
                        idowner: objQuotation.clientInfo.adcontactos[j].idowner
                    }
                }
            }


            totalinvoice.detalleDocumento.forEach(element => {
                sumDiscounts = sumDiscounts + element.valordescuento;
                products.push({
                    Codigo: element.codigo,
                    Descripcion: element.descripcion,
                    Cantidad: element.cantidad,
                    Valorunitario: element.valorNeto,
                    Porcentajedescuento: element.porcentajedescuento,
                    Valordescuento: element.valordescuento,
                    Id: 0,
                    Dianunidadmedidaid: element.dianunidadmedidaid,
                    Articuloid: element.articuloid,
                    Totaliva: element.totaliva,
                    Totalinc: element.totalinc,
                    Idstate: 0,
                    Createdby: 1,
                    Createdon: '1900-01-01',
                    Modifiedby: 1,
                    Modifiedon: '1900-01-01',
                    Idbusinessunit: 1,
                    Idowner: 1,
                    Subtotal: element.subtotal,
                    Tipodescuento: element.tipodescuento,
                    Total: element.total,
                    Totaldescuento: element.totaldescuento,
                    Valordescuentocomercial: element.valordescuentocomercial,
                    Prorrateo: element.prorrateo,
                    Totaldescuentoreal: element.totaldescuentoreal,
                    Subtotalreal: element.subtotalreal,
                    Totalivareal: element.totalivareal,
                    Totalincreal: element.totalincreal,
                    Totalreal: element.totalreal
                })
            });

            totalinvoice.cargos.forEach(charge => {
                charges.push({
                    Idstate: 0,
                    Createdby: 1,
                    Createdon: '1900-01-01',
                    Modifiedby: 1,
                    Modifiedon: '1900-01-01',
                    Idbusinessunit: 1,
                    Idowner: 1,
                    documentoid: 0,
                    Cargoid: charge.id,
                    Descripcion: charge.descripcion,
                    Tipocargo: charge.tipocargo,
                    Valor: charge.valor,
                    Esglobal: false, //no definido
                    Cantidad: charge.cantidad,
                    Valorcalculado: charge.valorcalculado,
                    Valorasumar: charge.valorasumar,
                    Identificador: charge.identificador
                })
            });

            totalinvoice.otrosdescuentos.forEach(discount => {
                discounts.push({
                    Idstate: 1,
                    Createdby: 1,
                    Createdon: '1900-01-01',
                    Modifiedby: 1,
                    Modifiedon: '1900-01-01',
                    Idbusinessunit: 1,
                    Idowner: 1,
                    documentoid: 0,
                    Descuentoid: discount.id,
                    descripcion: discount.descripcion,
                    motivoid: discount.motivoid,
                    modelodescuento: discount.modelodescuento,
                    valor: discount.valor,
                    tipodescuento: discount.tipodescuento,
                    valoradescontar: discount.valoradescontar,
                    motivodescuento: discount.motivoDescripcion,
                    identificador: discount.identificador
                })
            });


            setFormPreview({
                Opdocumento: {
                    fecha: objQuotation.dateInvoice,
                    numeracionid: 0,
                    estadodocumentoid: 0,
                    tipoaceptaciondocumentoid: 1,
                    tipoacusedocumentoid: 3,
                    valorapagar: totalinvoice.totalapagar,
                    dianformapagoid: objQuotation.visibleCredit === true ? 2 : 1,
                    valordescuento: sumDiscounts,
                    valorcargos: 0,
                    subtotal: totalinvoice.valorbruto,
                    valortotal: totalinvoice.totalfactura,
                    valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                    valorcargodocumento: totalinvoice.valorcargos,
                    valoranticipo: 0,
                    valorinc: totalinvoice.valorinc,
                    valoriva: totalinvoice.valoriva,
                    textoobservacion: objQuotation.observations,
                    vendedorid: objQuotation.vendorid,
                    diantipodocumentoelectronicoid: 4,
                    clienteid: objQuotation.clientInfo.id,
                    dianmediopagoid: objQuotation.paymethodid,
                    idstate: 0,
                    createdby: 1,
                    modifiedby: 1,
                    idbusinessunit: 1,
                    idowner: 1,
                    origendocumentoid: 1,
                    Dianconceptonotadebitoid: 0,
                    Dianconceptonotacreditoid: 0,
                    fechapago: objQuotation.visibleCredit === true ? objQuotation.datePay : null,
                    favorito: star,
                    empresaid: Empresaid,
                    Incluirvendedor: objQuotation.includeVendor,
                    Valordescuentoreal: totalinvoice.valordescuentoreal,
                    Valorivareal: totalinvoice.valorivareal,
                    Valorincreal: totalinvoice.valorincreal,
                    TotalfacturaReal: totalinvoice.totalfacturaReal,
                    Archivovisualizar: 'QUOTATION',
                    Vendedor: {
                        Id: objQuotation.vendorid.id,
                        Nombres: objQuotation.Vendor.nombres,
                        Idstate: objQuotation.Vendor.idstate,
                        Createdby: objQuotation.Vendor.createdby,
                        Modifiedby: objQuotation.Vendor.modifiedby,
                        Idbusinessunit: objQuotation.Vendor.idbusinessunit,
                        Idowner: objQuotation.Vendor.idowner,
                        Empresaid: objQuotation.Vendor.empresaid,
                        Telefono: objQuotation.Vendor.telefono,
                        Email: objQuotation.Vendor.email
                    },
                    Cliente: {
                        Id: objQuotation.clientInfo.id,
                        Documento: objQuotation.clientInfo.documento,
                        Documentodv: objQuotation.clientInfo.documentodv,
                        Razonsocial: objQuotation.clientInfo.razonsocial,
                        Telefono: objQuotation.clientInfo.telefono,
                        Correoelectronico: objQuotation.clientInfo.correoelectronico,
                        Diantipodocumentoidentidadid: objQuotation.clientInfo.diantipodocumentoidentidadid,
                        Diantipopersonaid: objQuotation.clientInfo.diantipopersonaid,
                        Empresaid: objQuotation.clientInfo.Empresaid,
                        Favorito: objQuotation.clientInfo.Favorito,
                        Idstate: objQuotation.clientInfo.Idstate,
                        createdby: objQuotation.clientInfo.createdby,
                        modifiedby: objQuotation.clientInfo.modifiedby,
                        idbusinessunit: objQuotation.clientInfo.idbusinessunit,
                        idowner: objQuotation.clientInfo.idbusinessunit,
                        Clientedireccion: {
                            Id: address.id,
                            Descripcion: address.descripcion,
                            Clienteid: address.clienteid,
                            Ciudadid: address.ciudadid,
                            Idstate: address.idstate,
                            Createdby: address.createdby,
                            Modifiedby: address.modifiedby,
                            Idbusinessunit: address.idowner,
                            Idowner: address.idowner,
                            Direccion: address.direccion,
                            Principal: address.principal
                        },
                        Contactos: {
                            Id: contact.id,
                            Nombre: contact.nombre,
                            Telefono: contact.telefono,
                            Celular: contact.celular,
                            Email: contact.email,
                            Clienteid: contact.clienteid,
                            Idstate: contact.idstate,
                            Createdby: contact.createdby,
                            Modifiedby: contact.modifiedby,
                            Idbusinessunit: contact.idbusinessunit,
                            Idowner: contact.idowner
                        }
                    },
                    Numeracion: {
                        Id: 0,
                        Resolucion: '',
                        Fechainicio: '1900-01-01',
                        Fechafinal: '1900-01-01',
                        Prefijo: '',
                        Consecinicial: '',
                        Consecfinal: '',
                        Consecactual: '',
                        Empresaid: 0,
                        Diantipomodalidadid: 0,
                        Sucursalempresaid: objQuotation.branchofficeid,
                        Idstate: 0,
                        Createdby: 0,
                        Modifiedby: 0,
                        Idbusinessunit: 0,
                        Idowner: 0,
                        Nombre: ''
                    }
                },
                LstOpdetalledocumento: products,
                LstCargos: charges,
                LstDescuentos: discounts
            });
            setOpenModalFile(true);
        }
    }

    const confirmSendQuoOk = (response) => {

        setLoading(false);
        setModalResponse({
            type: 'success',
            title: 'Perfecto!!!',
            subtitle: 'Has modificado una cotización',
            body: (<div><div>Perfecto, tenemos un cliente interesado, hay que convencerlo.</div>
                <div class="fenomenalWhiteLittle">Se editado exitosamente la cotización <span className="fenomenalBlueLittle">No. {response.data.result}</span></div></div>),
            modalImage: 'quotation',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 2
        });

    }

    const confirmErrorQuo = (response) => {

        setLoading(false);
        setModalResponse({
            type: 'warning',
            title: 'Oops!!!',
            subtitle: 'Ocurrió algo inesperado',
            body: (<div><div>
                {JSON.stringify(response === undefined ? 'Error inesperado' : response.data.statusMessage)}
            </div></div>),
            modalImage: 'warning',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 0
        });
    }

    const handleResponseModal = (rta) => {
        if (rta === 2) {
            localStorage.removeItem('itemsInCart');
            props.setStatusShowCartAction(false);
            props.clearLoadedChargeInvoiceActionAll();
            props.clearLoadedDiscountInvoiceActionAll();
            history.push({
                pathname: '/document',
                state: {
                    redirect: 2
                }
            });
        }
        setModalResponse({
            type: '',
            title: '',
            subtitle: '',
            body: '',
            modalImage: '',
            open: false,
            closeElement: '',
            onCloseElement: ''
        });

    }

    const handleItemState = (state) => {
        //console.log('Estado-->'+state);
        setFootShow(state);
    }

    const handleCloseModalFile = () => {
        setOpenModalFile(false);
    };

    const footPage = (step) => {
        return (
            <div className='bottomButtonsInvoiceRevision'>
                <Grid container >
                    <Grid item lg={2} md={2} sm={2}>
                        <div className="backInvoice" onClick={handleStep(step)}><ArrowBackIosIcon></ArrowBackIosIcon>Volver</div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1}>

                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div >
                            <div style={{ display: star === true ? 'block' : 'none' }}>
                                <StarIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(false)} ></StarIcon>
                            </div>
                            <div style={{ display: star === false ? 'block' : 'none' }}>
                                <StarBorderIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(true)} ></StarBorderIcon>
                            </div>
                            <div className="textButtonsInvoice">Destacar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div title="Previsualizar PDF">
                            <VisibilityIcon className="buttonInvoiceBehavior" onClick={() => sendPreview()}></VisibilityIcon><br></br>
                            <div className="textButtonsInvoice">Previsualizar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <ClearIcon className="buttonInvoiceBehavior" onClick={() => handleResponseModal(2)}></ClearIcon><br></br>
                        <div className="textButtonsInvoice">Cancelar</div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    useEffect(() => {
        if (objInvoice != null) {
            if (objInvoice.startedit === true) {
                //setActiveStep(0);
            }
            else {
                console.log("Activando paso 1...");
                let newCompleted = new Set(completed);
                newCompleted.add(0);
                setCompleted(newCompleted);
                //setActiveStep(1);
            }

            /*let newCompleted = new Set(completed);
            newCompleted.add(0);
            setCompleted(newCompleted);
            setActiveStep(1);*/
        }

    }, [objInvoice]);


    useEffect(() => {
        if (formQuoSave !== null) {
            //console.log('quotation-->'+JSON.stringify(formQuoSave))
            props.editQoutation(formQuoSave, confirmSendQuoOk, confirmErrorQuo);

        }
    }, [formQuoSave]);

    useEffect(() => {
        //console.log('objeto invoice init = '+ JSON.stringify(objHead))
        if (objHead !== null) {
            setObjInvoice({
                invoice: objHead,
                startedit: true
            })
        }
    }, [objHead]);

    useEffect(() => {
        if (loading) {
            setModalResponse({
                type: 'success',
                title: '',
                subtitle: 'Generando documento, por favor espere...',
                body: (<div><div>
                    <Fragment>  <CustomProgress />{'Procesando'} </Fragment>
                </div></div>),
                modalImage: 'noimagen',
                open: true,
                closeElement: '',
                onCloseElement: 0
            });


        }
    }, [loading]);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (<QuotationForm CancelCreate={handleResponseModal} NextStep={nextStepForm} ApproveStepInfo={approveStepInfo} Empresaid={Empresaid} Userid={Userid} objHead={objInvoice.invoice}></QuotationForm>);
            case 1:
                return (<div className="panelColorInvoice">
                    <InvoiceItem HandleItemState={handleItemState} activeStep={step}></InvoiceItem>
                    <div>
                        <div className={footShow ? '' : 'panelInvisibleInvoice'}>
                            <Grid container>
                                <Grid item lg={2} md={2} sm={12} xs={12}>
                                </Grid>
                                <Grid item lg={3} xs={12}>
                                    <button
                                        className="buttonInvoiceConfirm"
                                        type="button"
                                        onClick={() => sendQuotation()}
                                        disabled={isValidDocument === true ? false : true}>
                                        Guardar Cotización
                                    </button>
                                </Grid>
                                <Grid item lg={2} md={2} sm={12} xs={12}>
                                </Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <button
                                        className="buttonInvoiceConfirm"
                                        type="button"
                                        onClick={handleStep(2)}
                                        disabled={isValidDocument === true ? false : true}>
                                        Revisar Cotización
                                </button>
                                </Grid>
                                <Grid item lg={2} md={2} sm={12} xs={12}>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="spacingInvoice"></div>
                    {footPage(0)}
                </div>);
            case 2:
                return (<div className="panelColorInvoice">
                    <QuotationRevision ObjId={0}
                        heightDiv={heightDiv}
                        objHead={objInvoice.invoice}
                        objItems={totalinvoice}
                        CancelCreate={cancelCreate}
                        Empresaid={Empresaid}
                        Userid={Userid}>
                    </QuotationRevision>
                    <div className="spacingInvoice"></div>
                    <Grid container>
                        <Grid item lg={4} md={4} sm={12} xs={12}>

                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <button
                                className="buttonInvoiceConfirm"
                                type="button"
                                onClick={() => sendQuotation()}
                                disabled={isValidDocument === true ? false : true}>
                                Guardar Cotización
                                </button>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>

                        </Grid>
                    </Grid>
                    <div className="spacingInvoice"></div>
                    {footPage(1)}
                </div>);
            default:
                return 'Unknown step';
        }
    }
    return (
        <div className="container-form" xs={12} lg={12}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={< QontoConnector />} className={classes.root} >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    return (
                        <Step key={label} {...stepProps} className={activeStep === index ? classes.activeButton : ''}>
                            <StepButton
                                //onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                {...buttonProps}
                            >
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    <label className="stepper-title">{label}</label>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                <div>{getStepContent(activeStep)}</div>
            </div>
            <ResponseModal
                modalType={modalResponse.modalType}
                title={modalResponse.title}
                subtitle={modalResponse.subtitle}
                body={modalResponse.body}
                modalImage={modalResponse.modalImage}
                open={modalResponse.open}
                closeElement={modalResponse.closeElement}
                onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
            ></ResponseModal>

            <FileModalData
                open={openModalFile}
                onCloseElement={handleCloseModalFile}
                closeElement={"Cerrar"}
                paramFile={formPreview}
            >
            </FileModalData>
        </div>
    );
}

QuotationEdit.propTypes = {
    invoiceReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    invoiceReducer: state.invoiceReducer,
    sucessupdate: state.invoiceReducer.sucess,
    create: state.invoiceReducer.create,
    createquotation: state.invoiceReducer.create,
    totalinvoice: state.valuesInvoiceReducer.totalDetail,
    isValidDocument: state.valuesInvoiceReducer.isValidDocument
});

export default connect(mapStateToProps, {
    updateDocument,
    createElectronicInvoice,
    getTotalAction,
    editQoutation,
    setStatusShowCartAction,
    clearLoadedChargeInvoiceActionAll,
    clearLoadedDiscountInvoiceActionAll,
    setActiveStepInvoiceAction
})(QuotationEdit);


