import axios from 'axios';

import { API_ENDPOINT_ARTICLE, DEFAULTRESPONSE, defaultHeaders } from "../config/config";
import {
  SAVE_DISCOUNT,
  UPDATE_DISCOUNT,
  DELETE_DISCOUNT,
  GET_DISCOUNTLIST,
  GET_DISCOUNT,
  GET_REASON_DISCOUNT,
  GET_FILTER_DISCOUNT,
  LOADING_SAVE_DISCOUNT,
  CHANGE_EDITING_DISCOUNT_STATUS,
  GET_ERRORS,
  LOADING_GET_DISCOUNTS,
} from "./types";

import { handleResponse, authHeader, enterpriseIdHeader } from './authActions';

const urlEndPoint = `${API_ENDPOINT_ARTICLE}/item/api/descuento`;
const urlEndPointReason = `${API_ENDPOINT_ARTICLE}/item/api/motivodescuento`;
let allDiscounts = [];


/**
 * @action
 * @description Consulta listado descuentos por empresa
 * @param {*} data 
 * @param {*} filter 
 * @param {*} refresh 
 */
export const getDiscounts = (filter = '', refresh = true) => async (dispatch) => {

  try {

    if (allDiscounts.length > 0 && !refresh) {
      dispatch({
        type: GET_DISCOUNTLIST,
        payload: filerList(allDiscounts, filter),
      });

    } else {
      changeGetListLoading(dispatch, true);
      const url = `${urlEndPoint}/getallbyenterpriseid`;
      let config = defaultHeaders();
      Object.assign(config.headers, {
        'enterpriseId': `${enterpriseIdHeader()}`,
      });


      var response = await axios.get(url, config);
      allDiscounts = response.data.result.map(item => {
        return {
          ...item,
          text: item.descripcion
        }
      });

      dispatch({
        type: GET_DISCOUNTLIST,
        payload: (filter !== '') ? filerList(allDiscounts, filter) : allDiscounts
      });
    }

  } catch (err) {
    console.log("Error consultando listado descuentos.", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar listado de descuentos.'
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * @function
 * @description Actualiza estado de loading gSAVE
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: LOADING_GET_DISCOUNTS,
    payload: status
  })
}


export const getDiscount = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/getdatabyid`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });

    var response = await axios.get(url, config);

    dispatch({
      type: GET_DISCOUNT,
      payload: response.data,
    });

  } catch (err) {
    console.log("Error consultando información descuento.", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar información descuento.'
    });

  }
};

export const getFilterDiscount = (data) => async (dispatch) => {
  dispatch({
    type: GET_FILTER_DISCOUNT,
    payload: data,
  });
};

export const changeEditingDiscountStatus = (data) => async (dispatch) => {
  dispatch({
    type: CHANGE_EDITING_DISCOUNT_STATUS,
    payload: data,
  });
};

/**
 * @action
 * @description Registra descuento
 * @param {*} data 
 */
export const postDiscount = (data) => async (dispatch) => {
  
  try {
    changeSaveLoading(dispatch, true);

    data = {
      ...data,
      empresaid: `${enterpriseIdHeader()}`,
    }

    const url = `${urlEndPoint}/creatediscount`;
    var response = await axios.post(url, data, defaultHeaders());

    dispatch({
      type: SAVE_DISCOUNT,
      payload: response.data,
    });


  } catch (err) {
    console.log("Error  registrando descuento.", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido registrar descuento.'
    });

  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * @action
 * @description Actualiza descuento
 * @param {*} data 
 */
export const putDiscount = (data) => async (dispatch) => {


  try {

    changeSaveLoading(dispatch, true);

    const url = `${urlEndPoint}/updatediscount`;
    const response = await axios.put(url, data, defaultHeaders());

    dispatch({
      type: UPDATE_DISCOUNT,
      payload: response.data,
    });


  } catch (err) {
    handleResponse(err.response);
    console.log("Error actualizando descuento", err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response
    });

  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * @function
 * @description Actualiza estado proceso guardado
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_DISCOUNT,
    payload: status
  })
}


export const deleteDiscount = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/deletediscount`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });

    var response = await axios.delete(url, config);
    dispatch({
      type: DELETE_DISCOUNT,
      payload: response.data,
    });

  } catch (err) {
    console.log("Error eliminando información descuento.", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido eliminando información descuento.'
    });

  }
};

export const getReasonDiscount = () => async (dispatch) => {

  try {

    const url = `${urlEndPointReason}/getallreason`;

    var response = await axios.get(url, defaultHeaders());

    dispatch({
      type: GET_REASON_DISCOUNT,
      payload: response.data
    });


  } catch (err) {
    console.log("Error consultando listado razones de descuento.", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar listado razones de descuento.'
    });

  }
};

export const clearCurrentDiscount = () => async dispatch => {
  dispatch({
    type: GET_FILTER_DISCOUNT,
    payload: null
  })
}

export const clearUpdateDiscount = () => async dispatch => {
  dispatch({
    type: UPDATE_DISCOUNT,
    payload: null
  })
}

export const clearCreateDiscount = () => async dispatch => {
  dispatch({
    type: SAVE_DISCOUNT,
    payload: null
  })
}

export const clearDeleteDiscount = () => async dispatch => {
  dispatch({
    type: DELETE_DISCOUNT,
    payload: null
  })
}

//Filtra listado descuentos
function filerList(list, filter) {
  try {
    if (!list) return list;

    const data = list.filter(x => {
      const modelo = (x.modelodescuento === 1 ? 'descuento comercial' : 'descuento financiero');
      const valor = x.valor;
      const motivo = x.motivo.descripcion.toLowerCase();
      const descripcion = x.descripcion.toLowerCase();
      const query = `${modelo} ${valor} ${motivo} ${descripcion}`;
      const modValue = filter.toLowerCase();
      return query.indexOf(modValue) > -1;
    });

    return data;
  } catch (err) {
    console.log("Error Filtrando listado cargos:", err);
  }
}
