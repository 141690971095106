import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';


import { ConfirmAlert, ErrorAlert } from '../../../helpers/alert.helpers'
import InvoiceItemManage from './invoiceItemManage.component'
import { deleteItemToCartAction, getTotalAction } from '../../../actions/valuesInvoiceAction'


const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 1000,
        backgroundColor: theme.palette.thirdBackgroundColor,
        borderRadius: '5px',
        color: theme.palette.primaryColor,
        border: 'none',
    },
    noresult: {
        padding: 10
    }
});


/**
 * @Component 
 * @description Componente para Renderizado de Items cargados en Carrito
 * @param {itemsInCart} Arreglo con listado de items agregados a carrito
 */
class ItemInvoiceList extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {}



    onDeleteItem(e, item) {
        e.preventDefault();
        const deleteData = (confirmed) => {
            if (confirmed) {
                this.props.deleteItemToCartAction(item);
            }
        }

        ConfirmAlert('Eliminar artículo', `¿Estas seguro de eliminar ${item.descripcion}?`, deleteData)
    }

    render() {
        const { classes } = this.props;
        const { detalleDocumento } = this.props.totalDetail;

        return (
            <div className={classes.root}>
                {this.props.documentDetail.length > 0 ?
                    <Fragment>
                        {this.props.documentDetail.map((item, index) =>
                            <InvoiceItemManage
                                data={item}
                                newData = {detalleDocumento.find(d=> d.identificador === item.identificador)}
                                onDelete={(e) => this.onDeleteItem(e, item)}
                            />
                        )}
                    </Fragment> :
                    <div className={classes.noresult}  >{'No se han cargado articulos a lista.'}</div>
                }
            </div>
        );
    }
}


ItemInvoiceList.propTypes = {
    deleteItemToCartAction: PropTypes.func.isRequired,
    getTotalAction: PropTypes.func.isRequired,

    valuesInvoiceReducer: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
    valuesInvoiceReducer: state.valuesInvoiceReducer
})

export default connect(mapStateToProps, {
    deleteItemToCartAction,
    getTotalAction
})(withStyles(styles)(ItemInvoiceList));