/* eslint-disable no-control-regex */
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import aesjs from 'aes-js';
import moment from 'moment';
import 'moment/locale/es';
import { URL_SECURITY, API_ENDPOINT_ENTERPRISE, ROL_CFCOLAB, API_ENDPOINT_IBUHOO } from "../config/config";
import {
  SET_CURRENT_USER,
  GET_COMPANY,
  GET_SELLERS,
  GET_BILLERS,
  GET_NUMERATIONS,
  GET_BRANCH_OFFICES,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { encryptionKey, encryptionIv } from "./../config/configKeys";
import { ConfirmAlert } from "../helpers/alert.helpers";
import { fillListOffices } from "./branchOfficeActions";
import { fillListSellers, fillListBillers } from "./userAction";
import { fillListNumerations } from "./numerationActions";
import { TIME_REFRESH_TOKEN } from "./../config/config";

const urlEnterpriseEndPoint = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;
const urlAuthentication = `${API_ENDPOINT_IBUHOO}/authentication`;

/**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @method POST
 * @param {*} userData
 */
async function loginUser(token) {
  try {
    const url = `${urlEnterpriseEndPoint}/TokenValidate`;
    const config = {
      headers: {
       'Authorization': `${authHeader(token)}`,
      },
    };
    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    redirectToLogin();
  }
}
/**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @method GET
 * @param {history} history
 */
export const logoutUserAction = () => (async) => {
  const logoutData = (confirmed) => {
    if (confirmed) {
      redirectToLogin();
    }
  };
  ConfirmAlert(
    "Cierre de sesión",
    "¿Está seguro de cerrar sesión?",
    logoutData
  );
};

/**
* Validar token
*
* @method 
* @param {queryString} queryString
*/
export const validateTokenAction = (queryString, history) => async (dispatch) => {
    try {
        // Lanza loading
        setAuthToken(false);
        const decodedToken = await decodeTokenData(queryString);
        const data = await loginUser(decodedToken);
        setAuthToken(decodedToken);
        return await DispatchData(data, dispatch);
    } catch (err) {
        console.log(err);
        redirectToLogin();
    } finally {
        // detener loading
    }
};

/**
 * Dispatch datos
 *
 * @method
 * @param {data} data
 */
async function DispatchData(data, dispatch) {
  try {
    const response = {
      //data: data.result.enterprise,
      companyId: data.result.enterprise.id,
      companyName: data.result.enterprise.razonsocial,
      userName: data.result.userLogged.nombres,
      userId: data.result.userLogged.iduser,
      userEmail: data.result.userLogged.email,
    };

    localStorage.setItem("dataCompany", JSON.stringify(response));

    dispatch({
      type: GET_COMPANY,
      payload: data.result.enterprise,
    });

    dispatch({
      type: GET_BRANCH_OFFICES,
      payload: data.result.enterprise.sucursales,
    });
    fillListOffices(data.result.enterprise.sucursales);

    dispatch({
      type: GET_SELLERS,
      payload: data.result.enterprise.advendedor,
    });
    fillListSellers(data.result.enterprise.advendedor);

    dispatch({
      type: GET_BILLERS,
      payload: data.result.enterprise.adusuarios,
    });
    fillListBillers(data.result.enterprise.adusuarios);

    dispatch({
      type: GET_NUMERATIONS,
      payload: data.result.enterprise.numeraciones,
    });
    fillListNumerations(data.result.enterprise.numeraciones);

    return true;
  } 
  catch (err) {
    console.log(err);
    redirectToLogin();
  }
}

/**
 * decodificar token dentro de un querystring
 *
 * @method
 * @param {queryString} queryString
 */
async function decodeTokenData(queryString) {
    try {
        const paramsChar = decodeURIComponent(queryString);
        const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
        const encryptedtext = aesjs.utils.hex.toBytes(
            new Buffer(paramsChar || "", "base64").toString("hex")
        );
        const decryptedBytes = aesCbc.decrypt(encryptedtext);
        const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
        const cleanParamsObj = clearQueryVariable(decryptedText);
        const paramsObj = JSON.parse(cleanParamsObj);
        if (paramsObj.token !== undefined && paramsObj.token !== null) {
            return paramsObj.token;
        }
        else {
            redirectToLogin();
        }
    } catch (err) {
        console.log(err);
        redirectToLogin();
    }
}

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    paramsChar = paramsChar
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsChar = paramsChar.replace(/[\u0000-\u0019]+/g, "");
    return paramsChar;
  } catch (err) {
    console.log("Error limpiando parametros:", err);
  }
}

/**
 * validar vigencia del token
 *
 * @method
 * @param {querytokenString} token
 */
export const validateTokenExpirationDate = async () => {
  try {
    const jwtToken = localStorage.getItem("jwtToken");
    const decodedToken = jwt_decode(jwtToken);
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedToken.exp).utc();

    if ((moment(dateExpiresToken).isAfter(currentDate)) === false) {
      redirectToLogin()
    } else if ((moment(dateExpiresToken).diff(currentDate, 'seconds')) < TIME_REFRESH_TOKEN) {
      //Refresh Token    
      let userName = decodedToken.unique_name;
      let refreshData = {
          Token: jwtToken,
          UserName: userName,
          Appname: "colfactura",
      };

      try {
        await axios.post(urlAuthentication + "/api/Login/RefreshToken", refreshData).then((response) => {
            setAuthToken(response.data.result.token)    
        });
      } catch (err) {
        redirectToLogin();
        console.log("Error refrescando Token:", err);
      }
        }
      } catch (err) {
        redirectToLogin();
        console.log("Error al validar la fecha de vencimiento del token:", err);
      }
};

/**
 * Redireccionar a login
 *
 * @method
 * @param
 */
function redirectToLogin() {
  setAuthToken(false);
  window.location.href = URL_SECURITY;
}

/**
 * Procesar respuesta 401 o 403
 *
 * @method
 * @param {response} response
 */
export function handleResponse(response) {
  if (!!response) {
    if (response.status !== null && response.status !== undefined) {
      if ([401, 403].indexOf(response.status) !== -1) {
        redirectToLogin();
      }
    }
  }
}

/**
 * Obtener token en el header
 *
 * @method
 * @param {token} token
 */
export function authHeader(token) {
  const currentToken =
    token === null || token === undefined
      ? localStorage.getItem("jwtToken")
      : token;
  if (currentToken !== null || currentToken !== undefined) {
    return `Bearer ${currentToken}`;
  } else {
    return {};
  }
}

/**
 * Obtener id empresa en el header
 *
 * @method
 * @param {}
 */
export function enterpriseIdHeader() {
  const company = JSON.parse(localStorage.getItem("dataCompany"));
  const enterpriseId =
    company !== null || company !== undefined ? company.companyId : null;
  if (enterpriseId !== null || enterpriseId !== undefined) {
    return `${enterpriseId}`;
  } else {
    return {};
  }
}

/**
 * Obtener tipo de documento de la empresa.
 *
 * @method
 * @param {}
 */
export function enterpriseDocumentType() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.customer_type}`;
  } else {
    return {};
  }
}
/**
 * Obtener numero de documento de la empresa
 *
 * @method
 * @param {}
 */
export function enterpriseDocumentNumber() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.customer_value}`;
  } else {
    return {};
  }
}

/**
* Obtener rol del usuario logueado
*
* @method 
* @param {} 
*/
export function getRoleOfLoggedUser() {
  const decodedToken = jwt_decode(localStorage.getItem('jwtToken'));
  if (!!decodedToken.COLFACTURA_ROLE && decodedToken.COLFACTURA_ROLE !== '') {
    return decodedToken.COLFACTURA_ROLE === ROL_CFCOLAB;
  }
  else {
    return true;
  }
}


/**
 * Obtener nombre de usuario que logueado
 *
 * @method
 * @param {}
 */
export function fullNameUser() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    let fullName = decodedToken.given_name.concat(" " + decodedToken.family_name);
    return `${fullName}`;
  } else {
    return {};
  }
}

/**
 * Obtener el correo electronico de usuario logueado
 *
 * @method
 * @param {}
 */
export function emailUser() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.email}`;
  } else {
    return {};
  }
}
