import React, {Component} from "react"
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/styles';
import {Grid} from "@material-ui/core"

import {getComponentName} from "../../utils/general.js";
import {getMenuComponentAction, cleanMenuComponentAction} from '../../actions/configAction'

const styles = theme => ({
  descriptionSpan: {
    height: '81px',
    width: '480px',
    color: '#7DD1F8',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontStyle: 'italic',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '23px',
    textAlign: 'center'
  },
  descriptionName: {
    height: '26px',
    width: '372px',
    color: '#848181',
    fontFamily: 'Muli',
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '26px'
  },
  tittle: {
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '30px',
    textAlign: 'center',
    margin: 'auto'
  },
  textPolicy: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,255,0.11)',
  },
  divText: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'justify',
    marginLeft: 50,
    marginRight: 50
  },
  mainGrid: {
    margin: 'auto'
  }
})

export class PrivacyPolicy extends Component {

  constructor() {
    super();
    this.state = {
      policy: '',
      components: []
    }
  }

  componentDidMount() {
    this.props.getMenuComponentAction(9);

  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (!!nextProps.configReducer.policy && nextProps.configReducer.policy !== state.policy)
      update.policy = nextProps.configReducer.policy;

    if (!!nextProps.configReducer.components && nextProps.configReducer.components !== state.components)
      update.components = nextProps.configReducer.components

    return Object.keys(update).length ? update : null;
  }

  render() {
    const classes = this.props.classes;
    return (
      <Grid container spacing={2}>
        <Grid item md={8} className={classes.mainGrid}>
          <div className={classes.textPolicy}>
            <br/>
            <div className={classes.tittle}>
              {
                getComponentName(
                  this.state.components,
                  90,
                  144,
                  'Política de privacidad'
                )}
            </div>
            <br/>
            <div className={classes.divText} dangerouslySetInnerHTML={{__html: this.state.policy}}>
            </div>
            <br/>
          </div>
        </Grid>
      </Grid>
    )
  }
}

PrivacyPolicy.propTypes = {}

const mapStateToProps = state => ({
  configReducer: state.configReducer
})

export default connect(mapStateToProps,
  {
    getMenuComponentAction,
    cleanMenuComponentAction
  })(withStyles(styles)(PrivacyPolicy))

