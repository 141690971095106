import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import './autocomplete.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: 15,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));


const AutoCompleteSelect = withStyles({
  root: {
    '& .MuiAutocomplete-clearIndicator': {
      color: '#FFFFFF',
    },
    '& .MuiAutocomplete-input': {
      color: '#FAFAFA',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFFF',
    },

    '& .MuiAutocomplete-groupUl': {
      backgroundColor: 'blue'
    },
    '& .MuiAutocomplete-popper': {
      color: 'red',
      backgroundColor: 'red'
    }
  },

})(Autocomplete);

const CustomSelect = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.thirdColor, //azul
    },
    '& label.Mui-disabled': {
      color: '#FFFF', //gris
    },
    '& label.MuiFormLabel-filled:not(.Mui-focused)': {
      color: 'gray', //gris
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.thirdColor, //azul
    },
    '& .MuiInputBase-input': {
      color: '#FFFF'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid',
        borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.thirdColor, //azul
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray', //gris
      },
      '& svg': {
        color: theme.palette.fifthBackgroundColor
      }
    }
  },
}))(TextField);


const CustomAutoComplete = ({ ...props }) => {
  const classes = useStyles();
  const { options, label, value, onChange, id, textNoOption, validator, validateOptions, nameValidator } = props;

  let errorValidator = validator ? validator.message(!!nameValidator ? nameValidator : label, value, validateOptions) : '';
  return (
    <div >
      <AutoCompleteSelect
        id={id}
        className={classes.margin}
        noOptionsText={textNoOption}
        options={options}
        value={value}
        getOptionSelected={(options) => !!options?.value ? options.value.toString() : ""}
        getOptionLabel={(options) => !!options?.text ? options.text : ""}
        onChange={onChange}
        fullWidth
        openOnFocus={true}
        popupIcon={ <ExpandMoreIcon/>}
        openText={"Abrir"}
        clearText={"Limpiar"}
        closeText={"Cerrar"}
        renderInput={(params) => (
          <div className={classes.root}>
            <CustomSelect {...params}
              variant="outlined"
              label={label}
              fullWidth
              error={!!errorValidator ? true : false}
              helperText={errorValidator}
            />
          </div>

        )}
      />
    </div>

  );

}

export default CustomAutoComplete;