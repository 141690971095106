import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import InputIcon from '../../components/input/inputIcon.component'
import CustomProgress from '../../components/Progress/progress.component'


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 800,
        backgroundColor: theme.palette.thirdBackgroundColor,
        borderRadius: '5px',
        color: theme.palette.primaryColor,
        border: 'none',
    },
    listStyle: {
        backgroundColor: theme.palette.secundaryBackgroundColor,
        color: theme.palette.primaryColor,
    },
    item: {
        marginBottom: 5,
        width: '90%',
        fontWeight: 600,
        "&.MuiListItem-root:hover": {
            borderRadius: 5,
            border: '1px solid #16B1F6',
        },
        '&.Mui-selected': {
            backgroundColor: '#16B1F3',
            borderRadius: 5,
        }
    },
    itemText: {
        fontWeight: 600,
        color: theme.palette.primaryColor,
        textTransform: 'capitalize'
    }
}));


/**
 * Filtra lista de acuerdo a parametros
 * @param {*} list Listado
 * @param {*} filter cadena para filtro
 */
function handleFilterList(list, filter, top) {
  
        if (!!filter && filter !== '') {
            list = list.filter(c => c.text.toLowerCase().match(new RegExp(filter.toLowerCase() + ".*")));
        }

        return list.slice(0, top);
}



/**
 * Lista con filtro
 * Autor:Fernando Ruiz
 * @method function
 * @param {array}list  Listado original
 * @param {event}onClick Evento click al seleccionar un item de la lista
 * @param {boolean} loading  visualzación de progress
 * @param {int} top  MAximo de item a mostrar
 */

function ListWithFilter(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState('');
    const [filter, setFilter] = React.useState('');
    const [filterList, setFilterList] = React.useState([]);
    const { list, onClick, loading, top } = props;


    useEffect(() => {
        if(!!list && list.length){
            setFilterList(list);
            setSelectedIndex(0);
        }
    
    }, [list]);

    const handleFilter = (e) => {
        const value = e.target.value;
        let newList = handleFilterList(list, value, top);
        setFilter(value);
        setFilterList(newList);
        setSelectedIndex(-1);
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };


    return (
        <Fragment>
            <InputIcon
                name={"txtFilter"}
                label={"Buscar cliente..."}
                maxLength={15}
                value={filter}
                onChange={(e) => {
                    handleFilter(e);
                }} />
            {loading === true ?
                <CustomProgress /> :
                <Fragment>
                    {filterList.length ?
                        <div className={classes.root}>

                            <List className={classes.listStyle} component="nav" aria-label="main mailbox folders">
                                {filterList.map((item, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            className={classes.item}
                                            button
                                            selected={selectedIndex === index}
                                            onClick={(event) => {
                                                handleListItemClick(event, index);
                                                onClick(event, item)
                                            }}
                                        >
                                            <ListItemText className={classes.itemText}>
                                                <span style={{ fontWeight: 600 }}>{item.text.toLowerCase()}</span>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                        : <div>No se han encontrado resultados...</div>
                    }
                </Fragment>
            }
        </Fragment>

    );
}

ListWithFilter.propTypes = {
    list: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {

})(ListWithFilter);