import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";

import InputForm from "../../components/input/input.component";
import ButtonPrimary from '../../components/button/buttonPrimary.component';

import { saveContactAction, updateContactAction, getContactsAction } from '../../actions/contactAction';

import generateId from "../../utils/generateId";
import { SuccessAlert, WarningAlert } from '../../helpers/alert.helpers'
import { regexEmail, regexAlphaNumeric, regexPhone } from '../../helpers/customRegex.hepers'


class NewContact extends Component {


    constructor(props) {
        super(props);
        this.state = {
            form: {
                txtContactName: '',
                txtContactEmail: '',
                txtContactPhone: ''
            },
            saveContactResponse: {},
            loadContact: {},
            updateContactResponse: {},
            editContactStatus: false

        }

        this.validator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.contactReducer.saveContactResponse && nextProps.contactReducer.saveContactResponse !== state.saveContactResponse)
            update.saveContactResponse = nextProps.contactReducer.saveContactResponse;

        if (nextProps.contactReducer.loadContact !== state.loadContact) {
            update.loadContact = nextProps.contactReducer.loadContact;
            update.editContactStatus = nextProps.contactReducer.editContactStatus;
        }

        if (!!nextProps.contactReducer.updateContactResponse && nextProps.contactReducer.updateContactResponse !== state.updateContactResponse)
            update.updateContactResponse = nextProps.contactReducer.updateContactResponse;


        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.saveContactResponse !== this.state.saveContactResponse) {
            if (this.state.saveContactResponse.responseCode === 'A10') {
                this.cleanForm();
                SuccessAlert('Registro exitoso', 'Se ha registrado un contacto adicional');
            }
            else if (this.state.saveContactResponse.responseCode === 'R11') {
                WarningAlert('Contacto existente', 'Ya se ha agregado este contacto');
            }
        }

        if (prevState.updateContactResponse !== this.state.updateContactResponse) {
            if (this.state.updateContactResponse.responseCode === 'A10') {
                this.cleanForm();
                SuccessAlert('Actualización exitoso', 'Se ha actualizado  contacto adicional');
            }
        }

        if (prevState.loadContact !== this.state.loadContact) {
            if (!!this.state.loadContact)
                this.loadInfo(this.state.loadContact);
        }
    }


    syncChanges(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    validateEmail(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexEmail;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    validatePhone(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexPhone;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }


    validateAlphaNumeric(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexAlphaNumeric;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }


    loadInfo(contact) {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtContactName: contact.nombre,
                txtContactEmail: contact.email,
                txtContactPhone: contact.telefono
            }
        }))
    }


    onSaveContact = (e) => {
        e.preventDefault();
        console.log("Validador-->", this.validator)
        if (this.validator.allValid()) {
            console.log("VALIDO-->", this.validator)

            let contact = {
                id: this.state.editContactStatus ? this.state.loadContact.id : generateId(),
                nombre: this.state.form.txtContactName,
                telefono: this.state.form.txtContactPhone,
                celular: this.state.form.txtContactPhone,
                email: this.state.form.txtContactEmail
            }

            console.log("ESTATUS", this.state.editContactStatus);

            if (this.state.editContactStatus)
                this.props.updateContactAction(contact);
            else
                this.props.saveContactAction(contact);

        } else {
            console.log("INVALIDO-->", this.validator)
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    cleanForm = () => {
        this.setState({
            form: {
                txtContactName: '',
                txtContactEmail: '',
                txtContactPhone: ''
            },
            saveContactResponse: {},
            updateContactResponse: {}
        });
        this.validator.hideMessages();
    }



    render() {
        return (
            <Fragment>
                <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                        <InputForm
                            id={"txtContactName"}
                            label={"Nombre *"}
                            maxLength={100}
                            value={this.state.form.txtContactName}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateAlphaNumeric(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|min:3|max:100"}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <InputForm
                            id={"txtContactEmail"}
                            label={"Correo electrónico *"}
                            maxLength={50}
                            value={this.state.form.txtContactEmail}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateEmail(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|email|min:10|max:50"}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <InputForm
                            id={"txtContactPhone"}
                            label={"Telefono *"}
                            maxLength={20}
                            value={this.state.form.txtContactPhone}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validatePhone(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|min:7|max:15"}
                        />
                    </Grid>

                    <Grid item lg={4} xs={12}>
                        <ButtonPrimary
                            text={this.state.editContactStatus ? 'Actualizar' : 'Agregar'}
                            type="button"
                            loading={false}
                            onClick={(e) => this.onSaveContact(e)}
                        />
                    </Grid>
                </Grid>
            </Fragment>



        );
    }


}

NewContact.propTypes = {
    saveContactAction: PropTypes.func.isRequired,
    updateContactAction: PropTypes.func.isRequired,
    getContactsAction: PropTypes.func.isRequired,

    contactReducer: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    contactReducer: state.contactReducer
})

export default connect(mapStateToProps, {
    saveContactAction,
    updateContactAction,
    getContactsAction

})(NewContact);