import React , {Component}from "react";
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, LabelList } from "recharts";
import { Card } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";

import CustomProgress from "../../../components/Progress/progress.component";

import "../Components/dashboard.css";

const useStyles = (theme) => ({
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
  },
  divMessage:{
    textAlign:'center',
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  }
});

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;

  return width >= 150 && value.length === 18 ? 
    <g>
      <text x={x + width / 2} y={y + 12} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    </g> : '' ;
};

class BestCustomChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, dataClient,statusCode} = this.props;

    return (
      <Card className={classes.colorCard}>
        <CardHeader title="Mejores Clientes" className={"titleCard"} />
        {dataClient.length === 0 && statusCode === undefined ? <CustomProgress/> : 
            dataClient.length === 0 && statusCode === 0  || dataClient.length === 0 && statusCode === 200? 
            <div className={classes.divMessage}>No tiene facturas registradas</div>
         : 
          <ComposedChart
            layout="vertical"
            width={390}
            height={168}
            data={dataClient}
          >
            <XAxis  type="category" tick={{ stroke: "#FFFFFF" }} />
            <YAxis
              type="category"
              tick={false}
              padding={{ bottom: 5 }}
              dataKey="nombreCliente"
            />
            <Tooltip cursor={false} />
            <Bar dataKey="Cantidad_Factura" barSize={25} fill="#0091BB">
              <LabelList
                dataKey="nombreCliente"
                position="insideTop"
                fill={"#FFFFFF"}
                content={renderCustomizedLabel}
              />
            </Bar>
          </ComposedChart>
        }
      </Card>
    );
  }
}

export default withStyles(useStyles)(BestCustomChart);
