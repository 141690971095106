import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Grid, GridList } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import GridListTile from '@material-ui/core/GridListTile';
import { Tooltip } from '@material-ui/core';

import CategoryCard from "./CategoryComponent";
import CollapsePanel from "../../../components/collapse/collapse.component";
import CreateCategory from "../category/newCategory.viwe";
import InputIcon from "../../../components/input/inputIcon.component";
import ButtonPrimary from '../../../components/button/buttonPrimary.component'

import { postCategory, deleteCategory, putCategory, loadCategoryAction, getCategoryPagination } from "../../../actions/categoryActions";
import { SuccessAlert, WarningAlert } from "../../../helpers/alert.helpers";
import { QUANTITY_DATA } from '../../../config/config.js'
import imageNotification from "../../../../src/images/NotificacionesColfactura.png";
import '../article/article.css'
import { getRoleOfLoggedUser } from "../../../actions/authActions";


const useStyles = (theme) => ({
  txtFilter: {
    margin: "10px 0px 10px 0px",
    color: theme.palette.primaryColor,
  },
  modalConfirm: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paperConfirm: {
    width: 410,
    height: 150,
    backgroundColor: "#252625",
    padding: theme.spacing(2, 4, 3),
  },
  colorIcon: {
    color: "white",
  },
  button: {
    color: "#81F7D8",
  },
  gridList: {
    width: '100%',
    height: '10%',
  },
  TypographyNotification: {
    fontSize: 28,
    fontFamily: 'Muli Medium',
    textAling: 'center',

  },
  centerImage: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export class CategoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txCategoryName: "",
        txCategoryColor: "",
        txCategoryImage: null
      },

      requestSuccesCategory: {},
      category: [],

      requestSuccessDeleteCategory: {},
      requestSuccessUpdateCategory: {},

      editCategoryStatus: false,
      expandPanelCategory: '',

      textFilter: "",
      modalDeleteCategory: false,

      page: 0,
      categoriasGeneral: [],

      count: 0,
      cleanDataCategory: false,

      cleanData: false,

      categoryDelete: 0,
      loadSearch: false

    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.categoryReducer.requestSuccessCategory) {
      update.cleanData = nextProps.categoryReducer.cleanDataCategory.data
      update.categoriasGeneral = nextProps.categoryReducer.cleanDataCategory.clean
    }

    if (!!nextProps.categoryReducer.requestGetCategory && nextProps.categoryReducer.requestGetCategory !== state.category) {
      update.category = nextProps.categoryReducer.requestGetCategory;
      update.count = nextProps.categoryReducer.count
    }

    if (!!nextProps.categoryReducer.editCategoryStatus && nextProps.categoryReducer.editCategoryStatus !== state.editCategoryStatus) {
      update.editCategoryStatus = nextProps.categoryReducer.editCategoryStatus;
      update.cleanData = nextProps.categoryReducer.cleanDataCategory

    }

    if (!!nextProps.categoryReducer.requestSuccessDeleteCategory && nextProps.categoryReducer.requestSuccessDeleteCategory !== state.requestSuccessDeleteCategory) {
      update.requestSuccessDeleteCategory = nextProps.categoryReducer.requestSuccessDeleteCategory;

    }

    if (!!nextProps.categoryReducer.requestSuccessUpdateCategory && nextProps.categoryReducer.requestSuccessUpdateCategory !== state.editCategoryStatus) {
      update.cleanData = nextProps.categoryReducer.cleanDataCategory.data
      update.categoriasGeneral = nextProps.categoryReducer.cleanDataCategory.clean
      update.editCategoryStatus = nextProps.categoryReducer.editCategoryStatus
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {

    let data = {
      page: this.state.page,
      quantity: QUANTITY_DATA,
      name: ''
    }
    this.props.getCategoryPagination(data)
  }


  componentDidUpdate(prevProps, prevState) {

    if (prevState.category !== this.state.category) {
      if (this.state.category.length > 0) {
        let categories = this.state.categoriasGeneral.concat(this.state.category)
        this.setState({ categoriasGeneral: categories })
      }
    }

    if (prevState.cleanData !== this.state.cleanData) {
      if (this.state.cleanData === true) {
        this.setState({ expandPanelCategory: '' })
      }
    }

    if (prevState.requestSuccessDeleteCategory !== this.state.requestSuccessDeleteCategory) {
      let data = {
        page: 0,
        quantity: QUANTITY_DATA,
        name: null
      }
      SuccessAlert("Genial!!!", "Eliminaste categoría correctamente");
      this.props.getCategoryPagination(data)
      this.handleCloseModalArticulo();
    }
  }

  delete(value) {
    this.setState({
      modalDeleteCategory: true,
      categoryDelete: value
    })
  }

  handleCloseConfirm = () => {
    this.props.deleteCategory(this.state.categoryDelete);
    this.setState({
      modalDeleteCategory: false,
      categoriasGeneral: [],
      page: 0
    })
  }

  handleCloseModalArticulo = () => {
    this.setState({
      modalDeleteCategory: false
    })
  }

  onLoadCategory = (e, id) => {
    e.preventDefault();

    var result = this.state.categoriasGeneral.filter((obj) => {
      return obj.id === id;
    })

    if (result === undefined || result === null) {
      WarningAlert("Opss...", "No se ha podido cargar la categoría");
      return;
    }

    const form = {
      txCategoryName: result[0].nombre,
      txCategoryColor: result[0].color,
      id: result[0].id
    };

    let statusCategoryPanel = "";
    if (this.state.expandPanelCategory === "")
      statusCategoryPanel = "panel";
    else if (this.state.expandPanelCategory === "panel")
      statusCategoryPanel = "refreshPanel";
    else if (this.state.expandPanelCategory === "refreshPanel")
      statusCategoryPanel = "panel";
    else statusCategoryPanel = "";
    this.props.loadCategoryAction(form);

    this.setState({
      expandPanelCategory: statusCategoryPanel
    })
  };

  syncChanges(value, property) {

    /*    let data = {
          empresaId: 6,
          name: this.state.txtFilter,
          quantity: QUANTITY_DATA,
          page: 0,
        }; */

    this.setState((prevState) => ({
      ...prevState.txtFilter,
      [property]: value,
    }));
    if (value.length === 0) {
      this.setState({
        page: 0,
        txtFilter: null,
        categoriasGeneral: []
      })
      let data = {
        empresaId: 6,
        name: null,
        quantity: QUANTITY_DATA,
        page: 0,
      };
      this.props.getCategoryPagination(data);

    }

  }

  loadMore() {
    let page = parseInt(this.state.page) + parseInt(QUANTITY_DATA)
    let load = {
      page: page,
      quantity: QUANTITY_DATA,
      name: null
    }

    this.setState({
      page: page,
      load: true
    })
    this.props.getCategoryPagination(load)
  }

  search() {
    let data = {
      empresaId: 6,
      name: this.state.txtFilter,
      quantity: QUANTITY_DATA,
      page: 0,
    };
    this.setState({
      categoriasGeneral: []
    })
    this.props.getCategoryPagination(data);
  }

  onClickCollapse = () => {
    this.setState({
      cleanData: false
    })
    if (this.state.expandPanelCategory === "panel") {
      this.setState({
        expandPanelCategory: "",
      });
    } else {
      this.setState({
        expandPanelCategory: "panel",
      }
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (

      <Grid container spacing={1}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              {
                (getRoleOfLoggedUser())
                  ? <CollapsePanel
                    component={CreateCategory}
                    title={
                      <LightTooltip
                        title="No tiene permisos sobre esta acción"
                        placement="bottom">
                        <span>Crear Nueva Categoría</span>
                      </LightTooltip>
                    }
                    titleOpen={"Crear Nueva Categoría"}
                    titleEdit={"Crear Nueva Categoría"}
                    open={''}
                  />
                  : <CollapsePanel
                    component={CreateCategory}
                    title={
                      !this.state.editCategoryStatus
                        ? "Crear Nueva Categoría"
                        : "Editar Categoría"
                    }
                    titleOpen={
                      !this.state.editCategoryStatus
                        ? "Crear Nueva Categoría"
                        : "Editar Categoría"
                    }
                    titleEdit={
                      !this.state.editCategoryStatus
                        ? "Crear Nueva Categoría"
                        : "Editar Categoría"
                    }
                    open={this.state.expandPanelCategory}
                    onClick={this.onClickCollapse}
                  />
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} className={classes.txtFilter}>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <InputIcon
                id={"txtFilter"}
                label={this.state.categoriasGeneral.length === 0 ? "No se encontraron resultados" : "Buscar categoría.."}
                value={this.state.txtFilter}
                onChange={(e) => {
                  this.syncChanges(e.target.value, "txtFilter");
                  // this.onChangeFilter(e);
                }}
                onSearch={() => this.search()}
              />
            </Grid>
          </Grid>
        </Grid>

        {this.state.categoriasGeneral.length === 0 ? <div className={classes.centerImage}>
          <br />
          <br />
          <img
            src={imageNotification} />
          <div className={classes.TypographyNotification}><span className={classes.TypographyNotification}>No tienes categorías creadas</span></div>
        </div> : ''}
        <GridList cellHeight={80} className={classes.gridList} cols={1}>
          {/**  { this.state.requestSuccesCategory.count > 0 && this.state.requestSuccesCategory.httpCode === 200 && this.state.requestSuccesCategory.content.length > 0  ? '' : <div><CustomProgress /></div>}*/}
          {this.state.categoriasGeneral !== null || this.state.categoriasGeneral !== undefined ? this.state.categoriasGeneral.map((item, index) => (
            <GridListTile key={item} cols={1}>
              <Fragment>
                <div className='slider'>
                  <Grid item lg={12} xs={12} spacing={1}>
                    <CategoryCard
                      key={index}
                      data={item}
                      onLoad={(e) => {
                        this.onLoadCategory(e, item.id);
                      }}
                      //onDelete={() => this.delete()}
                      onClick={() => this.delete(item.id)}

                    ></CategoryCard>
                  </Grid>
                  <Modal
                    className={classes.modalConfirm}
                    open={this.state.modalDeleteCategory}
                  >
                    <div className={classes.paperConfirm}>
                      <h4 className={classes.colorIcon}>
                        ¿Esta seguro que desea eliminar la categoría ?
                </h4>
                      <Button
                        className={classes.button}
                        onClick={() => this.handleCloseModalArticulo()}
                      >
                        Cancelar
                </Button>
                      <Button
                        className={classes.button}
                        onClick={() => this.handleCloseConfirm()}
                      >
                        Eliminar
                </Button>
                    </div>
                  </Modal>
                </div>
              </Fragment>

            </GridListTile>

          )
          )

            : ''}

        </GridList>
        {

          this.state.categoriasGeneral.length !== this.state.count ?
            <Grid container>
              <Grid item lg={4} xs={12} />
              <Grid item lg={4} xs={12}>
                <ButtonPrimary
                  text={'Mostrar más...'}
                  onClick={() => this.loadMore()}
                  loading={this.state.loadSearch === true ? true : false}
                //disabled ={this.state.categoriasGeneral.length === this.state.count ? true : false}
                />
              </Grid>
              <Grid item lg={4} xs={12} />

            </Grid> : ''
        }

      </Grid>
    );
  }
}
CategoryView.propTypes = {};

const mapStateToProps = (state) => ({
  categoryReducer: state.categoryReducer,
  articleReducer: state.articleReducer,
});

export default connect(mapStateToProps, {
  postCategory,
  deleteCategory,
  putCategory,
  loadCategoryAction,
  getCategoryPagination
})(withStyles(useStyles)(CategoryView));
