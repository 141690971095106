import {
    GET_INVOICES,
    SUCESS_DOCUMENT_UPDATE,
    GET_DOCUMENT,
    SUCESS_DOCUMENT_CREATE,
    SUCESS_QUOTATION_CREATE,
    RESEND_ELECTRONIC_INVOICE,
    GET_ADITIONAL_DATA_INVOICE,
    SUCESS_SEND_INVOICE,
    GET_TOTAL_DOCUMENTS,
    GET_QUOTATION,
    GET_REASONS_NOTES,
    GET_FILE_INVOICE,
    ACTIVE_STEP_INVOICE
} from "../actions/types";

const initialState = {
    invoices: [],
    sucess: {},
    document: {},
    create: {},
    resend: {},
    createquotation: {},
    aditionaldataenterprise: {},
    sendmail: {},
    showCatalog: true,
    totaldocs: [],
    quotation: {},
    reasons: [],
    file: {}
}


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICES:
            return {
                ...state,
                invoices: action.payload
            };

        case SUCESS_DOCUMENT_UPDATE:
            return {
                ...state,
                sucess: action.payload
            };

        case GET_DOCUMENT:
            return {
                ...state,
                document: action.payload
            };
        case SUCESS_DOCUMENT_CREATE:
            return {
                ...state,
                create: action.payload
            };

        case RESEND_ELECTRONIC_INVOICE:
            return {
                ...state,
                resend: action.payload
            };

        case SUCESS_QUOTATION_CREATE:
            return {
                ...state,
                createquotation: action.payload
            };

        case SUCESS_SEND_INVOICE:
            return {
                ...state,
                sendmail: action.payload
            };

        case GET_ADITIONAL_DATA_INVOICE:
            return {
                ...state,
                aditionaldataenterprise: action.payload
            };

        case GET_TOTAL_DOCUMENTS:
            return {
                ...state,
                totaldocs: action.payload
            };
        case GET_QUOTATION:
            return {
                ...state,
                quotation: action.payload
            };
        case GET_REASONS_NOTES:
            return {
                ...state,
                reasons: action.payload
            }
        case GET_FILE_INVOICE:
            return {
                ...state,
                file: action.payload
            };
        case ACTIVE_STEP_INVOICE:
            return{
                ...state,
                activeStepInvoice: action.payload
            }
        default:
            return state
    }
}