import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";


import { Grid, IconButton } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import FormControl from "@material-ui/core/FormControl";
import { Tooltip } from '@material-ui/core';

import InputIcon from "../../../components/input/inputIcon.component";
import GrandList from "../component/GrandList/GrandList.component";
import CheckboxList from '../../../components/list/checkBoxList.component'
import CardList from "./cardArticle.component";
import CollapsePanel from "../../../components/collapse/collapse.component";
import CreateArticle from "./createArticle.component";
import SelectFilter from "../component/selectFilter.component"
import ButtonSecundary from '../../../components/button/buttonSecundary.component'
import ButtonPrimary from '../../../components/button/buttonPrimary.component'
import CustomProgress from '../../../components/Progress/progress.component'
import SimpleModal from '../../../components/modal/simpleModal.component'
import DetailArticle from '../article/detailArticle.component'

import {
  deleteArticle,
  loadArticleAction,
  getArticleListAction,
  clearArticleListAction,
  filterArticleListAction,
  orderArticleListAction,
  setArticleCheckedAction,
  getArticleCheckedAction,
  cleanArticleCheckedAction,
  getInitialArticleListAction,
  refreshAfterUpdatingAction,
  refreshAfterSaveAction,
  refreshAfterDeleteAction
} from "../../../actions/articleActions";
import { setStatusShowCartAction, addArticlesToCartAction } from '../../../actions/valuesInvoiceAction'
import { getCategoryAction } from "../../../actions/categoryActions";

import { SuccessAlert, WarningAlert, ConfirmAlert } from "../../../helpers/alert.helpers";
import isEmpty from "../../../utils/isEmpty";
import { getRoleOfLoggedUser } from "../../../actions/authActions";


const optionsFilter = [
  { value: 1, text: "Menor precio" },
  { value: 2, text: "Mayor precio" },
  { value: 3, text: "A-Z" },
  { value: 4, text: "Z-A" },
  { value: 5, text: "Ver todo" }]


const useStyles = (theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    minWidth: 800
  },
  paper: {
    width: 600,
    height: 700,
    backgroundColor: "#252625",
    padding: theme.spacing(2, 4, 3),
  },
  buttonIcon: {
    color: "white",
    fontSize: 30
  },
  buttonIconSearch: {
    color: "#D8D8D8",
  },

  modalConfirm: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },

  paperConfirm: {
    width: 410,
    height: 150,
    backgroundColor: "#252625",
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    color: "#81F7D8",
  },
  filter: {
    height: 30,
    color: "white",
  },
  closeModal: {
    left: 510,
    color: "#00BFFF",
  },
  divImage: {
    width: 200,
    height: 200,
  },

  colorIcon: {
    color: "white",
  },

  txtFilter: {
    margin: "10px 0px 10px 0px",
    color: theme.palette.primaryColor,
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


export class ArticleView extends Component {
  constructor(props) {
    super(props);
    this.state = {

      txtFilter: '',
      expandPanelArticle: "",
      modalDeleteArticle: false,

      deleteArticleResponse: {},
      editArticleStatus: false,
      updateFavoriteResponse: {},

      categories: [],
      checkedCategories: [],

      articleDetailObject: {},
      skip: 0,
      typeView: '',
      getArticlesResponse: [],
      loadingGetArticles: false,
      getArticleListResponse: [],
      totalArticles: 0,
      filterTextConfirmed: '',
      ddlOrderBy: '5',
      presentationType: 'List',
      openModalItem: false,
      checkedArticles: [],
      loadingUpdateArticle: false,
      updateArticleResponse: {},
      saveArticleResponse: {},

    };
  }


  componentDidMount() {
    let filterData = {
      skip: this.state.skip,
      filter: '',
      type: this.props.type,
      typeOrder: parseInt(this.state.ddlOrderBy)
    };

    this.props.getInitialArticleListAction(filterData);
    this.props.getCategoryAction();
  }


  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.categoryReducer.getCategories && nextProps.categoryReducer.getCategories !== state.categories)
      update.categories = nextProps.categoryReducer.getCategories;

    if (!!nextProps.articleReducer.getArticleListResponse && nextProps.articleReducer.getArticleListResponse !== state.getArticleListResponse)
      update.getArticleListResponse = nextProps.articleReducer.getArticleListResponse;

    if (!isEmpty(nextProps.articleReducer.deleteArticleResponse) && nextProps.articleReducer.deleteArticleResponse !== state.deleteArticleResponse)
      update.deleteArticleResponse = nextProps.articleReducer.deleteArticleResponse;

    if (nextProps.articleReducer.editArticleStatus !== state.editArticleStatus)
      update.editArticleStatus = nextProps.articleReducer.editArticleStatus;

    if (!isEmpty(nextProps.articleReducer.updateFavoriteResponse) && nextProps.articleReducer.updateFavoriteResponse !== state.updateFavoriteResponse)
      update.updateFavoriteResponse = nextProps.articleReducer.updateFavoriteResponse;

    if (!isEmpty(nextProps.articleReducer.saveArticleResponse) !== nextProps.articleReducer.saveArticleResponse !== state.saveArticleResponse)
      update.saveArticleResponse = nextProps.articleReducer.saveArticleResponse


    if (!isEmpty(nextProps.articleReducer.updateArticleResponse) !== nextProps.articleReducer.updateArticleResponse !== state.updateArticleResponse)
      update.updateArticleResponse = nextProps.articleReducer.updateArticleResponse;

    if (!!nextProps.typeView && nextProps.typeView !== state.typeView)
      update.typeView = nextProps.typeView;

    if (!isEmpty(nextProps.articleReducer.loadingGetArticles) && nextProps.articleReducer.loadingGetArticles !== state.loadingGetArticles)
      update.loadingGetArticles = nextProps.articleReducer.loadingGetArticles;

    if (!isEmpty(nextProps.articleReducer.totalArticles) && nextProps.articleReducer.totalArticles !== state.totalArticles)
      update.totalArticles = nextProps.articleReducer.totalArticles;

    if (!!nextProps.articleReducer.checkedArticles && nextProps.articleReducer.checkedArticles !== state.checkedArticles)
      update.checkedArticles = nextProps.articleReducer.checkedArticles;

    if (!isEmpty(nextProps.articleReducer.loadingUpdateArticle) && nextProps.articleReducer.loadingUpdateArticle !== state.loadingUpdateArticle)
      update.loadingUpdateArticle = nextProps.articleReducer.loadingUpdateArticle;

    return Object.keys(update).length ? update : null;
  }



  componentDidUpdate(prevProps, prevState) {

    if (prevState.deleteArticleResponse !== this.state.deleteArticleResponse) {
      if (this.state.deleteArticleResponse.statusCode === "200") {
        SuccessAlert("Articulo eliminado", "Se ha eliminado el articulo");
        this.props.refreshAfterDeleteAction(this.state.deleteArticleResponse.id);
      }
    }

    if (prevState.updateFavoriteResponse !== this.state.updateFavoriteResponse) {
      if (this.state.updateFavoriteResponse.statusCode === "200") {
        this.refreshAfterClickFavorite(this.state.updateFavoriteResponse.id);
      }
    }


    if (prevState.updateArticleResponse !== this.state.updateArticleResponse) {
      if (this.state.updateArticleResponse.statusCode === "201") {
        this.setState({
          expandPanelArticle: ''
        })
        this.props.refreshAfterUpdatingAction(this.state.updateArticleResponse.result, this.props.type);
      }
    }

    if (prevState.saveArticleResponse !== this.state.saveArticleResponse) {
      if (this.state.saveArticleResponse.statusCode === "201") {
        this.setState({
          expandPanelArticle: ''
        })
        this.props.refreshAfterSaveAction(this.state.saveArticleResponse.result, this.props.type);
      }
    }
  }

  /**
   * Sincroniza inputs con state de componente
   * @param {object} e 
   */
  syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState({
      [id]: value
    })
  }

  /**
   * Sincroniza información de CheckBox Articulo,
   * agrega objecto articulo a listado (getArticleCheckedAction) de articulos seleccionados
   * @param {*} event
   * @param {*} item
   */
  syncCheckChange(event, item) {
    let checked = event.target.checked;

    item = {
      ...item, 
      dateAdded: new Date()
    }

    this.props.setArticleCheckedAction(item, checked);
  }


  /**
  * Evento click, Carga articulos seleccionados en Carrito
  * @param {*} item
  */
  addItemsToCart() {

    if (this.state.checkedArticles.length === 0) {
      WarningAlert('Opss!!!', 'Aun no has seleccionado articulos.');
      return;
    }

    //Agrega Items a memoria
    this.props.addArticlesToCartAction(this.state.checkedArticles);

    //Setea estado para ver panel detalle factura, carrito de compras
    this.props.setStatusShowCartAction(true);

    //Limpia lista de articulos seleccionados en Redux
    this.props.cleanArticleCheckedAction();

  }

  /**
 * Consulta listado de articulos por parametros de busqueda
 * @event
 */
  onSearch = (e) => {
    e.preventDefault();

    //Limpia lista actual
    this.props.clearArticleListAction()

    let filterData = {
      skip: 0,
      filter: this.state.txtFilter,
      type: this.props.type,
      typeOrder: parseInt(this.state.ddlOrderBy)
    };

    //Actualiza filtro confirmado
    this.setState({
      filterTextConfirmed: this.state.txtFilter,
    })

    this.props.filterArticleListAction(filterData);
  }


  /**
   * Evento click, Invoca action para renderixado de carrito de compras.
   * Setea estado para ver panel detalle factura, carrito de compras 
   * @param {object} e 
   */
  goToCart = (e) => {
    e.preventDefault();
    //
    this.props.setStatusShowCartAction(true);
  }


  /**
   * Consulta siguiente pagina de articulos en la BD
   */
  loadMoreArticles = (e) => {
    e.preventDefault();

    let skip = this.state.getArticleListResponse.length;
    let filterData = {
      skip: skip,
      filter: this.state.filterTextConfirmed,
      type: this.props.type,
      typeOrder: parseInt(this.state.ddlOrderBy)
    };


    //Actualiza filtro confirmado
    this.setState({
      txtFilter: this.state.filterTextConfirmed,
    })

    //Consulta nuevo lote de articulos
    this.props.getArticleListAction(filterData);
  }

  /**
   * Evento, Ordena lista cargada de acuerdo a la selección del usuario
   * @param {object} e 
   */
  onChangeOrder(e) {
    e.preventDefault();
    const value = e.target.value;

    //Limpia lista actual
    this.props.clearArticleListAction()

    let filterData = {
      skip: 0,
      filter: this.state.filterTextConfirmed,
      type: this.props.type,
      typeOrder: parseInt(value)
    };


    //Actualiza filtro confirmado
    this.setState({
      txtFilter: this.state.filterTextConfirmed,
    })

    this.props.filterArticleListAction(filterData);
  }

  /**
   * Escucha cambios en tipo presentación 
   * catalogo articulos (Presentación mosaico o listado tarjetas)
   */
  syncChangePresentationType(type) {

    if (type !== this.state.presentationType) {

      this.setState({
        presentationType: type
      });

      let skip = this.state.getArticleListResponse.length;
      let filterData = {
        skip: skip,
        filter: '',
        type: this.props.type,
        typeOrder: parseInt(this.state.ddlOrderBy)
      };


      this.props.getArticleListAction(filterData, false);
    }
  }

  /**
   * Actualiza estado, cierra modal detalle articulo
   * al dar click en boton cerrar
   * @param {object} e 
   */
  onCloseModalItem = (e) => {
    this.setState({
      openModalItem: false
    });
  };

  /**
  * Actualiza estado, Muestra modal detalle articulo
  */
  handleOpenModal(e, item) {

    this.setState({
      openModalItem: true,
      articleDetailObject: item, //Carga articulo seleccionado en estado
    });
  };

  /**
   * Evento, Escucha seleccion de articulo desde modal de detalle
   * @param {object} e Evento
   * @param {object} data Información Item
   */
  onSelectItem = (e, data) => {
    e.preventDefault();

    this.props.setArticleCheckedAction(data, true);

    this.setState({
      openModalItem: false
    })

    this.props.getArticleListAction(null, false);
  }


  /**
   * Carga información del articulo en Redux
   * para edición
   * @param {*} e 
   */
  onLoadItem = (e, item) => {
    e.preventDefault();


    //Carga item en Redux
    this.props.loadArticleAction(item);

    //Expande collapse articulos,
    //cierra modal detalle articulos
    this.setState({
      expandPanelArticle: 'panel',
      openModalItem: false
    });

  };

  /**
   * Evento, Escucha evento para eliminar articulo desde el modal de detalle
   * @param {object} e Evento
   * @param {object} item Información articulo
   */
  onDeleteItem = (e, item) => {
    e.preventDefault();

    const deleteData = (confirmed) => {
      if (confirmed) {
        this.props.deleteArticle(item.id);

        //Cierra modal detalle articulos
        this.setState({
          openModalItem: false
        });
      }
    }
    ConfirmAlert('Eliminar...', `¿Desea eliminar el articulo ${item.nombrearticulo}?`, deleteData)

  }

  /**
   * Evento click, Escucaha evento click sobre collapse
   * del formulario articulos, expande o contrae collapse
   */
  onClickCollapse = () => {

    if (this.state.expandPanelArticle === 'panel') {
      this.setState({
        expandPanelArticle: ''
      })
    } else if (this.state.expandPanelArticle === '') {
      this.setState({
        expandPanelArticle: 'panel',
      })
    }
  };

  /**
   * Actualiza objeto cargado en Modal de detalle articulo,
   * y actualiza listado de articulos en memoria
   */
  refreshAfterClickFavorite(id) {

    //actualiza objecto cargdo en modal
    let newData = {
      ...this.state.articleDetailObject,
      favorito: this.state.articleDetailObject.favorito === true ? false : true
    }
    this.setState({
      articleDetailObject: newData
    })

    //Actualiza Listado en memoria
    this.props.refreshAfterUpdatingAction(id, this.props.type);
  }


  // handleCloseConfirm() {
  //   this.setState({ modalDeleteArticle: false, openModalItem: true });
  // }

  // handleCloseModalArticulo(value) {
  //   if (value === "delete") {
  //     this.setState({ modalDeleteArticle: false, openModalItem: false });

  //   } else {
  //     this.setState({ modalDeleteArticle: false, openModalItem: false });
  //   }
  // }



  /**
   * Evento, escucha evento de cerrado de lista de categorias
   * realiza filtro
   * @param {*} e 
   * @param {*} categories 
   */
  onCloseListCategory(e, categories) {
    e.preventDefault();

    const list = this.state.checkedCategories;
    const newList = categories.map((data) => {
      return data.id;
    })

    list.sort();
    newList.sort();

    var theyAreEqual = list.length === newList.length && list.every((v, i) => {
      return v === newList[i]
    });

    if (theyAreEqual !== true) {

      //Actualiza filtro confirmado.
      //actualiza listado de categorias seleccionadas
      this.setState({
        txtFilter: this.state.filterTextConfirmed,
        checkedCategories: newList
      })



      let filterData = {
        skip: 0,
        filter: this.state.txtFilter,
        type: this.props.type,
        categories: newList,
        typeOrder: parseInt(this.state.ddlOrderBy)
      };


      //Limpia lista actual
      this.props.clearArticleListAction()
      this.props.filterArticleListAction(filterData);
    }

  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment >
        <div className="comunContainer">
          {
            (getRoleOfLoggedUser())
              ? <CollapsePanel
                component={CreateArticle}
                title={
                  <LightTooltip
                    title="No tiene permisos sobre esta acción"
                    placement="bottom">
                    <span>Crear nuevo artículo</span>
                  </LightTooltip>
                }
                titleOpen={"Crear nuevo artículo"}
                titleEdit={"Crear nuevo artículo"}
                open={''}
              />
              : <CollapsePanel
                component={CreateArticle}
                title={!this.state.editArticleStatus ? "Crear nuevo artículo" : "Editar artículo"}
                titleOpen={!this.state.editArticleStatus ? "Nuevo Artículo" : "Editar artículo"}
                open={this.state.expandPanelArticle}
                onClick={this.onClickCollapse}
              />
          }
        </div>

        <Grid container>
          <Grid item lg={4} xs={12}>
            <InputIcon
              id={"txtFilter"}
              label={"Buscar articulos.."}
              value={this.state.txtFilter}
              onChange={(e) => this.syncChanges(e)}
              onSearch={this.onSearch}
              maxLength={100}
              disabled={this.state.loadingGetArticles}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <div style={{ display: 'flex' }}>
              <Tooltip title="Filtrar por categoria">
                <CheckboxList
                  options={this.state.categories}
                  onCloseList={(e, categories) => {
                    this.onCloseListCategory(e, categories);
                  }}
                />
              </Tooltip>
              <Tooltip title="Lista">
                <IconButton
                  onClick={() => this.syncChangePresentationType('Grid')}
                  disabled={this.state.loadingGetArticles}
                >
                  <ViewListIcon className={classes.buttonIcon} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Mosaico">
                <IconButton
                  onClick={() => this.syncChangePresentationType('List')}
                  disabled={this.state.loadingGetArticles} >
                  <ViewModuleIcon className={classes.buttonIcon} />
                </IconButton>
              </Tooltip>

              <FormControl style={{ paddingTop: 20, width: '100%' }}>
                <SelectFilter
                  name={'ddlOrderBy'}
                  value={this.state.ddlOrderBy}
                  options={optionsFilter}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.onChangeOrder(e);
                  }}
                  label={''}
                  disabled={this.state.loadingGetArticles}
                />
              </FormControl>
            </div>

          </Grid>

          {this.state.typeView === 'invoice' ?
            <Fragment>
              <Grid item lg={2} xs={2}>
                <ButtonPrimary
                  onClick={(e) => this.addItemsToCart(e)}
                  loading={false}
                  text={'Añadir a factura'}
                  disabled={this.state.checkedArticles.length > 0 ? false : true}
                />

              </Grid>
              <Grid item lg={2} xs={2}>
                <ButtonSecundary
                  text={'Ir a Carrito'}
                  loading={false}
                  onClick={this.goToCart} 
                  disabled={this.props.quantityInCart > 0 ? false : true} />
              </Grid>
            </Fragment>
            :
            <Fragment>
              <Grid item lg={4} xs={4}>
                <ButtonPrimary
                  onClick={(e) => this.addItemsToCart(e)}
                  loading={false}
                  text={'Añadir a factura'}
                  disabled={this.state.checkedArticles.length > 0 ? false : true}
                />
              </Grid>
            </Fragment>
          }
        </Grid>

        {this.state.presentationType === "List" ?
          <GrandList
            options={this.state.getArticleListResponse}
            onSelectArticle={(e, item) => {
              this.handleOpenModal(e, item);
            }}
            onChange={(e, item) => {
              this.syncCheckChange(e, item);
            }}
          />
          :
          <Grid container spacing={2} className={classes.container}>
            {this.state.getArticleListResponse.map((item, index) =>
              <Grid key={index} item lg={4} xs={6}>
                <CardList
                  data={item}
                  onSelectArticle={(e, item) => {
                    this.handleOpenModal(e, item);
                  }}
                  onChange={(e, item) => {
                    this.syncCheckChange(e, item);
                  }}
                />
              </Grid>
            )}
          </Grid>
        }

        {this.state.getArticleListResponse.length === 0 && this.state.loadingGetArticles === false ?
          <div>No se han encontrado resultados...</div> : ''
        }

        {this.state.getArticleListResponse.length === 0 && (this.state.loadingGetArticles === true)
          ? <CustomProgress />
          : ''}

        {/* Modal detalle articulo */}
        <SimpleModal
          onClose={e => this.onCloseModalItem(e)}
          open={this.state.openModalItem}
          title={''}
          disabled={this.state.loadingUpdateArticle}
          noPadding={true}
          component={() =>
            <DetailArticle
              data={this.state.articleDetailObject}
              onSelectItem={this.onSelectItem}
              onLoadItem={this.onLoadItem}
              onDeleteItem={this.onDeleteItem}
              loadingFavorite={this.state.loadingUpdateArticle} />}
        />

        {/* Modal confirmación */}
        {/* <Modal
          className={classes.modalConfirm}
          open={this.state.modalDeleteArticle}>
          <div className={classes.paperConfirm}>
            <h4 className={classes.colorIcon}>
              ¿Esta seguro que desea eliminar el artículo?
            </h4>
            <Button
              className={classes.button}
              onClick={() => this.handleCloseConfirm()}>
              Cancelar
            </Button>
            <Button
              className={classes.button}
              onClick={() => this.handleCloseModalArticulo("delete")}>
              Eliminar
            </Button>
          </div>
        </Modal> */}

        <Grid container style={{ marginTop: 30 }}>
          <Grid item lg={4} xs={4} />
          <Grid item lg={4} xs={4}>
            {this.state.getArticleListResponse.length > 0 ?
              <ButtonPrimary
                onClick={this.loadMoreArticles}
                loading={this.state.loadingGetArticles}
                text={'Mostrar más...'}
                disabled={this.state.totalArticles === this.state.getArticleListResponse.length ? true : false}
              /> : ''
            }
          </Grid>
          <Grid item lg={4} xs={4} />
        </Grid>
      </Fragment>
    );
  }
}

ArticleView.propTypes = {
  authReducer: PropTypes.object.isRequired,
  setStatusShowCartAction: PropTypes.func.isRequired,
  addArticlesToCartAction: PropTypes.func.isRequired,
  getArticleListAction: PropTypes.func.isRequired,
  clearArticleListAction: PropTypes.func.isRequired,
  filterArticleListAction: PropTypes.func.isRequired,
  orderArticleListAction: PropTypes.func.isRequired,
  setArticleCheckedAction: PropTypes.func.isRequired,
  getArticleCheckedAction: PropTypes.func.isRequired,
  cleanArticleCheckedAction: PropTypes.func.isRequired,
  getInitialArticleListAction: PropTypes.func.isRequired,
  refreshAfterUpdatingAction: PropTypes.func.isRequired,
  refreshAfterSaveAction: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  refreshAfterDeleteAction: PropTypes.func.isRequired,

  articleReducer: PropTypes.object.isRequired

};

const mapStateToProps = (state) => ({
  articleReducer: state.articleReducer,
  authReducer: state.authReducer,
  categoryReducer: state.categoryReducer,
});

export default connect(mapStateToProps, {
  deleteArticle,
  loadArticleAction,
  getCategoryAction,
  setStatusShowCartAction,
  addArticlesToCartAction,
  getArticleListAction,
  clearArticleListAction,
  filterArticleListAction,
  orderArticleListAction,
  setArticleCheckedAction,
  getArticleCheckedAction,
  cleanArticleCheckedAction,
  getInitialArticleListAction,
  refreshAfterUpdatingAction,
  refreshAfterSaveAction,
  refreshAfterDeleteAction
})(withStyles(useStyles)(ArticleView));

