
import {
  GET_STATES,
  GET_CITIES,
  GET_CITIES_STATE,
  GET_ALL_CITIES,
  GET_DOCUMENT_TYPES,
  GET_REGIME_TYPES,
  GET_MENU,
  GET_COMPONENTS,
  GET_PAYMENT_METHODS,
  GET_CITIES_COUNTRY,
  GET_MENU_COMPONENT,
  GET_FREQUENT_QUESTIONS,
  GET_REPORT_PROBLEM,
  GET_SECURITY_POLICY,
  SEND_MAIL,
  GET_QUESTION_FILTER,
  GET_REASON,
  SEND_MAIL_CONTACT
} from "../actions/types";

const initialState = {
  menu: {},
  getCitiesStateResponse: [],
  responseSendReport: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    
    case GET_STATES:
      return {
        ...state,
        getStatesResponse: action.payload,
      };
    case GET_CITIES:
      return {
        ...state,
        getCitiesResponse: action.payload,
      };
    case GET_ALL_CITIES:
      return {
        ...state,
        getAllCitiesResponse: action.payload,
      };
    case GET_CITIES_STATE:
      return {
        ...state,
        getCitiesStateResponse: action.payload,
      };
    case GET_DOCUMENT_TYPES:
      return {
        ...state,
        getDocumentTypesResponse: action.payload,
      };
    case GET_REGIME_TYPES:
      return {
        ...state,
        getRegimeTypesResponse: action.payload,
      };
    case GET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case GET_COMPONENTS:
      return {
        ...state,
        components: action.payload,
      };
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        getPaymentMethodsResponse: action.payload,
      };
    case GET_CITIES_COUNTRY:
      return {
        ...state,
        allCitiesCountry: action.payload,
      };
    case GET_MENU_COMPONENT:
      return {
        ...state,
        menuComponent: action.payload,
      };
    case GET_FREQUENT_QUESTIONS:
      return {
        ...state,
        frequentQuestion: action.payload,
      };
   
    case GET_SECURITY_POLICY:
      return {
        ...state,
        policy: action.payload,
      };

      case GET_QUESTION_FILTER:
      return {
        ...state,
        getFilterQuestion: action.payload,
      };
      case GET_REPORT_PROBLEM:
      return {
        ...state,
        dataIssue: action.payload,
        issue:action.payload1,
        fullName: action.payload2,
        emailUserProblem:action.payload3
      };

      case GET_REASON:
      return {
        ...state,
        reasonData: action.payload,
        data : action.payload1,
        emailUser: action.payload2

      };
      case SEND_MAIL:
      return {
        ...state,
        responseSendReportProblem: action.payload
      };
      case SEND_MAIL_CONTACT:
      return {
        ...state,
        responseDataMailContact: action.payload
      };

    default:
      return state;
  }
}
