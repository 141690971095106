import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Style from '@material-ui/icons/Style';
import { Grid } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

import { getRoleOfLoggedUser } from '../../../actions/authActions';
import {validateTotalDocumentAction} from '../../../actions/valuesInvoiceAction'



const useStyles = (theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.eighthBackgroundColor,
    },
    content: {
        textAlign: 'center'
    },
    totalText: {
        fontSize: 26,
        color: theme.palette.secundaryColor,
        fontWeight: 600
    },
    comunText: {
        color: theme.palette.primaryColor
    },
    actions: {
        textAlign: 'center'
    },
    button: {
        borderRadius: '15px !important',
        borderColor: '1px solid red'
    },
    label: {
        flexDirection: 'column',
        fontSize: 12,
        color: theme.palette.primaryColor
    },
    icon: {
        color: theme.palette.secundaryColor,
        fontSize: '5vh !important',
    },
    containerIcon: {
        backgroundColor: 'rgba(255,255,255,0.11)',
        padding: 5,
        borderRadius: 10
    },
    divider: {
        width: '100%',
        borderBottom: '1px solid gray',
        display: 'block',
    },
    totalInvalid: {
        color: 'red',
        fontSize: 26,
        fontWeight: 600
    }

});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

class InvoiceTotal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {

            },
            invoiceValues: {}
        }
    }

   render() {
        const { classes, data, onClickGoCatalog, onClickNewItem } = this.props;
        return (
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <span
                        className={classes.comunText}
                        style={{ fontSize: 14 }} >
                        {`${data.totalCantidades.toLocaleString('en')}  Productos`}
                    </span>
                    <br />
                    {parseFloat(data?.totalapagar) < 1 ?
                        <span className={classes.totalInvalid}>
                            ${!!data?.totalapagar ? data.totalapagar.toLocaleString('en') : "0.00"}
                        </span> :
                        <span className={classes.totalText}>
                            ${!!data?.totalapagar ? data.totalapagar.toLocaleString('en') : "0.00"}
                        </span>
                    }
                    <br />
                    <span
                        className={classes.comunText}
                        style={{ fontSize: 12, fontStyle: 'italic' }} >
                        Valor total impuestos incluidos
                    </span>
                </CardContent>
                <span className={classes.divider} ></span>
                <CardActions className={classes.actions}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={6}>
                            {
                                (getRoleOfLoggedUser())
                                    ? <LightTooltip
                                        title="No tiene permisos sobre esta acción"
                                        placement="bottom">
                                        <IconButton
                                            classes={{ root: classes.button, label: classes.label }}
                                            variant="raised"
                                            disableRipple={true}>
                                            <div className={classes.containerIcon}>
                                                <AddCircleIcon className={classes.icon} />
                                            </div>
                                            <span style={{ marginTop: 5 }}>
                                                {'Crear producto'}
                                            </span>
                                        </IconButton>
                                    </LightTooltip>

                                    : <IconButton
                                        classes={{ root: classes.button, label: classes.label }}
                                        variant="raised"
                                        disableRipple={true}
                                        onClick={onClickNewItem}>
                                        <div className={classes.containerIcon}>
                                            <AddCircleIcon className={classes.icon} />
                                        </div>
                                        <span style={{ marginTop: 5 }}>
                                            {'Crear producto'}
                                        </span>
                                    </IconButton>
                            }
                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <IconButton
                                classes={{ root: classes.button, label: classes.label }}
                                variant="raised"
                                disableRipple={true}
                                onClick={onClickGoCatalog}>
                                <div className={classes.containerIcon}>
                                    <Style className={classes.icon} />
                                </div>
                                <span style={{ marginTop: 5 }}> {'Agregar desde catalogo'}</span>
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        );
    }


}

InvoiceTotal.propTypes = {
    validateTotalDocumentAction: PropTypes.func.isRequired

}

const mapStateToProps = state => ({

})


export default connect(mapStateToProps, {
    validateTotalDocumentAction
})(withStyles(useStyles)(InvoiceTotal));

