import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import InvoiceItem from './invoiceItem/invoiceItem.view.js'
import InvoiceRevision from '../invoice/invoice-revision.view';

import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';

import CustomProgress from '../../components/Progress/progress.component';
import { updateDocument, createNote, setActiveStepInvoiceAction } from "../../actions/invoiceActions";
import { getTotalAction, setStatusShowCartAction, clearLoadedChargeInvoiceActionAll, clearLoadedDiscountInvoiceActionAll } from "../../actions/valuesInvoiceAction";
import { useHistory } from 'react-router-dom';
import { ConfirmAlert, WarningAlert } from '../../helpers/alert.helpers';
import ResponseModal from '../../components/modal/responseModal.component';
import FileModalData from '../../components/modal/fileModalData.component';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secundaryBackgroundColor,
        paddingBottom: '18px',
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingRight: '0px'
    },
    activeButton: {
        borderBottom: '1px solid #50C1EE',
        paddingBottom: '10px'
    }
}));

const QontoConnector = withStyles({
    root: {},
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    line: {
        borderColor: '#4F4F4F',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (<div className={
        clsx(classes.root, {
            [classes.active]: active,
        })
    } > {completed ? < Check className={classes.completed} /> : <div className={classes.circle} />} </div>
    );
}

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#FFFFFF',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#2CC63E',
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: '#4F4F4F',
    },
    completed: {
        color: '#4F4F4F',
        zIndex: 1,
        fontSize: 18,
        backgroundColor: '#2CC63E',
        borderRadius: '50%',
        padding: 5
    },

});

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};



function getSteps() {
    return ['Información de Factura', 'Artículos', 'Revisar Factura'];
}

const InvoiceEdit = (props) => {
    const classes = useStyles();
    const {setActiveStepInvoiceAction} = props;
    const { HandleBack, Empresaid, Userid, totalinvoice, objInvoice, isValidDocument, editInvoiceStatus } = props;
    const [loading, setLoading] = React.useState(false);
    const [formSave, setFormSave] = React.useState(null);
    const [star, setStar] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0); //Debe ser cero no cambiar
    const [completed, setCompleted] = React.useState(new Set());
    const [modalResponse, setModalResponse] = React.useState({
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: 0
    });
    const windowsHeight = parseInt((window.innerHeight) * 0.7, 10);
    const heightDiv = windowsHeight + 'px';
    const steps = getSteps();
    const history = useHistory();
    const [footShow, setFootShow] = React.useState(false);
    const [reason, setReason] = React.useState(null);
    const [observation, setObservations] = React.useState('');
    const [openModalFile, setOpenModalFile] = React.useState(false);
    const [formPreview, setFormPreview] = React.useState(null);


    /**
     * Muestra sección 1 (carrto de comparas) al momento
     *  de editar una factura
     */
    useEffect(() => {
        if (editInvoiceStatus === true) {
            setActiveStep(1);
        }
    }, [editInvoiceStatus])


    /**
    * Carga información de paso activo en redux
    */
    useEffect(() => {
        if (activeStep !== null && activeStep !== undefined) {
            setActiveStepInvoiceAction(activeStep);
        }
    }, [activeStep])


    const sendEditInvoice = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;


        if (reason === null) {
            WarningAlert("Debe seleccionar el motivo de la edición");
        }
        else {
            if (observation === '') {
                WarningAlert("Debe digitar la observación de la nota");
            }
            else {
                let typeReason = reason.value.split(":")[1];
                let idReason = reason.value.split(":")[0];
                if (objInvoice != null) {

                    for (i === 0; i < objInvoice.clientInfo.adclientedireccion.length; i++) {
                        if (objInvoice.clientInfo.adclientedireccion[i].id === objInvoice.addressid) {
                            address = {
                                nombmun: objInvoice.clientInfo.adclientedireccion[i].ciudaddescripcion,
                                nombpais: objInvoice.clientInfo.adclientedireccion[i].departamentodescripcion,
                                id: objInvoice.clientInfo.adclientedireccion[i].id,
                                direccion: objInvoice.clientInfo.adclientedireccion[i].direccion,
                                descripcion: objInvoice.clientInfo.adclientedireccion[i].descripcion,
                                Clienteid: objInvoice.clientInfo.adclientedireccion[i].clienteid,
                                Ciudadid: objInvoice.clientInfo.adclientedireccion[i].ciudadid,
                                Idstate: objInvoice.clientInfo.adclientedireccion[i].idstate,
                                Createdby: objInvoice.clientInfo.adclientedireccion[i].createdby,
                                Modifiedby: objInvoice.clientInfo.adclientedireccion[i].modifiedby,
                                Idbusinessunit: objInvoice.clientInfo.adclientedireccion[i].idowner,
                                Idowner: objInvoice.clientInfo.adclientedireccion[i].idowner,
                                Principal: objInvoice.clientInfo.adclientedireccion[i].principal
                            }
                        }
                    }

                    if (address !== {} && objInvoice.dirprincipal.length > 0) {
                        address = {
                            nombmun: objInvoice.dirprincipal[0].ciudaddescripcion,
                            nombpais: objInvoice.dirprincipal[0].departamentodescripcion,
                            id: objInvoice.dirprincipal[0].id,
                            direccion: objInvoice.dirprincipal[0].direccion,
                            descripcion: objInvoice.dirprincipal[0].descripcion,
                            Clienteid: objInvoice.dirprincipal[0].clienteid,
                            Ciudadid: objInvoice.dirprincipal[0].ciudadid,
                            Idstate: objInvoice.dirprincipal[0].idstate,
                            Createdby: objInvoice.dirprincipal[0].createdby,
                            Modifiedby: objInvoice.dirprincipal[0].modifiedby,
                            Idbusinessunit: objInvoice.dirprincipal[0].idowner,
                            Idowner: objInvoice.dirprincipal[0].idowner,
                            Principal: objInvoice.dirprincipal[0].principal
                        }
                    }
                    for (j === 0; j < objInvoice.clientInfo.adcontactos.length; j++) {

                        if (objInvoice.clientInfo.adcontactos[j].id === objInvoice.contactid) {

                            contact = {
                                id: objInvoice.clientInfo.adcontactos[j].id,
                                nombre: objInvoice.clientInfo.adcontactos[j].nombre,
                                telefono: objInvoice.clientInfo.adcontactos[j].telefono,
                                celular: objInvoice.clientInfo.adcontactos[j].celular,
                                email: objInvoice.clientInfo.adcontactos[j].email,
                                clienteid: objInvoice.clientInfo.adcontactos[j].clienteid,
                                idstate: objInvoice.clientInfo.adcontactos[j].idstate,
                                createdby: objInvoice.clientInfo.adcontactos[j].createdby,
                                modifiedby: objInvoice.clientInfo.adcontactos[j].modifiedby,
                                idbusinessunit: objInvoice.clientInfo.adcontactos[j].idbusinessunit,
                                idowner: objInvoice.clientInfo.adcontactos[j].idowner
                            }
                        }
                    }

                    if (reason === null) {
                        WarningAlert("Debe seleccionar el motivo de la edición")
                    }
                    else {
                        const setPrincipal = (confirmed) => {

                            totalinvoice.detalleDocumento.forEach(element => {
                                sumDiscounts = sumDiscounts + element.valordescuento;
                                products.push({
                                    Codigo: element.codigo,
                                    Descripcion: element.descripcion,
                                    Cantidad: element.cantidad,
                                    Valorunitario: element.valorNeto,
                                    Porcentajedescuento: element.porcentajedescuento,
                                    Valordescuento: element.valordescuento,
                                    Id: 0,
                                    Dianunidadmedidaid: element.dianunidadmedidaid,
                                    Articuloid: element.articuloid,
                                    Totaliva: element.totaliva,
                                    Totalinc: element.totalinc,
                                    Idstate: 0,
                                    Createdby: 1,
                                    Createdon: '1900-01-01',
                                    Modifiedby: 1,
                                    Modifiedon: '1900-01-01',
                                    Idbusinessunit: 1,
                                    Idowner: 1,
                                    Subtotal: element.subtotal,
                                    Tipodescuento: element.tipodescuento,
                                    Total: element.total,
                                    Totaldescuento: element.totaldescuento,
                                    Valordescuentocomercial: element.valordescuentocomercial,
                                    Prorrateo: element.prorrateo,
                                    Totaldescuentoreal: element.totaldescuentoreal,
                                    Subtotalreal: element.subtotalreal,
                                    Totalivareal: element.totalivareal,
                                    Totalincreal: element.totalincreal,
                                    Totalreal: element.totalreal
                                })
                            });

                            totalinvoice.cargos.forEach(charge => {
                                charges.push({
                                    Idstate: 0,
                                    Createdby: 1,
                                    Createdon: '1900-01-01',
                                    Modifiedby: 1,
                                    Modifiedon: '1900-01-01',
                                    Idbusinessunit: 1,
                                    Idowner: 1,
                                    documentoid: 0,
                                    Cargoid: charge.id,
                                    Descripcion: charge.descripcion,
                                    Tipocargo: charge.tipocargo,
                                    Valor: charge.valor,
                                    Esglobal: false, //no definido
                                    Cantidad: charge.cantidad,
                                    Valorcalculado: charge.valorcalculado,
                                    Valorasumar: charge.valorasumar,
                                    Identificador: charge.identificador
                                })
                            });

                            console.log('datos entrada' + JSON.stringify(totalinvoice.cargos))

                            totalinvoice.otrosdescuentos.forEach(discount => {
                                discounts.push({
                                    Idstate: 1,
                                    Createdby: 1,
                                    Createdon: '1900-01-01',
                                    Modifiedby: 1,
                                    Modifiedon: '1900-01-01',
                                    Idbusinessunit: 1,
                                    Idowner: 1,
                                    documentoid: 0,
                                    Descuentoid: discount.id,
                                    descripcion: discount.descripcion,
                                    motivoid: discount.motivoid,
                                    modelodescuento: discount.modelodescuento,
                                    valor: discount.valor,
                                    tipodescuento: discount.tipodescuento,
                                    valoradescontar: discount.valoradescontar,
                                    motivodescuento: discount.motivoDescripcion,
                                    identificador: discount.identificador
                                })
                            });

                            if (confirmed) {
                                setLoading(true);
                                setFormSave({
                                    Opdocumento: {

                                        fecha: objInvoice.dateInvoice,
                                        numeracionid: objInvoice.resolutionid,
                                        estadodocumentoid: 0,
                                        tipoaceptaciondocumentoid: 1,
                                        tipoacusedocumentoid: 3,
                                        valorapagar: totalinvoice.totalapagar,
                                        dianformapagoid: objInvoice.visibleCredit === true ? 2 : 1,
                                        valordescuento: sumDiscounts,
                                        valorcargos: 0,
                                        subtotal: totalinvoice.valorbruto,
                                        valortotal: totalinvoice.totalfactura,
                                        valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                                        valorcargodocumento: totalinvoice.valorcargos,
                                        valoranticipo: 0,
                                        valorinc: totalinvoice.valorinc,
                                        valoriva: totalinvoice.valoriva,
                                        textoobservacion: observation,
                                        vendedorid: objInvoice.vendorid,
                                        diantipodocumentoelectronicoid: typeReason === "ND" ? 2 : 3,
                                        clienteid: objInvoice.clientInfo.id,
                                        dianmediopagoid: objInvoice.paymethodid,
                                        idstate: 0,
                                        createdby: 1,
                                        modifiedby: 1,
                                        idbusinessunit: 1,
                                        idowner: 1,
                                        origendocumentoid: 1,
                                        fechapago: objInvoice.datePay,
                                        favorito: star,
                                        empresaid: Empresaid,
                                        Incluirvendedor: objInvoice.includeVendor,
                                        Dianconceptonotacreditoid: typeReason === "NC" ? idReason : 0,
                                        Dianconceptonotadebitoid: typeReason === "ND" ? idReason : 0,
                                        documentoreferenciaid: objInvoice.documentId,
                                        documentoref: objInvoice.numeroFactura,
                                        Valordescuentoreal: totalinvoice.valordescuentoreal,
                                        Valorivareal: totalinvoice.valorivareal,
                                        Valorincreal: totalinvoice.valorincreal,
                                        TotalfacturaReal: totalinvoice.totalfacturaReal,
                                        Vendedor: {
                                            Id: objInvoice.Vendor.id,
                                            Nombres: objInvoice.Vendor.nombres,
                                            Idstate: objInvoice.Vendor.idstate,
                                            Createdby: objInvoice.Vendor.createdby,
                                            Modifiedby: objInvoice.Vendor.modifiedby,
                                            Idbusinessunit: objInvoice.Vendor.idbusinessunit,
                                            Idowner: objInvoice.Vendor.idowner,
                                            Empresaid: objInvoice.Vendor.empresaid,
                                            Telefono: objInvoice.Vendor.telefono,
                                            Email: objInvoice.Vendor.email
                                        },
                                        Cliente: {
                                            Id: objInvoice.clientInfo.id,
                                            Documento: objInvoice.clientInfo.documento,
                                            Documentodv: objInvoice.clientInfo.documentodv,
                                            Razonsocial: objInvoice.clientInfo.razonsocial,
                                            Telefono: objInvoice.clientInfo.telefono,
                                            Correoelectronico: objInvoice.clientInfo.correoelectronico,
                                            Diantipodocumentoidentidadid: objInvoice.clientInfo.diantipodocumentoidentidadid,
                                            Diantipopersonaid: objInvoice.clientInfo.diantipopersonaid,
                                            Empresaid: objInvoice.clientInfo.Empresaid,
                                            Favorito: objInvoice.clientInfo.Favorito,
                                            Idstate: objInvoice.clientInfo.Idstate,
                                            createdby: objInvoice.clientInfo.createdby,
                                            modifiedby: objInvoice.clientInfo.modifiedby,
                                            idbusinessunit: objInvoice.clientInfo.idbusinessunit,
                                            idowner: objInvoice.clientInfo.idbusinessunit,
                                            Clientedireccion: {
                                                Id: address.id,
                                                Descripcion: address.descripcion,
                                                Clienteid: address.clienteid,
                                                Ciudadid: address.ciudadid,
                                                Idstate: address.idstate,
                                                Createdby: address.createdby,
                                                Modifiedby: address.modifiedby,
                                                Idbusinessunit: address.idowner,
                                                Idowner: address.idowner,
                                                Direccion: address.direccion,
                                                Principal: address.principal
                                            },
                                            Contactos: {
                                                Id: contact.id,
                                                Nombre: contact.nombre,
                                                Telefono: contact.telefono,
                                                Celular: contact.celular,
                                                Email: contact.email,
                                                Clienteid: contact.clienteid,
                                                Idstate: contact.idstate,
                                                Createdby: contact.createdby,
                                                Modifiedby: contact.modifiedby,
                                                Idbusinessunit: contact.idbusinessunit,
                                                Idowner: contact.idowner
                                            }
                                        },
                                        Numeracion: {
                                            Id: objInvoice.resolutionid,
                                            Resolucion: objInvoice.Resolution.resolucion,
                                            Fechainicio: objInvoice.Resolution.fechainicio,
                                            Fechafinal: objInvoice.Resolution.fechafinal,
                                            Prefijo: objInvoice.Resolution.prefijo,
                                            Consecinicial: objInvoice.Resolution.consecinicial,
                                            Consecfinal: objInvoice.Resolution.consecfinal,
                                            Consecactual: objInvoice.Resolution.consecactual,
                                            Empresaid: objInvoice.Resolution.empresaid,
                                            Diantipomodalidadid: objInvoice.Resolution.diantipomodalidadid,
                                            Sucursalempresaid: objInvoice.Resolution.ducursalempresaid,
                                            Idstate: objInvoice.Resolution.idstate,
                                            Createdby: objInvoice.Resolution.createdby,
                                            Modifiedby: objInvoice.Resolution.modifiedby,
                                            Idbusinessunit: objInvoice.Resolution.idbusinessunit,
                                            Idowner: objInvoice.Resolution.idowner,
                                            Nombre: objInvoice.Resolution.nombre
                                        }
                                    },
                                    LstOpdetalledocumento: products,
                                    LstCargos: charges,
                                    LstDescuentos: discounts
                                });
                            }
                        }

                        if (totalinvoice.detalleDocumento.length === 0) {
                            WarningAlert("Debe seleccionar al menos un producto");
                        }
                        else {

                            if (totalinvoice.totalapagar < 0) {
                                WarningAlert("El valor a pagar de la factura no puede ser negativo");
                            }
                            else {
                                let validacargos = false;
                                if (totalinvoice.valorcargos > 0) {
                                    let valorbolsas = 0;
                                    totalinvoice.cargos.forEach(charge => {
                                        if (charge.descripcion === 'Bolsas') {
                                            valorbolsas = valorbolsas + charge.valorcalculado
                                        }

                                    });
                                    //console.log('valor bolsas'+ valorbolsas);

                                    if ((totalinvoice.valorbruto - totalinvoice.valorOtrosDescuentos - totalinvoice.valordescuentoreal) < totalinvoice.valorcargos - valorbolsas) {
                                        WarningAlert("El valor de los cargos no puede ser superior a la factura con descuentos");
                                    }
                                    else {
                                        validacargos = true;
                                    }
                                } else {
                                    validacargos = true;
                                }

                                if (validacargos) {
                                    ConfirmAlert('Oh...', '¿Al editar la factura se generará una Nota ' + (typeReason === 'ND' ? 'Debito' : 'Crédito') + ' ¿Esta seguro que desea hacerlo?', setPrincipal);
                                }
                            }
                        }

                    }

                    //console.log('contacto'+ JSON.stringify(contact))


                }
            }
        }


    }

    const sendPreview = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;

        if (reason === null) {
            WarningAlert("Debe seleccionar el motivo de la edición");
        }
        else {
            if (observation === '') {
                WarningAlert("Debe digitar la observación de la nota");
            }
            else {
                let typeReason = reason.value.split(":")[1];
                let idReason = reason.value.split(":")[0];

                if (objInvoice != null) {

                    for (i === 0; i < objInvoice.clientInfo.adclientedireccion.length; i++) {
                        if (objInvoice.clientInfo.adclientedireccion[i].id === objInvoice.addressid) {
                            address = {
                                nombmun: objInvoice.clientInfo.adclientedireccion[i].ciudaddescripcion,
                                nombpais: objInvoice.clientInfo.adclientedireccion[i].departamentodescripcion,
                                id: objInvoice.clientInfo.adclientedireccion[i].id,
                                direccion: objInvoice.clientInfo.adclientedireccion[i].direccion,
                                descripcion: objInvoice.clientInfo.adclientedireccion[i].descripcion,
                                Clienteid: objInvoice.clientInfo.adclientedireccion[i].clienteid,
                                Ciudadid: objInvoice.clientInfo.adclientedireccion[i].ciudadid,
                                Idstate: objInvoice.clientInfo.adclientedireccion[i].idstate,
                                Createdby: objInvoice.clientInfo.adclientedireccion[i].createdby,
                                Modifiedby: objInvoice.clientInfo.adclientedireccion[i].modifiedby,
                                Idbusinessunit: objInvoice.clientInfo.adclientedireccion[i].idowner,
                                Idowner: objInvoice.clientInfo.adclientedireccion[i].idowner,
                                Principal: objInvoice.clientInfo.adclientedireccion[i].principal
                            }
                        }
                    }

                    if (address !== {}) {
                        address = {
                            nombmun: objInvoice.dirprincipal[0].ciudaddescripcion,
                            nombpais: objInvoice.dirprincipal[0].departamentodescripcion,
                            id: objInvoice.dirprincipal[0].id,
                            direccion: objInvoice.dirprincipal[0].direccion,
                            descripcion: objInvoice.dirprincipal[0].descripcion,
                            Clienteid: objInvoice.dirprincipal[0].clienteid,
                            Ciudadid: objInvoice.dirprincipal[0].ciudadid,
                            Idstate: objInvoice.dirprincipal[0].idstate,
                            Createdby: objInvoice.dirprincipal[0].createdby,
                            Modifiedby: objInvoice.dirprincipal[0].modifiedby,
                            Idbusinessunit: objInvoice.dirprincipal[0].idowner,
                            Idowner: objInvoice.dirprincipal[0].idowner,
                            Principal: objInvoice.dirprincipal[0].principal
                        }
                    }
                    for (j === 0; j < objInvoice.clientInfo.adcontactos.length; j++) {

                        if (objInvoice.clientInfo.adcontactos[j].id === objInvoice.contactid) {

                            contact = {
                                id: objInvoice.clientInfo.adcontactos[j].id,
                                nombre: objInvoice.clientInfo.adcontactos[j].nombre,
                                telefono: objInvoice.clientInfo.adcontactos[j].telefono,
                                celular: objInvoice.clientInfo.adcontactos[j].celular,
                                email: objInvoice.clientInfo.adcontactos[j].email,
                                clienteid: objInvoice.clientInfo.adcontactos[j].clienteid,
                                idstate: objInvoice.clientInfo.adcontactos[j].idstate,
                                createdby: objInvoice.clientInfo.adcontactos[j].createdby,
                                modifiedby: objInvoice.clientInfo.adcontactos[j].modifiedby,
                                idbusinessunit: objInvoice.clientInfo.adcontactos[j].idbusinessunit,
                                idowner: objInvoice.clientInfo.adcontactos[j].idowner
                            }
                        }
                    }


                    totalinvoice.detalleDocumento.forEach(element => {
                        sumDiscounts = sumDiscounts + element.valordescuento;
                        products.push({
                            Codigo: element.codigo,
                            Descripcion: element.descripcion,
                            Cantidad: element.cantidad,
                            Valorunitario: element.valorNeto,
                            Porcentajedescuento: element.porcentajedescuento,
                            Valordescuento: element.valordescuento,
                            Id: 0,
                            Dianunidadmedidaid: element.dianunidadmedidaid,
                            Articuloid: element.articuloid,
                            Totaliva: element.totaliva,
                            Totalinc: element.totalinc,
                            Idstate: 0,
                            Createdby: 1,
                            Createdon: '1900-01-01',
                            Modifiedby: 1,
                            Modifiedon: '1900-01-01',
                            Idbusinessunit: 1,
                            Idowner: 1,
                            Subtotal: element.subtotal,
                            Tipodescuento: element.tipodescuento,
                            Total: element.total,
                            Totaldescuento: element.totaldescuento,
                            Valordescuentocomercial: element.valordescuentocomercial,
                            Prorrateo: element.prorrateo,
                            Totaldescuentoreal: element.totaldescuentoreal,
                            Subtotalreal: element.subtotalreal,
                            Totalivareal: element.totalivareal,
                            Totalincreal: element.totalincreal,
                            Totalreal: element.totalreal
                        })
                    });

                    totalinvoice.cargos.forEach(charge => {
                        charges.push({
                            Idstate: 0,
                            Createdby: 1,
                            Createdon: '1900-01-01',
                            Modifiedby: 1,
                            Modifiedon: '1900-01-01',
                            Idbusinessunit: 1,
                            Idowner: 1,
                            documentoid: 0,
                            Cargoid: charge.id,
                            Descripcion: charge.descripcion,
                            Tipocargo: charge.tipocargo,
                            Valor: charge.valor,
                            Esglobal: false, //no definido
                            Cantidad: charge.cantidad,
                            Valorcalculado: charge.valorcalculado,
                            Valorasumar: charge.valorasumar,
                            Identificador: charge.identificador
                        })
                    });

                    totalinvoice.otrosdescuentos.forEach(discount => {
                        discounts.push({
                            Idstate: 1,
                            Createdby: 1,
                            Createdon: '1900-01-01',
                            Modifiedby: 1,
                            Modifiedon: '1900-01-01',
                            Idbusinessunit: 1,
                            Idowner: 1,
                            documentoid: 0,
                            Descuentoid: discount.id,
                            descripcion: discount.descripcion,
                            motivoid: discount.motivoid,
                            modelodescuento: discount.modelodescuento,
                            valor: discount.valor,
                            tipodescuento: discount.tipodescuento,
                            valoradescontar: discount.valoradescontar,
                            motivodescuento: discount.motivoDescripcion,
                            identificador: discount.identificador
                        })
                    });


                    setFormPreview({
                        Opdocumento: {
                            fecha: objInvoice.dateInvoice,
                            numeracionid: objInvoice.resolutionid,
                            estadodocumentoid: 0,
                            tipoaceptaciondocumentoid: 1,
                            tipoacusedocumentoid: 3,
                            valorapagar: totalinvoice.totalapagar,
                            dianformapagoid: objInvoice.visibleCredit === true ? 2 : 1,
                            valordescuento: sumDiscounts,
                            valorcargos: 0,
                            subtotal: totalinvoice.valorbruto,
                            valortotal: totalinvoice.totalfactura,
                            valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                            valorcargodocumento: totalinvoice.valorcargos,
                            valoranticipo: 0,
                            valorinc: totalinvoice.valorinc,
                            valoriva: totalinvoice.valoriva,
                            textoobservacion: observation,
                            vendedorid: objInvoice.vendorid,
                            diantipodocumentoelectronicoid: typeReason === "ND" ? 2 : 3,
                            clienteid: objInvoice.clientInfo.id,
                            dianmediopagoid: objInvoice.paymethodid === null ? 0 : objInvoice.paymethodid,
                            idstate: 0,
                            createdby: 1,
                            modifiedby: 1,
                            idbusinessunit: 1,
                            idowner: 1,
                            origendocumentoid: 1,
                            fechapago: objInvoice.datePay,
                            favorito: star,
                            empresaid: Empresaid,
                            Incluirvendedor: objInvoice.includeVendor,
                            Dianconceptonotacreditoid: typeReason === "NC" ? idReason : 0,
                            Dianconceptonotadebitoid: typeReason === "ND" ? idReason : 0,
                            documentoreferenciaid: objInvoice.documentId,
                            documentoref: objInvoice.numeroFactura,
                            Valordescuentoreal: totalinvoice.valordescuentoreal,
                            Valorivareal: totalinvoice.valorivareal,
                            Valorincreal: totalinvoice.valorincreal,
                            TotalfacturaReal: totalinvoice.totalfacturaReal,
                            Archivovisualizar: 'NOTE',
                            Vendedor: {
                                Id: objInvoice.vendorid.id,
                                Nombres: objInvoice.Vendor.nombres,
                                Idstate: objInvoice.Vendor.idstate,
                                Createdby: objInvoice.Vendor.createdby,
                                Modifiedby: objInvoice.Vendor.modifiedby,
                                Idbusinessunit: objInvoice.Vendor.idbusinessunit,
                                Idowner: objInvoice.Vendor.idowner,
                                Empresaid: objInvoice.Vendor.empresaid,
                                Telefono: objInvoice.Vendor.telefono,
                                Email: objInvoice.Vendor.email
                            },
                            Cliente: {
                                Id: objInvoice.clientInfo.id,
                                Documento: objInvoice.clientInfo.documento,
                                Documentodv: objInvoice.clientInfo.documentodv,
                                Razonsocial: objInvoice.clientInfo.razonsocial,
                                Telefono: objInvoice.clientInfo.telefono,
                                Correoelectronico: objInvoice.clientInfo.correoelectronico,
                                Diantipodocumentoidentidadid: objInvoice.clientInfo.diantipodocumentoidentidadid,
                                Diantipopersonaid: objInvoice.clientInfo.diantipopersonaid,
                                Empresaid: objInvoice.clientInfo.Empresaid,
                                Favorito: objInvoice.clientInfo.Favorito,
                                Idstate: objInvoice.clientInfo.Idstate,
                                createdby: objInvoice.clientInfo.createdby,
                                modifiedby: objInvoice.clientInfo.modifiedby,
                                idbusinessunit: objInvoice.clientInfo.idbusinessunit,
                                idowner: objInvoice.clientInfo.idbusinessunit,
                                Clientedireccion: {
                                    Id: address.id,
                                    Descripcion: address.descripcion,
                                    Clienteid: address.clienteid,
                                    Ciudadid: address.ciudadid,
                                    Idstate: address.idstate,
                                    Createdby: address.createdby,
                                    Modifiedby: address.modifiedby,
                                    Idbusinessunit: address.idowner,
                                    Idowner: address.idowner,
                                    Direccion: address.direccion,
                                    Principal: address.principal
                                },
                                Contactos: {
                                    Id: contact.id,
                                    Nombre: contact.nombre,
                                    Telefono: contact.telefono,
                                    Celular: contact.celular,
                                    Email: contact.email,
                                    Clienteid: contact.clienteid,
                                    Idstate: contact.idstate,
                                    Createdby: contact.createdby,
                                    Modifiedby: contact.modifiedby,
                                    Idbusinessunit: contact.idbusinessunit,
                                    Idowner: contact.idowner
                                }
                            },
                            Numeracion: {
                                Id: objInvoice.resolutionid,
                                Resolucion: objInvoice.Resolution.resolucion,
                                Fechainicio: objInvoice.Resolution.fechainicio,
                                Fechafinal: objInvoice.Resolution.fechafinal,
                                Prefijo: objInvoice.Resolution.prefijo,
                                Consecinicial: objInvoice.Resolution.consecinicial,
                                Consecfinal: objInvoice.Resolution.consecfinal,
                                Consecactual: objInvoice.Resolution.consecactual,
                                Empresaid: objInvoice.Resolution.empresaid,
                                Diantipomodalidadid: objInvoice.Resolution.diantipomodalidadid,
                                Sucursalempresaid: objInvoice.Resolution.sucursalempresaid,
                                Idstate: objInvoice.Resolution.idstate,
                                Createdby: objInvoice.Resolution.createdby,
                                Modifiedby: objInvoice.Resolution.modifiedby,
                                Idbusinessunit: objInvoice.Resolution.idbusinessunit,
                                Idowner: objInvoice.Resolution.idowner,
                                Nombre: objInvoice.Resolution.nombre
                            }
                        },
                        LstOpdetalledocumento: products,
                        LstCargos: charges,
                        LstDescuentos: discounts
                    });
                    setOpenModalFile(true);
                }
            }
        }



    }

    const confirmSendOk = (response) => {

        setLoading(false);
        if (response.data.statusCode == "201"){
            setModalResponse({
                type: 'success',
                title: 'Buen trabajo!!!',
                subtitle: 'Has generado una nueva nota',
                body: (<div><div></div>
                    <div class="fenomenalWhiteLittle">Actualizaste la factura <span className="fenomenalBlueLittle">No. {objInvoice.numeroFactura}</span> con la Nota No. <span className="fenomenalBlueLittle">{response.data.result}</span> {response.data.statusMessage.indexOf('Rechazo') !== -1 ? response.data.statusMessage : 'de manera exitosa, sigamos facturando'}</div></div>),
                modalImage: 'quotation',
                open: true,
                closeElement: 'Saltar',
                onCloseElement: 1
            });
        }
        else{
            setModalResponse({
                type: 'warning',
                title: 'Oops!!!',
                subtitle: 'Ocurrió algo inesperado',
                body: (<div><div></div>
                    <div class="fenomenalWhiteLittle">Actualizaste la factura <span className="fenomenalBlueLittle">No. {objInvoice.numeroFactura}</span> con la Nota No. <span className="fenomenalBlueLittle">{response.data.result}</span> {response.data.statusMessage}</div></div>),
                modalImage: 'warning',
                open: true,
                closeElement: 'Saltar',
                onCloseElement: 1
            });
        }

        
        

    }

    const confirmError = (response) => {

        setLoading(false);
        setModalResponse({
            type: 'warning',
            title: 'Oops!!!',
            subtitle: 'Ocurrió algo inesperado',
            body: (<div><div>
                {JSON.stringify(response === undefined ? 'Error inesperado' : response.data.statusMessage)}
            </div></div>),
            modalImage: 'warning',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 0
        });
        //WarningAlert("Error al guardar la factura!!" + JSON.stringify(response.message === undefined ? response.StatusMessage : response.message));
    }
    const handleResponseModal = (rta) => {
        if (rta === 1) {
            //localStorage.clear('itemsInCart');  
            localStorage.removeItem('itemsInCart');
            props.setStatusShowCartAction(false);

            props.clearLoadedChargeInvoiceActionAll();
            props.clearLoadedDiscountInvoiceActionAll();

            history.push({
                pathname: '/document',
                state: {
                    redirect: 1
                }
            });
        }
        setModalResponse({
            type: '',
            title: '',
            subtitle: '',
            body: '',
            modalImage: '',
            open: false,
            closeElement: '',
            onCloseElement: ''
        });

    }

    function isStepComplete(step) {
        return completed.has(step);
    }

    const handleFavourite = (action) => {
        setStar(action);
    }

    const handleStep = (step) => () => {

        //alert(step);
        let newCompleted;
        let i = 0;
        if (step === 0) {
            newCompleted = new Set();
            setCompleted(newCompleted);
            setActiveStep(step);
        }
        else {
            //alert(step);
            if (step === 2) {
                if (reason === null) {
                    WarningAlert("Debe seleccionar el motivo de la edición");
                }
                else {
                    if (observation === '') {
                        WarningAlert("Debe digitar la observación de la nota");
                    }
                    else {
                        newCompleted = new Set();
                        for (i === 0; i < step; i++) {
                            newCompleted.add(i);
                        }
                        setCompleted(newCompleted);
                        setActiveStep(step);
                    }

                }
            }
            if (step === 1) {
                newCompleted = new Set();
                for (i === 0; i < step; i++) {
                    newCompleted.add(i);
                }
                setCompleted(newCompleted);
                setActiveStep(step);
            }

        }
    };

    const cancelCreate = () => {
        setActiveStep(0);
        //setObjInvoice(null);
        HandleBack();
    }

    const handleItemState = (state) => {
        //console.log('Estado-->'+state);
        setFootShow(state);
    }

    const handleReason = (reason) => {
        setReason(reason);
    }
    const handleObservations = (value) => {
        setObservations(value);
    }

    const handleCloseModalFile = () => {
        setOpenModalFile(false);
    };

    useEffect(() => {
        if (formSave !== null) {
            //console.log('response note-->'+JSON.stringify(formSave))
            props.createNote(formSave, confirmSendOk, confirmError);

        }
    }, [formSave]);

    useEffect(() => {
        if (loading) {
            setModalResponse({
                type: 'success',
                title: '',
                subtitle: 'Generando documento, por favor espere...',
                body: (<div><div>
                    <Fragment>  <CustomProgress />{'Procesando'} </Fragment>
                </div></div>),
                modalImage: 'noimagen',
                open: true,
                closeElement: '',
                onCloseElement: 0
            });


        }
    }, [loading]);

    const footPage = (step) => {
        return (
            <div className='bottomButtonsInvoiceRevision'>
                <Grid container >
                    <Grid item lg={2} md={2} sm={2}>
                        <div className={activeStep === 2 ? 'backInvoice' : 'panelInvisibleInvoice'} onClick={handleStep(step)} ><ArrowBackIosIcon></ArrowBackIosIcon>
                    Volver</div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div >
                            <div style={{ display: star === true ? 'block' : 'none' }}>
                                <StarIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(false)} ></StarIcon>
                            </div>
                            <div style={{ display: star === false ? 'block' : 'none' }}>
                                <StarBorderIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(true)} ></StarBorderIcon>
                            </div>
                            <div className="textButtonsInvoice">Destacar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div title="Previsualizar PDF">
                            <VisibilityIcon className="buttonInvoiceBehavior" onClick={() => sendPreview()}></VisibilityIcon><br></br>
                            <div className="textButtonsInvoice">Previsualizar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <ClearIcon className="buttonInvoiceBehavior" onClick={() => handleResponseModal(1)}></ClearIcon><br></br>
                        <div className="textButtonsInvoice">Cancelar</div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1}>

                    </Grid>
                </Grid>
            </div>
        )
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (<div></div>);
            case 1:
                return (<div className="panelColorInvoice">
                    <InvoiceItem HandleItemState={handleItemState}
                        Edit={true} HandleReason={handleReason}
                        HandleObservation={handleObservations}
                        Reason={reason}
                        Observation={observation}>
                    </InvoiceItem>
                    <div>
                        <div className={footShow ? 'panelVisibleInvoice' : 'panelInvisibleInvoice'}>
                            <Grid container>
                                <Grid item lg={3} xs={12}>

                                </Grid>
                                <Grid item lg={1} md={1} sm={12} xs={12}>
                                </Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <button
                                        className="buttonInvoiceConfirm"
                                        type="button"
                                        onClick={handleStep(2)}
                                        disabled={isValidDocument === true ? false : true}>
                                        Revisar factura
                                    </button>
                                </Grid>
                                <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <button
                                        className="buttonInvoiceConfirm"
                                        type="button"
                                        onClick={() => sendEditInvoice()}
                                        disabled={isValidDocument === true ? false : true}>
                                        Enviar Factura
                                    </button>
                                </Grid>
                                <Grid item lg={1} md={1} sm={12} xs={12}>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="spacingInvoice"></div>
                    {footPage(0)}
                </div>);
            case 2:
                return (<div className="panelColorInvoice">
                    <InvoiceRevision ObjId={0}
                        heightDiv={heightDiv}
                        objHead={objInvoice}
                        objItems={totalinvoice}
                        CancelCreate={cancelCreate}
                        Empresaid={Empresaid}
                        Userid={Userid}
                        Reason={reason}
                        ObservationEdit={observation}>
                    </InvoiceRevision>
                    <div className="spacingInvoice"></div>
                    <Grid container>
                        <Grid item lg={4} md={4} sm={12} xs={12}>

                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <button
                                className="buttonInvoiceConfirm"
                                type="button"
                                onClick={() => sendEditInvoice()}
                                disabled={isValidDocument === true ? false : true}>
                                Enviar Factura
                                </button>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>

                        </Grid>
                    </Grid>
                    <div className="spacingInvoice"></div>
                    {footPage(1)}
                </div>);
            default:
                return 'Unknown step';
        }
    }

    return (
        <div className="container-form" xs={12} lg={12}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={< QontoConnector />} className={classes.root} >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    return (
                        <Step key={label} {...stepProps} className={activeStep === index ? classes.activeButton : ''}>
                            <StepButton
                                //onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                {...buttonProps}  
                            >
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    <label className="stepper-title">{label}</label>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                <div>{getStepContent(activeStep)}</div>
            </div>
            <ResponseModal
                modalType={modalResponse.modalType}
                title={modalResponse.title}
                subtitle={modalResponse.subtitle}
                body={modalResponse.body}
                modalImage={modalResponse.modalImage}
                open={modalResponse.open}
                closeElement={modalResponse.closeElement}
                onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
            ></ResponseModal>
            <FileModalData
                open={openModalFile}
                onCloseElement={handleCloseModalFile}
                closeElement={"Cerrar"}
                paramFile={formPreview}
            >
            </FileModalData>
        </div>
    );
}

InvoiceEdit.propTypes = {
    invoiceReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    invoiceReducer: state.invoiceReducer,
    sucessupdate: state.invoiceReducer.sucess,
    totalinvoice: state.valuesInvoiceReducer.totalDetail,
    isValidDocument: state.valuesInvoiceReducer.isValidDocument
});

export default connect(mapStateToProps, {
    updateDocument, 
    getTotalAction, 
    setStatusShowCartAction, 
    createNote, 
    clearLoadedChargeInvoiceActionAll, 
    clearLoadedDiscountInvoiceActionAll,
    setActiveStepInvoiceAction
})(InvoiceEdit);
