import React from "react"
import PropTypes from "prop-types"
import { InputAdornment } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';


import CustomTooltip from '../tooltip/tooltip.component'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
      
    },
    endAdornment:{
        color: theme.palette.primaryColor
    }
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

function TextUUIDMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, '-', /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, '-', /[1-5]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, '-', /[89ab]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, '-', /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/, /[a-f0-9]/]}
            placeholderChar={'\u2000'}
            showMask
        /> 
    );

}

const CustomInput = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.thirdColor, //azul
        },
        '& label.Mui-disabled': {
            color: '#FFFF', //gris
        },
        '& label.MuiFormLabel-filled:not(.Mui-focused)': {
            color: 'gray', //gris
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#16B1F3', //verde
        },
        '& .MuiInputBase-input': {
            color: '#FFFF'
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:focus': {
            borderBottomColor: 'gray', //gris
        },


        '& .MuiOutlinedInput-root': {

            '&.MuiInputLabel-outlined': {
                color: 'white',
            },
            '& fieldset': {
                border: '2px solid',
                borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
            },
            '&:hover fieldset': {
                borderColor: 'gray',
                color: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.thirdColor, //azul
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray', //gris
            },
        },

    },
}))(TextField);

const Input = ({ ...props }) => {
    const { tooltip, id, onChange, value, label, disabled, validator, validateOptions, hidden, onKeyPress, onBlur, maxLength, nameValidator, variant, multiline, format, name, size, max, textFormat, porcentage,rowsLine,placeholder} = props;
    const classes = useStyles();

    let errorValidator = validator
        ? validator.message(
            !!nameValidator ? nameValidator : label,
            value,
            validateOptions,
            // nameValidator
        )
        : "";
    if (!!hidden) return null
    
    return (
        
        <div className={classes.root} >
            <CustomInput
                className={classes.margin}
                rows={rowsLine=== true ? 5 : 0}
                fullWidth
                disabled={disabled === true ? true : false}
                multiline={multiline ? true : false}
                label={label}
                variant={!!variant ? variant : "outlined"}
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                error={!!errorValidator ? true : false}
                helperText={errorValidator}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
                size={size}
                inputProps={{ maxLength: maxLength, max: max }}
                autoComplete='off'
                placeholder={placeholder}
                InputProps={format === true ?
                    {
                        inputComponent: NumberFormatCustom,
                    } : {
                        inputComponent: format === 'UUID' ? TextUUIDMaskCustom : 'input',
                        endAdornment: !!tooltip ?
                            <InputAdornment position="end">
                                {porcentage === true ?
                                    <span className={classes.endAdornment}>%</span> :
                            <CustomTooltip message={tooltip} />

                            }
                            </InputAdornment> : ''
                        ,
                    }
                }
 

            />
        </div>

    );
}


Input.propTypes = {
    id: PropTypes.string,
}

export default Input
