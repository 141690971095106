import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getRoleOfLoggedUser } from '../../../actions/authActions';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.thirdBackgroundColor,
    color: theme.palette.primaryColor,
  },
  avatar: {
  },
  header: {
    color: theme.palette.primaryColor,
  },
  title: {
    color: theme.palette.primaryColor,
    fontWeight: 'bold',
  },
  subheader: {
    color: theme.palette.primaryColor
  },
  icon: {
    color: theme.palette.secundaryColor
  },
  iconAction: {
    color: theme.palette.primaryColor
  }
}));

export default function ChargeCard(props) {
  const { data, onLoad, onClick } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header}
        avatar={
          <FiberManualRecordIcon fontSize="large" style={{ color: data.color }} />
        }
        action={
          <Fragment>
            {
              (getRoleOfLoggedUser())
                ? <div>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton aria-label="Editar">
                      <CreateIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton aria-label="Eliminar">
                      <DeleteIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                </div>
                : <div>
                  <Tooltip title="Editar">
                    <IconButton aria-label="Editar" onClick={onLoad}>
                      <CreateIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton aria-label="Eliminar" onClick={onClick}>
                      <DeleteIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                </div>
            }

          </Fragment>
        }
        classes={{
          title: classes.title,
        }}
        title={
          data.nombre
        }
      />
    </Card>
  );
}