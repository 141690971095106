import React, { Component } from "react";
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import SimpleReactValidator from "simple-react-validator";
import InputForm from "../../components/input/input.component";
import SelectForm from '../../components/select/select.component';
import { SuccessAlert } from '../../helpers/alert.helpers';
import { withStyles } from "@material-ui/core/styles";
import ToggleButtons from '../../components/toggle/toggle.component';
import ButtonPrimary from '../../components/button/buttonPrimary.component';
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import {
  postDiscount,
  putDiscount,
  getDiscounts,
  changeEditingDiscountStatus,
  getReasonDiscount,
  clearCurrentDiscount,
  clearUpdateDiscount,
  clearCreateDiscount,
} from './../../actions/discountAction'
import { regexDecimal } from '../../helpers/customRegex.hepers';
import TooltipMessage from "../../components/tooltip/tootltip-message.component";

const useStyles = (theme) => ({
  paddingTop: {
    paddingTop: 200
  }
});
class NewDiscount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        toogleDiscountModel: '1',
        toogleDiscountType: '1',
        txtDiscountDescription: '',
        txtDiscountValue: 0,
        ddlDiscountReason: '',
      },
      createDiscount: {},
      updateDiscount: {},
      currentDiscount: {},
      reasonDiscount: [],
      editingDiscountData: false,
      enterpriseId: 6,
      alterForm: {
        createdby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        createdon: new Date(),
        empresaid: JSON.parse(localStorage.getItem('dataCompany')).companyId,
        esglobal: false,
        id: 0,
        idbusinessunit: 0,
        idowner: 0,
        idstate: 0,
        modifiedby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        modifiedon: new Date(),
      },
      loadingSave: false,
      components: [],
      btnSaveDiscount: '',
      txtModelDiscount: '',
      txtTypeDiscount: '',
      txtReasonDiscount: '',
      txtNameDiscount: '',
      txtValueDiscount: '',
      recordSuccessHeader: '',
      recordSuccessBody: '',
      updateSuccessHeader: '',
      updateSuccessBody: '',
      tooltipModelDiscountHeader: '',
      tooltipModelDiscountBody: '',
      tooltipModelDiscountBotton: '',
      tooltipModelDiscountUrl: '',
      tooltipTypeDiscountHeader: '',
      tooltipTypeDiscountBody: '',
      tooltipTypeDiscountBotton: '',
      tooltipTypeDiscountUrl: '',
    }

    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.discountReducer.createDiscount) {
      if (nextProps.discountReducer.createDiscount.statusCode === '201') {
        update.createDiscount = nextProps.discountReducer.createDiscount;
      }
    }

    if (!!nextProps.discountReducer.updateDiscount) {
      if (nextProps.discountReducer.updateDiscount.statusCode === '201') {
        update.updateDiscount = nextProps.discountReducer.updateDiscount;
      }
    }

    if (!!nextProps.discountReducer.reasonDiscount) {
      if (nextProps.discountReducer.reasonDiscount.statusCode === '200') {
        update.reasonDiscount = nextProps.discountReducer.reasonDiscount.result;
      }
    }

    if (nextProps.discountReducer.currentDiscount !== state.currentDiscount) {
      update.currentDiscount = nextProps.discountReducer.currentDiscount;
      update.editingDiscountData = nextProps.discountReducer.editingDiscountData;
    }

    if (nextProps.discountReducer.loadingSave !== state.loadingSave) {
      update.loadingSave = nextProps.discountReducer.loadingSave;
    }

    if (!!nextProps.configReducer.components !== state.components) {
      update.components = nextProps.configReducer.components;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getReasonDiscount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.createDiscount !== this.state.createDiscount) {
      if (this.state.createDiscount.statusCode === '201') {
        SuccessAlert(this.state.recordSuccessHeader, this.state.recordSuccessBody);
        this.cleanData();
      }
    }

    if (prevState.currentDiscount !== this.state.currentDiscount) {
      if (!!this.state.currentDiscount)
        this.loadInfo(this.state.currentDiscount);
    }

    if (prevState.updateDiscount !== this.state.updateDiscount) {
      if (this.state.updateDiscount.statusCode === '201') {
        SuccessAlert(this.state.updateSuccessHeader, this.state.updateSuccessBody);
        this.cleanData();
        this.props.changeEditingDiscountStatus(false);
      }
    }

    if (prevState.components !== this.state.components) {
      if (!!this.state.components && this.state.components.length > 0) {
        this.assignConfigData();
      }
    }
  }

  assignConfigData() {
    const btnSaveDiscountConst = this.filterConfigInfo(54);
    const txtModelDiscountConst = this.filterConfigInfo(55);
    const txtTypeDiscountConst = this.filterConfigInfo(56);
    const txtReasonDiscountConst = this.filterConfigInfo(57);
    const txtNameDiscountConst = this.filterConfigInfo(58);
    const txtValueDiscountConst = this.filterConfigInfo(59);
    const popupDiscountRecordSuccess = this.filterConfigInfo(60);
    const popupDiscountUpdateSuccess = this.filterConfigInfo(61);
    const tooltipModelDiscountConst = this.filterConfigInfo(64);
    const tooltipTypeDiscountConst = this.filterConfigInfo(65);

    if (!!btnSaveDiscountConst) {
      this.setState({
        btnSaveDiscount: this.filterConfigInfoList(btnSaveDiscountConst.adcomponenteinformacion, 31).text,
      })
    }
    if (!!txtModelDiscountConst) {
      this.setState({
        txtModelDiscount: this.filterConfigInfoList(txtModelDiscountConst.adcomponenteinformacion, 32).text,
      })
    }
    if (!!txtTypeDiscountConst) {
      this.setState({
        txtTypeDiscount: this.filterConfigInfoList(txtTypeDiscountConst.adcomponenteinformacion, 33).text,
      })
    }
    if (!!txtReasonDiscountConst) {
      this.setState({
        txtReasonDiscount: this.filterConfigInfoList(txtReasonDiscountConst.adcomponenteinformacion, 34).text,
      })
    }
    if (!!txtNameDiscountConst) {
      this.setState({
        txtNameDiscount: this.filterConfigInfoList(txtNameDiscountConst.adcomponenteinformacion, 35).text,
      })
    }
    if (!!txtValueDiscountConst) {
      this.setState({
        txtValueDiscount: this.filterConfigInfoList(txtValueDiscountConst.adcomponenteinformacion, 36).text,
      })
    }
    if (!!popupDiscountRecordSuccess) {
      this.setState({
        recordSuccessHeader: this.filterConfigInfoList(popupDiscountRecordSuccess.adcomponenteinformacion, 37).text,
        recordSuccessBody: this.filterConfigInfoList(popupDiscountRecordSuccess.adcomponenteinformacion, 38).text,
      })
    }
    if (!!popupDiscountUpdateSuccess) {
      this.setState({
        updateSuccessHeader: this.filterConfigInfoList(popupDiscountUpdateSuccess.adcomponenteinformacion, 39).text,
        updateSuccessBody: this.filterConfigInfoList(popupDiscountUpdateSuccess.adcomponenteinformacion, 40).text,
      })
    }
    if (!!tooltipModelDiscountConst) {
      this.setState({
        tooltipModelDiscountHeader: this.filterConfigInfoList(tooltipModelDiscountConst.adcomponenteinformacion, 45).text,
        tooltipModelDiscountBody: this.filterConfigInfoList(tooltipModelDiscountConst.adcomponenteinformacion, 46).text,
        tooltipModelDiscountBotton: this.filterConfigInfoList(tooltipModelDiscountConst.adcomponenteinformacion, 127).text,
        tooltipModelDiscountUrl: this.filterConfigInfoList(tooltipModelDiscountConst.adcomponenteinformacion, 127).url,
      })
    }
    if (!!tooltipTypeDiscountConst) {
      this.setState({
        tooltipTypeDiscountHeader: this.filterConfigInfoList(tooltipTypeDiscountConst.adcomponenteinformacion, 47).text,
        tooltipTypeDiscountBody: this.filterConfigInfoList(tooltipTypeDiscountConst.adcomponenteinformacion, 48).text,
        tooltipTypeDiscountBotton: this.filterConfigInfoList(tooltipTypeDiscountConst.adcomponenteinformacion, 128).text,
        tooltipTypeDiscountUrl: this.filterConfigInfoList(tooltipTypeDiscountConst.adcomponenteinformacion, 128).url,
      })
    }
  }

  filterConfigInfo(id) {
    const configData = this.state.components;
    const data = configData.find(item => item.id === id);
    return data
  }

  filterConfigInfoList(list, id) {
    const data = list.find(item => item.id === id);
    return data
  }

  cleanData() {
    this.setState({
      form: {
        toogleDiscountModel: '1',
        toogleDiscountType: '1',
        txtDiscountDescription: '',
        txtDiscountValue: 0,
        ddlDiscountReason: '',
      },
      alterForm: {
        createdby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        createdon: new Date(),
        empresaid: JSON.parse(localStorage.getItem('dataCompany')).companyId,
        esglobal: false,
        id: 0,
        idbusinessunit: 0,
        idowner: 0,
        idstate: 0,
        modifiedby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        modifiedon: new Date(),
      },
    });
    this.validator.hideMessages();
    this.props.clearCurrentDiscount();
    this.props.clearUpdateDiscount();
    this.props.clearCreateDiscount();
  }

  syncChanges(value, property) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [property]: value,
      },
    }));
  }

  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked
      }
    }))
  };

  loadInfo(item) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleDiscountModel: item.modelodescuento === 1 ? '1' : '2',
        toogleDiscountType: item.tipodescuento === 1 ? '1' : '2',
        txtDiscountDescription: item.descripcion,
        txtDiscountValue: item.valor,
        ddlDiscountReason: item.motivoid,
      },

      alterForm: {
        ...prevState.alterForm,
        createdby: item.createdby,
        createdon: item.createdon,
        empresaid: item.empresaid,
        esglobal: item.esglobal,
        id: item.id,
        idbusinessunit: item.idbusinessunit,
        idowner: item.idowner,
        idstate: item.idstate,
        modifiedby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        modifiedon: item.modifiedon,
      },
    }))
  }

  onSaveDiscount = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        modelodescuento: this.state.form.toogleDiscountModel === '1' ? 1 : 2,
        tipodescuento: this.state.form.toogleDiscountType === '1' ? 1 : 2,
        descripcion: this.state.form.txtDiscountDescription,
        valor: this.state.form.txtDiscountValue,
        motivoid: this.state.form.ddlDiscountReason,
        createdby: this.state.alterForm.createdby,
        createdon: this.state.alterForm.createdon,
        empresaid: this.state.alterForm.empresaid,
        esglobal: this.state.alterForm.esglobal,
        id: this.state.alterForm.id,
        idbusinessunit: this.state.alterForm.idbusinessunit,
        idowner: this.state.alterForm.idowner,
        idstate: this.state.alterForm.idstate,
        modifiedby: this.state.alterForm.modifiedby,
        modifiedon: this.state.alterForm.modifiedon,
      }

      if (this.state.editingDiscountData)
        this.props.putDiscount(data)
      else
        this.props.postDiscount(data)

    } else {
      console.log('else onSaveDiscount');
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleAlignmentDiscountModel = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleDiscountModel: !!newAlignment ? newAlignment : '1'
      }
    }))
  };

  handleAlignmentDiscountType = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleDiscountType: !!newAlignment ? newAlignment : '1'
      }
    }))
  };

  validateOnlyNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexDecimal;
    value = value.replace(regex, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }

  validateFieldsOnBlur(e, name) {
    e.preventDefault();
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name)
    }
  }

  render() {
    return (
      <form id="discountForm" onSubmit={(e) => this.onSaveDiscount(e)}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ToggleButtons
              label={this.state.txtModelDiscount}
              value={this.state.form.toogleDiscountModel}
              onChange={this.handleAlignmentDiscountModel}
              titleOne={'Comercial'}
              titleTwo={'Financiero'}
              txtTooltip={
                <TooltipMessage
                  title={!!this.state.tooltipModelDiscountHeader
                    ? this.state.tooltipModelDiscountHeader
                    : ''}
                  message={!!this.state.tooltipModelDiscountBody
                    ? this.state.tooltipModelDiscountBody
                    : ''}
                  botton={!!this.state.tooltipModelDiscountBotton
                    ? this.state.tooltipModelDiscountBotton
                    : ''}
                  href={!!this.state.tooltipModelDiscountUrl
                    ? this.state.tooltipModelDiscountUrl
                    : ''}
                />
              }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <SelectForm
              id="ddlDiscountReason"
              label={this.state.txtReasonDiscount}
              name="ddlDiscountReason"
              validator={this.validator}
              nameValidator={'MotivoDescuento'}
              validateOptions={"required"}
              value={this.state.form.ddlDiscountReason}
              options={this.state.reasonDiscount.map((item) => ({
                value: item.id,
                text: item.descripcion,
              }))}
              onChange={(ev) =>
                this.syncChanges(ev.target.value, "ddlDiscountReason")
              }
              placeholder={"Seleccione..."}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ToggleButtons
              label={this.state.txtTypeDiscount}
              value={this.state.form.toogleDiscountType}
              onChange={this.handleAlignmentDiscountType}
              titleOne={'$'}
              titleTwo={'%'}
              txtTooltip={
                <TooltipMessage
                  title={!!this.state.tooltipTypeDiscountHeader
                    ? this.state.tooltipTypeDiscountHeader
                    : ''}
                  message={!!this.state.tooltipTypeDiscountBody
                    ? this.state.tooltipTypeDiscountBody
                    : ''}
                  botton={!!this.state.tooltipTypeDiscountBotton
                    ? this.state.tooltipTypeDiscountBotton
                    : ''}
                  href={!!this.state.tooltipTypeDiscountUrl
                    ? this.state.tooltipTypeDiscountUrl
                    : ''}
                />
              }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputForm
              id={"txtDiscountValue"}
              label={this.state.txtValueDiscount}
              value={this.state.form.txtDiscountValue}
              format={this.state.form.toogleDiscountType === '1' ? true : false}
              onChange={(ev) => {
                this.syncChanges(ev.target.value, "txtDiscountValue");
                this.validateOnlyNumbers(ev);
              }
              }
              nameValidator={'Valor'}
              onBlur={(e) =>
                this.validateFieldsOnBlur(e, 'Valor')
              }
              validator={this.validator}
              validateOptions={this.state.form.toogleDiscountType === '1'
                ? 'required|noNegative'
                : 'required|percentageValue'
              }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputForm
              id={"txtDiscountDescription"}
              label={this.state.txtNameDiscount}
              maxLength={200}
              value={this.state.form.txtDiscountDescription}
              onChange={(ev) =>
                this.syncChanges(ev.target.value, "txtDiscountDescription")
              }
              nameValidator={'Descripción'}
              onBlur={(e) =>
                this.validateFieldsOnBlur(e, 'Descripción')
              }
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>
          <Grid container>
            <Grid item lg={4} md={4}>
            </Grid>
            <Grid item lg={4} md={4}>
            </Grid>
            <Grid item lg={4} md={4} style={{ paddingTop: -50 }}>
              <ButtonPrimary
                text={this.state.btnSaveDiscount}
                loading={this.state.loadingSave}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

}

NewDiscount.propTypes = {
  discountReducer: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  discountReducer: state.discountReducer,
  configReducer: state.configReducer,
})

export default connect(mapStateToProps, {
  postDiscount,
  putDiscount,
  getDiscounts,
  getReasonDiscount,
  changeEditingDiscountStatus,
  clearCurrentDiscount,
  clearUpdateDiscount,
  clearCreateDiscount,
})(
  withStyles(useStyles)(NewDiscount));