import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import InvoiceForm from '../invoice-form.view';
import InvoiceRevision from '../invoice-revision.view';
import InvoiceItem from '../invoiceItem/invoiceItem.view.js';

import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import SelectForm from '../../../components/select/select.component';

import CustomProgress from '../../../components/Progress/progress.component';
import { updateDocument, createElectronicInvoice, editQoutation} from "../../../actions/invoiceActions";
import { getTotalAction, setStatusShowCartAction, clearLoadedChargeInvoiceActionAll, clearLoadedDiscountInvoiceActionAll } from "../../../actions/valuesInvoiceAction";
import { useHistory } from 'react-router-dom';
import { ConfirmAlert, WarningAlert } from '../../../helpers/alert.helpers';
import ResponseModal from '../../../components/modal/responseModal.component';
import SimpleModal from '../../../components/modal/simpleModal.component';
import ButtonSecundary from '../../../components/button/buttonSecundary.component';
import FileModalData from '../../../components/modal/fileModalData.component';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secundaryBackgroundColor
    },
    activeButton: {
        borderBottom: '1px solid #50C1EE'
    }
}));

const QontoConnector = withStyles({
    root: {},
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    line: {
        borderColor: '#4F4F4F',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (<div className={
        clsx(classes.root, {
            [classes.active]: active,
        })
    } > {completed ? < Check className={classes.completed} /> : <div className={classes.circle} />} </div>
    );
}

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#FFFFFF',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#2CC63E',
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: '#4F4F4F',
    },
    completed: {
        color: '#4F4F4F',
        zIndex: 1,
        fontSize: 18,
        backgroundColor: '#2CC63E',
        borderRadius: '50%',
        padding: 5
    },

});

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};



function getSteps() {
    return ['Información de Factura', 'Artículos', 'Revisar Factura'];
}

const QuotationCheckin = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [skipped, setSkipped] = React.useState(new Set());
    const [objInvoice, setObjInvoice] = React.useState({
        invoice: null,
        startedit: false
    });
    const [star, setStar] = React.useState(false);
    const windowsHeight = parseInt((window.innerHeight) * 0.58, 10);
    const heightDiv = windowsHeight + 'px';
    const steps = getSteps();
    const { HandleBack, Empresaid, Userid, totalinvoice, objHead } = props;
    const [loading, setLoading] = React.useState(false);
    const [formSave, setFormSave] = React.useState(null);  
    const [formQuoSave, setFormQuoSave] = React.useState(null);  
    const [openModalResolution, setOpenModalResolution] = React.useState(false); 
    const [modalResponse, setModalResponse] = React.useState({
        type: '',
        title: '', 
        subtitle: '',
        body: '',
        modalImage:'',
        open: false,
        closeElement: '',
        onCloseElement: 0
    });
    const[arrNumerations, setArrNumerations] = React.useState([]);
    const [footShow, setFootShow] = React.useState(false);
    const [resolution, setResolution] = React.useState(null);
    const [openModalFile, setOpenModalFile] = React.useState(false);
    const [formPreview, setFormPreview] = React.useState(null);

    const totalSteps = () => {
        return getSteps().length;
    };
    const history = useHistory()

    const completedSteps = () => {
        return completed.size;
    }; 

    const handleStep = (step) => () => {

        let newCompleted;
        let i = 0;
        if (step === 0) {
            newCompleted = new Set();
        }
        else {
            //alert(step);
            newCompleted = new Set();
            for (i === 0; i < step; i++) {
                newCompleted.add(i);
            }
        }

        setCompleted(newCompleted);
        setActiveStep(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }
    const cancelCreate = () => {
        setActiveStep(0);
        setObjInvoice({
            invoice: null,
            startedit: false
        });
        HandleBack();
    }
    const nextStepForm = (step) => {
        setActiveStep(step);
    }

    //datos del paso 2
    const approveStepInfo = (obj) => {
        if (obj !== null) {
            setStar(obj.favorite);
        }
        setObjInvoice({
            invoice: obj,
            startedit: false
        });
    }

    const handleFavourite = (action) => {
        setStar(action);
    }

    const handleOpenModal = () =>{
        setOpenModalResolution(true);    
    } 

    const sendInvoice = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;

        let objInv = objInvoice.invoice;

        if (objInvoice != null) {

            for (i === 0; i < objInv.clientInfo.adclientedireccion.length; i++) {
                if (objInv.clientInfo.adclientedireccion[i].id === objInv.addressid) {
                    address = {
                        nombmun: objInv.clientInfo.adclientedireccion[i].ciudaddescripcion,
                        nombpais: objInv.clientInfo.adclientedireccion[i].departamentodescripcion,
                        id: objInv.clientInfo.adclientedireccion[i].id,
                        direccion: objInv.clientInfo.adclientedireccion[i].direccion,
                        descripcion: objInv.clientInfo.adclientedireccion[i].descripcion,
                        Clienteid: objInv.clientInfo.adclientedireccion[i].clienteid,
                        Ciudadid: objInv.clientInfo.adclientedireccion[i].ciudadid,
                        Idstate: objInv.clientInfo.adclientedireccion[i].idstate,
                        Createdby: objInv.clientInfo.adclientedireccion[i].createdby,
                        Modifiedby: objInv.clientInfo.adclientedireccion[i].modifiedby,
                        Idbusinessunit: objInv.clientInfo.adclientedireccion[i].idowner,
                        Idowner: objInv.clientInfo.adclientedireccion[i].idowner,
                        Principal: objInv.clientInfo.adclientedireccion[i].principal
                    }
                }
            }

            if (address!=={}){
                address = {
                    nombmun: objInv.dirprincipal[0].ciudaddescripcion,
                    nombpais: objInv.dirprincipal[0].departamentodescripcion,
                    id: objInv.dirprincipal[0].id,
                    direccion: objInv.dirprincipal[0].direccion,
                    descripcion: objInv.dirprincipal[0].descripcion,
                    Clienteid: objInv.dirprincipal[0].clienteid,
                    Ciudadid: objInv.dirprincipal[0].ciudadid,
                    Idstate: objInv.dirprincipal[0].idstate,
                    Createdby: objInv.dirprincipal[0].createdby,
                    Modifiedby: objInv.dirprincipal[0].modifiedby,
                    Idbusinessunit: objInv.dirprincipal[0].idowner,
                    Idowner: objInv.dirprincipal[0].idowner,
                    Principal: objInv.dirprincipal[0].principal
                }
            }
            for (j === 0; j < objInv.clientInfo.adcontactos.length; j++) {

                if (objInv.clientInfo.adcontactos[j].id === objInv.contactid) {

                    contact = {
                        id: objInv.clientInfo.adcontactos[j].id,
                        nombre: objInv.clientInfo.adcontactos[j].nombre,
                        telefono: objInv.clientInfo.adcontactos[j].telefono,
                        celular: objInv.clientInfo.adcontactos[j].celular,
                        email: objInv.clientInfo.adcontactos[j].email,
                        clienteid: objInv.clientInfo.adcontactos[j].clienteid,
                        idstate: objInv.clientInfo.adcontactos[j].idstate,
                        createdby: objInv.clientInfo.adcontactos[j].createdby,
                        modifiedby: objInv.clientInfo.adcontactos[j].modifiedby,
                        idbusinessunit: objInv.clientInfo.adcontactos[j].idbusinessunit,
                        idowner: objInv.clientInfo.adcontactos[j].idowner
                    }
                }
            }

            //console.log('contacto'+ JSON.stringify(contact))
            const setPrincipal = (confirmed) => {

                totalinvoice.detalleDocumento.forEach(element => {
                    sumDiscounts = sumDiscounts + element.valordescuento;
                    products.push({
                        Codigo: element.codigo,
                        Descripcion: element.descripcion,
                        Cantidad: element.cantidad,
                        Valorunitario: element.valorNeto,
                        Porcentajedescuento: element.porcentajedescuento,
                        Valordescuento: element.valordescuento,
                        Id: 0,
                        Dianunidadmedidaid: element.dianunidadmedidaid,
                        Articuloid: element.articuloid,
                        Totaliva: element.totaliva,
                        Totalinc: element.totalinc,
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        Subtotal: element.subtotal,
                        Tipodescuento: element.tipodescuento,
                        Total: element.total,
                        Totaldescuento: element.totaldescuento,
                        Valordescuentocomercial:element.valordescuentocomercial,
                        Prorrateo:element.prorrateo,
                        Totaldescuentoreal:element.totaldescuentoreal,
                        Subtotalreal:element.subtotalreal,
                        Totalivareal:element.totalivareal,
                        Totalincreal:element.totalincreal,
                        Totalreal:element.totalreal
                    })
                });

                totalinvoice.cargos.forEach(charge => {
                    charges.push({
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Cargoid: charge.id,
                        Descripcion: charge.descripcion,
                        Tipocargo: charge.tipocargo,
                        Valor: charge.valor,
                        Esglobal: false, //no definido
                        Cantidad:charge.cantidad,
                        Valorcalculado:charge.valorcalculado,
                        Valorasumar:charge.valorasumar,
                        Identificador: charge.identificador
                    })
                });

                totalinvoice.otrosdescuentos.forEach(discount => {
                    discounts.push({
                        Idstate: 1,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Descuentoid: discount.id,
                        descripcion: discount.descripcion,
                        motivoid: discount.motivoid,
                        modelodescuento: discount.modelodescuento,
                        valor: discount.valor,
                        tipodescuento: discount.tipodescuento,
                        valoradescontar: discount.valoradescontar,
                        motivodescuento: discount.motivoDescripcion,
                        identificador:discount.identificador
                    })
                });

                if (confirmed) {
                    setLoading(true);
                    setFormSave({
                        Opdocumento: {
                            fecha: objInv.dateInvoice,
                            numeracionid: objInv.resolutionid,
                            estadodocumentoid: 0,
                            tipoaceptaciondocumentoid: 1,
                            tipoacusedocumentoid: 3,
                            valorapagar: totalinvoice.totalapagar,
                            dianformapagoid: objInv.visibleCredit === true ? 2 : 1,
                            valordescuento: sumDiscounts,
                            valorcargos: 0,
                            subtotal: totalinvoice.valorbruto,
                            valortotal: totalinvoice.totalfactura,
                            valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                            valorcargodocumento: totalinvoice.valorcargos,
                            valoranticipo: 0,
                            valorinc: totalinvoice.valorinc,
                            valoriva: totalinvoice.valoriva,
                            textoobservacion: objInv.observations,
                            vendedorid: objInv.vendorid,
                            diantipodocumentoelectronicoid: 1,
                            clienteid: objInv.clientInfo.id,
                            dianmediopagoid: objInv.paymethodid,
                            idstate: 0,
                            createdby: 1,
                            modifiedby: 1,
                            idbusinessunit: 1,
                            idowner: 1,
                            origendocumentoid: 1,
                            Dianconceptonotadebitoid: 0,
                            Dianconceptonotacreditoid: 0,
                            fechapago: objInv.datePay,
                            favorito: star,
                            empresaid: Empresaid,
                            Incluirvendedor: objInv.includeVendor,
                            Valordescuentoreal:totalinvoice.valordescuentoreal,
                            Valorivareal:totalinvoice.valorivareal,
                            Valorincreal:totalinvoice.valorincreal,
                            TotalfacturaReal:totalinvoice.totalfacturaReal,
                            Vendedor: {
                                Id: objInv.vendorid.id,
                                Nombres: objInv.Vendor.nombres,
                                Idstate: objInv.Vendor.idstate,
                                Createdby: objInv.Vendor.createdby,
                                Modifiedby: objInv.Vendor.modifiedby,
                                Idbusinessunit: objInv.Vendor.idbusinessunit,
                                Idowner: objInv.Vendor.idowner,
                                Empresaid: objInv.Vendor.empresaid,
                                Telefono: objInv.Vendor.telefono,
                                Email: objInv.Vendor.email
                            },
                            Cliente: {
                                Id: objInv.clientInfo.id,
                                Documento: objInv.clientInfo.documento,
                                Documentodv: objInv.clientInfo.documentodv,
                                Razonsocial: objInv.clientInfo.razonsocial,
                                Telefono: objInv.clientInfo.telefono,
                                Correoelectronico: objInv.clientInfo.correoelectronico,
                                Diantipodocumentoidentidadid: objInv.clientInfo.diantipodocumentoidentidadid,
                                Diantipopersonaid: objInv.clientInfo.diantipopersonaid,
                                Empresaid: objInv.clientInfo.Empresaid,
                                Favorito: objInv.clientInfo.Favorito,
                                Idstate: objInv.clientInfo.Idstate,
                                createdby: objInv.clientInfo.createdby,
                                modifiedby: objInv.clientInfo.modifiedby,
                                idbusinessunit: objInv.clientInfo.idbusinessunit,
                                idowner: objInv.clientInfo.idbusinessunit,
                                Clientedireccion: {
                                    Id: address.id,
                                    Descripcion: address.descripcion,
                                    Clienteid: address.clienteid,
                                    Ciudadid: address.ciudadid,
                                    Idstate: address.idstate,
                                    Createdby: address.createdby,
                                    Modifiedby: address.modifiedby,
                                    Idbusinessunit: address.idowner,
                                    Idowner: address.idowner,
                                    Direccion: address.direccion,
                                    Principal: address.principal
                                },
                                Contactos: {
                                    Id: contact.id,
                                    Nombre: contact.nombre,
                                    Telefono: contact.telefono,
                                    Celular: contact.celular,
                                    Email: contact.email,
                                    Clienteid: contact.clienteid,
                                    Idstate: contact.idstate,
                                    Createdby: contact.createdby,
                                    Modifiedby: contact.modifiedby,
                                    Idbusinessunit: contact.idbusinessunit,
                                    Idowner: contact.idowner
                                }
                            },
                            Numeracion: {
                                Id: objInv.resolutionid,
                                Resolucion: objInv.Resolution.resolucion,
                                Fechainicio: objInv.Resolution.fechainicio,
                                Fechafinal: objInv.Resolution.fechafinal,
                                Prefijo: objInv.Resolution.prefijo,
                                Consecinicial: objInv.Resolution.consecinicial,
                                Consecfinal: objInv.Resolution.consecfinal,
                                Consecactual: objInv.Resolution.consecactual,
                                Empresaid: objInv.Resolution.empresaid,
                                Diantipomodalidadid: objInv.Resolution.diantipomodalidadid,
                                Sucursalempresaid: objInv.Resolution.sucursalempresaid,
                                Idstate: objInv.Resolution.idstate,
                                Createdby: objInv.Resolution.createdby,
                                Modifiedby: objInv.Resolution.modifiedby,
                                Idbusinessunit: objInv.Resolution.idbusinessunit,
                                Idowner: objInv.Resolution.idowner,
                                Nombre: objInv.Resolution.nombre
                            }
                        },
                        LstOpdetalledocumento: products,
                        LstCargos: charges,
                        LstDescuentos: discounts
                    });
                }
            }

            if (totalinvoice.detalleDocumento.length ===0){
                WarningAlert("Debe seleccionar al menos un producto");
            }
            else{

                if (totalinvoice.totalapagar < 0){
                    WarningAlert("El valor a pagar de la factura no puede ser negativo");
                }
                else{
                    let validacargos = false;
                    if (totalinvoice.valorcargos> 0){
                        let valorbolsas = 0;
                        totalinvoice.cargos.forEach(charge => {
                            if (charge.descripcion === 'Bolsas'){
                                valorbolsas = valorbolsas + charge.valorcalculado
                            }
                            
                        });
                        //console.log('valor bolsas'+ valorbolsas);
                        
                        if ((totalinvoice.valorbruto-totalinvoice.valorOtrosDescuentos-totalinvoice.valordescuentoreal) < totalinvoice.valorcargos-valorbolsas)
                        {
                            WarningAlert("El valor de los cargos no puede ser superior a la factura con descuentos");
                        }
                        else{
                            validacargos = true;
                        } 
                    }else{
                        validacargos = true;
                    }    
                    
                    if (validacargos){
                        ConfirmAlert('Oh...', '¿Desea guardar y enviar la factura?', setPrincipal);
                    }
                }
            }           

        }
    }

    const sendPreview = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;

        let objInv = objInvoice.invoice;
        if (objInvoice != null) {

            for (i === 0; i < objInv.clientInfo.adclientedireccion.length; i++) {
                if (objInv.clientInfo.adclientedireccion[i].id === objInv.addressid) {
                    address = {
                        nombmun: objInv.clientInfo.adclientedireccion[i].ciudaddescripcion,
                        nombpais: objInv.clientInfo.adclientedireccion[i].departamentodescripcion,
                        id: objInv.clientInfo.adclientedireccion[i].id,
                        direccion: objInv.clientInfo.adclientedireccion[i].direccion,
                        descripcion: objInv.clientInfo.adclientedireccion[i].descripcion,
                        Clienteid: objInv.clientInfo.adclientedireccion[i].clienteid,
                        Ciudadid: objInv.clientInfo.adclientedireccion[i].ciudadid,
                        Idstate: objInv.clientInfo.adclientedireccion[i].idstate,
                        Createdby: objInv.clientInfo.adclientedireccion[i].createdby,
                        Modifiedby: objInv.clientInfo.adclientedireccion[i].modifiedby,
                        Idbusinessunit: objInv.clientInfo.adclientedireccion[i].idowner,
                        Idowner: objInv.clientInfo.adclientedireccion[i].idowner,
                        Principal: objInv.clientInfo.adclientedireccion[i].principal
                    }
                }
            }

            if (address!=={}){
                address = {
                    nombmun: objInv.dirprincipal[0].ciudaddescripcion,
                    nombpais: objInv.dirprincipal[0].departamentodescripcion,
                    id: objInv.dirprincipal[0].id,
                    direccion: objInv.dirprincipal[0].direccion,
                    descripcion: objInv.dirprincipal[0].descripcion,
                    Clienteid: objInv.dirprincipal[0].clienteid,
                    Ciudadid: objInv.dirprincipal[0].ciudadid,
                    Idstate: objInv.dirprincipal[0].idstate,
                    Createdby: objInv.dirprincipal[0].createdby,
                    Modifiedby: objInv.dirprincipal[0].modifiedby,
                    Idbusinessunit: objInv.dirprincipal[0].idowner,
                    Idowner: objInv.dirprincipal[0].idowner,
                    Principal: objInv.dirprincipal[0].principal
                }
            }
            for (j === 0; j < objInv.clientInfo.adcontactos.length; j++) {

                if (objInv.clientInfo.adcontactos[j].id === objInv.contactid) {

                    contact = {
                        id: objInv.clientInfo.adcontactos[j].id,
                        nombre: objInv.clientInfo.adcontactos[j].nombre,
                        telefono: objInv.clientInfo.adcontactos[j].telefono,
                        celular: objInv.clientInfo.adcontactos[j].celular,
                        email: objInv.clientInfo.adcontactos[j].email,
                        clienteid: objInv.clientInfo.adcontactos[j].clienteid,
                        idstate: objInv.clientInfo.adcontactos[j].idstate,
                        createdby: objInv.clientInfo.adcontactos[j].createdby,
                        modifiedby: objInv.clientInfo.adcontactos[j].modifiedby,
                        idbusinessunit: objInv.clientInfo.adcontactos[j].idbusinessunit,
                        idowner: objInv.clientInfo.adcontactos[j].idowner
                    }
                }
            }


                totalinvoice.detalleDocumento.forEach(element => {
                    sumDiscounts = sumDiscounts + element.valordescuento;
                    products.push({
                        Codigo: element.codigo,
                        Descripcion: element.descripcion,
                        Cantidad: element.cantidad,
                        Valorunitario: element.valorNeto,
                        Porcentajedescuento: element.porcentajedescuento,
                        Valordescuento: element.valordescuento,
                        Id: 0,
                        Dianunidadmedidaid: element.dianunidadmedidaid,
                        Articuloid: element.articuloid,
                        Totaliva: element.totaliva,
                        Totalinc: element.totalinc,
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        Subtotal: element.subtotal,
                        Tipodescuento: element.tipodescuento,
                        Total: element.total,
                        Totaldescuento: element.totaldescuento,
                        Valordescuentocomercial:element.valordescuentocomercial,
                        Prorrateo:element.prorrateo,
                        Totaldescuentoreal:element.totaldescuentoreal,
                        Subtotalreal:element.subtotalreal,
                        Totalivareal:element.totalivareal,
                        Totalincreal:element.totalincreal,
                        Totalreal:element.totalreal
                    })
                });

                totalinvoice.cargos.forEach(charge => {
                    charges.push({
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Cargoid: charge.id,
                        Descripcion: charge.descripcion,
                        Tipocargo: charge.tipocargo,
                        Valor: charge.valor,
                        Esglobal: false, //no definido
                        Cantidad:charge.cantidad,
                        Valorcalculado:charge.valorcalculado,
                        Valorasumar:charge.valorasumar,
                        Identificador: charge.identificador
                    })
                });

                totalinvoice.otrosdescuentos.forEach(discount => {
                    discounts.push({
                        Idstate: 1,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Descuentoid: discount.id,
                        descripcion: discount.descripcion,
                        motivoid: discount.motivoid,
                        modelodescuento: discount.modelodescuento,
                        valor: discount.valor,
                        tipodescuento: discount.tipodescuento,
                        valoradescontar: discount.valoradescontar,
                        motivodescuento: discount.motivoDescripcion,
                        identificador:discount.identificador
                    })
                });


                    setFormPreview({
                        Opdocumento: {
                            fecha: objInv.dateInvoice,
                            numeracionid: 0,
                            estadodocumentoid: 0,
                            tipoaceptaciondocumentoid: 1,
                            tipoacusedocumentoid: 3,
                            valorapagar: totalinvoice.totalapagar,
                            dianformapagoid: objInv.visibleCredit === true ? 2 : 1,
                            valordescuento: sumDiscounts,
                            valorcargos: 0,
                            subtotal: totalinvoice.valorbruto,
                            valortotal: totalinvoice.totalfactura,
                            valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                            valorcargodocumento: totalinvoice.valorcargos,
                            valoranticipo: 0,
                            valorinc: totalinvoice.valorinc,
                            valoriva: totalinvoice.valoriva,
                            textoobservacion: objInv.observations,
                            vendedorid: objInv.vendorid,
                            diantipodocumentoelectronicoid: 4,
                            clienteid: objInv.clientInfo.id,
                            dianmediopagoid: objInv.paymethodid,
                            idstate: 0,
                            createdby: 1,
                            modifiedby: 1,
                            idbusinessunit: 1,
                            idowner: 1,
                            origendocumentoid: 1,
                            Dianconceptonotadebitoid: 0,
                            Dianconceptonotacreditoid: 0,
                            fechapago: objInv.visibleCredit === true ? objInv.datePay : null,
                            favorito: star,
                            empresaid: Empresaid,
                            Incluirvendedor: objInv.includeVendor,
                            Valordescuentoreal:totalinvoice.valordescuentoreal,
                            Valorivareal:totalinvoice.valorivareal,
                            Valorincreal:totalinvoice.valorincreal,
                            TotalfacturaReal:totalinvoice.totalfacturaReal,
                            Archivovisualizar: 'INVOICE',
                            Vendedor: {
                                Id: objInv.vendorid.id,
                                Nombres: objInv.Vendor.nombres,
                                Idstate: objInv.Vendor.idstate,
                                Createdby: objInv.Vendor.createdby,
                                Modifiedby: objInv.Vendor.modifiedby,
                                Idbusinessunit: objInv.Vendor.idbusinessunit,
                                Idowner: objInv.Vendor.idowner,
                                Empresaid: objInv.Vendor.empresaid,
                                Telefono: objInv.Vendor.telefono,
                                Email: objInv.Vendor.email
                            },
                            Cliente: {
                                Id: objInv.clientInfo.id,
                                Documento: objInv.clientInfo.documento,
                                Documentodv: objInv.clientInfo.documentodv,
                                Razonsocial: objInv.clientInfo.razonsocial,
                                Telefono: objInv.clientInfo.telefono,
                                Correoelectronico: objInv.clientInfo.correoelectronico,
                                Diantipodocumentoidentidadid: objInv.clientInfo.diantipodocumentoidentidadid,
                                Diantipopersonaid: objInv.clientInfo.diantipopersonaid,
                                Empresaid: objInv.clientInfo.Empresaid,
                                Favorito: objInv.clientInfo.Favorito,
                                Idstate: objInv.clientInfo.Idstate,
                                createdby: objInv.clientInfo.createdby,
                                modifiedby: objInv.clientInfo.modifiedby,
                                idbusinessunit: objInv.clientInfo.idbusinessunit,
                                idowner: objInv.clientInfo.idbusinessunit,
                                Clientedireccion: {
                                    Id: address.id,
                                    Descripcion: address.descripcion,
                                    Clienteid: address.clienteid,
                                    Ciudadid: address.ciudadid,
                                    Idstate: address.idstate,
                                    Createdby: address.createdby,
                                    Modifiedby: address.modifiedby,
                                    Idbusinessunit: address.idowner,
                                    Idowner: address.idowner,
                                    Direccion: address.direccion,
                                    Principal: address.principal
                                },
                                Contactos: {
                                    Id: contact.id,
                                    Nombre: contact.nombre,
                                    Telefono: contact.telefono,
                                    Celular: contact.celular,
                                    Email: contact.email,
                                    Clienteid: contact.clienteid,
                                    Idstate: contact.idstate,
                                    Createdby: contact.createdby,
                                    Modifiedby: contact.modifiedby,
                                    Idbusinessunit: contact.idbusinessunit,
                                    Idowner: contact.idowner
                                }
                            },
                            Numeracion: {
                                Id: 0,
                                Resolucion: '',
                                Fechainicio: '1900-01-01',
                                Fechafinal: '1900-01-01',
                                Prefijo: '',
                                Consecinicial: '',
                                Consecfinal: '',
                                Consecactual: '',
                                Empresaid: 0,
                                Diantipomodalidadid: 0,
                                Sucursalempresaid: objInv.branchofficeid,
                                Idstate: 0,
                                Createdby: 0,
                                Modifiedby: 0,
                                Idbusinessunit: 0,
                                Idowner: 0,
                                Nombre: ''
                            }
                        },
                        LstOpdetalledocumento: products,
                        LstCargos: charges,
                        LstDescuentos: discounts
                    });
                    setOpenModalFile(true);
        }
    }

    const confirmSendOk = (response) => {

        setLoading(false);
        setModalResponse ({
            type: 'success',
            title: 'Fenomenal!!!', 
            subtitle: 'Has generado una nueva factura',
            body: (<div><div>{response.data.statusMessage.indexOf('Rechazo')!== -1 ? 'Factura generada con error al enviar a Dian' : 'Proceso Exitoso'}</div>
            <div class="fenomenalWhiteLittle">{response.data.statusMessage} <span className="fenomenalBlueLittle">No. {response.data.result}</span></div></div>),
            modalImage:'success',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 2
        });       
     
    }

    const confirmError = (response) => {

        setLoading(false);
        setModalResponse ({
            type: 'warning',
            title: 'Oops!!!', 
            subtitle: 'Ocurrió algo inesperado',
            body: (<div><div>
                {JSON.stringify(response === undefined ? 'Error inesperado' : response.data.statusMessage)}
                </div></div>),
            modalImage:'warning',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 0
        });   
        //WarningAlert("Error al guardar la factura!!" + JSON.stringify(response.message === undefined ? response.StatusMessage : response.message));
    } 

    const handleResponseModal = (rta) => {     
        if (rta === 1 || rta===2){
            localStorage.removeItem('itemsInCart');
            props.setStatusShowCartAction(false);
            props.clearLoadedChargeInvoiceActionAll();
            props.clearLoadedDiscountInvoiceActionAll();
            history.push({
                pathname: '/document',
                state:{
                    redirect: 2
                }
            });
        }
        setModalResponse ({
            type: '',
            title: '', 
            subtitle: '',
            body: '',
            modalImage:'',
            open: false,
            closeElement: '',
            onCloseElement: ''
        });

    }

    const handleItemState = (state) =>{
        //console.log('Estado-->'+state);
        setFootShow(state);
    }

    const handleCloseModalFile = () => {
        setOpenModalFile(false);
      };

    const footPage = (step) => {
        return (
            <div className='bottomButtonsInvoiceRevision'>
                <Grid container >
                    <Grid item lg={2} md={2} sm={2}>
                        <div className="backInvoice" onClick={handleStep(step)}><ArrowBackIosIcon></ArrowBackIosIcon>Volver</div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1}>

                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div >
                            <div style={{ display: star === true ? 'block' : 'none' }}>
                                <StarIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(false)} ></StarIcon>
                            </div>
                            <div style={{ display: star === false ? 'block' : 'none' }}>
                                <StarBorderIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(true)} ></StarBorderIcon>
                            </div>
                            <div className="textButtonsInvoice">Destacar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div title="Previsualizar PDF">
                            <VisibilityIcon className="buttonInvoiceBehavior" onClick={() => sendPreview()}></VisibilityIcon><br></br>
                            <div className="textButtonsInvoice">Previsualizar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <ClearIcon className="buttonInvoiceBehavior" onClick={() => handleResponseModal(2)}></ClearIcon><br></br>
                        <div className="textButtonsInvoice">Cancelar</div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    useEffect(() => {

        
        if (objInvoice != null) {
            if (objInvoice.startedit === true){
                setActiveStep(0);
            }
            else{
                let newCompleted = new Set(completed);
                newCompleted.add(0);
                setCompleted(newCompleted);
                setActiveStep(1);
            }            
            
            /*let newCompleted = new Set(completed);
            newCompleted.add(0);
            setCompleted(newCompleted);
            setActiveStep(1);*/
        }

    }, [objInvoice]);


    useEffect(() => {
        if (formSave !== null) {
            //console.log('response-->'+JSON.stringify(formSave))
            props.createElectronicInvoice(formSave, confirmSendOk, confirmError);

        }
    }, [formSave]);

    useEffect(() => {
        //console.log('objeto invoice init = '+ JSON.stringify(objHead))
        if (objHead !== null){
            setObjInvoice({
                invoice: objHead,
                startedit: true
            })
        }        
    }, [objHead]);  

    useEffect(() => {
        if (loading) {
            setModalResponse ({
                type: 'success',  
                title: '', 
                subtitle: 'Generando documento, por favor espere...',
                body: (<div><div>
                    <Fragment>  <CustomProgress />{'Procesando'} </Fragment>
                    </div></div>),
                modalImage:'noimagen',
                open: true,
                closeElement: '',
                onCloseElement: 0
            });
            

        }
    }, [loading]);
 
    function getStepContent(step) {
        switch (step) {
            case 0:                
                return (<InvoiceForm CancelCreate={handleResponseModal} NextStep={nextStepForm} ApproveStepInfo={approveStepInfo} Empresaid={Empresaid} Userid={Userid} objHead={objInvoice.invoice}></InvoiceForm>);
            case 1:
                return (<div className="panelColorInvoice">
                    <InvoiceItem HandleItemState={handleItemState} activeStep={step}></InvoiceItem>   
                    <div>
                        <div className={footShow ? '': 'panelInvisibleInvoice'}>
                        <Grid container>
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <button className="buttonInvoiceConfirm" type="button" onClick={() => sendInvoice()}>Enviar Factura</button>
                            </Grid>   
                            <Grid item lg={2} md={2} sm={12} xs={12}>                                
                            </Grid>                   
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <button className="buttonInvoiceConfirm" type="button" onClick={handleStep(2)}>Revisar Factura</button>
                            </Grid>
                              <Grid item lg={2} md={2} sm={12} xs={12}>                                
                            </Grid>                           
                        </Grid>
                        </div>                        
                    </div>
                    <div className="spacingInvoice"></div>
                    {footPage(0)}
                </div>);
            case 2:
                return (<div className="panelColorInvoice">
                   <InvoiceRevision ObjId={0}
                        heightDiv={heightDiv}
                        objHead={objInvoice.invoice}
                        objItems={totalinvoice}
                        CancelCreate={cancelCreate}
                        Empresaid={Empresaid}
                        Userid={Userid}>
                </InvoiceRevision>              
                    <div className="spacingInvoice"></div>
                    <Grid container>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                            
                            </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <button className="buttonInvoiceConfirm" type="button" onClick={() => sendInvoice()}>Enviar Factura</button>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            
                            </Grid>
                    </Grid>
                    <div className="spacingInvoice"></div>
                    {footPage(1)}
                </div>);                
            default:
                return 'Unknown step';
        }
    }
     return (
        <div className="container-form" xs={12} lg={12}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={< QontoConnector />} className={classes.root} >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepButton
                                //onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                {...buttonProps}
                                className={activeStep === index ? classes.activeButton : ''}
                            >
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    <label className="stepper-title">{label}</label>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                <div>{getStepContent(activeStep)}</div>
            </div>
            <ResponseModal
                    modalType={modalResponse.modalType}
                    title={modalResponse.title}
                    subtitle={modalResponse.subtitle}
                    body={modalResponse.body}
                    modalImage={modalResponse.modalImage}
                    open={modalResponse.open}
                    closeElement={modalResponse.closeElement}
                    onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
                    ></ResponseModal>  
            <FileModalData
                open={openModalFile}
                onCloseElement={handleCloseModalFile}
                closeElement={"Cerrar"}    
                paramFile={formPreview}       
            >
            </FileModalData> 
        </div>
    );
}

QuotationCheckin.propTypes = {
    invoiceReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    invoiceReducer: state.invoiceReducer,
    sucessupdate: state.invoiceReducer.sucess,
    create: state.invoiceReducer.create,
    createquotation: state.invoiceReducer.create,
    totalinvoice: state.valuesInvoiceReducer.totalDetail
});

export default connect(mapStateToProps, {
    updateDocument, createElectronicInvoice, getTotalAction, editQoutation, setStatusShowCartAction, clearLoadedChargeInvoiceActionAll, clearLoadedDiscountInvoiceActionAll
})(QuotationCheckin);


