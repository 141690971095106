import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InvoiceForm from '../invoice/invoice-form.view';
import InvoiceRevision from '../invoice/invoice-revision.view';
import InvoiceItem from './invoiceItem/invoiceItem.view.js'
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomProgress from '../../components/Progress/progress.component';
import { updateDocument, createElectronicInvoice, createQoutation, setActiveStepInvoiceAction } from "../../actions/invoiceActions";
import { getTotalAction, setStatusShowCartAction, clearLoadedChargeInvoiceActionAll, clearLoadedDiscountInvoiceActionAll } from "../../actions/valuesInvoiceAction";
import { useHistory } from 'react-router-dom';
import { ConfirmAlert, WarningAlert } from '../../helpers/alert.helpers';
import ResponseModal from '../../components/modal/responseModal.component';
import FileModalData from '../../components/modal/fileModalData.component';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secundaryBackgroundColor,
        paddingBottom: '18px',
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingRight: '0px'
    },
    activeButton: {
        borderBottom: '1px solid #50C1EE',
        paddingBottom: '10px'
    }
}));

const QontoConnector = withStyles({
    root: {},
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    line: {
        borderColor: '#4F4F4F',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (<div className={
        clsx(classes.root, {
            [classes.active]: active,
        })
    } > {completed ? < Check className={classes.completed} /> : <div className={classes.circle} />} </div>
    );
}

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#FFFFFF',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#2CC63E',
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: '#4F4F4F',
    },
    completed: {
        color: '#4F4F4F',
        zIndex: 1,
        fontSize: 18,
        backgroundColor: '#2CC63E',
        borderRadius: '50%',
        padding: 5
    },

});

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};



function getSteps() {
    return ['Información de Factura', 'Artículos', 'Revisar Factura'];
}

const InvoiceCreate = (props) => {
    const classes = useStyles();
    const {setActiveStepInvoiceAction} = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [objInvoice, setObjInvoice] = React.useState(null);
    const [star, setStar] = React.useState(false);
    const windowsHeight = parseInt((window.innerHeight) * 0.7, 10);
    const heightDiv = windowsHeight + 'px';
    const steps = getSteps();
    const { HandleBack, Empresaid, Userid, totalinvoice, isValidDocument } = props;
    const [loading, setLoading] = React.useState(false);
    const [formSave, setFormSave] = React.useState(null);
    const [formQuoSave, setFormQuoSave] = React.useState(null);
    const [modalResponse, setModalResponse] = React.useState({
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: 0
    });
    const [footShow, setFootShow] = React.useState(false);
    const [openModalFile, setOpenModalFile] = React.useState(false);
    const [formPreview, setFormPreview] = React.useState(null);

    const totalSteps = () => {
        return getSteps().length;
    };
    const history = useHistory()

    const completedSteps = () => {
        return completed.size;
    };

    const handleStep = (step) => () => {

        let newCompleted;
        let i = 0;
        if (step === 0) {
            newCompleted = new Set();
        }
        else {
            //alert(step);
            newCompleted = new Set();
            for (i === 0; i < step; i++) {
                newCompleted.add(i);
            }
        }

        setCompleted(newCompleted);
        setActiveStep(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }
    const cancelCreate = () => {
        setActiveStep(0);
        setObjInvoice(null);
        HandleBack();
    }
    const nextStepForm = (step) => {
        setActiveStep(step);
    }

    //datos del paso 2
    const approveStepInfo = (obj) => {
        if (obj !== null) {
            setStar(obj.favorite);
        }
        setObjInvoice(obj);
    }
    const handleFavourite = (action) => {
        setStar(action);
    }


    /**
     * Carga información de paso activo en redux
     */
    useEffect(() => {
       if(activeStep!==null && activeStep!== undefined){
           setActiveStepInvoiceAction(activeStep);
       }
    }, [activeStep])

    const sendInvoice = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;


        if (objInvoice != null) {

            for (i === 0; i < objInvoice.clientInfo.adclientedireccion.length; i++) {
                if (objInvoice.clientInfo.adclientedireccion[i].id === objInvoice.addressid) {
                    address = {
                        nombmun: objInvoice.clientInfo.adclientedireccion[i].ciudaddescripcion,
                        nombpais: objInvoice.clientInfo.adclientedireccion[i].departamentodescripcion,
                        id: objInvoice.clientInfo.adclientedireccion[i].id,
                        direccion: objInvoice.clientInfo.adclientedireccion[i].direccion,
                        descripcion: objInvoice.clientInfo.adclientedireccion[i].descripcion,
                        Clienteid: objInvoice.clientInfo.adclientedireccion[i].clienteid,
                        Ciudadid: objInvoice.clientInfo.adclientedireccion[i].ciudadid,
                        Idstate: objInvoice.clientInfo.adclientedireccion[i].idstate,
                        Createdby: objInvoice.clientInfo.adclientedireccion[i].createdby,
                        Modifiedby: objInvoice.clientInfo.adclientedireccion[i].modifiedby,
                        Idbusinessunit: objInvoice.clientInfo.adclientedireccion[i].idowner,
                        Idowner: objInvoice.clientInfo.adclientedireccion[i].idowner,
                        Principal: objInvoice.clientInfo.adclientedireccion[i].principal
                    }
                }
            }

            if (address !== {}) {
                address = {
                    nombmun: objInvoice.dirprincipal[0].ciudaddescripcion,
                    nombpais: objInvoice.dirprincipal[0].departamentodescripcion,
                    id: objInvoice.dirprincipal[0].id,
                    direccion: objInvoice.dirprincipal[0].direccion,
                    descripcion: objInvoice.dirprincipal[0].descripcion,
                    Clienteid: objInvoice.dirprincipal[0].clienteid,
                    Ciudadid: objInvoice.dirprincipal[0].ciudadid,
                    Idstate: objInvoice.dirprincipal[0].idstate,
                    Createdby: objInvoice.dirprincipal[0].createdby,
                    Modifiedby: objInvoice.dirprincipal[0].modifiedby,
                    Idbusinessunit: objInvoice.dirprincipal[0].idowner,
                    Idowner: objInvoice.dirprincipal[0].idowner,
                    Principal: objInvoice.dirprincipal[0].principal
                }
            }
            for (j === 0; j < objInvoice.clientInfo.adcontactos.length; j++) {

                if (objInvoice.clientInfo.adcontactos[j].id === objInvoice.contactid) {

                    contact = {
                        id: objInvoice.clientInfo.adcontactos[j].id,
                        nombre: objInvoice.clientInfo.adcontactos[j].nombre,
                        telefono: objInvoice.clientInfo.adcontactos[j].telefono,
                        celular: objInvoice.clientInfo.adcontactos[j].celular,
                        email: objInvoice.clientInfo.adcontactos[j].email,
                        clienteid: objInvoice.clientInfo.adcontactos[j].clienteid,
                        idstate: objInvoice.clientInfo.adcontactos[j].idstate,
                        createdby: objInvoice.clientInfo.adcontactos[j].createdby,
                        modifiedby: objInvoice.clientInfo.adcontactos[j].modifiedby,
                        idbusinessunit: objInvoice.clientInfo.adcontactos[j].idbusinessunit,
                        idowner: objInvoice.clientInfo.adcontactos[j].idowner
                    }
                }
            }
            const setPrincipal = (confirmed) => {

                totalinvoice.detalleDocumento.forEach(element => {
                    sumDiscounts = sumDiscounts + element.valordescuento;
                    products.push({
                        Codigo: element.codigo,
                        Descripcion: element.descripcion,
                        Cantidad: element.cantidad,
                        Valorunitario: element.valorNeto,
                        Porcentajedescuento: element.porcentajedescuento,
                        Valordescuento: element.valordescuento,
                        Id: 0,
                        Dianunidadmedidaid: element.dianunidadmedidaid,
                        Articuloid: element.articuloid,
                        Totaliva: element.totaliva,
                        Totalinc: element.totalinc,
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        Subtotal: element.subtotal,
                        Tipodescuento: element.tipodescuento,
                        Total: element.total,
                        Totaldescuento: element.totaldescuento,
                        Valordescuentocomercial: element.valordescuentocomercial,
                        Prorrateo: element.prorrateo,
                        Totaldescuentoreal: element.totaldescuentoreal,
                        Subtotalreal: element.subtotalreal,
                        Totalivareal: element.totalivareal,
                        Totalincreal: element.totalincreal,
                        Totalreal: element.totalreal
                    })
                });

                totalinvoice.cargos.forEach(charge => {
                    charges.push({
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Cargoid: charge.id,
                        Descripcion: charge.descripcion,
                        Tipocargo: charge.tipocargo,
                        Valor: charge.valor,
                        Esglobal: false, //no definido
                        Cantidad: charge.cantidad,
                        Valorcalculado: charge.valorcalculado,
                        Valorasumar: charge.valorasumar,
                        Identificador: charge.identificador
                    })
                });

                totalinvoice.otrosdescuentos.forEach(discount => {
                    discounts.push({
                        Idstate: 1,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Descuentoid: discount.id,
                        descripcion: discount.descripcion,
                        motivoid: discount.motivoid,
                        modelodescuento: discount.modelodescuento,
                        valor: discount.valor,
                        tipodescuento: discount.tipodescuento,
                        valoradescontar: discount.valoradescontar,
                        motivodescuento: discount.motivoDescripcion,
                        identificador: discount.identificador
                    })
                });

                if (confirmed) {
                    setLoading(true);
                    setFormSave({
                        Opdocumento: {
                            fecha: objInvoice.dateInvoice,
                            numeracionid: objInvoice.resolutionid,
                            estadodocumentoid: 0,
                            tipoaceptaciondocumentoid: 1,
                            tipoacusedocumentoid: 3,
                            valorapagar: totalinvoice.totalapagar,
                            dianformapagoid: objInvoice.visibleCredit === true ? 2 : 1,
                            valordescuento: sumDiscounts,
                            valorcargos: 0,
                            subtotal: totalinvoice.valorbruto,
                            valortotal: totalinvoice.totalfactura,
                            valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                            valorcargodocumento: totalinvoice.valorcargos,
                            valoranticipo: 0,
                            valorinc: totalinvoice.valorinc,
                            valoriva: totalinvoice.valoriva,
                            textoobservacion: objInvoice.observations,
                            vendedorid: objInvoice.vendorid,
                            diantipodocumentoelectronicoid: 1,
                            clienteid: objInvoice.clientInfo.id,
                            dianmediopagoid: objInvoice.paymethodid,
                            idstate: 0,
                            createdby: 1,
                            modifiedby: 1,
                            idbusinessunit: 1,
                            idowner: 1,
                            origendocumentoid: 1,
                            Dianconceptonotadebitoid: 0,
                            Dianconceptonotacreditoid: 0,
                            fechapago: objInvoice.datePay,
                            favorito: star,
                            empresaid: Empresaid,
                            Incluirvendedor: objInvoice.includeVendor,
                            Valordescuentoreal: totalinvoice.valordescuentoreal,
                            Valorivareal: totalinvoice.valorivareal,
                            Valorincreal: totalinvoice.valorincreal,
                            TotalfacturaReal: totalinvoice.totalfacturaReal,
                            Vendedor: {
                                Id: objInvoice.vendorid.id,
                                Nombres: objInvoice.Vendor.nombres,
                                Idstate: objInvoice.Vendor.idstate,
                                Createdby: objInvoice.Vendor.createdby,
                                Modifiedby: objInvoice.Vendor.modifiedby,
                                Idbusinessunit: objInvoice.Vendor.idbusinessunit,
                                Idowner: objInvoice.Vendor.idowner,
                                Empresaid: objInvoice.Vendor.empresaid,
                                Telefono: objInvoice.Vendor.telefono,
                                Email: objInvoice.Vendor.email
                            },
                            Cliente: {
                                Id: objInvoice.clientInfo.id,
                                Documento: objInvoice.clientInfo.documento,
                                Documentodv: objInvoice.clientInfo.documentodv,
                                Razonsocial: objInvoice.clientInfo.razonsocial,
                                Telefono: objInvoice.clientInfo.telefono,
                                Correoelectronico: objInvoice.clientInfo.correoelectronico,
                                Diantipodocumentoidentidadid: objInvoice.clientInfo.diantipodocumentoidentidadid,
                                Diantipopersonaid: objInvoice.clientInfo.diantipopersonaid,
                                Empresaid: objInvoice.clientInfo.Empresaid,
                                Favorito: objInvoice.clientInfo.Favorito,
                                Idstate: objInvoice.clientInfo.Idstate,
                                createdby: objInvoice.clientInfo.createdby,
                                modifiedby: objInvoice.clientInfo.modifiedby,
                                idbusinessunit: objInvoice.clientInfo.idbusinessunit,
                                idowner: objInvoice.clientInfo.idbusinessunit,
                                Clientedireccion: {
                                    Id: address.id,
                                    Descripcion: address.descripcion,
                                    Clienteid: address.clienteid,
                                    Ciudadid: address.ciudadid,
                                    Idstate: address.idstate,
                                    Createdby: address.createdby,
                                    Modifiedby: address.modifiedby,
                                    Idbusinessunit: address.idowner,
                                    Idowner: address.idowner,
                                    Direccion: address.direccion,
                                    Principal: address.principal
                                },
                                Contactos: {
                                    Id: contact.id,
                                    Nombre: contact.nombre,
                                    Telefono: contact.telefono,
                                    Celular: contact.celular,
                                    Email: contact.email,
                                    Clienteid: contact.clienteid,
                                    Idstate: contact.idstate,
                                    Createdby: contact.createdby,
                                    Modifiedby: contact.modifiedby,
                                    Idbusinessunit: contact.idbusinessunit,
                                    Idowner: contact.idowner
                                }
                            },
                            Numeracion: {
                                Id: objInvoice.resolutionid,
                                Resolucion: objInvoice.Resolution.resolucion,
                                Fechainicio: objInvoice.Resolution.fechainicio,
                                Fechafinal: objInvoice.Resolution.fechafinal,
                                Prefijo: objInvoice.Resolution.prefijo,
                                Consecinicial: objInvoice.Resolution.consecinicial,
                                Consecfinal: objInvoice.Resolution.consecfinal,
                                Consecactual: objInvoice.Resolution.consecactual,
                                Empresaid: objInvoice.Resolution.empresaid,
                                Diantipomodalidadid: objInvoice.Resolution.diantipomodalidadid,
                                Sucursalempresaid: objInvoice.Resolution.sucursalempresaid,
                                Idstate: objInvoice.Resolution.idstate,
                                Createdby: objInvoice.Resolution.createdby,
                                Modifiedby: objInvoice.Resolution.modifiedby,
                                Idbusinessunit: objInvoice.Resolution.idbusinessunit,
                                Idowner: objInvoice.Resolution.idowner,
                                Nombre: objInvoice.Resolution.nombre
                            }
                        },
                        LstOpdetalledocumento: products,
                        LstCargos: charges,
                        LstDescuentos: discounts
                    });
                }
            }
            //console.log('valores:' + totalinvoice.valorbruto + ' ' + totalinvoice.valorOtrosDescuentos + '' + totalinvoice.valordescuentoreal + ' ' + totalinvoice.detalleDocumento.length);
            //validacion
            if (totalinvoice.detalleDocumento.length === 0) {
                WarningAlert("Debe seleccionar al menos un producto");
            }
            else {

                if (totalinvoice.totalapagar < 0) {
                    WarningAlert("El valor a pagar de la factura no puede ser negativo");
                }
                else {
                    let validacargos = false;
                    if (totalinvoice.valorcargos > 0) {
                        let valorbolsas = 0;
                        totalinvoice.cargos.forEach(charge => {
                            if (charge.descripcion === 'Bolsas') {
                                valorbolsas = valorbolsas + charge.valorcalculado
                            }

                        });
                        //console.log('valor bolsas'+ valorbolsas);

                        if ((totalinvoice.valorbruto - totalinvoice.valorOtrosDescuentos - totalinvoice.valordescuentoreal) < totalinvoice.valorcargos - valorbolsas) {
                            WarningAlert("El valor de los cargos no puede ser superior a la factura con descuentos");
                        }
                        else {
                            validacargos = true;
                        }
                    } else {
                        validacargos = true;
                    }

                    if (validacargos) {
                        ConfirmAlert('Oh...', '¿Desea guardar y enviar la factura?', setPrincipal);
                    }
                }
            }



        }
    }

    const sendQuotation = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;


        if (objInvoice != null) {

            for (i === 0; i < objInvoice.clientInfo.adclientedireccion.length; i++) {
                if (objInvoice.clientInfo.adclientedireccion[i].id === objInvoice.addressid) {
                    address = {
                        nombmun: objInvoice.clientInfo.adclientedireccion[i].ciudaddescripcion,
                        nombpais: objInvoice.clientInfo.adclientedireccion[i].departamentodescripcion,
                        id: objInvoice.clientInfo.adclientedireccion[i].id,
                        direccion: objInvoice.clientInfo.adclientedireccion[i].direccion,
                        descripcion: objInvoice.clientInfo.adclientedireccion[i].descripcion,
                        Clienteid: objInvoice.clientInfo.adclientedireccion[i].clienteid,
                        Ciudadid: objInvoice.clientInfo.adclientedireccion[i].ciudadid,
                        Idstate: objInvoice.clientInfo.adclientedireccion[i].idstate,
                        Createdby: objInvoice.clientInfo.adclientedireccion[i].createdby,
                        Modifiedby: objInvoice.clientInfo.adclientedireccion[i].modifiedby,
                        Idbusinessunit: objInvoice.clientInfo.adclientedireccion[i].idowner,
                        Idowner: objInvoice.clientInfo.adclientedireccion[i].idowner,
                        Principal: objInvoice.clientInfo.adclientedireccion[i].principal
                    }
                }
            }

            if (address !== {}) {
                address = {
                    nombmun: objInvoice.dirprincipal[0].ciudaddescripcion,
                    nombpais: objInvoice.dirprincipal[0].departamentodescripcion,
                    id: objInvoice.dirprincipal[0].id,
                    direccion: objInvoice.dirprincipal[0].direccion,
                    descripcion: objInvoice.dirprincipal[0].descripcion,
                    Clienteid: objInvoice.dirprincipal[0].clienteid,
                    Ciudadid: objInvoice.dirprincipal[0].ciudadid,
                    Idstate: objInvoice.dirprincipal[0].idstate,
                    Createdby: objInvoice.dirprincipal[0].createdby,
                    Modifiedby: objInvoice.dirprincipal[0].modifiedby,
                    Idbusinessunit: objInvoice.dirprincipal[0].idowner,
                    Idowner: objInvoice.dirprincipal[0].idowner,
                    Principal: objInvoice.dirprincipal[0].principal
                }
            }
            for (j === 0; j < objInvoice.clientInfo.adcontactos.length; j++) {

                if (objInvoice.clientInfo.adcontactos[j].id === objInvoice.contactid) {

                    contact = {
                        id: objInvoice.clientInfo.adcontactos[j].id,
                        nombre: objInvoice.clientInfo.adcontactos[j].nombre,
                        telefono: objInvoice.clientInfo.adcontactos[j].telefono,
                        celular: objInvoice.clientInfo.adcontactos[j].celular,
                        email: objInvoice.clientInfo.adcontactos[j].email,
                        clienteid: objInvoice.clientInfo.adcontactos[j].clienteid,
                        idstate: objInvoice.clientInfo.adcontactos[j].idstate,
                        createdby: objInvoice.clientInfo.adcontactos[j].createdby,
                        modifiedby: objInvoice.clientInfo.adcontactos[j].modifiedby,
                        idbusinessunit: objInvoice.clientInfo.adcontactos[j].idbusinessunit,
                        idowner: objInvoice.clientInfo.adcontactos[j].idowner
                    }
                }
            }

            //console.log('contacto'+ JSON.stringify(contact))
            const setPrincipal = (confirmed) => {

                totalinvoice.detalleDocumento.forEach(element => {
                    sumDiscounts = sumDiscounts + element.valordescuento;
                    products.push({
                        Codigo: element.codigo,
                        Descripcion: element.descripcion,
                        Cantidad: element.cantidad,
                        Valorunitario: element.valorNeto,
                        Porcentajedescuento: element.porcentajedescuento,
                        Valordescuento: element.valordescuento,
                        Id: 0,
                        Dianunidadmedidaid: element.dianunidadmedidaid,
                        Articuloid: element.articuloid,
                        Totaliva: element.totaliva,
                        Totalinc: element.totalinc,
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        Subtotal: element.subtotal,
                        Tipodescuento: element.tipodescuento,
                        Total: element.total,
                        Totaldescuento: element.totaldescuento,
                        Valordescuentocomercial: element.valordescuentocomercial,
                        Prorrateo: element.prorrateo,
                        Totaldescuentoreal: element.totaldescuentoreal,
                        Subtotalreal: element.subtotalreal,
                        Totalivareal: element.totalivareal,
                        Totalincreal: element.totalincreal,
                        Totalreal: element.totalreal
                    })
                });

                totalinvoice.cargos.forEach(charge => {
                    charges.push({
                        Idstate: 0,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Cargoid: charge.id,
                        Descripcion: charge.descripcion,
                        Tipocargo: charge.tipocargo,
                        Valor: charge.valor,
                        Esglobal: false, //no definido
                        Cantidad: charge.cantidad,
                        Valorcalculado: charge.valorcalculado,
                        Valorasumar: charge.valorasumar,
                        Identificador: charge.identificador
                    })
                });

                totalinvoice.otrosdescuentos.forEach(discount => {
                    discounts.push({
                        Idstate: 1,
                        Createdby: 1,
                        Createdon: '1900-01-01',
                        Modifiedby: 1,
                        Modifiedon: '1900-01-01',
                        Idbusinessunit: 1,
                        Idowner: 1,
                        documentoid: 0,
                        Descuentoid: discount.id,
                        descripcion: discount.descripcion,
                        motivoid: discount.motivoid,
                        modelodescuento: discount.modelodescuento,
                        valor: discount.valor,
                        tipodescuento: discount.tipodescuento,
                        valoradescontar: discount.valoradescontar,
                        motivodescuento: discount.motivoDescripcion,
                        identificador: discount.identificador
                    })
                });


                if (confirmed) {
                    setLoading(true);
                    setFormQuoSave({
                        Opdocumento: {
                            fecha: objInvoice.dateInvoice,
                            numeracionid: 0,
                            estadodocumentoid: 0,
                            tipoaceptaciondocumentoid: 1,
                            tipoacusedocumentoid: 3,
                            valorapagar: totalinvoice.totalapagar,
                            dianformapagoid: objInvoice.visibleCredit === true ? 2 : 1,
                            valordescuento: sumDiscounts,
                            valorcargos: 0,
                            subtotal: totalinvoice.valorbruto,
                            valortotal: totalinvoice.totalfactura,
                            valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                            valorcargodocumento: totalinvoice.valorcargos,
                            valoranticipo: 0,
                            valorinc: totalinvoice.valorinc,
                            valoriva: totalinvoice.valoriva,
                            textoobservacion: objInvoice.observations,
                            vendedorid: objInvoice.vendorid,
                            diantipodocumentoelectronicoid: 4,
                            clienteid: objInvoice.clientInfo.id,
                            dianmediopagoid: objInvoice.paymethodid,
                            idstate: 0,
                            createdby: 1,
                            modifiedby: 1,
                            idbusinessunit: 1,
                            idowner: 1,
                            origendocumentoid: 1,
                            Dianconceptonotadebitoid: 0,
                            Dianconceptonotacreditoid: 0,
                            fechavigencia: objInvoice.datePay,
                            favorito: star,
                            empresaid: Empresaid,
                            Incluirvendedor: objInvoice.includeVendor,
                            Valordescuentoreal: totalinvoice.valordescuentoreal,
                            Valorivareal: totalinvoice.valorivareal,
                            Valorincreal: totalinvoice.valorincreal,
                            TotalfacturaReal: totalinvoice.totalfacturaReal,
                            Vendedor: {
                                Id: objInvoice.vendorid.id,
                                Nombres: objInvoice.Vendor.nombres,
                                Idstate: objInvoice.Vendor.idstate,
                                Createdby: objInvoice.Vendor.createdby,
                                Modifiedby: objInvoice.Vendor.modifiedby,
                                Idbusinessunit: objInvoice.Vendor.idbusinessunit,
                                Idowner: objInvoice.Vendor.idowner,
                                Empresaid: objInvoice.Vendor.empresaid,
                                Telefono: objInvoice.Vendor.telefono,
                                Email: objInvoice.Vendor.email
                            },
                            Cliente: {
                                Id: objInvoice.clientInfo.id,
                                Documento: objInvoice.clientInfo.documento,
                                Documentodv: objInvoice.clientInfo.documentodv,
                                Razonsocial: objInvoice.clientInfo.razonsocial,
                                Telefono: objInvoice.clientInfo.telefono,
                                Correoelectronico: objInvoice.clientInfo.correoelectronico,
                                Diantipodocumentoidentidadid: objInvoice.clientInfo.diantipodocumentoidentidadid,
                                Diantipopersonaid: objInvoice.clientInfo.diantipopersonaid,
                                Empresaid: objInvoice.clientInfo.Empresaid,
                                Favorito: objInvoice.clientInfo.Favorito,
                                Idstate: objInvoice.clientInfo.Idstate,
                                createdby: objInvoice.clientInfo.createdby,
                                modifiedby: objInvoice.clientInfo.modifiedby,
                                idbusinessunit: objInvoice.clientInfo.idbusinessunit,
                                idowner: objInvoice.clientInfo.idbusinessunit,
                                Clientedireccion: {
                                    Id: address.id,
                                    Descripcion: address.descripcion,
                                    Clienteid: address.clienteid,
                                    Ciudadid: address.ciudadid,
                                    Idstate: address.idstate,
                                    Createdby: address.createdby,
                                    Modifiedby: address.modifiedby,
                                    Idbusinessunit: address.idowner,
                                    Idowner: address.idowner,
                                    Direccion: address.direccion,
                                    Principal: address.principal
                                },
                                Contactos: {
                                    Id: contact.id,
                                    Nombre: contact.nombre,
                                    Telefono: contact.telefono,
                                    Celular: contact.celular,
                                    Email: contact.email,
                                    Clienteid: contact.clienteid,
                                    Idstate: contact.idstate,
                                    Createdby: contact.createdby,
                                    Modifiedby: contact.modifiedby,
                                    Idbusinessunit: contact.idbusinessunit,
                                    Idowner: contact.idowner
                                }
                            }
                        },
                        LstOpdetalledocumento: products,
                        LstCargos: charges,
                        LstDescuentos: discounts
                    });
                }
            }

            if (totalinvoice.detalleDocumento.length === 0) {
                WarningAlert("Debe seleccionar al menos un producto");
            }
            else {

                if (totalinvoice.totalapagar < 0) {
                    WarningAlert("El valor a pagar de la cotización no puede ser negativo");
                }
                else {
                    let validacargos = false;
                    if (totalinvoice.valorcargos > 0) {
                        let valorbolsas = 0;
                        totalinvoice.cargos.forEach(charge => {
                            if (charge.descripcion === 'Bolsas') {
                                valorbolsas = valorbolsas + charge.valorcalculado
                            }

                        });
                        //console.log('valor bolsas'+ valorbolsas);

                        if ((totalinvoice.valorbruto - totalinvoice.valorOtrosDescuentos - totalinvoice.valordescuentoreal) < totalinvoice.valorcargos - valorbolsas) {
                            WarningAlert("El valor de los cargos no puede ser superior a la cotización con descuentos");
                        }
                        else {
                            validacargos = true;
                        }
                    } else {
                        validacargos = true;
                    }

                    if (validacargos) {
                        ConfirmAlert('Oh...', '¿Desea guardar la cotización?', setPrincipal);
                    }
                }
            }

        }
    }

    const sendPreview = () => {

        let address = {};
        let contact = {};
        let i = 0;
        let j = 0;
        let products = [];
        let charges = [];
        let discounts = [];
        let sumDiscounts = 0;


        if (objInvoice != null) {

            for (i === 0; i < objInvoice.clientInfo.adclientedireccion.length; i++) {
                if (objInvoice.clientInfo.adclientedireccion[i].id === objInvoice.addressid) {
                    address = {
                        nombmun: objInvoice.clientInfo.adclientedireccion[i].ciudaddescripcion,
                        nombpais: objInvoice.clientInfo.adclientedireccion[i].departamentodescripcion,
                        id: objInvoice.clientInfo.adclientedireccion[i].id,
                        direccion: objInvoice.clientInfo.adclientedireccion[i].direccion,
                        descripcion: objInvoice.clientInfo.adclientedireccion[i].descripcion,
                        Clienteid: objInvoice.clientInfo.adclientedireccion[i].clienteid,
                        Ciudadid: objInvoice.clientInfo.adclientedireccion[i].ciudadid,
                        Idstate: objInvoice.clientInfo.adclientedireccion[i].idstate,
                        Createdby: objInvoice.clientInfo.adclientedireccion[i].createdby,
                        Modifiedby: objInvoice.clientInfo.adclientedireccion[i].modifiedby,
                        Idbusinessunit: objInvoice.clientInfo.adclientedireccion[i].idowner,
                        Idowner: objInvoice.clientInfo.adclientedireccion[i].idowner,
                        Principal: objInvoice.clientInfo.adclientedireccion[i].principal
                    }
                }
            }

            if (address !== {}) {
                address = {
                    nombmun: objInvoice.dirprincipal[0].ciudaddescripcion,
                    nombpais: objInvoice.dirprincipal[0].departamentodescripcion,
                    id: objInvoice.dirprincipal[0].id,
                    direccion: objInvoice.dirprincipal[0].direccion,
                    descripcion: objInvoice.dirprincipal[0].descripcion,
                    Clienteid: objInvoice.dirprincipal[0].clienteid,
                    Ciudadid: objInvoice.dirprincipal[0].ciudadid,
                    Idstate: objInvoice.dirprincipal[0].idstate,
                    Createdby: objInvoice.dirprincipal[0].createdby,
                    Modifiedby: objInvoice.dirprincipal[0].modifiedby,
                    Idbusinessunit: objInvoice.dirprincipal[0].idowner,
                    Idowner: objInvoice.dirprincipal[0].idowner,
                    Principal: objInvoice.dirprincipal[0].principal
                }
            }
            for (j === 0; j < objInvoice.clientInfo.adcontactos.length; j++) {

                if (objInvoice.clientInfo.adcontactos[j].id === objInvoice.contactid) {

                    contact = {
                        id: objInvoice.clientInfo.adcontactos[j].id,
                        nombre: objInvoice.clientInfo.adcontactos[j].nombre,
                        telefono: objInvoice.clientInfo.adcontactos[j].telefono,
                        celular: objInvoice.clientInfo.adcontactos[j].celular,
                        email: objInvoice.clientInfo.adcontactos[j].email,
                        clienteid: objInvoice.clientInfo.adcontactos[j].clienteid,
                        idstate: objInvoice.clientInfo.adcontactos[j].idstate,
                        createdby: objInvoice.clientInfo.adcontactos[j].createdby,
                        modifiedby: objInvoice.clientInfo.adcontactos[j].modifiedby,
                        idbusinessunit: objInvoice.clientInfo.adcontactos[j].idbusinessunit,
                        idowner: objInvoice.clientInfo.adcontactos[j].idowner
                    }
                }
            }


            totalinvoice.detalleDocumento.forEach(element => {
                sumDiscounts = sumDiscounts + element.valordescuento;
                products.push({
                    Codigo: element.codigo,
                    Descripcion: element.descripcion,
                    Cantidad: element.cantidad,
                    Valorunitario: element.valorNeto,
                    Porcentajedescuento: element.porcentajedescuento,
                    Valordescuento: element.valordescuento,
                    Id: 0,
                    Dianunidadmedidaid: element.dianunidadmedidaid,
                    Articuloid: element.articuloid,
                    Totaliva: element.totaliva,
                    Totalinc: element.totalinc,
                    Idstate: 0,
                    Createdby: 1,
                    Createdon: '1900-01-01',
                    Modifiedby: 1,
                    Modifiedon: '1900-01-01',
                    Idbusinessunit: 1,
                    Idowner: 1,
                    Subtotal: element.subtotal,
                    Tipodescuento: element.tipodescuento,
                    Total: element.total,
                    Totaldescuento: element.totaldescuento,
                    Valordescuentocomercial: element.valordescuentocomercial,
                    Prorrateo: element.prorrateo,
                    Totaldescuentoreal: element.totaldescuentoreal,
                    Subtotalreal: element.subtotalreal,
                    Totalivareal: element.totalivareal,
                    Totalincreal: element.totalincreal,
                    Totalreal: element.totalreal
                })
            });

            totalinvoice.cargos.forEach(charge => {
                charges.push({
                    Idstate: 0,
                    Createdby: 1,
                    Createdon: '1900-01-01',
                    Modifiedby: 1,
                    Modifiedon: '1900-01-01',
                    Idbusinessunit: 1,
                    Idowner: 1,
                    documentoid: 0,
                    Cargoid: charge.id,
                    Descripcion: charge.descripcion,
                    Tipocargo: charge.tipocargo,
                    Valor: charge.valor,
                    Esglobal: false, //no definido
                    Cantidad: charge.cantidad,
                    Valorcalculado: charge.valorcalculado,
                    Valorasumar: charge.valorasumar,
                    Identificador: charge.identificador
                })
            });

            totalinvoice.otrosdescuentos.forEach(discount => {
                discounts.push({
                    Idstate: 1,
                    Createdby: 1,
                    Createdon: '1900-01-01',
                    Modifiedby: 1,
                    Modifiedon: '1900-01-01',
                    Idbusinessunit: 1,
                    Idowner: 1,
                    documentoid: 0,
                    Descuentoid: discount.id,
                    descripcion: discount.descripcion,
                    motivoid: discount.motivoid,
                    modelodescuento: discount.modelodescuento,
                    valor: discount.valor,
                    tipodescuento: discount.tipodescuento,
                    valoradescontar: discount.valoradescontar,
                    motivodescuento: discount.motivoDescripcion,
                    identificador: discount.identificador
                })
            });


            setFormPreview({
                Opdocumento: {
                    fecha: objInvoice.dateInvoice,
                    numeracionid: objInvoice.resolutionid,
                    estadodocumentoid: 0,
                    tipoaceptaciondocumentoid: 1,
                    tipoacusedocumentoid: 3,
                    valorapagar: totalinvoice.totalapagar,
                    dianformapagoid: objInvoice.visibleCredit === true ? 2 : 1,
                    valordescuento: sumDiscounts,
                    valorcargos: 0,
                    subtotal: totalinvoice.valorbruto,
                    valortotal: totalinvoice.totalfactura,
                    valordescuentodocumento: totalinvoice.valorOtrosDescuentos,
                    valorcargodocumento: totalinvoice.valorcargos,
                    valoranticipo: 0,
                    valorinc: totalinvoice.valorinc,
                    valoriva: totalinvoice.valoriva,
                    textoobservacion: objInvoice.observations,
                    vendedorid: objInvoice.vendorid,
                    diantipodocumentoelectronicoid: 1,
                    clienteid: objInvoice.clientInfo.id,
                    dianmediopagoid: objInvoice.paymethodid,
                    idstate: 0,
                    createdby: 1,
                    modifiedby: 1,
                    idbusinessunit: 1,
                    idowner: 1,
                    origendocumentoid: 1,
                    Dianconceptonotadebitoid: 0,
                    Dianconceptonotacreditoid: 0,
                    fechapago: objInvoice.datePay,
                    favorito: star,
                    empresaid: Empresaid,
                    Incluirvendedor: objInvoice.includeVendor,
                    Valordescuentoreal: totalinvoice.valordescuentoreal,
                    Valorivareal: totalinvoice.valorivareal,
                    Valorincreal: totalinvoice.valorincreal,
                    TotalfacturaReal: totalinvoice.totalfacturaReal,
                    Archivovisualizar: 'INVOICE',
                    Vendedor: {
                        Id: objInvoice.vendorid.id,
                        Nombres: objInvoice.Vendor.nombres,
                        Idstate: objInvoice.Vendor.idstate,
                        Createdby: objInvoice.Vendor.createdby,
                        Modifiedby: objInvoice.Vendor.modifiedby,
                        Idbusinessunit: objInvoice.Vendor.idbusinessunit,
                        Idowner: objInvoice.Vendor.idowner,
                        Empresaid: objInvoice.Vendor.empresaid,
                        Telefono: objInvoice.Vendor.telefono,
                        Email: objInvoice.Vendor.email
                    },
                    Cliente: {
                        Id: objInvoice.clientInfo.id,
                        Documento: objInvoice.clientInfo.documento,
                        Documentodv: objInvoice.clientInfo.documentodv,
                        Razonsocial: objInvoice.clientInfo.razonsocial,
                        Telefono: objInvoice.clientInfo.telefono,
                        Correoelectronico: objInvoice.clientInfo.correoelectronico,
                        Diantipodocumentoidentidadid: objInvoice.clientInfo.diantipodocumentoidentidadid,
                        Diantipopersonaid: objInvoice.clientInfo.diantipopersonaid,
                        Empresaid: objInvoice.clientInfo.Empresaid,
                        Favorito: objInvoice.clientInfo.Favorito,
                        Idstate: objInvoice.clientInfo.Idstate,
                        createdby: objInvoice.clientInfo.createdby,
                        modifiedby: objInvoice.clientInfo.modifiedby,
                        idbusinessunit: objInvoice.clientInfo.idbusinessunit,
                        idowner: objInvoice.clientInfo.idbusinessunit,
                        Clientedireccion: {
                            Id: address.id,
                            Descripcion: address.descripcion,
                            Clienteid: address.clienteid,
                            Ciudadid: address.ciudadid,
                            Idstate: address.idstate,
                            Createdby: address.createdby,
                            Modifiedby: address.modifiedby,
                            Idbusinessunit: address.idowner,
                            Idowner: address.idowner,
                            Direccion: address.direccion,
                            Principal: address.principal
                        },
                        Contactos: {
                            Id: contact.id,
                            Nombre: contact.nombre,
                            Telefono: contact.telefono,
                            Celular: contact.celular,
                            Email: contact.email,
                            Clienteid: contact.clienteid,
                            Idstate: contact.idstate,
                            Createdby: contact.createdby,
                            Modifiedby: contact.modifiedby,
                            Idbusinessunit: contact.idbusinessunit,
                            Idowner: contact.idowner
                        }
                    },
                    Numeracion: {
                        Id: objInvoice.resolutionid,
                        Resolucion: objInvoice.Resolution.resolucion,
                        Fechainicio: objInvoice.Resolution.fechainicio,
                        Fechafinal: objInvoice.Resolution.fechafinal,
                        Prefijo: objInvoice.Resolution.prefijo,
                        Consecinicial: objInvoice.Resolution.consecinicial,
                        Consecfinal: objInvoice.Resolution.consecfinal,
                        Consecactual: objInvoice.Resolution.consecactual,
                        Empresaid: objInvoice.Resolution.empresaid,
                        Diantipomodalidadid: objInvoice.Resolution.diantipomodalidadid,
                        Sucursalempresaid: objInvoice.Resolution.sucursalempresaid,
                        Idstate: objInvoice.Resolution.idstate,
                        Createdby: objInvoice.Resolution.createdby,
                        Modifiedby: objInvoice.Resolution.modifiedby,
                        Idbusinessunit: objInvoice.Resolution.idbusinessunit,
                        Idowner: objInvoice.Resolution.idowner,
                        Nombre: objInvoice.Resolution.nombre
                    }
                },
                LstOpdetalledocumento: products,
                LstCargos: charges,
                LstDescuentos: discounts
            });
            setOpenModalFile(true);
        }
    }


    const confirmSendOk = (response) => {

        if (response.data.statusCode == "201"){
            setLoading(false);
            setModalResponse({
                type: 'success',
                title: 'Fenomenal!!!',
                subtitle: 'Has generado una nueva factura',
                body: (<div><div>{response.data.statusMessage.indexOf('Rechazo') !== -1 ? 'Factura generada con error al enviar a Dian' : 'Proceso Exitoso'}</div>
                    <div class="fenomenalWhiteLittle">{response.data.statusMessage} <span className="fenomenalBlueLittle">No. {response.data.result}</span></div></div>),
                modalImage: 'success',
                open: true,
                closeElement: 'Saltar',
                onCloseElement: 1
            });
        }
        else{
            setLoading(false);
            setModalResponse({
                type: 'warning',
                title: 'Oops!!!',
                subtitle: 'Ocurrió algo inesperado',
                body: (<div><div>{'Factura generada con error al enviar a Dian'}</div>
                    <div class="fenomenalWhiteLittle">{response.data.statusMessage} Factura Generada: <span className="fenomenalBlueLittle">No. {response.data.result}</span></div></div>),
                modalImage: 'warning',
                open: true,
                closeElement: 'Saltar',
                onCloseElement: 1
            });
        }    
    }

    const confirmError = (response) => {

        console.log('ERROR' + JSON.stringify(response))
        setLoading(false);
        setModalResponse({
            type: 'warning',
            title: 'Oops!!!',
            subtitle: 'Ocurrió algo inesperado',
            body: (<div><div>
                {JSON.stringify(response === undefined ? 'Error inesperado' : response.data.statusMessage)}
            </div></div>),
            modalImage: 'warning',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 0
        });
        //WarningAlert("Error al guardar la factura!!" + JSON.stringify(response.message === undefined ? response.StatusMessage : response.message));
    }

    const confirmSendQuoOk = (response) => {

        setLoading(false);
        setModalResponse({
            type: 'success',
            title: 'Perfecto!!!',
            subtitle: 'Has generado una nueva cotización',
            body: (<div><div>Perfecto, tenemos un cliente interesado, hay que convencerlo.</div>
                <div class="fenomenalWhiteLittle">Se genero exitosamente la cotización <span className="fenomenalBlueLittle">No. {response.data.result}</span></div></div>),
            modalImage: 'quotation',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 1
        });

    }

    const confirmErrorQuo = (response) => {

        setLoading(false);
        setModalResponse({
            type: 'warning',
            title: 'Oops!!!',
            subtitle: 'Ocurrió algo inesperado',
            body: (<div><div>
                {JSON.stringify(response.data.statusMessage)}
            </div></div>),
            modalImage: 'warning',
            open: true,
            closeElement: 'Saltar',
            onCloseElement: 0
        });
    }
    const handleResponseModal = (rta) => {
        //console.log('llego a modal cerrar');
        if (rta === 1) {
            localStorage.removeItem('itemsInCart');
            //console.log('borrando storage');
            //localStorage.setItem('itemsInCart', JSON.stringify([]))
            props.setStatusShowCartAction(false);
            //console.log('borrando cargos');
            props.clearLoadedChargeInvoiceActionAll();
            props.clearLoadedDiscountInvoiceActionAll();
            //console.log('cambiando ventana');
            history.push({
                pathname: '/document',
                state: {
                    redirect: 1
                }
            });
        }
        setModalResponse({
            type: '',
            title: '',
            subtitle: '',
            body: '',
            modalImage: '',
            open: false,
            closeElement: '',
            onCloseElement: ''
        });

    }

    const handleItemState = (state) => {
        setFootShow(state);
    }

    const handleCloseModalFile = () => {
        setOpenModalFile(false);
    };

    const footPage = (step) => {
        return (
            <div className='bottomButtonsInvoiceRevision'>
                <Grid container >
                    <Grid item lg={2} md={2} sm={2}>
                        <div className="backInvoice" onClick={handleStep(step)}><ArrowBackIosIcon></ArrowBackIosIcon>Volver</div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1}>

                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div >
                            <div style={{ display: star === true ? 'block' : 'none' }}>
                                <StarIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(false)} ></StarIcon>
                            </div>
                            <div style={{ display: star === false ? 'block' : 'none' }}>
                                <StarBorderIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(true)} ></StarBorderIcon>
                            </div>
                            <div className="textButtonsInvoice">Destacar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <div title="Previsualizar PDF">
                            <VisibilityIcon className="buttonInvoiceBehavior" onClick={() => sendPreview()}></VisibilityIcon><br></br>
                            <div className="textButtonsInvoice">Previsualizar</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                        <ClearIcon className="buttonInvoiceBehavior" onClick={() => handleResponseModal(1)}></ClearIcon><br></br>
                        <div className="textButtonsInvoice">Cancelar</div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    useEffect(() => {
        if (objInvoice != null) {
            let newCompleted = new Set(completed);
            newCompleted.add(0);
            //newCompleted.add(1);
            setCompleted(newCompleted);
            setActiveStep(1);
        }

    }, [objInvoice]);

    useEffect(() => {
        if (formSave !== null) {
            //console.log('response-->'+JSON.stringify(formSave))
            props.createElectronicInvoice(formSave, confirmSendOk, confirmError);

        }
    }, [formSave]);

    useEffect(() => {
        if (formQuoSave !== null) {
            //console.log('quotation-->'+JSON.stringify(formQuoSave))
            props.createQoutation(formQuoSave, confirmSendQuoOk, confirmErrorQuo);

        }
    }, [formQuoSave]);

    useEffect(() => {
        if (loading) {
            setModalResponse({
                type: 'success',
                title: '',
                subtitle: 'Generando documento, por favor espere...',
                body: (<div><div>
                    <Fragment>  <CustomProgress />{'Procesando'} </Fragment>
                </div></div>),
                modalImage: 'noimagen',
                open: true,
                closeElement: '',
                onCloseElement: 0
            });


        }
    }, [loading]);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (<InvoiceForm CancelCreate={handleResponseModal} NextStep={nextStepForm} ApproveStepInfo={approveStepInfo} Empresaid={Empresaid} Userid={Userid} objHead={objInvoice}></InvoiceForm>);
            case 1:
                return (<div className="panelColorInvoice">
                    {/* Enviar paso activo a componente hijo */}
                    <InvoiceItem HandleItemState={handleItemState}></InvoiceItem>
                    <div>
                        <div className={footShow ? '' : 'panelInvisibleInvoice'}>
                            <Grid container>
                                <Grid item lg={3} xs={12}>
                                    <button
                                        className="buttonSecondary"
                                        type="button"
                                        onClick={() => sendQuotation()}
                                        disabled={isValidDocument === true ? false : true}>
                                        Guardar como cotización
                                    </button>
                                </Grid>
                                <Grid item lg={1} md={1} sm={12} xs={12}>
                                </Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <button
                                        className="buttonInvoiceConfirm"
                                        type="button"
                                        onClick={handleStep(2)}
                                        disabled={isValidDocument === true ? false : true}>
                                        Revisar factura
                                    </button>
                                </Grid>
                                <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <button
                                        className="buttonInvoiceConfirm"
                                        type="button"
                                        disabled={isValidDocument === true ? false : true}
                                        onClick={() => sendInvoice()}>
                                        Enviar factura
                                    </button>
                                </Grid>
                                <Grid item lg={1} md={1} sm={12} xs={12}>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="spacingInvoice"></div>
                    {footPage(0)}
                </div>);
            case 2:
                return (<div className="panelColorInvoice">
                    <InvoiceRevision ObjId={0}
                        heightDiv={heightDiv}
                        objHead={objInvoice}
                        objItems={totalinvoice}
                        CancelCreate={cancelCreate}
                        Empresaid={Empresaid}
                        Userid={Userid}>
                    </InvoiceRevision>
                    <div className="spacingInvoice"></div>
                    <Grid container>
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <button
                                className="buttonSecondary"
                                type="button"
                                onClick={() => sendQuotation()}
                                disabled={isValidDocument === true ? false : true}>
                                Guardar como cotización
                                </button>
                        </Grid>
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <button
                                className="buttonInvoiceConfirm"
                                type="button"
                                onClick={() => sendInvoice()}
                                disabled={isValidDocument === true ? false : true}>
                                Enviar factura
                                 </button>
                        </Grid>
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                        </Grid>
                    </Grid>
                    <div className="spacingInvoice"></div>
                    {footPage(1)}
                </div>);
            default:
                return 'Unknown step';
        }
    }
    return (
        <div className="container-form" xs={12} lg={12}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={< QontoConnector />} className={classes.root} >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    return (
                        <Step key={label} {...stepProps} className={activeStep === index ? classes.activeButton : ''}>
                            <StepButton
                                // onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                {...buttonProps}
                            >
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    <label className="stepper-title">{label}</label>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                <div>{getStepContent(activeStep)}</div>
            </div>
            <ResponseModal
                modalType={modalResponse.modalType}
                title={modalResponse.title}
                subtitle={modalResponse.subtitle}
                body={modalResponse.body}
                modalImage={modalResponse.modalImage}
                open={modalResponse.open}
                closeElement={modalResponse.closeElement}
                onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
            ></ResponseModal>
            <FileModalData
                open={openModalFile}
                onCloseElement={handleCloseModalFile}
                closeElement={"Cerrar"}
                paramFile={formPreview}
            >
            </FileModalData>
        </div>
    );
}

InvoiceCreate.propTypes = {
    invoiceReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    invoiceReducer: state.invoiceReducer,
    sucessupdate: state.invoiceReducer.sucess,
    create: state.invoiceReducer.create,
    totalinvoice: state.valuesInvoiceReducer.totalDetail,
    createquotation: state.invoiceReducer.create,
    isValidDocument: state.valuesInvoiceReducer.isValidDocument
});

export default connect(mapStateToProps, {
    updateDocument,
    createElectronicInvoice,
    getTotalAction,
    setStatusShowCartAction,
    createQoutation,
    clearLoadedChargeInvoiceActionAll,
    clearLoadedDiscountInvoiceActionAll,
    setActiveStepInvoiceAction
})(InvoiceCreate);