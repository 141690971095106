import React, { Component } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { regexAlphaNumericAddress } from '../../../helpers/regex.helper'
import InputForm from "../../../components/input/input.component";
import SwitchForm from '../../../components/switch/switch.component.js';
import AutoCompleteForm from '../../../components/autocomplete/autocomplete.component'
import MultiSelectForm from "../../../components/multiselect/multiselect.component";
import ButtonPrimary from '../../../components/button/buttonPrimary.component'


import { updateBranchOfficeAction, saveBranchOfficeAction, loadBranchOfficeAction, getBranchOfficeAction } from "../../../actions/branchOfficeActions"
import { updateNumerationAction, getNumerationsFilterAction } from "../../../actions/numerationActions"
import { getStatesAction, getAllCitiesAction } from "../../../actions/configAction"


import { SuccessAlert, WarningAlert, ConfirmAlert } from "../../../helpers/alert.helpers"
import { getComponentName } from "../../../utils/general";
import isEmpty from '../../../utils/isEmpty'

class FormBranchOffice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            branchOfficesList: [],
            requestCompany: {},
            components: [],
            modalities: [],
            numerations: [],
            numerationsFree: [],
            numerationsOptions: [],
            principal: {},
            oldPrincipal: {},
            editBranchStatus: false,
            loadBranchOffice: {},
            responseSaveBranchOffice: [],
            responseUpdateBranchOffice: [],
            form: {
                nombre: '',
                principal: false,
                numeraciones: [],
                direccion: '',
                departamentoid: null,
                ciudadid: null,
                idstate: true,
            },
            loadingSaveBranch: false,
            getStatesResponse:[],
            allCitiesCountry:[],
            cities: [],
        }
        this.validator = new SimpleReactValidator(reactValidatorOptions)
    }

    componentDidMount() {
        this.props.getStatesAction();
        this.props.getAllCitiesAction();
        this.props.getNumerationsFilterAction();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.configReducer.getStatesResponse && nextProps.configReducer.getStatesResponse !== state.getStatesResponse)
            update.getStatesResponse = nextProps.configReducer.getStatesResponse;

        if (!!nextProps.configReducer.allCitiesCountry && nextProps.configReducer.allCitiesCountry !== state.allCitiesCountry)
            update.allCitiesCountry = nextProps.configReducer.allCitiesCountry;

        if (!!nextProps.numerationReducer.numerations && nextProps.numerationReducer.numerations !== state.numerations) {
            update.numerations = nextProps.numerationReducer.numerations;
            update.numerationsOptions = nextProps.numerationReducer.numerations.filter(n => n.sucursalempresaid === null && n.idstate === 0);
        }

        if (!!nextProps.numerationReducer.modalities) {
            if (nextProps.numerationReducer.modalities !== state.modalities) {
                update.modalities = nextProps.numerationReducer.modalities
            }
        }

        if (!!nextProps.configReducer.components && nextProps.configReducer.components !== state.components) {
            update.components = nextProps.configReducer.components;
        }

        if (!!nextProps.basicDataReducer.requestCompany && nextProps.basicDataReducer.requestCompany !== state.requestCompany) {
            update.requestCompany = nextProps.basicDataReducer.requestCompany
        }

        if (!!nextProps.branchOfficeReducer.branchOfficesList && nextProps.branchOfficeReducer.branchOfficesList !== state.branchOfficesList) {
            update.branchOfficesList = nextProps.branchOfficeReducer.branchOfficesList;
            update.principal = nextProps.branchOfficeReducer.branchOfficesList.find(o => o.principal === true)
        }

        if (!isEmpty(nextProps.branchOfficeReducer.loadBranchOffice) && nextProps.branchOfficeReducer.loadBranchOffice !== state.loadBranchOffice) {
            update.loadBranchOffice = nextProps.branchOfficeReducer.loadBranchOffice;
        }

        if (!isEmpty(nextProps.branchOfficeReducer.editBranchStatus) && nextProps.branchOfficeReducer.editBranchStatus !== state.editBranchStatus) {
            update.editBranchStatus = nextProps.branchOfficeReducer.editBranchStatus;
        }

        if (!!nextProps.branchOfficeReducer.responseSaveBranchOffice && nextProps.branchOfficeReducer.responseSaveBranchOffice !== state.responseSaveBranchOffice) {
            update.responseSaveBranchOffice = nextProps.branchOfficeReducer.responseSaveBranchOffice
        }

        if (!!nextProps.branchOfficeReducer.responseUpdateBranchOffice && nextProps.branchOfficeReducer.responseUpdateBranchOffice !== state.responseUpdateBranchOffice) {
            update.responseUpdateBranchOffice = nextProps.branchOfficeReducer.responseUpdateBranchOffice
        }

        if (!isEmpty(nextProps.branchOfficeReducer.loadingSaveBranch) && nextProps.branchOfficeReducer.loadingSaveBranch !== state.loadingSaveBranch)
            update.loadingSaveBranch = nextProps.branchOfficeReducer.loadingSaveBranch;

        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.responseSaveBranchOffice !== this.state.responseSaveBranchOffice) {
            if (this.state.responseSaveBranchOffice.statusCode === '200' ||
                this.state.responseSaveBranchOffice.statusCode === '201') {
                SuccessAlert(getComponentName(this.state.components, 24, 89, 'Perfecto!!!'), getComponentName(this.state.components, 24, 90, 'Creaste una nueva sucursal para tu empresa.'));
                this.props.getBranchOfficeAction()
                this.props.getNumerationsFilterAction()
                this.cleanData();
            }
        }

        if (prevState.responseUpdateBranchOffice !== this.state.responseUpdateBranchOffice) {
            if (this.state.responseUpdateBranchOffice.statusCode === '200' ||
                this.state.responseUpdateBranchOffice.statusCode === '201') {
                SuccessAlert(getComponentName(this.state.components, 25, 93, 'Buen trabajo!!!'), getComponentName(this.state.components, 25, 94, 'Actualizaste la Sucursal $nombreSucursal de manera exitosa, sigamos facturando.').replace('$nombreSucursal', this.state.loadBranchOffice.nombre));
                this.props.getBranchOfficeAction()
                this.props.getNumerationsFilterAction()
                this.cleanData();
            }
        }

        if (prevState.loadBranchOffice !== this.state.loadBranchOffice) {
            if (!!this.state.loadBranchOffice) {
                this.loadInfo(this.state.loadBranchOffice);
            }
        }
    }

    /**
     * Sincroniza nuevo valor de input con state
     * @param {*} e Evento
     */
    syncChanges = (e) => {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    };

    /**
     * Sincroniza nuevo valor de checkBox con state
     * @param {*} e Evento
     */
    syncCheckChange = (e) => {
        const name = e.target.name;
        let checked = e.target.checked;
        if (name === 'principal') {
            this.changePrincipal(checked)
        } else if (name === 'idstate') {
            this.changeState(checked)
        }
    };



    /**
     * Limpia información de formulario
     */
    cleanData() {
        this.setState(({
            form: {
                nombre: '',
                principal: false,
                numeraciones: [],
                direccion: '',
                departamentoid: '',
                ciudadid: '',
                idstate: true,
            },
            loadBranchOffice:null
        }))
        this.validator.hideMessages()
        this.forceUpdate()

        this.props.loadBranchOfficeAction(null);
    }


    /**
     * Actualiza sucursal principal
     * @param {*} checked NUevo valor
     */
    changePrincipal = (checked) => {
        if (!!this.state.principal.id && this.state.principal.id !== this.state.oldPrincipal.id
            && (this.state.loadBranchOffice.id !== this.state.principal.id || !this.state.editBranchStatus)) {
            if (checked) {
                const setPrincipal = (confirmed) => {
                    if (confirmed) {
                        this.setState((prevState) => ({
                            oldPrincipal: prevState.principal,
                            form: {
                                ...prevState.form,
                                principal: true
                            }
                        }))
                    }
                }
                ConfirmAlert('Cambiar sucursal', '¿Desea cambiar la sucursal principal?', setPrincipal)
            }
        } else {
            this.setState((prevState) => ({
                oldPrincipal: {},
                form: {
                    ...prevState.form,
                    principal: false
                }
            }))
        }
    }

    /**
     * Actualiza estado de una sucursal
     * @param {*} checked Nuevo estado
     */
    changeState = (checked) => {
        if (!checked && this.state.form.principal) {
            WarningAlert('Upss...!!!', 'Debe existir una sucursal principal.');
        } else {
            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    idstate: checked
                }
            }))
        }
    }


    /**
     * Sincroniza componente multiple select con state
     * @param {*} event
     */
    syncMultipleChange = (event) => {
        const options = event.target;
        const value = [];

        for (let index = 0; index < options.value.length; index++) {
            value.push(options.value[index]);
        }

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [options.name]: value
            },
        }));
    };


    // /**
    //  * Sincroniza estado de multiselección
    //  * @param {*} event Evento
    //  */
    // syncMultipleChange = event => {
    //     const options = event.target
    //     const value = [];
    //     let newNumerationsFree = !!this.state.loadBranchOffice.numeraciones ? this.state.loadBranchOffice.numeraciones : []
    //     for (let index = 0; index < options.value.length; index++) {
    //         if (!!options.value[index]) {
    //             value.push(options.value[index]);
    //             if (!!this.state.loadBranchOffice.numeraciones) {
    //                 if (newNumerationsFree.includes(options.value[index])) {
    //                     newNumerationsFree = newNumerationsFree.filter(numeracion => numeracion.id !== options.value[index].id)
    //                 }
    //             }
    //         }
    //     }
    //     this.setState((prevState) => ({
    //         form: {
    //             ...prevState.form,
    //             [options.name]: value
    //         },
    //         numerationsFree: newNumerationsFree,
    //     }))
    // };

    /**
     * Sincroniza Autocmplete con state
     * @param {*} value Nuevo valor 
     * @param {*} id Identificador propiedad state
     */
    syncAutoCompleteChanges(value, id) {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }


    /**
     * Elimina caracteres invalidos, solo permite valores permitidos en una dirección
     * @param {*} e Evento
     */
    validateAddress(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexAlphaNumericAddress;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    /**
     * Evento cambio departamento seleccionado
     * @param {*} event Evento
     * @param {*} values Nuevo valor
     */
     changeBranchState(event, values) {
        event.preventDefault();
        let newCities = [];
        if (!!values)
            newCities = this.state.allCitiesCountry.filter(c => c.departamentoid === parseInt(values.value));
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                ciudadid: null,
            },
            cities: newCities,
        }))
    }

    /**
     * Evento, Guarda información de sucursal y actualiza numeraciones liberadas
     * @param {*} e Evento
     */
    onSubmitBranchOffice = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            if (this.state.editBranchStatus) {

                let branch = this.state.branchOfficesList.filter(b => b.id !== this.state.loadBranchOffice.id).find(b => b.nombre.toLowerCase().trim() === this.state.form.nombre.toLowerCase().trim());
                if (branch && Object.keys(branch).length) {
                    WarningAlert("Sucursal existente....!", `Ya se encuentra registrada una sucursal con el nombre ${this.state.form.nombre} `);
                    return;
                }

                this.updateFreeNumerations()
                this.updateBranchOffices()
            } else {

                let branch = this.state.branchOfficesList.find(b => b.nombre.toLowerCase() === this.state.form.nombre.toLowerCase());
                if (branch && Object.keys(branch).length) {
                    WarningAlert("Sucursal existente....!", `Ya se encuentra registrada una sucursal con el nombre ${this.state.form.nombre} `);
                    return;
                }
    
                this.saveBranchOffice()
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    /**
     * Actualiza numeraciónes liberadas,
     * sin asinación de sucursal
     */
    updateFreeNumerations = () => {
        for (let i = 0; i < this.state.numerationsFree.length; i++) {
            let item = this.state.numerationsFree[i];
            item = {
                ...item,
                sucursalempresaid: null
            }
            this.props.updateNumerationAction(item);
        }
    }

    /**
     * Captura y actualiza información de sucursal
     */
    updateBranchOffices = () => {
        let newData = [
            {
                ...this.state.loadBranchOffice,
                nombre: this.state.form.nombre,
                principal: this.state.form.principal,
                numeraciones: this.state.form.numeraciones,
                direccion: this.state.form.direccion,
                ciudadid: this.state.form.ciudadid.value,
                idstate: this.state.form.idstate ? 0 : 1,
            },
        ]
        if (this.state.oldPrincipal.id !== null && this.state.oldPrincipal.id !== undefined) {
            newData = [
                ...newData,
                {
                    ...this.state.oldPrincipal,
                    principal: false
                }
            ]
        }
        this.props.updateBranchOfficeAction(newData);
    }

    //Carga información en formulario para edición
    loadInfo(data) {

        let city = this.state.allCitiesCountry.filter(c => c.value === data.ciudadid.toString());
        let stateid = !!city && city.length ? city[0].departamentoid : 0;
        let state = this.state.getStatesResponse.filter(s => s.value === stateid.toString());
        let cities = this.state.allCitiesCountry.filter(c => c.departamentoid === stateid);

        this.setState((prevState) => ({
            form: {
                nombre: data.nombre,
                principal: data.principal,
                numeraciones: data.numeraciones,
                direccion: data.direccion,
                departamentoid: state.length ? state[0] : null,
                ciudadid: city.length ? city[0] : null,
                idstate: data.idstate === 0 ? true : false,
            },
            cities: cities,
        }));
    }

    /**
     * Captura y gurda información de sucursal
     */
    saveBranchOffice = () => {
        let newData = [
            {
                nombre: this.state.form.nombre,
                principal: this.state.form.principal,
                numeraciones: this.state.form.numeraciones,
                direccion: this.state.form.direccion,
                ciudadid: this.state.form.ciudadid.value,
                idstate: this.state.form.idstate ? 0 : 1
            },
        ]
        if (this.state.oldPrincipal.id !== null && this.state.oldPrincipal.id !== undefined) {
            newData = [
                ...newData,
                {
                    ...this.state.oldPrincipal,
                    principal: false
                }
            ]
        }
        this.props.saveBranchOfficeAction(newData);
    }

    /**
     * Obtine descripción modalidad
     * @param {*} numeration 
     */
    getNumerationName = (numeration) => {
        if (!!numeration) {
            const modality = this.state.modalities.find(x => x.id === numeration.diantipomodalidadid);
            return !!numeration.nombre ? modality.descripcion + '- Prefijo:' + (!!numeration.prefijo ? numeration.prefijo : 'Sin prefijo') + ' - ' + numeration.nombre : modality.descripcion + '- Prefijo:' + (!!numeration.prefijo ? numeration.prefijo : 'Sin prefijo')
        } else {
            return '';
        }
    }

    render() {
        //Numeraciones disponibles.
        let numerationsAvailable = this.state.numerationsOptions;
        if (this.state.editBranchStatus) {

            this.state.loadBranchOffice.numeraciones.forEach((item) => {
                let exist = this.state.numerationsOptions.find(n => n.id === item.id);
                if (exist === undefined || exist === null) {
                    numerationsAvailable.push(item);
                }
            })
        }



        let numerationsOptions = numerationsAvailable.map(item => {
            return {
                value: item,
                text: this.getNumerationName(item)
            }
        })

        return (
            <form id="numerationForm" onSubmit={(e) => this.onSubmitBranchOffice(e)} noValidate autoComplete="off" >
                <Grid container alignItems="center" spacing={3}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            name={"nombre"}
                            label={"Nombre *"}
                            maxLength={300}
                            value={this.state.form.nombre}
                            onChange={this.syncChanges}
                            validator={this.validator}
                            validateOptions={"required"}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <SwitchForm
                            name="principal"
                            titleOn="Principal"
                            checked={this.state.form.principal}
                            onChange={this.syncCheckChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <MultiSelectForm
                            name="numeraciones"
                            label={"Numeraciones"}
                            value={this.state.form.numeraciones}
                            options={numerationsOptions}
                            optional={true}
                            onChange={this.syncMultipleChange}
                            renderValue={selected => (
                                this.state.form.numeraciones.map((value) => {
                                    return this.getNumerationName(value);
                                }).join(','))}
                            placeholder={"Seleccione..."}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            name={"direccion"}
                            label={"Dirección *"}
                            maxLength={150}
                            value={this.state.form.direccion}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateAddress(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|address"}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <AutoCompleteForm
                            label={"Departamento *"}
                            name="departamentoid"
                            textNoOption={"No hay registros"}
                            value={this.state.form.departamentoid}
                            options={this.state.getStatesResponse}
                            validator={this.validator}
                            validateOptions={'required'}
                            onChange={(event, values) =>{
                                this.syncAutoCompleteChanges(values, "departamentoid");
                                this.changeBranchState(event, values);
                            }}
                            placeholder={"Seleccione..."}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <AutoCompleteForm
                            label={"Ciudad *"}
                            name="ciudadid"
                            textNoOption={"No hay registros"}
                            value={this.state.form.ciudadid}
                            options={this.state.cities}
                            validator={this.validator}
                            validateOptions={'required'}
                            onChange={(event, value) =>
                                this.syncAutoCompleteChanges(value, "ciudadid")}
                            placeholder={"Seleccione..."}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <SwitchForm
                            name="idstate"
                            checked={this.state.form.idstate}
                            titleOn={this.state.form.idstate ? "Desactivar sucursal" : "Activar sucursal"}
                            onChange={this.syncCheckChange}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    spacing={3}
                    justify="flex-end"
                    alignItems="center">
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <ButtonPrimary
                            text={getComponentName(this.state.components, 27, 95, 'Listo')}
                            loading={this.state.loadingSaveBranch}
                            type={'submit'}
                        />
                    </Grid>
                </Grid>

            </form>
        );
    }

}

FormBranchOffice.propTypes = {
    numerationReducer: PropTypes.object.isRequired,
    branchOfficeReducer: PropTypes.object.isRequired,
    getNumerationsFilterAction: PropTypes.func.isRequired,
    updateNumerationAction: PropTypes.func.isRequired,
    loadBranchOfficeAction: PropTypes.func.isRequired,
    getStatesAction: PropTypes.func.isRequired,
    getAllCitiesAction: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    numerationReducer: state.numerationReducer,
    branchOfficeReducer: state.branchOfficeReducer,
    basicDataReducer: state.basicDataReducer,
    configReducer: state.configReducer,
})

export default connect(mapStateToProps,
    {
        getNumerationsFilterAction,
        getStatesAction, 
        getAllCitiesAction,
        saveBranchOfficeAction,
        updateBranchOfficeAction,
        updateNumerationAction,
        loadBranchOfficeAction,
        getBranchOfficeAction,
    })(FormBranchOffice);