import {
    GET_DOCUMENT_DETAIL,
    GET_TOTAL_DETAIL,
    GET_CHARGES_DOCUMENT,
    LOAD_CHARGE_DOCUMENT,
    UPDATE_CHARGE_DOCUMENT,
    OPEN_MODAL_CHARGE,
    GET_DISCOUNTS_DOCUMENT,
    LOAD_DISCOUNT_DOCUMENT,
    OPEN_MODAL_DISCOUNT,
    UPDATE_DISCOUNT_DOCUMENT,
    SHOW_CART_INVOICE,
    DOCUMENT_VALID
} from "../actions/types";

import isEmpty from '../utils/isEmpty'

const initialState = {
    editChargeDocument: false,
    openModalCharge: false,
    editDiscountDocument: false,
    openModalDiscount: false,
    showCartInvoice: false,
    isValidDocument: true
}


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCUMENT_DETAIL:
            return {
                ...state,
                documentDetail: action.payload
            };

        case GET_TOTAL_DETAIL:
            return {
                ...state,
                totalDetail: action.payload
            };

        case GET_CHARGES_DOCUMENT:
            return {
                ...state,
                documentCharges: action.payload
            };

        case LOAD_CHARGE_DOCUMENT:
            return {
                ...state,
                loadChargeDocument: action.payload,
                editChargeDocument: !isEmpty(action.payload) ? true : false,
            };

        case UPDATE_CHARGE_DOCUMENT:
            return {
                ...state,
                updateChargeDocumentResponse: action.payload,
            };

        case OPEN_MODAL_CHARGE:
            return {
                ...state,
                openModalCharge: action.payload
            }
            
        case GET_DISCOUNTS_DOCUMENT:
            return {
                ...state,
                documentDiscounts: action.payload
            }

        case LOAD_DISCOUNT_DOCUMENT:
            return {
                ...state,
                loadDiscountDocument: action.payload,
                editDiscountDocument: !isEmpty(action.payload) ? true : false,
            };
            
        case OPEN_MODAL_DISCOUNT:
            return {
                ...state,
                openModalDiscount: action.payload
            }

        case UPDATE_DISCOUNT_DOCUMENT:
            return {
                ...state,
                updateDiscountDocumentResponse: action.payload
            }

        case SHOW_CART_INVOICE:
            return {
                ...state,
                showCartInvoice: action.payload
            }     
        
        case DOCUMENT_VALID:
            return{
                ...state,
                isValidDocument: action.payload
            }
        default:
            return state
    }
}