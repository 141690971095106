import React, { Fragment,useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CustomTooltip from '../../../components/tooltip/tooltip.component'
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";

import { ClearGetComponentsAction,getMenuComponentAction} from '../../../actions/configAction'
import { getComponentName,getComponentUrl } from '../../../utils/general.js';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.thirdBackgroundColor,
        color: theme.palette.primaryColor,
    },
    avatar: {
    },
    header: {
        paddingBottom: 0,
        paddingTop: 0
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
        fontSize: 14
    },
    title: {
        color: theme.palette.primaryColor,
    },

    icon: {
        color: theme.palette.secundaryColor
    },
    iconAction: {
        color: theme.palette.primaryColor
    },
    valueText: {
        color: '#EC005F',
        fontWeight: 500
    },
    tooltip:{
    marginTop:15
    }

}));

function ManageReasonCard(props) {
    const classes = useStyles();
    const { data, getMenuComponentAction,ClearGetComponentsAction} = props;
    let { configReducer } = props;
    let components = configReducer.menuComponent

    useEffect(() => {
        getMenuComponentAction(3);
        // returned function will be called on component unmount 
        return () => {
          ClearGetComponentsAction()
        }
      },[])


    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}                
                avatar={<ReceiptIcon fontSize="small" className={classes.icon} /> }  
                action={
                    <Fragment>
                        <div className={classes.tooltip}>
                        <CustomTooltip
                         message={
                                <TooltipMessage
                                title={ getComponentName(components,102,184,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,102,185,'Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura.')}
                               botton={ getComponentName(components,102,186,'Más información')}
                               href={getComponentUrl(components, 102, 186, null)}
                            /> }
                        />
                        </div>
                                                
                    </Fragment>
                }
                classes={{
                    title: classes.title,
                }}
                title={data.value.indexOf("NC")!== -1 ? 'Nota Crédito' : 'Nota Debito'}
            />
            <CardContent className={classes.content}>                
                <span>{data.text.substring(0, data.text.indexOf("("))}</span>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state) => ({    
    configReducer: state.configReducer
  });
  
  const mapDispatchToProps = {     
    getMenuComponentAction,
    ClearGetComponentsAction
  };
  export default connect(mapStateToProps, mapDispatchToProps)(ManageReasonCard);