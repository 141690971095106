import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import AutoIncrementSmall from '../../../components/autoIncrement/autoIncrementSmall.component'

import { updateGlobalChargeToInvoiceAction } from '../../../actions/valuesInvoiceAction'

import { ErrorAlert } from '../../../helpers/alert.helpers'
import { regexOnlyNumbers } from '../../../helpers/customRegex.hepers'


const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.thirdBackgroundColor,
        color: theme.palette.primaryColor,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: 55
        }
    },
    avatar: {
    },
    header: {
        color: theme.palette.primaryColor,
        paddingBottom: 0,
        paddingTop: 0
    },
    title: {
        color: theme.palette.primaryColor,
    },
    icon: {
        color: theme.palette.secundaryColor
    },
    iconAction: {
        color: theme.palette.primaryColor
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
        fontSize: 14,
        color: theme.palette.primaryColor
    },
    text: {
        color: theme.palette.primaryColor
    },
    valueText: {
        color: '#EC005F',
        fontWeight: 500
    }
});

class ManageGlobalChargeCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                txtQuantity: 0,
                labelTotal: 0
            },
            data: {}
        }
    }


    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.data && nextProps.data !== state.data) {
            update.data = nextProps.data;
            const data = nextProps.data;
            update.form = {
                txtQuantity: data.cantidad,
                labelTotal: data.valorcalculado
            }
        }

        return Object.keys(update).length ? update : null;
    }

    /**
     * @event 
     * @description Sincroniza  valor input con state,
     * solo para valores enteros
     * @param {*} e 
     */
    syncChanges(e) {
        let name = e.target.name;
        let value = e.target.value;
        value = value.replace(regexOnlyNumbers, '');

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    }


    /**
   * @event
   * @description Aumenta valor en campo cantidad
   * @param {*} e 
   */
    handleIncrement(e) {
        let counter = this.state.form.txtQuantity === '' ? 0 : parseInt(this.state.form.txtQuantity);
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtQuantity: counter + 1
            }
        }))
        this.onChangesQuantity(counter + 1);
    }

    /**
     * @event
     * @description Disminuye valor en campo cantidad
     * @param {*} e 
     */
    handleDecrement(e) {
        let counter = this.state.form.txtQuantity === '' ? 0 : parseInt(this.state.form.txtQuantity);
        let newcounter = counter - 1;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtQuantity: newcounter < 0 ? 0 : newcounter
            }
        }))
        this.onChangesQuantity(counter - 1);
    }


    /**
   * @event
   * @description Escucha cambio en campo cantidad,
   * recalcula valores totales
   * @param {} e 
   */
    onChangesQuantity(value) {

        try {
            let newValue = value.toString();
            newValue = newValue === '' ? '0' : newValue.replace(regexOnlyNumbers, '');

            let newData = this.state.data;
            newData = {
                ...newData,
                cantidad: parseInt(newValue),
                valorcalculado: parseFloat(parseInt(newValue) * parseFloat(newData.valor))
            }

            this.refreshForm(newData);

        } catch (err) {
            console.log("Error en evento cambio cantidad", err);
            ErrorAlert('Opss!!', 'Ha ocurrido un error recalculando total del cargo, con cantidad digitada');
        }
    }


    /**
     * @function
     * @description Renderiza nuevos valores de card
     * @param {*} data 
     */
    refreshForm(data) {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtQuantity: data.cantidad,
                labelTotal: data.valorcalculado
            },
        }))
        this.props.updateGlobalChargeToInvoiceAction(data);
    }


    render() {
        const { classes, onDelete, data } = this.props;
        return (
            <Card className={classes.root}>
                <CardHeader className={classes.header}
                    avatar={
                        <AddToPhotosIcon fontSize="small" className={classes.icon} />
                    }
                    action={
                        <Tooltip title="Eliminar">
                            <IconButton aria-label="Eliminar" onClick={onDelete} >
                                <DeleteIcon className={classes.iconAction} />
                            </IconButton>
                        </Tooltip>
                    }
                    classes={{
                        title: classes.title,
                    }}
                    title={data.descripcion}
                />
                <CardContent className={classes.content}>
                    <span style={{ maxWidth: 200 }}>
                        <AutoIncrementSmall
                            name={"txtQuantity"}
                            value={this.state.form.txtQuantity}
                            onChange={(e) => {
                                this.syncChanges(e);
                            }}
                            onBlur={(e) => this.onChangesQuantity(e.target.value)}
                            handleIncrement={(e) => this.handleIncrement(e)}
                            handleDecrement={(e) => this.handleDecrement(e)}
                            maxLength={10}
                        />
                    </span>
                    <br />
                    <span className={classes.total}>$ {this.state.form.labelTotal.toLocaleString('en')} </span>
                </CardContent>
            </Card>
        );
    }
}

ManageGlobalChargeCard.propTypes = {
    updateGlobalChargeToInvoiceAction: PropTypes.func.isRequired,

    valuesInvoiceReducer: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    valuesInvoiceReducer: PropTypes.object.isRequired,
})


export default connect(mapStateToProps, {
    updateGlobalChargeToInvoiceAction

})(withStyles(styles)(ManageGlobalChargeCard));

