import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { URL_IMAGES } from '../../../config/config.js';

import CustomCardAditionalData from "../../invoice/aditional-data-card.component";
import { updateDocument, getQuotation } from "../../../actions/invoiceActions";
import { ClearGetComponentsAction,getMenuComponentAction} from '../../../actions/configAction'
import CollapsePanel from '../../../components/collapse/collapsestyles.component';
import CustomProgress from '../../../components/Progress/progress.component';
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import { ConfirmAlert } from '../../../helpers/alert.helpers';
import { getComponentName,getComponentUrl } from '../../../utils/general.js';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from "@material-ui/core/CardMedia";
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles({
  
  showLayer :{
    display: 'block'
  },
  hideLayer:{
      display: 'none'
  },
  letter: {
      color: '#FFFFFF'
  },
  alignRight:{
    textAlign: "right"
  },
  alignLeft:{
    textAlign: "left"
  },
  alignCenter:{
    textAlign: "center"
  },
  widthMax:{
    width: "100%"
  },
  paddingIcons: {
    paddingTop: "20%"
  },
  width99:{
    width: "99%"
  },
  width98:{
    width: "98%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)"
  },
  width97:{
    width: "97%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)"
  },
  width90:{
    width: "90%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)"
  },
  letterTaxes:{
    color: "#2CC63E"
  },
  totalInvoiceDetail:{
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: "20px",
    fontWeight: "800",
    letterSpacing: "0",
    textAlign: "left"
  },
  colorIcon:{
    color: "#2CC63E"
  },
  h1Card: {
    justifyContent: "center",
    textAlign: "center",
    color: "white"
  },
  cover: {
    width: "100%",
    height: "100%"
  },
});

const QuotationDetails = (props) => {
  const classes = useStyles();
    const {document, heightDiv, ObjId, functionDetails, OpenMailModal, OpenEdit,getMenuComponentAction,ClearGetComponentsAction} = props;  
    let { configReducer } = props;
      let components = configReducer.menuComponent
    
    const [updateField, setUpdateField] = useState({
        Operacion: "",
        Favorito: false,
        Pagado: false,
        Id: 0
     });
     const [loading, setLoading] = useState(true);
     const history = useHistory();

    const handleFavourite = (action) => {  
     
          setUpdateField((prevState) => ({ ...prevState,  
            Id: ObjId.Id,
            Operacion: "FAVORITO",
            Pagado: ObjId.Pagado,
            Favorito: action})); 
      }

      const handleDeleteDocument = () => {
        const setPrincipal = (confirmed) => {
          if (confirmed) { 
            setUpdateField((prevState) => ({ ...prevState,  
              Id: ObjId.Id,
              Operacion: "BORRADO",
              Pagado: false,
              Favorito: ObjId.Favorito}));
          }
        }
        ConfirmAlert('Eliminar.', '¿Desea eliminar la cotización?', setPrincipal);        
      }      

     const [expandPanel, setExpandPanel] = useState({
        expandPanelInfo: '',
        cleanData: false,
        edit: false
      }); 
      const activarDetalle = () => {        

        functionDetails(updateField.Id);
      }
      const finishLoading = () =>{
        setLoading(false);
      }

    useEffect(() => {
 
        if (updateField.Id !== 0){

           if (updateField.Operacion === "BORRADO"){
            localStorage.removeItem('itemsInCart');
              history.push({
                pathname: '/document',
                state:{
                    redirect: 2
                }
            });
           }
          props.updateDocument(updateField, activarDetalle);      
        }      
      }, [updateField]);    
   

      const pad = (input, length, padding) => { 
        var str = input + "";
        return (length <= str.length) ? str : pad(padding+str, length, padding);
      }
  
      useEffect(() => {        
        if (ObjId.Id !== 0){
          setLoading(true);
          props.getQuotation(ObjId.Id, finishLoading);  
        }      
      }, [ObjId.Id, ObjId.Cambio]);

      useEffect(() => {
        getMenuComponentAction(3);
        return () => {
          ClearGetComponentsAction()
        }
      },[])


    const getCategories = (cats) => {
        if (cats !== null){
          
          const categories = cats.map((cat) => 
               <Grid item key={cat.id} lg={2} md={2} sm={12} xs={12}>
                 <div className="spacingInvoiceMini"></div>                
                   <div className='circlesCategories' style={{ backgroundColor : cat.color}} title={cat.nombre}></div>
                </Grid>
          );
          return categories;
        }
      }

      /*const getTaxes = (taxes) => {
        if (taxes !== null){
          
          const taxesRes = taxes.map((tax) => 
               <Grid item key={taxes.id} lg={5} md={5} sm={12} xs={12}>
                   {tax.descripcion}: <span className={classes.letterTaxes}>{tax.porcentaje}%</span>
                </Grid>
          );
          return taxesRes;
        }
      }*/
    
      const getArticles = () => {
        if (document.opdetalledocumento !== undefined && document.opdetalledocumento !== null){
          const articles = document.opdetalledocumento.map((items) => 
            <div>
                <div class="cardItemInvoice">
                  <Grid container>
                    <Grid item lg={3} md={3} sm={12}>  
                      <Card key={items.id} style={{height: '80%', width: '97%', backgroundColor: items.colorimagen === null || items.colorimagen === undefined || items.colorimagen.length === 0 ? '#33BBFF' : items.colorimagen}}>                        
                        <CardMedia
                         className={classes.cover}
                        image={items && URL_IMAGES + items.imagen}
                        style={{
                          background: `${
                            items.imagen === null || items.imagen === undefined || items.imagen.length === 0
                              ? items.colorimagen === null || items.colorimagen === undefined || items.colorimagen.length === 0 ? '#33BBFF' : items.colorimagen
                              : null
                            }`,
                        }}
                        >
                          {items.imagen === null || items.imagen === undefined || items.imagen.length === 0 ? (
                          <h1
                            className={classes.h1Card}
                          >
                            {items.descripcion.substring(0,2).toUpperCase()}
                          </h1>
                        ) : null}
                        </CardMedia>
                      </Card>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12}>
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} className="titleArticleInvoice">
                        {items.descripcion.length > 60 ? items.descripcion.substring(0,60) + '...': items.descripcion}  <div className="spacingInvoice"></div>
                        </Grid>              
                        <Grid container>
                      <Grid item lg={12} md={12} sm={12}><div className={classes.width98} ></div></Grid>    
                      </Grid> 
                      </Grid>                    
                      <Grid container className="fieldsReadContainer">
                      <Grid item lg={10} md={10} sm={12} className="fieldsReadMini">Valor C/U:</Grid>
                      <Grid item lg={2} md={2} sm={12} className="fieldsReadMini">Cantidad:</Grid>
                    </Grid>
                    <Grid container>
                    <Grid item lg={10} md={10} sm={12} className="totalInvoiceDetail">${new Intl.NumberFormat('es-CO').format(items.valorunitario)}</Grid>
                    <Grid item lg={2} md={2} sm={12} className="fieldsReadBig">{new Intl.NumberFormat('es-CO').format(items.cantidad)}</Grid>            
                  </Grid>
                  <div className="spacingInvoiceMini"></div>
                  <Grid container>
                  <Grid item lg={10} md={10} sm={12}><div className={classes.width98}></div></Grid>
                    <Grid item lg={2} md={2} sm={12}><div className={classes.width98}></div></Grid>    
                    </Grid> 
                    <Grid container>                    
                    <Grid item lg={6} md={6} sm={12} className="fieldsReadBig">
                    <div className="spacingInvoiceMini"></div>  
                      {items.valoriva === 0 ? '' : 'IVA: '}<span className={classes.letterTaxes}>{items.valoriva === 0 ? '' : items.valoriva+'%'}</span> {items.valoriva> 0 && items.valorinc>0 ? '/':''} {items.valorinc === 0 ? '' : 'INC: '}<span className={classes.letterTaxes}>{items.valorinc === 0 ? '' : items.valorinc+'%'}</span></Grid>
                    <Grid item lg={6} md={6} sm={12} className="fieldsReadBig"><Grid container justify="flex-end">{getCategories(items.adcategoriaarticulo)}</Grid></Grid>            
                  </Grid>
                  <div className="spacingInvoiceMini"></div>
                  <Grid container>
                      <Grid item lg={12} md={12} sm={12}><div className={classes.width98}></div></Grid>    
                  </Grid>
                  <Grid container className="fieldsReadContainer">
                      <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">Descuento:</Grid>
                      <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Total:</Grid>
                  </Grid>  
                  <Grid container>
                    <Grid item lg={8} md={8} sm={12} className={classes.totalInvoiceDetail}>{items.valordescuento > 0 ? '$': '%'}{items.valordescuento > 0 ? new Intl.NumberFormat('es-CO').format(items.valordescuento) : items.porcentajedescuento}</Grid>
                    <Grid item lg={4} md={4} sm={12} className="totalInvoiceDetail">${new Intl.NumberFormat('es-CO').format(items.totalreal)}</Grid>            
                  </Grid>
                    </Grid>
                  </Grid>               
                </div>
            <div className="spacingInvoice"></div>
            </div>   
          );
          return articles;        
        }
      }

    const getInfoAditional = () => {
        
        let cargos=document.opdocumentocargos;
        let descuentos=document.opdocumentodescuentos;
        
        return(
          <div>
            <div className="cardDetailInfoAditional">
            <Grid container >
            <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Fecha Cotización </Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Forma de Pago</Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Fecha de Vigencia</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{pad(new Date(document.fecha).getDate(), 2, "0")}/{pad((new Date(document.fecha).getMonth()+1),2,"0")}/{new Date(document.fecha).getFullYear()} {pad(new Date(document.fecha).getHours(), 2, "0")}:{pad(new Date(document.fecha).getMinutes(), 2, "0")}</Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{document.descripcionforma}</Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{pad(new Date(document.fechavigencia).getDate(), 2, "0")}/{pad((new Date(document.fechavigencia).getMonth()+1),2,"0")}/{new Date(document.fechavigencia).getFullYear()}</Grid>
            </Grid>          
            <Grid container>
              <Grid item lg={4} md={4} sm={12}><div className={classes.width97}></div></Grid>
              <Grid item lg={4} md={4} sm={12}><div className={classes.width97}></div></Grid>
              <Grid item lg={4} md={4} sm={12}><div className={classes.width97}></div></Grid>
            </Grid>
            <Grid container className="fieldsReadContainer">
              <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Medio Pago</Grid>
              <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">Vendedor</Grid>
            </Grid>
            <Grid container>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{document.descripcionmedio === null? 'Sin datos': document.descripcionmedio}</Grid>
              <Grid item lg={8} md={8} sm={12} className="fieldsReadBig">{document.nombresvendedor === null ? 'Sin datos': document.nombresvendedor}</Grid>          
            </Grid>
            <Grid container>
            <Grid item lg={4} md={4} sm={12}><div className={classes.width98}></div></Grid>
              <Grid item lg={8} md={8} sm={12}><div className={classes.width98}></div></Grid>    
              </Grid>        
            <Grid container className="fieldsReadContainer">
              <Grid item lg={12} md={12} sm={12} className="fieldsReadMini" style={{width : '96%'}}>Observaciones</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} className="fieldsReadBig">{document.textoobservacion}            
              </Grid>   
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12}></Grid>    
              </Grid> 
              </div> 
              <div className="spacingInvoice"></div>
              <div className="titleAdicitional">Cargos</div>
              <div className="spacingInvoice"></div>
              <Grid container spacing={1}>            
              {cargos!== undefined ? cargos!== null ? cargos.map((data, index) =>
                              <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                              <CustomCardAditionalData data={data} type="charges"                  
                                  />
                              </Grid>
                          ):"": ""}
                          </Grid>
              <div className="spacingInvoice"></div>                        
              <div className="titleAdicitional">Descuentos sobre el total</div>
              <div className="spacingInvoice"></div>
              <Grid container spacing={1}>
              {descuentos!== undefined ? descuentos!== null ?descuentos.map((data, index) =>
                              <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                              <CustomCardAditionalData data={data} type="discounts" tooltipMessage={ data.modelodescuento === 1 ?
                               <TooltipMessage
                               title={ getComponentName(components,93,157,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,93,158,'Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura.')}
                               botton={ getComponentName(components,93,159,'Más información')}
                               href={getComponentUrl(components, 93, 150, null)}
                             /> :  
                             <TooltipMessage
                               title={ getComponentName(components,94,160,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,94,161,'Es un descuento que afecta el valor total a pagar pero no el valor total de la factura.')}
                               botton={ getComponentName(components,94,162,'Más información')}
                               href={getComponentUrl(components, 94, 162, null)}
                           />
                              }
                                  onClick=""
                                  />
                              </Grid>
                          ):"" : ""}
                          </Grid>
          </div>
        );
      }
        return(
            <div className={document.numero === undefined? classes.hideLayer : classes.showLayer}>
            <div className="cardDetailInvoiceFullData" style={{overflow : 'auto', height: heightDiv}}>   
            <div >
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} className="detailInvoiceNumber">
                    No. {document.numero}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} style={{'text-align' : 'right'}}>
                      <div className={document.favorito === true ? classes.showLayer : classes.hideLayer} >  
                      <StarIcon ></StarIcon>
                      </div>
                      <div className={document.favorito === false ? classes.showLayer : classes.hideLayer} >
                      <StarBorderIcon ></StarBorderIcon>
                      </div>
                    </Grid>                      
                </Grid>
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} style={{textAlign: 'left'}} className={document.estadocot === 'Vencida'? 'totalInvoiceGridExpired': 'totalInvoiceGridPaidout' } >
                    ${new Intl.NumberFormat('es-CO').format(document.valortotal)}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} className={document.estadocot === 'Vencida'? 'stateInvoiceExpired':'stateInvoicePaidout' }>
                    {document.estadocot}
                    </Grid>                      
                </Grid>
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} className="dataInvoiceGrid">
                  {new Date(document.fecha).getFullYear()}-{pad((new Date(document.fecha).getMonth()+1),2,"0")}-{pad(new Date(document.fecha).getDate(),2,"0")} {pad(new Date(document.fecha).getHours(), 2, "0")}:{pad(new Date(document.fecha).getMinutes(), 2, "0")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12}></Grid>                      
                </Grid>
            </div>
            <div className="lineDivision"></div>
            {loading ? <Fragment>  <CustomProgress /></Fragment> : ''}
            <div class="paddingLeft">    
              <div className="constumerNameDetail">{document.razonsocialcliente}</div>
              <div className="detailInvoiceInfo">{document.desdoccli} {document.clientedocumento}</div>
              <div className="detailInvoiceInfo">{document.desctipe}</div>
              <div className="detailInvoiceInfo">{document.desctirg}</div>
              <div className="spacingInvoice"></div>
            </div>
            <div class="gridNoSpace">  
              <EmailIcon className={classes.colorIcon}></EmailIcon>&nbsp;&nbsp;<div className="detailInvoiceInfo">{document.correoelectronicocliente}</div>
            </div>
            <div class="gridNoSpace">  
              <PhoneIcon className={classes.colorIcon}></PhoneIcon>&nbsp;&nbsp;<div className="detailInvoiceInfo">{document.telefonocliente}</div>
            </div>
            <div class="gridNoSpace">  
              <RoomIcon className={classes.colorIcon}></RoomIcon>&nbsp;&nbsp;<div className="detailInvoiceInfo">{document.adclientedirecciones === undefined ? "": document.adclientedirecciones[0].direccion + ' '+ document.adclientedirecciones[0].nombmun + ' '+ document.adclientedirecciones[0].nombdep}</div>
            </div>
            <div className="spacingInvoice"></div>
            <div className="greenTitleInvoice">Artículos ({document.opdetalledocumento === undefined ? 0 : document.opdetalledocumento === null ? 0 : document.opdetalledocumento.length})</div>
            <div className="spacingInvoice"></div>
            {getArticles()}                
            <CollapsePanel 
                component={getInfoAditional}
                title={"Información Adicional"}
                titleOpen={"Información Adicional"}
                titleEdit={"Información Adicional"}
                edit={expandPanel.edit}
                open={expandPanel.expandPanelInfo}
                cleanData={expandPanel.cleanData}
                backgroundColorSummary={{ backgroundColor: "#2CC63E", color:'#000000'}}
                backgroundColorDetail={{}}
                type="showinfo"
                />
            </div>
            <div className="bottomButtonsInvoice">
            <Grid container>
            <Grid item lg={1} md={1} sm={1}>
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                  <div style={{ display  : document.favorito === true ? 'block' : 'none'}}>
                  <StarIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(false)} ></StarIcon>
                    </div>
                  <div style={{ display  : document.favorito === false ? 'block' : 'none'}}>
              <StarBorderIcon className="buttonInvoiceBehavior" onClick={() => handleFavourite(true)} ></StarBorderIcon>
                  </div> 
                      <div className="textButtonsInvoice">Destacar</div>
                </Grid>
              <Grid item lg={2} md={2} sm={2}>
                  <div  title="Eliminar Cotización">
                  <DeleteIcon className="buttonInvoiceBehavior" onClick={() => handleDeleteDocument()}></DeleteIcon><br></br>
                      <div className="textButtonsInvoice">Eliminar</div> 
                  </div>                
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                <ReceiptIcon className="buttonInvoiceBehavior" onClick={() => OpenEdit(document, 2)}></ReceiptIcon><br></br>
                      <div className="textButtonsInvoice">Facturar</div>
                </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <EditIcon className="buttonInvoiceBehavior" onClick={() => OpenEdit(document, 1)}></EditIcon><br></br>
                      <div className="textButtonsInvoice">Editar</div>
                </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <EmailIcon className="buttonInvoiceBehavior" onClick={() => OpenMailModal(document.correoelectronicocliente)}></EmailIcon><br></br>
                      <div className="textButtonsInvoice">Enviar</div>
                </Grid>
                <Grid item lg={1} md={1} sm={1}>
              </Grid>
            </Grid>
          </div>
          </div>                        
      );

}

const mapStateToProps = (state) => ({
    sucessupdate: state.invoiceReducer.sucess,
    document: state.invoiceReducer.quotation,
    configReducer: state.configReducer
    });
    
    const mapDispatchToProps = {
      updateDocument,
      getQuotation,
      getMenuComponentAction,
      ClearGetComponentsAction
    };
    export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);