import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';

import User from '../../views/myAccount/user/user.view.js'
import BasicData from '../../views/myAccount/basicData.view'
import Numeration from '../../views/myAccount/numeration/numeration.view.js'
import Branch from '../../views/myAccount/branch/branch.view'
import Template from '../../views/myAccount/template/template.view'

import { getBillersAction, getSellersAction} from '../../actions/userAction'
import { getCompanyAction,} from '../../actions/basicDataActions'
import { getModalities} from '../../actions/numerationActions'
import { getBranchOfficeAction} from '../../actions/branchOfficeActions'
import { getComponentsAction, ClearGetComponentsAction, getAllCitiesAction } from '../../actions/configAction'
import { getComponentName } from '../../utils/general.js';
import { getRoleOfLoggedUser } from '../../actions/authActions.js';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secundaryBackgroundColor
    },
    activeButton: {
        borderBottom: '1px solid #50C1EE'
    }
}));

const QontoConnector = withStyles({
    root: {},
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#4F4F4F',
        },
    },
    line: {
        borderColor: '#4F4F4F',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);


const useQontoStepIconStyles = makeStyles({
    root: {
        color: 'white',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#2CC63E',
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: '#4F4F4F',
    },
    completed: {
        color: '#4F4F4F',
        zIndex: 1,
        fontSize: 18,
        backgroundColor: '#2CC63E',
        borderRadius: '50%',
        padding: 5
    }
});


function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (<div className={
        clsx(classes.root, {
            [classes.active]: active,
        })
    } > {completed ? < Check className={classes.completed} /> : <div className={classes.circle} />} </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};



function getSteps(components) {
    return [getComponentName(components, 9, 9, 'Datos Básicos'), getComponentName(components, 15, 74, 'Numeración'), getComponentName(components, 22, 87, 'Sucursales'), getComponentName(components, 28, 96, 'Usuario'), getComponentName(components, 38, 119, 'Logo y plantilla')];
}



const usePreviousValue = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function MyAccount(props) {
    const classes = useStyles();
    let { userReducer, basicDataReducer, numerationReducer, branchOfficeReducer, configReducer } = props;
    const { getBillersAction, getSellersAction, getComponentsAction, getCompanyAction, getBranchOfficeAction, getModalities, ClearGetComponentsAction, getAllCitiesAction} = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [stepsCompleted, setStepCompleted] = React.useState([]);
    const [applyElectronicTicket, SetApplyElectronicTicket] = React.useState(false);

    const { requestCompany } = basicDataReducer;
    const { branchOfficesList } = branchOfficeReducer;
    const { filteredBillersList, filteredSellersList } = userReducer;
    const { numerations } = numerationReducer;
    let components = configReducer.components
    let steps = getSteps(components);
    let menu = configReducer.menu
    let prevMenu = usePreviousValue(menu)

    useEffect(() => {
        if (!!prevMenu && prevMenu !== menu) {
            getComponentsAction(2);
        }
    }, [menu])

    useEffect(() => {
        if (getRoleOfLoggedUser()) {
            props.history.push('/');
        }

        getAllCitiesAction();
        getModalities();
        getComponentsAction(2);
        getBillersAction();
        getSellersAction();
       
        if (!(!!basicDataReducer.requestCompany))
            getCompanyAction();

        if (!(!!branchOfficeReducer.branchOfficesList))
            getBranchOfficeAction();


        return () => {
            ClearGetComponentsAction();
        }
    }, []);

  
    useEffect(() => {

        if (requestCompany && Object.keys(requestCompany).length)
            SetApplyElectronicTicket(requestCompany?.aplicafe === true ? true : false);

        if (requestCompany && Object.keys(requestCompany).length)
            handleCompleteStep(0);

        if (numerations && numerations.length > 0)
            handleCompleteStep(1);

        if (branchOfficesList && branchOfficesList.length > 0)
            handleCompleteStep(2);

        if (filteredBillersList && filteredSellersList && filteredBillersList.length > 0 && filteredSellersList.length > 0)
            handleCompleteStep(3);

        if (requestCompany && requestCompany?.plantillaid !== '' && requestCompany?.plantillaid !== null)
            handleCompleteStep(4);

    }, [requestCompany, numerations, branchOfficesList, filteredBillersList, filteredSellersList])


    /**
     * Listado de componentes para el paso a paso
     * @param {*} step Idetificador paso
     */
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (<BasicData />)
            case 1:
                return (<Numeration applyElectronicTicket={applyElectronicTicket} />);
            case 2:
                return (<Branch />);
            case 3:
                return (<User />);
            case 4:
                return (<Template />);
            default:
                return 'Unknown step';
        }
    }

    /**
     * Agrega paso completo a listado
     * @param {int} index Identificador paso
     */
    const handleCompleteStep = (index) => {
        let exist = stepsCompleted.find(s => s === index);
        let newArray = [];
        newArray = stepsCompleted;
        if (exist === null || exist === undefined) {
            newArray.push(index);
            setStepCompleted(newArray);
            setCompleted(new Set(newArray));
        }

    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    function isStepCompleted(step) {
        return completed.has(step);
    }

    return (
        <div className="container-form" xs={12} lg={12} >
            <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={< QontoConnector />} className={classes.root} >
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepButton
                                onClick={handleStep(index)}
                                completed={isStepCompleted(index)}
                                className={activeStep === index ? classes.activeButton : ''}
                            >
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    <label className="stepper-title">{label}</label>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                <div>{getStepContent(activeStep)}</div>
            </div>
        </div>
    );

}

MyAccount.propTypes = {
    getBillersAction: PropTypes.func.isRequired,
    getSellersAction: PropTypes.func.isRequired,
    ClearGetComponentsAction: PropTypes.func.isRequired,
    getBranchOfficeAction: PropTypes.func.isRequired,
    getAllCitiesAction: PropTypes.func.isRequired,

    userReducer: PropTypes.object.isRequired,
    basicDataReducer: PropTypes.object.isRequired,
    templateReducer: PropTypes.object.isRequired,
    numerationReducer: PropTypes.object.isRequired,
    branchOfficeReducer: PropTypes.object.isRequired,
    configReducer: PropTypes.object.isRequired

}

const mapStateToProps = state => ({
    userReducer: state.userReducer,
    templateReducer: state.templateReducer,
    basicDataReducer: state.basicDataReducer,
    numerationReducer: state.numerationReducer,
    branchOfficeReducer: state.branchOfficeReducer,
    configReducer: state.configReducer
})

export default connect(mapStateToProps, {
    getBillersAction,
    getSellersAction,
    getCompanyAction,
    getBranchOfficeAction,
    getModalities,
    getComponentsAction,
    ClearGetComponentsAction,
    getAllCitiesAction
})(MyAccount);