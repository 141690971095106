
//Calcula valor impuestos y totales por Item
export const calculateValuesItem = (item) => {

    //Tipo valor --> 1 valor 2 porcentaje
    try {

        let cValorBruto = (item.valorNeto * item.cantidad);
        let cValorDescuento = item.valordescuento;
        let cPorcentajeDescuento = item.porcentajedescuento;
        let cValorDescuentoFinal = item.tipodescuento === 2 ? (cValorBruto * (cPorcentajeDescuento / 100)) : cValorDescuento;
        let cValorSubtotal = cValorBruto - cValorDescuentoFinal;
        let cValorIva = cValorSubtotal * (item.porcentajeiva / 100);
        let cValorInc = cValorSubtotal * (item.porcentajeinc / 100);
        let cTotal = cValorSubtotal + cValorIva + cValorInc;

        item.totaldescuento = roundValue(cValorDescuentoFinal);
        item.valorbruto = roundValue(cValorBruto);
        item.subtotal = roundValue(cValorSubtotal);
        item.totaliva = roundValue(cValorIva);
        item.totalinc = roundValue(cValorInc);
        item.total = roundValue(cTotal);

    } catch (err) {
        console.error("Ha ocurrido un error calculando valores de item", err);
        throw err;
    }
}

//Calcula valor impuestos y totales para Factura
export const calculateTotal = (detailDocument, charges = [], discounts = []) => {

    try {

        //Agrega campo nuevo a descuentos
        discounts = discounts.map((item) => {
            return {
                ...item,
                valoradescontar: 0

            }
        })

        let cValorBruto = 0;
        let cValorCargos = 0;
        let cValorDescuentosFinancieros = 0;

        let cValorIva = 0;
        let cValorInc = 0;
        let cValorDescuento = 0;
        let cValorTotal = 0;

        let cValorIVAReal = 0;
        let cValorINCReal = 0;
        let cValorDescuentoReal = 0;
        let cValorTotalReal = 0;

        let cTotalAPagar = 0;
        let cTotalCantidadesItem = 0;


        //Calcula descuentos adicionales, modelo descuento 1--> Comercial, 2--> Financiero
        let financialDiscounts = [];
        let commercialDiscounts = [];
        discounts = !!discounts && discounts.length > 0 ? discounts : [];

        commercialDiscounts = discounts.filter(d => d.modelodescuento === 1);
        financialDiscounts = discounts.filter(d => d.modelodescuento === 2);



        //Calculo para descuentos comerciales
        calculeValueCommercialDiscounts(detailDocument, commercialDiscounts);
   

        //Suma totales por Items en carrito
        for (var i = 0; i < detailDocument.length; i++) {

            const item = detailDocument[i];
            cValorBruto += parseFloat(item.valorbruto); //Total sumatoria valor bruto items (VlrUnitario * Cantidad)

            //VALORES FACTURA SIN DESCUENTOS COMERCIALES
            cValorDescuento += parseFloat(item.totaldescuento); //Total sumatoria descuentos items
            cValorIva += parseFloat(item.totaliva); //Total sumatoria valores IVA items
            cValorInc += parseFloat(item.totalinc); //Total sumatoria valores INC items
            cValorTotal += parseFloat(item.total); // Total sumatoria totales item 

            //VALORES REALES FACTURA, INCLUYE DESCUENTOS COMERCIALES
            cValorDescuentoReal += parseFloat(item.totaldescuentoreal);
            cValorIVAReal += parseFloat(item.totalivareal);
            cValorINCReal += parseFloat(item.totalincreal);
            cValorTotalReal += parseFloat(item.totalreal);


            cTotalCantidadesItem += parseFloat(item.cantidad); // Sumatoria cantidades productos
        }

        //Suma cargos adicionales de documento
        if (!!charges && charges.length > 0) {
            for (let i = 0; i < charges.length; i++) {
                const item = charges[i];

                let valueCharge = 0;
                if (item.tipocargo === 1) {

                    valueCharge = item.esglobal === true ? parseFloat(item.valorcalculado) : parseFloat(item.valor);
                    cValorCargos += valueCharge;

                } else if (item.tipocargo === 2) {

                    valueCharge = item.esglobal === true ? parseFloat(item.valorcalculado) : cValorBruto * (parseFloat(item.valor) / 100);
                    cValorCargos += valueCharge;
                }

                //Agrega valor calculado a sumar por cargo, al valorBruto de factura.
                charges[i] = {
                    ...charges[i],
                    valorasumar: roundValue(valueCharge)
                }
            }
        }

        //Calculo para descuentos Financieros sobre total Real
        for (let i = 0; i < financialDiscounts.length; i++) {
            const item = financialDiscounts[i];
            let valuediscount = 0;
            if (item.tipodescuento === 1) {

                valuediscount = parseFloat(item.valor);
                cValorDescuentosFinancieros += valuediscount;

            } else if (item.tipodescuento === 2) {

                valuediscount = cValorTotalReal * (parseFloat(item.valor) / 100)
                cValorDescuentosFinancieros += valuediscount;
            }

            //Agrega valor calculado a descontar de total Factura
            financialDiscounts[i] = {
                ...financialDiscounts[i],
                valoradescontar: roundValue(valuediscount)
            }
        }

        discounts = financialDiscounts.concat(commercialDiscounts);

        //Total Factura
        cTotalAPagar = cValorTotalReal + cValorCargos - cValorDescuentosFinancieros;
    
        let totals = {
            detalleDocumento: detailDocument,
            cargos: charges,
            descuentosFinancieros: financialDiscounts,
            descuentosComerciales: commercialDiscounts,
            otrosdescuentos: discounts, //Cambio nombre otrosdescuentos por descuentos
            valorbruto: cValorBruto,
            valorcargos:roundValue(cValorCargos),

            valordescuento: cValorDescuento,
            valoriva: cValorIva,
            valorinc: cValorInc,
            totalfactura: cValorTotal,

            valordescuentoreal: cValorDescuentoReal,
            valorivareal: cValorIVAReal,
            valorincreal: cValorINCReal,
            totalfacturaReal: cValorTotalReal,


            cantidad: detailDocument.length,
            totalCantidades:roundValue(cTotalCantidadesItem),

            valorOtrosDescuentos: cValorDescuentosFinancieros,//Descuentos financieros
            totalapagar: roundValue(cTotalAPagar), //Total real a pagar con descuentos comerciales

        }

        return totals;

    }
    catch (err) {
        console.error("Ha ocurrido un error calculando Totales, Por favor contacte al administrador", err);
        throw err;
    }
}

const roundValue=(value)=>{
   let newValue= parseFloat(value.toFixed(2));
   return newValue;
}


/**
 * Calcula valores para modelo de descuento comercial
 * @param {Array} detailDocument  Listado items de Factura
 * @param {*} commercialDiscounts Listado de descuentos comerciales
 */
const calculeValueCommercialDiscounts = (detailDocument, commercialDiscounts) => {


    //Calcular total bruto (vlrUnitario * cantidad)
    let cValorTotalBruto = 0;
    for (let i = 0; i < detailDocument.length; i++) {
        const item = detailDocument[i];
        cValorTotalBruto += item.valorbruto;
    }

    //Calcular Prorrateo
    for (let i = 0; i < detailDocument.length; i++) {
        const item = detailDocument[i];
        let prorrateo = roundValue((item.valorbruto * 100) / cValorTotalBruto);
        detailDocument[i] = {
            ...detailDocument[i],
            prorrateo: prorrateo
        }
    }
 
    //Calcula valores de descuento comercial por item
    if (commercialDiscounts.length <= 0) {
        for (let i = 0; i < detailDocument.length; i++) {         
            const item = detailDocument[i];

            let cValorBruto = (item.valorNeto * item.cantidad);
            let cValorDescuento = item.valordescuento;
            let cPorcentajeDescuento = item.porcentajedescuento;
            let cValorDescuentoFinal = item.tipodescuento === 2 ? (cValorBruto * (cPorcentajeDescuento / 100)) : cValorDescuento;
            let cValorSubtotal = cValorBruto - cValorDescuentoFinal;
            let cValorIva = cValorSubtotal * (item.porcentajeiva / 100);
            let cValorInc = cValorSubtotal * (item.porcentajeinc / 100);
            let cTotal = cValorSubtotal + cValorIva + cValorInc;
    
            item.totaldescuento = roundValue(cValorDescuentoFinal);
            item.valorbruto = roundValue(cValorBruto);
            item.subtotal = roundValue(cValorSubtotal);
            item.totaliva = roundValue(cValorIva);
            item.totalinc = roundValue(cValorInc);
            item.total = roundValue(cTotal);
    

            detailDocument[i] = {
                ...detailDocument[i],
                valordescuentocomercial: 0,
                totaldescuentoreal: item.totaldescuento,
                subtotalreal: item.subtotal,
                totalivareal: item.totaliva,
                totalincreal: item.totalinc,
                totalreal: item.total,
            }
        }
    }

    //Limpiar valores anteriores antes de calculo
    if (commercialDiscounts.length > 0) {
        for (let i = 0; i < detailDocument.length; i++) {
            detailDocument[i] = {
                ...detailDocument[i],
                valordescuentocomercial: 0,
                totaldescuentoreal: 0,
                subtotalreal: 0,
                totalivareal: 0,
                totalincreal: 0,
                totalreal: 0,
            }
        }
    }


    for (let i = 0; i < commercialDiscounts.length; i++) {

        const discount = commercialDiscounts[i];
        let cDescuentoComercial = 0;
        let valorDescuento = discount.tipodescuento === 1 ? discount.valor : 0;
        let acumuladoDescuento = 0;


        for (let d = 0; d < detailDocument.length; d++) {

            const item = detailDocument[d];
            let cValorDescuento = 0;

            if (discount.tipodescuento === 1) {  // Tipo valor

                //Ajusta descuento en ultimo item
                if ((d + 1) === detailDocument.length) {
                    cValorDescuento = valorDescuento - acumuladoDescuento;
                    acumuladoDescuento += cValorDescuento;

                } else {
                    cValorDescuento = parseFloat((discount.valor * (item.prorrateo / 100)).toFixed(2));
                    acumuladoDescuento += cValorDescuento;
                }


            } else {    // Tipo porcentaje
                cValorDescuento = parseFloat((item.valorbruto * (discount.valor / 100)).toFixed(2));
            }



            cDescuentoComercial += cValorDescuento;

            let cTotalDescuentoReal = item.totaldescuento + item.valordescuentocomercial + cValorDescuento;
            let cSubtotalReal = item.valorbruto - cTotalDescuentoReal;
            let cValorIVAReal = cSubtotalReal * (item.porcentajeiva / 100);
            let cValorINCReal = cSubtotalReal * (item.porcentajeinc / 100);
            let cTotalReal = cSubtotalReal + cValorIVAReal + cValorINCReal;

            let nuevoComercial = detailDocument[d].valordescuentocomercial + cValorDescuento;
            detailDocument[d] = {
                ...detailDocument[d],
                valordescuentocomercial: roundValue(nuevoComercial),
                totaldescuentoreal: roundValue(cTotalDescuentoReal),
                subtotalreal: roundValue(cSubtotalReal),
                totalivareal: roundValue(cValorIVAReal),
                totalincreal: roundValue(cValorINCReal),
                totalreal: roundValue(cTotalReal),
            }         
        }
        
        let nuevoDescuento = commercialDiscounts[i].valoradescontar + cDescuentoComercial;
        commercialDiscounts[i] = {
            ...commercialDiscounts[i],
            valoradescontar: roundValue(nuevoDescuento)

        }

    }
}

/**
 * @function
 * @description Calcula valor de cargo global respecto a cantidad
 * @param {int} quantity Cantidad 
 * @param {float} value Valor cargo
 * @returns {float} valor calculado
 */
export const calculateValueGlobalCharge = (quantity, value) => {

    try {

        let result = parseFloat(value * quantity);
        return roundValue(result);

    } catch (err) {
        console.error("Ha ocurrido un error calculando valor de cargo, Por favor contacte al administrador", err);
        throw err;
    }
}


