import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secundaryBackgroundColor,
    width: '100%',
    color: theme.palette.primaryColor,
    ' & .MuiTab-wrapper': {
      textTransform: 'capitalize',
      fontsize: 16
    }
  },
  tabs: {
    backgroundColor: theme.palette.secundaryBackgroundColor,
  },
  appBar: {
    backgroundColor: theme.palette.secundaryBackgroundColor,
    border: 'none',
    boxShadow: 'none',
    paddingLeft: 30,
    paddingRight: 30,
  },
  indicator: {
    backgroundColor: '#16B1F3', //Azul
    height: 1
  },
  tab: {
    textTransform: 'capitalize'
  },

}));

const SingleCustomTabs = ({ ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { tabs, tabIndex, using, tabSelected } = props;
  const [value, setValue] = React.useState(tabSelected !== undefined ? tabSelected : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indexTab = (e) => {
    tabIndex(e)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar} >
        <Tabs
          className={classes.tabs}
          classes={{
            indicator: classes.indicator
          }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {
            tabs.map((panel, index) => {
              return (
                <Tab key={index} label={panel.title} {...a11yProps(index)} onClick={using === true ? indexTab(value) : ''} />
              )
            })
          }
        </Tabs>
      </AppBar>
      {tabs.map((panel, index) => {
        return (
          <TabPanel key={index} value={value} index={index} dir={theme.direction}>
            {panel.component}
          </TabPanel>
        )
      })}
    </div>
  );
}

SingleCustomTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default SingleCustomTabs;
