import React,{Component} from "react";

import {ComposedChart,Line,Bar,XAxis,YAxisTooltip,Legend,YAxis,Tooltip} from "recharts";

import {Card,CardContent,FormControlLabel,CardHeader} from "@material-ui/core";
import Brightness1Icon from '@material-ui/icons/Brightness1';

import { withStyles } from "@material-ui/core/styles";

import CustomProgress from '../../../components/Progress/progress.component';

import '../Components/dashboard.css'

const sizeIcon = 10

const useStyles = (theme) => ({
  colorCard: {
    background: theme.palette.ninethBackgroundColor 
  },
  composedChart:{
    color:theme.palette.primaryColor,
    fontFamily:theme.typography.fontFamily  ,
    fontSize:theme.typography.fontSize
  },
  divMessage:{
    textAlign:'center',
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  },
  sizeIconIcome:{
    fontSize:sizeIcon,
    color:'#50C1EE'
  },
  sizeIconCost:{
    fontSize:sizeIcon,
    color:'#F1474A'
  },
  sizeIconBenefit:{
    fontSize:sizeIcon,
    color:'#DDDD1C'
  },
  styleLabel:{
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 100
  }
});


class IncomeChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes,dataIncome,statusCode} = this.props;
    return (
      <Card className={classes.colorCard}>
        <CardHeader title="Ingresos"  className={'title'} />

        <CardContent>
          {dataIncome.length === 0 && statusCode === undefined ? <CustomProgress/> : 
            dataIncome.length === 0 && statusCode === 0  || dataIncome.length === 0 && statusCode === 200? 
            <div className={classes.divMessage}>No tiene facturas registradas</div> :
        <ComposedChart
        width={1000}
        height={250}
        data={dataIncome}
        className={classes.composedChart}
        margin={{
          top: 20, right: 80, bottom: 20, left: 50,
        }}
      >
        
        <XAxis dataKey="name" tick={{stroke: '#FFFFFF'}} />
      {/** <YAxis dataKey='Beneficios' tick={{stroke: '#FFFFFF'}} tickSize={10} orientation= 'left' minTickGap={2}/>*/}
      <YAxis  tick={{stroke: '#FFFFFF'}} tickSize={10} orientation= 'left' minTickGap={2} />  
      {/** <Legend iconType="circle" iconSize={10}/> */} 

        <Tooltip cursor={false}/>
          <Bar dataKey="ingresos" barSize={20} fill="#50C1EE" />
         <Bar dataKey="Costos" barSize={20} fill="#F1474A" /> 

        <Line type="monotone" dataKey="Beneficios" stroke="#DDDD1C"/>
        <YAxis />
      </ComposedChart> }
      <div style={{textAlign:'center'}}>
      <FormControlLabel
        control={<Brightness1Icon className={classes.sizeIconIcome}/>} 
        label={<div className={classes.styleLabel}>Ingresos</div>}
      />
      &nbsp;
      &nbsp;
      &nbsp;
      <FormControlLabel
        control={<Brightness1Icon className={classes.sizeIconCost}/>}
        label={<div className={classes.styleLabel}>Costos</div>}
      />
      &nbsp;
      &nbsp;
      &nbsp;
      <FormControlLabel
        control={<Brightness1Icon className={classes.sizeIconBenefit}/>}
        label={<div className={classes.styleLabel}>Beneficios</div>}
      />
      
      </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(useStyles)(IncomeChart);
