import React from 'react';
import cogoToast from 'cogo-toast';

export const readGeneralError = (errorResponse) => {
    const error = errorResponse.error;
    const message = `${error.statusMessage}  ${!!errorResponse.message? errorResponse.message: ''} `;

    switch (error.statusCode) {
        case '500':
            errorToast('Error 500', message);
            break;
        case '400':
            warningToast('Error 400, petición invalida', message);
            break;
        case '404':
            warningToast('Error 404 usuario no autorizado', message);
            break;
        case '401':
            errorToast('Error 401 error no controlado"', message);
            break;
        default:
            errorToast('Opss!!"', 'Ha ocurrido un error no identificado, Por favor contacte al administrador');
            break;
    }
}


const errorToast = (title, message) => {

    const { hide } = cogoToast.error(
        <div>
            <b>{title}</b>
            <div>{message}</div>
        </div>, {
        onClick: () => {
            hide();
        },
        position: 'top-right',
        hideAfter: 10
    });
}

const warningToast = (title, message) => {

    const { hide } = cogoToast.error(
        <div>
            <b>{title}</b>
            <div>{message}</div>
        </div>, {
        onClick: () => {
            hide();
        },
        position: 'top-right',
        hideAfter: 10
    });
}