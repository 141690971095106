import React, { Component } from "react";
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import SimpleReactValidator from "simple-react-validator";
import InputForm from "../../components/input/input.component";
import { SuccessAlert } from '../../helpers/alert.helpers';
import { withStyles } from "@material-ui/core/styles";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import {
  postCharge,
  putCharge,
  getCharges,
  changeEditingChargeStatus,
  clearCurrentCharge,
  clearUpdateCharge,
  clearCreateCharge,
} from './../../actions/chargeAction';
import ToggleButtons from '../../components/toggle/toggle.component';
import ButtonPrimary from '../../components/button/buttonPrimary.component';
import { regexDecimal } from '../../helpers/customRegex.hepers';


const useStyles = (theme) => ({
  paddingTop: {
    paddingTop: 200
  }

});

class NewCharge extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        toogleChargeType: '1',
        txtChargeDescription: '',
        txtChargeValue: null,
      },
      createCharge: {},
      updateCharge: {},
      currentCharge: {},
      editingChargeData: false,
      alterForm: {
        createdby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        createdon: new Date(),
        empresaid: JSON.parse(localStorage.getItem('dataCompany')).companyId,
        esglobal: false,
        id: 0,
        idbusinessunit: 0,
        idowner: 0,
        idstate: 0,
        modifiedby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        modifiedon: new Date(),
      },
      loadingSave: false,
      components: [],
      btnSaveCharge: '',
      txtTypeCharge: '',
      txtNameCharge: '',
      txtValueCharge: '',
      recordSuccessHeader: '',
      recordSuccessBody: '',
      updateSuccessHeader: '',
      updateSuccessBody: '',
      openModal: false,
      modalData: {
        modalType: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        closeElement: '',
      },
    }

    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.chargeReducer.createCharge) {
      if (nextProps.chargeReducer.createCharge.statusCode === '201') {
        update.createCharge = nextProps.chargeReducer.createCharge;
      }
    }

    if (!!nextProps.chargeReducer.updateCharge) {
      if (nextProps.chargeReducer.updateCharge.statusCode === '201') {
        update.updateCharge = nextProps.chargeReducer.updateCharge;
      }
    }

    if (nextProps.chargeReducer.currentCharge !== state.currentCharge) {
      update.currentCharge = nextProps.chargeReducer.currentCharge;
      update.editingChargeData = nextProps.chargeReducer.editingChargeData;
    }

    if (nextProps.chargeReducer.loadingSave !== state.loadingSave) {
      update.loadingSave = nextProps.chargeReducer.loadingSave;
    }

    if (!!nextProps.configReducer.components !== state.components) {
      update.components = nextProps.configReducer.components;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.createCharge !== this.state.createCharge) {
      if (this.state.createCharge.statusCode === '201') {
        SuccessAlert(this.state.recordSuccessHeader, this.state.recordSuccessBody);
        this.cleanData();
      }
    }

    if (prevState.currentCharge !== this.state.currentCharge) {
      if (!!this.state.currentCharge)
        this.loadInfo(this.state.currentCharge);
    }

    if (prevState.updateCharge !== this.state.updateCharge) {
      if (this.state.updateCharge.statusCode === '201') {
        SuccessAlert(this.state.updateSuccessHeader, this.state.updateSuccessBody);
        this.cleanData();
        this.props.changeEditingChargeStatus(false);
      }
    }

    if (prevState.components !== this.state.components) {
      if (!!this.state.components && this.state.components.length > 0) {
        this.assignConfigData();
      }
    }
  }

  assignConfigData() {
    const btnSaveChargeConst = this.filterConfigInfo(46);
    const txtTypeChargeConst = this.filterConfigInfo(47);
    const txtNameChargeConst = this.filterConfigInfo(48);
    const txtValueChargeConst = this.filterConfigInfo(49);
    const popupChargeRecordSuccess = this.filterConfigInfo(50);
    const popupChargeUpdateSuccess = this.filterConfigInfo(51);

    if (!!btnSaveChargeConst) {
      this.setState({
        btnSaveCharge: this.filterConfigInfoList(btnSaveChargeConst.adcomponenteinformacion, 49).text,
      })
    }
    if (!!txtTypeChargeConst) {
      this.setState({
        txtTypeCharge: this.filterConfigInfoList(txtTypeChargeConst.adcomponenteinformacion, 50).text,
      })
    }
    if (!!txtNameChargeConst) {
      this.setState({
        txtNameCharge: this.filterConfigInfoList(txtNameChargeConst.adcomponenteinformacion, 51).text,
      })
    }
    if (!!txtValueChargeConst) {
      this.setState({
        txtValueCharge: this.filterConfigInfoList(txtValueChargeConst.adcomponenteinformacion, 52).text,
      })
    }
    if (!!popupChargeRecordSuccess) {
      this.setState({
        recordSuccessHeader: this.filterConfigInfoList(popupChargeRecordSuccess.adcomponenteinformacion, 53).text,
        recordSuccessBody: this.filterConfigInfoList(popupChargeRecordSuccess.adcomponenteinformacion, 24).text,
      })
    }
    if (!!popupChargeUpdateSuccess) {
      this.setState({
        updateSuccessHeader: this.filterConfigInfoList(popupChargeUpdateSuccess.adcomponenteinformacion, 25).text,
        updateSuccessBody: this.filterConfigInfoList(popupChargeUpdateSuccess.adcomponenteinformacion, 26).text,
      })
    }
  }

  filterConfigInfo(id) {
    const configData = this.state.components;
    const data = configData.find(item => item.id === id);
    return data
  }

  filterConfigInfoList(list, id) {
    const data = list.find(item => item.id === id);
    return data
  }

  cleanData() {
    this.setState({
      form: {
        toogleChargeType: '1',
        txtChargeDescription: '',
        txtChargeValue: 0,
      },
      alterForm: {
        createdby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        createdon: new Date(),
        empresaid: JSON.parse(localStorage.getItem('dataCompany')).companyId,
        esglobal: false,
        id: 0,
        idbusinessunit: 0,
        idowner: 0,
        idstate: 0,
        modifiedby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        modifiedon: new Date(),
      },
    });
    this.validator.hideMessages();
    this.props.clearCurrentCharge();
    this.props.clearUpdateCharge();
    this.props.clearCreateCharge();
  }

  syncChanges(value, property) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [property]: value,
      },
    }));
  }

  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked
      }
    }))
  };

  loadInfo(item) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleChargeType: item.tipocargo === 1 ? '1' : '2',
        txtChargeDescription: item.descripcion,
        txtChargeValue: item.valor,
      },

      alterForm: {
        ...prevState.alterForm,
        createdby: item.createdby,
        createdon: item.createdon,
        empresaid: item.empresaid,
        esglobal: item.esglobal,
        id: item.id,
        idbusinessunit: item.idbusinessunit,
        idowner: item.idowner,
        idstate: item.idstate,
        modifiedby: JSON.parse(localStorage.getItem('dataCompany')).userId,
        modifiedon: item.modifiedon,
      },
    }))
  }

  onSaveCharge = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        tipocargo: this.state.form.toogleChargeType === '1' ? 1 : 2,
        descripcion: this.state.form.txtChargeDescription,
        valor: this.state.form.txtChargeValue,
        createdby: this.state.alterForm.createdby,
        createdon: this.state.alterForm.createdon,
        empresaid: this.state.alterForm.empresaid,
        esglobal: this.state.alterForm.esglobal,
        id: this.state.alterForm.id,
        idbusinessunit: this.state.alterForm.idbusinessunit,
        idowner: this.state.alterForm.idowner,
        idstate: this.state.alterForm.idstate,
        modifiedby: this.state.alterForm.modifiedby,
        modifiedon: this.state.alterForm.modifiedon,
      }

      if (this.state.editingChargeData)
        this.props.putCharge(data)
      else
        this.props.postCharge(data)

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleAlignmentChargeType = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleChargeType: !!newAlignment ? newAlignment : '1'
      }
    }))
  };

  validateOnlyNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexDecimal;
    value = value.replace(regex, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      }
    }))
  }

  validateFieldsOnBlur(e, name) {
    e.preventDefault();
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name)
    }
  }

  render() {
    return (
      <div>
        <form id="chargeForm" onSubmit={(e) => this.onSaveCharge(e)}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <ToggleButtons
                label={this.state.txtTypeCharge}
                value={this.state.form.toogleChargeType}
                onChange={this.handleAlignmentChargeType}
                titleOne={'$'}
                titleTwo={'%'}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputForm
                id={"txtChargeDescription"}
                label={this.state.txtNameCharge}
                maxLength={200}
                value={this.state.form.txtChargeDescription}
                onChange={(e) =>
                  this.syncChanges(e.target.value, "txtChargeDescription")
                }
                nameValidator={'Nombre Cargo'}
                onBlur={(e) =>
                  this.validateFieldsOnBlur(e, 'Nombre Cargo')
                }
                validator={this.validator}
                validateOptions={"required"}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputForm
                id={"txtChargeValue"}
                label={this.state.txtValueCharge}
                value={this.state.form.txtChargeValue}
                format={this.state.form.toogleChargeType === '1' ? true : false}
                onChange={(ev) => {
                  this.syncChanges(ev.target.value, "txtChargeValue");
                  this.validateOnlyNumbers(ev);
                }
                }
                nameValidator={'Valor'}
                onBlur={(e) =>
                  this.validateFieldsOnBlur(e, 'Valor')
                }
                validator={this.validator}
                validateOptions={this.state.form.toogleChargeType === '1'
                  ? 'required|noNegative'
                  : 'required|percentageValue'
                }
              />
            </Grid>
            <Grid container>
              <Grid item lg={4} md={4}></Grid>
              <Grid item lg={4} md={4}></Grid>
              <Grid item lg={4} md={4} style={{ paddingTop: -50 }}>
                <ButtonPrimary
                  text={this.state.btnSaveCharge}
                  loading={this.state.loadingSave}
                />
              </Grid>

            </Grid>
          </Grid>

        </form>
      </div>
    );
  }

}

NewCharge.propTypes = {
  chargeReducer: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  chargeReducer: state.chargeReducer,
  configReducer: state.configReducer,
})

export default connect(mapStateToProps, {
  postCharge,
  putCharge,
  getCharges,
  changeEditingChargeStatus,
  clearCurrentCharge,
  clearUpdateCharge,
  clearCreateCharge,
})(
  withStyles(useStyles)(NewCharge));