import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { ErrorAlert } from '../../../helpers/alert.helpers'
import { regexDecimal } from '../../../helpers/customRegex.hepers'
import InputSmall from "../../../components/input/inputSmall.component";
import InputForm from "../../../components/input/input.component";
import RadioButtonGroupSmall from '../../../components/radioButton/radioButtonSmall.component'
import AutoIncrementSmall from '../../../components/autoIncrement/autoIncrementSmall.component'

import { updateCartAction,  updateDescriptionItemCartAction, validateTotalDocumentAction, updateCartAfterItemChanges } from '../../../actions/valuesInvoiceAction'
import { URL_IMAGES } from '../../../config/config.js';

import { calculateValuesItem, calculateTotal } from '../../../utils/calculateInvoiceValues'
import getTypeValue from '../../../utils/typeValue'



const styles = theme => ({
    text: {
        color: theme.palette.primaryColor,
        fontSize: 15,
        fontWeight: 500
    },
    totalTitle: {
        fontSize: 10
    },
    value: {
        color: theme.palette.secundaryColor,
        fontSize: 15,
        fontWeight: 600
    },
    iconLock: {
        fontSize: 15,
        color: '#3c3838',
        padding: 0,
        marginTop: 30
    },
    tag: {
        width: 100,
        borderRadius: 10,
        color: theme.palette.primaryColor,
        fontWeight: 600,
        fontSize: 12,
        marginTop: 2,
        marginRight: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 3,
        paddingBottom: 3,
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center'
    },
    containerTag: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        width: '90%',
    },
    total: {
        color: theme.palette.secundaryColor,
        fontSize: 21,
        fontWeight: 600
    },
    styleCard: {
        backgroundColor: theme.palette.ninethBackgroundColor,
        borderRadius: '5px',
        color: theme.palette.primaryColor,
        border: 'none',
        margin: 10,
        '& .MuiCardContent-root': {
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10

        },
    },
    title: {
        '& .MuiInputBase-input':{
            color: theme.palette.primaryColor,
            fontSize: 18,
            fontWeight: 600,
        }
    },
    content: {
        paddingBottom: 0,
        paddingTop: 0
    },
    icon: {
        color: theme.palette.primaryColor,
    },
    cover: {
        borderRadius: 10,
        position: 'relative',
        width: '100%',
        height: '100%',
        color: theme.palette.primaryColor,
    },
    headerCard: {
        paddingTop: 5,
        paddingBottom: 0
    },
    titleImage: {
        position: 'absolute',
        textAlign: "center",
        color: theme.palette.primaryColor,
        top: '30%',
        left: '35%',
        fontSize: '5vh'
    },
    totalInvalid:{
        color: 'red',
        fontSize: 21,
        fontWeight: 600
    }
});


class InvoiceItemManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                rbgTypeDiscount: '2',
                txtDiscountValue: 0,
                txtQuantity: 0.0,
                txtUnitValue: 0,
                labelTotal: 0,
                txtDescription: ''
            },
            data: {}

        }
        this.validator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentDidMount() { }



    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.data && nextProps.data !== state.data) {
            update.data = nextProps.data;
            const data = nextProps.data;
            update.form = {
                rbgTypeDiscount: data.tipodescuento.toString(),
                txtQuantity: data.cantidad,
                txtDiscountValue: data.tipodescuento === 2 ? data.porcentajedescuento : data.valordescuento,
                txtUnitValue: data.valorNeto,
                labelTotal: data.total,
                txtDescription: data.descripcion
            }
        }

        return Object.keys(update).length ? update : null;
    }


    //Sincroniza cadenas de tecto
    syncChangesString(e) {
        let name = e.target.name;
        let value = e.target.value;

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    }

    //Sincroniza valores decimales
    syncChanges(e) {
        let name = e.target.name;
        let value = e.target.value;
        value = value.replace(regexDecimal, '');

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    }

    syncRadioChanges(e, id) {
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }


    //Valida regla simple React validator, campo discuento
    validRuleDiscount(e) {
        let name = e.target.name;
        let value = e.target.value;
        value = value.replace(regexDecimal, '');

        let type = this.state.form.rbgTypeDiscount;
        let validLocal = true;

        //Validación Local (1 valor, 2 porcentaje)
        if (type === '2')
            validLocal = (parseFloat(value) <= 101) ? true : false;
        else if (type === '1')
            validLocal = parseFloat(value) <= (parseFloat(this.state.form.txtUnitValue) * parseFloat(this.state.form.txtQuantity)) ? true : false;


        if (this.validator.fieldValid(name) === false || validLocal === false) {
            this.validator.showMessageFor(name);
            this.forceUpdate();
        }
    }

    /**
     * @event
     * @description Aumenta valor en campo cantidad
     * @param {*} e 
     */
    handleIncrement(e) {
        let counter = this.state.form.txtQuantity === '' ? 0 : parseFloat(this.state.form.txtQuantity);
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtQuantity: counter + 1
            }
        }))
        this.onChangesQuantity(counter + 1);
    }

    /**
     * @event
     * @description Disminuye valor en campo cantidad
     * @param {*} e 
     */
    handleDecrement(e) {
        let counter = this.state.form.txtQuantity === '' ? 0 : parseFloat(this.state.form.txtQuantity);
        let newcounter = counter - 1;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtQuantity: newcounter < 0 ? 0 : newcounter
            }
        }))
        this.onChangesQuantity(counter - 1);
    }


    /**
     * @event
     * @description Escucha cambio en campo valor unitario,
     * recalcula valores totales
     * @param {*} e 
     */

    onChangesUnitValue(e) {
        e.preventDefault();
        try {

            let value = e.target.value;
            value = value === '' ? '0' : value.replace(regexDecimal, '');

            let newData = this.state.data;
            const type = parseInt(this.state.form.rbgTypeDiscount);

            newData = {
                ...newData,
                tipodescuento: type,
                valordescuento: type === 2 ? 0 : parseFloat(this.state.form.txtDiscountValue),
                porcentajedescuento: type === 1 ? 0 : parseFloat(this.state.form.txtDiscountValue),
                cantidad: parseFloat(this.state.form.txtQuantity),
                valorNeto: parseFloat(value)
            }
            calculateValuesItem(newData);
            this.refreshForm(newData);

        } catch (err) {
            ErrorAlert('Opss!!', 'Ha ocurrido un error recalculando total, con valor unitario digitado');
        }
    }


    /**
     * @event
     * @description Escucha cambio en campo cantidad,
     * recalcula valores totales
     * @param {} e 
     */
    onChangesQuantity(value) {
        try {

            let newValue = value.toString();
            newValue = newValue === '' ? '0' : newValue.replace(regexDecimal, '');

            let newData = this.state.data;
            const type = parseInt(this.state.form.rbgTypeDiscount);

            newData = {
                ...newData,
                valorNeto: parseFloat(this.state.form.txtUnitValue),
                tipodescuento: type,
                valordescuento: type === 2 ? 0 : parseFloat(this.state.form.txtDiscountValue),
                porcentajedescuento: type === 1 ? 0 : parseFloat(this.state.form.txtDiscountValue),
                cantidad: parseFloat(newValue)
            }

            calculateValuesItem(newData);
            this.refreshForm(newData);
 
     } catch (err) {

            ErrorAlert('Opss!!', 'Ha ocurrido un error recalculando total, con cantidad digitada');
        }
    }

    /**
     * @event 
     * @description Escucha cambio en campo porcentaje,
     * recalcula valores totales
     * @param {*} e 
     */
    onChangesDiscount(value, type) {

        try {
            let newValue = value.toString();
            newValue = newValue === '' ? '0' : newValue.replace(regexDecimal, '');


            let valid = this.validator.fieldValid('txtDiscountValue');
            if (valid === false)
                newValue = "0";

            let newData = this.state.data;

            newData = {
                ...newData,
                cantidad: parseFloat(this.state.form.txtQuantity),
                valorNeto: parseFloat(this.state.form.txtUnitValue),
                tipodescuento: type,
                valordescuento: type === 2 ? 0 : parseFloat(newValue),
                porcentajedescuento: type === 1 ? 0 : parseFloat(newValue)
            }
            calculateValuesItem(newData);
            this.refreshForm(newData);

        } catch (err) {
            ErrorAlert('Opss!!', 'Ha ocurrido un error recalculando total, con descuento digitado');
        }
    }


    /**
     * Escucha cambios en tipo de descuento
     * @param {Object} e Evento
     */
    onChangeTypeDiscount(e) {
        const type = e.target.value;
        this.onChangesDiscount(0, parseInt(type));
    }


    /**
     * Escucha cambios en la descipción del articulo
     * @param {Object} e Evento
     */
    onChangeDescription(e){
        e.preventDefault();

        let value = e.target.value === '' ? this.state.data.nombrearticulo : e.target.value;
        let newData = this.state.data;

        newData = {
            ...newData,
            descripcion: value
        }

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtDescription: value
            },
        }))

        this.props.updateDescriptionItemCartAction(newData);
    }
    
    /**
     * @function
     * @description Refresca formulario despues de calculo de valores
     * @param {*} data 
     */
    refreshForm(data) {

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                rbgTypeDiscount: data.tipodescuento.toString(),
                txtQuantity: data.cantidad,
                txtDiscountValue: data.tipodescuento === 2 ? data.porcentajedescuento : data.valordescuento,
                txtUnitValue: data.valorNeto,
                labelTotal: data.total

            },
        }))
        this.props.updateCartAction(data);
    }


    subNameItem = (name) => {
        return name ? name.substring(0, 2).toUpperCase() : "";
    };


    render() {
        const { classes, onDelete } = this.props;
        const { data, newData } = this.props;


        return (
            <Card className={classes.styleCard}>
                <CardHeader
                    action={
                        <IconButton aria-label="settings" onClick={onDelete} >
                            <DeleteIcon className={classes.icon} />
                        </IconButton>
                    }
                    className={classes.headerCard}
                    classes={{
                        title: classes.title,
                    }}
                    title={
                        <InputForm
                            name={'txtDescription'}
                            label={""}
                            maxLength={200}
                            value={this.state.form.txtDescription}
                            variant={'standard'}
                            onChange={(e) => {
                                this.syncChangesString(e);
                            }}
                            onBlur={(e) => this.onChangeDescription(e)}
                        />}
                    subheader="" />

                {/* Contenido de tarjeta -- Formulario e imagen */}
                <CardContent className={classes.content}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} xs={3}>
                            <CardMedia
                                className={classes.cover}
                                image={URL_IMAGES + data.imagen}
                                style={{
                                    background: `${data.imagen === null || data.imagen === undefined || data.imagen === '' ?
                                        data.color :
                                        null}`,
                                }}
                                title="image" >
                                {data.imagen === null || data.imagen === undefined || data.imagen.length === 0 ? (
                                    <span className={classes.titleImage}>
                                        {this.subNameItem(data.descripcion)}
                                    </span>
                                ) : ''}

                            </CardMedia>
                        </Grid>

                        <Grid item lg={9} xs={9}>
                            <Fragment>
                                <Grid container spacing={1}>
                                    <Grid item lg={4} xs={12}>
                                        <InputSmall
                                            name={'txtUnitValue'}
                                            label={"Valor Unitario"}
                                            maxLength={20}
                                            value={this.state.form.txtUnitValue}
                                            variant={'standard'}
                                            onChange={(e) => {
                                                this.syncChanges(e);
                                            }}
                                            onBlur={(e) => this.onChangesUnitValue(e)}
                                            disabled={data.valoreditable === true ? false : true}
                                            format={true}
                                            lockIcon={data.valoreditable === true ? false : true}
                                        />
                                    </Grid >
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item lg={12} xs={12}>
                                        <span className={classes.text}>IVA: </span><span className={classes.value}>{data.porcentajeiva}% </span>
                                         &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className={classes.text}>INC: </span><span className={classes.value}>{data.porcentajeinc}% </span>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={8} xs={8}>
                                        <div className={classes.containerTag}>
                                            {data.categorias?.map((category, index) =>
                                                <span className={classes.tag} style={{ backgroundColor: category?.categoria?.color }}> {category?.categoria?.nombre}</span>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item lg={4} xs={4}>
                                        <AutoIncrementSmall
                                            name={"txtQuantity"}
                                            value={this.state.form.txtQuantity}
                                            onChange={(e) => {
                                                this.syncChanges(e);
                                            }}
                                            onBlur={(e) => this.onChangesQuantity(e.target.value)}
                                            handleIncrement={(e) => this.handleIncrement(e)}
                                            handleDecrement={(e) => this.handleDecrement(e)}
                                            maxLength={15}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                    <Grid item lg={4} xs={4}>
                                        <RadioButtonGroupSmall
                                            name={'rbgTypeDiscount'}
                                            value={this.state.form.rbgTypeDiscount}
                                            onChange={(e) => this.onChangeTypeDiscount(e)}
                                            options={getTypeValue()}
                                            label={"Descuentos"}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={4}>
                                        <InputSmall
                                            name={'txtDiscountValue'}
                                            label={this.state.form.rbgTypeDiscount === '2' ? "Porcentaje" : "Valor Descuento"}
                                            maxLength={20}
                                            value={this.state.form.txtDiscountValue}
                                            variant={'standard'}
                                            onChange={(e) => {
                                                this.syncChanges(e);
                                                this.validRuleDiscount(e);
                                            }}
                                            onBlur={(e) => this.onChangesDiscount(e.target.value, parseInt(this.state.form.rbgTypeDiscount))}
                                            format={this.state.form.rbgTypeDiscount === '1' ? true : false}
                                            nameValidator={"txtDiscountValue"}
                                            validator={this.validator}
                                            validateOptions={this.state.form.rbgTypeDiscount === '2' ?
                                                'porcentage' :
                                                [{ discountvalue: (parseFloat(this.state.form.txtUnitValue) * parseFloat(this.state.form.txtQuantity)) }]
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={4}>
                                        <span className={classes.totalTitle}>Total </span><br />
                                        {newData?.totalreal <= 0 ?
                                            <span className={classes.totalInvalid}>{`$ ${this.state.form.labelTotal.toLocaleString('en')}`}  </span> :
                                            <span className={classes.total}>{`$ ${this.state.form.labelTotal.toLocaleString('en')}`}  </span>
                                        }

                                    </Grid>
                                </Grid>
                            </Fragment>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }


}

InvoiceItemManage.propTypes = {
    data: PropTypes.object.isRequired,
    updateCartAction: PropTypes.func.isRequired,
    updateDescriptionItemCartAction: PropTypes.func.isRequired,
    validateTotalDocumentAction: PropTypes.func.isRequired,

    valuesInvoiceReducer: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
    valuesInvoiceReducer: state.userReducer,

})


export default connect(mapStateToProps, {
    updateCartAction,
    updateDescriptionItemCartAction,
    validateTotalDocumentAction
})(withStyles(styles)(InvoiceItemManage));


