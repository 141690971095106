import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Tooltip } from '@material-ui/core';

import InputIcon from '../../components/input/inputIcon.component'
import CollapsePanel from './../../components/collapse/collapse.component';
import { ConfirmAlert, SuccessAlert } from '../../helpers/alert.helpers';
import NewDiscount from './newDiscount.component';
import DiscountCard from './discountCard.component.js';
import {
  getFilterDiscount,
  getDiscounts,
  deleteDiscount,
  clearDeleteDiscount,
} from './../../actions/discountAction';
import { getRoleOfLoggedUser } from '../../actions/authActions';
import CustomProgress from '../../components/Progress/progress.component';

const useStyles = (theme) => ({
  txtFilter: {
    margin: '10px 0px 10px 0px',
    color: theme.palette.primaryColor
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

class DiscountManage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      deleteDiscount: {},
      createDiscount: {},
      updateDiscount: {},
      currentDiscount: {},
      txtFilter: '',
      openPanel: '',
      editingDiscountData: false,
      enterpriseId: 6,
      components: [],
      wantToDeleteHeader: '',
      wantToDeleteBody: '',
      recordRemovedHeader: '',
      recordRemovedBody: '',
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.discountReducer.getDiscountsEnterprise !== state.data) {
      update.data = nextProps.discountReducer.getDiscountsEnterprise;
    }

    if (!!nextProps.discountReducer.getFilterDiscounts) {
      update.data = nextProps.discountReducer.getFilterDiscounts;
    }

    if (nextProps.discountReducer.editingDiscountData !== state.editingDiscountData) {
      update.editingDiscountData = nextProps.discountReducer.editingDiscountData;
    }

    if (!!nextProps.discountReducer.createDiscount) {
      if (nextProps.discountReducer.createDiscount.statusCode === '201') {
        update.createDiscount = nextProps.discountReducer.createDiscount;
        update.openPanel = '';
      }
    }

    if (!!nextProps.discountReducer.updateDiscount) {
      if (nextProps.discountReducer.updateDiscount.statusCode === '201') {
        update.updateDiscount = nextProps.discountReducer.updateDiscount;
        update.openPanel = '';
      }
    }

    if (!!nextProps.discountReducer.deleteDiscount) {
      if (nextProps.discountReducer.deleteDiscount.statusCode === '201') {
        update.deleteDiscount = nextProps.discountReducer.deleteDiscount;
      }
    }

    if (!!nextProps.configReducer.components !== state.components) {
      update.components = nextProps.configReducer.components;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getDiscounts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.createDiscount !== this.state.createDiscount) {
      if (this.state.createDiscount.statusCode === '201') {
        (this.state.txtFilter !== '') ? this.props.getDiscounts(this.state.txtFilter) : this.props.getDiscounts()
      }
    }

    if (prevState.updateDiscount !== this.state.updateDiscount) {
      if (this.state.updateDiscount.statusCode === '201') {
        (this.state.txtFilter !== '') ? this.props.getDiscounts(this.state.txtFilter) : this.props.getDiscounts()
      }
    }

    if (prevState.deleteDiscount !== this.state.deleteDiscount) {
      if (this.state.deleteDiscount.statusCode === '201') {
        SuccessAlert(this.state.recordRemovedHeader, this.state.recordRemovedBody);
        this.cleanData();
        (this.state.txtFilter !== '') ? this.props.getDiscounts(this.state.txtFilter) : this.props.getDiscounts()
      }
    }

    if (prevState.components !== this.state.components) {
      if (!!this.state.components && this.state.components.length > 0) {
        this.assignConfigData();
      }
    }
  }

  cleanData() {
    this.props.clearDeleteDiscount();
  }

  assignConfigData() {
    const popupDiscountWantToDelete = this.filterConfigInfo(62);
    const popupDiscountRecordRemoved = this.filterConfigInfo(63)
    if (!!popupDiscountWantToDelete) {
      this.setState({
        wantToDeleteHeader: this.filterConfigInfoList(popupDiscountWantToDelete.adcomponenteinformacion, 41).text,
        wantToDeleteBody: this.filterConfigInfoList(popupDiscountWantToDelete.adcomponenteinformacion, 42).text,
      })

    }
    if (!!popupDiscountRecordRemoved) {
      this.setState({
        recordRemovedHeader: this.filterConfigInfoList(popupDiscountRecordRemoved.adcomponenteinformacion, 43).text,
        recordRemovedBody: this.filterConfigInfoList(popupDiscountRecordRemoved.adcomponenteinformacion, 44).text,
      })
    }
  }

  filterConfigInfo(id) {
    const configData = this.state.components;
    const data = configData.find(item => item.id === id);
    return data
  }

  filterConfigInfoList(list, id) {
    const data = list.find(item => item.id === id);
    return data
  }

  onClickCollapse = () => {
    if (this.state.openPanel === 'panel') {
      this.setState({
        openPanel: ''
      })
    } else if (this.state.openPanel === '') {
      this.setState({
        openPanel: 'panel',
      })
    }
  }

  onLoad(e, item) {
    e.preventDefault();
    this.props.getFilterDiscount(item);

    this.setState({
      openPanel: 'panel',
    })
  }

  onDeleteData(e, item) {
    const deleteData = (confirmed) => {
      if (confirmed) {
        this.props.deleteDiscount(item.id);
      }
    }
    ConfirmAlert(this.state.wantToDeleteHeader, `${this.state.wantToDeleteBody} ${item.descripcion}`, deleteData)
  }

  syncChanges(value, property) {
    this.setState((prevState) => ({
      ...prevState.txtFilter,
      [property]: value,
    }));
  }

  onChangeFilter(e) {
    e.preventDefault();
    const value = e.target.value;
    this.props.getDiscounts(value, false);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12} >
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12} >
              {
                (getRoleOfLoggedUser())
                  ? <CollapsePanel
                    component={NewDiscount}
                    title={
                      <LightTooltip
                        title="No tiene permisos sobre esta acción"
                        placement="bottom">
                        <span>Crear nuevo descuento</span>
                      </LightTooltip>
                    }
                    titleOpen={"Crear nuevo descuento"}
                    titleEdit={"Crear nuevo descuento"}
                    open={''}
                  />
                  : <CollapsePanel
                    component={NewDiscount}
                    title={!this.state.editingDiscountData ? "Crear nuevo descuento" : "Editar descuento"}
                    titleOpen={!this.state.editingDiscountData ? "Crear nuevo descuento" : "Editar descuento"}
                    titleEdit={!this.state.editingDiscountData ? "Crear Nuevo descuento" : "Editar descuento"}
                    open={this.state.openPanel}
                    onClick={this.onClickCollapse}
                  />
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} className={classes.txtFilter}>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12} >
              <InputIcon
                id={"txtFilter"}
                label={"Buscar descuento..."}
                maxLength={15}
                value={this.state.txtFilter}
                onChange={(e) => {
                  this.syncChanges(e.target.value, "txtFilter")
                  this.onChangeFilter(e);
                }} />
            </Grid>
          </Grid>
        </Grid>
        {!!this.state.data && this.state.data.length > 0
          ? this.state.data.map((item, index) => (
            <Grid item lg={12} xs={12}>
              <DiscountCard key={index}
                data={item}
                onDelete={e => { this.onDeleteData(e, item) }}
                onLoad={e => { this.onLoad(e, item) }}
              ></DiscountCard>
            </Grid>
          ))
          : <Grid container>
            <Grid item lg={4} xs={4}></Grid>
            <Grid item lg={4} xs={4}><CustomProgress /></Grid>
            <Grid item lg={4} xs={4}></Grid>
          </Grid>
        }
      </Grid>
    );
  }

}

DiscountManage.propTypes = {
  discountReducer: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  discountReducer: state.discountReducer,
  configReducer: state.configReducer,
})

export default connect(mapStateToProps, {
  getFilterDiscount,
  getDiscounts,
  deleteDiscount,
  clearDeleteDiscount,
})(
  withStyles(useStyles)(DiscountManage));
