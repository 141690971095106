import React, { Fragment,Component } from "react";

import { withStyles } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";
import {Card,CardContent,Grid} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";

import goldPlate from "../../../src/images/PlacaORO.png";
import silverPlate from "../../../src/images/PlacaPlata.png";
import bronzePlate from "../../../src/images/PlacaBronce.png";

import CustomProgress from '../Progress/progress.component';


const useStyles = (theme) => ({
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
  },
  cardGoldPlate: {
    background: "linear-gradient( #1879C7 0%, #014780 100%)",
    height: 130,
    widht:60
  },
  cardSilverPlate: {
    background: "linear-gradient( #E39117 0%, #935801 100%)",
    height: 130,
    widht:60
  },
  cardBrozePlate: {
    background: "linear-gradient( #76A97B 0%, #29652F 100%)",
    height: 130,
    widht:60
  },
  contentCard: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 14,
    fontWeight: 800
  },
  priceCard: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: '-8px'
  },
  contentCardInvoice: {
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 12,
    fontWeight: 400,
  },
  cardGeneral: {
    background: "linear-gradient( #7C7B7B 0%, #434242 100%)",
    height: 130,
    widht:60
  },
  cardDisable: {
    background: "linear-gradient( #7C7B7B 0%, #434242 100%)",
    opacity: 0.3,
    height: 130,
    widht:60
  },
  platePosition: {
    top: '75%',
    marginLeft: "1rem",
    position: "absolute",
  },
  icon: {
    fill: theme.palette.primaryColor,
  },
  cardContent:{
    position: "realtive",
    paddingBottom: 60 
  },
  divMessage:{
    textAlign:'center',
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  }
});

class BasicCarrousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: false,
      timer: 500,
      animation: "slide",
      indicators: false,
      timeout: 200,
      numItemPerPage: 6,
      pages: 0,
      navButtonsAlwaysVisible: true,
    };
  }
  /**
   *
   * @param {*} classes
   * @param {*} data
   * @param {*} index
   */
  Card(classes, data, index) {
    return (
      <Grid item lg={2} xs={2}>
        
        <Card lg={2} xs={2} key={index}
        
          className={index===0 ? classes.cardGoldPlate: index===1 ? classes.cardSilverPlate: index===2
              ? classes.cardBrozePlate : index === 5 ? classes.cardDisable : classes.cardGeneral}>

          <CardContent className={classes.cardContent}>
            <span className={classes.contentCard}>{data.collaboratorId !== null ? data.name  : 'Sin Vendedor'}</span>
            <br/>
            <span className={classes.priceCard}>
              ${data.totalSold.toLocaleString("en")}
            </span>
            <br/>
            <div>
              <span className={classes.contentCardInvoice}>
                {data.quantityInvoice}
              </span>
              &nbsp;
              <span className={classes.contentCardInvoice}>Facturas</span>
            </div>
            <div className={classes.platePosition}>
             {/** <img src={data.rank === 1 ? goldPlate : data.rank === 2 ? silverPlate : data.rank === 3 ? bronzePlate : ''}/>*/} 
              <img src = {index===0 ? goldPlate:index === 1 ? silverPlate:index === 2 
              ? bronzePlate : index === 5 ? '' : ''}/>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  render() {
    const { name, classes, items, statusCode} = this.props;

    let ejemplo = 200
    let list = [];
    let listCards = [];
    for (let index = 0; index < items.length; index++) {
      if (
        (index + 1) % this.state.numItemPerPage !== 0 &&
        index + 1 !== items.length
      ) {
        list.push(items[index]);
      } else {
        list.push(items[index]);
        listCards.push(list);
        list = [];
      }
    }
    return (
      <Card className={classes.colorCard}>
        <CardContent>
          <CardHeader title={name} className={"title"} />
          <Fragment>
             
            { items.length === 0 && statusCode === undefined ? <CustomProgress/> : 
            items.length === 0 && statusCode === 0  || items.length === 0 && statusCode === 200? 
            <div className={classes.divMessage}>No tiene facturas registradas</div> :
            !!listCards && listCards.length && (
              <Carousel
                autoPlay={this.state.autoPlay}
                timer={this.state.timer}
                animation={this.state.animation}
                indicators={this.state.indicators}
                timeout={this.state.timeout}
                navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                
              >
                {listCards.map((cards, index) => {
                  return (
                    <Grid container spacing={1} key={index} style={{marginTop:8}}>
                      {cards.map((data, indexCard) =>
                        this.Card(classes, data, indexCard)
                      )}
                    </Grid>
                  );
                })}
              </Carousel>
            )    
            }
          </Fragment>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(useStyles)(BasicCarrousel);
