import React, {  } from 'react';
import PropTypes from 'prop-types'
import { Grid, IconButton } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY:'initial',
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    paper: {
        // height: 'auto',
        maxHeight: 700,
        backgroundColor: '#393939',
        border: 'none',
        borderRadius: '5px',
        overflowY: 'auto',
        overflowX: 'hidden',
        color: 'rgba(216,216,216,0.5)',
        outline:0,
    },
    iconClose: {
        color: theme.palette.thirdColor,
        padding: 0,
    },
    buttonClose: {
        textAlign: 'end',
        padding: 10
    },
    title: {
        textAlign: 'start',
        color: '#A2E3A4',
        padding: 10,
        fontSize: "16px",
        fontWeight: "600"
    }
}));

const SimpleModal = ({ component: Component, ...props }) => {
    const classes = useStyles();
    const { open, onClose, title, width, showCloseButton, disabled, noPadding } = props

    return (
        <Modal className={classes.modal} open={open}   onClose={onClose}>
            <div 
            className={classes.paper} 
            style={{
                 width:!!width? width:  600 ,
                 padding: noPadding === true ? 0:'5px 20px 10px 20px'
                 }} >
                <Grid container>
                    <Grid item lg={10} xs={10} className={classes.title}>
                        {title}
                    </Grid>
                    <Grid item lg={2} xs={2} className={classes.buttonClose}>
                        {showCloseButton === false ?
                            '' :
                            <IconButton className={classes.iconClose} onClick={onClose}  disabled={disabled} >
                                <CloseIcon style={{fontSize:18}} />
                            </IconButton>
                        }
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container>
                            <Component />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Modal >
    );
}

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    component: PropTypes.object.isRequired,
}


export default SimpleModal;