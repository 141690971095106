import {
    UPDATE_BRANCH_OFFICE,
    SAVE_BRANCH_OFFICE,
    LOAD_BRANCH_OFFICE,
    UPDATE_STATE_BRANCH_OFFICE,
    GET_BRANCH_OFFICES,
    GET_FILTER_BRANCH_OFFICES,
    UPDATE_PRINCIPAL_BRANCH,
    LOADING_SAVE_BRANCH,
    LOADING_GET_BRANCH_OFFICES
} from '../actions/types';
import isEmpty from '../utils/isEmpty';

const initialState = {
    responseSaveBranchOffice: {},
    responseUpdateBranchOffice: {},
    responseUpdateStateBranchOffice: {},
    editBranchStatus: false,
    stepBranchOfficeComplete: false,
    loadingSaveBranch: false,
    loadingGetBranchOffices: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BRANCH_OFFICES:
            return {
                ...state,
                filteredBranchOfficesList: action.payload,
                branchOfficesList: action.payload1
            }
        case SAVE_BRANCH_OFFICE:
            return {
                ...state,
                responseSaveBranchOffice: action.payload
            }
        case UPDATE_BRANCH_OFFICE:
            return {
                ...state,
                responseUpdateBranchOffice: action.payload,
            }
        case UPDATE_STATE_BRANCH_OFFICE:
            return {
                ...state,
                responseUpdateStateBranchOffice: action.payload,
            }
        case LOAD_BRANCH_OFFICE:
            return {
                ...state,
                loadBranchOffice: action.payload,
                editBranchStatus: !isEmpty(action.payload)
            }

        case GET_FILTER_BRANCH_OFFICES:
            return {
                ...state,
                getFilterBranchOffices: action.payload,
            }

        case UPDATE_PRINCIPAL_BRANCH:
            return {
                ...state,
                updatePrincipalBranchResponse: action.payload,
            }
        case LOADING_SAVE_BRANCH:
            return {
                ...state,
                loadingSaveBranch: action.payload
            }
        case LOADING_GET_BRANCH_OFFICES:
            return{
                ...state,
                loadingGetBranchOffices: action.payload
            }
        default:
            return state;
    }
}