import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";

import calculateDV from '../../utils/calculateDV'
import { SuccessAlert } from '../../helpers/alert.helpers'
import { regexEmail, regexAlphaNumeric, regexPhone, regexOnlyNumbers } from '../../helpers/customRegex.hepers'
import InputForm from "../../components/input/input.component";
import SwitchForm from '../../components/switch/switch.component.js';
import SelectForm from '../../components/select/select.component';
import CustomAutoComplete from '../../components/autocomplete/autocomplete.component';
import SecondCollapse from '../../components/collapse/secondCollapse.component';
import ToggleButtons from '../../components/toggle/toggle.component';
import ButtonPrimary from '../../components/button/buttonPrimary.component';

import { getStatesAction, getCitiesAction, getDocumentTypesAction, getRegimeTypesAction, getAllCitiesAction, getMenuComponentAction } from '../../actions/configAction'
import { saveClientAction, updateClientAction, loadClientAction, clearLoadClientAction } from '../../actions/clientAction'
import { getAndressListAction, clearAndressListAction, loadAndressListAction } from '../../actions/addressAction'
import { getContactsAction, clearContactListAction, loadContactListAction } from '../../actions/contactAction'

import Address from '../address/address.component'
import Contact from '../contact/contact.component'
import TooltipMessage from "../../components/tooltip/tootltip-message.component";


class NewClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                id: 0,
                ddlTypeDocument: '',
                txtDocument: '',
                toogleTypePerson: '1',
                txtDV: '',
                txtName: '',
                ddlTypeRegime: '',
                txtEmail: '',
                txtPhone: '',
                txtDirection: '',
                ddlCity: null,
                ddlState: null,
                chkStandOut: false

            },
            cities: [],
            hideTypePerson: true,
            getStatesResponse: [],
            allCitiesCountry: [],
            getDocumentTypesResponse: [],
            getRegimeTypesResponse: [],
            getAddressListResponse: [],
            getContactResponse: [],
            saveClientResponse: {},
            getClientListResponse: [],
            loadClientObject: null,
            editClientStatus: false,
            updateClientResponse: null,
            openPanelInfo: '',
            loadingSave: false,
            stateIdSeleted: 0,
            menuComponent: [],
            configurableMenu: {}
        }

        this.validator = new SimpleReactValidator(reactValidatorOptions);

    }

    componentDidMount() {
        this.props.getStatesAction();
        this.props.getAllCitiesAction();
        this.props.getDocumentTypesAction();
        this.props.getRegimeTypesAction();
        this.props.getMenuComponentAction(2);
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.configReducer.getStatesResponse && nextProps.configReducer.getStatesResponse !== state.getStatesResponse)
            update.getStatesResponse = nextProps.configReducer.getStatesResponse;

        if (!!nextProps.configReducer.allCitiesCountry && nextProps.configReducer.allCitiesCountry !== state.allCitiesCountry)
            update.allCitiesCountry = nextProps.configReducer.allCitiesCountry;

        if (!!nextProps.configReducer.getDocumentTypesResponse && nextProps.configReducer.getDocumentTypesResponse !== state.getDocumentTypesResponse)
            update.getDocumentTypesResponse = nextProps.configReducer.getDocumentTypesResponse;

        if (!!nextProps.configReducer.getRegimeTypesResponse && nextProps.configReducer.getRegimeTypesResponse !== state.getRegimeTypesResponse)
            update.getRegimeTypesResponse = nextProps.configReducer.getRegimeTypesResponse;

        if (!!nextProps.addressReducer.getAddressListResponse && nextProps.addressReducer.getAddressListResponse !== state.getAddressListResponse)
            update.getAddressListResponse = nextProps.addressReducer.getAddressListResponse;

        if (!!nextProps.contactReducer.getContactResponse && nextProps.contactReducer.getContactResponse !== state.getContactResponse)
            update.getContactResponse = nextProps.contactReducer.getContactResponse;

        if (!!nextProps.clientReducer.saveClientResponse && nextProps.clientReducer.saveClientResponse !== state.saveClientResponse)
            update.saveClientResponse = nextProps.clientReducer.saveClientResponse;

        if (!!nextProps.clientReducer.getClientListResponse && nextProps.clientReducer.getClientListResponse !== state.getClientListResponse)
            update.getClientListResponse = nextProps.clientReducer.getClientListResponse;

        if (nextProps.clientReducer.loadClientObject !== undefined && nextProps.clientReducer.loadClientObject !== state.loadClientObject) {
            update.loadClientObject = nextProps.clientReducer.loadClientObject;
            update.editClientStatus = nextProps.clientReducer.editClientStatus;
        }

        if (!!nextProps.clientReducer.updateClientResponse && nextProps.clientReducer.updateClientResponse !== state.updateClientResponse)
            update.updateClientResponse = nextProps.clientReducer.updateClientResponse;

        if (nextProps.clientReducer.loadingSave !== state.loadingSave)
            update.loadingSave = nextProps.clientReducer.loadingSave;

        if (!!nextProps.configReducer.menuComponent && nextProps.configReducer.menuComponent !== state.menuComponent) {

            update.menuComponent = nextProps.configReducer.menuComponent;
            let menu = nextProps.configReducer.menuComponent;
            let personTypeComponent = menu.find(e => e.id === 12)?.adcomponenteinformacion;
            update.configurableMenu = {
                ...update.configurableMenu,
                personTypeTitle: !!personTypeComponent?.find(e => e.id === 16)?.text ? personTypeComponent.find(e => e.id === 16)?.text : '',
                personTypeMessage: !!personTypeComponent?.find(e => e.id === 17)?.text ? personTypeComponent.find(e => e.id === 17)?.text : '',
                personTypeButton: !!personTypeComponent?.find(e => e.id === 18)?.text ? personTypeComponent.find(e => e.id === 18)?.text : '',
                personTypeHref: !!personTypeComponent?.find(e => e.id === 18)?.url ? personTypeComponent.find(e => e.id === 18)?.url : '',
            }
        }

        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.saveClientResponse !== this.state.saveClientResponse) {
            if (this.state.saveClientResponse.statusCode === '201' || this.state.saveClientResponse.statusCode === '200') {
                SuccessAlert("Genial...!", "Se ha registrado un nuevo cliente");
                this.cleanForm();
            }
        }

        if (prevState.loadClientObject !== this.state.loadClientObject) {
            if (!!this.state.loadClientObject)
                this.loadInfo(this.state.loadClientObject);
            else
                this.cleanForm();
        }


        if (prevState.updateClientResponse !== this.state.updateClientResponse) {
            if (this.state.updateClientResponse.statusCode === '201' || this.state.updateClientResponse.statusCode === '200') {
                SuccessAlert("Genial...!", "Se ha actualizado exitosamente el cliente");
                this.cleanForm();
            }
        }
    }

    componentWillUnmount() {
        this.props.clearLoadClientAction();
    }

    syncChanges(e) {
        e.preventDefault();
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    syncCheckChange(e) {
        e.preventDefault();
        const name = e.target.name;
        const checked = e.target.checked;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: checked
            }
        }))
    };

    syncAutoCompleteChanges(event, values, id) {
        event.preventDefault();
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: values
            }
        }))
    }


    validateEmail(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        value = value.replace(regexEmail, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    validatePhone(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexPhone;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    validateAlphaNumeric(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexAlphaNumeric;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    validateOnlyNumbers(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexOnlyNumbers;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    changeTypeDocument(e) {
        e.preventDefault();
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtDocument: '',
                txtDV: '',
                toogleTypePerson: this.state.form.ddlTypeDocument !== 6 ? '1' : this.state.form.toogleTypePerson,
            }
        }))

    }

    //Evento cambio departamento seleccionado
    async changeClientState(event, values) {
        event.preventDefault();
        let newCities = [];
        if (!!values)
            newCities = this.state.allCitiesCountry.filter(c => c.departamentoid === parseInt(values.value));
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                ddlCity: null,
            },
            cities: newCities,
            stateIdSeleted: !!values?.value ? parseInt(values.value) : 0,
        }))

    }

    /**
     * Calcula digito de verificación de documento.
     * actualiza formulario
     * @param {*} e
     */
    onBlurDocument(e) {
        e.preventDefault();
        const document = e.target.value;
        let dv = calculateDV(document);

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtDV: dv
            }
        }))

        //Cargar cliente existente
        let clients = this.state.getClientListResponse;
        if (clients.length) {
            if (this.state.form.ddlTypeDocument !== '' && this.state.form.txtDocument !== '') {
                const typeDocument = parseInt(this.state.form.ddlTypeDocument);
                const document = this.state.form.txtDocument;
                let client = clients.find(c => c.diantipodocumentoidentidadid === typeDocument && c.documento === document);
                if (!!client) {
                    this.props.loadClientAction(client);
                } else {
                    this.cleanInfo();
                }

            }
        }
    }

    //Evento Submit Form
    onSubmitClient = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.saveClient();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    //Guarda o actualiza un cliente
    saveClient = (e) => {
        let addressList = this.state.getAddressListResponse.map(item => {
            return {
                Id: Number.isInteger(item.id) ? item.id : 0,
                Descripcion: item.descripcion,
                Direccion: item.direccion,
                CiudadId: item.ciudadid,
            }
        })

        let contactList = this.state.getContactResponse.map(item => {
            return {
                Id: Number.isInteger(item.id) ? item.id : 0,
                Nombre: item.nombre,
                Telefono: item.telefono,
                Celular: item.telefono,
                Email: item.email,
                Activo: true
            }
        })

        let form = this.state.form;
        let edit = this.state.editClientStatus;
        let client = {
            Id: edit ? this.state.loadClientObject.id : 0,
            Documento: edit ? this.state.loadClientObject.documento : form.txtDocument,
            Documentodv: edit ? this.state.loadClientObject.documentodv : form.txtDV,
            RazonSocial: form.txtName,
            Telefono: form.txtPhone,
            CorreoElectronico: form.txtEmail,
            DianTipoDocumentoIdentidadId: edit ? this.state.loadClientObject.diantipodocumentoidentidadid : parseInt(form.ddlTypeDocument),
            DianTipoPersonaId: form.toogleTypePerson === '1' ? 1 : 2,
            Diantiporegimenid: form.ddlTypeRegime,
            Favorito: form.chkStandOut,
            Direccionid: edit ? this.state.loadClientObject.direccionid : 0,
            Direccion: form.txtDirection,
            CiudadId: parseInt(form.ddlCity.value),
            Adclientedireccion: addressList,
            Adcontactos: contactList
        }

        if (edit)
            this.props.updateClientAction(client);
        else
            this.props.saveClientAction(client);
    }

    //Limpia formulario
    cleanForm = () => {
        this.setState({
            form: {
                ddlTypeDocument: '',
                txtDocument: '',
                toogleTypePerson: '1',
                txtDV: '',
                txtName: '',
                ddlTypeRegime: '',
                txtEmail: '',
                txtPhone: '',
                txtDirection: '',
                ddlCity: null,
                ddlState: null,
                chkStandOut: false
            },
            saveClientResponse: {},
        });

        this.props.clearAndressListAction();
        this.props.clearContactListAction();
        this.props.clearLoadClientAction();

        this.validator.hideMessages();
    }

    //Carga información en formulario para edición
    loadInfo(client) {

        let city = this.state.allCitiesCountry.filter(c => c.value === client.ciudadid.toString());
        let stateid = !!city && city.length ? city[0].departamentoid : 0;
        let state = this.state.getStatesResponse.filter(s => s.value === stateid.toString());
        let cities = this.state.allCitiesCountry.filter(c => c.departamentoid === stateid);

        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                ddlTypeDocument: client.diantipodocumentoidentidadid.toString(),
                txtDocument: client.documento,
                txtDV: client.documentodv,
                toogleTypePerson: client.diantipopersonaid === 1 ? '1' : '2',
                txtName: client.razonsocial,
                ddlTypeRegime: client.diantiporegimenid,
                txtEmail: client.correoelectronico,
                txtPhone: client.telefono,
                txtDirection: client.direccion,
                ddlCity: city.length ? city[0] : null,
                ddlState: state.length ? state[0] : null,
                chkStandOut: client.favorito
            },
            cities: cities,
            stateIdSeleted: stateid,
        }));

        let addressList = client.adclientedireccion.length ? client.adclientedireccion : [];
        let contactList = client.adcontactos.length ? client.adcontactos : [];
        this.props.loadAndressListAction(addressList);
        this.props.loadContactListAction(contactList);

        //expanade panel Iiformación adicional
        this.setState({
            openPanelInfo: addressList.length || contactList.length ? 'panel' : '',
        });
    }

    //Limpia información de formulario cuando no encunentra coincidencias
    // del documento de identificación
    cleanInfo() {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                txtName: '',
                ddlTypeRegime: '',
                txtEmail: '',
                txtPhone: '',
                txtDirection: '',
                ddlCity: null,
                ddlState: null,
                chkStandOut: false
            },
            loadClientObject: null
        }))
        this.validator.hideMessages();
        this.props.clearLoadClientAction();
    }



    handleAlignment = (event, newAlignment) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                toogleTypePerson: !!newAlignment ? newAlignment : '1'
            }
        }))

    };

    /**
     * Validadores depues de OnBlur en caja de texto
     * @param {object} e Evento
     * @param {string} name Nombre validador
     */
    validateFieldsOnBlur(e, name) {
        if (!this.validator.fieldValid(name)) {
            this.validator.showMessageFor(name)
        }
    }

    //Panel Información adicional
    panelAdditionalInfo = (e) => {
        return (
            <Fragment>
                <Address />
                <Contact />
            </Fragment>

        );
    }

    render() {
        const { configurableMenu } = this.state;
        return (
            <Fragment>
                <form onSubmit={(e) => this.onSubmitClient(e)} noValidate autoComplete="off" >
                    <Grid container spacing={3}>
                        <Grid item lg={4} xs={12} >
                            <SelectForm
                                id="ddlTypeDocument"
                                label={"Tipo de documento *"}
                                name="ddlTypeDocument"
                                value={this.state.form.ddlTypeDocument}
                                options={this.state.getDocumentTypesResponse}
                                disabled={this.state.editClientStatus ? true : false}
                                validator={this.validator}
                                validateOptions={'required'}
                                onChange={(e) => {
                                    this.syncChanges(e);
                                    this.changeTypeDocument(e);
                                }}
                                placeholder={"Seleccione..."}
                            />
                        </Grid>

                        <Grid item lg={this.state.form.ddlTypeDocument === '6' ? 4 : 8} xs={12} >
                            <InputForm
                                name={"txtDocument"}
                                label={"Numero de documento *"}
                                maxLength={15}
                                value={this.state.form.txtDocument}
                                disabled={this.state.editClientStatus ? true : false}
                                onChange={(e) => {
                                    this.syncChanges(e);
                                    this.state.form.ddlTypeDocument === '7' ? this.validateAlphaNumeric(e) : this.validateOnlyNumbers(e);
                                }}
                                nameValidator={'numeroDocumento'}
                                onBlur={(e) => {
                                    this.validateFieldsOnBlur(e, 'numeroDocumento');
                                    this.onBlurDocument(e);
                                }
                                }
                                validator={this.validator}
                                validateOptions={"required|min:3|max:15"}
                            />
                        </Grid>
                        {this.state.form.ddlTypeDocument === '6' ?
                            <Grid item lg={4} xs={12} >
                                <InputForm
                                    id={"txtDV"}
                                    label={"Dígito de verificación"}
                                    maxLength={1}
                                    value={this.state.form.txtDV}
                                    disabled={true}
                                    onChange={(e) => {
                                        this.syncChanges(e);
                                        this.validateOnlyNumbers(e);
                                    }}
                                    validator={this.validator}
                                    validateOptions={this.state.form.ddlTypeDocument === '6' ? "required" : "void"}
                                />
                            </Grid> : ''
                        }
                    </Grid>

                    <Grid container spacing={3}>
                        {this.state.form.ddlTypeDocument === '6' ?
                            <Grid item lg={4} xs={12}>
                                <ToggleButtons
                                    label={"Tipo de persona"}
                                    value={this.state.form.toogleTypePerson}
                                    onChange={this.handleAlignment}
                                    titleOne={'Natural'}
                                    titleTwo={'Juridica'}
                                    txtTooltip={
                                    <TooltipMessage
                                        title={configurableMenu.personTypeTitle}
                                        message={configurableMenu.personTypeMessage}
                                        botton={configurableMenu.personTypeButton}
                                        href={configurableMenu.personTypeHref}
                                    />}
                                />
                            </Grid> : ''
                        }

                        <Grid item lg={this.state.form.ddlTypeDocument === '6' ? 8 : 12} xs={12} >
                            {/* agregar tipo de documento en validacion */}
                            <InputForm
                                name={"txtName"}
                                label={this.state.form.toogleTypePerson === '2' ? "Razón Social *" : "Nombre(s) y Apellido(s) *"}
                                maxLength={100}
                                value={this.state.form.txtName}
                                onChange={(e) => {
                                    this.syncChanges(e);
                                }}
                                nameValidator={'nombreCliente'}
                                onBlur={(e) => {
                                    this.validateFieldsOnBlur(e, 'nombreCliente');
                                }
                                }
                                validator={this.validator}
                                validateOptions={"required|min:3|max:100"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={4} xs={12} >
                            <SelectForm
                                id="ddlTypeRegime"
                                label={"Régimen *"}
                                name="ddlTypeRegime"
                                value={this.state.form.ddlTypeRegime}
                                options={this.state.getRegimeTypesResponse}
                                validator={this.validator}
                                validateOptions={'required'}
                                onChange={(e) => {
                                    this.syncChanges(e);
                                }}
                                placeholder={"Seleccione..."}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12} >
                            <InputForm
                                name={"txtEmail"}
                                label={"Correo eletrónico *"}
                                maxLength={50}
                                value={this.state.form.txtEmail}
                                onChange={(e) => {
                                    this.syncChanges(e);
                                    this.validateEmail(e);
                                }}
                                nameValidator={'correoEletronico'}
                                onBlur={(e) => {
                                    this.validateFieldsOnBlur(e, 'correoEletronico');
                                }
                                }
                                validator={this.validator}
                                validateOptions={"required|email|min:10|max:50"}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12} >
                            <InputForm
                                name={"txtPhone"}
                                label={"Teléfono *"}
                                maxLength={15}
                                value={this.state.form.txtPhone}
                                onChange={(e) => {
                                    this.syncChanges(e);
                                    this.validatePhone(e);
                                }}
                                nameValidator={'telefono'}
                                onBlur={(e) => {
                                    this.validateFieldsOnBlur(e, 'telefono');
                                }
                                }
                                validator={this.validator}
                                validateOptions={"required|min:7|max:15"}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12} >
                            <InputForm
                                name={"txtDirection"}
                                label={"Dirección *"}
                                maxLength={200}
                                value={this.state.form.txtDirection}
                                onChange={(e) => {
                                    this.syncChanges(e);
                                }}
                                nameValidator={'direccion'}
                                onBlur={(e) => {
                                    this.validateFieldsOnBlur(e, 'direccion');
                                }
                                }
                                validator={this.validator}
                                validateOptions={"required"}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12} >
                            <CustomAutoComplete
                                name="ddlState"
                                label="Departamento"
                                value={this.state.form.ddlState}
                                options={this.state.getStatesResponse}
                                textNoOption="No se encontraron resultados."
                                onChange={(event, values) => {
                                    this.syncAutoCompleteChanges(event, values, 'ddlState');
                                    this.changeClientState(event, values);
                                }}
                                validator={this.validator}
                                validateOptions={'required'}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12} >
                            <CustomAutoComplete
                                name="ddlCity"
                                label="Municipio"
                                value={this.state.form.ddlCity}
                                options={this.state.cities}
                                textNoOption="No se encontraron resultados."
                                onChange={(event, values) => {
                                    this.syncAutoCompleteChanges(event, values, 'ddlCity');
                                }}
                                validator={this.validator}
                                validateOptions={'required'}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12} >
                            <SwitchForm
                                id="chkStandOut"
                                name="chkStandOut"
                                titleOn="Marcar como destacado"
                                checked={this.state.form.chkStandOut}
                                onChange={(e) => {
                                    this.syncCheckChange(e);
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div className="comunContainer">
                        <SecondCollapse
                            component={this.panelAdditionalInfo}
                            title={"Información Adicional"}
                            open={this.state.openPanelInfo} />
                    </div>

                    <Grid container spacing={3} >
                        <Grid item lg={8} md={8} ></Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <ButtonPrimary
                                text={'Listo'}
                                loading={this.state.loadingSave}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Fragment>

        )
    }

}


NewClient.propTypes = {
    getStatesAction: PropTypes.func.isRequired,
    getCitiesAction: PropTypes.func.isRequired,
    getDocumentTypesAction: PropTypes.func.isRequired,
    getRegimeTypesAction: PropTypes.func.isRequired,
    getAllCitiesAction: PropTypes.func.isRequired,
    saveClientAction: PropTypes.func.isRequired,
    loadClientAction: PropTypes.func.isRequired,
    getAndressListAction: PropTypes.func.isRequired,
    getContactsAction: PropTypes.func.isRequired,
    clearAndressListAction: PropTypes.func.isRequired,
    clearContactListAction: PropTypes.func.isRequired,
    updateClientAction: PropTypes.func.isRequired,
    clearLoadClientAction: PropTypes.func.isRequired,
    loadAndressListAction: PropTypes.func.isRequired,
    loadContactListAction: PropTypes.func.isRequired,


    configReducer: PropTypes.object.isRequired,
    clientReducer: PropTypes.object.isRequired,
    addressReducer: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    configReducer: state.configReducer,
    clientReducer: state.clientReducer,
    addressReducer: state.addressReducer,
    contactReducer: state.contactReducer,
})

export default connect(mapStateToProps, {
    getStatesAction,
    getCitiesAction,
    getDocumentTypesAction,
    getRegimeTypesAction,
    getAllCitiesAction,
    saveClientAction,
    loadClientAction,
    getAndressListAction,
    getContactsAction,
    clearAndressListAction,
    clearContactListAction,
    updateClientAction,
    clearLoadClientAction,
    loadAndressListAction,
    loadContactListAction,
    getMenuComponentAction,
})(NewClient);
