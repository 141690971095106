import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import {getBillClient,getrakingCollaborators, getBilling, getIncome, getCustomer, getrakingBranchOffice,getrakingArticle,issuedInvoices}   from '../../actions/dashboardAction'

import InfoCard from '../../components/card/infoCard.component';
import RakingCollaborators from '../../components/carrousel/basicCarrousel'
import Billing from '../dashboard/Components/billingChart.component'
import IncomeChart from '../dashboard/Components/incomeChart.component'
import CustomerChart from '../dashboard/Components/bestCustomerChart.component'
import RakingItem from '../dashboard/Components/rakingArticle.component'
import Informe from '../../components/informe/informe.component';



  class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getBillClient: [],
            getRakingColaborator: [],
            getBilling: [],
            getIncome: [],
            getCustomer: [],
            getRakingBranch: [],
            getRakingItem: [],
            issuedInvoices:[],
            totalSold:0,
            statusCodeColaborator:0,
            statusCodeBilling:0,
            statusCodeIncome:0,
            statusCodeCustomer:0,
            statusCodeBranch:0,
            statusCodeItem:0,
            quantityInvoice:0,
            getInforme:[],
            
        }
    }

       
    componentDidMount() {
        this.props.getBillClient();
        this.props.getrakingCollaborators()
        this.props.getBilling()
        this.props.getIncome()
        this.props.getCustomer()
        this.props.getrakingBranchOffice()
        this.props.getrakingArticle()
        this.props.issuedInvoices()
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.dashboardReducer.getBillClient && nextProps.dashboardReducer.getBillClient !== state.getBillClient)
            update.getBillClient = nextProps.dashboardReducer.getBillClient;

        if (!!nextProps.dashboardReducer.getRakingColaborator && nextProps.dashboardReducer.getRakingColaborator !== state.getRakingColaborator)
            update.getRakingColaborator = nextProps.dashboardReducer.getRakingColaborator;
            update.statusCodeColaborator = nextProps.dashboardReducer.statusCodeColaborator;

        if (!!nextProps.dashboardReducer.getBilling && nextProps.dashboardReducer.getBilling !== state.getBilling)
            update.getBilling = nextProps.dashboardReducer.getBilling;
            update.totalSold = nextProps.dashboardReducer.totalSold;
            update.statusCodeBilling = nextProps.dashboardReducer.statusCodeBilling;

        if (!!nextProps.dashboardReducer.getIncome && nextProps.dashboardReducer.getIncome !== state.getIncome)
            update.getIncome = nextProps.dashboardReducer.getIncome;
            update.statusCodeIncome = nextProps.dashboardReducer.statusCodeIncome;

        if (!!nextProps.dashboardReducer.getCustomer && nextProps.dashboardReducer.getCustomer !== state.getCustomer)
            update.getCustomer = nextProps.dashboardReducer.getCustomer;
            update.statusCodeCustomer = nextProps.dashboardReducer.statusCodeCustomer;

        if (!!nextProps.dashboardReducer.getRakingBranch && nextProps.dashboardReducer.getRakingBranch !== state.getRakingBranch)
            update.getRakingBranch = nextProps.dashboardReducer.getRakingBranch;
            update.statusCodeBranch = nextProps.dashboardReducer.statusCodeBranch;

        if (!!nextProps.dashboardReducer.getRakingItem && nextProps.dashboardReducer.getRakingItem !== state.getRakingItem)
            update.getRakingItem = nextProps.dashboardReducer.getRakingItem;
            update.statusCodeItem= nextProps.dashboardReducer.statusCodeItem;
        
        if (!!nextProps.dashboardReducer.quantityInvoice && nextProps.dashboardReducer.quantityInvoice !== state.quantityInvoice)
            update.quantityInvoice = nextProps.dashboardReducer.quantityInvoice;

        if (!!nextProps.dashboardReducer.getInforme && nextProps.dashboardReducer.getInforme !== state.getInforme)
            update.getInforme = nextProps.dashboardReducer.getInforme;
            update.statusCodeItem= nextProps.dashboardReducer.statusCodeItem;

        return Object.keys(update).length ? update : null;
    }
    render() {
        return (
            <div className="container-home">
                <Grid container spacing={2} >
                    <Grid item lg={5} md={12} xs={12} direction="column" >
                        <Grid container spacing={1}>
                            <Grid item lg={6} xs={6} >
                                <InfoCard title={this.state.getBillClient.map(billigs =>{
                                    return billigs.billings
                                })} subtitle={'Facturas Diponibles'} />
                            </Grid>
                            <Grid item lg={6} xs={6} >
                            <InfoCard title={this.state.quantityInvoice} subtitle={'Facturas'} />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item lg={12} md={12} xs={12} direction="column">
                            <Billing
                                data={this.state.getBilling}
                                totalSold={this.state.totalSold}
                                statusCode = {this.state.statusCodeBilling}
                            />
                        </Grid>
                    </Grid>
                    <Grid item lg={7} md={12} xs={12} direction="column"  >

                        <RakingCollaborators
                            name={"Ranking Colaboradores"}
                            items={ this.state.getRakingColaborator}
                            statusCode = {this.state.statusCodeColaborator}
                        />

                        <br />
                        <RakingCollaborators
                            name={"Ranking Sucursales"}
                            items={this.state.getRakingBranch}
                            statusCode = {this.state.statusCodeBranch}
                        />

                    </Grid>


                    <Grid item lg={12} md={12} xs={12} >
                        <IncomeChart
                            dataIncome={this.state.getIncome}
                            statusCode = {this.state.statusCodeIncome}
                        />
                    </Grid>
                    <Grid item lg={5} md={12} xs={12} >
                        <CustomerChart
                            dataClient={this.state.getCustomer}
                            statusCode = {this.state.statusCodeCustomer}
                        />
                    </Grid>
                    <Grid item lg={7} md={12} xs={12} >
                        <RakingItem
                            items={this.state.getRakingItem}
                           // statusCode = {this.state.statusCodeItem}
                            name={'Raking de Productos'}
                        />
                    </Grid>
                    <Grid item lg={5} md={12} xs={12} >
                    <Informe
                        name={'Generar Informe contador'}
                    />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Home.propTypes = {
    prop: PropTypes
}

const mapStateToProps = (state) => ({
    dashboardReducer: state.dashboardReducer,
});

export default connect(mapStateToProps, {
    getBillClient,
    getrakingCollaborators,
    getBilling,
    getIncome,
    getCustomer,
    getrakingBranchOffice,
    getrakingArticle,
    issuedInvoices
})(Home);
