import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { AddCircle } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.thirdBackgroundColor,
        borderRadius: '5px',
        color: theme.palette.primaryColor,
        border: 'none'
    },
    rootSecundary: {
        backgroundColor: theme.palette.fourthBackgroundColor,
        borderRadius: '5px',
        color: theme.palette.primaryColor,
        border: 'none'
    },
    title: {
        display: "inline-flex",
        verticalAlign: "top",
        fontSize: 16,
        fontWeight: 600
    },
    titleOpen: {
        color: '#16B1F3',
        fontSize: 16,
        fontWeight: 600
    },
    body: {
        width: '100%'
    },
    margin: {
        flexDirection: "column",
        justifyContent: "center"
    },
    icon: {
        marginLeft: 10
    }
}));
const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },

    },
    expanded: {

    }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },

    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);



const CollapsePanel = ({ component: Component, ...props }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('');
    const { open, edit, cleanData, idItemToEdit, type, onClick } = props;

    useEffect(() => {
        if (open !== '') {
            setExpanded('panel')
        }

        if (open !== 'panel') {
            setExpanded('')
        }

    }, [open, props.open]);


 
    return (
        <ExpansionPanel className={type === "secundary" ? classes.rootSecundary : classes.root} square expanded={expanded === 'panel'} onChange={onClick}>
            <ExpansionPanelSummary aria-controls="panel1bh-content" id="panel1bh-header" className={classes.margin}>
                <Typography
                    component={'span'}
                    className={expanded === 'panel' ? classes.titleOpen : classes.title}>
                    {!!edit ? props.titleEdit :
                        (expanded === 'panel' ? props.titleOpen : props.title)
                    }
                    {expanded === 'panel' ? '' : <AddCircle className={classes.icon} />}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography component={'div'} className={classes.body}>
                    <Component edit={edit} cleanData={cleanData} idItemToEdit={idItemToEdit} />
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}

CollapsePanel.propTypes = {
    component: PropTypes.object.isRequired,

};



export default CollapsePanel;
