import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import { Help } from "@material-ui/icons"




const useStyles = makeStyles((theme) => ({
    tooltip: {
        color: theme.palette.secundaryColor,
    },
}));

const CustomTooltip = ({ ...props }) => {
    const { message } = props
    const classes = useStyles();

    return (
        <Tooltip title={message} placement="bottom-end" interactive enterTouchDelay={1000}>
            <Help className={classes.tooltip} />
        </Tooltip>
    );
}

CustomTooltip.propTypes = {
    message: PropTypes.object
}

export default CustomTooltip