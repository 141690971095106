/* eslint-disable jsx-a11y/alt-text */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {ChromePicker} from "react-color";
import InputForm from "../../../components/input/input.component";
import SimpleReactValidator from "simple-react-validator";
import {
  cleanStatusEdit,
  dataCleanCloseForm,
  getCategoryPagination,
  postCategory,
  putCategory
} from "../../../actions/categoryActions";
import {SuccessAlert} from "../../../helpers/alert.helpers";
import {QUANTITY_DATA} from "../../../config/config.js";
import {reactValidatorOptions} from "../../../helpers/simpleReactValidator";

import '../article/article.css'
import SimpleModal from "../../../components/modal/simpleModal.component";

const useStyles = (theme) => ({
  cardCreate: {
    background: theme.palette.thirdBackgroundColor,
    with: "100%",
  },

  cardList: {
    background: theme.palette.thirdBackgroundColor,
    with: "100%",
    height: 45,
  },

  typography: {
    color: "#16B1F3",
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    borderRadius: 10,
    width: 405,
    height: 360,
  },
  typographyCategory: {
    color: "white",
    textAlign: "center",
  },
  buttonModal: {
    width: "80%",
    height: "100%",
    background: "#16B1F3",
    color: "white",
    borderRadius: "5px",
    fontFamily: "Muli",
  },
  divButtonModal: {
    textAlign: "center",
  },
  buttonCloseModal: {
    left: "80%",
    color: "#00BFFF",
  },
  img: {
    borderRadius: "5px",
    border: "solid 2px white",
  },

  styleGridButton: {
    paddingTop: -50,
  },
  centerImage: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  gridButton: {
    padding: '20px 40px'
  },
  marginButtons: {
    padding: '0 15px'
  },
  btnModalImage: {
    borderRadius: 5,
    backgroundColor: theme.palette.thirdColor,
    width: '100%',
    height: 39,
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 300,
    marginTop: 5,
    marginBottom: 15,
    marginLeft: 15
  },
});

const ColorCategoryModal = props => {
  const {classes, handleClose, handleChangeComplete, form} = props
  return (
    <Grid container>
      <Grid className={classes.centerImage}>
        <ChromePicker
            color={form.txCategoryColor}
            onChangeComplete={handleChangeComplete}
        />
      </Grid>
      <Grid container className={classes.gridButton}>
        <Grid item md={6} xs={12} className={classes.marginButtons}>
          <button
              type="button"
              className={classes.btnModalImage}
              onClick={handleClose}>
            Cerrar
          </button>
        </Grid>
        <Grid item md={6} xs={12}>
          <button
              type="button"
              className={classes.btnModalImage}
              onClick={handleClose}>
            Terminar
          </button>
        </Grid>
      </Grid>
    </Grid>
  )
}


export class NewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txCategoryName: "",
        txCategoryColor: "",
        txCategoryImage: null,
      },

      idCategorySelect: 0,
      stateModal: false,

      requestSuccesCategory: {},
      requestLoadCategory: {},

      editStatusCategory: false,
      page: 0,

      components: [],
      componentRequest: []
    };

    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};


    if (!!nextProps.categoryReducer.requestSuccessCategory && nextProps.categoryReducer.requestSuccessCategory !== state.requestSuccessCategory)
        update.requestSuccesCategory = nextProps.categoryReducer.requestSuccessCategory;


      if (!!nextProps.categoryReducer.requestLoadCategory && nextProps.categoryReducer.requestLoadCategory  !== state.requestLoadCategory) {
        update.form = nextProps.categoryReducer.requestLoadCategory;
        update.requestLoadCategory = nextProps.categoryReducer.requestLoadCategory;
        update.editStatusCategory = nextProps.categoryReducer.editCategoryStatus;
        update.idCategorySelect = nextProps.categoryReducer.requestLoadCategory.id;
      }

      if(!!nextProps.categoryReducer.requestSuccessUpdateCategory && nextProps.categoryReducer.requestSuccessUpdateCategory !== state.requestSuccessUpdateCategory){
        update.requestSuccessUpdateCategory = nextProps.categoryReducer.requestSuccessUpdateCategory;
        update.editStatusCategory = nextProps.categoryReducer.cleanStatus
      }



    if (!!nextProps.configReducer.components !== state.components) {
      update.componentRequest = nextProps.configReducer.components;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) {
    let data = {
      page: this.state.page,
      quantity: QUANTITY_DATA,
      name: null
    };
    if (prevState.requestSuccesCategory !== this.state.requestSuccesCategory) {
      let dataClean = {
        data: false,
        close: []
      }

      if (prevState.requestSuccesCategory !== this.state.requestSuccesCategory) {
        SuccessAlert(this.state.components.length > 0 ?
          this.state.components.find(element => element.id === 72).adcomponenteinformacion[0].text
          : 'Genial configuraste categoria correctamente');
        this.cleanData();
        this.props.getCategoryPagination(data);
        this.props.dataCleanCloseForm(dataClean)
      }
    }

    if (prevState.requestSuccessUpdateCategory !== this.state.requestSuccessUpdateCategory) {
        let data = {
          page: this.state.page,
          quantity: QUANTITY_DATA,
          name: null
        };
        let cleanStatus = {
          data: true
        }
        let cleanForm = {
          data: false
        }
        SuccessAlert(this.state.components.length > 0 ?
          this.state.components.find(element => element.id === 73).adcomponenteinformacion[0].text
          : "Genial!!!", "Modificaste categoría correctamente");
        this.cleanData();
        this.props.getCategoryPagination(data);
        this.props.cleanStatusEdit(cleanStatus)
        this.props.dataCleanCloseForm(cleanForm)
      }


    if (prevState.componentRequest !== this.state.componentRequest) {
      this.setState({ components: this.state.componentRequest })
    }
  }

  updateCategory = (value) => {
    for (let i = 0; i < this.state.category.length; i++) {
      if (value === this.state.category[i].id) {
        this.setState({
          form: {
            txCategoryName: this.state.category[i].nombre,
            txCategoryColor: this.state.category[i].color,
          },
          stateUpdate: true,
          idCategorySelect: value,
        });
      }
    }
  };

  syncChanges(value, property) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [property]: value,
      },
    }));
  }

  handleChangeComplete = (color) => {
    this.setState({
      form: {
        txCategoryName: this.state.form.txCategoryName,
        txCategoryColor: color.hex,
      },
    });
  };

  saveCategory = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let cleanForm = {
        data: true
      }
      let data = {};
      let dataModified = {};

      data = {
        nombre: this.state.form.txCategoryName,
        color: this.state.form.txCategoryColor,
        imagen: null,
        favorita: false,
        idowner: 0,
        createdby: 1,
        modifiedby: 1,
        idbusinessunit: 0,
      };

      dataModified = {
        id: this.state.idCategorySelect,
        nombre: this.state.form.txCategoryName,
        color: this.state.form.txCategoryColor,
        imagen: null,
        activo: true,
        favorita: false,
        idowner: 0,
        createdby: 1,
        modifiedby: 1,
        idbusinessunit: 0,
      };
      this.state.editStatusCategory === true ? this.props.putCategory(dataModified) : this.props.postCategory(data);
      this.props.dataCleanCloseForm(cleanForm)
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  cleanData() {
    this.setState({
      form: {
        txCategoryName: "",
        txCategoryColor: "",
      },
    });
    this.validator.hideMessages();
  }

  handleOpen = () => {
    this.setState({ stateModal: true });
  };

  handleClose = () => {
    this.setState({
      stateModal: false,
    });
  };

  validatarTest(e, name) {
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name);
    }
  }
  render() {
    const { classes } = this.props;
    const { form } = this.state
    return (
      <form
        id="categoryForm"
        onSubmit={(e) => this.saveCategory(e)}
        noValidate
        autoComplete="off"
      >
        <Fragment>
          <Grid container style={{ padding: '0px 20px' }}>
            <Grid item lg={4} md={4}>
              <InputForm
                id="txCategoryName"
                name="txCategoryName"
                value={this.state.form.txCategoryName || ""}
                label={"Nombre categoría *"}
                nameValidator={"txCategoryName"}
                onBlur={(e) => this.validatarTest(e, "txCategoryName")}
                onChange={(ev) => this.syncChanges(ev.target.value, "txCategoryName")}
                validator={this.validator}
                validateOptions={"required"}
              />
            </Grid>

            <Grid item lg={2} md={2}>
              <img
                className={classes.img}
                width={120}
                height={59}
                onClick={this.handleOpen}
                style={{ background: this.state.form.txCategoryColor, cursor: 'pointer', margin: '8px' }}
              />
            </Grid>
            <SimpleModal
                onClose={this.handleClose}
                open={this.state.stateModal}
                title={''}
                // disabled={this.state.loadingUpdateArticle}
                noPadding={true}
                component={() =>
                    <ColorCategoryModal
                        classes={classes}
                        form={form}
                        handleClose={this.handleClose}
                        handleChangeComplete={this.handleChangeComplete}
                    />}
            />
          </Grid>

          <Grid container>
            {/* <Grid item lg={4} md={4}>
                      <button type="submit" className="btn-primary">
                        Cargue masivo
                      </button>
                    </Grid> */}
            <Grid item lg={4} md={4}></Grid>
            <Grid item lg={4} md={4}></Grid>
            <Grid item lg={4} md={4} className={classes.styleGridButton}>


              <button type="submit" className="btn-primary" >
                {this.state.components.length > 0 ?
                  this.state.components.find(element => element.id === 45).adcomponenteinformacion[0].text
                  : 'Listo'}
              </button>
            </Grid>
          </Grid>
        </Fragment>
      </form>

    );
  }
}
NewCategory.propTypes = {};

const mapStateToProps = (state) => ({
  categoryReducer: state.categoryReducer,
  articleReducer: state.articleReducer,
  configReducer: state.configReducer
});

export default connect(mapStateToProps, {
  postCategory,
  putCategory,
  getCategoryPagination,
  dataCleanCloseForm,
  cleanStatusEdit
})(withStyles(useStyles)(NewCategory));
