import { API_ENDPOINT_DOCUMENT, DEFAULTRESPONSE, defaultHeaders } from "../config/config"
import axios from 'axios';
import {
  GET_INVOICES,
  SUCESS_DOCUMENT_UPDATE,
  GET_DOCUMENT,
  SUCESS_DOCUMENT_CREATE,
  RESEND_ELECTRONIC_INVOICE,
  GET_ADITIONAL_DATA_INVOICE,
  GET_ERRORS,
  SUCESS_SEND_INVOICE,
  SHOW_CATALOG_ITEMS,
  GET_TOTAL_DOCUMENTS,
  GET_QUOTATION,
  GET_REASONS_NOTES,
  GET_FILE_INVOICE,
  ACTIVE_STEP_INVOICE
} from "./types"
import { handleResponse } from './authActions';

// const headers = { 'Authorization': localStorage.getItem('jwtToken') }
const urlInvoice = `${API_ENDPOINT_DOCUMENT}/document/api/Documento/`;



export const getInvoices = (data, functionSelected) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    const response = await axios.post(`${urlInvoice}GetDocumentsFilter`, data, config);
    if (response.status === 200) {
      if (response.data.result.length > 0) {
        functionSelected(response.data.result[0].id);
      }
      dispatch({
        type: GET_INVOICES,
        payload: response.data.result
      });
    }
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};

export const getAditionalData = (id, userid) => async (dispatch) => {

  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      'pEmpresaId': id,
      'pUserId': userid
    })

    const response = await axios.get(`${urlInvoice}GetAdicionalDataEnterprise`, config);

    const { data } = response;
    dispatch({
      type: GET_ADITIONAL_DATA_INVOICE,
      payload: data,
    });
  } catch (err) {
    handleResponse(err.response);
    console.log("Error obteniendo información adiconal de la empresa,", !!err.response ? err.response : err);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido obtener información adicional de la empresa'
    });
  }
};

export const getDocument = (id, functionLoad) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      'idDocumento': id
    })

    const response = await axios.get(`${urlInvoice}GetDocumentoById`, config);
    //console.log('LLEGO='+JSON.stringify(response));
    if (response.data.statusCode === "200") {
      functionLoad();
    }
    dispatch({
      type: GET_DOCUMENT,
      payload: response.data.result,
    });
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};

export const updateDocument = (data, funtionResponse) => async (dispatch) => {
  try {

    const response = await axios.post(`${urlInvoice}UpdateDocument`, data, defaultHeaders());
    if (response.status === 200) {
      funtionResponse();
      dispatch({
        type: SUCESS_DOCUMENT_UPDATE,
        payload: response.data,
      });
    }
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};
 
export const createElectronicInvoice = (data, funtionResponse, functionError) => async (dispatch) => {
  try {

    console.log('documento='+JSON.stringify(data));
    const response = await axios.post(`${urlInvoice}CreateDocumentInvoice`, data, defaultHeaders());    
    if (response.data.statusCode === "201") {
      funtionResponse(response);
      dispatch({
        type: SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
    if (response.data.statusCode === "200") {
      funtionResponse(response);
      dispatch({
        type: SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
  } catch (error) {
    handleResponse(error.response);
    functionError(error.response);   
  }
};

export const resendElectronicInvoice = (data, funtionResponse) => async (dispatch) => {
  try {

    const response = await axios.post(`${urlInvoice}ReenviarFacturaElectronicaId`, data, defaultHeaders());
    if (response.status === 200) {
      funtionResponse();
      dispatch({
        type: RESEND_ELECTRONIC_INVOICE,
        payload: response.data,
      });
    }
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};

export const createQoutation = (data, funtionResponse, functionError) => async (dispatch) => {
  try {

    //console.log('documento Cotizacion ='+JSON.stringify(data));
    const response = await axios.post(`${urlInvoice}createDocumentQuotation`, data, defaultHeaders());
    if (response.data.statusCode === "201") {
      funtionResponse(response);
      dispatch({
        type: SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
  } catch (err) {
    handleResponse(err.response);
    functionError(err.response);
  }
};

export const editQoutation = (data, funtionResponse, functionError) => async (dispatch) => {
  try {

    console.log('documento Edicion='+JSON.stringify(data));
    const response = await axios.post(`${urlInvoice}editDocumentQuotation`, data, defaultHeaders());
    if (response.data.statusCode === "201") {
      funtionResponse(response);
      dispatch({
        type: SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
  } catch (err) {
    handleResponse(err.response);
    functionError(err.response);
  }
};

export const sendInvoice = (data) => async (dispatch) => {
  try {
    //console.log('send mail' + JSON.stringify(data));
    const response = await axios.post(`${urlInvoice}SendingEmailInvoice`, data, defaultHeaders());
    if (response.status === 200) {
      dispatch({
        type: SUCESS_SEND_INVOICE,
        payload: response.data.result,
      });
    }
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};


/**
* @function
* @description Carga Listado de articulos seleccionados en arreglo
* @param {Listado items seleccionados} listItems 
*/
// export const addItemsToCartAction = (listItems) => (dispatch) => {

//   try {

//     let storageItems = localStorage.getItem('itemsInCart');
//     let list = storageItems === null ? [] : JSON.parse(localStorage.getItem('itemsInCart'));
//     list = list.concat(listItems);
//     localStorage.setItem('itemsInCart', JSON.stringify(list))

//     dispatch({
//       type: GET_ITEMS_CART,
//       payload: list,
//     });

//   } catch (err) {
//     console.error("Errro agregando articulos a factura", err)
//     localStorage.clear('itemsInCart');
//   }
// }


/**
 * @function
 * @description Devuelve listado de items cargados en carrito
 */
// export const getItemsInCartAction = () => (dispatch) => {

//   let aux = localStorage.getItem('itemsInCart');
//   let list = aux === null ? [] : JSON.parse(localStorage.getItem('itemsInCart'));

//   dispatch({
//     type: GET_ITEMS_CART,
//     payload: list,
//   });
// }


/**
 * @function
 * @description Asigna estado para mostrar o ocultar 
 * catalogo de articulos desde la generación de facturas
 * @param {Estado} status 
 */
export const setStatusShowCatalogAction = (status) => (dispatch) => {
  dispatch({
    type: SHOW_CATALOG_ITEMS,
    payload: status,
  });
}



export const getTotalDocs = (id, tipoelec) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      'pIdEmpresa': id,
        'pTipoDocElec': tipoelec
    })

    const response = await axios.get(`${urlInvoice}GetTotalDocuments`, config);
    dispatch({
      type: GET_TOTAL_DOCUMENTS,
      payload: response.data.result,
    });
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};

export const getQuotation = (id, functionLoad) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      'pIdEmpresa': id,
      'idDocumento': id
    })

    const response = await axios.get(`${urlInvoice}GetDocumentoById`, config);
    //console.log('LLEGO='+JSON.stringify(response));
    if (response.data.statusCode === "200") {
      functionLoad();
    }
    dispatch({
      type: GET_QUOTATION,
      payload: response.data.result,
    });
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};

export const getReasonNotes = () => async (dispatch) => {
  try {
    const response = await axios.get(`${urlInvoice}GetReasonNote`, defaultHeaders());
    if (response.status === 200) {
      dispatch({
        type: GET_REASONS_NOTES,
        payload: response.data.result
      });
    }
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};

export const createNote = (data, funtionResponse, functionError) => async (dispatch) => {
  try {
    console.log('documento='+JSON.stringify(data));
    const response = await axios.post(`${urlInvoice}createDocumentNote`, data, defaultHeaders());
    if (response.data.statusCode === "201") {
      funtionResponse(response);
      dispatch({
        type: SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
    if (response.data.statusCode === "200") {
      funtionResponse(response);
      dispatch({
        type: SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
  } catch (err) {
    handleResponse(err.response);
    functionError(err.response);
  }
};

export const getFileInvoice = (data, functionLoad) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      'pTipoDocumento': data.TipoDocumento,
      'pDocumento': data.Documento,
      'pCodigoUnico': data.CodigoUnico,
      'pTipoArchivo': data.TipoArchivoId      
     })

    const response = await axios.get(`${urlInvoice}GetFile`, config);
    //console.log('LLEGO='+JSON.stringify(response));
    if (response.data.statusCode === "200") {
      functionLoad(response.data.result);
    }
    dispatch({
      type: GET_FILE_INVOICE,
      payload: response.data.result,
    });
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};

export const getFilePreview = (data, funtionResponse) => async (dispatch) => {
  try {
    const response = await axios.post(`${urlInvoice}GetPreviewFile`, data, defaultHeaders());
    if (response.data.statusCode === "201") {
      funtionResponse(response.data.result);
      dispatch({
        type: GET_FILE_INVOICE,
        payload: response.data,
      });
    }
  } catch (err) {
    handleResponse(err.response);
    console.log("Error,", err.response);
    let response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
    });
  }
};


export const setActiveStepInvoiceAction = (step) => async (dispatch) => {
  dispatch({
    type: ACTIVE_STEP_INVOICE,
    payload: step
  });
}




