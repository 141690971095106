import axios from 'axios';

import { API_ENDPOINT_ARTICLE, DEFAULTRESPONSE, defaultHeaders } from "../config/config";
import {
  SAVE_CHARGE,
  UPDATE_CHARGE,
  DELETE_CHARGE,
  GET_CHARGELIST,
  GET_CHARGE,
  GET_FILTER_CHARGE,
  LOADING_SAVE_CHARGE,
  CHANGE_EDITING_CHARGE_STATUS,
  GET_ERRORS,
  LOADING_GET_CHARGES
} from "./types";

import { handleResponse, authHeader, enterpriseIdHeader } from './authActions';

const urlEndPoint = `${API_ENDPOINT_ARTICLE}/item/api/cargo`;
let allCharges = [];


export const getCharges = (filter = '', refresh = true) => async (dispatch) => {

  try {

    if (allCharges.length > 0 && refresh === false) {
      dispatch({
        type: GET_CHARGELIST,
        payload: filerList(allCharges, filter),
      });

    } else {
      changeGetListLoading(dispatch, true);
      let config = defaultHeaders();
      Object.assign(config.headers, {
        'enterpriseId': `${enterpriseIdHeader()}`,
      });

      const url = `${urlEndPoint}/getallbyenterpriseid`;
      var response = await axios.get(url, config);

      allCharges = response.data.result.map(item => {
        return {
          ...item,
          text: item.descripcion
        }
      });

      dispatch({
        type: GET_CHARGELIST,
        payload: (filter !== '') ? filerList(allCharges, filter) : allCharges
      });

    }
  }catch(err) {
    console.log("Error consultando listado de cargos", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar listado cargos.'
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }
};

//
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: LOADING_GET_CHARGES,
    payload: status
  })
}


export const getCharge = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/getdatabyid`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });

    var response = await axios.get(url, config);
    dispatch({
      type: GET_CHARGE,
      payload: response.data,
    });

  }catch(err) {
    console.log("Error consultando información cargo", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido consultar información de cargo.'
    });
  }
};

export const getFilterCharge = (data) => async (dispatch) => {
  dispatch({
    type: GET_FILTER_CHARGE,
    payload: data,
  });
};

export const changeEditingChargeStatus = (data) => async (dispatch) => {
  dispatch({
    type: CHANGE_EDITING_CHARGE_STATUS,
    payload: data,
  });
};

/**
 * @action
 * @description Guarda cargo nuevo
 * @param {object} data Información cargp
 */
export const postCharge = (data) => async (dispatch) => {

  try {

    changeSaveLoading(dispatch, true);

    data = {
      ...data,
      empresaid: `${enterpriseIdHeader()}`,
    }

    const url = `${urlEndPoint}/createcharge`;
    var response = await axios.post(url, data, defaultHeaders());

    dispatch({
      type: SAVE_CHARGE,
      payload: response.data,
    });

  }catch(err) {
    console.log("Error registrando información cargo", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido registrando información de cargo.'
    });

  } finally {
    changeSaveLoading(dispatch, false);
  }
}


export const putCharge = (data) => async (dispatch) => {

  try {
    
    changeSaveLoading(dispatch, true);
    const url = `${urlEndPoint}/updatecharge`;
    var response = await axios.put(url, data, defaultHeaders());

    dispatch({
      type: UPDATE_CHARGE,
      payload: response.data,
    });

  }catch(err) {
    console.log("Error actualizando información cargo", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido actualizando información de cargo.'
    });

  } finally {
    changeSaveLoading(dispatch, false);
  }
};


function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_CHARGE,
    payload: status
  })
}


export const deleteCharge = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/deletecharge`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });


    var response = await axios.delete(url, config);
    dispatch({
      type: DELETE_CHARGE,
      payload: response.data,
    });

  }catch(err) {
    console.log("Error eliminando información cargo", !!err.response ? err.response : err);
    handleResponse(err.response);
    const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_ERRORS,
      payload: response,
      payload1: 'No se ha podido eliminando información de cargo.'
    });

  }
};

export const clearCurrentCharge = () => async dispatch => {
  dispatch({
    type: GET_FILTER_CHARGE,
    payload: null
  })
}

export const clearUpdateCharge = () => async dispatch => {
  dispatch({
    type: UPDATE_CHARGE,
    payload: null
  })
}

export const clearCreateCharge = () => async dispatch => {
  dispatch({
    type: SAVE_CHARGE,
    payload: null
  })
}

export const clearDeleteCharge = () => async dispatch => {
  dispatch({
    type: DELETE_CHARGE,
    payload: null
  })
}

//Filtra listado cargos
function filerList(list, filter) {
  try {

    if (!list) return list;

    const data = list.filter(x => {
      const descripcion = x.descripcion.toLowerCase();
      const valor = x.valor;
      const query = `${descripcion} ${valor}`;
      const modValue = filter.toLowerCase();
      return query.indexOf(modValue) > -1;
    });

    return data;
  } catch (err) {
    console.log("Error Filtrando listado cargos:", err);
  }
}
