import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { URL_IMAGES } from '../../config/config.js';

import Grid from '@material-ui/core/Grid';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from "@material-ui/core/CardMedia";
import { Card } from '@material-ui/core';

import CustomCardAditionalData from "../invoice/aditional-data-card.component";
import CollapsePanel from '../../components/collapse/collapsestyles.component';
import TooltipMessage from "../../components/tooltip/tootltip-message.component";

import { getAditionalData} from '../../actions/invoiceActions';
import { ClearGetComponentsAction,getMenuComponentAction} from '../../actions/configAction'

import { getComponentName,getComponentUrl } from '../../utils/general.js';

const useStyles = makeStyles({
  
  showLayer :{
    display: 'block'
  },
  hideLayer:{
      display: 'none'
  },
  letter: {
      color: '#FFFFFF'
  },
  alignRight:{
    textAlign: "right"
  },
  alignLeft:{
    textAlign: "left"
  },
  alignCenter:{
    textAlign: "center"
  },
  widthMax:{
    width: "100%"
  },
  paddingIcons: {
    paddingTop: "20%"
  },
  width99:{
    width: "99%"
  },
  width98:{
    width: "98%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)"
  },
  width97:{
    width: "97%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)"
  },
  width90:{
    width: "90%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)"
  },
  letterTaxes:{
    color: "#2CC63E"
  },
  totalInvoiceDetail:{
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: "20px",
    fontWeight: "800",
    letterSpacing: "0",
    textAlign: "left"
  },
  colorIcon:{
    color: "#2CC63E"
  },
  h1Card: {
    justifyContent: "center",
    textAlign: "center",
    color: "white"
  },
  cover: {
    width: "100%",
    height: "100%"
  },
});

const InvoiceRevision = (props) => {
  const classes = useStyles();
    const {heightDiv, objHead, objItems, aditionalData, Reason, ObservationEdit,getMenuComponentAction,ClearGetComponentsAction } = props;      
    
     const [star, setStar] = React.useState(objHead.favorite);  

     const [expandPanel, setExpandPanel] = useState({
        expandPanelInfo: '',
        cleanData: false,
        edit: false
      });

      let document = null;
      let address = {};
      let contact = {};
      let { configReducer } = props;
      let components = configReducer.menuComponent
     
      //console.log('RESPONSE PRODUCTOS-->'+JSON.stringify(objItems));   

      if (objHead != null){
        
         let i = 0;
         let j = 0;
         
         for (i===0;i<objHead.clientInfo.adclientedireccion.length;i++){
             if (objHead.clientInfo.adclientedireccion[i].id === objHead.addressid)   {
                address = {
                    nombmun: objHead.clientInfo.adclientedireccion[i].ciudaddescripcion,                      
                    nombpais: objHead.clientInfo.adclientedireccion[i].departamentodescripcion, 
                    id: objHead.clientInfo.adclientedireccion[i].id,                      
                    direccion: objHead.clientInfo.adclientedireccion[i].direccion,
                    descripcion: objHead.clientInfo.adclientedireccion[i].descripcion,                  
                    Clienteid: objHead.clientInfo.adclientedireccion[i].clienteid, 
                    Ciudadid: objHead.clientInfo.adclientedireccion[i].ciudadid, 
                    Idstate: objHead.clientInfo.adclientedireccion[i].idstate, 
                    Createdby: objHead.clientInfo.adclientedireccion[i].createdby, 
                    Modifiedby: objHead.clientInfo.adclientedireccion[i].modifiedby, 
                    Idbusinessunit: objHead.clientInfo.adclientedireccion[i].idowner, 
                    Idowner: objHead.clientInfo.adclientedireccion[i].idowner,                        
                    Principal: objHead.clientInfo.adclientedireccion[i].principal 
                }
             }
         }
         if (address!=={} && objHead.dirprincipal.length>0 ){  
            address = {
                nombmun: objHead.dirprincipal[0].ciudaddescripcion,
                nombpais: objHead.dirprincipal[0].departamentodescripcion,
                id: objHead.dirprincipal[0].id,
                direccion: objHead.dirprincipal[0].direccion,
                descripcion: objHead.dirprincipal[0].descripcion,
                Clienteid: objHead.dirprincipal[0].clienteid,
                Ciudadid: objHead.dirprincipal[0].ciudadid,
                Idstate: objHead.dirprincipal[0].idstate,
                Createdby: objHead.dirprincipal[0].createdby,
                Modifiedby: objHead.dirprincipal[0].modifiedby,
                Idbusinessunit: objHead.dirprincipal[0].idowner,
                Idowner: objHead.dirprincipal[0].idowner,
                Principal: objHead.dirprincipal[0].principal
            }
        }
         for (j===0;j<objHead.clientInfo.adcontactos.length;j++){
            
            if (objHead.clientInfo.adcontactos[j].id === objHead.contactid)   {
                
               contact = {
                    id: objHead.clientInfo.adcontactos[j].id, 
                    nombre: objHead.clientInfo.adcontactos[j].nombre, 
                    telefono: objHead.clientInfo.adcontactos[j].telefono, 
                    celular: objHead.clientInfo.adcontactos[j].celular, 
                    email: objHead.clientInfo.adcontactos[j].email, 
                    clienteid: objHead.clientInfo.adcontactos[j].clienteid, 
                    idstate:objHead.clientInfo.adcontactos[j].idstate, 
                    createdby:objHead.clientInfo.adcontactos[j].createdby, 
                    modifiedby:objHead.clientInfo.adcontactos[j].modifiedby,
                    idbusinessunit:objHead.clientInfo.adcontactos[j].idbusinessunit, 
                    idowner:objHead.clientInfo.adcontactos[j].idowner
               }
            }
        }

        //obtiene el nombre del medio de pago
        let k = 0;
        let mediodesc = "";
        if (aditionalData.mediospago !== null){
          for (k===0;k<aditionalData.mediospago.length;k++){
              if(objHead.paymethodid !== ''){
                  if (aditionalData.mediospago[k].id == objHead.paymethodid){
                    mediodesc = aditionalData.mediospago[k].descripcion;
                }
              }
              else{
                mediodesc = "No disponible";
              }
              
          }
        }

        document={
            descripcionmedio: objHead.paymethodid === 0 || objHead.paymethodid === '' ? "No aplica" : mediodesc, 
            descripcionforma: objHead.visibleCredit === true ? "Credito" : "Contado",  
            clientedocumento: objHead.clientInfo.documento,
            tipdoccli: objHead.clientInfo.diantipodocumentoidentidadid,  
            desdoccli: aditionalData.adclienteinfo !== null? aditionalData.adclienteinfo.tipodoc: '', 
            desctipe: aditionalData.adclienteinfo !== null? aditionalData.adclienteinfo.tipoper: '', 
            estado: "Pagada",
            desctirg: aditionalData.adclienteinfo !== null? aditionalData.adclienteinfo.tiporeg: '', 
            adclientedirecciones: [address],
            opdocumentodescuentos: null,
            opdocumentocargos: null,
            adtarifaimpuesto: [],
            id: 0,
            numero: "",
            fecha: objHead.dateInvoice, 
            valortotal: 0.00, 
            textoobservacion: objHead.observations, 
            diantipodocumentoelectronicoid: 1,
            fechapago: objHead.datePay, 
            clienteid: objHead.clientInfo.id,
            favorito: star,
            razonsocialcliente: objHead.clientInfo.razonsocial,
            telefonocliente: objHead.clientInfo.telefono,
            correoelectronicocliente: objHead.clientInfo.correoelectronico, 
            nombresvendedor: objHead.Vendor.nombres, 
            opdetalledocumento: []
        }
      }   

    const getCategories = (cats) => {
        if (cats !== null){
          
          const categories = cats.map((cat) => 
               <Grid item key={cat.id} lg={2} md={2} sm={12} xs={12}>
                 <div className="spacingInvoiceMini"></div> 
                   <div className='circlesCategories' style={{ backgroundColor : cat.color}} title={cat.nombre}></div>
                </Grid>
          );
          return categories;
        }
      }

      //console.log('aditional' + JSON.stringify(aditionalData.adclienteinfo));
      useEffect(() => { 
            //console.log('response-->'+JSON.stringify(formSave))
          props.getAditionalData(objHead.clientInfo.id, '');    
        
      }, [objHead]);
      
      useEffect(() => {
        getMenuComponentAction(3);
        // returned function will be called on component unmount 
        return () => {
          ClearGetComponentsAction()
        }
      },[])

      const pad = (input, length, padding) => { 
        var str = input + "";
        return (length <= str.length) ? str : pad(padding+str, length, padding);
      }      
    
      const getArticles = () => {
        if (objItems.detalleDocumento !== undefined){
          const articles = objItems.detalleDocumento.map((items) => 
            <div>
                <div class="cardItemInvoice">
                  <Grid container>
                    <Grid item lg={3} md={3} sm={12}>
                      <Card key={items.id} style={{height: '80%', width: '97%', background: items.color === null || items.color === undefined || items.color.length === 0 ? '#33BBFF' : items.color}}>                        
                        <CardMedia
                         className={classes.cover}
                        image={items && URL_IMAGES + items.imagen}
                        style={{
                          background: `${
                            items.imagen === null || items.imagen === undefined || items.imagen.length === 0
                              ? items.color === null || items.color === undefined || items.color.length === 0 ? '#33BBFF' : items.color
                              : null
                            }`,
                        }}
                        >
                          {items.imagen === null || items.imagen === undefined || items.imagen.length === 0 ? (
                          <h1
                            className={classes.h1Card}
                          >
                            {items.descripcion.substring(0,2).toUpperCase()}
                          </h1>
                        ) : null}
                        </CardMedia>
                      </Card>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} className="paddingLeft">
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} className="titleArticleInvoice">
                        {items.descripcion.length > 60 ? items.descripcion.substring(0,60) + '...': items.descripcion}  <div className="spacingInvoice"></div>
                        </Grid>              
                        <Grid container>
                          <Grid item lg={12} md={12} sm={12}><div className={classes.width98} ></div></Grid>    
                        </Grid> 
                      </Grid>                    
                      <Grid container className="fieldsReadContainer">
                        <Grid item lg={10} md={10} sm={12} className="fieldsReadMini">Valor C/U:</Grid>
                        <Grid item lg={2} md={2} sm={12} className="fieldsReadMini">Cantidad:</Grid>
                      </Grid>
                      <Grid container>
                        <Grid item lg={10} md={10} sm={12} className="totalInvoiceDetail">${new Intl.NumberFormat('es-CO').format(items.valorNeto)}</Grid>
                        <Grid item lg={2} md={2} sm={12} className="fieldsReadBig">{new Intl.NumberFormat('es-CO').format(items.cantidad)}</Grid>             
                      </Grid>
                      <Grid container>
                        <Grid item lg={10} md={10} sm={12}><div className={classes.width98}></div></Grid>
                        <Grid item lg={2} md={2} sm={12}><div className={classes.width98}></div></Grid>    
                      </Grid> 
                      <Grid container>
                        <Grid item lg={6} md={6} sm={12} className="fieldsReadBig">
                        <div className="spacingInvoice"></div>  
                          {items.porcentajeiva === 0 ? '' : 'IVA: '}<span className={classes.letterTaxes}>{items.porcentajeiva === 0 ? '' : items.porcentajeiva+'%'}</span> {items.porcentajeiva> 0 && items.porcentajeinc>0 ? '/':''} {items.porcentajeinc === 0 ? '' : 'INC: '}<span className={classes.letterTaxes}>{items.porcentajeinc === 0 ? '' : items.porcentajeinc+'%'}</span></Grid>
                        <Grid item lg={6} md={6} sm={12} className="fieldsReadBig"><Grid container>{getCategories(items.categorias)}</Grid></Grid>          
                      </Grid>
                      <div className="spacingInvoice"></div>
                      <Grid container>
                          <Grid item lg={12} md={12} sm={12}><div className={classes.width98}></div></Grid>    
                      </Grid>
                      <Grid container className="fieldsReadContainer">
                          <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">Descuento:</Grid>
                          <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Total:</Grid>
                      </Grid>  
                      <Grid container>
                        <Grid item lg={8} md={8} sm={12} className={classes.totalInvoiceDetail}>{items.valordescuento > 0 ? '$': '%'}{items.valordescuento > 0 ? new Intl.NumberFormat('es-CO').format(items.valordescuento) : items.porcentajedescuento}</Grid>
                        <Grid item lg={4} md={4} sm={12} className="totalInvoiceDetail">${new Intl.NumberFormat('es-CO').format(items.total)}</Grid>            
                      </Grid>
                    </Grid>
                  </Grid>               
                </div>
            <div className="spacingInvoice"></div>
            </div>   
          );
          return articles;        
        }
      }

      const getInfoAditional = () => {
        
        let cargos=objItems.cargos;
        let descuentos=objItems.otrosdescuentos;
        
        return(
          <div>
            <Grid container>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Fecha Factura </Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Forma de Pago</Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Fecha de Pago</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{pad(new Date(document.fecha).getDate(), 2, "0")}/{pad((new Date(document.fecha).getMonth()+1),2,"0")}/{new Date(document.fecha).getFullYear()}</Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{document.descripcionforma}</Grid>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{document.fechapago === '' ? 'No disponible' : pad(new Date(document.fechapago).getDate(), 2, "0")+'/'+pad((new Date(document.fechapago).getMonth()+1),2,"0")+'/'+new Date(document.fechapago).getFullYear()}</Grid>
            </Grid>          
            <Grid container>
              <Grid item lg={4} md={4} sm={12}><div className={classes.width97}></div></Grid>
              <Grid item lg={4} md={4} sm={12}><div className={classes.width97}></div></Grid>
              <Grid item lg={4} md={4} sm={12}><div className={classes.width97}></div></Grid>
            </Grid>
            <Grid container className="fieldsReadContainer">
              <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">Medio Pago</Grid>
              <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">Vendedor</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">{document.descripcionmedio}</Grid>
              <Grid item lg={8} md={8} sm={12} className="fieldsReadBig">{document.nombresvendedor} {document.apellidosvendedor}</Grid>            
            </Grid>
            <Grid container>
            <Grid item lg={4} md={4} sm={12}><div className={classes.width98}></div></Grid>
              <Grid item lg={8} md={8} sm={12}><div className={classes.width98}></div></Grid>    
              </Grid>        
            <Grid container className="fieldsReadContainer">
              <Grid item lg={12} md={12} sm={12} className="fieldsReadMini" style={{width : '96%'}}>Observaciones</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} className="fieldsReadBig">{document.textoobservacion}</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12}><div className="lineDivisionFields"></div></Grid>    
            </Grid>  
            <div className="spacingInvoice"></div>
            <div className="titleAdicitional">Cargos</div>
            <div className="spacingInvoice"></div>
            <Grid container spacing={1}>            
            {cargos!== undefined ? cargos!== null ? cargos.map((data, index) =>
                            <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                            <CustomCardAditionalData data={data} type="charges"
                                onClick=""
                                />
                            </Grid>
                        ):"": ""}
            </Grid>
            <div className="spacingInvoice"></div>                        
            <div className="titleAdicitional">Descuentos sobre el total</div>
            <div className="spacingInvoice"></div>
            <Grid container spacing={1}>
            {descuentos!== undefined ? descuentos!== null ?descuentos.map((data, index) =>
                            <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                              <CustomCardAditionalData data={data} type="discounts" tooltipMessage={ data.modelodescuento === 1 ?
                               <TooltipMessage
                               title={ getComponentName(components,93,157,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,93,158,'Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura.')}
                               botton={ getComponentName(components,93,159,'Más información')}
                               href={getComponentUrl(components, 93, 150, null)}
                             /> :  
                             <TooltipMessage
                               title={ getComponentName(components,94,160,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,94,161,'Es un descuento que afecta el valor total a pagar pero no el valor total de la factura.')}
                               botton={ getComponentName(components,94,162,'Más información')}
                               href={getComponentUrl(components, 94, 162, null)}
                           />
                              }
                                  onClick=""
                                  />
                            </Grid>
                        ):"" : ""}
            </Grid>
          </div>
        );
      }

    const getInfoAditionalNotas = () => {
        
        let cargos=objItems.cargos;
        let descuentos=objItems.otrosdescuentos;
        
        return(
          <div>
            <Grid container>
            <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">Factura Asociada </Grid>
              <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">Tipo de Nota</Grid>
            </Grid>
            <Grid container>
        <Grid item lg={6} md={6} sm={12} className="fieldsReadBig">{objHead.numeroFactura}</Grid>
              <Grid item lg={6} md={6} sm={12} className="fieldsReadBig">{Reason.value.split(":")[1] === "NC" ? "Crédito" : "Debito"}</Grid>
            </Grid>          
            <Grid container>
              <Grid item lg={6} md={6} sm={12}><div className={classes.width97}></div></Grid>
              <Grid item lg={6} md={6} sm={12}><div className={classes.width97}></div></Grid>              
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} className="fieldsReadMini">Motivo de la Edición</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} className="fieldsReadBig">{Reason.text}</Grid>         
            </Grid> 
            <Grid container>
            <Grid item lg={4} md={4} sm={12}><div className={classes.width98}></div></Grid>
              <Grid item lg={8} md={8} sm={12}><div className={classes.width98}></div></Grid>    
              </Grid>        
              <Grid container>
              <Grid item lg={12} md={12} sm={12} className="fieldsReadMini" style={{width : '96%'}}>Observaciones</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} className="fieldsReadBig">{ObservationEdit}</Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12}><div className="lineDivisionFields"></div></Grid>    
              </Grid>  
              <div className="spacingInvoice"></div>
              <div className="titleAdicitional">Cargos</div>
              <div className="spacingInvoice"></div>
              <Grid container spacing={1}>            
              {cargos!== undefined ? cargos!== null ? cargos.map((data, index) =>
                              <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                              <CustomCardAditionalData data={data} type="charges"
                                  onClick=""
                                  />
                              </Grid>
                          ):"": ""}
                          </Grid>
              <div className="spacingInvoice"></div>                        
              <div className="titleAdicitional">Descuentos sobre el total</div>
              <div className="spacingInvoice"></div>
              <Grid container spacing={1}>
              {descuentos!== undefined ? descuentos!== null ?descuentos.map((data, index) =>
                              <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                              <CustomCardAditionalData data={data} type="discounts" tooltipMessage={ data.modelodescuento === 1 ?
                               <TooltipMessage
                               title={ getComponentName(components,93,157,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,93,158,'Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura.')}
                               botton={ getComponentName(components,93,159,'Más información')}
                               href={getComponentUrl(components, 93, 150, null)}
                             /> :  
                             <TooltipMessage
                               title={ getComponentName(components,94,160,'¿Cómo afecta la factura este descuento?')}
                               message={ getComponentName(components,94,161,'Es un descuento que afecta el valor total a pagar pero no el valor total de la factura.')}
                               botton={ getComponentName(components,94,162,'Más información')}
                               href={getComponentUrl(components, 94, 162, null)}
                           />
                              }
                                  onClick=""
                                  />
                            </Grid>
                          ):"" : ""}
                          </Grid>
          </div>
        );
      }

        return(            
            <div style={{color: '#FFFFFF'}}>            
            <Grid container>
                <Grid item lg={3} md={3} sm={12}></Grid>
                <Grid item lg={6} md={6} sm={12}>
                <div className="backgroundRevision" style={{overflow : 'auto', height: heightDiv}}>  
            <div className="spacingInvoice"></div>
            <Grid container>
                <Grid item lg={4} md={4} sm={12}></Grid>
                <Grid item lg={4} md={4} sm={12}></Grid>
                <Grid item lg={4} md={4} sm={12} className="dateRevision">{new Date(document.fecha).getFullYear()}-{pad((new Date(document.fecha).getMonth()+1),2,"0")}-{pad(new Date(document.fecha).getDate(),2,"0")}</Grid>
            </Grid>
            <div className="spacingInvoice"></div>
            <div className="lineDivision"></div>
            <div className="spacingInvoice"></div>
            <Grid container>
                <Grid item lg={4} md={4} sm={12}></Grid>
                <Grid item lg={4} md={4} sm={12} className="totalRevision">${new Intl.NumberFormat('es-CO').format(objItems.totalapagar)}</Grid>
                <Grid item lg={4} md={4} sm={12}></Grid>
            </Grid>
            <Grid container>
                <Grid item lg={4} md={4} sm={12}></Grid>
                <Grid item lg={4} md={4} sm={12} className="commentsRevision">Valor total impuestos incluidos</Grid>
                <Grid item lg={4} md={4} sm={12}></Grid>
            </Grid>
            <div className="spacingInvoice"></div>
            <div className="lineDivision"></div>
            <div class="paddingLeft">    
              <div className="constumerNameDetail">{document.razonsocialcliente}</div>
              <div className="detailInvoiceInfo">{aditionalData.adclienteinfo !== null? aditionalData.adclienteinfo[0].tipodoc: ''} {document.clientedocumento}</div>
              <div className="detailInvoiceInfo">{aditionalData.adclienteinfo !== null? aditionalData.adclienteinfo[0].tipoper: ''}</div>
              <div className="detailInvoiceInfo">{aditionalData.adclienteinfo !== null? aditionalData.adclienteinfo[0].tiporeg: ''}</div>
              <div className="spacingInvoice"></div>
            </div>
            <div class="gridNoSpace">  
              <EmailIcon className={classes.colorIcon}></EmailIcon>&nbsp;&nbsp;<div className="detailInvoiceInfo">{document.correoelectronicocliente}</div>
            </div>
            <div class="gridNoSpace">  
              <PhoneIcon className={classes.colorIcon}></PhoneIcon>&nbsp;&nbsp;<div className="detailInvoiceInfo">{document.telefonocliente}</div>
            </div>
            <div class="gridNoSpace">  
              <RoomIcon className={classes.colorIcon}></RoomIcon>&nbsp;&nbsp;<div className="detailInvoiceInfo">{document.adclientedirecciones === undefined ? "": document.adclientedirecciones[0].direccion + ' '+ document.adclientedirecciones[0].nombmun + ' '+ document.adclientedirecciones[0].nombpais}</div>
            </div>
            <div className="spacingInvoice"></div>
            <div className="greenTitleInvoice">Artículos ({objItems.detalleDocumento === undefined ? 0 : objItems.detalleDocumento.length})</div>
            <div className="spacingInvoice"></div>
            {getArticles()}                
            <CollapsePanel
                component={Reason === undefined ? getInfoAditional : getInfoAditionalNotas}
                title={"Información Adicional"}
                titleOpen={"Información Adicional"}
                titleEdit={"Información Adicional"}
                edit={expandPanel.edit}
                open={expandPanel.expandPanelInfo}
                cleanData={expandPanel.cleanData}
                backgroundColorSummary={{ backgroundColor: "#2CC63E", color:'#000000'}}
                backgroundColorDetail={{ backgroundColor: "#5E5E5E"}}
                type="showinfo"
                />
            </div>           
                </Grid>
                <Grid item lg={3} md={3} sm={12}></Grid>
            </Grid> 
          </div>                        
      );

}

const mapStateToProps = (state) => ({    
      aditionalData: state.invoiceReducer.aditionaldataenterprise, 
      configReducer: state.configReducer
    });
    
    const mapDispatchToProps = {     
      getAditionalData,
      getMenuComponentAction,
      ClearGetComponentsAction
    };
    export default connect(mapStateToProps, mapDispatchToProps)(InvoiceRevision);