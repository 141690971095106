import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Grid, IconButton } from '@material-ui/core/';

import './modal.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.modalBackgroundColor,
  },
  paper: {
    height: '100%',
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
    color: theme.palette.primaryColor,
  },
  iconClose: {
    color: theme.palette.thirdColor,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'right',
    textAlign: 'right',
  },
  logoColfactura: {
    height: '51px',
    width: '162.25px',
  },
  divContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  titleModal: {
    fontSize: '30px',
    fontWeight: '800',
    fontFamily: 'Muli',
  },
  subTitleModal: {
    color: theme.palette.primaryColor,
    fontSize: '30px',
    fontWeight: '400',
    fontFamily: 'Muli',
  },
  bodyModal: {
    color: theme.palette.primaryColor,
    fontWeight: '800',
    fontFamily: 'Muli',
    marginBottom: '2rem',
  },
  successTitle: {
    color: theme.palette.thirdColor,
  },
  warningTitle: {
    color: theme.palette.fourthColor,
  },
  dangerTitle: {
    color: theme.palette.fifthColor,
  },
}));

function ResponseModal({ modalType, title, subtitle, body, modalImage, closeElement, onCloseElement, open = true }) {
  const classes = useStyles();

  const getModalType = () => {
    switch (modalType) {
      case 'success':
        return classes.successTitle;
      case 'warning':
        return classes.warningTitle;
      case 'danger':
        return classes.dangerTitle;
      default:
        return classes.successTitle;
    }
  }

  const getModalImage = () => {
    switch (modalImage) {
      case 'success':
        return require('./../../images/modal/9CreacionFactura-09.png');
      case 'warning':
        return require('./../../images/modal/14Ocurriounerror-14.png');
      case 'danger':
        return require('./../../images/modal/14Ocurriounerror-14.png');
      case 'quotation':
        return require('./../../images/modal/10CreacionCotizacion-10.png');
      case 'successBasicData':
        return require('./../../images/modal/11ActualizacionDatosEmpresa-11.png');
        case 'noimagen':
        return require('./../../images/blank.png');
      default:
        return require('./../../images/modal/10CreacionCotizacion-10.png');
    }
  }
  //console.log(onCloseElement);
  const contentModal = (
    <div className={classes.paper}>
      <Grid container>
        <Grid item lg={4} md={4} sm={12}></Grid>
        <Grid item lg={4} md={4} sm={12}></Grid>
        <Grid item lg={4} md={4} sm={12}>
          <IconButton className={classes.iconClose} onClick={onCloseElement}  >
            {closeElement}
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4} md={4} sm={12}></Grid>
        <Grid item lg={4} md={4} sm={12}>
          <div className={classes.divContainer}>
            <div id="response-modal-title" className={classes.titleModal}>
              <span className={getModalType()}>{title}</span>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={3} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12}>
          <div className={classes.divContainer}>
            <div id="response-modal-description" className={classes.subTitleModal}>{subtitle}</div>
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={3} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12}>
          <div className={classes.divContainer}>
            <img src={getModalImage()} alt={modalImage} ></img>
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={3} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12}>
          <div className={classes.divContainer}>
            <div className={classes.bodyModal}>
              {body}
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4} md={4} sm={12}></Grid>
        <Grid item lg={4} md={4} sm={12}>
          <div className={classes.divContainer}>
            <img src={require('./../../images/logo-small.png')} className={classes.logoColfactura} alt={"Logo"} ></img>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12}></Grid>
      </Grid>
    </div >
  );

  return (
    <div>
      <Modal
        aria-labelledby="response-modal-title"
        aria-describedby="response-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {contentModal}
        </Fade>
      </Modal>
    </div>
  );
}

ResponseModal.propTypes = {};

export default ResponseModal;
