import React , {Component}from "react";
import { withStyles } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";

import {Card,CardContent,Grid,Tooltip} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import '../../../src/styles/informe.css';

const useStyles = (theme) => ({
 
    txtFilter: {
      margin: "10px 0px 10px 0px",
      color: theme.palette.primaryColor,
    },
    titleCard: {
      color: theme.palette.primaryColor,
      fontFamily: theme.typography.fontFamily,
      fontSize: 10,
      fontWeight:'bold',
      textAlign: "center",
    },
    colorCard: {
      background: theme.palette.ninethBackgroundColor,
    },
    colorDescription: {
      background: "#272727",
      color: theme.palette.primaryColor,
      fontSize: 12.32,
      fontWeight: 600,
      whiteSpace: 'nowrap',
      width: '100%',
      height:'100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameArticle: {
      marginLeft: 3,
    },
    priceArticle: {
      fontSize: 10.68,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primaryColor,
      marginLeft: 10,
      fontWeight:200
    },
    cardOpacity:{
      opacity:0.3
    },
    divMessage:{
      textAlign:'center',
      fontFamily: theme.typography.fontFamily,
      color:theme.palette.primaryColor,
      fontSize: 16,
      fontWeight: 600
    }
  });

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

class Informe extends Component {

    constructor(props) {
        super(props);

        this.state = {
          open: false,
          nameA: '',
          currenMoun: null,
          currenYear: null,
          years: null,
        }
    }

    componentDidMount(){
      this.setState({
        currenMoun: this.getMountData(),
        currenYear: this.getYearData(),
        years: this.setYears(),
      })
    }

    getMountData(){
      const n =  new Date();
      const m = n.getMonth() + 1;
      return ("0" + (n.getMonth() + 1)).slice(-2)
    }

    getYearData(){
      const n =  new Date();
      const a = n.getFullYear();
      return a;
    }

    setYears(){
      const n =  new Date();
      const a = n.getFullYear();
      let y = []

      for (let index = 2018; index <= a ; index++) {
        const element = index;
        y.push(index)
      }
      return y.reverse();
    }

    /**
   *
   * @param {*} classes
   * @param {*} data
   */
    render (){

        const {classes, name} = this.props
        const { open, nameA, currenMoun, years, currenYear } = this.state

        const generarInforme = () => {
            console.log('tales')
            this.setState({
              open:true,
            })
        }

        const handleClose = () => {
          console.log('tales cerrar')
          this.setState({
            open:false,
          })
        }

        const handleAceptar = () => {
          console.log('tales cerrarasdfds', nameA)
          
        }

        const getTextArea = (evento) => {
          console.log('tales cerrar',evento.target.value)
          this.setState({
            nameA: evento.target.value
          })
        }

        const getMes = (evento) => {
          console.log('tales Date',evento.target.value)
          this.setState({
            currenMoun: evento.target.value
          })
          //return "hola mundo"
        }   

        const getYear = (evento) => {
          console.log('tales Year',evento.target.value)
          this.setState({
            currenYear: evento.target.value
          })
          //return "hola mundo"
        }   

        return (
            <Card className={classes.colorCard}>
                <CardHeader title={false ? 'loquesea' : name} className={"title"} />
                <div className={classes.divMessage}>En este informe tendrá los datos específicos sobre las facturas de ventas, notas crédito y débito que se generaron en su empresa</div>
                <CardContent>
                    <Button variant="contained" color="primary" onClick={generarInforme}>
                        Generar
                    </Button>


                    <div>
                      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" className="backgroundContent"><span className="backgroundContentText">{name}</span></DialogTitle>
                        <DialogContent className="backgroundContent">
                          <DialogContentText>
                            <span className="backgroundContentText">En este informe tendrá los datos específicos sobre las facturas de ventas, notas crédito y débito que se generaron en su empresa</span>
                          </DialogContentText>
                          <Grid
                            container
                            direction="row"
                          >
                            <div className="positionSelect">
                            <InputLabel shrink className="labelSelect">
                              Seleccione el año
                            </InputLabel>
                              <NativeSelect
                                  id="demo-customized-select-native"
                                  value={currenYear}
                                  onChange={getYear}
                                  input={<BootstrapInput />}
                                >
                                {years ? years.map((year,i)=><option key={i} value={year}>{year}</option>) : null}
                              </NativeSelect>
                            </div>
                            
                            <div className="positionSelect">
                          <InputLabel shrink className="labelSelect">
                            Selecciones el mes
                          </InputLabel>
                          <NativeSelect
                              id="demo-customized-select-native"
                              value={currenMoun}
                              onChange={getMes}
                              input={<BootstrapInput />}
                            >
                              <option aria-label="None" value="" />
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </NativeSelect>
                          </div>
                          </Grid>
                        </DialogContent>
                        <DialogActions className="backgroundContent">
                          <Button onClick={handleClose} variant="contained" color="secondary">
                            Cancel
                          </Button>
                          <Button onClick={handleAceptar} variant="contained" color="primary">
                            Descargar
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>

                </CardContent>
            </Card>
        )
    }
}

export default withStyles(useStyles)(Informe);
