import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import RoomIcon from '@material-ui/icons/Room';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';




const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
        backgroundColor: theme.palette.fourthBackgroundColor,
        color: theme.palette.primaryColor,
        fontWeight: 500
    },
    title: {
        color: theme.palette.primaryColor,
        fontWeight: 600,
    },
    content: {
        textTransform: 'capitalize',
        padding: '5px 10px'

    },
    icon: {
        color: '#2CC63E'
    },
    header: {
        padding: '5px 10px 0px 10px'
    },
    cardContent: {
        padding: '5px 10px 0px 42px'
    }
}));

function AddressCard(props) {
    const { data, onDelete, onLoad } = props;
    const classes = useStyles();


    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.header}
                avatar={
                    <RoomIcon className={classes.icon} />
                }
                action={
                    <Fragment>
                        <Tooltip title="Editar">
                            <IconButton aria-label="Editar">
                                <CreateIcon className={classes.icon} onClick={onLoad} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                            <IconButton aria-label="Eliminar">
                                <DeleteIcon className={classes.icon} onClick={onDelete} />
                            </IconButton>
                        </Tooltip>

                    </Fragment>
                }
                classes={{
                    title: classes.title,
                }}
                title={data.descripcion}
                subheader=""
            />
            <CardContent className={classes.cardContent}>
                <Typography disableTypography variant="body2" className={classes.content} component="p">
                    {data.direccion}
                </Typography>
                <Typography disableTypography variant="body2" className={classes.content} component="p">
                    {`${data.departamentodescripcion} - ${data.ciudaddescripcion}`}<br />
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
            </CardActions>
        </Card>
    );
}


AddressCard.propTypes = {
    data: PropTypes.object.isRequired
}

export default AddressCard;
