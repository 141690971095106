import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import { ConfirmAlert } from '../../../helpers/alert.helpers'
import ArticleView from '../../catalogCategory/article/article.view'
import ItemInvoiceList from './ItemInvoiceList.component'
import InvoiceTotal from './invoiceTotal.component'
import ThirdCollapse from '../../../components/collapse/thirdCollapse.component'
import ManageList from '../../../components/manageList/manageList.component'
import ManageChargeCard from '../invoiceCharge/manageChargeCard.component'
import ManageDiscountCard from '../invoiceDiscount/manageDiscountCard.component'
import ManageReasonCard from '../reasonsNote/manageReasonCard.component'
import SimpleModal from '../../../components/modal/simpleModal.component'
import NewChargeInvoice from '../invoiceCharge/newCharge.component'
import NewDiscountInvoice from '../invoiceDiscount/newDiscount.component'
import CreateArticle from '../../catalogCategory/article/createArticle.component';
import ManageGlobalChargeCard from '../invoiceGlobalCharge/manageGlobalChargeCard.component'
import InputForm from "../../../components/input/input.component";
import cogoToast from 'cogo-toast';

import {
    getTotalAction,
    addChargeToInvoiceAction,
    getChargesInvoiceAction,
    loadChargeInvoiceAction,
    deleteChargeToInvoiceAction,
    clearLoadedChargeInvoiceAction,
    updateChargeToInvoiceAction,
    openModalChargeAction,
    getDiscountsInvoiceAction,
    addDiscountToInvoiceAction,
    deleteDiscountToInvoiceAction,
    loadDiscountInvoiceAction,
    openModalDiscountAction,
    clearLoadedDiscountInvoiceAction,
    setStatusShowCartAction,
    validateTotalDocumentAction

} from '../../../actions/valuesInvoiceAction'
import {
    getReasonNotes,
    setActiveStepInvoiceAction
} from '../../../actions/invoiceActions'

import { getCharges } from '../../../actions/chargeAction'
import { getDiscounts } from '../../../actions/discountAction'

import generateId from '../../../utils/generateId'
import { getRoleOfLoggedUser } from "../../../actions/authActions";
import isEmpty from "../../../utils/isEmpty";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);



class InvoiceItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModalCharge: false,
            openModalDiscount: false,
            invoiceValues: {},
            documentDetail: [],
            detalleDocumento:[],
            totalDetail: {},
            getChargesEnterprise: [],
            loadingGetChargesList: false,
            loadChargeDocument: {},
            editChargeDocument: false,
            updateChargeDocumentResponse: {},
            getDiscountsEnterprise: [],
            loadingGetDiscountList: false,
            showCartInvoice: false,
            openModalItem: false,
            openCollapseCharge: '',
            openCollapseDiscount: '',
            openCollapseReason: '',
            getReasonsNote: [],
            reasonItem: {
                value: isEmpty(this.props.Reason) ? '' : this.props.Reason.value,
                text: isEmpty(this.props.Reason) ? '' : this.props.Reason.text
            },
            observations: this.props.Observation !== null ? this.props.Observation : '',
            activeStepInvoice: null
        }
    }

    componentDidMount() {
        this.props.getTotalAction();
        this.props.getCharges();
        this.props.getDiscounts();
        this.props.getReasonNotes();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};


        if (!!nextProps.valuesInvoiceReducer.totalDetail && nextProps.valuesInvoiceReducer.totalDetail !== state.totalDetail)
          {
            update.totalDetail = nextProps.valuesInvoiceReducer.totalDetail;
          }  

        if (!!nextProps.valuesInvoiceReducer.documentDetail && nextProps.valuesInvoiceReducer.documentDetail !== state.detalleDocumento) 
            update.detalleDocumento = nextProps.valuesInvoiceReducer.documentDetail;
        

        if (!!nextProps.chargeReducer.getChargesEnterprise && nextProps.chargeReducer.getChargesEnterprise !== state.getChargesEnterprise)
            update.getChargesEnterprise = nextProps.chargeReducer.getChargesEnterprise;

        if (nextProps.chargeReducer.loadingGetChargesList !== state.loadingGetChargesList)
            update.loadingGetChargesList = nextProps.chargeReducer.loadingGetChargesList;

        if (nextProps.valuesInvoiceReducer.editChargeDocument !== state.editChargeDocument)
            update.editChargeDocument = nextProps.valuesInvoiceReducer.editChargeDocument;

        if (nextProps.valuesInvoiceReducer.openModalCharge !== state.openModalCharge)
            update.openModalCharge = nextProps.valuesInvoiceReducer.openModalCharge;

        if (!!nextProps.discountReducer.getDiscountsEnterprise && nextProps.discountReducer.getDiscountsEnterprise !== state.getDiscountsEnterprise)
            update.getDiscountsEnterprise = nextProps.discountReducer.getDiscountsEnterprise;

        if (nextProps.discountReducer.loadingGetDiscountList !== state.loadingGetDiscountList)
            update.loadingGetDiscountList = nextProps.discountReducer.loadingGetDiscountList;

        if (nextProps.valuesInvoiceReducer.openModalDiscount !== state.openModalDiscount)
            update.openModalDiscount = nextProps.valuesInvoiceReducer.openModalDiscount;

        if (!isEmpty(nextProps.valuesInvoiceReducer.showCartInvoice) && nextProps.valuesInvoiceReducer.showCartInvoice !== state.showCartInvoice)
            update.showCartInvoice = nextProps.valuesInvoiceReducer.showCartInvoice;

        if (!!nextProps.invoiceReducer.reasons && nextProps.invoiceReducer.reasons !== state.getReasonsNote)
            update.getReasonsNote = nextProps.invoiceReducer.reasons;

        if (!isEmpty(nextProps.invoiceReducer.activeStepInvoice) && nextProps.invoiceReducer.activeStepInvoice !== state.activeStepInvoice){
            update.activeStepInvoice = nextProps.invoiceReducer.activeStepInvoice;
        }
       

        return Object.keys(update).length ? update : null;
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.detalleDocumento !== this.state.detalleDocumento) {
            if (this.state.detalleDocumento.length > 0) {
                this.props.setStatusShowCartAction(true);
            }
        }

        if (prevState.totalDetail !== this.state.totalDetail || prevState.activeStepInvoice !== this.state.activeStepInvoice) {
            if (Object.keys(this.state.totalDetail).length) {
                this.checkValuesValid(this.state.totalDetail);
            }
        }

        this.props.HandleItemState(prevState.showCartInvoice);
    }

    componentWillUnmount() {
        this.props.validateTotalDocumentAction(true);
        this.props.setActiveStepInvoiceAction(-1);
        this.props.setStatusShowCartAction(false);
    }

    /**
     * @event
     * @description Sincroniza input con state
     * @param {*} e 
     */
    syncChanges(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    /**
     * @event onClickCharge
     * @description Captura evento click sobre item en listado cargos
     * y lo agrega a la factura
     * @param {*} e  Evento
     * @param {*} item Objecto con información del item seleccionado
     */
    onClickCharge(e, item) {
        e.preventDefault();

        let chargeInvoice = {
            id: item.id,
            identificador: generateId(),
            descripcion: item.descripcion,
            tipocargo: item.tipocargo,
            valor: item.valor,
            esglobal: item.esglobal,
            cantidad: 1, //aplica solo para cargos globales
            valorcalculado: item.valor //aplica solo para cargos globales
        }

        this.props.addChargeToInvoiceAction(chargeInvoice);

        //Contraee panel cargos
        this.setState({
            openCollapseCharge: ''
        })
    }

    /**
    * @event
    * @description Evento click, Abre modal para registro de nuevo cargo
    * @param {*} e 
    */
    onClickNewCharge(e) {
        e.preventDefault();
        this.props.openModalChargeAction(true);
    }

    /**
     * @event
     * @description Carga Información de cargo en formulario para edición
     * @param {*} e 
     * @param {*} charge 
     */
    onLoadCharge(e, charge) {
        e.preventDefault();
        this.props.loadChargeInvoiceAction(charge);
        this.props.openModalChargeAction(true);
    }

    /**
     * @event
     * @description Elimina cargo de documento (Factura  o Nota)
     * @param {*} e 
     * @param {*} charge 
     */
    onDeleteCharge(e, charge) {
        e.preventDefault();
        const deleteData = (confirmed) => {
            if (confirmed) {
                this.props.deleteChargeToInvoiceAction(charge);
            }
        }

        ConfirmAlert('Eliminar cargo', `¿Estas seguro de eliminar ${charge.descripcion}?`, deleteData)
    }


    /**
    * @event
    * @description Evento click, Cierra modal gestión cargo 
    * @param {*} e 
    */
    onCloseModalCharge(e) {
        e.preventDefault();
        this.props.openModalChargeAction(false);
        this.props.clearLoadedChargeInvoiceAction();
    }


    /**
    * @event onClickDiscount
    * @description Captura evento click sobre item en listado descuentos
    * @param {*} e  Evento
    * @param {*} item Objecto con información del item seleccionado
    */
    onClickDiscount(e, item) {
        e.preventDefault();
        let discountInvoice = {
            id: item.id,
            identificador: generateId(),
            descripcion: item.descripcion,
            modelodescuento: item.modelodescuento,
            motivoid: item.motivoid,
            motivoDescripcion: !!item.motivo?.descripcion ? item.motivo.descripcion : '',
            tipodescuento: item.tipodescuento,
            valor: item.valor
        }
        this.props.addDiscountToInvoiceAction(discountInvoice);

        //Contraee panel cargos
        this.setState({
            openCollapseDiscount: ''
        })
    }


    /**
    * @event onClickReason
    * @description Captura evento click sobre item en listado motivos de edición
    * @param {*} e  Evento
    * @param {*} item Objecto con información del item seleccionado
    */
    onClickReason(e, item) {
        e.preventDefault();

        this.props.HandleReason(item);

        //Contraee panel razones
        this.setState({
            openCollapseReason: '',
            reasonItem: item
        })

    }

    /**
         * @event
         * @description captura el evento de observaciones
         */
    syncObservation = (value) => {

        this.setState({
            observations: value
        })
        this.props.HandleObservation(value);
    }


    /**
     * @event
     * @description Evento click, Abre modal para registro de nuevo descuento
     * @param {*} e 
     */
    onClickNewDiscount(e) {
        e.preventDefault();
        this.props.openModalDiscountAction(true);
    }

    /**
     * @event
     * @description Evento click, Cierra modal gestión descuento 
     * @param {*} e 
     */
    onCloseModalDiscount(e) {
        e.preventDefault();
        this.props.openModalDiscountAction(false);
        this.props.clearLoadedDiscountInvoiceAction();
    }

    /**
     * @event
     * @description Carga Información de descuento en formulario para edición
     * @param {*} e 
     */
    onLoadDiscount(e, discount) {
        e.preventDefault();
        this.props.loadDiscountInvoiceAction(discount);
        this.props.openModalDiscountAction(true);
    }

    /**
     * @event 
     * @description Elimina disucento de documento
     * @param {*} e 
     * @param {*} discount 
     */
    onDeleteDiscount(e, discount) {
        e.preventDefault();
        const deleteData = (confirmed) => {
            if (confirmed) {
                this.props.deleteDiscountToInvoiceAction(discount);
            }
        }

        ConfirmAlert('Eliminar descuento', `¿Estas seguro de eliminar ${discount.descripcion}?`, deleteData)
    }


    /**
     * @event
     * @description Escucha evento click, volver a catalogo articulos
     */
    onClickGoCatalog = (e) => {
        e.preventDefault();
        this.props.setStatusShowCartAction(false);
    }

    /**
     * @event
     * @description Escucha evento click, abre modal para creación nuevo articulo
     */
    onClickNewItem = (e) => {
        e.preventDefault();
        this.setState({
            openModalItem: true
        })

    }

    /**
     * Cierra modal nuevo articulo
     */
    onCloseModalItem(e) {
        e.preventDefault();
        this.setState({
            openModalItem: false
        })
    }

    /**
     * @event
     * @description Captura evento click expansión de panel gestion cargoo
     */
    onClickCollapseCharge = () => {
        this.setState({
            openCollapseCharge: this.state.openCollapseCharge === 'panel' ? '' : 'panel'
        })
        
    }


    /**
    * @event
    * @description Captura evento click expansión de panel gestion cargoo
    */
    onClickCollapseDiscount = () => {
        if (this.state.openCollapseDiscount === 'panel') {
            this.setState({
                openCollapseDiscount: ''
            })
        } else if (this.state.openCollapseDiscount === '') {
            this.setState({
                openCollapseDiscount: 'panel',
            })
        }
    }

    /**
     * @event
     * @description Captura evento click expansión de panel gestion motivos
     */
    onClickCollapseReason = () => {
        if (this.state.openCollapseReason === 'panel') {
            this.setState({
                openCollapseReason: ''
            })
        } else if (this.state.openCollapseReason === '') {
            this.setState({
                openCollapseReason: 'panel',
            })
        }
    }


    /**
     * Verifica si existe un total invalido dentro del listado de items 
     * cargados en el carrito, o el valor total a pagar es invalido
     * @param {object} totals Totales documento
     */
    checkValuesValid = (totals) => {
        
        let activeStep = this.state.activeStepInvoice=== undefined || this.state.activeStepInvoice === null ? -1: this.state.activeStepInvoice;  
        if(activeStep === 1){
            let items = !!totals?.detalleDocumento && totals?.detalleDocumento.length ? totals?.detalleDocumento : [];
            // Notificación carrito vacio
            if (items.length === 0 && this.state.showCartInvoice=== true) {
                this.warningToast(
                    "Carrito vacío...!!!",
                    `Seleccione los artículos que desea agregar al carrito.`)
            }

          
            let itemsInvalid = items.filter(i => i.totalreal <= 0);
            if ((totals?.totalapagar <= 0 || itemsInvalid.length > 0) && items.length > 0) {
                this.warningToast(
                    "Totales inválidos...!!!",
                    `El total a pagar o el total por ítem está generando un valor inválido para la factura, Por favor ajuste los totales resaltados en color rojo para continuar.
                     Recuerde que el total de ítem es afectado por descuentos comerciales.`)
            }
    
            let valid = itemsInvalid.length > 0 || totals?.totalapagar <= 0 ? false : true;
            this.props.validateTotalDocumentAction(valid);
        }
    }


    /**
    * Toast Warning
    * @param {string} title Titulo
    * @param {string} message Mensaje
    */
    warningToast = (title, message) => {

        const { hide } = cogoToast.warn(
            <div>
                <b>{title}</b>
                <div>{message}</div>
            </div>, {
            onClick: () => {
                hide();
            },
            position: 'top-right',
            hideAfter: 20
        });
    }


    /**
     * @function
     * @description Panel gestión cargos
     */
    chargePanel = () => {
        return (
            <Fragment>
                {
                    (getRoleOfLoggedUser())
                        ? <ManageList
                            list={this.state.getChargesEnterprise}
                            labelFilter={'Buscar cargo...'}
                            labelButton={
                                <LightTooltip
                                    title="No tiene permisos sobre esta acción"
                                    placement="bottom">
                                    <span>Crear nuevo cargo</span>
                                </LightTooltip>
                            }
                            onClick={(e, item) => {
                                this.onClickCharge(e, item)
                            }}
                            loading={this.state.loadingGetChargesList}
                        />
                        : <ManageList
                            list={this.state.getChargesEnterprise}
                            labelFilter={'Buscar cargo...'}
                            labelButton={'Crear nuevo cargo'}
                            onClick={(e, item) => {
                                this.onClickCharge(e, item)
                            }}
                            onClickNew={(e) => { this.onClickNewCharge(e) }}
                            loading={this.state.loadingGetChargesList}
                        />
                }
                <SimpleModal
                    onClose={e => this.onCloseModalCharge(e)}
                    open={this.state.openModalCharge}
                    component={NewChargeInvoice}
                    title={this.state.editChargeDocument === true ? 'Editar cargo documento' : 'Crear cargo'}
                    width={400}
                />
            </Fragment>
        );
    }

    /**
     * @function
     * @description Panel gestión descuentos
     */
    discountPanel = () => {
        return (
            <Fragment>
                {
                    (getRoleOfLoggedUser())
                        ? <ManageList
                            list={this.state.getDiscountsEnterprise}
                            labelFilter={'Buscar descuento...'}
                            labelButton={
                                <LightTooltip
                                    title="No tiene permisos sobre esta acción"
                                    placement="bottom">
                                    <span>Crear nuevo descuento</span>
                                </LightTooltip>
                            }
                            onClick={(e, item) => {
                                this.onClickDiscount(e, item)
                            }}
                            title={'Cargos'}
                            loading={this.state.loadingGetDiscountList}
                        />
                        : <ManageList
                            list={this.state.getDiscountsEnterprise}
                            labelFilter={'Buscar descuento...'}
                            labelButton={'Crear nuevo descuento'}
                            onClick={(e, item) => {
                                this.onClickDiscount(e, item)
                            }}
                            onClickNew={(e) => { this.onClickNewDiscount(e) }}
                            title={'Cargos'}
                            loading={this.state.loadingGetDiscountList}
                        />
                }
                <SimpleModal
                    onClose={e => this.onCloseModalDiscount(e)}
                    open={this.state.openModalDiscount}
                    component={NewDiscountInvoice}
                    title={'Descuentos'}
                    width={600}
                />
            </Fragment>
        );
    }

    /**
     * @function
     * @description Panel motivo edición, solo aplica en notas debito y credito
     */
    notePanel = () => {
        return (
            <Fragment>
                <ManageList
                    list={this.state.getReasonsNote.map((item) => ({
                        value: item.id + ':' + item.tipo,
                        text: item.descripcion,
                    }))}
                    labelFilter={'Buscar motivo...'}
                    onClick={(e, item) => {
                        this.onClickReason(e, item)
                    }}
                    title={'Motivos'}
                    loading={false}
                />
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                {this.state.showCartInvoice === true ?
                    <div className="comunContainer" style={{ minWidth: 900 }}>
                        <Grid container spacing={3} >
                            <Grid item lg={7} xs={7}>
                                <ItemInvoiceList
                                    documentDetail={this.state.detalleDocumento}
                                    totalDetail={this.state.totalDetail} />
                            </Grid>
                            <Grid item lg={4} xs={4} >
                                <Grid container spacing={1} className={'scrollPanel'}>
                                    <Grid item lg={12} xs={12}>
                                        <InvoiceTotal
                                            data={this.state.totalDetail}
                                            onClickGoCatalog={this.onClickGoCatalog}
                                            onClickNewItem={this.onClickNewItem} />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <ThirdCollapse
                                            component={this.chargePanel}
                                            title='Agregar Cargo'
                                            open={this.state.openCollapseCharge}
                                            onClick={this.onClickCollapseCharge} />
                                    </Grid>
                                    {this.state.totalDetail?.cargos?.map((item, index) =>
                                        <Grid key={index} item lg={12} xs={12}>
                                            {item.esglobal === true ?
                                                <ManageGlobalChargeCard
                                                    key={index}
                                                    data={item}
                                                    onDelete={(e) => this.onDeleteCharge(e, item)}
                                                /> :
                                                <ManageChargeCard
                                                    key={index}
                                                    data={item}
                                                    onLoad={(e) => this.onLoadCharge(e, item)}
                                                    onDelete={(e) => this.onDeleteCharge(e, item)} />
                                            }
                                        </Grid>
                                    )}
                                    <Grid item lg={12} xs={12}>
                                        <ThirdCollapse
                                            component={this.discountPanel}
                                            title='Agregar descuento sobre subtotal'
                                            open={this.state.openCollapseDiscount}
                                            onClick={this.onClickCollapseDiscount} />
                                    </Grid>
                                    {this.state.totalDetail?.otrosdescuentos?.map((item, index) =>
                                        <Grid item lg={12} xs={12}>
                                            <ManageDiscountCard
                                                key={index}
                                                data={item}
                                                onLoad={(e) => this.onLoadDiscount(e, item)}
                                                onDelete={(e) => this.onDeleteDiscount(e, item)} />
                                        </Grid>
                                    )}
                                    <Grid item lg={12} xs={12}>
                                        <div className={this.props.Edit !== undefined || this.props.Edit ? 'panelVisibleInvoice' : 'panelInvisibleInvoice'}>
                                            <ThirdCollapse
                                                component={this.notePanel}
                                                title='Seleccionar motivo edición'
                                                open={this.state.openCollapseReason}
                                                onClick={this.onClickCollapseReason} />

                                        </div>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <div className={this.props.Edit !== undefined || this.props.Edit ? this.state.reasonItem.text === '' ? 'panelInvisibleInvoice' : 'panelVisibleInvoice' : 'panelInvisibleInvoice'}>
                                            <ManageReasonCard
                                                key={1}
                                                data={this.state.reasonItem} />
                                        </div>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <div className={this.props.Edit !== undefined ? 'panelVisibleInvoice' : 'panelInvisibleInvoice'}>
                                            <InputForm
                                                id="observaciones"
                                                maxLength="1000"
                                                label={"Observaciones *"}
                                                multiline={true}
                                                value={this.state.observations}
                                                onChange={(ev) =>
                                                    this.syncObservation(ev.target.value)
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Fragment>
                            {/* Modal nuevo articulo */}
                            <SimpleModal
                                onClose={e => this.onCloseModalItem(e)}
                                open={this.state.openModalItem}
                                component={() =>
                                    <CreateArticle origin={'cart'} />}
                                title={'Nuevo articulo'}
                                width={1000}
                            />
                        </Fragment>
                    </div >
                    :
                    <div className="comunContainer" style={{ minWidth: 900 }}>
                        <ArticleView typeView='invoice' type={0} quantityInCart={this.state.detalleDocumento.length} />
                    </div>
                }
            </Fragment>

        );
    }

}

InvoiceItem.propTypes = {
    addChargeToInvoiceAction: PropTypes.func.isRequired,
    getChargesInvoiceAction: PropTypes.func.isRequired,
    getDiscountsInvoiceAction: PropTypes.func.isRequired,
    getTotalAction: PropTypes.func.isRequired,
    getCharges: PropTypes.func.isRequired,
    loadChargeInvoiceAction: PropTypes.func.isRequired,
    deleteChargeToInvoiceAction: PropTypes.func.isRequired,
    clearLoadedChargeInvoiceAction: PropTypes.func.isRequired,
    updateChargeToInvoiceAction: PropTypes.func.isRequired,
    openModalChargeAction: PropTypes.func.isRequired,
    getDiscounts: PropTypes.func.isRequired,
    addDiscountToInvoiceAction: PropTypes.func.isRequired,
    deleteDiscountToInvoiceAction: PropTypes.func.isRequired,
    loadDiscountInvoiceAction: PropTypes.func.isRequired,
    openModalDiscountAction: PropTypes.func.isRequired,
    clearLoadedDiscountInvoiceAction: PropTypes.func.isRequired,
    setStatusShowCartAction: PropTypes.func.isRequired,
    validateTotalDocumentAction: PropTypes.func.isRequired,

    invoiceReducer: PropTypes.object.isRequired,
    valuesInvoiceReducer: PropTypes.object.isRequired,
    chargeReducer: PropTypes.object.isRequired,
    discountReducer: PropTypes.object.isRequired,
    getReasonNotes: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    invoiceReducer: state.invoiceReducer,
    valuesInvoiceReducer: state.valuesInvoiceReducer,
    chargeReducer: state.chargeReducer,
    discountReducer: state.discountReducer
})

export default connect(mapStateToProps, {
    addChargeToInvoiceAction,
    getChargesInvoiceAction,
    getDiscountsInvoiceAction,
    getTotalAction,
    getCharges,
    loadChargeInvoiceAction,
    deleteChargeToInvoiceAction,
    clearLoadedChargeInvoiceAction,
    updateChargeToInvoiceAction,
    openModalChargeAction,
    getDiscounts,
    addDiscountToInvoiceAction,
    deleteDiscountToInvoiceAction,
    loadDiscountInvoiceAction,
    openModalDiscountAction,
    clearLoadedDiscountInvoiceAction,
    setStatusShowCartAction,
    getReasonNotes,
    validateTotalDocumentAction,
    setActiveStepInvoiceAction
})(InvoiceItem);