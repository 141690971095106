import React, { Component } from "react";
import { connect } from 'react-redux'
import PropTypes, { bool, object } from 'prop-types'
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";


import InputForm from "../../../components/input/input.component";
import DateForm from "../../../components/datepicker/datepicker.component";
import SwitchForm from '../../../components/switch/switch.component.js';
import SelectForm from '../../../components/select/select.component'
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import ButtonPrimary from '../../../components/button/buttonPrimary.component'


import {
    createNumerationAction,
    updateNumerationAction,
    getNumerationsFilterAction,
    loadNumerationAction,
} from '../../../actions/numerationActions'
import { getBranchOfficeAction } from '../../../actions/branchOfficeActions'

import { regexAlphaNumeric, regexOnlyNumbers } from '../../../helpers/regex.helper'
import { SuccessAlert, WarningAlert } from '../../../helpers/alert.helpers'
import { getComponentName, getComponentUrl } from "../../../utils/general";
import isEmpty from '../../../utils/isEmpty'

class FormNumeration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editNumerationStatus: false,
            modalities: [],
            loadedNumeration: {},
            company: [],
            saveNumerationResponse: {},
            updateNumerationResponse: [],

            form: {
                resolucion: '',
                prefijo: '',
                consecinicial: '',
                empresaId: 6,
                consecfinal: '',
                consecactual: '',
                diantipomodalidadid: '',
                fechainicio: '',
                fechafinal: '',
                nombre: '',
                sucursalempresaid: "",
                idstate: true,
            },
            components: [],
            loadingSaveNumeration: false,
            branchOfficesList: [],
            branchOfficesOptions:[],
        }
        this.validator = new SimpleReactValidator(reactValidatorOptions)

    }

    componentDidMount() { }

    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.numerationReducer.modalities && nextProps.numerationReducer.modalities !== state.modalities) {
            update.modalities = nextProps.numerationReducer.modalities.map(item => {
                return {
                    value: item.id,
                    text: item.descripcion
                }
            })
        }

        if (!!nextProps.basicDataReducer.requestCompany) {
            if (nextProps.basicDataReducer.requestCompany !== state.company) {
                update.company = nextProps.basicDataReducer.requestCompany;
            }
        }

        if (!!nextProps.branchOfficeReducer.branchOfficesList && nextProps.branchOfficeReducer.branchOfficesList !== state.branchOfficesList) {
            update.branchOfficesList = nextProps.branchOfficeReducer.branchOfficesList;
            update.branchOfficesOptions = nextProps.branchOfficeReducer.branchOfficesList.filter(b => b.idstate === 0);
        }


        if (!!nextProps.configReducer.components & nextProps.configReducer.components !== state.components)
            update.components = nextProps.configReducer.components;

        if (!!nextProps.numerationReducer.loadedNumeration && nextProps.numerationReducer.loadedNumeration !== state.loadedNumeration)
            update.loadedNumeration = nextProps.numerationReducer.loadedNumeration;


        if (!isEmpty(nextProps.numerationReducer.editNumerationStatus) && nextProps.numerationReducer.editNumerationStatus !== state.editNumerationStatus)
            update.editNumerationStatus = nextProps.numerationReducer.editNumerationStatus;

        if (!!nextProps.numerationReducer.saveNumerationResponse && nextProps.numerationReducer.saveNumerationResponse !== state.saveNumerationResponse)
            update.saveNumerationResponse = nextProps.numerationReducer.saveNumerationResponse;

        if (!!nextProps.numerationReducer.updateNumerationResponse && nextProps.numerationReducer.updateNumerationResponse !== state.updateNumerationResponse)
            update.updateNumerationResponse = nextProps.numerationReducer.updateNumerationResponse;

        if (!isEmpty(nextProps.numerationReducer.loadingSaveNumeration) && nextProps.numerationReducer.loadingSaveNumeration !== state.loadingSaveNumeration)
            update.loadingSaveNumeration = nextProps.numerationReducer.loadingSaveNumeration;

        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.saveNumerationResponse !== this.state.saveNumerationResponse) {
            if (this.state.saveNumerationResponse.statusCode === '201') {
                SuccessAlert(
                    getComponentName(this.state.components, 18, 78, 'Excelente!!!'),
                    getComponentName(this.state.components, 18, 79, 'Agregaste una nueva numeración')
                );
                this.props.getNumerationsFilterAction();
                this.props.getBranchOfficeAction();
                this.cleanData();
            } else if (this.state.saveNumerationResponse.statusCode === '400') {
               WarningAlert("Numeración existente...!!!", "Ya se encuentra registrada una numeración con la resolución, prefijo y modalidad suministradas.");

            }
        }


        if (prevState.updateNumerationResponse !== this.state.updateNumerationResponse) {
            if (this.state.updateNumerationResponse.statusCode === '201') {
                SuccessAlert(
                    getComponentName(this.state.components, 19, 81, 'Buen trabajo!!!'),
                    getComponentName(this.state.components, 19, 81, 'Actualizaste la numeración $resolucion de manera exitosa, sigamos facturando.').replace('$resolucion', this.state.loadedNumeration.resolucion)
                );
                this.props.getNumerationsFilterAction();
                this.props.getBranchOfficeAction();
                this.cleanData();
            }
        }

        if (prevState.loadedNumeration !== this.state.loadedNumeration) {
            if (!!this.state.loadedNumeration) {
                this.loadInfo(this.state.loadedNumeration);
            }
        }
    }


    /**
     * Sincroniza valor de Input con state
     * @param {*} e Evento
     */
    syncChanges = (e) => {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    };

    /**
     * Sincroniza valor de checkbox con state
     * @param {*} e Evento
     * @param {*} name 
     */
    syncCheckChange = (e) => {
        const name = !!e.target.id ? e.target.id : e.target.name;
        const checked = e.target.checked;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: checked
            }
        }))
    };

    /**
     * Sincroniza valor de calendar con state
     * @param {*} property Nombre de la propiedad en state
     * @param {*} value Nuevo valor 
     */
    syncDateChange = (property, value) => {
        const formattedDate = this.dateToString(value);
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [property]: formattedDate
            }
        }))
    }

    /**
     * Elimina caracteres invalidos, solo permite valores alfanumericos
     * @param {*} e Evento
     */
    validateAlphaNumeric(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexAlphaNumeric;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    /**
     * Elimina caracteres invalidos, solo permite valores numericos
     * @param {*} e Evento
     */
    validateOnlyNumbers(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexOnlyNumbers;
        value = value.replace(regex, '');
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value
            }
        }))
    }

    /**
     * Limpia información del formulario
     */
    cleanData() {
        this.setState(({
            form: {
                resolucion: '',
                prefijo: '',
                consecinicial: '',
                consecfinal: '',
                consecactual: '',
                diantipomodalidadid: '',
                fechainicio: '',
                fechafinal: '',
                nombre: '',
                sucursalempresaid: '',
                idstate: true,
            },
            loadedNumeration: null
        }))
        this.props.loadNumerationAction(null);
        this.validator.hideMessages();
        this.forceUpdate();
    }


    /**
     * Formate fecha a aaaa/mm/dd
     * @param {string} dateTime Fecha
     */
    dateToString(dateTime) {
        const date = new Date(dateTime)
        return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
    }

    /**
     * Captura y gurda o actualiza información de numeración
     * @param {*} e Evento
     */
    onSaveNumeration = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {


            if (this.state.editNumerationStatus) {    
                let data = {
                    ...this.state.loadedNumeration,
                    resolucion: this.state.form.resolucion,
                    prefijo: this.state.form.prefijo,
                    consecinicial: this.state.form.consecinicial,
                    consecfinal: this.state.form.consecfinal,
                    consecactual: this.state.form.consecactual,
                    diantipomodalidadid: this.state.form.diantipomodalidadid,
                    fechainicio: this.state.form.fechainicio,
                    fechafinal: this.state.form.fechafinal,
                    nombre: this.state.form.nombre,
                    sucursalempresaid: this.state.form.sucursalempresaid,
                    idstate: this.state.form.idstate === true ? 0 : 1,
                }

                this.props.updateNumerationAction(data);

            } else {
    
                let dataSave = {}
                dataSave = {
                    resolucion: this.state.form.resolucion,
                    prefijo: this.state.form.prefijo,
                    consecinicial: this.state.form.consecinicial,
                    consecfinal: this.state.form.consecfinal,
                    consecactual: this.state.form.consecactual,
                    diantipomodalidadid: this.state.form.diantipomodalidadid,
                    fechainicio: this.state.form.fechainicio,
                    fechafinal: this.state.form.fechafinal,
                    nombre: this.state.form.nombre,
                    sucursalempresaid: this.state.form.sucursalempresaid,
                    idstate: this.state.form.idstate === true ? 0 : 1,
                }
                this.props.createNumerationAction(dataSave);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    /**
     * Carga información de numeración para edición
     * @param {object} data  Infromación numeración 
     */
    loadInfo = (data) => {
        this.setState({
            form: {
                resolucion: data.resolucion,
                prefijo: data.prefijo,
                consecinicial: data.consecinicial,
                consecfinal: data.consecfinal,
                consecactual: data.consecactual,
                diantipomodalidadid: data.diantipomodalidadid,
                fechainicio: data.fechainicio,
                fechafinal: data.fechafinal,
                nombre: data.nombre,
                sucursalempresaid: data.sucursalempresaid,
                idstate: data.idstate === 0 ? true : false,
            }
        })
    }


    /**
     * Obtiene listado se sucursales disponibles
     */
    getBranchOfficesList = () => {
        let options = this.state.branchOfficesList.filter(b => b.idstate === 0);
        if (this.state.editNumerationStatus === true) {
            let loadNumeration = this.state.loadedNumeration;
            let exist = this.state.options.find(b => b.id === loadNumeration.id);
            if (exist === null || exist === undefined) {
                let newOption = this.state.branchOfficesList.find(b => b.id === loadNumeration.id);
                options.push(newOption);
            }
        }
        return options;
    }


    render() {
        let modalitiesOptions = [];
        let modalities = this.state.modalities;
        if (this.state.modalities !== undefined) {

            if (!!this.state.company) {
                if (this.state.company.aplicafe) {
                    modalitiesOptions = modalities.filter(m => m.text === 'POS');
                } else {
                    modalitiesOptions = modalities.filter(m => m.text === 'Computador' || m.text === 'POS');
                }
            }

            if (this.state.editNumerationStatus) {
                let modalityNumeration = modalities.filter(m => m.value === this.state.loadedNumeration.diantipomodalidadid);
                if (!modalitiesOptions.includes(modalityNumeration[0])) {
                    modalitiesOptions.push(modalityNumeration[0])
                }
            }
        }

        //Listado sucursales
        let branchOfficesOptions = this.state.branchOfficesOptions;
        if (this.state.editNumerationStatus === true) {
            let loadedNumeration = this.state.loadedNumeration;
            let exist = branchOfficesOptions.find(b => b.id === loadedNumeration.sucursalempresaid);
            if (exist === null || exist === undefined) {
                let newOption = this.state.branchOfficesList.find(b => b.id === loadedNumeration.sucursalempresaid);
                if (newOption !== undefined) {
                    branchOfficesOptions.push(newOption);
                }
            }
        }

        return (
            <form id="numerationForm" onSubmit={(e) => this.onSaveNumeration(e)} noValidate autoComplete="off" >
                <Grid container alignItems="center" spacing={3}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            id={"resolucion"}
                            label={"Resolución *"}
                            maxLength={20}
                            value={this.state.form.resolucion}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateOnlyNumbers(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|isNumber|noDecimal|noDecimal|noZero"}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            name={"prefijo"}
                            label={"Prefijo"}
                            maxLength={4}
                            value={this.state.form.prefijo}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateAlphaNumeric(e);
                            }}
                            validateOptions={"required"}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            name={"consecinicial"}
                            label={"Rango inicial *"}
                            maxLength={10}
                            value={this.state.form.consecinicial}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateOnlyNumbers(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|isNumber|noDecimal|noDecimal|noZero|initialConsecutive:" + this.state.form.consecfinal}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            name={"consecfinal"}
                            label={"Rango final *"}
                            maxLength={10}
                            value={this.state.form.consecfinal}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateOnlyNumbers(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|isNumber|noDecimal|noDecimal|noZero"}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            name={"consecactual"}
                            label={"Consecutivo actual *"}
                            maxLength={10}
                            value={this.state.form.consecactual}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateOnlyNumbers(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required|isNumber|noDecimal|noDecimal|noZero|consecutiveBetween:" + this.state.form.consecinicial + "," + this.state.form.consecfinal}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <DateForm
                            id={"fechainicio"}
                            label={"Fecha formalización *"}
                            value={this.state.form.fechainicio}
                            format={"dd MMM yyyy"}
                            onChange={this.syncDateChange}
                            validator={this.validator}
                            validateOptions={"required"}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <DateForm
                            id={"fechafinal"}
                            label={"Vigencia *"}
                            value={this.state.form.fechafinal}
                            format={"dd MMM yyyy"}
                            minDate={this.state.form.fechainicio}
                            minDateMessage={"La fecha de vigencia no puede ser menor a la fecha de formalización"}
                            onChange={this.syncDateChange}
                            validator={this.validator}
                            validateOptions={"required"}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <SelectForm
                            name="diantipomodalidadid"
                            label={"Modalidad *"}
                            value={this.state.form.diantipomodalidadid}
                            options={modalitiesOptions}
                            validator={this.validator}
                            validateOptions={'required'}
                            onChange={this.syncChanges}
                            placeholder={"Seleccione..."}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <InputForm
                            name={"nombre"}
                            label={"Nombre numeración"}
                            maxLength="100"
                            value={this.state.form.nombre}
                            onChange={this.syncChanges}
                            tooltip={
                                <TooltipMessage
                                    title={getComponentName(this.state.components, 17, 75, '¿Para que me sirve esto?')}
                                    message={getComponentName(this.state.components, 17, 76, 'Este nombre te servirá para identificar mas fácilmente tus resoluciones a la hora de elaborar una factura.')}
                                    botton={getComponentName(this.state.components, 17, 77, 'Más Información')}
                                    href={getComponentUrl(this.state.components, 17, 77, null)}
                                />
                            }
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <SelectForm
                            name="sucursalempresaid"
                            label={"Sucursal *"}
                            value={this.state.form.sucursalempresaid}
                            options={branchOfficesOptions}
                            validator={this.validator}
                            validateOptions={'required'}
                            placeholder={"Seleccione..."}
                            onChange={this.syncChanges}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <SwitchForm
                            name="idstate"
                            titleOn={this.state.form.idstate ? "Desactivar numeración" : "Activar numeración"}
                            checked={this.state.form.idstate}
                            disabled={this.state.editNumerationStatus ? this.state.form.diantipomodalidadid === 3 || this.state.form.diantipomodalidadid === 5 ? true : false : false}
                            onChange={(e) => this.syncCheckChange(e)}
                            value={this.state.form.idstate}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    spacing={3}
                    justify="flex-end"
                    alignItems="center">
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <ButtonPrimary
                            text={getComponentName(this.state.components, 21, 86, 'Listo')}
                            loading={this.state.loadingSaveNumeration}
                            type={'submit'}
                        />
                    </Grid>
                </Grid>

            </form>
        );
    }

}

FormNumeration.propTypes = {
    updateNumerationAction: PropTypes.func.isRequired,
    createNumerationAction: PropTypes.func.isRequired,
    getBranchOfficeAction: PropTypes.func.isRequired,

    numerationReducer: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    numerationReducer: state.numerationReducer,
    branchOfficeReducer: state.branchOfficeReducer,
    basicDataReducer: state.basicDataReducer,
    configReducer: state.configReducer,
})

export default connect(mapStateToProps, {
    updateNumerationAction,
    createNumerationAction,
    getBranchOfficeAction,
    getNumerationsFilterAction,
    loadNumerationAction,
})(FormNumeration);