import React, { Fragment,Component } from "react";
import { PieChart } from "react-minimal-pie-chart";


import Brightness1Icon from '@material-ui/icons/Brightness1';
import { withStyles } from "@material-ui/core/styles";

import {Card,CardContent,Grid,FormControlLabel,} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import CustomProgress from '../../../components/Progress/progress.component';


import "../Components/dashboard.css";

const useStyles = (theme) => ({
  
  colorCard: {
    background: theme.palette.ninethBackgroundColor 
  },
  optionName: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontWeight:400
  },
  optionTotal: {
    height: 20,
    width:216,
    color: theme.palette.primaryColor,
    fontFamily:theme.typography.fontFamily,
    fontSize:16,
    fontWeight:500,
    textAlign:'center'
  },
 colorPaid:{
  color: '#16B1F3', 
  fontSize:10
 },
 colorSlopes:{
  color: '#DDDD1C', 
  fontSize:10
 },
 colorOverdue:{
  color: '#F1474A', 
  fontSize:10
 },
 chart:{
  width: "80%",
  height:'100%',
  marginLeft: 10,
  fill: "#FFFFFF",
  fontSize: "5px",
  fontFamily: "Muli"
 },
 gridPosition:{
  paddingTop:10
 },
 icon: {
  fill: theme.palette.primaryColor
},
optionSelect:{
  fontFamily: theme.typography.fontFamily,
  fontSize: 14,
  fontWeight:500
},
SelectStyle:{
  width:180 , 
  marginLeft:70
},
divMessage:{
  textAlign:'center',
  fontFamily: theme.typography.fontFamily,
  color:theme.palette.primaryColor,
  fontSize: 16,
    fontWeight: 600
}
});

const CustomSelect = withStyles({
  root: {
    '&.MuiSelect-select': {
      width: 150,
      color: '#FFFFFF',
      fontFamily:'Muli',
      fontWeight:500,
      fontSize:14
      }
  },
  
})(Select);

const CustomForm = withStyles({
  root: {
      '& label.Mui-focused': {
          color: '#509b34',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#50C1EE', 
    },
      '& .MuiInput-underline:after': {
          borderBottomColor: '#50C1EE',
      },
      '& .MuiInputBase-input': {
          color: '#FFFF'
      }
  },
})(FormControl);


class BillingChart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes,data,totalSold, statusCode} = this.props;
    return (
    
      <Card className={classes.colorCard}>
           <FormControlLabel
        control={
          <CardHeader title={'Facturación'}  className={'titleCard'}/>
          
        }
         label={<CardHeader title={
         <CustomForm>
           <CustomSelect
           defaultValue={1}
           disabled
            inputProps={{
              classes: {
                  icon: classes.icon
              }
          }}
          className={classes.SelectStyle}
        >
          <MenuItem className={classes.optionSelect} value={1}>Balance Mensual</MenuItem>
          <MenuItem className={classes.optionSelect} value={2}>Balance Trimestral</MenuItem>
          <MenuItem className={classes.optionSelect} value={3}>Balance Anual</MenuItem>
        </CustomSelect>
         </CustomForm>
         } />}
      />
     { data.length === 0 && statusCode === undefined ? <CustomProgress/>:
         data.length === 0 && statusCode === 0  || data.length === 0 && statusCode === 200? 
         <div className={classes.divMessage}>No tiene facturas registradas</div> :'' }

     
  {/**
   *  <div>
      <MobileStepper
       
        position="static"
        variant="text"
       
        nextButton={
          <Button size="small" >
           <KeyboardArrowRight/>
          </Button>
        }
        backButton={
          <Button size="small" >
           <KeyboardArrowLeft/>
          </Button>
        }
      />
    </div>
   */}  
      
        
        <Grid container >

        

        <Grid item lg={7} md={7} xs={7} direction="column" >
         {data.length === 0 && statusCode === undefined ?'':
         data.length === 0 && statusCode === 0  || data.length === 0 && statusCode === 200? 
        '':
        <PieChart
          data={data}
          lineWidth={18}
          rounded
          label={({ dataEntry }) => Math.round(dataEntry.percentage)+"%"}
          radius={38}
          labelPosition={103}
          className={classes.chart}
        />}
        </Grid>
       
{
  data.length === 0 && statusCode === undefined ? '':
  data.length === 0 && statusCode === 0  || data.length === 0 && statusCode === 200? 
  '':        <Grid item  direction="column" className={classes.gridPosition} >
  <FormControlLabel
  control={
    <Brightness1Icon className={classes.colorPaid}
    />
  }
   label={<Fragment><span> </span> &nbsp; <span className={classes.optionName}>Pagadas</span></Fragment>}
/>
<br/>
   <FormControlLabel
  control={
    <Brightness1Icon className={classes.colorSlopes}
    />
    
  }
  label={  <Fragment><span> </span> &nbsp; <span className={classes.optionName}>Pendientes</span></Fragment> }
/>
  <br/>
  <FormControlLabel
  control={
    <Brightness1Icon className={classes.colorOverdue}
    />
  }
  label={<Fragment><span> </span> &nbsp; <span className={classes.optionName}>Vencidas</span></Fragment>}
/>  
<br/>
<br/>
<span className={classes.optionTotal}>Total Facturación:</span>
<br/>
<span className={classes.optionName}>{totalSold !== undefined ? totalSold.toLocaleString('en') : ''}</span>
</Grid>
}

        </Grid>
        <CardContent>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(useStyles)(BillingChart);
