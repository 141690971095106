import React, { Fragment, Component } from 'react';
import QuotationGrid from '../quotation/quotationGrid.view';
import QuotationCreate from '../quotation/quotationCreate.view';
import QuotationEdit from '../quotation/quotationEdit.view';
import QuotationBill from '../quotation/quotationBill.view';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import "../../../App.css";
import '../../../styles/invoice.css';
import CustomTabs from "../../../components/tabs/tabs.component";
import { cleanErrorAction } from '../../../actions/generalAction';



class Quotation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: {},
      showCreate: {display : 'none'},
      showGrid: {display : 'block'},
      showEdit: {display: 'none'} ,
      showBill: {display: 'none'} ,
      objHead: null
    }    
  }   
  
  empresaId(){
    return JSON.parse(localStorage.getItem('dataCompany')).companyId;
  }  


  userId(){
    //return 'f0f45843-405b-4e9d-8703-3714e5f151db';
    return '';
  }
       
  componentDidMount() {
    this.props.cleanErrorAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.generalReducer.error & nextProps.generalReducer.error !== state.error) {
      update.error = nextProps.generalReducer.error;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this.props.cleanErrorAction();
  }

  handleBack = () => {
    this.setState({ 
      ...this.state, 
      showGrid: { display: 'block' },
       showCreate: { display: 'none' }, 
       showEdit: { display: 'none'}, 
       showBill: { display: 'none'} 
      });
  };

  handleCreate = (objHead) => {
    this.setState({ ...this.state,
       showGrid: { display: 'none' }, 
       showCreate: { display: 'block' }, 
       showEdit: { display: 'none'}, 
       showBill: { display: 'none'} });
  };

  handleEdit = (objHead) => {
   
    this.setState({ ...this.state, 
      showGrid: { display: 'none' }, 
      showCreate: { display: 'none' }, 
      showEdit: { display: 'block'},
      showBill: { display: 'none'},
      objHead: objHead
    });    
  };


  handleBill = (objHead) => {
   
    this.setState({ ...this.state, 
      showGrid: { display: 'none' }, 
      showCreate: { display: 'none' }, 
      showEdit: { display: 'none'},
      showBill: { display: 'block'},
      objHead: objHead
    });    
  };
  

  tabs = [
    {
      title: 'Emitidas',
      component: <QuotationGrid HandleCreate={this.handleCreate} 
      HandleEdit={this.handleEdit} 
      HandleBill={this.handleBill} 
      Change={Math.random()} 
      Empresaid={this.empresaId()} 
      Userid={this.userId()} 
      Type={0}
      />
    },
    {
      title: 'Aprobadas',
      component: <QuotationGrid HandleCreate={this.handleCreate} 
      HandleEdit={this.handleEdit} 
      HandleBill={this.handleBill} 
      Change={Math.random()} 
      Empresaid={this.empresaId()} 
      Userid={this.userId()} 
      Type={2}
      />
    },
    {
      title: 'Rechazadas',
      component: <QuotationGrid HandleCreate={this.handleCreate} 
      HandleEdit={this.handleEdit} 
      HandleBill={this.handleBill} 
      Change={Math.random()} 
      Empresaid={this.empresaId()} 
      Userid={this.userId()} 
      Type={4}
      /> 
    }
  ];

  render() {
    return (
      <Fragment>
        <div className="container-form" style={this.state.showGrid} >
          <CustomTabs tabs={this.tabs} />
        </div>
        <div style={this.state.showCreate}>
          <QuotationCreate HandleBack={this.handleBack} Empresaid={this.empresaId()} Userid={this.userId()}></QuotationCreate>
        </div>  
        <div style={this.state.showEdit}>
          <QuotationEdit HandleBack={this.handleBack} Empresaid={this.empresaId()} Userid={this.userId()} objHead={this.state.objHead}></QuotationEdit>
        </div> 
        <div style={this.state.showBill}>
          <QuotationBill HandleBack={this.handleBack} Empresaid={this.empresaId()} Userid={this.userId()} objHead={this.state.objHead}></QuotationBill>
        </div>           
      </Fragment>
    );
  }


}

Quotation.propTypes = {
  cleanErrorAction: PropTypes.func.isRequired,
  generalReducer: PropTypes.object.isRequired,


}

const mapStateToProps = state => ({
  generalReducer: state.generalReducer
})

export default connect(mapStateToProps, {
  cleanErrorAction
})(Quotation);