import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import { IconButton } from "@material-ui/core/";


const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  icon: {
    // color: theme.palette.primaryColor
    color: 'gray'
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.primaryBackgroundColor,
    color: theme.palette.primaryBackgroundColor,

  },
  tag: {
    width: 250,
    borderRadius: 10,
    color: theme.palette.primaryColor,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
function CheckboxList(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [dataSelected, setDataSelected] = React.useState([]);
  const { options, onCloseList } = props;


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (index, data) => () => {
    const currentIndex = checked.indexOf(index);
    const newChecked = [...checked];
    const newData = [...dataSelected];

    if (currentIndex === -1) {
      newChecked.push(index);
      newData.push(data)
    } else {
      newChecked.splice(currentIndex, 1);
      newData.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setDataSelected(newData);
    console.log("Checked....", newData)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Fragment>
      <IconButton onClick={handleClick} disabled={true}>
        <CollectionsBookmarkIcon className={classes.icon} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e)=>{
          handleClose();
          onCloseList(e, dataSelected);
        } }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List dense
          className={classes.root}>
          {options.map((data, index) => {
            const labelId = `checkbox-list-secondary-label-${index}`;
            return (
              <ListItem key={index} button>
                <ListItemAvatar style={{ minWidth: 40 }}>
                  <FiberManualRecordIcon style={{ color: !!data.color ? data.color : 'white' }} />
                </ListItemAvatar>
                <ListItemText id={labelId} >
                  <div className={classes.tag}>
                    {data.nombre}
                  </div>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(index, data)}
                    checked={checked.indexOf(index) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </Fragment>

  );
}


export default CheckboxList;