export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOADING = 'LOADING';
export const SET_INDEX_SELECTED = 'SET_INDEX_SELECTED';


//Begin users
export const SAVE_BILLER = 'SAVE_BILLER';
export const UPDATE_BILLER = 'UPDATE_BILLER'
export const UPDATE_STATUS_BILLER = 'UPDATE_STATUS_BILLER'
export const GET_BILLERS = 'GET_BILLERS';
export const LOAD_BILLER = 'LOAD_BILLER';
export const LOADING_GET_BILLERS = 'LOADING_GET_BILLERS'
export const LOADING_SAVE_BILLER = "LOADING_SAVE_BILLER";


export const SAVE_SELLER = 'SAVE_SELLER';
export const UPDATE_SELLER = 'UPDATE_SELLER'
export const UPDATE_STATUS_SELLER = 'UPDATE_STATUS_SELLER'
export const GET_SELLERS = 'GET_SELLERS';
export const LOAD_SELLER = 'LOAD_SELLER';
export const LOADING_GET_SELLERS = 'LOADING_GET_SELLERS';
export const LOADING_SAVE_SELLER = "LOADING_SAVE_SELLER";


//Begin offices
export const GET_BRANCH_OFFICES = 'GET_BRANCH_OFFICES';
export const GET_OFFICES = 'GET_OFFICES';
export const GET_COMPANY = "GET_COMPANY";
export const LOAD_BRANCH_OFFICE = "LOAD_BRANCH_OFFICE";
export const SAVE_BRANCH_OFFICE = "SAVE_BRANCH_OFFICE";
export const UPDATE_BRANCH_OFFICE = "UPDATE_BRANCH_OFFICE";
export const UPDATE_STATE_BRANCH_OFFICE = "UPDATE_STATE_BRANCH_OFFICE";
export const GET_FILTER_BRANCH_OFFICES = 'GET_FILTER_BRANCH_OFFICES'
export const STATUS_EDIT_BRANCH_OFFICE = "STATUS_EDIT_BRANCH_OFFICE";
export const STATUS_STEP_BRANCH_OFFICE = "STATUS_STEP_BRANCH_OFFICE";
export const UPDATE_PRINCIPAL_BRANCH = "UPDATE_PRINCIPAL_BRANCH";
export const LOADING_SAVE_BRANCH = "LOADING_SAVE_BRANCH";
export const LOADING_GET_BRANCH_OFFICES = "LOADING_GET_BRANCH_OFFICES";





//Begin numeration
export const GET_NUMERATION = "GET_NUMERATION";
export const GET_NUMERATIONS = "GET_NUMERATIONS";
export const SAVE_NUMERATION = "SAVE_NUMERATION";
export const UPDATE_NUMERATION = "UPDATE_NUMERATION";
export const LOAD_NUMERATION = 'LOAD_NUMERATION';
export const STATUS_STEP_NUMERATION = "STATUS_STEP_NUMERATION";
export const GET_FILTER_NUMERATIONS = 'GET_FILTER_NUMERATIONS'
export const SYNC_GET_NUMERATION = "SYNC_GET_NUMERATION";
export const UPDATE_NUMERATION_STATE = "UPDATE_NUMERATION_STATE";
export const START_SYNC_NUMERATION = "START_SYNC_NUMERATION";
export const STATUS_SYNC_NUMERATION = "STATUS_SYNC_NUMERATION";
export const LOADING_UPDATE_NUMERATION = "LOADING_UPDATE_NUMERATION";
export const LOADING_SAVE_NUMERATION = "LOADING_SAVE_NUMERATION";
export const LOADING_GET_NUMERATIONS = "LOADING_GET_NUMERATIONS";

//Begin basicData
export const SAVE_BASIC_DATA = "SAVE_BASIC_DATA";
export const GET_TYPE_DOCUMENT = "GET_TYPE_DOCUMENT";
export const GET_REGIMEN = "GET_REGIMEN";
export const STATUS_STEP_BASIC_DATA = "STATUS_STEP_BASIC_DATA";
export const LOADING_SAVE_BASIC_DATA = "LOADING_SAVE_BASIC_DATA";
export const LOADING_GET_BASIC_DATA = "LOADING_GET_BASIC_DATA";


//Begin Template
export const GET_TEMPLATE = "GET_TEMPLATE";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE"
export const STATUS_STEP_TEMPLATE = "STATUS_STEP_TEMPLATE"
export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_MODALITY_TYPES = "GET_MODALITY_TYPES"
export const GET_TOWNS = "GET_TOWNS"

//Begin Config
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_STATE = 'GET_CITIES_STATE';
export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const GET_DOCUMENT_TYPES = 'GET_DOCUMENT_TYPES';
export const GET_REGIME_TYPES = 'GET_REGIME_TYPES';
export const GET_MENU = 'GET_MENU';
export const GET_COMPONENTS = 'GET_COMPONENTS';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_CITIES_COUNTRY = 'GET_CITIES_COUNTRY';
export const GET_MENU_COMPONENT = 'GET_MENU_COMPONENT';
export const GET_FREQUENT_QUESTIONS = 'GET_FREQUENT_QUESTIONS';
export const GET_REPORT_PROBLEM = 'GET_REPORT_PROBLEM'
export const GET_SECURITY_POLICY = 'GET_SECURITY_POLICY'
export const SEND_MAIL = 'SEND_MAIL'
export const GET_QUESTION_FILTER = 'GET_QUESTION_FILTER'
export const GET_REASON = 'GET_REASON'
export const SEND_MAIL_CONTACT ='SEND_MAIL_CONTACT'
//End Config

//Begin Article
export const GET_UNIT_MEASURE = "GET_UNIT_MEASURE"
export const GET_CATEGORY = "GET_CATEGORY"
export const SAVE_ARTICLE = "SAVE_ARTICLE"
export const GET_ARTICLE = 'GET_ARTICLE'
export const LOAD_ARTICLE = 'LOAD_ARTICLE'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const SUCCES_DELETE_ARTICLE = 'SUCCES_DELETE_ARTICLE'
export const LOAD_ARTICLES_IN_CART = 'LOAD_ARTICLES_IN_CART'
export const CLOSE_COLLAPSE_ARTICLE = 'CLOSE_COLLAPSE_ARTICLE'
export const SEND_INDEX_TAB = 'SEND_INDEX_TAB'
export const GET_FILTER_ARTICLE = 'GET_FILTER_ARTICLE'
export const GET_CATEGORY_ARTICLE = 'GET_CATEGORY_ARTICLE'
export const CLEAN_ARTICLE = 'CLEAN_ARTICLE'
export const END_SEACRH_ARTICLE = 'END_SEACRH_ARTICLE'
export const CLEAN_REQUEST = 'CLEAN_REQUEST'
export const LOADING_GET_ARTICLES = 'LOADING_GET_ARTICLES'
export const GET_ARTICLE_LIST = 'GET_ARTICLE_LIST'
export const GET_ARTICLE_CHECKED = 'GET_ARTICLE_CHECKED'
export const LOADING_UPDATE_ARTICLE = 'LOADING_UPDATE_ARTICLE'
export const LOADING_SAVE_ARTICLE = 'LOADING_SAVE_ARTICLE'
export const UPDATE_FAVORITE_ARTICLE = 'UPDATE_FAVORITE_ARTICLE'
export const SAVE_ADD_ARTICLE = "SAVE_ADD_ARTICLE"


//Begin Category
export const SUCCES_CATEGORY = "SUCCES_CATEGORY"
export const SUCCES_DELETE_CATEGORY = 'SUCCES_DELETE_CATEGORY'
export const SUCCES_UPDATE_CATEGORY = 'SUCCES_UPDATE_CATEGORY'
export const LOAD_CATEGORY = 'LOAD_CATEGORY'
export const CLEAN_DATA_CATEGORY = 'CLEAN_DATA_CATEGORY'
export const CLEAN_STATUS = "CLEAN_STATUS"

//Begin Favorite
export const SUCCES_CATEGORY_FAVORITE = 'SUCCES_CATEGORY_FAVORITE'
export const UPDATE_FAVORITE = 'UPDATE_FAVORITE'

//Begin Invoice
export const GET_INVOICES = 'GET_INVOICES';
export const SUCESS_DOCUMENT_UPDATE = 'SUCESS_DOCUMENT_UPDATE';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const SUCESS_DOCUMENT_CREATE = 'SUCESS_DOCUMENT_CREATE';
export const SUCESS_QUOTATION_CREATE = 'SUCESS_QUOTATION_CREATE';
export const RESEND_ELECTRONIC_INVOICE = 'RESEND_ELECTRONIC_INVOICE';
export const GET_ADITIONAL_DATA_INVOICE = 'GET_ADITIONAL_DATA_INVOICE';
export const SUCESS_SEND_INVOICE = 'SUCESS_SEND_INVOICE';
export const GET_ITEMS_CART = 'GET_ITEMS_CART';
export const SHOW_CATALOG_ITEMS = 'SHOW_CATALOG_ITEMS';
export const GET_TOTAL_DOCUMENTS = 'GET_TOTAL_DOCUMENTS';
export const CALCULE_INVOICE_VALUES = 'CALCULE_INVOICE_VALUES';
export const GET_QUOTATION = 'GET_QUOTATION';
export const GET_REASONS_NOTES = 'GET_REASONS_NOTES';
export const GET_FILE_INVOICE = 'GET_FILE_INVOICE';
export const ACTIVE_STEP_INVOICE = 'ACTIVE_STEP_INVOICE';
//End Invoice


//Begin Values Invoice
export const GET_DOCUMENT_DETAIL = 'GET_DOCUMENT_DETAIL';
export const GET_TOTAL_DETAIL = 'GET_TOTAL_DETAIL';
export const GET_CHARGES_DOCUMENT = 'GET_CHARGES_DOCUMENT';
export const LOAD_CHARGE_DOCUMENT = 'LOAD_CHARGE_DOCUMENT';
export const UPDATE_CHARGE_DOCUMENT = 'UPDATE_CHARGE_DOCUMENT';
export const OPEN_MODAL_CHARGE = 'OPEN_MODAL_CHARGE';
export const GET_DISCOUNTS_DOCUMENT = 'GET_DISCOUNTS_DOCUMENT';
export const LOAD_DISCOUNT_DOCUMENT = 'LOAD_DISCOUNT_DOCUMENT';
export const OPEN_MODAL_DISCOUNT = 'OPEN_MODAL_DISCOUNT';
export const UPDATE_DISCOUNT_DOCUMENT = 'UPDATE_DISCOUNT_DOCUMENT';
export const SHOW_CART_INVOICE = 'SHOW_CART_INVOICE';
export const DOCUMENT_VALID = 'DOCUMENT_VALID';
//End Values Invoice

//Begin Client
export const SAVE_CLIENT = 'SAVE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_STATUS = 'UPDATE_CLIENT_STATUS';
export const GET_FILTERLIST_CLIENT = 'GET_FILTERLIST_CLIENT'
export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const LOAD_CLIENT = 'LOAD_CLIENT';
export const LOAD_CLIENT_DETAIL = 'LOAD_CLIENT_DETAIL';
export const LOADING_SAVE_CLIENT = 'LOADING_SAVE_CLIENT';
export const LOADING_LIST_CLIENT = 'LOADING_LIST_CLIENT';
export const LOADING_UPDATE_CLIENT_STATUS = 'LOADING_UPDATE_CLIENT_STATUS';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const LOADING_DELETE_CLIENT = 'LOADING_DELETE_CLIENT';
export const STANDOUT_CLIENT = 'STANDOUT_CLIENT';
export const LOADING_STANDOUT_CLIENT = 'LOADING_STANDOUT_CLIENT';
export const LOADING_FILTERLIST_CLIENT = 'LOADING_FILTERLIST_CLIENT'
export const LOADING_DETAIL_CLIENT = 'LOADING_DETAIL_CLIENT';
//End Client

//Begin Address
export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const GET_ADDRESSLIST = 'GET_ADDRESSLIST';
export const LOAD_ADDRESS = 'LOAD_ADDRESS';
//End Address

//Begin contact
export const SAVE_CONTACT = 'SAVE_CONTACT';
export const GET_CONTACTSLIST = 'GET_CONTACTSLIST';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const LOAD_CONTACT = 'LOAD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
//End contact

//Begin Charge
export const SAVE_CHARGE = 'SAVE_CHARGE';
export const UPDATE_CHARGE = 'UPDATE_CHARGE';
export const DELETE_CHARGE = 'DELETE_CHARGE';
export const GET_CHARGELIST = 'GET_CHARGELIST';
export const GET_CHARGE = 'GET_CHARGE';
export const GET_FILTER_CHARGES = 'GET_FILTER_CHARGES';
export const GET_FILTER_CHARGE = 'GET_FILTER_CHARGE';
export const LOADING_SAVE_CHARGE = 'LOADING_SAVE_CHARGE';
export const CHANGE_EDITING_CHARGE_STATUS = 'CHANGE_EDITING_CHARGE_STATUS';
export const LOADING_GET_CHARGES = 'LOADING_GET_CHARGES';
//End Charge

//Begin Discount
export const SAVE_DISCOUNT = 'SAVE_DISCOUNT';
export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const DELETE_DISCOUNT = 'DELETE_DISCOUNT';
export const GET_DISCOUNTLIST = 'GET_DISCOUNTLIST';
export const GET_DISCOUNT = 'GET_DISCOUNT';
export const GET_REASON_DISCOUNT = 'GET_REASON_DISCOUNT';
export const GET_FILTER_DISCOUNTS = 'GET_FILTER_DISCOUNTS';
export const GET_FILTER_DISCOUNT = 'GET_FILTER_DISCOUNT';
export const LOADING_SAVE_DISCOUNT = 'LOADING_SAVE_DISCOUNT';
export const CHANGE_EDITING_DISCOUNT_STATUS = 'CHANGE_EDITING_DISCOUNT_STATUS';
export const LOADING_GET_DISCOUNTS = 'LOADING_GET_DISCOUNTS';

//End Discount

//Begin Dashboard
export const GET_BILL_CLIENT = 'GET_BILL_CLIENT';
export const GET_COLLABORATOR_RANKING = 'GET_COLLABORATOR_RANKING';
export const GET_BILLING = 'GET_BILLING';
export const GET_BRANCH_RAKING = 'GET_BRANCH_RAKING';
export const GET_INCOME = 'GET_INCOME';
export const GET_BEST_CUSTOMER = 'GET_BEST_CUSTOMER';
export const GET_RAKING_ITEMS = 'GET_RAKING_ITEMS'
export const LOADING_GET_GRAPHIC = 'LOADING_GET_GRAPHIC'
export const GET_ISSUED_INVOICE = 'GET_ISSUED_INVOICE'
//End Dashboard