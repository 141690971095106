import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { formatCurrency } from './../../helpers/formatCurrency';
import { getRoleOfLoggedUser } from '../../actions/authActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.sixthBackgroundColor,
    color: theme.palette.primaryColor,
  },
  avatar: {
  },
  header: {
    color: theme.palette.primaryColor,
    alignItems: 'normal',
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: '1rem !important',
    fontWeight: 'bold',
  },
  subheader: {
    color: theme.palette.primaryColor
  },
  icon: {
    color: theme.palette.secundaryColor
  },
  iconAction: {
    color: theme.palette.primaryColor
  },
  bodysubheader: {
    color: theme.palette.primaryColor,
    fontSize: '0.9rem !important',
  },
  valueCurrency: {
    color: theme.palette.sixthColor,
  },
  valuePercentage: {
    color: theme.palette.primaryColor,
  },
}));

export default function DiscountCard(props) {
  const { data, onDelete, onLoad } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header}
        avatar={data.modelodescuento === 1
          ? <LocalOfferIcon fontSize="small" className={classes.icon} />
          : <AccountBalanceIcon fontSize="small" className={classes.icon} />
        }
        action={
          <Fragment>
            {
              (getRoleOfLoggedUser())
                ? <div>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton aria-label="Editar">
                      <CreateIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton aria-label="Eliminar">
                      <DeleteIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                </div>
                : <div>
                  <Tooltip title="Editar">
                    <IconButton aria-label="Editar" onClick={onLoad}>
                      <CreateIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton aria-label="Eliminar" onClick={onDelete}>
                      <DeleteIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                </div>
            }
          </Fragment>
        }
        classes={{
          title: classes.title,
        }}
        title={data.modelodescuento === 1
          ? 'Descuento Comercial'
          : 'Descuento Financiero'
        }
        subheader={
          <div className={classes.bodysubheader}>
            <Fragment>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <div className={
                    data.tipodescuento === 1
                      ? classes.valueCurrency
                      : classes.valuePercentage
                  }>
                    {
                      data.tipodescuento === 1
                        ? formatCurrency("es-CO", "COP", 2, data.valor)
                        : `${data.valor}%`
                    }
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  {
                    `Motivo: ${data.motivo.descripcion}`
                  }
                </Grid>
                <Grid item lg={12} xs={12}>
                  {data.descripcion}
                </Grid>
              </Grid>
            </Fragment>
          </div>
        }
      />
    </Card>
  );
}