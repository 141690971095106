import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PropTypes from "prop-types"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    icon: {
        color: theme.palette.primaryColor,
        fontSize:30
    }
}));


const CustomInput = withStyles({
    root: {
        '& .MuiInputLabel-formControl': {
            fontSize: 10
        },
        '& label.Mui-focused': {
            color: '#16B1F3', //verde
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#16B1F3', //verde
        },
        '& .MuiInputBase-input': {
            color: '#FFFF',
            fontSize: 15,
            paddingTop: 0,
            paddingBottom: 0
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:focus': {
            borderBottomColor: 'gray', //gris
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#16B1F3', //verde
            },
        },

    },
})(TextField);


const AutoIncrementSmall = ({ ...props }) => {
    const classes = useStyles();
    const { onBlur, onChange, handleIncrement, handleDecrement, name, maxLength } = props;
    let { value } = props;


    return (
        <CustomInput
            name={name}
            className={classes.margin}
            label={"Cantidad"}
            value={value}
            size="small"
            onBlur={onBlur}
            onChange={onChange}
            autoComplete='off'
            inputProps={{ maxLength: maxLength }}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton onClick={handleIncrement} >
                            <span className={classes.icon}>+</span>
                        </IconButton>
                    </InputAdornment>,
                startAdornment:
                    <InputAdornment position="start">
                        <IconButton onClick={handleDecrement} >
                            <span className={classes.icon}>-</span>
                        </IconButton>
                    </InputAdornment>

            }}
        />




    );

}

export default AutoIncrementSmall;
