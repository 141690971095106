export const regexEmail = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /[-_.@A-Za-z0-9]/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}

export const regexAlphaNumeric = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /^([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]+)$/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}

export const regexAlphaNumericAddress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /^([a-zA-Z0-9-.#áéíóúÁÉÍÓÚñÑ\s]+)$/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}

export const regexOnlyNumbers = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /^[0-9]{0,}$/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}

export const regexPhone = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /[-+()@0-9\s]/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}

export const regexText = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /^[A-Za-z áéíóúüñÁÉÍÓÚÜÑ]*$/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}

export const regexNumberTax = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /^(0{0,2}[0-9]|0?[1-9][0-9]|1[0-7][0-9]|100)$/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}

export const regexPercentage = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode)
    let regex = /^((100(\.0{1,2})?)|(\d{1,2}(\.\d{1,2})?))$/;
    if (!regex.test(keyValue))
        return false;
    else
        return true;
}



