import React, { Component } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import InputForm from "../../../components/input/input.component";
import SwitchForm from '../../../components/switch/switch.component.js';
import ButtonPrimary from '../../../components/button/buttonPrimary.component'

import { saveSellerAction, updateSellerAction, getSellersAction, loadSellerAction } from '../../../actions/userAction'

import { regexEmail, regexAlphaNumeric, regexPhone } from '../../../helpers/customRegex.hepers'
import { SuccessAlert, WarningAlert } from '../../../helpers/alert.helpers'
import { getComponentName } from "../../../utils/general";
import isEmpty from '../../../utils/isEmpty'

class Seller extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                txtSellerName: '',
                txtSellerLastName: '',
                txtSellerEmail: '',
                txtSellerPhone: '',
                chkSellerStatus: true
            },
            saveSellerResponse: {},
            updateSellerResponse: {},
            loadSellerObject: {},
            editSellerStatus: false,
            components: [],
            loadingSaveSeller: false
        }

        this.validator = new SimpleReactValidator(reactValidatorOptions);
    }


    componentDidMount() {

    }


    static getDerivedStateFromProps(nextProps, state) {
        let update = {};

        if (!!nextProps.userReducer.saveSellerResponse && nextProps.userReducer.saveSellerResponse !== state.saveSellerResponse) 
            update.saveSellerResponse = nextProps.userReducer.saveSellerResponse;

        if (!!nextProps.userReducer.loadSellerObject && nextProps.userReducer.loadSellerObject !== state.loadSellerObject) 
            update.loadSellerObject = nextProps.userReducer.loadSellerObject;
        

        if (!isEmpty(nextProps.userReducer.editSellerStatus) && nextProps.userReducer.editSellerStatus !== state.editSellerStatus) 
            update.editSellerStatus = nextProps.userReducer.editSellerStatus;
        
        if (!!nextProps.userReducer.updateSellerResponse && nextProps.userReducer.updateSellerResponse !== state.updateSellerResponse)
            update.updateSellerResponse = nextProps.userReducer.updateSellerResponse;

        if (!!nextProps.configReducer.components & nextProps.configReducer.components !== state.components)
            update.components = nextProps.configReducer.components;

        if (!isEmpty(nextProps.userReducer.loadingSaveSeller) && nextProps.userReducer.loadingSaveSeller !== state.loadingSaveSeller)
            update.loadingSaveSeller = nextProps.userReducer.loadingSaveSeller;

        return Object.keys(update).length ? update : null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.saveSellerResponse !== this.state.saveSellerResponse) {
            if (this.state.saveSellerResponse.statusCode === '201') {
                this.cleanFormSeller();
                SuccessAlert(getComponentName(this.state.components, 33, 106, 'Huu juu!!!'), getComponentName(this.state.components, 33, 107, 'Agregaste un nuevo vendedor'));
                this.props.getSellersAction();
            } else if (this.state.saveSellerResponse.statusCode === "301") {
                WarningAlert('Vendedor existente', 'Ya existe un vendedor con este email');
            }
        }

        if (prevState.updateSellerResponse !== this.state.updateSellerResponse) {
            if (this.state.updateSellerResponse.statusCode === '201') {
                SuccessAlert(getComponentName(this.state.components, 34, 110, 'Buen trabajo!!!'), getComponentName(this.state.components, 34, 111, 'Actualizaste el usuario $nombreUsuario de manera exitosa, sigamos facturando.').replace('$nombreUsuario', this.state.updateSellerResponse.result.nombres + ' ' + this.state.updateSellerResponse.result.apellidos));
                this.cleanFormSeller();
                this.props.getSellersAction();
            }
        }

        if(prevState.loadSellerObject !== this.state.loadSellerObject){
            if(!!this.state.loadSellerObject){
                this.loadInfo(this.state.loadSellerObject);
            }
        }

    }

    componentWillUnmount(){
        this.props.loadSellerAction(null);
    }


    /**
       * Sincroniza nuevo valor de input o select con state.
       * @param {*} e Evento
       */
    syncChanges(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        const value = e.target.value;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value,
            },
        }));
    }


    /**
     * Sincroniza nuevo valor de checbox con state.
     * @param {*} e Evento
     */
    syncCheckChange = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: checked,
            },
        }));
    };


    /**
      * Elimina caracteres invalidos para correo electronico
      * @param {*} e Evento
      */
    validateEmail(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexEmail;
        value = value.replace(regex, "");
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value,
            },
        }));
    }

    /**
     * Elimina caracteres inavlidos para formatado telefonico
     * @param {*} e Evento
     */
    validatePhone(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexPhone;
        value = value.replace(regex, "");
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value,
            },
        }));
    }

    /**
     * Elimina caracteres invalidos, solamente letras y numeros
     * @param {*} e Evento
     */
    validateAlphaNumeric(e) {
        const id = !!e.target.id ? e.target.id : e.target.name;
        let value = e.target.value;
        let regex = regexAlphaNumeric;
        value = value.replace(regex, "");
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [id]: value,
            },
        }));
    }



    /**
     * Captura y guarda o actualiza información de Vendedor
     * @param {*} e Evento
     */
    onSaveSeller = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {

            if (this.state.editSellerStatus === true) {
                let data = {
                    ...this.state.loadSellerObject,
                    nombres: this.state.form.txtSellerName,
                    apellidos: this.state.form.txtSellerLastName,
                    email: this.state.form.txtSellerEmail,
                    telefono: this.state.form.txtSellerPhone,
                    idState: this.state.form.chkSellerStatus === true ? 0 : 1,
                };
                this.props.updateSellerAction(data)

            } else {
                let data = {
                    nombres: this.state.form.txtSellerName,
                    apellidos: this.state.form.txtSellerLastName,
                    email: this.state.form.txtSellerEmail,
                    telefono: this.state.form.txtSellerPhone,
                    idState: this.state.form.chkSellerStatus === true ? 0 : 1,
                    createby: 1,
                    modifiedby: 1,
                    idowner: 1,
                };
                this.props.saveSellerAction(data);
            }

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    /**
     * LImpia información de formulario
     */
    cleanFormSeller = () => {
        this.setState({
            form: {
                txtSellerName: '',
                txtSellerLastName: '',
                txtSellerEmail: '',
                txtSellerPhone: '',
                chkSellerStatus: true
            },
        });
        this.validator.hideMessages();
        this.forceUpdate();
        this.props.loadSellerAction(null);
    }

    /**
     * Carga información  de vendedor en formulario para edición
     * @param {*} data Información vendedor
     */
    loadInfo = (data)=>{
        this.setState({
            form: {
                txtSellerName: data.nombres,
                txtSellerLastName: data.apellidos,
                txtSellerEmail: data.email,
                txtSellerPhone: data.telefono,
                chkSellerStatus: data.idstate=== 0 ? true: false
            },
        });
    }

    render() {
        return (
            <form id="sellerForm" onSubmit={(e) => this.onSaveSeller(e)} noValidate autoComplete="off" >
                <Grid container alignItems="center" spacing={3}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <InputForm
                            name={"txtSellerName"}
                            label={"Nombres *"}
                            maxLength={200}
                            value={this.state.form.txtSellerName}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateAlphaNumeric(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required"}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <InputForm
                            name={"txtSellerLastName"}
                            label={"Apellidos *"}
                            maxLength={200}
                            value={this.state.form.txtSellerLastName}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateAlphaNumeric(e);
                            }}
                            validator={this.validator}
                            validateOptions={"required"}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <InputForm
                            name="txtSellerEmail"
                            label={"Correo electrónico"}
                            maxLength={100}
                            value={this.state.form.txtSellerEmail}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validateEmail(e);
                            }}
                            validator={this.validator}
                            validateOptions={"email"}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <InputForm
                            name="txtSellerPhone"
                            label={"Teléfono"}
                            maxLength={15}
                            value={this.state.form.txtSellerPhone}
                            onChange={(e) => {
                                this.syncChanges(e);
                                this.validatePhone(e);
                            }}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <SwitchForm
                            name="chkSellerStatus"
                            titleOn={this.state.form.chkSellerStatus ? "Desactivar vendedor" : "Activar vendedor"}
                            checked={this.state.form.chkSellerStatus}
                            onChange={this.syncCheckChange}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justify="flex-end" alignItems="center">
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <ButtonPrimary
                            text={getComponentName(this.state.components, 37, 118, 'Listo')}
                            loading={this.state.loadingSaveSeller}
                            type={'submit'}
                        />
                    </Grid>
                </Grid>
            </form>
        )
    }
}

Seller.propTypes = {
    saveSellerAction: PropTypes.func.isRequired,
    updateSellerAction: PropTypes.func.isRequired,
    getSellersAction: PropTypes.func.isRequired,
    loadSellerAction: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({
    userReducer: state.userReducer,
    basicDataReducer: state.basicDataReducer,
    configReducer: state.configReducer,

})

export default connect(mapStateToProps, {
    saveSellerAction,
    updateSellerAction,
    getSellersAction,
    loadSellerAction
})(Seller);