import { createMuiTheme } from '@material-ui/core/styles';


export const theme = createMuiTheme({
    typography: {
        fontFamily: 'Muli',
        fontSize: 15,
    },
    palette: {
        primaryBackgroundColor: '#121212', // Negro
        secundaryBackgroundColor: '#232323', //Gris
        thirdBackgroundColor: '#4A4A4A', //Gris claro
        fourthBackgroundColor: '#5E5E5E;', // Gris brillante
        fifthBackgroundColor: '#C9C9C9', // Gris muy claro
        sixthBackgroundColor: '#363636', // Gris card
        seventhBackgroundColor: '#272727', // Negro claro
        eighthBackgroundColor: '#3B3B3B', // Gris para panel Total
        ninethBackgroundColor: 'rgba(255,255,255,0.12)', // Negro cards Dashboard

        primaryColor: '#FFFF', // blanco
        secundaryColor: '#2CC63E',  // verde
        thirdColor: '#16B1F3', //azul
        fourthColor: '#F6D746', //amarillo
        fifthColor: '#FF5757', //rojo
        sixthColor: '#EC005F', //fucsia
        seventhColor: '#A2E3A4', //Verde claro
        eighthColor: '#4A4A4A', //Gris muy claro

        modalBackgroundColor: 'rgba(18,18,18,0.7)',
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: 'white',
                '&$focused': {
                    color: 'white',
                },
            },
        },
        MuiMenu: {
            list: {
                backgroundColor: '#232323', //Gris
            },
        },
        MuiMenuItem: {
            root: {
                color: '#FFFF', // blanco
            },
        },

    },

});
