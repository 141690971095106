import {
  SUCCES_CATEGORY,
  SUCCES_DELETE_CATEGORY,
  SUCCES_UPDATE_CATEGORY,
  LOAD_CATEGORY,
  GET_CATEGORY_ARTICLE,
  CLEAN_DATA_CATEGORY,
  CLEAN_STATUS,
  GET_CATEGORY
} from "../actions/types";
import isEmpty from "../utils/isEmpty";
const initialState = {
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUCCES_CATEGORY:
      return {
        ...state,
        requestSuccessCategory: action.payload,
      };
    case SUCCES_DELETE_CATEGORY:
      return {
        ...state,
        requestSuccessDeleteCategory: action.payload,
      };
    case SUCCES_UPDATE_CATEGORY:
      return {
        ...state,
        requestSuccessUpdateCategory: action.payload,
      };
    case LOAD_CATEGORY:
      return {
        ...state,
        editCategoryStatus: !isEmpty(action.payload),
        requestLoadCategory: action.payload,
      }

    case GET_CATEGORY_ARTICLE:
      return {
        ...state,
        getCategories: action.payload
      };

      case CLEAN_DATA_CATEGORY:
      return {
        cleanDataCategory: action.payload,
        editCategoryStatus : action.payload1
      }
      case CLEAN_STATUS :
        return{
          cleanStatus : action.payload
        }
      //Funciona-----  
      case GET_CATEGORY : 
      return {
        requestGetCategory : action.payload,
        count : action.payload1
      }
    default:
      return state;
  }
}
