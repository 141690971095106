import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#5E5E5E',
    color: theme.palette.primaryColor,
    boxShadow:'none',
    '& .MuiCardHeader-avatar': {
      marginRight: 10
    },
    '& .MuiCardContent-root:last-child':{
      paddingBottom: 0,
    }
  },
  avatar: {

  },
  header: {
    padding: 0
  },
  title: {
    color: theme.palette.primaryColor,
    fontWeight: 'bold',
    fontSize: 14
  },
  subheader: {
    color: theme.palette.primaryColor
  },
  icon: {
    color: theme.palette.secundaryColor
  },
  iconAction: {
    color: theme.palette.primaryColor
  },
  content: {
    textTransform: 'capitalize',
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
    marginLeft:15
  }

}));

export default function AddressCard(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header}
        avatar={<LocationOnIcon fontSize="small" className={classes.icon} />}
        action={''}
        classes={{
          title: classes.title,
          subheader: classes.title
        }}
        title={data.descripcion}
        subheader={''}
      />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item lg={12} xs={12}>
            {data.direccion}
          </Grid>
          <Grid item lg={12} xs={12} >
            <div className={classes.content}>
              {`${data.departamentodescripcion.toLowerCase()} - ${data.ciudaddescripcion.toLowerCase()}`}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
