import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import DeleteIcon from '@material-ui/icons/Delete';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import Grid from '@material-ui/core/Grid';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import SecondCollapse from '../../components/collapse/secondCollapse.component';
import CustomProgress from '../../components/Progress/progress.component';
import ButtonPrimary from '../../components/button/buttonPrimary.component'
import AddressCard from './addressCard.component';
import ContactCard from './contactCard.component';

import documentDescription from '../../utils/typeDocument';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.fourthBackgroundColor,
        color: theme.palette.primaryColor,
        fontWeight: 300
    },
    header: {
        color: theme.palette.fourthColor,
        fontWeight: 'bold',
        padding: '15px 20px 5px 20px'
    },
    content: {
        textTransform: 'capitalize',
        fontSize: 14,
        fontWeight: 300
    },
    icon: {
        color: '#2CC63E',
        marginLeft: 0,
        marginRight: 10,
        fontSize: 19
    },

    title: {
        fontWeight: 600,
        fontSize: 24,
        textTransform: 'capitalize'
    },
    label: {
        flexDirection: 'column',
        fontSize: 10,
        color: theme.palette.primaryColor
    },
    formCheck: {
        margin: 0,
        '& .MuiIconButton-root': {
            padding: 0
        },
    },
    styleActions: {
        backgroundColor: '#393939'
    },
    cardContent: {
        paddingTop: 5,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 700,
    },
    divider: {
        width: '100%',
        borderBottom: '1px solid gray',
        display: 'inline-grid'
    },
    iconComun: {
        color: theme.palette.primaryColor
    },
    containerProgress: {
        textAlign: 'center',
        width: '100%'
    },
    titleInfo: {
        color: theme.palette.secundaryColor,
        fontSize: 14,
        fontWeight: 600,
    },
    buttonGreen: {
        width: '100%',
        backgroundColor: theme.palette.secundaryColor,
        color: theme.palette.primaryBackgroundColor,
        outline: 'none',
        borderRadius: 5,
        boxShadow: 'none',
        border: 'none',
        fontWeight: 'bold',
        fontSize: 16,
        fontFamily: 'Muli',
        display: 'inline-flex'
    }

}));

function Invoices() {
    return (
        <Fragment>
            <div>Listado Facturas</div>
        </Fragment>

    );
}


function ClientDetail(props) {
    const classes = useStyles();
    const { data, onUpdate, onDelete, onStandOut, regimeTypeName, loadingDetail, disabled } = props;

    return (

        <Fragment>
            {!!data ?
                <Card className={classes.root}>
                    <CardHeader
                        className={classes.header}
                        classes={{
                            title: classes.title,
                        }}
                        title={data.razonsocial.toLowerCase()}
                        subheader=""
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.content} component="div">
                            {documentDescription(!!data.diantipodocumentoidentidadid ? data.diantipodocumentoidentidadid : 0)} {data.documento} <br />
                            {regimeTypeName && regimeTypeName.text} <br />
                        </Typography>
                        <Typography disableTypography component="div" className={classes.content} style={{ textTransform: 'none' }} >
                            <EmailIcon className={classes.icon} />
                            {data.correoelectronico}
                        </Typography>
                        <Typography disableTypography className={classes.content} component="div">
                            <PhoneIcon className={classes.icon} />
                            {data.telefono}
                        </Typography>
                        <Typography disableTypography className={classes.content} component="div">
                            <RoomIcon className={classes.icon} />
                            {data.ciudaddescripcion + ' - ' + data.departamentodescripcion}
                        </Typography>
                        <div>
                            {
                                (data?.adclientedireccion?.length > 0 || data?.adcontactos?.length > 0)
                                    ? <div>
                                        <span className={classes.divider} ></span>
                                        <div className={classes.titleInfo}> Información adicional</div>
                                    </div>
                                    : null
                            }
                        </div>
                        {
                            data.adclientedireccion.map((item, index) => (
                                <Grid item lg={12} xs={12}>
                                    <AddressCard key={index}
                                        data={item}
                                    />
                                </Grid>
                            ))
                        }
                        {
                            data.adcontactos.map((item, index) => (
                                <Grid item lg={12} xs={12}>
                                    <ContactCard key={index}
                                        data={item}
                                    />
                                </Grid>
                            ))
                        }

                        <div className="container-title"> Últimas Facturas</div>
                        <div className={classes.buttonGreen}>
                            <div style={{
                                width: '50%',
                                padding: '10px 20px'
                            }}>
                                Ver todas las facturas
                                </div>
                            <div style={{
                                width: '50%',
                                padding: '10px 20px',
                                textAlign: 'end'
                            }}>
                                <ArrowForwardIosIcon />
                            </div>
                        </div>

                    </CardContent >
                    <CardActions className={classes.styleActions} >
                        <Grid container >
                            {loadingDetail === true ?
                                <div className={classes.containerProgress}>
                                    <CustomProgress />
                                </div>
                                :
                                <Fragment>
                                    <Grid item lg={6} xs={6} style={{ textAlign: 'center' }}>
                                        <ButtonPrimary
                                            text={"Nueva Factura"}
                                            loading={false}
                                            type='button'
                                            disabled={disabled === true ? true : false}
                                        />
                                    </Grid>
                                    <Grid item lg={2} xs={2} style={{ textAlign: 'center' }}>
                                        <IconButton
                                            classes={{ root: classes.button, label: classes.label }}
                                            variant="raised"
                                            disableRipple={true}
                                            onClick={onDelete}
                                            disabled={disabled === true ? true : false}>
                                            <DeleteIcon className={classes.iconAction} />
                                            {'Eliminar'}
                                        </IconButton>

                                    </Grid>
                                    <Grid item lg={2} xs={2} style={{ textAlign: 'center' }}>
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <Checkbox
                                                    name="checkedF"
                                                    checked={data.favorito}
                                                    onChange={onStandOut}
                                                    className={classes.formCheck}
                                                    disabled={disabled === true ? true : false}
                                                    icon={
                                                        <IconButton
                                                            classes={{ root: classes.button, label: classes.label }}
                                                            variant="raised"
                                                            disableRipple={true}>
                                                            <StarBorderIcon className={classes.iconAction} />
                                                            {'Destacar'}
                                                        </IconButton>}
                                                    checkedIcon={
                                                        <IconButton
                                                            classes={{ root: classes.button, label: classes.label }}
                                                            variant="raised"
                                                            disableRipple={true}>
                                                            <StarIcon className={classes.iconAction} />
                                                            {'Destacar'}
                                                        </IconButton>
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={2} xs={2} style={{ textAlign: 'center' }}>
                                        <IconButton
                                            classes={{ root: classes.button, label: classes.label }}
                                            variant="raised"
                                            disableRipple={true}
                                            onClick={onUpdate}
                                            disabled={disabled === true ? true : false}>
                                            <EditIcon className={classes.iconAction} />
                                            {'Editar'}
                                        </IconButton>
                                    </Grid>
                                </Fragment>
                            }

                        </Grid>
                    </CardActions>
                </Card > : ''
            }

        </Fragment>
    );
}


ClientDetail.propTypes = {

}

export default ClientDetail;
