import { combineReducers } from "redux";

import generalReducer from './generalReducer';
import userReducer from './userReducer'
import officeReducer from './officeReducer'
import branchOfficeReducer from './branchOfficeReducer'
import numerationReducer from './numerationReducer'
import templateReducer from './templateReducer'
import basicDataReducer from './basicDataReducer'
import articleReducer from './articleReducer'
import categoryReducer from './categoryReducer'
import configReducer from './configReducer'
import invoiceReducer from './invoiceReducer'
import addressReducer from './addressReducer'
import contactReducer from './contactReducer'
import clientReducer from './clientReducer'
import chargeReducer from './chargeReducer'
import discountReducer from './discountReducer'
import valuesInvoiceReducer from './valuesInvoiceReducer'
import dashboardReducer from './dashboardReducer'

export default combineReducers({
    generalReducer,
    userReducer,
    branchOfficeReducer,
    numerationReducer,
    templateReducer,
    basicDataReducer,
    officeReducer,
    configReducer,
    articleReducer,
    categoryReducer,
    invoiceReducer,
    addressReducer,
    contactReducer,
    clientReducer,
    chargeReducer,
    discountReducer,
    valuesInvoiceReducer,
    dashboardReducer
});
