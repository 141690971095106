import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InputSearch from '../input/inputSearch.component'
import CustomProgress from '../Progress/progress.component'



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 200,
        backgroundColor: theme.palette.primaryBackgroundColor,
        color: theme.palette.primaryColor,
        border: 'none',
    },
    listStyle: {
        backgroundColor: theme.palette.primaryBackgroundColor,
        color: theme.palette.primaryColor,
    },
    item: {
        fontSize: 12,
        marginBottom: 3,
        "&.MuiListItem-root:hover": {
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,0.16)'
        },
        '& .MuiTypography-displayBlock': {
            fontSize: 14
        }
    },
    itemButton:{
        fontSize: 12,
        marginBottom: 3,
        '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,0.16)'
        },
        '& .MuiTypography-displayBlock': {
            fontSize: 14
        }
    },
    icon: {
        color: 'gray'
    },
    noResult: {
        marginBottom: 5,
        padding: 5,
        paddingLeft: 15
    },
    containerInput:{
        padding:'10px 15px'
    }
}));


/**
 * Administración de Lista (listado, creación y busqueda)
 * Autor:Fernando Ruiz
 * @method function
 * @param list  Listado a mostrar
 * @param value Valor a  filtrar
 */
function filterList(value, list) {
    if (value !== '') {
        let myList = list.filter(c => c.text.toLowerCase().match(new RegExp(value.toLowerCase() + ".*")));
        return myList;
    }
    return list;
}



function ManageList(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState('');
    const [txtListFilter, SetFilter] = React.useState('');
    const [filteredList, SetList] = React.useState([]);
    const { list, labelFilter, labelButton, onClick, onClickNew, loading } = props;



    useEffect(() => {
        SetList(list);
    }, [list]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const syncChanges = (e) => {
        SetFilter(e.target.value);
    }


    /**
     * @event onChangeFilter 
     * @description Escucha cambios en input de filtro
     * @param {*} e  Evento
     */
    const onChangeFilter = (e) => {
        let value = e.target.value;
        SetList(filterList(value, list));
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.containerInput}>
                <InputSearch
                    label={labelFilter}
                    maxLength={15}
                    value={txtListFilter}
                    onChange={(e) => {
                        syncChanges(e);
                        onChangeFilter(e);
                    }} />
                </div>  
                {loading === true ?
                    <CustomProgress /> :
                    <List className={classes.listStyle} component="nav" aria-label="main mailbox folders">
                        <ListItem
                            className={classes.itemButton}
                            button
                            onClick={onClickNew}>
                            <ListItemText primary={labelButton} />
                            <ListItemIcon style={{minWidth:0}}>
                                <AddCircleIcon className={classes.icon} />
                            </ListItemIcon>
                        </ListItem>
                        {filteredList.length ?
                            <Fragment>
                                {filteredList.map((item, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            className={classes.item}
                                            button
                                            selected={selectedIndex === index}
                                            onClick={(event) => {
                                                handleListItemClick(event, index);
                                                onClick(event, item)
                                            }}
                                        >
                                            <ListItemText primary={item.text} />
                                        </ListItem>
                                    )
                                })}
                            </Fragment> :
                            <div className={classes.noResult}>No se han encontrado resultados...</div>}
                    </List>
                }
            </div>
        </Fragment >

    );
}

ManageList.propTypes = {
    list: PropTypes.array.isRequired,

}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {

})(ManageList);