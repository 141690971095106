import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";


import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Grid } from "@material-ui/core";
import { Tooltip } from '@material-ui/core';

import isEmpty from '../../../utils/isEmpty'
import { URL_IMAGES } from '../../../config/config.js';



const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor: theme.palette.ninethBackgroundColor,
    borderRadius: '5px',
    color: theme.palette.primaryColor,
    border: 'none',
    height: '100%',
    '& .MuiCardContent-root': {
      padding: 0
    },
  },
  '& .MuiCheckbox-root': {
    color: 'white',
    padding: 0
  },
  cardSelected: {
    backgroundColor: theme.palette.ninethBackgroundColor,
    borderRadius: '5px',
    color: theme.palette.primaryColor,
    border: '1.5px solid #16B1F3',
    boxShadow: '0px 0px 6px 1px #16b1f38c',
    height: '100%',
    '& .MuiCardContent-root': {
      padding: 0
    },
  },
  cover: {
    borderRadius: 10,
    position: 'relative',
    width: '100%',
    height: '100%'
  },

  titleImage: {
    position: 'absolute',
    textAlign: "center",
    color: theme.palette.primaryColor,
    top: '30%',
    left: '30%',
    fontSize: '4vh',
    fontWeight: 600

  },
  spanArticleName: {
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    width: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  },
  spanValor: {
    color: theme.palette.primaryColor,
    fontSize: 16,
    paddingTop: -20,
    fontWeight: 500,
  },

  containerCard: {
    marginTop: '10%',
    height: '80%'
  },

  styleCheck: {
    color: theme.palette.primaryColor
  },
  tag: {
    width: 100,
    borderRadius: 10,
    color: theme.palette.primaryColor,
    fontWeight: 600,
    fontSize: 12,
    marginRight: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 1,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
}));

const CheckboxCustom = withStyles({
  root: {
    "& .MuiCheckbox-colorPrimary": {
      backgroundColor: "green",
      color: "red",
    },
    '& .MuiCheckbox-root': {
      color: 'white'
    },
    "& .MuiTab-textColorSecondary": {
      backgroundColor: "green",
      color: "red",
    },
    "&.Mui-checked": {
      color: "#16B1F3",
    },
  },
})(Checkbox);

const CardArticle = ({ ...props }) => {
  const [checked, setChecked] = React.useState(false);
  const [localdata, setLocalData] = React.useState({});
  const classes = useStyles();
  const { onSelectArticle, onChange, data } = props;


  useEffect(() => {

    if (!!data && data !== localdata) {
      setLocalData(data);
      setChecked(data.checked);
    }

  }, [data, localdata, props.data])


  const subNameItem = (name) => {
    return name ? name.substring(0, 2).toUpperCase() : "";
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  }


  return (
    <Card className={checked === true ? classes.cardSelected : classes.root}>
      <CardContent style={{ height: '100%' }}>
        <Grid container spacing={1}>
          <Grid lg={5} xs={5} style={{cursor: 'pointer'}}>
            <Tooltip title="Ver detalle...">
              <CardMedia
                className={classes.cover}
                image={URL_IMAGES + data.imagen}
                style={{
                  background: `${data.imagen === null || data.imagen === undefined || data.imagen === '' ?
                    data.color :
                    null}`,
                }}
                onClick={(e) => onSelectArticle(e, data)}
                title="image"
              >
                {isEmpty(data.imagen) ?
                  <span className={classes.titleImage}>
                    {subNameItem(data.nombrearticulo)}
                  </span>
                  :
                  ''}
              </CardMedia>
            </Tooltip>


          </Grid>
          <Grid item lg={7} xs={7} style={{paddingLeft: '10px'}}>
            <Grid container className={classes.containerCard}>

              <Grid item lg={12} xs={12} style={{ paddingBottom: 5, paddingTop: 5 }}>
                <Tooltip title="Ver detalle...">
                  <div
                    className={classes.spanArticleName}
                    onClick={(e) => onSelectArticle(e, data)} >
                    {data.nombrearticulo}
                  </div>
                </Tooltip>
              </Grid>

              <Grid item lg={12} xs={12} style={{ paddingBottom: 5, paddingTop: 5, display: 'flex' }} >
                {data.adcategoriaarticulo.map((category, index) => (
                  <div
                    key={index}
                    className={classes.tag}
                    style={{ backgroundColor: category?.categoria?.color }}>
                    {category?.categoria?.nombre}
                  </div>
                ))}
              </Grid>

              <Grid lg={12} xs={12}>
                <span
                  className={classes.spanValor}
                >
                  {`$ ${data.valor.toLocaleString('en')}`}
                </span>
              </Grid>

              <Grid lg={12} xs={12} style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'end' }}>
                <CheckboxCustom
                  className={classes.styleCheck}
                  onChange={(e) => {
                    onChange(e, data);
                    handleCheck(e);
                  }}
                  checked={checked} />
              </Grid>

            </Grid>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};


export default CardArticle
