import React, {Component} from "react"
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/styles';
import {Grid} from "@material-ui/core"
import SimpleReactValidator from "simple-react-validator";

import {reactValidatorOptions} from "../../helpers/simpleReactValidator";
import InputComponent from '../../components/input/input.component'
import SelectComponent from '../../components/select/select.component'
import ButtonPrimary from '../../components/button/buttonPrimary.component'
import CardContact from '../../components/card/infoCardContact.component'
import {getMenuComponentAction, cleanMenuComponentAction, getreason, sendMailContact} from '../../actions/configAction'
import {getComponentName} from "../../utils/general.js";
import {SuccessAlert} from "../../helpers/alert.helpers";

import Phone from "../../../src/images/phone.png";
import Calendar from "../../../src/images/calendar.png";
import Email from "../../../src/images/email.png";


const styles = () => ({
  descriptionSpan: {
    height: '81px',
    width: '480px',
    color: '#7DD1F8',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontStyle: 'italic',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '23px',
    textAlign: 'center',
    margin: 'auto'
  },
  descriptionName: {
    height: '26px',
    width: '372px',
    color: '#848181',
    fontFamily: 'Muli',
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '26px'
  },
  mainGrid: {
    margin: 'auto'
  }
})

export class ReportProblem extends Component {

  constructor() {
    super();

    this.state = {
      form: {
        ddlReason: '',
        txtDescription: '',
        mailSupport: '',
        issue: ''
      },
      issue: [],
      data: [],
      components: [],
      fullName: '',
      emailUser: '',
      loadingSend: false,
      responseDataMailContact: {}
    }
    this.validator = new SimpleReactValidator(reactValidatorOptions);

  }

  componentDidMount() {
    this.props.getMenuComponentAction(9);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (!!nextProps.configReducer.reasonData && nextProps.configReducer.reasonData !== state.reasonData) {
      update.reasonData = nextProps.configReducer.reasonData;
      update.data = nextProps.configReducer.data;
      update.emailUser = nextProps.configReducer.emailUser;

    }
    if (!!nextProps.configReducer.components && nextProps.configReducer.components !== state.components) {
      update.components = nextProps.configReducer.components
    }
    if (!!nextProps.configReducer.responseDataMailContact &&
      (nextProps.configReducer.responseDataMailContact !== state.responseDataMailContact)) {
      update.responseDataMailContact = nextProps.configReducer.responseDataMailContact;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.responseDataMailContact !== this.state.responseDataMailContact) {
      if (this.state.responseDataMailContact.statusCode === "200") {
        SuccessAlert(getComponentName(this.state.components, 87, 140, 'Mensaje de notificacion de envio satisfactorio de reporte de problema.'))
        this.cleanFormProblem();
        this.setState({
          loadingSend: false
        })
        // this.props.changeState(false)

      }


    }
}
  syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;


    const value = e.target.value;
    let emailSelect = ''
    let issue = ''
    this.state.data.map(item => {
      if (item.id === value) {
        emailSelect = item.correo
        issue = item.descripcion
      }
    })
    console.log("Email seleccionado ----->", emailSelect)
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
        mailSupport: emailSelect,
        issue: issue
      },
    }));

  }

  syncChange(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value
      },
    }));
  }

  onSendReport = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let mailStructure = {}
      mailStructure.mailSupport = this.state.form.mailSupport
      mailStructure.mailUser = this.state.emailUser
      mailStructure.issue = this.state.form.issue
      mailStructure.bodyMail = this.state.form.txtDescription
      this.props.sendMailContact(mailStructure, true)
      this.setState({
        loadingSend: true
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  cleanFormProblem = () => {
    this.setState({
      form: {
        ddlReason: '',
        txtDescription: '',
        mailSupport: '',
        issue: ''
      }
  })
  this.validator.hideMessages();
}

  render() {
    const classes = this.props.classes;
    return (
      <form id="reportProblem" onSubmit={(e) => this.onSendReport(e)} noValidate autoComplete="off">
        <Grid container>
          <Grid item md={8} spacing={2} className={classes.mainGrid}>
            <Grid item lg={12} md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} xs={4}>
                  <CardContact
                    imageCard={Email}
                    subtitle={getComponentName(this.state.components, 81, 134, 'dortiz@kambo.name')}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={4}>
                  <CardContact
                    imageCard={Phone}
                    subtitle={getComponentName(this.state.components, 82, 135, '0-(972)531-4940')}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={4}>
                  <CardContact
                    imageCard={Calendar}
                    subtitle={getComponentName(this.state.components, 83, 136, 'Lunes-Viernes 7 am 6pm Sabados 8 am - 2 pm')}
                    margin={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid/>
            <br/>
            <hr color={'#979797'} size={1}/>
            <br/>
            <div className={classes.descriptionSpan}>
              {
                getComponentName(
                  this.state.components,
                  84,
                  137,
                  'Por favor completa el siguiente formulario. Dependiendo de la solicitud que requieras, nuestro equipo de trabajo te responderá a la ayor brevedad posible.'
                )}
            </div>
            <br/>
            <SelectComponent
              id="ddlReason"
              name="ddlReason"
              label={"Motivo *"}
              options={this.state.reasonData}
              //options={dataEjemplo}
              value={this.state.form.ddlReason}
              onChange={(e) => {
                this.syncChanges(e);
              }}
              validator={this.validator}
              validateOptions={'required'}
            />
            <InputComponent
              id={"txtDescription"}
              rowsLine={true}
              label="Observaciones *"
              maxLength={5000}
              multiline
              value={this.state.form.txtDescription}
              onChange={(e) => {
                this.syncChange(e)
              }}
              validator={this.validator}
              validateOptions={'required'}
            />
            <Grid container spacing={5} justify="center" alignItems="center">
              <Grid item lg={6} md={6} sm={12} xs={12}/>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ButtonPrimary
                  loading={this.state.loadingSentrue}
                  text={getComponentName(this.state.components, 85, 138, 'Enviar')}
                  //disabled={this.state.checkedArticles.length > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

ReportProblem.propTypes = {}

const mapStateToProps = state => ({
  configReducer: state.configReducer

})

export default connect(mapStateToProps,
  {
    getMenuComponentAction,
    cleanMenuComponentAction,
    getreason,
    sendMailContact
  })(withStyles(styles)(ReportProblem))

